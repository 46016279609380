import React, { useState } from "react";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikAutocomplete from "../../../components/Common/Autocomplete";
import UploadImage from "../../../components/Common/UploadImage";
import { getBase64 } from "../../../helpers/helper";

const AgencyForm = (props) => {
  const [imagesList, setImagesList] = useState([]);

  const handleChangeImages = async (files, field, setFieldValue, values) => {
    setFieldValue(field, files[0]);
    let images = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let content = await getBase64(file);
      images.push({
        url_path: content,
        name: file.name,
      });
    }
    setImagesList(images);
  };

  return (
    <Formik
      initialValues={props.initValues}
      validationSchema={props.validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        props.handleSubmit(values);
      }}
      render={({ errors, touched, setFieldValue, values }) => (
        <Form>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <FormGroup className="mb-4" row>
                <Label htmlFor="name" className="col-form-label col-lg-2">
                  Nom {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    tag={Field}
                    invalid={errors.name && !!touched.name}
                    className="form-control"
                    placeholder="Entrer un nom"
                  />
                  <ErrorMessage name="name" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="leader" className="col-form-label col-lg-2">
                  Responsable
                </Label>
                <Col lg="10">
                  <FormikAutocomplete
                    name="leader"
                    id="leader"
                    invalid={errors.leader && !!touched.leader}
                    className={errors.leader ? "selectorError" : null}
                    options={props.users.map((item) => {
                      return {
                        label: `${item.lastname} ${item.firstname}`,
                        value: item.id,
                      };
                    })}
                  />
                  <ErrorMessage
                    className={errors.leader ? "errorMessages" : null}
                    name="leader"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="address" className="col-form-label col-lg-2">
                  Adresse {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="address"
                    name="address"
                    type="text"
                    tag={Field}
                    invalid={errors.address && !!touched.address}
                    className="form-control"
                    placeholder="Entrer une adresse"
                  />
                  <ErrorMessage name="address" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="phone" className="col-form-label col-lg-2">
                  Téléphone {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="phone"
                    name="phone"
                    type="text"
                    tag={Field}
                    invalid={errors.phone && !!touched.phone}
                    className="form-control"
                    placeholder="Entrer un numéro de téléphone"
                  />
                  <ErrorMessage name="phone" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="email" className="col-form-label col-lg-2">
                  Email {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="email"
                    name="email"
                    type="text"
                    tag={Field}
                    invalid={errors.email && !!touched.email}
                    className="form-control"
                    placeholder="Entrer une adresse email"
                  />
                  <ErrorMessage name="email" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="comptable_account" className="col-form-label col-lg-2">
                  Section comptable {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="comptable_account"
                    name="comptable_account"
                    type="text"
                    tag={Field}
                    invalid={errors.comptable_account && !!touched.comptable_account}
                    className="form-control"
                    placeholder="Entrer un libellé comptable"
                  />
                  <ErrorMessage name="comptable_account" component={FormFeedback} />
                </Col>
              </FormGroup>
              <UploadImage
                files={
                  imagesList.length > 0
                    ? imagesList
                    : props.agency.url === "" || props.agency.url === undefined
                    ? []
                    : [
                        {
                          url_path:
                            props.agency.url.match(/http/) !== null
                              ? props.agency.url
                              : `${process.env.REACT_APP_API_URL}/files/${props.agency.url}`,
                        },
                      ]
                }
                title="Image de l'agence"
                handleChangeImages={handleChangeImages}
                max_file={1}
                field="url"
                setFieldValue={setFieldValue}
                invalid={errors.url && !!touched.url}
                invalidError={errors.url}
                isInForm={true}
              />
              <ErrorMessage name="url" component={FormFeedback} />
            </div>
          </div>
          <Row className="justify-content-end">
            <Col lg="10">
              <Row>
                <Col lg="2">
                  <Button type="submit" color="primary">
                    {props.isUpdate ? "Modifier l'agence" : "Créer l'agence"}
                  </Button>
                </Col>
                <Col lg="10">
                  {props.error && props.error ? (
                    <Alert color="danger" className="d-inline-block">
                      {props.error}
                    </Alert>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default AgencyForm;
