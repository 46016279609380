import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { GET_VATS } from "./actionTypes";
import { getVatsSuccess, vatsApiError } from "./actions";

import { vats } from "../../api";

function* getVats({ payload: { query } }) {
  try {
    const response = yield call(vats.getAll, query);
    yield put(getVatsSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        vatsApiError("Vous n'êtes pas autorisé à accéder à la liste des TVA")
      );
    } else if (error.response.data !== undefined) {
      yield put(vatsApiError(error.response.data.message));
    } else {
      yield put(vatsApiError(error.message));
    }
  }
}

export function* watchGetVats() {
  yield takeEvery(GET_VATS, getVats);
}

function* vatsSaga() {
  yield all([fork(watchGetVats)]);
}

export default vatsSaga;
