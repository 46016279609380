import { Field, Form, Formik } from 'formik';
import React from "react";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import DatePicker from "../../../../components/Common/DatePicker";
import { OffersFilters } from "../../../../models/offer";

interface OffersFilterProps {
  filters: OffersFilters,
  setFilters: React.Dispatch<React.SetStateAction<OffersFilters>>
}

const OffersFilter = ({
  filters,
  setFilters,
}: OffersFilterProps) => {

  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      onSubmit={(values) => {
        setFilters({
          ...filters,
          percent: values.percent,
          value: values.value,
          end_at: values.end_at
        })
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form>
          <Card>
            <CardBody>
              <Row>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="percent">Pourcentage</Label>
                    </Col>
                    <Col xs="12">
                      <Input
                        id="percent"
                        name="percent"
                        type="text"
                        className="form-control"
                        tag={Field}
                        placeholder="Entrer un pourcentage"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="value">Montant</Label>
                    </Col>
                    <Col xs="12">
                      <Input
                        id="value"
                        name="value"
                        type="text"
                        className="form-control"
                        tag={Field}
                        placeholder="Entrer un montant"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4" className='unova-datepicker'>
                  <Row>
                    <Col xs="12">
                      <Label>Date de fin</Label>
                    </Col>
                    <Col xs="12">
                      <DatePicker
                        className="form-control button-without-style"
                        name="end_at"
                        value={values.end_at}
                        onChange={setFieldValue}
                        isClearable={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="text-center mt-3">
                  <Button
                    color="none"
                    className="btn-green"
                    onClick={() => handleSubmit(values as any)}
                  >
                    Appliquer les filtres
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default OffersFilter;
