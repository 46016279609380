import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {Button, Col, Row} from "reactstrap";
import {onTableChange} from "../../../helpers/table_helper";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const FormulasTable = ({
  data,
  setFilters,
  filters,
  pagination,
  deleteFormula,
  history,
}) => {

  const columns = [
    {
      dataField: 'label',
      text: 'Titre',
    },
    {
      dataField: 'formule_type.title',
      text: 'Type de formule',
    },
    {
      dataField: 'price_ttc_monthly',
      text: 'Prix mensuel',
      formatter: cell => <span>{parseFloat(cell).toFixed(2)} €</span>,
    },
    {
      dataField: 'vat.name',
      text: 'TVA',
    },
    {
      dataField: 'actions',
      text: '',
      formatter: (cell, row) => (
        <>
          <UncontrolledDropdown>
            <DropdownToggle className={"bg-transparent"}>
              <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
            </DropdownToggle>
            <DropdownMenu right className="bordered important-default-colors">
              <DropdownItem onClick={() => { history.push(`/formula/${row.id}`) }}>Voir</DropdownItem>
              <DropdownItem onClick={() => { window.open(`/formula/${row.id}`, '_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
              <DropdownItem onClick={() => { deleteFormula(row.id, history) }}>Supprimer la formule</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      ),
      classes: "text-right"
    }
  ];

  return (
    <div>
      <Row className="mt-3 mb-3">
        <Col lg="4" sm="4">
          <input
            type="text"
            id="formula_query"
            className="form-control"
            placeholder="Rechercher une formule"
            defaultValue={filters.query}
          />
        </Col>
        <Col lg="3" sm="3">
          <Button
            onClick={() => setFilters(prev => ({...prev, query: document.getElementById('formula_query').value}))}
            className="btn-green"
          >
            Rechercher
          </Button>
        </Col>
      </Row>
      <BootstrapTable
        keyField='id'
        columns={columns}
        data={data}
        defaultSortDirection="asc"
        remote={{ pagination: true }}
        onTableChange={onTableChange.bind({setFilters, filters})}
        pagination={paginationFactory({
          page: pagination.current_page,
          totalSize: pagination.total_count,
          sizePerPage: pagination.current_page_size,
          sizePerPageList: [
            { text: "Afficher 10 éléments", value: 10 },
            { text: "Afficher 25 éléments", value: 25 },
            { text: "Afficher 50 éléments", value: 50 },
            { text: "Afficher 75 éléments", value: 75 },
            { text: "Afficher 100 éléments", value: 100 },
          ],
        })}
      />
    </div>
  );
}

export default FormulasTable;