import Subscription from "./subscription";
import RequestStatus from "./requests_status";
import Project from "./project";
import User from "./user";
import Client from "./client";
import RequestPriority from "./requests_priority";
import Lend from "./lend";

export enum InterventionFolderTypesEnum {
  SAV = "sav",
  MAINTENANCE = "maintenance",
}

export default interface InterventionFolder{
  id?:number|null,
  number?: string|null,
  intervention_at?: Date|null,
  zip_code?: string|null,
  address?: string|null,
  installation_type?: string|null,
  client_phone?: string|null,
  maintenance_contract?: Subscription|null ,
  description?: string|null,
  comment?: string|null,
  disponibility?: string|null,
  intervention_appointment?: string|null,
  intervention_document?: string|null,
  intervention_folder_type?: string|null,
  client?: Client|null,
  interlocutor?: User|null,
  project?: Project|null,
  priority?: RequestPriority|null,
  status?: RequestStatus|null,
  lend?: Lend|null,
  created_at?:Date|null,
  product_name?: string|null,
  technicians?: User[]|null,
}

export interface InterventionFolderFilter{
  sort_by: string,
  sort_dir: string,
  page: number,
  page_size: number,
  query: string|null,
  status: number[],
  zip_code_query: string,
  installation_type_query: string,
  date_begin: Date|null,
  date_end: Date|null,
  type: string,
  is_processed?: boolean | null,
}