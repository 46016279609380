import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import { Formik, Form } from "formik";

import AsyncAutoComplete2 from "../../../components/Common/AsyncAutoComplete2";
import {
    promiseProjectStatesOptions,
    promiseUsersByQueryOptions
} from "../../../helpers/autocomplete_promise_options";

const InvoicesForm = (props) => {
  const { orderId, handleSubmit } = props;
  return (
    <Formik
      initialValues={props.initValues}
      validationSchema={props.validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        handleSubmit({
          order_id: orderId,
          ...values
        });
      }}
      render={({ values, errors, touched, setFieldValue }) => (
        <Form>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <FormGroup>
                <Label for='payment_state'>État du projet</Label>
                <AsyncAutoComplete2
                  name='project_state_id'
                  id='project_state_id'
                  noOptionsMessage={() => "Aucun état disponible"}
                  placeholder="Sélectionner"
                  loadOptions={() => promiseProjectStatesOptions({blacklist_labels: ['draft']})}
                />
              </FormGroup>
              <FormGroup>
                <Label for='payment_state'>Commercial</Label>
                <AsyncAutoComplete2
                  name='user_id'
                  id='user_id'
                  noOptionsMessage={() => "Aucun commercial disponible"}
                  placeholder="Sélectionner"
                  loadOptions={promiseUsersByQueryOptions}
                />
              </FormGroup>
            </div>
          </div>
          <Row className="justify-content-end mt-4">
            <Col xs="12" className="text-center">
              <Button type="submit" color="primary">
                Générer le projet
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default InvoicesForm;
