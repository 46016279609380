import {
  GET_FINANCERS,
  GET_FINANCERS_SUCCESS,
  GET_FINANCER,
  GET_FINANCER_SUCCESS,
  CREATE_FINANCER,
  CREATE_FINANCER_SUCCESS,
  UPDATE_FINANCER,
  UPDATE_FINANCER_SUCCESS,
  DELETE_FINANCER,
  DELETE_FINANCER_SUCCESS,
  FINANCERS_API_ERROR,
} from "./actionTypes";

export const getFinancers = (query) => {
  return {
    type: GET_FINANCERS,
    payload: { query },
  };
};

export const getFinancersSuccess = (financers) => {
  return {
    type: GET_FINANCERS_SUCCESS,
    payload: financers,
  };
};

export const getFinancer = (id) => {
  return {
    type: GET_FINANCER,
    payload: { id },
  };
};

export const getFinancerSuccess = (financer) => {
  return {
    type: GET_FINANCER_SUCCESS,
    payload: financer,
  };
};

export const createFinancer = (financer, history) => {
  return {
    type: CREATE_FINANCER,
    payload: { financer, history },
  };
};

export const createFinancerSuccess = (financer) => {
  return {
    type: CREATE_FINANCER_SUCCESS,
    payload: financer,
  };
};

export const updateFinancer = (id, financer, history) => {
  return {
    type: UPDATE_FINANCER,
    payload: { id, financer, history },
  };
};

export const updateFinancerSuccess = (financer) => {
  return {
    type: UPDATE_FINANCER_SUCCESS,
    payload: financer,
  };
};

export const deleteFinancer = (id, history) => {
  return {
    type: DELETE_FINANCER,
    payload: { id, history },
  };
};

export const deleteFinancerSuccess = (financer) => {
  return {
    type: DELETE_FINANCER_SUCCESS,
    payload: financer,
  };
};

export const financerApiError = (error) => {
  return {
    type: FINANCERS_API_ERROR,
    payload: error,
  };
};
