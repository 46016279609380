import {
  GET_FORMULAS,
  GET_FORMULAS_SUCCESS,
  GET_FORMULA,
  GET_FORMULA_SUCCESS,
  CREATE_FORMULA,
  CREATE_FORMULA_SUCCESS,
  UPDATE_FORMULA,
  UPDATE_FORMULA_SUCCESS,
  DELETE_FORMULA,
  DELETE_FORMULA_SUCCESS,
  FORMULAS_API_ERROR,
} from "./actionTypes";

const initialState = {
  formulas: [],
  formula: null,
  error: false,
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const formulas = (state = initialState, action) => {
  switch (action.type) {

    case GET_FORMULAS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_FORMULAS_SUCCESS:
      state = {
        ...state,
        formulas: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        loading: false,
        error: false,
      };
      break;

    case GET_FORMULA:
      state = {
        ...state,
        loading: true,
      }
      break;

    case GET_FORMULA_SUCCESS:
      state = {
        ...state,
        formula: action.payload.data,
        loading: false,
        error: false,
      }
      break;

    case CREATE_FORMULA:
      state = {
        ...state,
        loading: true,
      }
      break;

    case CREATE_FORMULA_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false
      }
      break;

    case UPDATE_FORMULA:
      state = {
        ...state,
        loading: true,
      }
      break;

    case UPDATE_FORMULA_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      }
      break;

    case DELETE_FORMULA:
      state = {
        ...state,
        loading: true,
      }
      break;

    case DELETE_FORMULA_SUCCESS:
      state = {
        ...state,
        formulas:
          state.formulas === undefined || state.formulas === null
            ? []
            : state.formulas.filter((e) => e.id !== action.payload.data.id),
        loading: false,
        error: false,
      }
      break;
    case FORMULAS_API_ERROR:
      state = {...state, error: action.payload, loading: false};
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default formulas;
