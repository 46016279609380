import React, { useState } from "react";
import { Row, Col, Button, CardHeader, Label } from "reactstrap";
import { fileConvertSize } from "../../helpers/helper";
import { isImageUrl } from "../../helpers/url_helper";
import FileDownloader from "./FileDownloader";

const FILE_UPLOAD = process.env.REACT_APP_FILE_UPLOAD;

export default function UploadImage({
  files,
  title,
  deleteImageWithIndex = null,
  handleChange = null,
  max_file = 1,
  handleDeleteImages = null,
  handleChangeImages = null,
  field = "",
  setFieldValue = null,
  isInForm = false,
  values = null,
  invalid = null,
  invalidError = null,
}) {
  const [errorSize, setErrorSize] = useState(false);
  const [badSize, setBadSize] = useState(0);

  return (
    <React.Fragment>
      {!isInForm ? (
        <CardHeader className="cardHeader">{title}</CardHeader>
      ) : null}
      <Row>
        <Col>
          {isInForm ? <Label for="fileUp">{title}</Label> : null}
          <Row className="mr-0 ml-0">
            {files.map((imageUrl, index) => {

              const isImage = isImageUrl(imageUrl.url_path);
              const name = imageUrl.url_path.split("/")[imageUrl.url_path.split("/").length - 1];

              return (
                <div className='w-100'>
                  { isImage ? (
                    <div key={index} className="listImageContainer">
                      <a
                        key={index}
                        href={imageUrl.url_path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={imageUrl.url_path}
                          className="listImage"
                          alt=""
                        />
                      </a>
                    </div>
                  ) : (
                    <FileDownloader
                      doc={{
                        url: imageUrl.url_path,
                        name: name
                      }}
                    />
                  )}
                  <div>
                    {!isImage ? '' :
                      <p>
                        {name}
                      </p>}
                    {handleDeleteImages || deleteImageWithIndex ? (
                      <Button
                        className="listImageDeleteIcon"
                        onClick={
                          max_file === 1
                            ? () =>
                              handleDeleteImages(field, setFieldValue, values)
                            : () =>
                              deleteImageWithIndex(
                                index,
                                field,
                                setFieldValue,
                                values
                              )
                        }
                      >
                        <i className="bx bx-trash"></i>
                      </Button>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <input
            type="file"
            name="fileUp"
            onChange={(event) => {
              setErrorSize(false);
              let isValid = true;
              let size = 0;
              if (event.currentTarget.files) {
                if (
                  event.currentTarget.files &&
                  Array.from(event.currentTarget.files).length > 0
                ) {
                  Array.from(event.currentTarget.files).forEach((file) => {
                    if (file.size > FILE_UPLOAD) {
                      isValid = false;
                      size = file.size;
                    }
                  });
                }
              }

              if (isValid) {
                max_file === 1
                  ? handleChangeImages(
                      event.target.files,
                      field,
                      setFieldValue,
                      values
                    )
                  : handleChange(event, field, setFieldValue, values);
                event.target.value = null;
              } else {
                setErrorSize(true);
                setBadSize(size);
              }
            }}
            disabled={
              max_file === 1 ? false : files.length >= max_file ? true : false
            }
          />
          {/* {files.length >= max_file ? (
            <p>
              Vous ne pouvez ajouter que {max_file} image
              {max_file === 1 ? '' : 's'} au maximum
            </p>
          ) : null} */}
          {errorSize ? (
            <p className="text-danger">
              La taille des fichiers ne doit pas dépasser 2 Mo. Le fichier que
              vous avez essayer d'insérer à un taille de{" "}
              {fileConvertSize(badSize)}
            </p>
          ) : null}
          {invalid ? <p className="text-danger">{invalidError}</p> : null}
        </Col>
      </Row>
    </React.Fragment>
  );
}
