import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_QUOTES,
  GET_QUOTE,
  CREATE_QUOTE,
  UPDATE_QUOTE,
  DELETE_QUOTE,
  GENERATE_QUOTE_ZIP,
  SHOW_QUOTE,
  SEND_QUOTE_EMAILS,
  MIGRATE_ORDER_FROM_QUOTE,
} from "./actionTypes";
import {
  getQuotesSuccess,
  getQuoteSuccess,
  createQuoteSuccess,
  updateQuoteSuccess,
  deleteQuoteSuccess,
  generateQuoteZipSuccess,
  showQuoteSuccess,
  quotesApiError,
  sendQuoteEmailsSuccess,
  migrateOrderFromQuoteSuccess,
} from "./actions";

import { quotes, orders, projects } from "../../api";

function* getQuotes({ payload: { query } }) {
  try {
    const response = yield call(quotes.getAll, query);
    yield put(getQuotesSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        quotesApiError(
          "Vous n'êtes pas autorisé à accéder à la liste des devis"
        )
      );
    } else if (error.response.data !== undefined) {
      yield put(quotesApiError(error.response.data.message));
    } else {
      yield put(quotesApiError(error.message));
    }
  }
}

function* getQuote({ payload: { id } }) {
  try {
    const response = yield call(quotes.getOne, id);
    yield put(getQuoteSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        quotesApiError(
          "Vous n'êtes pas autorisé à accéder aux informations de ce devis"
        )
      );
    } else if (error.response.status === 404) {
      yield put(quotesApiError("Le devis n'a pas été trouvé"));
    } else if (error.response.data !== undefined) {
      yield put(quotesApiError(error.response.data.message));
    } else {
      yield put(quotesApiError(error.message));
    }
  }
}

function* createQuote({ payload: { order, history } }) {
  try {
    const responseOrder = yield call(orders.create, order);
    const response = yield call(
      orders.getGenerateQuote,
      responseOrder.data.id,
      order
    );
    yield put(createQuoteSuccess(response));
    window.open(
      process.env.REACT_APP_API_URL + "/files/" + response.data.file_path + `?filename=${response.data.order.quote.number}.pdf`
    );
    history.push("/quotes");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          quotesApiError("Vous n'êtes pas autorisé à ajouter un devis")
        );
      } else if (error.response.data !== undefined) {
        yield put(quotesApiError(error.response.data.message));
      }
    } else {
      yield put(quotesApiError(error.message));
    }
  }
}

function* updateQuote({ payload: { id, order, history } }) {
  try {
    const responseOrder = yield call(orders.update, id, order);
    const response = yield call(
      orders.getGenerateQuote,
      responseOrder.data.id,
      order
    );
    yield put(updateQuoteSuccess(response));
    history.push("/quotes");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          quotesApiError("Vous n'êtes pas autorisé à modifier ce devis")
        );
      } else if (error.response.status === 404) {
        yield put(quotesApiError("Le devis n'a pas été trouvé"));
      } else if (error.response.data !== undefined) {
        yield put(quotesApiError(error.response.data.message));
      }
    } else {
      yield put(quotesApiError(error.message));
    }
  }
}

function* deleteQuote({ payload: { id, history } }) {
  try {
    const response = yield call(quotes.delete, id);
    yield put(deleteQuoteSuccess(response));
    history.push("/quotes");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        quotesApiError("Vous n'êtes pas autorisé à supprimer ce devis")
      );
    } else if (error.response.status === 404) {
      yield put(quotesApiError("Le devis n'a pas été trouvé"));
    } else if (error.response.data !== undefined) {
      yield put(quotesApiError(error.response.data.message));
    } else {
      yield put(quotesApiError(error.message));
    }
  }
}

function* generateQuoteZip({ payload: { list } }) {
  try {
    const response = yield call(quotes.generate_quote_zip, list);

    const fileName = "Devis.zip";
    const blob = new Blob([response.data], {
      type: "application/zip",
    });

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display:none";
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
    yield put(generateQuoteZipSuccess(response));
  } catch (error) {
    if (error.response.data !== undefined) {
      yield put(quotesApiError(error.response.data.message));
    } else {
      yield put(quotesApiError(error.message));
    }
  }
}

function* sendQuoteEmails({ payload: { list, query } }) {
  try {
    const response = yield call(quotes.send_quote_emails, list);

    yield put(sendQuoteEmailsSuccess(response));

    const responseQuotes = yield call(quotes.getAll, query);

    yield put(getQuotesSuccess(responseQuotes));
  } catch (error) {
    if (error.response.data !== undefined) {
      yield put(quotesApiError(error.response.data.message));
    } else {
      yield put(quotesApiError(error.message));
    }
  }
}

function* showQuote({ payload: { order, isUpdate } }) {
  try {
    if (isUpdate) {
      const responseOrder = yield call(orders.update, isUpdate.id, order);
      const response = yield call(
        orders.getGenerateQuote,
        responseOrder.data.id,
        order
      );
      yield put(showQuoteSuccess(response));
      window.open(
        process.env.REACT_APP_API_URL + "/files/" + response.data.file_path
      );
    } else {
      const responseOrder = yield call(orders.create, order);
      const response = yield call(
        orders.getGenerateQuote,
        responseOrder.data.id,
        order
      );
      yield put(showQuoteSuccess(response));
      window.open(
        process.env.REACT_APP_API_URL + "/files/" + response.data.file_path
      );
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          quotesApiError("Vous n'êtes pas autorisé prévisualiser un devis")
        );
      } else if (error.response.data !== undefined) {
        yield put(quotesApiError(error.response.data.message));
      }
    } else {
      yield put(quotesApiError(error.message));
    }
  }
}

function* migrateOrderFromQuote({ payload: { values } }) {
  try {
    const response = yield call(projects.migrateOrder, values);
    yield put(migrateOrderFromQuoteSuccess(response));
  } catch (error) {
    console.log(error)
    if (error.response.status === 401) {
      yield put(
        quotesApiError(
          "Vous n'êtes pas autorisé à accéder aux informations de ce projet."
        )
      );
    } else if (error.response.status === 404) {
      yield put(quotesApiError("Le projet n'a pas été trouvé"));
    } else if (error.response.status !== undefined) {
      yield put(quotesApiError(error.response.data.message));
    } else {
      yield put(quotesApiError(error.message));
    }
  }
}

export function* watchGetQuotes() {
  yield takeEvery(GET_QUOTES, getQuotes);
}

export function* watchGetQuote() {
  yield takeEvery(GET_QUOTE, getQuote);
}

export function* watchCreateQuote() {
  yield takeEvery(CREATE_QUOTE, createQuote);
}

export function* watchUpdateQuote() {
  yield takeEvery(UPDATE_QUOTE, updateQuote);
}

export function* watchDeleteQuote() {
  yield takeEvery(DELETE_QUOTE, deleteQuote);
}

export function* watchGenerateQuoteZip() {
  yield takeEvery(GENERATE_QUOTE_ZIP, generateQuoteZip);
}

export function* watchSendQuoteEmails() {
  yield takeEvery(SEND_QUOTE_EMAILS, sendQuoteEmails);
}

export function* watchShowQuote() {
  yield takeEvery(SHOW_QUOTE, showQuote);
}
export function* watchMigrateOrderFromQuote() {
  yield takeEvery(MIGRATE_ORDER_FROM_QUOTE, migrateOrderFromQuote);
}

function* quotesSaga() {
  yield all([
    fork(watchGetQuotes),
    fork(watchGetQuote),
    fork(watchCreateQuote),
    fork(watchUpdateQuote),
    fork(watchDeleteQuote),
    fork(watchGenerateQuoteZip),
    fork(watchSendQuoteEmails),
    fork(watchShowQuote),
    fork(watchMigrateOrderFromQuote),
  ]);
}

export default quotesSaga;
