import {takeEvery, fork, put, all, call} from "redux-saga/effects";

// Login Redux States
import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTION,
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_VALIDATED,
  DELETE_SUBSCRIPTION,
} from "./actionTypes";

import {
  getSubscriptionsSuccess,
  getSubscriptionSuccess,
  createSubscriptionSuccess,
  updateSubscriptionSuccess,
  updateSubscriptionValidatedSuccess,
  deleteSubscriptionSuccess,
  subscriptionApiError
} from './actions'

import {subscriptions} from "../../api";
import {addMessage} from "../flash_messages/actions";
import { parseQuery } from "../../helpers/helper";

const UNAUTHORIZED_ERROR = 401;
const NOT_FOUND_ERROR = 404;

const UNAUTHORIZED_MESSAGE = "Vous n'êtes pas autorisé à accéder aux informations des abonnements";
const NOT_FOUND_MESSAGE = "Données non trouvées";
const BASE_ERROR_MESSAGE = "Erreur lors de l'envoi d'informations";

function computeError(error) {
  if (error.response.status === UNAUTHORIZED_ERROR) {
    put(addMessage(UNAUTHORIZED_ERROR));
    return put(subscriptionApiError(UNAUTHORIZED_MESSAGE));
  } else if (error.response.status === NOT_FOUND_ERROR) {
    put(addMessage(NOT_FOUND_ERROR));
    return put(subscriptionApiError(NOT_FOUND_MESSAGE));
  } else if (error.response.data !== undefined) {
    put(addMessage(error.response.data.message || BASE_ERROR_MESSAGE));
    return put(subscriptionApiError(error.response.data.message || BASE_ERROR_MESSAGE));
  } else {
    put(addMessage(BASE_ERROR_MESSAGE));
    return put(subscriptionApiError(BASE_ERROR_MESSAGE));
  }
}

function* getSubscriptions({payload: {query}}) {
  try {
    const response = yield call(subscriptions.getAll, query);
    yield put(getSubscriptionsSuccess(response));
  } catch (error) {
    yield computeError(error);
  }
}

function* getSubscription({payload: {id}}) {
  try {
    const response = yield call(subscriptions.getOne, id);
    yield put(getSubscriptionSuccess(response));
  } catch (error) {
    yield computeError(error);
  }
}

function* createSubscription({payload: {subscription, history}}) {
  try {
    const response = yield call(subscriptions.create, subscription);
    yield put(createSubscriptionSuccess(response));
    yield put(addMessage("Abonnement créé"));
    const query = parseQuery();
    if (query.new_tab) {
      window.close();
    } else {
      history.push('/subscriptions');
    }
  } catch (error) {
    yield computeError(error);
  }
}

function* updateSubscription({payload: {id, subscription, history}}) {
  try {
    const response = yield call(subscriptions.update, id, subscription);
    yield put(updateSubscriptionSuccess(response));
    yield put(addMessage("Abonnement modifié"));
    history.push('/subscriptions');
  } catch (error) {
    yield computeError(error);
  }
}

function* updateValidatedSubscription({payload: {id, subscription, history}}) {
  try {
    const response = yield call(subscriptions.updateValidated, id, subscription);
    yield put(updateSubscriptionValidatedSuccess(response));
    yield put(addMessage("Abonnement modifié"));
    history.push('/subscriptions');
  } catch (error) {
    yield computeError(error);
  }
}



function* deleteSubscription({payload: {id, history}}) {
  try {
    const response = yield call(subscriptions.delete, id);
    yield put(deleteSubscriptionSuccess(response));
    yield put(addMessage("Abonnement modifié"));
    history.push('/subscriptions');
  } catch (error) {
    yield computeError(error);
  }
}


export function* watchGetSubscriptions() {
  yield takeEvery(GET_SUBSCRIPTIONS, getSubscriptions);
}

export function* watchGetSubscription() {
  yield takeEvery(GET_SUBSCRIPTION, getSubscription);
}

export function* watchCreateSubscription() {
  yield takeEvery(CREATE_SUBSCRIPTION, createSubscription);
}

export function* watchUpdateSubscription() {
  yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscription);
}

export function* watchUpdateValidatedSubscription() {
  yield takeEvery(UPDATE_SUBSCRIPTION_VALIDATED, updateValidatedSubscription);
}

export function* watchDeleteSubscription() {
  yield takeEvery(DELETE_SUBSCRIPTION, deleteSubscription);
}

function* subscriptionsSaga() {
  yield all([
    fork(watchGetSubscriptions),
    fork(watchGetSubscription),
    fork(watchCreateSubscription),
    fork(watchUpdateSubscription),
    fork(watchUpdateValidatedSubscription),
    fork(watchDeleteSubscription)
  ]);
}

export default subscriptionsSaga;