import {
  GET_SUB_CATEGORIES,
  GET_SUB_CATEGORIES_SUCCESS,
  SUB_CATEGORIES_API_ERROR,
} from "./actionTypes";

export const getSubCategories = (query) => {
  return {
    type: GET_SUB_CATEGORIES,
    payload: { query },
  };
};

export const getSubCategoriesSuccess = (sub_categories) => {
  return {
    type: GET_SUB_CATEGORIES_SUCCESS,
    payload: sub_categories,
  };
};

export const subCategoryApiError = (error) => {
  return {
    type: SUB_CATEGORIES_API_ERROR,
    payload: error,
  };
};
