import React, { useState } from "react";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikAutocomplete from "../../../components/Common/Autocomplete";
import FormikAutocomplete2 from "../../../components/Common/AsyncAutoComplete2";
import UploadImage from "../../../components/Common/UploadImage";
import { getBase64 } from "../../../helpers/helper";
import { promiseProductsOptions } from "../../../helpers/autocomplete_promise_options";
import { getAssetsDistUrl } from "../../../helpers/url_helper";

const ProductForm = (props) => {
  const [imagesList, setImagesList] = useState([]);

  const handleChangeImages = async (files, field, setFieldValue, values) => {
    setFieldValue(field, files[0]);
    let images = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let content = await getBase64(file);
      images.push({
        url_path: content,
        name: file.name,
      });
    }
    setImagesList(images);
  };

  const getPriceHtFromTtc = (newPriceTtc, values, setFieldValue) => {
    if (values.vat !== "") {
      let vat = props.vats.filter((i) => i.id === values.vat)[0];
      let p = newPriceTtc / (1 + vat.value / 100);
      setFieldValue("price", parseFloat(p).toFixed(2));
    }
  };

  const getPriceTtcFromHt = (newPriceHt, values, setFieldValue) => {
    if (values.vat !== "") {
      let vat = props.vats.filter((i) => i.id === values.vat)[0];
      let p = newPriceHt * (1 + vat.value / 100);
      setFieldValue("price_ttc", parseFloat(p).toFixed(2));
    }
  };

  const getPromotionalOfferHtFromTtc = (
    newPromoOfferTtc,
    values,
    setFieldValue
  ) => {
    if (values.vat !== "") {
      let vat = props.vats.filter((i) => i.id === values.vat)[0];
      let p = newPromoOfferTtc / (1 + vat.value / 100);
      setFieldValue("promotional_offer", parseFloat(p).toFixed(2));
    }
  };

  const getPromotionalOfferTtcFromHt = (
    newPromoOfferHt,
    values,
    setFieldValue
  ) => {
    if (values.vat !== "") {
      let vat = props.vats.filter((i) => i.id === values.vat)[0];
      let p = newPromoOfferHt * (1 + vat.value / 100);
      setFieldValue("promotional_offer_ttc", parseFloat(p).toFixed(2));
    }
  };

  const getPriceHtAndTtcFromVat = (newVatValue, values, setFieldValue) => {
    let vat = props.vats.filter((i) => i.id === newVatValue.value)[0];
    if (values.price !== "") {
      let pTtc = values.price * (1 + vat.value / 100);
      setFieldValue("price_ttc", parseFloat(pTtc).toFixed(2));
    }
    if (values.price === "" && values.price_ttc !== "") {
      let pHt = values.price_ttc / (1 + vat.value / 100);
      setFieldValue("price", parseFloat(pHt).toFixed(2));
    }
  };

  const getPromotionalOfferHtAndTtcFromVat = (
    newVatValue,
    values,
    setFieldValue
  ) => {
    let vat = props.vats.filter((i) => i.id === newVatValue.value)[0];
    if (values.promotional_offer !== "") {
      let pTtc = values.promotional_offer * (1 + vat.value / 100);
      setFieldValue("promotional_offer_ttc", parseFloat(pTtc).toFixed(2));
    }
    if (
      values.promotional_offer === "" &&
      values.promotional_offer_ttc !== ""
    ) {
      let pHt = values.promotional_offer_ttc / (1 + vat.value / 100);
      setFieldValue("promotional_offer", parseFloat(pHt).toFixed(2));
    }
  };

  const updateTextareaHeight = (e) => {
    e.currentTarget.style.height = "auto";
    e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
  };

  return (
    <Formik
      initialValues={props.initValues}
      validationSchema={props.validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        props.handleSubmit(values);
      }}
      render={({ errors, touched, setFieldValue, values }) => (
        <Form>
          <Row className="mb-5">
            <Col>
              <UploadImage
                files={
                  imagesList.length > 0
                    ? imagesList
                    : props.product.image === "" ||
                      props.product.image === undefined
                    ? []
                    : [
                        {
                          url_path:
                            props.product.image && getAssetsDistUrl(props.product.image),
                        },
                      ]
                }
                title="Image du produit"
                handleChangeImages={handleChangeImages}
                max_file={1}
                field="image"
                setFieldValue={setFieldValue}
                invalid={errors.image && !!touched.image}
                invalidError={errors.image}
                isInForm={true}
              />
              <ErrorMessage name="image" component={FormFeedback} />
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <Row className="mb-3">
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="description"
                      className="col-form-label col-lg-12"
                    >
                      Description {props.isUpdate ? null : "*"}
                    </Label>
                    <Col lg="12">
                      <Input
                        id="description"
                        name="description"
                        type="textarea"
                        component="textarea"
                        rows="1"
                        tag={Field}
                        invalid={errors.description && !!touched.description}
                        className="form-control"
                        placeholder="Ajouter une description"
                        onFocus={(e) => updateTextareaHeight(e)}
                        onInput={(e) => updateTextareaHeight(e)}
                      />
                      <ErrorMessage
                        name="description"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="category"
                      className="col-form-label col-lg-12"
                    >
                      Famille {props.isUpdate ? null : "*"}
                    </Label>
                    <Col lg="12">
                      <FormikAutocomplete
                        name="category"
                        id="category"
                        invalid={errors.category && !!touched.category}
                        className={errors.category ? "selectorError" : null}
                        options={props.categories.map((item) => {
                          return {
                            label: `${item.name}`,
                            value: item.id,
                          };
                        })}
                        onChange={(value) => {
                          setFieldValue("sub_category", "");
                        }}
                      />
                      <ErrorMessage
                        className={errors.category ? "errorMessages" : null}
                        name="category"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="sub_category"
                      className="col-form-label col-lg-12"
                    >
                      Catégorie
                    </Label>
                    <Col lg="12">
                      {values.category === "" ? (
                        <p>Veuillez d'abord selectionner une famille</p>
                      ) : (
                        <FormikAutocomplete
                          name="sub_category"
                          id="sub_category"
                          value={
                            values.sub_category !== ""
                              ? null
                              : values.sub_category
                          }
                          invalid={
                            errors.sub_category && !!touched.sub_category
                          }
                          className={
                            errors.sub_category ? "selectorError" : null
                          }
                          options={props.categories
                            .filter((cat) => cat.id === values.category)[0]
                            ?.sub_categories.map((item) => {
                              return {
                                label: `${item.name}`,
                                value: item.id,
                              };
                            })}
                        />
                      )}
                      <ErrorMessage
                        className={errors.sub_category ? "errorMessages" : null}
                        name="sub_category"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="commercial_name"
                      className="col-form-label col-lg-12"
                    >
                      Nom commercial {props.isUpdate ? null : "*"}
                    </Label>
                    <Col lg="12">
                      <Input
                        id="commercial_name"
                        name="commercial_name"
                        type="text"
                        tag={Field}
                        invalid={
                          errors.commercial_name && !!touched.commercial_name
                        }
                        className="form-control"
                        placeholder="Entrer un nom commercial"
                      />
                      <ErrorMessage
                        name="commercial_name"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="brand" className="col-form-label col-lg-12">
                      Marque {props.isUpdate ? null : "*"}
                    </Label>
                    <Col lg="12">
                      <Input
                        id="brand"
                        name="brand"
                        type="text"
                        tag={Field}
                        invalid={errors.brand && !!touched.brand}
                        className="form-control"
                        placeholder="Entrer une marque"
                      />
                      <ErrorMessage name="brand" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="reference"
                      className="col-form-label col-lg-12"
                    >
                      Référence {props.isUpdate ? null : "*"}
                    </Label>
                    <Col lg="12">
                      <Input
                        id="reference"
                        name="reference"
                        type="text"
                        tag={Field}
                        invalid={errors.reference && !!touched.reference}
                        className="form-control"
                        placeholder="Entrer une référence"
                      />
                      <ErrorMessage name="reference" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="price" className="col-form-label col-lg-12">
                      Prix {props.isUpdate ? null : "*"}
                    </Label>
                    <Col lg="12">
                      <Input
                        id="price"
                        name="price"
                        type="text"
                        tag={Field}
                        invalid={errors.price && !!touched.price}
                        className="form-control"
                        placeholder="Entrer un prix HT"
                        onChange={(event) => {
                          setFieldValue("price", event.currentTarget.value);
                          if (
                            event.currentTarget &&
                            event.currentTarget.value !== ""
                          ) {
                            getPriceTtcFromHt(
                              event.currentTarget.value,
                              values,
                              setFieldValue
                            );
                          }
                        }}
                      />
                      <ErrorMessage name="price" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="vat" className="col-form-label col-lg-12">
                      TVA {props.isUpdate ? null : "*"}
                    </Label>
                    <Col lg="12">
                      <FormikAutocomplete
                        name="vat"
                        id="vat"
                        invalid={errors.vat && !!touched.vat}
                        className={errors.vat ? "selectorError" : null}
                        options={props.vats.map((item) => {
                          return {
                            label: `${item.name}`,
                            value: item.id,
                          };
                        })}
                        onChange={(value) => {
                          if (value) {
                            getPriceHtAndTtcFromVat(
                              value,
                              values,
                              setFieldValue
                            );
                            getPromotionalOfferHtAndTtcFromVat(
                              value,
                              values,
                              setFieldValue
                            );
                          }
                        }}
                      />
                      <ErrorMessage
                        className={errors.vat ? "errorMessages" : null}
                        name="vat"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="price_ttc"
                      className="col-form-label col-lg-12"
                    >
                      Prix TTC {props.isUpdate ? null : "*"}
                    </Label>
                    <Col lg="12">
                      <Input
                        id="price_ttc"
                        name="price_ttc"
                        type="text"
                        tag={Field}
                        invalid={errors.price_ttc && !!touched.price_ttc}
                        className="form-control"
                        placeholder="Entrer un prix TTC"
                        onChange={(event) => {
                          setFieldValue("price_ttc", event.currentTarget.value);
                          if (
                            event.currentTarget &&
                            event.currentTarget.value !== ""
                          ) {
                            getPriceHtFromTtc(
                              event.currentTarget.value,
                              values,
                              setFieldValue
                            );
                          }
                        }}
                      />
                      <ErrorMessage name="price_ttc" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="eco_tax"
                      className="col-form-label col-lg-12"
                    >
                      Eco taxe
                    </Label>
                    <Col lg="12">
                      <Input
                        id="eco_tax"
                        name="eco_tax"
                        type="text"
                        tag={Field}
                        invalid={errors.eco_tax && !!touched.eco_tax}
                        className="form-control"
                        placeholder="Entrer une eco taxe"
                      />
                      <ErrorMessage name="eco_tax" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="promotional_offer"
                      className="col-form-label col-lg-12"
                    >
                      Offre promotionnelle
                    </Label>
                    <Col lg="12">
                      <Input
                        id="promotional_offer"
                        name="promotional_offer"
                        type="text"
                        tag={Field}
                        invalid={
                          errors.promotional_offer &&
                          !!touched.promotional_offer
                        }
                        className="form-control"
                        placeholder="Entrer une offre promotionnelle HT"
                        onChange={(event) => {
                          setFieldValue(
                            "promotional_offer",
                            event.currentTarget.value
                          );
                          if (
                            event.currentTarget &&
                            event.currentTarget.value !== ""
                          ) {
                            getPromotionalOfferTtcFromHt(
                              event.currentTarget.value,
                              values,
                              setFieldValue
                            );
                          }
                        }}
                      />
                      <ErrorMessage
                        name="promotional_offer"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="promotional_offer_ttc"
                      className="col-form-label col-lg-12"
                    >
                      Offre promotionnelle TTC
                    </Label>
                    <Col lg="12">
                      <Input
                        id="promotional_offer_ttc"
                        name="promotional_offer_ttc"
                        type="text"
                        tag={Field}
                        invalid={
                          errors.promotional_offer_ttc &&
                          !!touched.promotional_offer_ttc
                        }
                        className="form-control"
                        placeholder="Entrer une offre promotionnelle TTC"
                        onChange={(event) => {
                          setFieldValue(
                            "promotional_offer_ttc",
                            event.currentTarget.value
                          );
                          if (
                            event.currentTarget &&
                            event.currentTarget.value !== ""
                          ) {
                            getPromotionalOfferHtFromTtc(
                              event.currentTarget.value,
                              values,
                              setFieldValue
                            );
                          }
                        }}
                      />
                      <ErrorMessage
                        name="promotional_offer_ttc"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="title" className="col-form-label col-lg-12">
                      Titre (devis)
                    </Label>
                    <Col lg="12">
                      <Input
                        id="title"
                        name="title"
                        type="text"
                        tag={Field}
                        invalid={errors.title && !!touched.title}
                        className="form-control"
                        placeholder="Entrer un titre"
                      />
                      <ErrorMessage name="title" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="quote_description"
                      className="col-form-label col-lg-12"
                    >
                      Description pour les devis et factures
                    </Label>
                    <Col lg="12">
                      <Input
                        id="quote_description"
                        name="quote_description"
                        type="textarea"
                        component="textarea"
                        rows="1"
                        tag={Field}
                        invalid={
                          errors.quote_description &&
                          !!touched.quote_description
                        }
                        className="form-control"
                        placeholder="Ajouter une description pour les devis et factures"
                        onFocus={(e) => updateTextareaHeight(e)}
                        onInput={(e) => updateTextareaHeight(e)}
                      />
                      <ErrorMessage
                        name="quote_description"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
              <Col lg="3">
                <FormGroup className="mb-4">
                  <Label htmlFor="has_warm_water" className="col-form-label">
                    A t-il un ECS ?
                  </Label>
                  <Col
                      xs="12"
                      className="custom-control-with-margin custom-control custom-switch"
                    >
                      <Input
                        id="has_warm_water"
                        name="has_warm_water"
                        type="checkbox"
                        tag={Field}
                        className="custom-control-input ml-3"
                      />
                      <Label
                        for="has_warm_water"
                        className="custom-control-label"
                      >
                        {values.has_warm_water
                          ? "Oui"
                          : "Non"}
                      </Label>
                  </Col>
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup className="mb-4" row>
                  <Label htmlFor="phase" className="col-form-label col-lg-12">
                    Phase {props.isUpdate ? null : "*"}
                  </Label>
                  <Col lg="12">
                    <FormikAutocomplete
                      name="phase"
                      id="phase"
                      invalid={errors.phase && !!touched.phase}
                      className={errors.phase ? "selectorError" : null}
                      options={[
                        { label: "Monophasé", value: "Monophasé" },
                        { label: "Triphasé", value: "Triphasé" },
                      ]}
                    />
                    <ErrorMessage
                      className={errors.vat ? "errorMessages" : null}
                      name="phase"
                      component={FormFeedback}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup className="mb-4" row>
                  <Label htmlFor="product_ids" className="col-form-label col-lg-12">
                    Main d'œuvres
                  </Label>
                  <Col xs="12">
                    <FormikAutocomplete2
                      className="w-100"
                      id="workforces_ids"
                      name="workforces_ids"
                      isSearchable={true}
                      multiple={true}
                      noOptionsMessage={() => "Aucun main d'œuvre disponible"}
                      placeholder="Choisir un main d'œuvre..."
                      cacheOptions
                      defaultOptions
                      loadOptions={(inputValue) => promiseProductsOptions(inputValue, true)}
                    />
                    <ErrorMessage
                      className={errors.workforces_ids ? "errorMessages" : null}
                      name="workforces_ids"
                      component={FormFeedback}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          <Row>
            <Col lg="12 text-center">
              <Button type="submit" color="primary">
                {props.isUpdate ? "Modifier le produit" : "Créer le produit"}
              </Button>
            </Col>
            <Col lg="12 text-center">
              {props.error && props.error ? (
                <Alert color="danger" className="d-inline-block">
                  {props.error}
                </Alert>
              ) : null}
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default ProductForm;
