import {
  ADD_MESSAGE,
  REMOVE_MESSAGE
} from "./actionTypes";

/**
 * @deprecated use FlashMessage instead @see {FlashMessage}
 */
export const addMessage = (message, importance) => {
  return {
    type: ADD_MESSAGE,
    payload: {message, importance},
  };
};

/**
 * @deprecated use FlashMessage instead @see {FlashMessage}
 */
export const removeMessage = () => {
  return {
    type: REMOVE_MESSAGE,
    payload: {},
  };
};

