import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  PRODUCTS_API_ERROR,
} from "./actionTypes";

export const getProducts = (query) => {
  return {
    type: GET_PRODUCTS,
    payload: { query },
  };
};

export const getProductsSuccess = (products) => {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload: products,
  };
};

export const getProduct = (id) => {
  return {
    type: GET_PRODUCT,
    payload: { id },
  };
};

export const getProductSuccess = (product) => {
  return {
    type: GET_PRODUCT_SUCCESS,
    payload: product,
  };
};

export const createProduct = (product, history) => {
  return {
    type: CREATE_PRODUCT,
    payload: { product, history },
  };
};

export const createProductSuccess = (product) => {
  return {
    type: CREATE_PRODUCT_SUCCESS,
    payload: product,
  };
};

export const updateProduct = (id, product, history) => {
  return {
    type: UPDATE_PRODUCT,
    payload: { id, product, history },
  };
};

export const updateProductSuccess = (product) => {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
    payload: product,
  };
};

export const deleteProduct = (id, history) => {
  return {
    type: DELETE_PRODUCT,
    payload: { id, history },
  };
};

export const deleteProductSuccess = (product) => {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: product,
  };
};

export const productApiError = (error) => {
  return {
    type: PRODUCTS_API_ERROR,
    payload: error,
  };
};
