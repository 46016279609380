export const GET_AGENDA_ITEMS = "GET_AGENDA_ITEMS";
export const GET_AGENDA_ITEMS_SUCCESS = "GET_AGENDA_ITEMS_SUCCESS";

export const GET_AGENDA_ITEM = "GET_AGENDA_ITEM";
export const GET_AGENDA_ITEM_SUCCESS = "GET_AGENDA_ITEM_SUCCESS";

export const CREATE_AGENDA_ITEM = "CREATE_AGENDA_ITEM";
export const CREATE_AGENDA_ITEM_SUCCESS = "CREATE_AGENDA_ITEM_SUCCESS";

export const UPDATE_AGENDA_ITEM = "UPDATE_AGENDA_ITEM";
export const UPDATE_AGENDA_ITEM_SUCCESS = "UPDATE_AGENDA_ITEM_SUCCESS";

export const DELETE_AGENDA_ITEM = "DELETE_AGENDA_ITEM";
export const DELETE_AGENDA_ITEM_SUCCESS = "DELETE_AGENDA_ITEM_SUCCESS";

export const AGENDA_ITEMS_API_ERROR = "AGENDA_ITEMS_API_ERROR";
