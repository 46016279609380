import {History} from "history";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row
} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import FlashMessage, {addFlashMessage, ImportantEnum} from "../../../../components/Common/FlashMessage";
import React, {useCallback, useEffect, useState} from "react";
import Client from "../../../../models/client";
import {match} from 'react-router';
import {V2} from "../../../../api";
import GlobalLoader from "../../../../components/Common/GlobalLoader";
import FormikAutocomplete from "../../../../components/Common/AsyncAutoComplete2";
import {Formik} from "formik";
import moment from "moment";
import {
  promiseProjectsCustomOptions, promiseRequestPriorityOptions, promiseRequestStatusOptions,
  promiseSubscriptionsOptions,
  promiseUsersOptions
} from "../../../../helpers/autocomplete_promise_options_api_v2";
import User, { UserRoleEnum } from "../../../../models/user";
import InterventionFolder, { InterventionFolderTypesEnum } from "../../../../models/intervention_folder";
import LoaderLayoutPulse from "../../../../components/Common/LoaderLayoutPulse";
import { parseCustomerName } from "../../../../helpers/customer_helper";

interface RequestCustomerInterventionFormProps {
  match: match<{ id: string, type:string, interventionId:string }>,
  history: History
}

export default function RequestCustomerInterventionForm({
  match,
  history
}: RequestCustomerInterventionFormProps){
  let isNew = !match.params.interventionId; // cond to force return boolean
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState<Client|null>(null);
  const [user, setUser] = useState<User|null>(null);
  const [intervention, setIntervention] = useState<InterventionFolder|null>(null);
  // @ts-ignore
  const projectHandle = useCallback(() => promiseProjectsCustomOptions(client?.id), [client])
  // @ts-ignore
  const subscriptionHandle = useCallback(() => promiseSubscriptionsOptions(client?.id), [client])

  useEffect(() => {
    V2.customers.get(parseInt(match.params.id),{}).then(({data}) => setClient(data));
    setUser(JSON.parse(localStorage.getItem("authUser")||"{}"));
    if(match.params.interventionId){
      V2.interventions.get(parseInt(match.params.interventionId),{})
        .then(({data}) => setIntervention(data))
        .catch(() => {setIntervention({})});
    } else {
      setIntervention({})
    }
  }, [match.params.id, match.params.interventionId]);

  const enum interventionTypes{
    MAINTENANCE = "maintenance",
    SAV = "sav",
  }

  function validate(values:any){
    let errors: string[] = [];
    if(match.params.type === interventionTypes.SAV && !values.project_id) errors = [...errors, "project_id"];
    if(match.params.type === interventionTypes.SAV && !values.product_name) errors = [...errors, "product_name"];
    if(match.params.type === interventionTypes.MAINTENANCE && !values.maintenance_contract_id) errors = [...errors, "maintenance_contract_id"];
    if(!values.priority_id) errors = [...errors, "priority_id"];
    return errors;
  }

  function submit(values:any){
    let fd:FormData = new FormData();
    fd.append("intervention_at", (new Date()).toString());
    fd.append("client_id", client?.id?.toString() || "");
    fd.append("status_id", values.status_id || "");
    fd.append("zip_code", client?.zip_code?.toString() || "");
    fd.append("address", client?.address?.toString() || "");
    fd.append("priority_id", values.priority_id || "");
    fd.append("client_phone", client?.mobile_phone?.toString() || "");
    fd.append("maintenance_contract_id", values.maintenance_contract_id || "");
    fd.append("description", values.description || "");
    fd.append("comment", values.comment || "");
    fd.append("disponibility", values.disponibility || "");
    fd.append("intervention_appointment", values.intervention_appointment || "");
    if(match.params.type === interventionTypes.SAV)
    fd.append("project_id", values.project_id || "");
    if(match.params.type === interventionTypes.SAV)
    fd.append("product_name", values.product_name || "");
    fd.append("intervention_folder_type", match.params.type || "");
    fd.append("interlocutor_id", user?.id?.toString() || "");
    if(values.file2 && values.file2 instanceof Blob )
    fd.append("intervention_document",  values.file2 || "", `intervention_document.${values.file2 ? values.file2.name.split(values.file2.name.lastIndexOf(".")+1) : ""}`);
    if(match.params.type === interventionTypes.SAV && values.file && values.file instanceof Blob )
      fd.append("lend_attributes[file]",  values.file || "", `lend_file.${values.file ? values.file.name.split(values.file.name.lastIndexOf(".")+1) : ""}`);
    if(match.params.type === interventionTypes.SAV)
    fd.append("lend_attributes[lend_begin_at]", values.lend_begin_at || "");
    if(match.params.type === interventionTypes.SAV)
    fd.append("lend_attributes[lend_end_at]", values.lend_end_at || "");
    if(values.technician_ids && values.technician_ids.length > 0) {
      for (let i = 0; i < values.technician_ids.length; i++) {
        fd.append(`technician_ids[]`, values.technician_ids[i] || null);
      }
    }
    setLoading(true);
    V2.interventions.upsert(intervention?.id, fd,{})
    .then(() => {
      if(isNew){
        addFlashMessage(ImportantEnum.SUCCESS, "Le dossier a correctement été ajoutée");
        history.push("/requests/customers/"+client?.id+"/show?tab=3");
      } else {
        addFlashMessage(ImportantEnum.SUCCESS, "Le dossier a correctement été modifié");
        history.push("/interventions/"+intervention?.id+"/details?tab=1");
      }
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  }

  return(
    <>
      <div className="page-content">
        <Container fluid className="notifications-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={`${isNew ? "Nouveau": "Modifier le"} dossier ${match.params.type === interventionTypes.MAINTENANCE ? "d'entretien":"de sav"}`}
            breadcrumbTitle="Suivi des demandes"
            breadcrumbItem={`${isNew ? "Nouvelle": "Modifier la"} demande`}
          />
          <FlashMessage/>
          {client && intervention?(
            <>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-0">
                        <Row className="pr-2 pl-2 d-flex">
                          <Col>Informations du contact</Col>
                        </Row>
                      </CardTitle>
                      <Row className="pr-2 pl-2 d-flex font-size-15 text-theme-color">
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">Client : </span> <br/>
                          <span className="font-weight-light">{parseCustomerName(client)}</span>
                        </Col>
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">Téléphone : </span> <br/>
                          <span className="font-weight-light">{client.mobile_phone}</span>
                        </Col>
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">Addresse : </span> <br/>
                          <span className="font-weight-light">{client.address}, {client.city}</span>
                        </Col>
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">CP : </span> <br/>
                          <span className="font-weight-light"> {client.zip_code}</span>
                        </Col>
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">Date : </span> <br/>
                          <span className="font-weight-light">{moment(new Date()).format('DD/MM/YYYY')}</span>
                        </Col>
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">Heure : </span> <br/>
                          <span className="font-weight-light">{moment(new Date()).format('HH:mm')}</span>
                        </Col>
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">Interlocuteur : </span> <br/>
                          <span className="font-weight-light">{user?.firstname} {user?.lastname}</span>
                        </Col>
                      </Row>
                      <Row className="font-size-15 text-theme-color">
                        <Formik initialValues={{
                          project_id: intervention.project?.id || "",
                          status_id: intervention.status?.id || "",
                          product_name: intervention.product_name || "",
                          maintenance_contract_id: intervention.maintenance_contract?.id || "",
                          disponibility: intervention.disponibility || "",
                          description: intervention.description || "",
                          comment: intervention.comment || "",
                          priority_id: intervention.priority?.id || "",
                          intervention_appointment: intervention.intervention_appointment ? moment(intervention.intervention_appointment).format("YYYY-MM-DD") : "",
                          lend_begin_at: intervention.lend ? moment(intervention.lend?.lend_begin_at).format("YYYY-MM-DD") : "",
                          lend_end_at: intervention.lend ? moment(intervention.lend?.lend_end_at).format("YYYY-MM-DD") : "",
                          technician_ids: intervention.technicians?.map((tech:any) => tech.id) || [],
                        }} onSubmit={submit} validateOnBlur={true} validateOnMount={true} validate={validate}>
                          {({values, handleSubmit, setFieldValue, errors}:{values:any,errors:string[],handleSubmit:(e?: React.FormEvent<HTMLFormElement>) => void,setFieldValue:(field: string, value: any, shouldValidate?: boolean) => void})=>(
                            <form className={"w-100 d-flex flex-column justify-content-center align-items-center"}>
                              <Row className={"w-100 px-2 pt-4"}>
                                {match.params.type === interventionTypes.SAV ?(
                                  <>
                                    <Col xs={"3"} className={"mb-4"}>
                                      <Label className={"mb-1"}>Projet/dossier rattaché *</Label>
                                      <FormikAutocomplete
                                        loadOptions={projectHandle}
                                        isSearchable={false}
                                        name={"project_id"}
                                        className={"w-100"}
                                        onChangeValue={(k,v)=> {setFieldValue(k, v.split('-|-')[0]);setFieldValue("product_name", v.split('-|-').map(v=>v==="null"?"":v)[1])}}
                                      />
                                    </Col>
                                    <Col xs={"3"} className={"mb-4"}>
                                      <Label className={"mb-1"}>Produit *</Label>
                                      <input type="text" className="form-control" placeholder={"Sélectionnez un projet"} defaultValue={values.product_name||""}
                                             onKeyUp={({currentTarget})=>{setFieldValue('product_name', currentTarget.value)}}
                                      />
                                    </Col>
                                  </>
                                ):null}
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Contrat d'entretien {match.params.type === interventionTypes.MAINTENANCE?"*":""}</Label>
                                  <FormikAutocomplete
                                    loadOptions={subscriptionHandle}
                                    isSearchable={false}
                                    name={"maintenance_contract_id"}
                                    className={"w-100"}
                                  />
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Disponibilités client</Label>
                                  <input type="text" className="form-control" placeholder={"Entrer des disponibilités"} defaultValue={values.disponibility||""}
                                         onKeyUp={({currentTarget})=>{setFieldValue('disponibility', currentTarget.value)}}/>
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Priorité *</Label>
                                  <FormikAutocomplete
                                    loadOptions={promiseRequestPriorityOptions}
                                    isSearchable={false}
                                    name={"priority_id"}
                                    className={"w-100"}
                                  />
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Status</Label>
                                  <FormikAutocomplete
                                    loadOptions={promiseRequestStatusOptions}
                                    isSearchable={false}
                                    name={"status_id"}
                                    className={"w-100"}
                                  />
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Description{match.params.type === InterventionFolderTypesEnum.SAV ? ' (Code erreur) ' : ''}</Label>
                                  <textarea className="form-control" placeholder={"Entrer une description"} defaultValue={values.description||""}
                                         onKeyUp={({currentTarget})=>{setFieldValue('description', currentTarget.value)}} rows={1}/>
                                </Col>
                                <Col xs={"12"} className={""}>
                                  <label>Intervention:</label>
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>RDV d'Intervention</Label>
                                  <input type="date" className="form-control" placeholder={"xx/xx/xxxx"} defaultValue={values.intervention_appointment}
                                         onChange={({currentTarget})=>{setFieldValue('intervention_appointment', currentTarget.value)}}/>
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Commentaire</Label>
                                  <textarea className="form-control" placeholder={"Entrer un commentaire"} defaultValue={values.comment||""}
                                            onKeyUp={({currentTarget})=>{setFieldValue('comment', currentTarget.value)}} rows={1}/>
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Bon d'intervention</Label>
                                  <div className="custom-file">
                                    <input type="file" className="form-control" id={"customInterventionFileInput2"}
                                           onChange={({currentTarget})=>{setFieldValue('file2', currentTarget.files? currentTarget.files[0] :null)}}/>
                                    <label className="custom-file-label" htmlFor="customInterventionFileInput2">{values.file2?values.file2.name:"Choisir un fichier"}</label>
                                  </div>
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Techniciens</Label>
                                  <FormikAutocomplete
                                    loadOptions={(query: string) => promiseUsersOptions({roles: [UserRoleEnum.TECHNICIAN], query})}
                                    loadOptionsByValue={(ids: number[]) => promiseUsersOptions({ids: ids, roles: [UserRoleEnum.TECHNICIAN]})}
                                    isSearchable={true}
                                    multiple={true}
                                    name={"technician_ids"}
                                    className={"w-100"}
                                  />
                                </Col>
                                {match.params.type === interventionTypes.SAV ?(
                                  <>
                                    <Col xs={"12"} className={""}>
                                      <label>Prêt:</label>
                                    </Col>
                                    <Col xs={"3"} className={"mb-4"}>
                                      <Label className={"mb-1"}>Demande de pret</Label>
                                      <div className="custom-file">
                                        <input type="file" className="form-control" id={"customInterventionFileInput"}
                                               onChange={({currentTarget})=>{setFieldValue('file', currentTarget.files? currentTarget.files[0] :null)}}/>
                                        <label className="custom-file-label" htmlFor="customInterventionFileInput">{values.file?values.file.name:"Choisir un fichier"}</label>
                                      </div>
                                    </Col>
                                    <Col xs={"3"} className={"mb-4"}>
                                      <Label className={"mb-1"}>Date de pret</Label>
                                      <input type="date" className="form-control" placeholder={"xx/xx/xxxx"} defaultValue={values.lend_begin_at}
                                             onKeyUp={({currentTarget})=>{setFieldValue('lend_begin_at', currentTarget.value)}}/>
                                    </Col>
                                    <Col xs={"3"} className={"mb-4"}>
                                      <Label className={"mb-1"}>Date de retour</Label>
                                      <input type="date" className="form-control" placeholder={"xx/xx/xxxx"} defaultValue={values.lend_end_at}
                                             onKeyUp={({currentTarget})=>{setFieldValue('lend_end_at', currentTarget.value)}}/>
                                    </Col>
                                  </>
                                ):null}
                              </Row>
                              {
                                loading ? <LoaderLayoutPulse/> : <Button type="button" className={`btn btn-green mt-3 ${errors.length>0?"btn-disabled":""}`} onClick={() => handleSubmit(values as any)}>Valider</Button>
                              }
                            </form>
                          )}
                        </Formik>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ):<GlobalLoader/>}
        </Container>
      </div>
    </>
  );
}