import React from "react";

// Modals components
import {
    Modal,
    ModalHeader,
    ModalBody,
  } from "reactstrap";

  // Customer form 
import InvoicesForm from "../FormComponent/InvoicesForm";
// Validation schema
import {createInvoiceValidationSchema} from "../FormValidationSchemas/createInvoiceValidationSchema";

/**
 * Modal opened when an invoice needs to be created
 * 
 * @param isOpen defined if the modal is open
 * @param toggler function toggling the modal
 * @param handleSubmit handler for the submission of an invoice
 * **/
const QuoteValidationModal = ({
    isOpen,
    toggler,
    handleSubmit,
}) => {

  return (
    <Modal
        isOpen={isOpen}
        toggle={() => {
            toggler();
        }}
    >
        <ModalHeader toggle={() => toggler()} tag="h4">
            Facture de solde
        </ModalHeader>
        <ModalBody>
            <InvoicesForm
                initValues={{
                    invoice_date: "",
                    payment_mode: "",
                }}
                isDeposit={false}
                validationSchema={createInvoiceValidationSchema}
                handleSubmit={handleSubmit}
            />
        </ModalBody>
    </Modal>
  );

}

export default QuoteValidationModal;