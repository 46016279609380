import Client from "../models/client";

export function hasConstructionSiteAddress(customer: Client) : boolean {
  return customer?.address_construction_site &&
    customer?.zip_code_construction_site &&
    customer?.city_construction_site ? true : false;
}

export function isProfessional(customer: Client) : boolean {
  return customer?.client_type === 'Professionnel' ? true : false;
}

export function parseCustomerAddress(customer: Client) : string {
  if (hasConstructionSiteAddress(customer)) {
    return `${customer?.address_construction_site}, ${customer?.zip_code_construction_site} ${customer?.city_construction_site}`;
  } else {
    return `${customer?.address}, ${customer?.zip_code} ${customer?.city}`;
  }
}

export function hasTwoNames(customer: Client) : boolean {
  return customer?.firstname2 && customer?.lastname2 ? true : false;
}

export function parseCustomerFullnames(customer: Client) : string {
  if (hasTwoNames(customer)) {
    return `${customer.civility} ${customer?.firstname} ${customer?.lastname} & ${customer?.lastname2} ${customer?.firstname2}`;
  } else {
    return `${customer.civility} ${customer?.firstname} ${customer?.lastname}`;
  }
}

export function parseCustomerName(customer: Client) : string {
  return isProfessional(customer) ? customer.company_name || '' : parseCustomerFullnames(customer);
}

export function parseCustomerPhone(customer: Client) : string {
  return customer.mobile_phone || customer.mobile_phone2 || customer.fix_phone || '';
}

export function parseCustomerGPSCoord(customer: Client) : { lat: number, lng: number } {
  if (hasConstructionSiteAddress(customer)) {
    return { lat: customer.lat_construction_site!, lng: customer.lon_construction_site! };
  } else {
    return { lat: customer.lat!, lng: customer.lon! };
  }
}

export function validCustomerGPSCoord(customer: Client) : boolean {
  var coord = parseCustomerGPSCoord(customer);
  return coord !== null && coord.lat !== null && coord.lng !== null;
}