import {
  GET_FORMULAS,
  GET_FORMULAS_SUCCESS,
  GET_FORMULA,
  GET_FORMULA_SUCCESS,
  CREATE_FORMULA,
  CREATE_FORMULA_SUCCESS,
  UPDATE_FORMULA,
  UPDATE_FORMULA_SUCCESS,
  DELETE_FORMULA,
  DELETE_FORMULA_SUCCESS,
  FORMULAS_API_ERROR,
} from "./actionTypes";

export const getFormulas = (query) => {
  return {
    type: GET_FORMULAS,
    payload: { query },
  };
};

export const getFormulasSuccess = (formulas) => {
  return {
    type: GET_FORMULAS_SUCCESS,
    payload: formulas,
  };
};

export const getFormula = (id) => {
  return {
    type: GET_FORMULA,
    payload: {id},
  };
};

export const getFormulaSuccess = (formula) => {
  return {
    type: GET_FORMULA_SUCCESS,
    payload: formula,
  };
};

export const createFormula = (formula, history) => {
  return {
    type: CREATE_FORMULA,
    payload: {formula, history},
  };
};

export const createFormulaSuccess = (formula) => {
  return {
    type: CREATE_FORMULA_SUCCESS,
    payload: formula,
  };
};

export const updateFormula = (id, formula, history) => {
  return {
    type: UPDATE_FORMULA,
    payload: {id, formula, history}
  }
}

export const updateFormulaSuccess = (formula) => {
  return {
    type: UPDATE_FORMULA_SUCCESS,
    payload: formula
  }
}

export const deleteFormula = (id, history) => {
  return {
    type: DELETE_FORMULA,
    payload: {id, history}
  }
}

export const deleteFormulaSuccess = (formula) => {
  return {
    type: DELETE_FORMULA_SUCCESS,
    payload: formula
  }
}

export const formulaApiError = (error) => {
  return {
    type: FORMULAS_API_ERROR,
    payload: error,
  };
};
