import { Form, Formik } from 'formik';
import React from "react";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import { promiseBankAccountsOptions } from "../../../../helpers/autocomplete_promise_options_api_v2";
import {CheckDepositFilters} from "../../../../models/check_deposit";
import { FormikAutocompleteUnselectable } from '../../../../components/Common/AsyncAutoComplete2';

interface CheckDepositFilterProps {
  filters: CheckDepositFilters,
  setFilters: React.Dispatch<React.SetStateAction<CheckDepositFilters>>
}

const CheckDepositFilter = ({
  filters,
  setFilters,
}: CheckDepositFilterProps) => {

  // @ts-ignore
  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      onSubmit={(values) => {
        setFilters({
          ...filters,
          ...values
        })
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form>
          <Card className={"shadow-none mb-0"}>
            <CardBody className={"px-0"}>
              <Row>
                <Col xs="12" lg="3" md="4" className="mb-4">
                  <Row>
                    <Col xs="12">
                      <Label for="percent"><b>Date</b></Label>
                    </Col>
                    <Col xs="6" className='mb-4 unova-datepicker'>
                      <Row>
                        <Col xs="3" className={"d-flex align-items-center"}>
                          <Label className={"mb-0"}>Du</Label>
                        </Col>
                        <Col xs="9">
                          <DatePicker
                            className="form-control button-without-style"
                            name="date_begin"
                            selected={values.date_begin?new Date(values.date_begin):null}
                            onChange={v => setFieldValue("date_begin",v)}
                            isClearable={true}
                            placeholderText={'dd/mm/aaaa'}
                            dateFormat={"dd/MM/yyyy"}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="6" className='mb-4 unova-datepicker'>
                      <Row>
                        <Col xs="3" className={"d-flex align-items-center"}>
                          <Label className={"mb-0"}>au</Label>
                        </Col>
                        <Col xs="9">
                          <DatePicker
                            className="form-control button-without-style"
                            name="date_end"
                            selected={values.date_end?new Date(values.date_end):null}
                            onChange={v => setFieldValue("date_end",v)}
                            isClearable={true}
                            placeholderText={'dd/mm/aaaa'}
                            dateFormat={"dd/MM/yyyy"}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="3" md="4" className="mb-4">

                  <Row>
                    <Col xs="12">
                      <Label for="percent"><b>Montant</b></Label>
                    </Col>
                    <Col xs="6" className='mb-4 unova-datepicker'>
                      <Row>
                        <Col xs="3" className={"d-flex align-items-center"}>
                          <Label className={"mb-0"}>Min</Label>
                        </Col>
                        <Col xs="9">
                          <input type="number"
                                 placeholder={"€"}
                                 name={"amount_begin"}
                                 className={"form-control"}
                                 step="0.01"
                                 defaultValue={values.amount_begin || ""}
                                 onKeyUp={e=>setFieldValue("amount_begin",e.currentTarget.value)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="6" className='mb-4 unova-datepicker'>
                      <Row>
                        <Col xs="3" className={"d-flex align-items-center"}>
                          <Label className={"mb-0"}>Max</Label>
                        </Col>
                        <Col xs="9">
                          <input type="number"
                                 placeholder={"€"}
                                 name={"amount_end"}
                                 className={"form-control"}
                                 step="0.01"
                                 defaultValue={values.amount_end || ""}
                                 onKeyUp={e=>setFieldValue("amount_end",e.currentTarget.value)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="3" md="4" className='mb-4 unova-datepicker'>
                  <Row>
                    <Col xs="12">
                      <Label for="value"><b>Banque</b></Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        loadOptions={promiseBankAccountsOptions}
                        isSearchable={false}
                        multiple={true}
                        name={"bank_accounts"}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="text-center mt-3">
                  <Button
                    color="none"
                    className="btn-green px-3"
                    onClick={() => handleSubmit(values as any)}
                  >
                    Appliquer
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CheckDepositFilter;
