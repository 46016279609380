import { History } from "history";
import React, { useEffect, useState } from "react";
import {Card, CardBody, CardTitle, Col, Collapse, Container, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { parseQuery, setQuery } from "../../helpers/helper";
import FlashMessage from '../../components/Common/FlashMessage';
import {CheckDepositFilters} from "../../models/check_deposit";
import CheckDepositTable from "./Components/Tables/check-deposit-table";
import CheckDepositFilter from "./Components/Filters/check-deposit-filter";
import SearchFilter from "../../components/Common/SearchFilter";
import { Link } from "react-router-dom";

const CheckDepositList = ({
  history
}: {
  history: History
}) => {
  const [filtersCollapseIsOpen, setFiltersCollapseIsOpen] = useState(false);

  // Filters

  const query = parseQuery();

  const [filters, setFilters] = useState<CheckDepositFilters>({
    sort_by: query.sort_by as any || '',
    sort_dir: query.sort_dir as any || '',
    page: (query.page || 1) as number,
    page_size: (query.page_size || 50) as number,
    query: (query.search || null) as string | null,
    date_begin: (query.date_begin || null) as Date | null,
    date_end: (query.date_end || null) as Date | null,
    amount_begin: (query.amount_begin || null) as number | null,
    amount_end: (query.amount_end || null) as number | null,
    bank_accounts: ((query.bank_accounts as string[])?.map(i => parseInt(i)) || null) as number[] | null,
  } as CheckDepositFilters);

  useEffect(() => {
    setQuery(filters, history); // Update query in path
  }, [filters, history]);

  function resetFilters() {
    setFilters({
      sort_by: '',
      sort_dir: 'asc',
      page: 1,
      page_size: 50,
      query: null,
      date_begin: null,
      date_end: null,
      amount_begin: null,
      amount_end: null,
      bank_accounts: null,
    } as CheckDepositFilters);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="notifications-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Remise de chèques"
            breadcrumbTitle="Devis / Factures"
            breadcrumbItem="Remise de chèques"
          />
          <FlashMessage />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-0">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>Toutes les remises de chèque</div>
                      <Link to="/check-deposit/add" className="btn btn-green btn-none">
                        <i className='bx bx-plus-circle fa-1.5x'/> Ajouter
                      </Link>
                    </div>
                  </CardTitle>
                  <Row className="my-3">
                    <Col lg="12" className="d-flex justify-content-between align-items-end">
                      <div className="d-flex align-items-center">
                        <span
                          className="btn btn-green outline mr-3"
                          onClick={() => {
                            setFiltersCollapseIsOpen(!filtersCollapseIsOpen);
                          }}
                        >
                          <i className='bx bx-slider-alt fa-1.5x'/> Filtres
                        </span>
                          <u
                            className="d-flex text-link text-underline text-larger"
                            onClick={resetFilters}
                          >
                           Réinitialiser
                        </u>
                      </div>

                      <SearchFilter
                        onSearch={(q) => { setFilters({ ...filters, query: q } as CheckDepositFilters) }}
                        placeholder={"Rechercher"}
                        defaultValue={""}
                        containerClasses={"mt-2 mx-0 no-separator"}
                        inputClasses={"w-4xl"}
                      />
                    </Col>
                    <Col xs="12">
                      <Collapse isOpen={filtersCollapseIsOpen}>
                        <CheckDepositFilter
                          filters={filters}
                          setFilters={setFilters}
                        />
                      </Collapse>
                    </Col>
                  </Row>
                  <CheckDepositTable filters={filters} setFilters={setFilters} history={history} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CheckDepositList;
