import React from 'react';



/**
 *
 * Display the buttons at the end of the form depending of the type of quote/invoice
 * Read the redmine wiki for more informations about the conditions
 * https://redmine.unova.fr/projects/new-wave-energies/wiki/CDC_m%C3%A9tier_-_Les_factures_et_devis_
 *
 *
 *
 * @param {array} states array of states for the buttons, data structure :
 * [
 *  {
 *      value: {
 *          label: "name",
 *          disabled: false
 *          onclick: ...
 *      }
 *  },
 *  {
 *      value: {
 *          label: "name",
 *          disabled: true
 *          onclick: ...
 *      }
 *  },
 *  ...
 * ]
 */
const ValidationButtonsComponent = ({
    states,
}) => {

    return (
        <div className="justify-content-center row">

            {states.map((button, index) => (
                <button
                  key={index}
                    className={"btn btn-primary ml-3" + (button.disabled ? " btn-disabled" : "")}
                    onClick={(e) => {
                       e.preventDefault();
                       button.onclick();
                    }}
                    disabled={button.disabled}>{button.label}</button>
            ))}
        </div>
    );

}

export default ValidationButtonsComponent;