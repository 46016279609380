import React from "react";
import { Badge } from "reactstrap";

const BadgeState = ({ state }) => (
  <Badge
    className={state.label ? `badge-color-${state.label}` : "badge-color-draft"}
  >
    {state.name || "Brouillon"}
  </Badge>
);

export default BadgeState;
