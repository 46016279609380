import React, {useEffect, useState} from 'react';

// Components
import {Formik} from 'formik';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import AsyncAutoComplete from "../../../components/Common/AsyncAutoComplete2";
import {ErrorMessageCustom} from "../../../components/Common/ErrorMessageCustom";

// Validation
import {paymentValidationForm} from "../FormValidationSchema/paymentValidationForm";

// style
import '../invoicesDetails.scss';

// Helpers
import {parseInputToFloat, payment_mode_options, payment_state_options} from "../../../helpers/helper";
import {promiseFinancersOptions} from "../../../helpers/autocomplete_promise_options";
import {promiseBankAccountsOptions} from "../../../helpers/autocomplete_promise_options_api_v2";

/**
 * Form for a payment of an invoice
 * @param handleSubmitForm
 * @param initValues
 * @param handleAbort function called when the 'annuler' button is clicked (with the "event" arg)
 * @param isUpdate
 */
const PaymentForm = ({
                       handleSubmitForm,
                       initValues,
                       handleAbort,
                       isUpdate
                     }) => {

  const [paymentOptions, setPaymentOptions] = useState(payment_mode_options);

  useEffect(() => {
    promiseFinancersOptions()
      .then(financersOptions => {
        setPaymentOptions(prev => [...prev, ...financersOptions])
      })
  }, []);

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmitForm}
      validationSchema={paymentValidationForm}
    >
      {({
          values,
          handleSubmit,
          handleChange,
          errors,
          touched,
          setFieldValue
        }) => (
        <form className='paymentForm'>
          <Col lg='8'>
            <Row>
              <FormGroup>
                <Label for='payment_date'>Date * :</Label>
                <Input
                  name='payment_date'
                  id='payment_date'
                  type='date'
                  invalid={errors.payment_date && !!touched.payment_date}
                  onChange={handleChange}
                  defaultValue={values.payment_date}
                />
                <ErrorMessageCustom name='payment_date'/>
              </FormGroup>
            </Row>

            <Row>
              <FormGroup>
                <Label for='amount'>Montant * :</Label>
                <Input
                  name='amount'
                  id='amount'
                  onChange={handleChange}
                  invalid={errors.amount && !!touched.amount}
                  value={values.amount}
                  onBlur={(event) => {
                    setFieldValue('amount', parseInputToFloat(event.target.value))
                  }}
                />
                <ErrorMessageCustom name='amount'/>
              </FormGroup>
            </Row>

            <Row>
              <FormGroup>
                <Label for='payment_type'>Moyen paiement * :</Label>
                <AsyncAutoComplete
                  name='payment_type'
                  id='payment_type'
                  noOptionsMessage={() => "Aucun moyen de paiement disponible"}
                  placeholder="Sélectionner"
                  loadOptions={paymentOptions}
                  invalid={errors.payment_type && !!touched.payment_type}
                />
                <ErrorMessageCustom name='payment_type'/>
              </FormGroup>
            </Row>

            <Row>
              <FormGroup>
                <Label for='payment_state'>État du paiement * :</Label>
                <AsyncAutoComplete
                  name='payment_state'
                  id='payment_state'
                  noOptionsMessage={() => "Aucun état disponible"}
                  placeholder="Sélectionner"
                  loadOptions={payment_state_options}
                  invalid={errors.payment_state && !!touched.payment_state}
                />
                <ErrorMessageCustom name='payment_state'/>
              </FormGroup>
            </Row>

            <Row>
              <FormGroup>
                <Label for='bank_account_id'>Banque * :</Label>
                <AsyncAutoComplete
                  name='bank_account_id'
                  id='bank_account_id'
                  noOptionsMessage={() => "Aucune banque disponible"}
                  placeholder="Sélectionner"
                  loadOptions={promiseBankAccountsOptions}
                  invalid={errors.bank_account_id && !!touched.bank_account_id}
                />
                <ErrorMessageCustom name='bank_account_id'/>
              </FormGroup>
            </Row>

            <Row>
              <FormGroup>
                <Label for='collection_date'>Date d'encaissement :</Label>
                <Input
                  name='collection_date'
                  id='collection_date'
                  type='date'
                  invalid={errors.collection_date && !!touched.collection_date}
                  onChange={handleChange}
                  defaultValue={values.collection_date}
                />
                <ErrorMessageCustom name='collection_date'/>
              </FormGroup>
            </Row>

            <Row>
              <FormGroup>
                <Label for='comment'>Commentaire :</Label>
                <Input
                  name='comment'
                  id='comment'
                  type='textarea'
                  onChange={handleChange}
                  invalid={errors.comment && !!touched.comment}
                  defaultValue={values.comment}
                />
                <ErrorMessageCustom name='comment'/>
              </FormGroup>
            </Row>

            <Row>
              <FormGroup>
                <Label for='reference'>Référence :</Label>
                <Input
                  name='reference'
                  id='reference'
                  onChange={handleChange}
                  invalid={errors.reference && !!touched.reference}
                  defaultValue={values.reference}
                />
                <ErrorMessageCustom name='reference'/>
              </FormGroup>
            </Row>
          </Col>
          <hr/>

          <Row className='actions'>
            <Button
              className='btn'
              onClick={(event) => handleAbort(event)}
            >Annuler</Button>

            <Button
              className='btn btn-green'
              onClick={() => handleSubmit(values)}
            >{isUpdate ? 'Modifier' : 'Ajouter'}</Button>
          </Row>
        </form>
      )}
    </Formik>
  );
}

export default PaymentForm;