import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_FAMILY_REPARTITION,
  GET_STATISTICS,
  GET_CATEGORY_REPARTITION,
  GET_PRODUCT_REPARTITION,
  GET_ALL_REPARTITIONS
} from "./actionTypes";
import {
  getFamilyRepartitionSuccess,
  getCategoryRepartitionSuccess,
  getProductRepartitionSuccess,
  getStatisticsSuccess,
  getAllRepartitionsSuccess,
  statisticApiError
} from "./actions";

import { statistics } from "../../api";

function* getStatistics({ payload: { query } }) {
  try {
    const response = yield call(statistics.getAll, query);
    yield put(getStatisticsSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          statisticApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des statistiques"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(statisticApiError(error.response.data.message));
      }
    } else {
      yield put(statisticApiError(error.message));
    }
  }
}

function* getFamilyRepartition({ payload: { query } }) {
  try {
    const response = yield call(statistics.getFamiliyRepartition, query);
    yield put(getFamilyRepartitionSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          statisticApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des statistiques"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(statisticApiError(error.response.data.message));
      }
    } else {
      yield put(statisticApiError(error.message));
    }
  }
}

function* getCategoryRepartition({ payload: { query } }) {
  try {
    const response = yield call(statistics.getCAtegoryRepartition, query);
    yield put(getCategoryRepartitionSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          statisticApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des statistiques"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(statisticApiError(error.response.data.message));
      }
    } else {
      yield put(statisticApiError(error.message));
    }
  }
}

function* getProductRepartition({ payload: { query } }) {
  try {
    const response = yield call(statistics.getProductRepartition, query);
    yield put(getProductRepartitionSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          statisticApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des statistiques"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(statisticApiError(error.response.data.message));
      }
    } else {
      yield put(statisticApiError(error.message));
    }
  }
}

function* getAllRepartitions({ payload: { query } }) {
  try {
    const responses = yield call(() => Promise.all([
      statistics.getFamiliyRepartition(query),
      statistics.getCAtegoryRepartition(query),
      statistics.getProductRepartition(query),
    ]));
    yield put(getAllRepartitionsSuccess(responses[0], responses[1], responses[2]));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          statisticApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des statistiques"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(statisticApiError(error.response.data.message));
      }
    } else {
      yield put(statisticApiError(error.message));
    }
  }
}

export function* watchGetStatistics() {
  yield takeEvery(GET_STATISTICS, getStatistics);
}
export function* watchGetFamilyRepartition() {
  yield takeEvery(GET_FAMILY_REPARTITION, getFamilyRepartition);
}
export function* watchGetCategoyRepartition() {
  yield takeEvery(GET_CATEGORY_REPARTITION, getCategoryRepartition);
}
export function* watchGetProductRepartition() {
  yield takeEvery(GET_PRODUCT_REPARTITION, getProductRepartition);
}
export function* watchGetAllRepartitions() {
  yield takeEvery(GET_ALL_REPARTITIONS, getAllRepartitions);
}

function* statisticsSaga() {
  yield all([fork(watchGetStatistics),
    fork(watchGetFamilyRepartition),
    fork(watchGetCategoyRepartition),
    fork(watchGetProductRepartition),
    fork(watchGetAllRepartitions),
  ]);
}

export default statisticsSaga;
