import {useEffect, useState} from 'react';

// Components
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link, withRouter} from "react-router-dom";
import FilterComponent from "../../components/Common/FilterComponent";
import SubscriptionsFilter, {computeFilterSelect, subscriptionFilter} from "./Filters/SubscriptionsFilter";
import SubscriptionsTable from "./Components/SubscriptionsTable";
import GlobalLoader from "../../components/Common/GlobalLoader";

// Redux
import {connect} from "react-redux";
import {getSubscriptions} from "../../store/subscriptions/actions";
import {parseQuery, setQuery} from "../../helpers/helper";
import FlashMessageComponent from "../../components/Common/FlashMessageComponent";

const SubscriptionsList = ({history, getSubscriptions, subscriptions, pagination, loading}) => {

  const query = parseQuery();

  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const defaultFilterValues = {
    page: 1,
    page_size: 25,
    validated: subscriptionFilter.ALL,
    to_renew: subscriptionFilter.ALL,
    query: '',
  }

  const [filter, setFilter] = useState({
    page: query.page || defaultFilterValues.page,
    page_size: query.page_size || defaultFilterValues.page_size,
    validated: query.validated || defaultFilterValues.validated,
    to_renew: query.to_renew || defaultFilterValues.to_renew,
    query: query.query || defaultFilterValues.query,
  });

  useEffect(() => {
    setQuery(filter, history);
    getSubscriptions({
      page: filter.page,
      page_size: filter.page_size,
      validate: computeFilterSelect(filter.validated),
      to_renew: computeFilterSelect(filter.to_renew) === null ? null : !computeFilterSelect(filter.to_renew),
      query: filter.query,
    });
  }, [filter, getSubscriptions, history])

  return (
    <div className="page-content">
      {loading ? <GlobalLoader/> :
      <Container fluid className="invoices-container">
        <Breadcrumbs title="Listes" breadcrumbItem="Listes" breadcrumbTitle='Abonnements'/>
        <Card>
          <FlashMessageComponent/>
          <CardBody>
            {/* Card title */}
            <CardTitle className="mb-3">
              <Row className="pr-2 pl-2 d-flex justify-content-between">
                <Col>Tous les abonnements</Col>
                <Col className="d-flex justify-content-end">
                  <Link
                    to="subscription/add"
                    className="d-flex align-items-center text-theme-color-2"
                  >
                    <i className="bx bx-plus-circle mr-1>"/> Ajouter
                  </Link>
                </Col>
              </Row>
            </CardTitle>

            <FilterComponent
              filter={filter}
              setFilter={setFilter}
              defaultFilterValues={defaultFilterValues}
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
            >
              <SubscriptionsFilter/>
            </FilterComponent>


              <SubscriptionsTable
                data={subscriptions}
                pagination={pagination}
                setFilters={setFilter}
                filters={filter}
                history={history}
              />


          </CardBody>
        </Card>
      </Container>}
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    subscriptions,
    loading,
    error,
    pagination,
  } = state.Subscriptions

  return {
    subscriptions,
    loading,
    error,
    pagination
  }
}

export default withRouter(
  connect(mapStateToProps, {
    getSubscriptions,
  })(SubscriptionsList)
);