import {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Alert,
} from "reactstrap";

// helpers
import {typeForm} from "../../helpers/quotes_invoices_helper";

// Redux
import {connect} from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {
    getVats,
    getOrder,
    getQuote,
} from "../../store/actions";

import QuoteInvoiceComponent from "./Components/QuoteInvoiceComponent";
import LoaderLayoutPulse from "../../components/Common/LoaderLayoutPulse";

const QuoteEdit = ({getOrder, getQuote, order, loading, quote, quote_order}) => {
    const [succesMessage, setSuccesMessage] = useState(false);

    useEffect(() => {
        getOrder(parseInt(window.location.pathname.split("/")[3], 10));
    }, [getOrder]);

    useEffect(() => {
        getQuote(parseInt(window.location.pathname.split("/")[4], 10));
    }, [getQuote]);

    return (
        <div className="page-content">
            <Container fluid className="quotes-container">
                {/* Render Breadcrumbs */}
                <Breadcrumbs
                    title="Devis"
                    breadcrumbItem={`Modifier un ${
                        order.order ? "avenant" : "devis"
                    }`}
                />
                {loading ? (
                    <LoaderLayoutPulse/>
                ) : order && quote ? (
                    <Row>
                        {succesMessage ? (
                            <Col xs="12">
                                <Alert
                                    isOpen={succesMessage}
                                    toggle={() => setSuccesMessage(false)}
                                    color="success"
                                >
                                    {succesMessage}
                                </Alert>
                            </Col>
                        ) : null}
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">{`Modifier ${
                                        order.order ? "l'avenant" : "le devis"
                                    } N°${quote.number}`}</CardTitle>
                                    <QuoteInvoiceComponent
                                        quote={quote}
                                        typeForm={typeForm.quote}
                                        isUpdate={true}
                                        quote_order={quote_order}
                                        order={order}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : <LoaderLayoutPulse/>}
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const {order, loading} = state.Orders;
    const {quote, quote_order} = state.Quotes;

    return {loading, order, quote, quote_order};
};

export default withRouter(
    connect(mapStatetoProps, {
        getVats,
        getOrder,
        getQuote,
    })(QuoteEdit)
);
