export const GET_SCALES = "GET_SCALES";
export const GET_SCALES_SUCCESS = "GET_SCALES_SUCCESS";

export const GET_SCALE = "GET_SCALE";
export const GET_SCALE_SUCCESS = "GET_SCALE_SUCCESS";

export const CREATE_SCALE = "CREATE_SCALE";
export const CREATE_SCALE_SUCCESS = "CREATE_SCALE_SUCCESS";

export const UPDATE_SCALE = "UPDATE_SCALE";
export const UPDATE_SCALE_SUCCESS = "UPDATE_SCALE_SUCCESS";

export const DELETE_SCALE = "DELETE_SCALE";
export const DELETE_SCALE_SUCCESS = "DELETE_SCALE_SUCCESS";

export const SCALES_API_ERROR = "SCALES_API_ERROR";
