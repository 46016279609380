import React from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import PaymentForm from "../Form/PaymentForm";
import {payments} from "../../../api";

const PaymentModal = ({
  isOpen,
  toggle,
  invoice,
  thenExecutedFunction = () => console.log(''),
  payment = null

                      }) => {

  let initValues;

  if(payment)
    initValues = {
      payment_date: payment.paid_at.substr(0, 10),
      total_price_ttc: invoice.total_ttc,
      paid_total_ttc: invoice.paid_total_ttc,
      amount: payment.amount,
      payment_type: payment.payment_type,
      reference: payment.reference,
      bank_account_id: payment.bank_account_id,
      comment: payment.comment,
      payment_state: payment.state,
      initPayment: payment.amount,
      collection_date: payment.date_remise_bank_check_to_bank?.substr(0, 10),
    }
  else
    initValues = {
      payment_date: new Date().toISOString().substr(0, 10),
      total_price_ttc: invoice.total_ttc,
      paid_total_ttc: invoice.paid_total_ttc,
      amount: '',
      payment_type: '',
      payment_state: 'reçu',
      bank_account_id: '',
      reference: '',
      comment: '',
      initPayment: false,
      collection_date: ''
    };

  const handleSubmitForm = (values) => {
    if(payment){
      payments.update(payment.id, {
        invoice_id: invoice.id,
        paid_at: values.payment_date,
        amount: values.amount,
        payment_type: values.payment_type,
        reference: values.reference,
        comment: values.comment,
        state: values.payment_state,
        bank_account_id: values.bank_account_id,
        date_remise_bank_check_to_bank: values.collection_date,
      })
        .then(r => {
          thenExecutedFunction();
          toggle();
        })
    }else{
      payments.create({
        invoice_id: invoice.id,
        paid_at: values.payment_date,
        amount: values.amount,
        payment_type: values.payment_type,
        reference: values.reference,
        comment: values.comment,
        state: values.payment_state,
        bank_account_id: values.bank_account_id,
        date_remise_bank_check_to_bank: values.collection_date,
      })
        .then(r => {
          thenExecutedFunction();
          toggle();
        })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={() => toggle()}>
        Ajouter un paiement
      </ModalHeader>

      <ModalBody>
        <PaymentForm
          initValues={initValues}
          handleSubmitForm={handleSubmitForm}
          handleAbort={() => toggle()}
          isUpdate={payment}
        />
      </ModalBody>

    </Modal>
  );
}

export default PaymentModal;
