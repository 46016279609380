import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//Import Date Picker
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

// actions
import {
  updateInvoice,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";

class FastInvoiceEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoice: null,
      customInvoiceDate: null,
      isOpen: false
    };
  }

  open(invoice) {
    this.setState({
      invoice: invoice,
      customInvoiceDate: new Date(invoice.invoice_date),
      isOpen: true
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleSubmit() {
    this.props.updateInvoice(this.state.invoice.id, {
      custom_invoice_date: this.state.customInvoiceDate
    }, this.props.history);
    this.toggle();
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} toggle={() => this.toggle()} className="">
        <ModalHeader toggle={() => this.toggle()} tag="h4">
          Modifier la facture
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12">
              <Label>Date de facturation</Label>
            </Col>
            <Col xs="12">
              <DatePicker
                className="form-control button-without-style"
                selected={this.state.customInvoiceDate}
                onChange={(date) => this.setState({customInvoiceDate: date})}
                dateFormat="dd/MM/yyyy"
                locale={fr}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => this.toggle()}
            className="btn btn-secondary waves-effect"
          >
            Annuler
          </Button>
          <Button
            onClick={() => this.handleSubmit()}
            className="btn btn-green waves-effect waves-light"
          >
            Valider
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStatetoProps = (state) => {
  return {};
};

// Use for forwardRef
const wrapper = React.forwardRef((props, ref) => (
  <FastInvoiceEditModal {...props} ref={ref} />
));

export default withRouter(
  connect(mapStatetoProps, {
    updateInvoice,
  }, null, {forwardRef: true})(wrapper)
);