import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Button } from "reactstrap";

// Redux
import { connect } from "react-redux";

import Select from "react-select";

//Import Date Picker
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

// actions
import { updateProject } from "../../../store/actions";

const CEEPrimeCard = (props) => {
  const [primeFolderState, setPrimeFolderState] = useState(
    props.project.prime_folder_state
      ? {
          value: props.project.prime_folder_state,
          label: props.project.prime_folder_state,
        }
      : null
  );
  const [certyproSentDate, setCertyproSentDate] = useState(
    props.project.certypro_sent_date &&
      new Date(props.project.certypro_sent_date)
  );
  const [primeRelanceDate, setPrimeRelanceDate] = useState(
    props.project.prime_relance_date &&
      new Date(props.project.prime_relance_date)
  );
  const [isCeePaid, setIsCeePaid] = useState(
    props.project.order && props.project.order.is_cee_paid !== null
      ? props.project.order.is_cee_paid
        ? { values: "Oui", label: "Oui" }
        : { values: "Non", label: "Non" }
      : null
  );
  const [observationPrime, setObservationPrime] = useState(
    props.project.observation_prime ? props.project.observation_prime : ""
  );

  /**
   * Fonction qui permet de gérer la hauteur du textarea du champ commentaire
   * @param {object} e L'événement retourné par le textarea, qui contient la balise HTML du textarea
   */
  const updateTextareaHeight = (e) => {
    e.currentTarget.style.height = "auto";
    e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
  };

  /**
   * Met à jour le projet avec les nouvelles données
   */
  const updateChanges = () => {
    let values = {};

    if (primeFolderState) values.prime_folder_state = primeFolderState.value;
    else delete values.prime_folder_state;

    if (certyproSentDate) values.certypro_sent_date = certyproSentDate;
    else delete values.certypro_sent_date;

    if (primeRelanceDate) values.prime_relance_date = primeRelanceDate;
    else delete values.prime_relance_date;

    if (isCeePaid)
      values.order_attributes = {
        id: props.project.order.id,
        is_cee_paid: isCeePaid.value === "Oui" ? true : false,
      };
    else delete values.order_attributs;

    if (observationPrime) values.observation_prime = observationPrime;
    else delete values.observation_prime;

    props.updateProject(props.project.id, values);
    props.setFlash_message(true);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-3 d-flex justify-content-between">
          <div>Dossier de prime cee</div>
        </CardTitle>
        <div className="cee-prime-container mt-3">
          <div className="cee-prime-row">
            <div className="cee-prime-item">
              <p>Montant :</p>
              <span>{`${
                props.project.order && props.project.order.cce_prime
                  ? parseFloat(props.project.order.cce_prime).toFixed(2)
                  : "-"
              } €`}</span>
            </div>
          </div>
          <div className="cee-prime-row">
            <div className="cee-prime-item">
              <p>État du dossier :</p>
              <Select
                className="w-100"
                noOptionsMessage={() => "Pas de choix disponible"}
                placeholder="État du dossier"
                options={[
                  {
                    value: "1",
                    label: "1",
                  },
                  {
                    value: "2",
                    label: "2",
                  },
                  {
                    value: "3",
                    label: "3",
                  },
                ]}
                value={primeFolderState}
                onChange={(primeFolderState) => {
                  setPrimeFolderState(primeFolderState);
                }}
              />
            </div>
          </div>
          <div className="cee-prime-row">
            <div className="cee-prime-item">
              <p>Dossier payé :</p>
              <Select
                className="w-100"
                placeholder="Dossier payé"
                options={[
                  {
                    value: "Oui",
                    label: "Oui",
                  },
                  {
                    value: "Non",
                    label: "Non",
                  },
                ]}
                value={isCeePaid}
                onChange={(isCeePaid) => {
                  setIsCeePaid(isCeePaid);
                }}
              />
            </div>
          </div>
          <div className="cee-prime-row">
            <div className="cee-prime-item">
              <p>Date de relance :</p>
              <DatePicker
                className="form-control button-without-style"
                selected={primeRelanceDate}
                onChange={(date) => {
                  setPrimeRelanceDate(date);
                }}
                dateFormat="dd/MM/yyyy"
                locale={fr}
                placeholderText="Date de relance"
              />
            </div>
          </div>
          <div className="cee-prime-row">
            <div className="cee-prime-item">
              <p>Date d'envoi à certypro :</p>
              <DatePicker
                className="form-control button-without-style"
                selected={certyproSentDate}
                onChange={(date) => {
                  setCertyproSentDate(date);
                }}
                dateFormat="dd/MM/yyyy"
                locale={fr}
                placeholderText="Date d'envoi à certypro"
              />
            </div>
          </div>
          <div className="cee-prime-row">
            <div className="cee-prime-item cee-prime-item-block">
              <p>Observations :</p>
              <textarea
                rows="2"
                className="form-control"
                placeholder="Ajouter un commentaire"
                value={observationPrime}
                onChange={(e) => setObservationPrime(e.currentTarget.value)}
                onFocus={(e) => updateTextareaHeight(e)}
                onInput={(e) => updateTextareaHeight(e)}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="text-center">
            <Button color="none" className="btn-green" onClick={updateChanges}>
              Sauvegarder les modifications
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStatetoProps = (state) => {
  const { project } = state.Projects;

  return { project };
};

export default withRouter(
  connect(mapStatetoProps, { updateProject })(CEEPrimeCard)
);
