import { Component } from 'react';

import { Alert } from 'reactstrap';
import {connect} from "react-redux";
import { removeMessage } from "../../store/flash_messages/actions";


export const importance = {
  DANGER: "danger",
  WARNING: "warning",
  SUCCESS: "success"
}

/**
 * Display Flash message using a redux store. To display a message you need to
 * add the addMessage action to the mapDispatchToProps and then call it with the corrects
 * args.
 *
 * You can call the addMessage action in the saga of any stores you want to automatise the responses.
 *
 * @see {addMessage}
 *
 * @param duration time after the message will be deleted (5000 ms by default)
 * @param persistOnHover if set to true, the deletion timer will be cleared
 * @deprecated use FlashMessage instead @see {FlashMessage}
 */
class FlashMessageComponent extends Component {
  constructor(props) {
    super(props);

    this.hide = this.hide.bind(this);
    this.resumeTimer = this.resumeTimer.bind(this);
    this.pauseTimer = this.pauseTimer.bind(this);
  }

  componentDidMount() {
    const { duration } = this.props;
    this.remaining = duration;
    this.resumeTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  hide() {
    this.props.removeMessage();
  }

  resumeTimer() {
    window.clearTimeout(this.timer);

    this.start = new Date();
    this.timer = setTimeout(this.hide, this.remaining);
  }

  pauseTimer() {
    const { persistOnHover } = this.props;
    if (persistOnHover) {
      clearTimeout(this.timer);

      this.remaining -= new Date() - this.start;
    }
  }



  render() {
    const { message, importance } = this.props;
    const showFlash = (message !== '')

    return showFlash ? (
      <div
        onMouseEnter={this.pauseTimer}
        onMouseLeave={this.resumeTimer}
      >
        <Alert
          color={importance}
          isOpen={showFlash}
          toggle={() => this.hide()}
        >{message}</Alert>
      </div>
    ) : null;
  }
}

FlashMessageComponent.defaultProps = {
  duration: 5000,
  persistOnHover: false,
};


const mapStateToProps = (state) => {
  const {
    message,
    importance
  } = state.FlashMessages
  return {
    message,
    importance
  }
}

export default connect(mapStateToProps, {
  removeMessage,
})(FlashMessageComponent);