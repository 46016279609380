import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardFooter, Modal } from "reactstrap";

// Redux
import { connect } from "react-redux";

// actions
import { deleteFinancer } from "../../store/actions";

const CardFinancer = (props) => {
  const [modal_standard, setmodal_standard] = useState(false);
  const [financier_id, setFinancier_id] = useState(-1);

  function tog_standard(financier_id) {
    setmodal_standard(!modal_standard);
    setFinancier_id(financier_id)
    removeBodyCss();
  }

  function removeBodyCss() {
      document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>
      <Col xl="4" sm="6">
        <Card>
          <CardBody>
            <Row>
              <Col lg="2">
                <div className="text-lg-center">
                  {
                    props.financer.url !== "Null"
                      ? <img src={props.financer.url.match(/http/) !== null ? props.financer.url : `${process.env.REACT_APP_API_URL}/files/${props.financer.url}`} className="avatar-md mr-3 rounded-circle mb-4 float-left float-lg-none" alt="img" />
                      : <div className="avatar-md mr-3 mx-lg-auto mb-4 float-left float-lg-none">
                        <span className={"avatar-title rounded-circle bg-soft-blue text-primary font-size-16"}></span>
                      </div>
                  }
                </div>
              </Col>

              <Col lg="10">
                <h5>{props.financer.name}</h5>
                <p className="mb-1">{props.financer.description}</p>
                <p className="mb-1"><i className="bx bx-phone mr-1"></i> {props.financer.phone}</p>
                <p className="mb-1"><i className="bx bx-envelope mr-1"></i> {props.financer.email}</p>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row className="container-text-theme-color-2">
              <Col xs="12" className="d-flex justify-content-end">
                <Link
                  to={`/financer/detail/${props.financer.id}`}
                  className="d-flex align-items-center mr-5"
                >
                  <i className="bx bx-show mr-1"></i> Voir
                </Link>
                <Link
                  to={`/financer/edit/${props.financer.id}`}
                  className="d-flex align-items-center mr-5"
                >
                  <i className="bx bx-pencil mr-1"></i> Modifier
                </Link>
                <Link
                  to="#"
                  onClick={() => { tog_standard(props.financer.id) }}
                  data-toggle="modal"
                  data-target="#projectDelete"
                  className="d-flex align-items-center mr-5"
                >
                  <i className="bx bx-trash mr-1"></i> Supprimer
                </Link>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
      <Modal isOpen={modal_standard} toggle={() => { tog_standard() }}>
          <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">Fianciement - Suppression</h5>
              <button type="button" onClick={() =>  { setmodal_standard(false) } } className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body">
              <h5>Attention</h5>
              <p>Voulez vous supprimer le financier definitivement ?</p>
          </div>
          <div className="modal-footer">
              <button type="button" onClick={() => { tog_standard() }} className="btn btn-secondary waves-effect" data-dismiss="modal">
                  Annuler
              </button>
              <button type="button" onClick={() => { props.deleteFinancer(financier_id, props.history) }} className="btn btn-green waves-effect waves-light">
                  Supprimer
              </button>
          </div>
      </Modal>
    </React.Fragment>
  )
};

const mapStatetoProps = (state) => {
  const { financers, loading } = state.Financers;
  return { financers, loading };
};


export default withRouter(
  connect(mapStatetoProps, { deleteFinancer })(
    CardFinancer
  )
);
