import { GET_VATS, GET_VATS_SUCCESS, VATS_API_ERROR } from "./actionTypes";

export const getVats = (query) => {
  return {
    type: GET_VATS,
    payload: { query },
  };
};

export const getVatsSuccess = (vats) => {
  return {
    type: GET_VATS_SUCCESS,
    payload: vats,
  };
};

export const vatsApiError = (error) => {
  return {
    type: VATS_API_ERROR,
    payload: error,
  };
};
