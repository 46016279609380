import {
  GET_PROJECT_STATES,
  GET_PROJECT_STATES_SUCCESS,
  PROJECTS_STATES_API_ERROR,
} from "./actionTypes";

const initialState = {
  projectStates: [],
  error: false,
  loading: false,
};

const projectStates = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_STATES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PROJECT_STATES_SUCCESS:
      state = {
        ...state,
        projectStates: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case PROJECTS_STATES_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default projectStates;
