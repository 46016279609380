import {
  GET_PROJECT_STATES,
  GET_PROJECT_STATES_SUCCESS,
  PROJECTS_STATES_API_ERROR,
} from './actionTypes';

export const getProjectStates = (query) => {
  return {
      type: GET_PROJECT_STATES,
      payload: { query }
  };
};

export const getProjectStatesSuccess = (projectStates) => {
  return {
      type: GET_PROJECT_STATES_SUCCESS,
      payload: projectStates
  };
};

export const projectStatesApiError = (error) => {
  return {
      type: PROJECTS_STATES_API_ERROR,
      payload: error
  };
};