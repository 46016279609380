import {useContext} from 'react';

import SelectComponent from './SelectComponent';
import DateComponent from './DateComponent';

import {promiseCustomersOptions} from "../../../helpers/autocomplete_promise_options";

import {typeForm} from "../../../helpers/quotes_invoices_helper";

import {Row, Col} from 'reactstrap';
import AsyncSelect from "react-select/async";
import ValuesContext from "../Context/ValuesContext";


const ProfessionalsSelect = ({
    errors,
    showErrors,
    value,
    setValue,
    disabled
                             }) => {
    return (
        <AsyncSelect
            name="customer"
            className={"selector-with-addon " + (errors.customer !== undefined && showErrors ? "parsley-error" : "")}
            placeholder="Sélectionner"
            loadOptions={(inputValue, componentDidMount) => promiseCustomersOptions(inputValue, componentDidMount, "Professionnel")}
            value={value.customer}
            onChange={(val) => setValue('customer', val)}
            defaultOptions
            isDisabled={disabled}
        />
    );
}

const IndividualSelect = ({
    errors,
    showErrors,
    value,
    setValue,
    disabled
                             }) => {
    return (
        <AsyncSelect
            name="customer"
            className={"selector-with-addon " + (errors.customer !== undefined && showErrors ? "parsley-error" : "")}
            placeholder="Sélectionner"
            loadOptions={(inputValue, componentDidMount) => promiseCustomersOptions(inputValue, componentDidMount, "Particulier")}
            value={value.customer}
            onChange={(val) => setValue('customer', val)}
            defaultOptions
            isDisabled={disabled}
        />
    );
}



/**
 * @description Component of the quote/invoice form defining the client and the date
 *
 * @param {function} openModalCustomer state function for the customer modal
 * @param {object} errors errors object defined with yup
 */
const QuoteCustomerComponent = ({
                                    openModalCustomer,
                                    errors,
                                    showErrors,
                                    formType,
    disabled
                                }) => {


    //Get init values passed by context higher in the DOM
    //const contextInitValue = ValueContext;

    // Customer state defined by the type selector and impacting the customer selector
    //const [customerType, setCustomerType] = useState(null);
    /*const [customer, setCustomer] = useState(null);
    const [date, setDate] = useState(new Date())*/

    const valuesContext = useContext(ValuesContext);

    const TYPES = [
        {
            label: "Particulier",
            value: "Particulier",
        },
        {
            label: "Professionnel",
            value: "Professionnel",
        },
    ];
    return (
        <div>
            <Row>
                <Col lg="6">
                    <SelectComponent
                        name="customerType"
                        placeholder="Choisir un type de client"
                        label="Type de client"
                        value={valuesContext.values.customerType ? {
                            label: valuesContext.values.customerType,
                            value: valuesContext.values.customerType
                        } : null}
                        handleChange={(val) => valuesContext.setValues('customerType', val)}
                        options={TYPES}
                        classNameSelector="col-lg-8"
                        classNameLabel="col-lg-4"
                        className="row"
                        error={errors.customerType}
                        showError={showErrors}
                        isDisabled={disabled}
                    />
                </Col>
            </Row>

            {valuesContext.values.customerType ?
                <Row>
                    {/*
                        Async select component to choose the customer in the database using promise
                        We don't use SelectComponent because of the span that is in the div with the selector
                    */}
                    <Col lg="6">
                        <Row className="mb-4 form-group">
                            <label className="col-form-label col-lg-4" htmlFor="customer">Client *</label>
                            <Col lg="8">
                                <div className="w-100 d-flex">
                                    {
                                        valuesContext.values.customerType === "Professionnel" ?
                                            <ProfessionalsSelect
                                                errors={errors}
                                                showErrors={showErrors}
                                                value={valuesContext.values}
                                                setValue={valuesContext.setValues}
                                                disabled={disabled}
                                            />
                                        :
                                            <IndividualSelect
                                                errors={errors}
                                                showErrors={showErrors}
                                                value={valuesContext.values}
                                                setValue={valuesContext.setValues}
                                                disabled={disabled}
                                            />
                                    }
                                    <span className={"btn border-gray ml-2 " + (disabled ? "disabled" : "")} onClick={disabled ? undefined : (() => openModalCustomer())}>
                                        <i className="fas fa-plus"/>
                                    </span>
                                </div>
                                {errors.customer !== undefined && showErrors ?
                                    <span className="error">{errors.customer}</span> : ""}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6">
                        <DateComponent
                            placeholder="Choisir la date"
                            name={formType === typeForm.quote || formType === typeForm.approval ? 'custom_quote_date' : 'custom_invoice_date'}
                            label="Date"
                            value={formType === typeForm.quote || formType === typeForm.approval ? valuesContext.values.custom_quote_date : valuesContext.values.custom_invoice_date}
                            classNameDate="col-lg-8"
                            classNameLabel="col-lg-4"
                            handleChange={(val) => valuesContext.setValues(((formType === typeForm.quote || formType === typeForm.approval) ? 'custom_quote_date' : 'custom_invoice_date'), val)}
                            isDisabled={disabled}
                        />
                    </Col>

                </Row>
                : ""}
        </div>
    );

}

export default QuoteCustomerComponent;