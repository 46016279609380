import React, {useState, useEffect} from "react";
import {Container, Row, Col} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Icons
import { parseQuery, setQuery } from "../../helpers/helper";
import { MapItemFilters, MapItemInterventionType } from "../../models/map_item";
import InterventionTypeFilter from "./Components/Filters/InterventionTypeFilter";
import MapItemComponent from "./Components/MapItemComponent";

const MapContainer = ({
  history
}: {
  history: History
}) => {
  // Filters

  const query = parseQuery();

  const [filters, setFilters] = useState<MapItemFilters>({
    start_at: (query.start_at || null) as string | null,
    end_at: (query.end_at || null) as string | null,
    intervention_types: (query.intervention_types || Object.values(MapItemInterventionType)) as MapItemInterventionType[],
  });

  useEffect(() => {
    setQuery(filters, history); // Update query in path
  }, [filters, history]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="map-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Map" breadcrumbTitle="Planification" breadcrumbItem="Map"/>
          <Row>
            <Col id="map-container" lg="10">
              <MapItemComponent
                filters={filters}
                setFilters={setFilters}
              />
            </Col>
            <InterventionTypeFilter
              filters={filters}
              setFilters={setFilters}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MapContainer;
