import {useState, Dispatch, SetStateAction} from "react";
import {Row, Col, Button, Label} from "reactstrap";

//Import Date Picker
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { MapItemFilters } from "../../../../models/map_item";

interface MapFilterProps {
  filters: MapItemFilters,
  setFilters: Dispatch<SetStateAction<MapItemFilters>>
}

const MapFilter = ({
  filters,
  setFilters,
} : MapFilterProps) => {

  const [dateBegin, setDateBegin] = useState<Date>(filters.start_at ? new Date(filters.start_at) : new Date());
  const [dateEnd, setDateEnd] = useState<Date>(filters.end_at ? new Date(filters.end_at) : new Date());

  function onSelectDateBegin(date: Date) {
    setDateBegin(date);

    if (+date > +dateEnd) {
      setDateEnd(date);
    }
  }

  function onSelectDateEnd(date: Date) {
    setDateEnd(date);

    if (+date < +dateBegin) {
      setDateBegin(date);
    }
  }

  return (
    <Row>
      <Col xs="12" md="3" className='d-flex justify-content-end align-items-end'>
        <Row>
          <Col xs="12" className="mt-3">
            <Label>Date de début</Label>
          </Col>
          <Col xs="12">
            <DatePicker
              className="form-control button-without-style"
              selected={dateBegin}
              onChange={onSelectDateBegin}
              dateFormat="dd/MM/yyyy"
              locale={fr}
            />
          </Col>
        </Row>
      </Col>
      <Col xs="12" md="3" className='d-flex justify-content-end align-items-end'>
        <Row>
          <Col xs="12" className="mt-3">
            <Label>Date de fin</Label>
          </Col>
          <Col xs="12">
            <DatePicker
              className="form-control button-without-style"
              selected={dateEnd}
              onChange={onSelectDateEnd}
              dateFormat="dd/MM/yyyy"
              locale={fr}
            />
          </Col>
        </Row>
      </Col>
      <Col xs="12" md="6" className="d-flex justify-content-end align-items-end">
        <div className='d-flex'>
          <Button
            color="none"
            className="btn btn-green waves-effect waves-light btn btn-secondary mr-1"
            onClick={() => {
              setFilters({
                ...filters,
                start_at: dateBegin?.toISOString(),
                end_at: dateEnd?.toISOString(),
              });
            }}
          >
            Appliquer les filtres
          </Button>
        </div>
        <div className='d-flex'>
          <Button
            color="none"
            className="btn btn-secondary waves-effect btn btn-secondary ml-1"
            onClick={() => {
              setFilters({
                ...filters,
                start_at: null,
                end_at: null,
              });
            }}
          >
            Vider les filtres
          </Button>
        </div>
      </Col>

    </Row>
  );
};

export default MapFilter;
