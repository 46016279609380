import { orderHasProject } from '../../../helpers/helper';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";

const InvoiceActionsComponent = ({
  row,
  toggleModalDelete,
  toggleModalRefund,
  isDepositInvoiceWithInvoice,
  projectConvert,
  toggleModalValidate,
  history
}) => {
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle className={"bg-transparent"}>
          <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
        </DropdownToggle>
        <DropdownMenu right className="bordered important-default-colors">
          <DropdownItem onClick={() => { window.open(`${process.env.REACT_APP_API_URL}/files/${row.url}`)?.focus() }}>Voir le pdf de la facture dans un nouvel onglet</DropdownItem>
          {row.is_draft ? 
            <>
              <DropdownItem onClick={() => { toggleModalValidate(row.id, row) }}>Valider la facture</DropdownItem>
              <DropdownItem onClick={() => { toggleModalDelete(row.id) }}>Supprimer la facture</DropdownItem>
            </>
          : 
            <>
              <DropdownItem onClick={() => { history.push(`/invoice/${row.id}/detail`) }}>Afficher plus de détails</DropdownItem>
              {
                row.order.quote === null &&
                row.order.project === null &&
                !row.is_refund &&
                !row.is_canceled ?
                  <DropdownItem onClick={() => { history.push(`/refund/add/${row.id}`) }}>Créer un avoir</DropdownItem>
                : (
                    row.refund_by !== null ||
                    isDepositInvoiceWithInvoice(row) ||
                    row.is_refund ? null : 
                      <DropdownItem onClick={() => { toggleModalRefund(row) }}>Créer un avoir</DropdownItem>
                  )
              }
            </>
          }
          {row.order && !orderHasProject(row.order) && row.subscription_id == null && !row.refund_on &&
            <DropdownItem onClick={() => { projectConvert(row.order.id) }}>Générer le projet</DropdownItem>
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default InvoiceActionsComponent;