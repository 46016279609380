import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {subscriptions} from "../../../api";
import {getSubscription} from "../../../store/subscriptions/actions";
import {connect} from "react-redux";
import { addFlashMessage, ImportantEnum } from '../../../components/Common/FlashMessage';

const StopSubscriptionModal = ({
  isOpen,
  toggleModal,
  subscription,
  getSubscription
                               }) => {

  const handleSubmit = () => {
    subscriptions.subscriptionRenew(subscription.id, false)
      .then(r => {
        toggleModal();
        addFlashMessage(ImportantEnum.SUCCESS, 'La reconduction a été arrêté')
        getSubscription(subscription.id);
      })
      .catch(err => console.error(err));
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleModal()}
    >
      <ModalHeader toggle={() => toggleModal()}>Abonnement - Arrêter la reconduction</ModalHeader>
      <ModalBody>
        <h1>Attention</h1>
        <p>Voulez-vous arrêter la reconduction de l'abonnement ?</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => toggleModal()}>Annuler</Button>
        <Button type='submit' onClick={() => handleSubmit()} className='btn-green'>Arrêter</Button>
      </ModalFooter>
    </Modal>
  );
}

export default connect(null, {getSubscription})(StopSubscriptionModal);