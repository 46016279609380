import {useEffect} from 'react';

// Component
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FormulaForm from "./Form/FormulaForm";

// Context
import {withRouter} from "react-router-dom";

// Redux
import {connect} from "react-redux";
import {getFormula, updateFormula} from "../../store/formulas/actions";
import GlobalLoader from "../../components/Common/GlobalLoader";

const FormulasUpdate = ({
  updateFormula,
  history,
  formula,
  getFormula,
  loading
                        }) => {

  useEffect(() => {
    const splitedPath = window.location.pathname.split('/');
    if(splitedPath.length === 3)
      getFormula(splitedPath[2]);
  }, [getFormula])

  let initValues;

  if(formula)
    initValues = {
      formula_type: formula.formule_type.id,
      label: formula.label,
      description: formula.description,
      price_ttc_monthly: formula.price_ttc_monthly,
      vat: formula.vat.id,
      categories: formula.categories.map((category) => (category.id)),
      sub_categories: formula.sub_categories.map((sub_category) => (sub_category.id)),
    }
  else
    initValues = {
      formula_type: '',
      label: '',
      description: '',
      price_ttc_monthly: '',
      vat: '',
      categories: [],
      sub_categories: [],
    }

  function handleSubmitFormula(values) {

    updateFormula(formula.id,
      {
        formule_type: values.formula_type,
        label: values.label,
        description: values.description,
        price_ttc_monthly: values.price_ttc_monthly,
        vat_id: values.vat,
        category_ids: values.categories,
        sub_category_ids: values.sub_categories
      }, history);
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Formules" breadcrumbItem="Formules" breadcrumbTitle='Abonnements'/>
        <Card>
          <CardBody>
            {/* Card title */}
            <CardTitle className="mb-3">
              <Row className="pr-2 pl-2 d-flex justify-content-between">
                <Col>Modifier une formule</Col>
              </Row>
            </CardTitle>

            {/* FORM */}
            {loading || !formula ? <GlobalLoader /> :
              <FormulaForm
                initialValues={initValues}
                handleSubmitFormula={handleSubmitFormula}
                handleSubmitButtonText='Modifier la formule'
              />
            }

          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    formula,
    loading,
    error,
    pagination,
  } = state.Formulas

  return {
    formula,
    loading,
    error,
    pagination
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateFormula,
    getFormula
  })(FormulasUpdate)
);