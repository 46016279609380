export const GET_FORMULAS = "GET_FORMULAS";
export const GET_FORMULAS_SUCCESS = "GET_FORMULAS_SUCCES";

export const GET_FORMULA = "GET_FORMULA";
export const GET_FORMULA_SUCCESS = "GET_FORMULA_SUCCES";

export const CREATE_FORMULA = "CREATE_FORMULA";
export const CREATE_FORMULA_SUCCESS = "CREATE_FORMULA_SUCCESS";

export const UPDATE_FORMULA = "UPDATE_FORMULA";
export const UPDATE_FORMULA_SUCCESS = "UPDATE_FORMULA_SUCCESS";

export const DELETE_FORMULA = "DELETE_FORMULA";
export const DELETE_FORMULA_SUCCESS = "DELETE_FORMULA_SUCCESS";

export const FORMULAS_API_ERROR = "FORMULAS_API_ERROR";
