export enum UserRoleEnum {
  COMMERCIAL = 'Commercial',
  SALES_ADMIN = 'Administrateur des ventes',
  LEADER = 'Dirigeant',
  AGENCY_MANAGER = 'Responsable d\'agence',
  REGIONAL_MANAGER = 'Responsable régional',
  ADMIN = 'Administrateur',
  RH = 'Ressource humaine',
  UNOVA = 'Unova',
  TECHNICIAN = 'Technicien',
}

export default interface User {
  id?: number|null,
  firstname?: string|null,
  lastname?: string|null,
}