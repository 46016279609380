import {
  GET_FINANCERS,
  GET_FINANCERS_SUCCESS,
  GET_FINANCER,
  GET_FINANCER_SUCCESS,
  CREATE_FINANCER,
  CREATE_FINANCER_SUCCESS,
  UPDATE_FINANCER,
  UPDATE_FINANCER_SUCCESS,
  DELETE_FINANCER,
  DELETE_FINANCER_SUCCESS,
  FINANCERS_API_ERROR,
} from "./actionTypes";

const initialState = {
  financers: [],
  financer: false,
  error: false,
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const financers = (state = initialState, action) => {
  switch (action.type) {
    case GET_FINANCERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FINANCERS_SUCCESS:
      state = {
        ...state,
        financers: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        loading: false,
        error: false,
      };
      break;
    case GET_FINANCER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FINANCER_SUCCESS:
      state = {
        ...state,
        financer: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case CREATE_FINANCER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_FINANCER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case UPDATE_FINANCER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_FINANCER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case DELETE_FINANCER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_FINANCER_SUCCESS:
      state = {
        ...state,
        financers:
          state.financers === undefined || state.financers === null
            ? []
            : state.financers.filter((e) => e.id !== action.payload.data.id),
        loading: false,
        error: false,
      };
      break;
    case FINANCERS_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default financers;
