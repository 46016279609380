import {
  customers,
  users,
  agencies,
  projects,
  free_items_save,
  products,
  categories,
  vats,
  sub_categories, formulas, formulasTypes, payment_states, financers, projectStates, scales, scaleItems, V2
} from "../api/index";
import { parseCustomerName } from "./customer_helper";

export function promiseCustomersOptions(inputValue, componentDidMount, client_type = null) {
  let filters = {
    exclude: this?.exclude,
  };
  return new Promise(async (resolve) => {
    if (inputValue && inputValue !== "") {
      filters.query = inputValue;
      filters.pagination = false;
    } else {
      filters.pagination = true;
      filters.sort = "updated_at DESC";
      filters.page_size = 15;
    }

    if (client_type)
      filters.client_type = client_type;

    let result = await customers.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((item) => {
        return {
          label: parseCustomerName(item),
          value: item.id,
        };
      })
    );
  });
}

export function promiseUsersOptions(roles, agency_id = null) {
  let filters = { pagination: false };
  if (roles) filters.roles = roles;
  if (agency_id) filters.agency_id = agency_id;
  return new Promise(async (resolve) => {
    let result = await users.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((user) => {
        return {
          value: user.id,
          label: `${user.firstname} ${user.lastname}`,
        };
      })
    );
  });
}

export function promiseUsersByQueryOptions(inputValue) {
  let filters = queryFilter(inputValue)
  return new Promise(async (resolve) => {
    let result = await V2.users.getAll(filters);
    resolve(
        (result.data.objects || result.data).map((user) => {
          return {
            value: user.id,
            label: `${user.firstname} ${user.lastname}`,
          };
        })
    );
  });
}


export function promiseCommercialOptions() {
  return promiseUsersOptions('Commercial');
}

export function promiseAgenciesOptions() {
  let filters = { pagination: false };
  return new Promise(async (resolve) => {
    let result = await agencies.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((agency) => {
        return {
          value: agency.id,
          label: agency.name,
        };
      })
    );
  });
}

export function promiseProjectsOptions(client_id= null) {
  let filters = { pagination: false };
  if (client_id) filters.client_id = client_id;
  return new Promise(async (resolve) => {
    let result = await projects.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((project) => {
        return {
          value: project.id,
          label: project.id,
        };
      })
    );
  });
}

export function promiseProductsOptions(inputValue, workforce = false) {
  let filters = {};
  if (inputValue && inputValue !== "") {
    filters.query = inputValue;
    filters.pagination = false;
  } else {
    filters.pagination = true;
    filters.page_size = 15;
  }
  if (workforce) filters.workforce = true;
  return new Promise(async (resolve) => {
    let result = await products.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((product) => {
        return {
          value: product.id,
          label: product.title,
        };
      })
    );
  });
}

export function promiseProductsOptionsFromIds(ids) {
  let idsString = '';
  if (ids && ids.length > 0) {
    idsString = ids.join(',');
  } else {
    idsString = '-1';
  }
  return new Promise(async (resolve) => {
    let result = await products.getAll({pagination: false, ids: idsString});
    resolve(
      (result.data.objects || result.data).map((product) => {
        return {
          value: product.id,
          label: product.title,
        };
      })
    );
  });
}

export function promiseCategoriesOptions() {
  let filters = { pagination: false };
  return new Promise(async (resolve) => {
    let result = await categories.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((category) => {
        return {
          value: category.id,
          label: category.name,
        };
      })
    );
  });
}

export function promiseSubCategoriesOptions(category_id) {
  let filters = { pagination: false };
  if (category_id) filters.category_id = category_id;
  return new Promise(async (resolve) => {
    let result = await sub_categories.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((sub_category) => {
        let label = sub_category.name
        if (sub_category.category && sub_category.category.name) {
          label = `${label} (${sub_category.category.name})`
        }
        return {
          value: sub_category.id,
          label,
        };
      })
    );
  });
}

export function promiseBrandOptions() {
  return new Promise(async (resolve) => {
    let result = await products.getBrands();
    resolve(
      (result.data.objects || result.data).map((brand) => {
        return {
          value: brand,
          label: brand,
        };
      })
    );
  });
}

export function promiseFreeItemSaves(maintenance_contract = false) {
  let filters = { pagination: false };
  if (maintenance_contract) filters.maintenance_contract = true;
  return new Promise(async (resolve) => {
    let result = await free_items_save.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((freeItemSave) => {
        return {
          value: freeItemSave,
          label: freeItemSave.free_field_safe.title.toUpperCase(),
        };
      })
    );
  });
}

export function promiseMaintenanceContract() {
  return promiseFreeItemSaves(true);
}

/**
 * @returns {Promise<unknown>} a promise with all products available as items
 */
export function promiseProductsOptionsItemsForm(inputValue) {
  let filters = queryFilter(inputValue)
  return new Promise(async (resolve) => {
    let result = await products.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((product) => {
        return {
          value: product,
          label: product.title,
        };
      })
    )
  });
}

/**
 * @returns {Promise<unknown>} a promise with all free items saved available as items
 */
export function promiseItemsSavedOptions() {
  let filters = { pagination: false };
  return new Promise(async (resolve) => {
    let result = await free_items_save.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((free_item_saved) => {
        return {
          value: {
            ...free_item_saved.free_field_safe,
            timeline_begin_at: free_item_saved.timeline_begin_at,
            quantity: free_item_saved.quantity
          },
          label: free_item_saved.free_field_safe.title,
        };
      })
    )
  });
}

/**
 * @returns {Promise<unknown>} a promise with all vats options
 */
export function promiseVatsOptions() {
  let filters = { pagination: false };
  return new Promise(async (resolve) => {
    let result = await vats.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((vat) => {
        return {
          value: vat,
          label: vat.name,
        };
      })
    )
  });
}

/**
 * @returns {Promise<unknown>} a promise with all vats with ids as value
 */
export function promiseVatsOptionsIds() {
  let filters = { pagination: false };
  return new Promise(async (resolve) => {
    let result = await vats.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((vat) => {
        return {
          value: vat.id,
          label: vat.name,
        };
      })
    )
  });
}



/**
 * @returns {Promise<unknown>} a promise with all workfoce categories products
 *
 */
export function promiseWorkforceOptions() {
  let filters = { pagination: false, workforce: true };
  return new Promise(async (resolve) => {
    let result = await products.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((product) => {
        return {
          value: product,
          label: product.description,
        };
      })
    )
  });
}

/**
 *
 * @return {Promise<unknown>} all formulas binded for selectors
 */
export function promiseFormulasOptions() {
  let filters = { pagination: false };
  return new Promise(async (resolve) => {
    let result = await formulas.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((formula) => {
        return {
          value: formula.id,
          label: formula.label
        }
      })
    );
  })
}

export function promiseFormulasTypesOptions() {
  let filters = { pagination: false };
  return new Promise(async (resolve) => {
    let result = await formulasTypes.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((formula) => {
        return {
          value: formula.id,
          label: formula.title
        }
      })
    );
  })
}

export function promisePaymentStatesOptions() {
  let filters = { pagination: false };
  return new Promise(async (resolve) => {
    let result = await payment_states.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((state) => {
        return {
          value: state.id,
          label: state.label
        }
      })
    );
  })
}

/**
 * return a promise to get project_states from api
 * @param {object} options an object with options (blacklist_labels: Array of string)
 * @return {Promise<unknown>} all project states
 */
export function promiseProjectStatesOptions(options = {}) {
  let filters = { pagination: false };
  if (options.blacklist_labels) filters.blacklist_labels = options.blacklist_labels;
  return new Promise(async (resolve) => {
    let result = await projectStates.getAll(filters);
    resolve(
      (result.data.objects || result.data).map((state) => {
        return {
          value: state.id,
          label: state.name
        }
      })
    );
  })
}

export function promiseFinancersOptions() {
  return new Promise(async (resolve) => {
    let result = await financers.getAll();
    resolve(
      (result.data.objects || result.data).map((financer) => {
        return {
          value: financer.name,
          label: financer.name
        }
      })
    );
  })
}

export function promiseScalesOptions(financeId) {
  return new Promise(async (resolve) => {
    let result = await scales.getAll({ financer_id: financeId });
    resolve(
      (result.data.objects || result.data).map((scale) => {
        return {
          value: scale.id,
          label: scale.label
        }
      })
    );
  })
}

export function promiseScaleItemsOptions(scaleId, price) {
  return new Promise(async (resolve) => {
    let result = await scaleItems.getAll({ scale_id: scaleId, price: price });
    resolve(
      (result.data.objects || result.data).map((scaleItem) => {
        return {
          value: scaleItem.id,
          label: `${scaleItem.total_loan_amount.toFixed(2)}€ en ${scaleItem.monthly_payment_qte} Mois (${scaleItem.monthly_payment.toFixed(2)}€)`
        }
      })
    );
  })
}

export function promiseCustomersOptionsForCheck(inputValue, componentDidMount, client_type = null) {
  return new Promise(async (resolve) => {
    let filters = {};
    if (inputValue && inputValue !== "") {
      filters.query = inputValue;
      filters.pagination = false;
    } else {
      filters.pagination = true;
      filters.sort = "updated_at DESC";
      filters.page_size = 15;
    }

    if (client_type)
      filters.client_type = client_type;

    let result = await customers.getAll(filters);
    resolve(
        (result.data.objects || result.data).map((item) => {
          return {
            label: parseCustomerName(item),
            value: item.id+"#-#°"+parseCustomerName(item),
          };
        })
    );
  });
}


function queryFilter(inputValue) {
  let filters = {}
  if (inputValue && inputValue !== "") {
    filters.query = inputValue
    if(inputValue.length >= 3) filters.pagination = false
  } else {
    filters.pagination = true
    filters.page_size = 15
  }
  return filters
}