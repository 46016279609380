import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  CREATE_INVOICE_FROM_ORDER,
  CREATE_INVOICE_FROM_ORDER_SUCCESS,
  CREATE_REFUND_FROM_ORDER,
  CREATE_REFUND_FROM_ORDER_SUCCESS,
  GET_GENERATE_INVOICE,
  GET_GENERATE_INVOICE_SUCCESS,
  GET_GENERATE_REFUND,
  GET_GENERATE_REFUND_SUCCESS,
  GET_GENERATE_QUOTE,
  GET_GENERATE_QUOTE_SUCCESS,
  SHOW_INVOICE,
  SHOW_INVOICE_SUCCESS,
  SHOW_REFUND,
  SHOW_REFUND_SUCCESS,
  ORDERS_API_ERROR,
} from "./actionTypes";

export const getOrders = (query) => {
  return {
    type: GET_ORDERS,
    payload: { query },
  };
};

export const getOrdersSuccess = (orders) => {
  return {
    type: GET_ORDERS_SUCCESS,
    payload: orders,
  };
};

export const getOrder = (id) => {
  return {
    type: GET_ORDER,
    payload: { id },
  };
};

export const getOrderSuccess = (orders) => {
  return {
    type: GET_ORDER_SUCCESS,
    payload: orders,
  };
};

export const createOrder = (order, history) => {
  return {
    type: CREATE_ORDER,
    payload: { order, history },
  };
};

export const createOrderSuccess = (order) => {
  return {
    type: CREATE_ORDER_SUCCESS,
    payload: order,
  };
};

export const updateOrder = (id, order, history) => {
  return {
    type: UPDATE_ORDER,
    payload: { id, order, history },
  };
};

export const updateOrderSuccess = (order) => {
  return {
    type: UPDATE_ORDER_SUCCESS,
    payload: order,
  };
};

export const deleteOrder = (id, history) => {
  return {
    type: DELETE_ORDER,
    payload: { id, history },
  };
};

export const deleteOrderSuccess = (order) => {
  return {
    type: DELETE_ORDER_SUCCESS,
    payload: order,
  };
};

export const createInvoiceFromOrder = (order, history) => {
  return {
    type: CREATE_INVOICE_FROM_ORDER,
    payload: { order, history },
  };
};

export const createInvoiceFromOrderSuccess = (order, file_url) => {
  return {
    type: CREATE_INVOICE_FROM_ORDER_SUCCESS,
    payload: { order, file_url },
  };
};

export const createRefundFromOrder = (
  invoice_id,
  history,
  order = false,
  is_free_order = false
) => {
  return {
    type: CREATE_REFUND_FROM_ORDER,
    payload: { invoice_id, history, order, is_free_order },
  };
};

export const createRefundFromOrderSuccess = (file_url) => {
  return {
    type: CREATE_REFUND_FROM_ORDER_SUCCESS,
    payload: { file_url },
  };
};

export const getGenerateInvoice = (order, values, open, history = false) => {
  return {
    type: GET_GENERATE_INVOICE,
    payload: { order, values, open, history },
  };
};

export const getGenerateInvoiceSuccess = (file_url) => {
  return {
    type: GET_GENERATE_INVOICE_SUCCESS,
    payload: file_url,
  };
};

export const getGenerateRefund = (order, values, open, history) => {
  return {
    type: GET_GENERATE_REFUND,
    payload: { order, values, open, history },
  };
};

export const getGenerateRefundSuccess = (file_url) => {
  return {
    type: GET_GENERATE_REFUND_SUCCESS,
    payload: file_url,
  };
};

export const getGenerateQuote = (id) => {
  return {
    type: GET_GENERATE_QUOTE,
    payload: { id },
  };
};

export const getGenerateQuoteSuccess = (file_url) => {
  return {
    type: GET_GENERATE_QUOTE_SUCCESS,
    payload: file_url,
  };
};

export const showInvoice = (order, isUpdate) => {
  return {
    type: SHOW_INVOICE,
    payload: { order, isUpdate },
  };
};

export const showInvoiceSuccess = (order) => {
  return {
    type: SHOW_INVOICE_SUCCESS,
    payload: order,
  };
};

export const showRefund = (
  invoice_id,
  history,
  order = false,
  isUpdate = false
) => {
  return {
    type: SHOW_REFUND,
    payload: { invoice_id, history, order, isUpdate },
  };
};

export const showRefundSuccess = (order) => {
  return {
    type: SHOW_REFUND_SUCCESS,
    payload: order,
  };
};

export const ordersApiError = (error) => {
  return {
    type: ORDERS_API_ERROR,
    payload: error,
  };
};
