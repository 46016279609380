import { GET_ROLES, GET_ROLES_SUCCESS, ROLES_API_ERROR } from "./actionTypes";

export const getRoles = (query) => {
  return {
    type: GET_ROLES,
    payload: { query },
  };
};

export const getRolesSuccess = (roles) => {
  return {
    type: GET_ROLES_SUCCESS,
    payload: roles,
  };
};

export const roleApiError = (error) => {
  return {
    type: ROLES_API_ERROR,
    payload: error,
  };
};
