import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  NOTIFICATIONS_API_ERROR,
} from "./actionTypes";

export const getNotifications = (query) => {
  return {
    type: GET_NOTIFICATIONS,
    payload: { query },
  };
};

export const getNotificationsSuccess = (notifications) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: notifications,
  };
};

export const getNotification = (id) => {
  return {
    type: GET_NOTIFICATION,
    payload: { id },
  };
};

export const getNotificationSuccess = (notification) => {
  return {
    type: GET_NOTIFICATION_SUCCESS,
    payload: notification,
  };
};

export const createNotification = (notification, history) => {
  return {
    type: CREATE_NOTIFICATION,
    payload: { notification, history },
  };
};

export const createNotificationSuccess = (notification) => {
  return {
    type: CREATE_NOTIFICATION_SUCCESS,
    payload: notification,
  };
};

export const updateNotification = (id, notification, history = null) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload: { id, notification, history },
  };
};

export const updateNotificationSuccess = (notification) => {
  return {
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload: notification,
  };
};

export const deleteNotification = (id, history = null) => {
  return {
    type: DELETE_NOTIFICATION,
    payload: { id, history },
  };
};

export const deleteNotificationSuccess = (notification) => {
  return {
    type: DELETE_NOTIFICATION_SUCCESS,
    payload: notification,
  };
};

export const notificationsApiError = (error) => {
  return {
    type: NOTIFICATIONS_API_ERROR,
    payload: error,
  };
};
