// Modals components
import {
    Modal,
    ModalHeader,
    ModalBody,
  } from "reactstrap";

  // Customer form 
import CustomerForm from "../../../Quotes/FormComponent/CustomerForm";

// Validation for the customer form
import {createCustomerValidationSchema} from "../../../Quotes/FormValidationSchemas/createCustomerValidationSchema";

import {connect} from "react-redux";
import {updateCustomer, createCustomer} from "../../../../store/customers/actions";
import {customers} from "../../../../api";

/**
 * @param isOpen defined if the modal is open
 * @param toggler function toggling the modal
 * **/
const CustomerModal = ({
    isOpen,
    toggler,
    customer = null,
    createCustomer,
    handleSuccess
}) => {

    // create a new customer using an the createCustomer action 
    const handleSubmit = (values) => {
      if(!customer)
        createCustomer({
          civility: values.civility,
          firstname: values.firstname,
          lastname: values.lastname,
          firstname2: values.firstname2,
          lastname2: values.lastname2,
          address: values.address,
          additional_address: values.additional_address,
          zip_code: values.zip_code,
          city: values.city,
          address_construction_site: values.address_construction_site,
          additional_address_construction_site: values.additional_address_construction_site,
          city_construction_site: values.city_construction_site,
          zip_code_construction_site: values.zip_code_construction_site,
          mobile_phone: values.mobile_phone,
          mobile_phone2: values.mobile_phone2,
          fix_phone: values.fix_phone,
          email: values.email,
          client_type: values.client_type,
          company_name: values.company_name,
          siret: values.siret,
        });
        else
          customers.update(customer.id, {
            civility: values.civility,
            firstname: values.firstname,
            lastname: values.lastname,
            firstname2: values.firstname2,
            lastname2: values.lastname2,
            address: values.address,
            additional_address: values.additional_address,
            zip_code: values.zip_code,
            city: values.city,
            address_construction_site: values.address_construction_site,
            additional_address_construction_site: values.additional_address_construction_site,
            city_construction_site: values.city_construction_site,
            zip_code_construction_site: values.zip_code_construction_site,
            mobile_phone: values.mobile_phone,
            mobile_phone2: values.mobile_phone2,
            fix_phone: values.fix_phone,
            email: values.email,
            client_type: values.client_type,
            company_name: values.company_name,
            siret: values.siret,
          }).then(r => {
            console.log("SUCCESS")
            handleSuccess();
            toggler();
          });

    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                toggler();
            }}
            size="lg"
        >
            <ModalHeader toggle={() => toggler()} tag="h4">
                Créer un prospect
            </ModalHeader>
            <ModalBody>
                <CustomerForm
                    initValues={{
                      civility: "" || customer.civility,
                      firstname: "" || customer.firstname,
                      lastname: "" || customer.lastname,
                      firstname2: "" || customer.firstname2,
                      lastname2: "" || customer.lastname2,
                      address: "" || customer.address,
                      additional_address: "" || customer.additional_address,
                      zip_code: "" || customer.zip_code,
                      city: "" || customer.city,
                      mobile_phone: "" || customer.mobile_phone,
                      mobile_phone2: "" || customer.mobile_phone2,
                      fix_phone: "" || customer.fix_phone,
                      email: "" || customer.email,
                      address_construction_site: "" || customer.address_construction_site,
                      additional_address_construction_site: "" || customer.additional_address_construction_site,
                      city_construction_site: "" || customer.city_construction_site,
                      zip_code_construction_site: "" || customer.zip_code_construction_site,
                      client_type: "Particulier" || customer.client_type,
                      company_name: "" || customer.company_name,
                      siret: "" || customer.siret,
                    }}
                    validationSchema={createCustomerValidationSchema}
                    handleSubmit={handleSubmit}
                    isUpdate={!!customer}
                />
            </ModalBody>
        </Modal>
    );

}

export default connect(null, {
  createCustomer,
  updateCustomer
})(CustomerModal);