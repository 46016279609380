import {
  CREATE_DEPOSIT,
  CREATE_DEPOSIT_SUCCESS,
  UPDATE_DEPOSIT,
  UPDATE_DEPOSIT_SUCCESS,
  DEPOSITS_API_ERROR,
} from "./actionTypes";

export const createDeposit = (order_id, deposit, open, history) => {
  return {
    type: CREATE_DEPOSIT,
    payload: { order_id, deposit, open, history },
  };
};

export const createDepositSuccess = (deposit) => {
  return {
    type: CREATE_DEPOSIT_SUCCESS,
    payload: deposit,
  };
};

export const updateDeposit = (deposit_id, deposit, history = false) => {
  return {
    type: UPDATE_DEPOSIT,
    payload: { deposit_id, deposit, history },
  };
};

export const updateDepositSuccess = (deposit) => {
  return {
    type: UPDATE_DEPOSIT_SUCCESS,
    payload: deposit,
  };
};

export const depositsApiError = (error) => {
  return {
    type: DEPOSITS_API_ERROR,
    payload: error,
  };
};
