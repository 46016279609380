import { useEffect, useMemo, useState } from "react"
import OptionValue, { NumberOptionValue } from "../../../../models/types/option_value";
import Select from "react-select";
import Project, { ComptantPaiementTypeEnum, TypeOfFinancementEnum } from "../../../../models/project";
import { Button } from "reactstrap";
import Financer from "../../../../models/financer";
import { financers, scales, scaleItems } from "../../../../api";
import SelectComponent, { SelectComponentOptionParam } from "../../../Quotes/Components/SelectComponent";
import { promiseScaleItemsOptions, promiseScalesOptions } from "../../../../helpers/autocomplete_promise_options";
import { getLastChildOrder } from "../../../../helpers/helper";

export interface FinancementFormValues {
  typeOfFinancement: TypeOfFinancementEnum | null,
  comptantPaiementType: ComptantPaiementTypeEnum | null,
  financer: number | null,
  scale: number | null,
  scaleItem: number | null,
  financementTotalPrice: number | null,
  distributeStaff: number | null,
}

interface FinancementFormProps {
  project: Project,
  handleSubmit: (values: FinancementFormValues) => void
}

const FinancementForm = ({ project, handleSubmit }: FinancementFormProps) => {

  let last_order = useMemo(()=>getLastChildOrder(project), [project]);

  const [typeOfFinancement, setTypeOfFinancement] = useState<OptionValue<string, TypeOfFinancementEnum> | null>(
    project.type_of_financement
      ? {
        value: project.type_of_financement,
        label: project.type_of_financement.toUpperCase(),
      }
      : null
  );
  const [comptantPaiementType, setComptantPaiementType] = useState<OptionValue<string, ComptantPaiementTypeEnum> | null>(
    project.comptant_paiement_type
      ? {
        value: project.comptant_paiement_type,
        label: project.comptant_paiement_type.toUpperCase(),
      }
      : null
  );
  const [financer, setFinancer] = useState<NumberOptionValue | null>(null);
  const [scale, setScale] = useState<NumberOptionValue | null>(null);
  const [scaleItem, setScaleItem] = useState<NumberOptionValue | null>(null);
  const [financerList, setFinancerList] = useState<Financer[]>([]);
  const [financementTotalPrice, setFinancementTotalPrice] = useState<number>(0);
  const [distributeStaff, setDistributeStaff] = useState<number>(last_order?.total_price_ttc || 0);

  useEffect(() => {
    Promise.all([
      financers.getAll({pagination: false}),
      project.scale_id ? scales.getOne(project.scale_id) : null,
      project.scale_item_id ? scaleItems.getOne(project.scale_item_id) : null
    ]).then(values => {
      setFinancerList(values[0].data)

      let financer = values[0].data.find(e => e.id === project.financer_id)
      if (financer) setFinancer({label: financer.name!, value: financer.id!})

      if (values[1]) {
        setScale({label: values[1].data.label!, value: values[1].data.id!})
      }

      if (values[2]) {
        let scaleItem = values[2].data;
        setScaleItem({label: `${scaleItem.total_loan_amount!.toFixed(2)}€ en ${scaleItem.monthly_payment_qte!} Mois (${scaleItem.monthly_payment!.toFixed(2)}€)`, value: values[2].data.id!})
        setFinancementTotalPrice(scaleItem.total_loan_amount!)
        setDistributeStaff(last_order?.total_price_ttc! - scaleItem.total_loan_amount!)
      }
    })
  }, [last_order?.total_price_ttc, project.scale_id, project.scale_item_id, project.financer_id]);

  return (
    <>
      <div className="financement-container mt-3">
        <div className="financement-row">
          <div className="financement-item">
            <p className="w-50">Type de paiement :</p>
            <Select
              className="w-50 flex-unset"
              noOptionsMessage={() => "Pas de choix disponible"}
              placeholder="Type de paiement"
              options={Object.values(TypeOfFinancementEnum).map(e => { return { value: e, label: e.toUpperCase() } })}
              value={typeOfFinancement}
              onChange={(typeOfFinancement) => {
                setTypeOfFinancement(typeOfFinancement)
                if (typeOfFinancement?.value === TypeOfFinancementEnum.FINANCEMENT) {
                  setComptantPaiementType(null)
                } else if (typeOfFinancement?.value === TypeOfFinancementEnum.COMPTANT) {
                  setFinancer(null)
                  setScale(null)
                  setScaleItem(null)
                  setFinancementTotalPrice(0)
                  setDistributeStaff(project.order?.total_price_ttc!)
                }
              }}
            />
          </div>
        </div>
        {
          typeOfFinancement?.value === TypeOfFinancementEnum.COMPTANT && <div className="financement-row">
            <div className="financement-item">
              <p className="w-50">Solde à la réception des travaux :</p>
              <Select
                className="w-50 flex-unset"
                noOptionsMessage={() => "Pas de choix disponible"}
                placeholder="Solde à la réception des travaux"
                options={Object.values(ComptantPaiementTypeEnum).map(e => { return { value: e, label: e.toUpperCase() } })}
                value={comptantPaiementType}
                onChange={(comptantPaiementType) => {
                  setComptantPaiementType(comptantPaiementType)
                }}
              />
            </div>
          </div>
        }
        {
          typeOfFinancement?.value === TypeOfFinancementEnum.FINANCEMENT && <div className="financement-row">
            <div className="financement-item">
              <p className="w-50">Financeur :</p>
              <Select
                className="w-50 flex-unset"
                noOptionsMessage={() => "Pas de choix disponible"}
                placeholder="Financeur"
                options={financerList.map(e => { return { value: e.id!, label: e.name! } })}
                value={financer}
                onChange={(financer) => {
                  setFinancer(financer)
                }}
              />
            </div>
          </div>
        }
        {
          typeOfFinancement?.value === TypeOfFinancementEnum.FINANCEMENT && financer && <div className="financement-row">
            <div className="financement-item">
              <p className="w-50">Barème :</p>
              <SelectComponent
                placeholder={"Choisir un barème"}
                isAsync={true}
                options={() => promiseScalesOptions(financer.value) as Promise<SelectComponentOptionParam[]>}
                value={scale}
                handleChange={(target:any) => {
                  setScale(target)
                }}
                className="w-50 flex-unset"
              />
            </div>
          </div>
        }
        {
          typeOfFinancement?.value === TypeOfFinancementEnum.FINANCEMENT && scale && <div className="financement-row">
            <div className="financement-item">
              <p className="w-50">Élément du barème :</p>
              <SelectComponent
                placeholder={"Choisir un élément du barème"}
                isAsync={true}
                options={(price) => promiseScaleItemsOptions(scale.value, price)}
                value={scaleItem}
                handleChange={(target) => {
                  setScaleItem(target)
                  if (target) {
                    scaleItems.getOne(target.value).then(data => {
                      let scaleItem = data.data
                      setFinancementTotalPrice(scaleItem.total_loan_amount!)
                      setDistributeStaff(project.order?.total_price_ttc! - scaleItem.total_loan_amount!)
                    })
                  }
                }}
                className="w-50 flex-unset"
              />
            </div>
          </div>
        }
        {
          typeOfFinancement?.value === TypeOfFinancementEnum.FINANCEMENT && <div className="financement-row">
            <div className="financement-item">
              <p className="w-25">Montant total du financement :</p>
              <p className="w-25">{`${financementTotalPrice.toFixed(2)} €`}</p>
              <p className="w-25">Apport personnel :</p>
              <p className="w-25">{`${distributeStaff.toFixed(2)} €`}</p>
            </div>
          </div>
        }
      </div>
      <div>
        <div className="text-center">
          <Button
            color="none"
            className="btn-green"
            onClick={() => handleSubmit({
              typeOfFinancement: typeOfFinancement?.value || null,
              comptantPaiementType: comptantPaiementType?.value || null,
              financer: financer?.value || null,
              scale: scale?.value || null,
              scaleItem: scaleItem?.value || null,
              financementTotalPrice,
              distributeStaff
            })}
          >
            Sauvegarder les modifications
          </Button>
        </div>
      </div>
    </>
  );
}

export default FinancementForm;