import { useCallback, useEffect, useState } from "react";
// Redux
import { connect } from "react-redux";
// routers
import { withRouter } from "react-router-dom";
// helpers
import { formatItems, sortDataByPositions } from "../../../helpers/free_fields_helper";
import {
    computeDefaultFormValues, computeInvoiceEditionValues, computeInvoiceOrderValues,
    computeQuoteEditionValues,
    computeQuoteOrderValues, computeTotalItemsPrices,
    typeForm
} from "../../../helpers/quotes_invoices_helper";
import {
    createInvoiceFromOrder,
    createRefundFromOrder,
    showInvoice,
    showRefund,
    updateOrder
} from "../../../store/orders/actions";
// Actions
import { createQuote, quotesApiError, showQuote, updateQuote } from "../../../store/quotes/actions";
import { getVats, vatsApiError } from "../../../store/vats/actions";
// Contexts
import ValuesContext from "../Context/ValuesContext";
import VatsContext from "../Context/VatsContext";
// Components
import GlobalQuoteInvoiceForm from "../FormComponent/GlobalQuoteInvoiceForm";
import ValidationModal from "../Modals/ValidationModal";

const QuoteInvoiceComponent = (props) => {

    // Init values if the quote is new
    const [values, setValues] = useState(computeDefaultFormValues(props.typeForm));

    // True if the preview is done once, fixed the invoice_order/quote_order initializing when not refreshing the list page
    const [previewDone, setPreviewDone] = useState(false);

    // Modal for errors and infos
    const [infoModal, setInfoModal] = useState(false);

    /***********************************************************
     * If the quote_order is initialized and != false, return the
     * initValues (usefull for edition of quote after a preview)
     *
     * When it's a quote ->
     * The quote_order prop takes precedence over prop quote and order
     * then come the default value
     *
     * When it's an invoice ->
     * The invoice_order prop takes precedence over prop invoice then come
     * the default value
     *
     * When it's a refund ->
     * Same as the invoice (a refund is an invoice on another invoice)
     *
     * quote_order and invoice_order are updated when a preview is done,
     * quote, order and invoice are just initial values that are updated when
     * the page is refreshed or when you get to the page
     *
     * @return {object} init values depending of the props that are changed
     ************************************************************/
    const initValuesQuote = useCallback(() => {
        let obj = computeDefaultFormValues(props.typeForm);
        // If we get a preview, the quote_order props init the values
        if ((previewDone || props.isUpdate) && props.quote_order) {

            obj = computeQuoteOrderValues(props.quote_order)

            // If it's an edition or an approval, the prop quote_order is undefined so the prop quote init the values
        } else if (
            (props.quote && props.typeForm === typeForm.quote) ||
            (props.order && props.typeForm === typeForm.approval)) {

            let client = null;
            let order = null;
            let customQuoteDate = null;

            if (props.typeForm === typeForm.quote) {
                client = props.quote.order.client;
                order = props.quote.order;
                customQuoteDate = props.quote.custom_quote_date;
            } else {
                client = props.order.client;
                order = props.order;
                customQuoteDate = props.order.quote.custom_quote_date ? props.order.quote.custom_quote_date : new Date() ;
            }

            // Using the helper function rerender too many times the component
            obj = computeQuoteEditionValues(props.typeForm, order, client, customQuoteDate);

        } else if (props.typeForm === typeForm.invoice && props.invoice_order && previewDone) {

            obj = computeInvoiceOrderValues(props.invoice_order, props.invoice_order.client);

        } else if ((props.typeForm === typeForm.invoice || props.typeForm === typeForm.refund) &&
            (props.invoice || (props.invoice_order && previewDone)) &&
            (props.isUpdate || previewDone)) {

            let order = null;
            let client = null;
            let invoice = null;

            // Compute init values for invoices
            if(props.invoice_order && previewDone) {
                order = props.invoice_order;
                client = props.invoice_order.client;
                invoice = props.invoice_order.invoices[props.invoice_order.invoices.length - 1];
            }else{
                order = props.invoice.order;
                client = order.client;
                invoice = props.invoice;
            }

            obj = computeInvoiceEditionValues(order, client, invoice);

            if (props.typeForm === typeForm.invoice) {
                obj.total_price_deal_ht = order.total_price_deal_ht;
                obj.total_price_deal_ttc = order.total_price_deal_ttc;
            } else {
                obj.refund_on = invoice.refund_on;
            }

        }

        return obj;
    }, [previewDone, props.invoice, props.invoice_order, props.isUpdate, props.order, props.quote, props.quote_order, props.typeForm])

    // Handle change for the values passed in context
    const handleValuesChange = useCallback((name, newValue) => {
        setValues(prev => ({
            ...prev,
            [name]: newValue,
        }));
    }, []);

    /**
     * Update the initial values when an invoice or a quote is created/preview/updated
     * props concerned :
     * - quote_order
     * - quote
     * - invoice
     * - invoice_order
     */
    useEffect(() => {
        // When quote_order is change, it means that the quote is created so we change the initValues
        const initValues = initValuesQuote();
        initValues.free_fields = sortDataByPositions(initValues.free_fields);
        setValues(initValues);
    }, [initValuesQuote]);

    useEffect(() => {
        props.getVats.call();
    }, [props.getVats]);


    /**********************************************************
     * HANDLE SUBMIT FOR QUOTE FORM
     *
     * Create, update or show quote when created/updated by the quote form
     * @param isShowing if true, that's means that's a preview
     **********************************************************/
    const handleQuoteSubmit = (isShowing) => {
        if (props.typeForm === typeForm.quote) {
            if (props.quote_order) {
                values.parent_id = props.quote_order.id;
            } else if (props.quote) {
                values.parent_id = props.quote.order.id;
            }
        } else if (props.typeForm === typeForm.approval) {
            values.parent_id = props.order.id;
        }

        const items = formatItems(values.free_fields, values.removed_free_fields);

        if (isShowing) {
            props.showQuote(
                {
                    customer: values.customer.value,
                    product_items: items.productItems,
                    free_fields: items.freeItems,
                    eco_tax: values.eco_tax,
                    cce_prime: values.cce_prime,
                    cee_label: values.cee_label,
                    other_prime_label: values.other_prime_label,
                    other_prime_amount: values.other_prime_amount,
                    custom_quote_date: values.custom_quote_date,
                    quote: {
                        custom_quote_date: values.custom_quote_date,
                    },
                    parent_id: values.parent_id,
                },
                (props.quote_order ? props.quote_order : (props.quote ? props.quote.order : null))
            );
        } else {
            if (props.quote_order || props.quote) {
                props.updateQuote(
                    props.quote_order ? props.quote_order.id : props.quote.order.id,
                    {
                        customer: values.customer.value,
                        product_items: items.productItems,
                        free_fields: items.freeItems,
                        eco_tax: values.eco_tax,
                        cce_prime: values.cce_prime,
                        cee_label: values.cee_label,
                        other_prime_label: values.other_prime_label,
                        other_prime_amount: values.other_prime_amount,
                        quote: {
                            custom_quote_date: values.custom_quote_date,
                        },
                    },
                    props.history
                )
            } else {
                props.createQuote(
                    {
                        customer: values.customer.value,
                        product_items: items.productItems,
                        free_fields: items.freeItems,
                        eco_tax: values.eco_tax,
                        cce_prime: values.cce_prime,
                        cee_label: values.cee_label,
                        other_prime_label: values.other_prime_label,
                        other_prime_amount: values.other_prime_amount,
                        quote: {
                            custom_quote_date: values.custom_quote_date,
                        },
                        parent_id: values.parent_id,
                    },
                    props.history
                )
            }
        }
    };


    /**********************************************************
     * HANDLE SUBMIT FOR INVOICE FORM
     *
     * Create, update or show invoice when created/updated by the invoice form
     * @param isShowing if true, that's means that's a preview
     **********************************************************/
    const handleInvoiceSubmit = (isShowing) => {

        const items = formatItems(values.free_fields, values.removed_free_fields);

        if (isShowing) {
            props.showInvoice(
                {
                    customer: values.customer.value,
                    product_items: items.productItems,
                    free_fields: items.freeItems,
                    eco_tax: values.eco_tax,
                    total_price_deal_ht: values.total_price_deal_ht,
                    total_price_deal_ttc: values.total_price_deal_ttc,
                    cce_prime: values.cce_prime,
                    cee_label: values.cee_label,
                    other_prime_label: values.other_prime_label,
                    other_prime_amount: values.other_prime_amount,
                    custom_invoice_date: values.custom_invoice_date,
                    invoice_date: values.custom_invoice_date,
                    invoice: {
                        custom_invoice_date: values.custom_invoice_date,
                        payment_mode: values.payment_mode
                    },
                    payment_mode: values.payment_mode,
                },
                ((props.invoice_order && previewDone) ? props.invoice_order : (props.invoice ? props.invoice.order : null))
            );
        } else {
            if (props.invoice_order || props.invoice) {
                props.updateOrder(
                    (props.invoice_order ? props.invoice_order.id : props.invoice.order.id),
                    {
                        customer: values.customer.value,
                        free_fields: items.freeItems,
                        eco_tax: values.eco_tax,
                        total_price_deal_ht: values.total_price_deal_ht,
                        total_price_deal_ttc: values.total_price_deal_ttc,
                        cce_prime: values.cce_prime,
                        cee_label: values.cee_label,
                        other_prime_label: values.other_prime_label,
                        other_prime_amount: values.other_prime_amount,
                        invoice: {
                            custom_invoice_date: values.custom_invoice_date,
                            payment_mode: values.payment_mode
                        },
                        invoice_date: values.custom_invoice_date,
                        product_items: items.productItems,
                        payment_mode: values.payment_mode,
                    },
                    props.history
                );
            } else {
                props.createInvoiceFromOrder(
                    {
                        customer: values.customer.value,
                        product_items: items.productItems,
                        free_fields: items.freeItems,
                        eco_tax: values.eco_tax,
                        total_price_deal_ht: values.total_price_deal_ht,
                        total_price_deal_ttc: values.total_price_deal_ttc,
                        cce_prime: values.cce_prime,
                        cee_label: values.cee_label,
                        other_prime_label: values.other_prime_label,
                        other_prime_amount: values.other_prime_amount,
                        custom_invoice_date: values.custom_invoice_date,
                        invoice_date: values.custom_invoice_date,
                        payment_mode: values.payment_mode,
                    },
                    props.history
                );
            }
        }
    }

    /**********************************************************
     * HANDLE SUBMIT FOR REFUND FORM
     *
     * Create, update or show refund when created/updated by the refund form
     * @param isShowing if true, that's means that's a preview
     **********************************************************/
    const handleRefundSubmit = (isShowing) => {

        const items = formatItems(values.free_fields, values.removed_free_fields);

        let invoiceId = null;
        let order = null;

        if (props.invoice_order && previewDone) {
            invoiceId = props.invoice_order.invoices[props.invoice_order.invoices.length - 1].id;
            order = props.invoice_order;
        } else if (props.invoice && props.isUpdate) {
            invoiceId = props.invoice.id
            order = props.invoice.order;
        }

        let data = {
            refund_on: values.refund_on,
            id: order ? order.id : null,
            customer: values.customer.value,
            product_items: items.productItems,
            free_fields: items.freeItems,
            eco_tax: values.eco_tax,
            cce_prime: values.cce_prime,
            cee_label: values.cee_label,
            other_prime_label: values.other_prime_label,
            other_prime_amount: values.other_prime_amount,
            custom_invoice_date: values.custom_invoice_date,
            invoice_date: values.custom_invoice_date,
            payment_mode: values.payment_mode,
        }

        if (isShowing) {
            props.showRefund(
                invoiceId,
                props.history,
                data,
                order
            );
        } else {
            props.createRefundFromOrder(
                invoiceId,
                props.history,
                data,
                true
            );
        }
    }


    /**
     * Compute witch handle function will be passed as prop
     */
    const handleSubmit = (isShowing) => {
        if (props.loadingOrder || props.loadingQuote) return;

        if (props.typeForm === typeForm.quote || props.typeForm === typeForm.approval) {
            handleQuoteSubmit(isShowing);

            if (isShowing)
                setPreviewDone(true);

        } else if (props.typeForm === typeForm.invoice) {
            let {_taxeslessPrice, _taxesfullPrice} = computeTotalItemsPrices(values.free_fields);

            if (_taxeslessPrice >= 0 && _taxesfullPrice >= 0) {
                handleInvoiceSubmit(isShowing);

                if (isShowing)
                    setPreviewDone(true);
            } else {
                setInfoModal(true);
            }

        } else if (props.typeForm === typeForm.refund) {

            let {_taxeslessPrice, _taxesfullPrice} = computeTotalItemsPrices(values.free_fields);

            if (_taxeslessPrice <= 0 && _taxesfullPrice <= 0) {
                handleRefundSubmit(isShowing);

                if (isShowing)
                    setPreviewDone(true);
            } else {
                setInfoModal(true);
            }


        }
    }

    return (
        <ValuesContext.Provider value={
            {
                values: values,
                setValues: handleValuesChange,
                rowSetValues: setValues
            }}>

            <VatsContext.Provider value={props.vats}>
                <GlobalQuoteInvoiceForm
                    handleSubmit={handleSubmit}
                    isLoading={props.loadingQuote || props.loadingOrder}
                    isUpdate={props.isUpdate}
                    isAddUpdate={(props.quote_order || props.invoice_order)}
                    error={props.error}
                    history={props.history}
                    invoice={props.invoice}
                    order={props.quote_order || props.invoice_order || (props.quote ? props.quote.order : false)}
                    quote={props.quote ? props.quote : false}
                    formType={props.typeForm}
                />
                <ValidationModal
                    isOpen={infoModal}
                    toggler={() => setInfoModal(!infoModal)}
                    title="Attention !"
                    message={"Le prix total de l'avoir doit être " + (props.typeForm === typeForm.refund ? "négatif" : "positif") + "." }
                    btnBackText="Retour"
                />
            </VatsContext.Provider>
        </ValuesContext.Provider>
    )
        ;
}

const mapStatetoProps = (state) => {
    const {error} = state.Quotes;
    const loadingQuote = state.Quotes.loading;
    const loadingOrder = state.Orders.loading;
    const {vats} = state.Vats;


    return {vats, loadingQuote, error, loadingOrder};
};

export default withRouter(
    connect(mapStatetoProps, {
        createQuote,
        updateQuote,
        showQuote,
        getVats,
        quotesApiError,
        vatsApiError,
        updateOrder,
        showInvoice,
        createInvoiceFromOrder,
        showRefund,
        createRefundFromOrder,

    })(QuoteInvoiceComponent)
);
