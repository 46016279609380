import {useCallback, useEffect, useState} from 'react';

import "../../assets/scss/bootstrap-dark.scss";

// Components
import {
  Card, CardBody,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FilterComponent from "../../components/Common/FilterComponent";
import {Link, withRouter} from "react-router-dom";
import FormulasTable from "./Components/FormulasTable";
import {connect} from "react-redux";
import {getFormulas, deleteFormula} from "../../store/formulas/actions";
import GlobalLoader from "../../components/Common/GlobalLoader";
import FormulasFilter from "./Filters/FormulasFilter";
import {parseQuery, setQuery} from "../../helpers/helper";
import FlashMessageComponent from "../../components/Common/FlashMessageComponent";

const FormulasList = (props) => {

  const query = parseQuery();

  const defaultFilterValues = {
    page: 1,
    page_size: 25,
    formulas_type: '',
    query: '',
  }

  const paramsFilterValues = {
    page: query.page || 1,
    page_size: query.page_size || 25,
    formulas_type: query.formulas_type || '',
    query: query.query || '',
  }

  const [filter, setFilter] = useState(paramsFilterValues);
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const getQuery = useCallback(() => {

    return {
      page: filter.page,
      page_size: filter.page_size,
      formule_type: filter.formulas_type,
      query: filter.query,
    };
  }, [filter]);

  useEffect(() => {
    setQuery(filter, props.history);
    props.getFormulas.call(undefined,getQuery())
  }, [filter, getQuery, props.getFormulas, props.history]);

  return (
    <div className="page-content">
      { props.loading ? <GlobalLoader /> :
      <Container fluid className="invoices-container">
        <Breadcrumbs title="Abonnements" breadcrumbItem="Formules"/>
        <Card>
          <FlashMessageComponent/>
          <CardBody>
            {/* Card title */}
            <CardTitle className="mb-3">
              <Row className="pr-2 pl-2 d-flex justify-content-between">
                <Col>Toutes les formules</Col>
                <Col className="d-flex justify-content-end">
                  <Link
                    to="formula/add"
                    className="d-flex align-items-center text-theme-color-2"
                    //onClick={() => tog_standard()}
                  >
                    <i className="bx bx-plus-circle mr-1>"/> Ajouter
                  </Link>
                </Col>
              </Row>
            </CardTitle>

            {/* Formulas list filter */}
            <FilterComponent
              filter={filter}
              setFilter={setFilter}
              defaultFilterValues={defaultFilterValues}
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
            >
              <FormulasFilter/>
            </FilterComponent>

            {/* Formulas table list */}

              <FormulasTable
                setFilters={setFilter}
                data={props.formulas}
                filters={filter}
                pagination={props.pagination}
                deleteFormula={props.deleteFormula}
                history={props.history}
              />

          </CardBody>
        </Card>
      </Container>}
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    formulas,
    loading,
    error,
    pagination,
  } = state.Formulas

  return {
    formulas,
    loading,
    error,
    pagination
  }
}

export default withRouter(
  connect(mapStateToProps, {
    getFormulas,
    deleteFormula,
  })(FormulasList)
);