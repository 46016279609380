import React from "react";
import { Field } from "formik";
import Select from "react-select";

const colorStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
        ? "#62d2a2"
        : isFocused
          ? "#62d2a2"
          : null,
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#62d2a2",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: "#dc3545",
      color: "white",
    },
  }),
};

const SelectField = ({
  options,
  field,
  form,
  disabled,
  multiple,
  defaultValue,
  value,
  className,
  onChangeField,
}) => {
  const onChange = (option) => {
    if (option === null) {
      option = [];
    }

    form.setFieldValue(
      field.name,
      multiple ? option.map((item) => item.value) : option.value
    );
    if (onChangeField !== null) onChangeField({ value: option.value, label: option.label });
  };

  const getValue = () => {
    if (value !== null && value !== undefined) return value;
    if (options) {
      return multiple
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    }
    return multiple ? [] : "";
  };

  return (
    <React.Fragment>
      {multiple ? (
        <Select
          placeholder="Sélectionner"
          options={options}
          name={field.name}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={field.onBlur}
          isLoading={disabled}
          isDisabled={disabled}
          noOptionsMessage={() => "Aucune correspondance"}
          isMulti={multiple}
          styles={colorStyles}
          className={className}
        />
      ) : (
          <Select
            placeholder="Sélectionner"
            options={options}
            name={field.name}
            value={getValue()}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={field.onBlur}
            isLoading={disabled}
            isDisabled={disabled}
            noOptionsMessage={() => "Aucune correspondance"}
            isMulti={multiple}
            styles={colorStyles}
            className={className}
          />
        )}
    </React.Fragment>
  );
};
/**
 * @param {object} params
 * @param {string} params.name
 * @param {object} params.options
 * @param {string | null=} params.id
 * @param {boolean=} params.disabled
 * @param {boolean=} params.invalid
 * @param {boolean=} params.multiple - Is multiple selection possible
 * @param {object=} params.defaultValue
 * @param {object=} params.className
 * */
const FormikAutocomplete = ({
  name,
  options,
  id = null,
  disabled = false,
  invalid = false,
  multiple = false,
  defaultValue = null,
  value = null,
  className = null,
  onChange = null,
}) => (
    <Field
      id={id}
      name={name}
      component={SelectField}
      options={options}
      disabled={disabled}
      invalid={invalid}
      multiple={multiple}
      defaultValue={defaultValue}
      value={value}
      className={className}
      onChangeField={onChange}
    />
  );

export default FormikAutocomplete;
