import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import queryString from "query-string";
import AgendaItem from "../models/agenda_item";
import BankAccount from "../models/bank_account";
import CheckDeposit from "../models/check-deposit";
import Client from "../models/client";
import Financer from "../models/financer";
import InterventionFolder from "../models/intervention_folder";
import Mandate from "../models/mandate";
import {MapItem, MapItemFilters} from "../models/map_item";
import Offer, {OffersFilters, PromotableTypeEnum} from "../models/offer";
import OfferCode, {OfferCodesFilters} from "../models/offer_code";
import PaymentState from "../models/payment_state";
import Product from "../models/product";
import Project from "../models/project";
import ProjectState from "../models/project_state";
import Request from "../models/request";
import RequestPriority from "../models/requests_priority";
import RequestStatus from "../models/requests_status";
import RequestType from "../models/requests_type";
import Scale from "../models/scale";
import ScaleItem from "../models/scale_item";
import Subscription from "../models/subscription";
import DefaultFilter from "../models/types/default_filter";
import {PaginateIndexResponse} from "../models/types/response";
import User from "../models/user";

// export const BASE_URL = "https://api.nwe.dev.unova.eu";
export const BASE_URL = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = BASE_URL;

// Middleware that adds the JWT to every API call
axios.interceptors.request.use(
    (config) => {
        const JWT = localStorage.getItem("nwe_jwt");
        if (JWT) {
            config.headers["Authorization"] = "Bearer " + JWT;
        } else {
            delete config.headers["Authorization"];
        }

        config.headers["Platform-Serializer"] = "dashboard";

        return config;
    },
    (err) => Promise.reject(err)
);

// Function that will be called to refresh authorization
const refreshAuthLogic = async (failedRequest) => {
    if (failedRequest.response.config.url !== "/login") {
        try {
            let response = await axios.post(
                "/login",
                {
                    grant_type: "refresh_token",
                    refresh_token: localStorage.getItem("nwe_refresh_jwt"),
                    plateform: "dashboard",
                },
                {
                    validateStatus: function (status) {
                        return true;
                    },
                }
            );
            if (response.status === 401) throw response;
            const JWT = response.data.access_token;
            const refresh_JWT = response.data.refresh_token;
            localStorage.setItem("nwe_jwt", JWT);
            localStorage.setItem("nwe_refresh_jwt", refresh_JWT);
            localStorage.setItem("authUser", JSON.stringify(response.data.user));
            localStorage.setItem("roleUser", JSON.stringify(response.data.role));
            failedRequest.response.config.headers["Authorization"] = "Bearer " + JWT;
            return response;
        } catch (error) {
            localStorage.removeItem("authUser");
            localStorage.removeItem("roleUser");
            localStorage.removeItem("nwe_jwt");
            localStorage.removeItem("nwe_refresh_jwt");
            delete axios.defaults.headers["Authorization"];
            window.location.href = "/login";
        }
    }
};

createAuthRefreshInterceptor(axios, refreshAuthLogic);

export type ParseParamsType = any;

const parseParams = (params: ParseParamsType) => {
    params = Object.assign({}, params) // Create a copy to avoid to set object reference
    if (params && params.sort_by && params.sort_dir) {
        params.sort = `${params.sort_by} ${params.sort_dir}`.trim()
        delete params.sort_by;
        delete params.sort_dir;
    }

    Object.keys(params).forEach((key) => {
        if (params[key] instanceof Date) {
            params[key] = params[key].toISOString();
        }
    })

    return '?' + queryString.stringify(
        params,
        {
            skipEmptyString: true,
            skipNull: true,
            arrayFormat: 'bracket-separator',
            arrayFormatSeparator: ','
        }
    );
};

export const auth = {
    login: (login: string, password: string) =>
        axios.post("/login", {
            email: login,
            password,
            grant_type: "password",
            plateform: "dashboard",
        }),
    logout: () => axios.post("/logout"),
};

export const users = {
    getAll: (params: ParseParamsType) => axios.get(`/users${parseParams(params)}`),
    getOne: (id: number) => axios.get(`/users/${id}`),
    create: (user) =>
        axios.post("/users", {
            email: user.email,
            password: user.password,
            password_confirmation: user.password_confirmation,
            role_id: user.role,
            agency_ids: user.agencies,
            firstname: user.firstname,
            lastname: user.lastname,
            entry_date: user.entryDate,
            phone: user.phone,
            address: user.address,
            comment: user.comment,
            contract_type: user.contractType,
            birth_date: user.birthDate,
        }),
    update: (id: number, user) =>
        axios.patch(`/users/${id}`, {
            email: user.email,
            password: user.password,
            password_confirmation: user.password_confirmation,
            role_id: user.role,
            agency_ids: user.agencies,
            firstname: user.firstname,
            lastname: user.lastname,
            entry_date: user.entryDate,
            phone: user.phone,
            address: user.address,
            comment: user.comment,
            contract_type: user.contractType,
            birth_date: user.birthDate,
            agenda_item_card_color: user.agenda_item_card_color,
        }),
    updateWithoutPassword: (id: number, user) =>
        axios.patch(`/users/${id}`, {
            email: user.email,
            role_id: user.role,
            agency_ids: user.agencies,
            firstname: user.firstname,
            lastname: user.lastname,
            entry_date: user.entryDate,
            phone: user.phone,
            address: user.address,
            comment: user.comment,
            contract_type: user.contractType,
            birth_date: user.birthDate,
            agenda_item_card_color: user.agenda_item_card_color,
        }),
    sendResetPassword: (email) => axios.post("/users/password/reset", {email}),
    resetPassword: (code, password, password_confirmation) =>
        axios.post("/users/password/edit", {
            code,
            password,
            password_confirmation,
        }),
    delete: (id: number) => axios.delete(`/users/${id}`),
};

export const roles = {
    getAll: (params: ParseParamsType) => axios.get(`/roles${parseParams(params)}`),
};

export const agencies = {
    getAll: (params: ParseParamsType) => axios.get(`/agencies${parseParams(params)}`),
    getOne: (id: number) => axios.get(`/agencies/${id}`),
    create: (agency) => {
        let data = new FormData();
        data.append("name", agency.name);
        data.append("url", agency.url);
        data.append("leader_id", agency.leader);
        data.append("address", agency.address);
        data.append("phone", agency.phone);
        data.append("email", agency.email);
        data.append("comptable_account", agency.comptable_account);
        return axios.post("/agencies", data);
    },
    update: (id: number, agency) => {
        let data = new FormData();
        data.append("name", agency.name);
        data.append("url", agency.url);
        data.append("leader_id", agency.leader);
        data.append("address", agency.address);
        data.append("phone", agency.phone);
        data.append("email", agency.email);
        data.append("comptable_account", agency.comptable_account);
        return axios.patch(`/agencies/${id}`, data);
    },
    delete: (id: number) => axios.delete(`/agencies/${id}`),
};

export const customers = {
    getAll: (params: ParseParamsType) => axios.get(`/clients${parseParams(params)}`),
    getOne: (id: number) => axios.get(`/clients/${id}`),
    create: (customer) =>
        axios.post("/clients", {
            // Identification du prospect
            civility: customer.civility,
            firstname: customer.firstname,
            lastname: customer.lastname,
            firstname2: customer.firstname2,
            lastname2: customer.lastname2,
            address: customer.address,
            additional_address: customer.additional_address,
            zip_code: customer.zip_code,
            city: customer.city,
            address_construction_site: customer.address_construction_site,
            additional_address_construction_site:
            customer.additional_address_construction_site,
            city_construction_site: customer.city_construction_site,
            zip_code_construction_site: customer.zip_code_construction_site,
            mobile_phone: customer.mobile_phone,
            mobile_phone2: customer.mobile_phone2,
            fix_phone: customer.fix_phone,
            email: customer.email,
            email2: customer.email2,
            client_type: customer.client_type,
            company_name: customer.company_name,
            siret: customer.siret,
            exposed_city: customer.exposed_city,
            is_owner: customer.is_owner,
            // Détail du logement
            main_resideence: customer.main_resideence,
            owner_at: customer.owner_at,
            housing_seniority: customer.housing_seniority,
            surface_habitation: customer.surface_habitation,
            floors_number: customer.floors_number,
            is_isolate_floors: customer.is_isolate_floors,
            attic_type: customer.attic_type,
            attic_isulated_at: customer.attic_isulated_at,
            attic_isulation_type: customer.attic_isulation_type,
            is_attic_isulation: customer.is_attic_isulation,
            window_type: customer.window_type,
            windows_number: customer.windows_number,
            ecs_production_type: customer.ecs_production_type,
            ecs_production_type_annual_cost: customer.ecs_production_type_annual_cost,
            heading_type: customer.heading_type,
            heading_type_annual_cost: customer.heading_type_annual_cost,
            appointment_explication: customer.appointment_explication,
            // Définition du projet potentiel
            building_work_type: customer.building_work_type,
            already_benefit_energy_study: customer.already_benefit_energy_study,
            already_benefit_grant: customer.already_benefit_grant,
            // Rencontre du prospect
            first_contact_date: customer.first_contact_date,
            contact_origin: customer.contact_origin,
            client_id: customer.client_id,
            user_id: customer.user_id,
            // Positionnement du rendez-vous
            appointment_take_with: customer.appointment_take_with,
            function1: customer.function1,
            work_council1: customer.work_council1,
            function2: customer.function2,
            work_council2: customer.work_council2,
            age: customer.age,
            spouse_age: customer.spouse_age,
            children_number: customer.children_number,
            fiscal_income: customer.fiscal_income,
            decision_makers_present: customer.decision_makers_present,
            ok_one_hours_thirty: customer.ok_one_hours_thirty,
            appointment_method: customer.appointment_method,
            // Le contact - suivi du rendez-vous
            follow_up_number_star: customer.follow_up_number_star,
            follow_up_call_date: customer.follow_up_call_date,
            follow_up_refusal_reason: customer.follow_up_refusal_reason,
            follow_up_client_profil: customer.follow_up_client_profil,
        }),
    update: (id: number, customer) =>
        axios.patch(`/clients/${id}`, {
            // Identification du prospect
            civility: customer.civility,
            firstname: customer.firstname,
            lastname: customer.lastname,
            firstname2: customer.firstname2,
            lastname2: customer.lastname2,
            address: customer.address,
            additional_address: customer.additional_address,
            zip_code: customer.zip_code,
            city: customer.city,
            address_construction_site: customer.address_construction_site,
            additional_address_construction_site:
            customer.additional_address_construction_site,
            city_construction_site: customer.city_construction_site,
            zip_code_construction_site: customer.zip_code_construction_site,
            mobile_phone: customer.mobile_phone,
            mobile_phone2: customer.mobile_phone2,
            fix_phone: customer.fix_phone,
            email: customer.email,
            email2: customer.email2,
            client_type: customer.client_type,
            company_name: customer.company_name,
            siret: customer.siret,
            exposed_city: customer.exposed_city,
            is_owner: customer.is_owner,
            // Détail du logement
            main_resideence: customer.main_resideence,
            owner_at: customer.owner_at,
            housing_seniority: customer.housing_seniority,
            surface_habitation: customer.surface_habitation,
            floors_number: customer.floors_number,
            is_isolate_floors: customer.is_isolate_floors,
            attic_type: customer.attic_type,
            attic_isulated_at: customer.attic_isulated_at,
            attic_isulation_type: customer.attic_isulation_type,
            is_attic_isulation: customer.is_attic_isulation,
            window_type: customer.window_type,
            windows_number: customer.windows_number,
            ecs_production_type: customer.ecs_production_type,
            ecs_production_type_annual_cost: customer.ecs_production_type_annual_cost,
            heading_type: customer.heading_type,
            heading_type_annual_cost: customer.heading_type_annual_cost,
            appointment_explication: customer.appointment_explication,
            // Définition du projet potentiel
            building_work_type: customer.building_work_type,
            already_benefit_energy_study: customer.already_benefit_energy_study,
            already_benefit_grant: customer.already_benefit_grant,
            // Rencontre du prospect
            first_contact_date: customer.first_contact_date,
            contact_origin: customer.contact_origin,
            client_id: customer.client_id,
            user_id: customer.user_id,
            // Positionnement du rendez-vous
            appointment_take_with: customer.appointment_take_with,
            function1: customer.function1,
            work_council1: customer.work_council1,
            function2: customer.function2,
            work_council2: customer.work_council2,
            age: customer.age,
            spouse_age: customer.spouse_age,
            children_number: customer.children_number,
            fiscal_income: customer.fiscal_income,
            decision_makers_present: customer.decision_makers_present,
            ok_one_hours_thirty: customer.ok_one_hours_thirty,
            appointment_method: customer.appointment_method,
            // Le contact - suivi du rendez-vous
            follow_up_number_star: customer.follow_up_number_star,
            follow_up_call_date: customer.follow_up_call_date,
            follow_up_refusal_reason: customer.follow_up_refusal_reason,
            follow_up_client_profil: customer.follow_up_client_profil,
        }),
    delete: (id: number) => axios.delete(`/clients/${id}`),
    import_csv: (import_file) =>
        axios.post("/import_clients", {import_file: import_file}),
};

export const invoices = {
    getAll: (params: ParseParamsType) => axios.get(`/invoices${parseParams(params)}`),
    getOne: (id: number) => axios.get(`/invoices/${id}`),
    getPaymentDetails: (id: number) => axios.get(`/invoices/${id}/payment_details`),
    create: (invoices) =>
        axios.post("/invoices", invoices),
    update: (id: number, invoices) =>
        axios.patch(`/invoices/${id}`, invoices),
    delete: (id: number) => axios.delete(`/invoices/${id}`),
    validateInvoice: (id: number, values) => axios.patch(`/invoices/${id}`, values),
    generate_zip: (params: ParseParamsType) =>
        axios.get(`generate_invoice_zip${parseParams(params)}`, {
            responseType: "blob",
        }),
    send_invoice_emails: (params: ParseParamsType) =>
        axios.post(`send_mail_invoices${parseParams(params)}`),

    update_state: (id: number, state) => axios.patch(`/invoices/${id}/update_payment_state`, {
        payment_state_id: state
    }),

    update_fund_request: (id: number, date) => axios.patch(`/invoices/${id}/update_fund_request_at`, {
        fund_request_at: date
    })
};

export const projects = {
    exportProjects: (values) =>
        axios.post(
            `/projects_export_projects${parseParams(values.filters)}`,
            {
                columns: values.columns,
            },
            {responseType: "blob"}
        ),
    migrateOrder: (data) => axios.post('/projects/migrate_order', data),
    getAll: (params: ParseParamsType) => axios.get(`/projects${parseParams(params)}`),
    getOne: (id: number) => axios.get<Project>(`/projects/${id}`),
    update: (id: number, project) => axios.patch(`/projects/${id}`, project),
    deleteTechnicalVisit: (id: number) =>
        axios.delete(`/projects/${id}/disponibility_vt`),
    delete: (id: number) => axios.delete(`/projects/${id}`),
};

export const projectStates = {
    getAll: (params: ParseParamsType) => axios.get<ProjectState>(`/project_states${parseParams(params)}`),
};

export const abonnementStates = {
    getAll: (params: ParseParamsType) => axios.get(`/abonnement_states${parseParams(params)}`),
};

export const financers = {
    getAll: (params: ParseParamsType) => axios.get<Financer[]>(`/financers${parseParams(params)}`),
    getOne: (id: number) => axios.get(`/financers/${id}`),
    create: (financer) => {
        let data = new FormData();
        data.append("name", financer.name);
        data.append("url", financer.url);
        data.append("description", financer.description);
        data.append("phone", financer.phone);
        data.append("email", financer.email);
        return axios.post("/financers", data);
    },
    update: (id: number, financer) => {
        let data = new FormData();
        data.append("name", financer.name);
        data.append("url", financer.url);
        data.append("description", financer.description);
        data.append("phone", financer.phone);
        data.append("email", financer.email);
        return axios.patch(`/financers/${id}`, data);
    },
    delete: (id: number) => axios.delete(`/financers/${id}`),
};

export const scales = {
    getAll: (params: ParseParamsType) => axios.get<Scale[]>(`/scales${parseParams(params)}`),
    getOne: (id: number) => axios.get<Scale>(`/scales/${id}`),
    create: (scales) => {
        let data = new FormData();
        data.append("financer_id", scales.financer_id);
        data.append("label", scales.label);
        data.append("min_monthly_payment", scales.min_monthly_payment);
        data.append("min_amount_loan", scales.min_amount_loan);
        data.append("max_amount_loan", scales.max_amount_loan);
        data.append("ref", scales.ref);
        data.append("starting_date", scales.starting_date);
        data.append("ending_date", scales.ending_date);
        data.append(
            "first_monthly_payment_delay",
            scales.first_monthly_payment_delay
        );
        if (scales.import_file !== null) {
            data.append("import_file", scales.import_file);
        }
        for (let i = 0; i < scales.documents.length; i++) {
            data.append(`documents_attributes[${i}][url]`, scales.documents[i]);
        }
        return axios.post("/scales", data);
    },
    update: (id: number, scales) => {
        let data = new FormData();
        data.append("financer_id", scales.financer_id);
        data.append("label", scales.label);
        data.append("min_monthly_payment", scales.min_monthly_payment);
        data.append("min_amount_loan", scales.min_amount_loan);
        data.append("max_amount_loan", scales.max_amount_loan);
        data.append("ref", scales.ref);
        data.append("starting_date", scales.starting_date);
        data.append("ending_date", scales.ending_date);
        data.append(
            "first_monthly_payment_delay",
            scales.first_monthly_payment_delay
        );
        if (scales.import_file !== null) {
            data.append("import_file", scales.import_file);
        }
        for (let i = 0; i < scales.documents.length; i++) {
            data.append(`documents_attributes[${i}][url]`, scales.documents[i]);
        }
        return axios.patch(`/scales/${id}`, data);
    },
    delete: (id: number) => axios.delete(`/scales/${id}`),
};

export const scaleItems = {
    getAll: (params: ParseParamsType) => axios.get<ScaleItem[]>(`/scale_items${parseParams(params)}`),
    getOne: (id: number) => axios.get<ScaleItem>(`/scale_items/${id}`),
}

export const orders = {
    getAll: (params: ParseParamsType) => axios.get(`/orders${parseParams(params)}`),
    getOne: (id: number) => axios.get(`/orders/${id}`),
    create: (order) =>
        axios.post("/orders", {
            client_id: order.customer,
            free_items_attributes: order.free_fields,
            eco_tax: order.eco_tax,
            total_price_deal_ht: order.total_price_deal_ht,
            total_price_deal_ttc: order.total_price_deal_ttc,
            cce_prime: order.cce_prime,
            cee_label: order.cee_label,
            other_prime_label: order.other_prime_label,
            other_prime_amount: order.other_prime_amount * 100.0,
            product_items_attributes: order.product_items,
            order_id: order.parent_id,
        }),
    update: (id: number, order) =>
        axios.patch(`/orders/${id}`, {
            agency_id: order.agency_id,
            client_id: order.customer,
            free_items_attributes: order.free_fields,
            eco_tax: order.eco_tax,
            total_price_deal_ht: order.total_price_deal_ht,
            total_price_deal_ttc: order.total_price_deal_ttc,
            cce_prime: order.cce_prime,
            cee_label: order.cee_label,
            other_prime_label: order.other_prime_label,
            other_prime_amount: order.other_prime_amount * 100.0,
            invoice: order.invoice,
            quote: order.quote,
            product_items_attributes: order.product_items,
        }),
    delete: (id: number) => axios.delete(`/orders/${id}`),
    getGenerateInvoice: (id: number, values, is_draft) =>
        axios.post(`/orders/${id}/generate_invoice`, {
            is_draft: is_draft,
            custom_invoice_date: values.invoice_date,
            payment_mode: values.payment_mode,
        }),
    getGenerateRefund: (invoice_id) =>
        axios.post(`/orders/generate_refund`, {
            invoice_id: invoice_id,
        }),
    getGenerateRefundWithOrder: (invoice_id, order, order_id = null) =>
        axios.post(`/orders/generate_refund`, {
            refund_on: order.refund_on,
            client_id: order.customer,
            free_items_attributes: order.free_fields,
            eco_tax: order.eco_tax,
            cce_prime: order.cce_prime,
            cee_label: order.cee_label,
            product_items_attributes: order.product_items,
            payment_mode: order.payment_mode,
            invoice_id: invoice_id,
            is_free_order: true,
            order_id: order_id,
            custom_invoice_date: order.custom_invoice_date,
        }),
    getGenerateQuote: (id: number, values) => {
        let data: any = {
            custom_quote_date: values.quote?.custom_quote_date || values.custom_quote_date,
        };
        if (values.force_generate_pdf) { // if avenant
            data.force_generate_pdf = true;
        }
        return axios.post(`/orders/${id}/generate_quote`, data);
    },
};

export const vats = {
    getAll: (params: ParseParamsType) => axios.get(`/vats${parseParams(params)}`),
};

export const free_fields = {
    delete: (id: number) => axios.delete(`/free_fields/${id}`),
};

export const products = {
    getAll: (params: ParseParamsType) => axios.get(`/products${parseParams(params)}`),
    getOne: (id: number) => axios.get(`/products/${id}`),
    getBrands: () => axios.get('/products/brands'),
    create: (product) => {
        let data = new FormData();
        data.append("title", product.title);
        data.append("quote_description", product.quote_description);
        data.append("commercial_name", product.commercial_name);
        data.append("brand", product.brand);
        data.append("description", product.description);
        data.append("reference", product.reference);
        data.append("category_id", product.category);
        data.append("sub_category_id", product.sub_category);
        data.append("vat_id", product.vat);
        data.append("price", product.price);
        data.append("price_ttc", product.price_ttc);
        data.append("promotional_offer", product.promotional_offer);
        data.append("promotional_offer_ttc", product.promotional_offer_ttc);
        data.append("eco_tax", product.eco_tax);
        data.append("image", product.image);
        data.append("has_warm_water", product.has_warm_water);
        data.append("phase", product.phase);
        if (product.workforces_ids && product.workforces_ids.length > 0) {
            data.append("workforce_ids[]", product.workforces_ids.join(','));
        }
        return axios.post("/products", data);
    },
    update: (id: number, product) => {
        let data = new FormData();
        data.append("title", product.title);
        data.append("quote_description", product.quote_description);
        data.append("commercial_name", product.commercial_name);
        data.append("brand", product.brand);
        data.append("description", product.description);
        data.append("reference", product.reference);
        data.append("category_id", product.category);
        data.append("sub_category_id", product.sub_category);
        data.append("vat_id", product.vat);
        data.append("price", product.price);
        data.append("price_ttc", product.price_ttc);
        data.append("promotional_offer", product.promotional_offer);
        data.append("promotional_offer_ttc", product.promotional_offer_ttc);
        data.append("eco_tax", product.eco_tax);
        data.append("image", product.image);
        data.append("has_warm_water", product.has_warm_water);
        data.append("phase", product.phase);
        if (product.workforces_ids && product.workforces_ids.length > 0) {
            data.append("workforce_ids[]", product.workforces_ids.join(','));
        }
        return axios.patch(`/products/${id}`, data);
    },
    delete: (id: number) => axios.delete(`/products/${id}`),
};

export const categories = {
    getAll: (params: ParseParamsType) => axios.get(`/categories${parseParams(params)}`),
};

export const sub_categories = {
    getAll: (params: ParseParamsType) => axios.get(`/sub_categories${parseParams(params)}`),
};

/*
category_ids
subcategory_ids
product_ids
 */
export const statistics = {
    getAll: (params: ParseParamsType) => axios.get(`/statistiques${parseParams(params)}`),
    getFamiliyRepartition: (params: ParseParamsType) => axios.get(`/statistics/families_repartition${parseParams(params)}`),
    getCAtegoryRepartition: (params: ParseParamsType) => axios.get(`/statistics/categories_repartition${parseParams(params)}`),
    getProductRepartition: (params: ParseParamsType) => axios.get(`/statistics/products_repartition${parseParams(params)}`),
};

export const agendaItems = {
    getAll: (params: ParseParamsType) => axios.get<AgendaItem[]>(`/agenda_items${parseParams(params)}`),
    getOne: (id: number) => axios.get(`/agenda_items/${id}`),
    create: (agendaItem: any) => axios.post("/agenda_items", agendaItem),
    update: (id: number, agendaItem: any) => axios.patch(`/agenda_items/${id}`, agendaItem),
    delete: (id: number) => axios.delete(`/agenda_items/${id}`),
};

export const deposits = {
    create: (order_id, deposit) => {
        return axios.post("/deposits", {
            order_id: order_id,
            percent: deposit.percent,
            price: deposit.price,
            custom_deposit_date: deposit.invoice_date,
            payment_mode: deposit.payment_mode,
        });
    },
    update: (id: number, deposit) => axios.patch(`/deposits/${id}`, deposit),
};

export const quotes = {
    getAll: (params: ParseParamsType) => axios.get(`/quotes${parseParams(params)}`),
    getOne: (id: number) => axios.get(`/quotes/${id}`),
    create: (quote) =>
        axios.post("/quotes", {
            client_id: quote.client_id,
            number: quote.number,
        }),
    update: (id: number, quote) =>
        axios.patch(`/quotes/${id}`, {
            client_id: quote.client_id,
            number: quote.number,
        }),
    delete: (id: number) => axios.delete(`/quotes/${id}`),
    generate_quote_zip: (list) =>
        axios.get(`generate_quote_zip?quotes=${list.map((id, index) => id)}`, {
            responseType: "blob",
        }),
    send_quote_emails: (list) =>
        axios.post("send_mail_quotes", {ids: list.map((id, index) => id)}),
};

export const free_items_save = {
    getAll: (params: ParseParamsType) => axios.get(`/free_item_saves${parseParams(params)}`),
    create: (free_item) =>
        axios.post("/free_item_saves", {
            quantity_unit: free_item.quantity_unit,
            timeline_begin_at: free_item.timeline_begin_at,
            free_field_safe_attributes: free_item.free_field_save,
        }),
};

export const notifications = {
    getAll: (params: ParseParamsType) => axios.get(`/notifications${parseParams(params)}`),
    getOne: (id: number) => axios.get(`/notifications/${id}`),
    create: (notification) => axios.post("/notifications", notification),
    update: (id: number, notification) =>
        axios.patch(`/notifications/${id}`, notification),
    delete: (id: number) => axios.delete(`/notifications/${id}`),
};

export const formulas = {
    getAll: (params: ParseParamsType) => axios.get(`/formules${parseParams(params)}`),
    getOne: (id: number) => axios.get(`/formules/${id}`),

    create: (formula) => axios.post('/formules',
        {
            formule_type_id: formula.formule_type,
            label: formula.label,
            description: formula.description,
            price_ttc_monthly: formula.price_ttc_monthly,
            vat_id: formula.vat_id,
            category_ids: formula.category_ids,
            sub_category_ids: formula.sub_category_ids
        }),

    update: (id: number, formula) => axios.patch(`/formules/${id}`,
        {
            formule_type_id: formula.formule_type,
            label: formula.label,
            description: formula.description,
            price_ttc_monthly: formula.price_ttc_monthly,
            vat_id: formula.vat_id,
            category_ids: formula.category_ids,
            sub_category_ids: formula.sub_category_ids
        }),

    delete: (id: number) => axios.delete(`/formules/${id}`)
}

export const formulasTypes = {
    getAll: () => axios.get('/formule_types'),
    getOne: (id: number) => axios.get(`/formule_types/${id}`),

    create: (title) => axios.post('/formule_types', {title: title}),

    update: (id: number, title) => axios.patch(`/formule_types/${id}`, {title: title}),

    delete: (id: number) => axios.delete(`/formule_types/${id}`),
}

export const subscriptions = {
    getAll: (params: ParseParamsType) => axios.get(`/subscriptions${parseParams(params)}`),
    getOne: (id: number) => axios.get(`/subscriptions/${id}`),

    invoices: (subscription_id, params) => axios.get(`/subscriptions/${subscription_id}/subscription_invoices`, params),

    create: (subscription) => {
        let data = new FormData();
        data.append('number', subscription.number);
        data.append('debit_day', subscription.debit_day);
        data.append('signed_at', subscription.signed_at);
        data.append('validated_at', subscription.validated_at);
        data.append('start_at', subscription.start_at);
        data.append('end_at', subscription.end_at);
        data.append('formule_id', subscription.formule_id);
        data.append('client_id', subscription.client_id);
        data.append('debit_type', subscription.debit_type);
        data.append('duration_type', subscription.duration_type);
        data.append('periodicity', subscription.periodicity);
        data.append('to_renew', subscription.to_renew);
        data.append('term_number', subscription.term_number);
        data.append('notes', subscription.notes);
        subscription.mandates_attributes.forEach(mandate => {
            if (mandate.document_path) {
                data.append('mandates_attributes[][document_path]', mandate.document_path);
            }

            data.append('mandates_attributes[][ics]', mandate.ics);
            data.append('mandates_attributes[][rum]', mandate.rum);
            data.append('mandates_attributes[][iban]', mandate.iban);
        })
        return axios.post('/subscriptions', data)
    },

    subscriptionPreview: (preview) => axios.post('subscription_previews', {
        start_at: preview.start_at,
        end_at: preview.end_at,
        periodicity: preview.periodicity,
        term_number: preview.term_number,
        debit_day: preview.debit_day
    }),

    update: (id: number, subscription) => {
        let data = new FormData();
        data.append('number', subscription.number);
        data.append('debit_day', subscription.debit_day);
        data.append('signed_at', subscription.signed_at);
        data.append('validated_at', subscription.validated_at);
        data.append('start_at', subscription.start_at);
        data.append('end_at', subscription.end_at);
        data.append('formule_id', subscription.formule_id);
        data.append('client_id', subscription.client_id);
        data.append('debit_type', subscription.debit_type);
        data.append('duration_type', subscription.duration_type);
        data.append('periodicity', subscription.periodicity);
        data.append('to_renew', subscription.to_renew);
        data.append('term_number', subscription.term_number);
        data.append('abonnement_state_id', subscription.abonnement_state_id);
        data.append('notes', subscription.notes);
        subscription.mandates_attributes.forEach(mandate => {
            if (mandate.id)
                data.append('mandates_attributes[][id]', mandate.id);
            if (mandate.document_path)
                data.append('mandates_attributes[][document_path]', mandate.document_path);

            data.append('mandates_attributes[][ics]', mandate.ics);
            data.append('mandates_attributes[][rum]', mandate.rum);
            data.append('mandates_attributes[][iban]', mandate.iban);

            if (mandate._destroy)
                data.append('mandates_attributes[][_destroy]', mandate._destroy)
        })
        return axios.patch(`/subscriptions/${id}`, data);
    },

    suspendAt: (id: number, time: Date | null) => axios.patch(`/subscriptions/${id}`, {suspended_at: time}),

    updateValidated: (id: number, subscription) => axios.patch(`/subscriptions/${id}`,
        {
            debit_day: subscription.debit_day,
            start_at: subscription.start_at,
            signed_at: subscription.signed_at,
            mandates_attributes: subscription.mandates_attributes,
        }),

    subscriptionRenew: (id: number, to_renew) => axios.patch(`/subscriptions/${id}`, {to_renew: to_renew}),
    subscriptionValidate: (id: number, validated_at, start_at) => axios.patch(`/subscriptions/${id}`, {
        validated_at: validated_at,
        start_at: start_at,
        to_renew: true,
    }),

    delete: (id: number) => axios.delete(`/subscriptions/${id}`)
}

export const payments = {
    getAll: () => axios.get('/payments'),
    getOne: (id: number) => axios.get(`/payments/${id}`),
    create: (payment) => axios.post('/payments', payment),
    update: (payment_id, payment) => axios.patch(`/payments/${payment_id}`, payment),
    delete: (id: number) => axios.delete(`/payments/${id}`),
}

export const payment_states = {
    getAll: (params: DefaultFilter) => axios.get<PaymentState>(`/payment_states${parseParams(params)}`),
}

export const offers = {
    getAll: (params: OffersFilters) => axios.get<PaginateIndexResponse<Offer[]>>(`/offers${parseParams(params)}`),
    getOne: (id: number) => axios.get<Offer>(`/offers/${id}`),
    upsert: (offer: Offer) => {
        let object: any = offer;
        object.promotable_attributes = {}
        switch (offer.promotable_type) {
            case PromotableTypeEnum.CATEGORY_GROUP:
                object.promotable_attributes.category_ids = offer.promotable_entity_ids;
                break;
            case PromotableTypeEnum.SUB_CATEGORY_GROUP:
                object.promotable_attributes.sub_category_ids = offer.promotable_entity_ids;
                break;
            case PromotableTypeEnum.PRODUCT_GROUP:
                object.promotable_attributes.product_ids = offer.promotable_entity_ids;
                break;
            case PromotableTypeEnum.ALL:
                object.promotable_attributes = null;
                object.promotable_id = null;
                object.promotable_type = null;
                break;
        }
        if (offer.id) {
            return axios.patch<OfferCode>(`/offers/${offer.id}`, object)
        } else {
            return axios.post<OfferCode>('/offers', object)
        }
    },
    delete: (id: number) => axios.delete(`/offers/${id}`),
}

export const offer_codes = {
    getAll: (params: OfferCodesFilters) => axios.get<PaginateIndexResponse<OfferCode[]>>(`/offer_codes${parseParams(params)}`),
    getOne: (id: number) => axios.get<OfferCode>(`/offer_codes/${id}`),
    upsert: (offerCode: OfferCode) => {
        if (offerCode.id) {
            return axios.patch<OfferCode>(`/offer_codes/${offerCode.id}`, offerCode)
        } else {
            return axios.post<OfferCode>('/offer_codes', offerCode)
        }
    },
    delete: (id: number) => axios.delete(`/offer_codes/${id}`),
    validateOfferCode: (order_id: number, code: string) => axios.post<{ valid: boolean, offer_id: number, offer_code: string }>(`/orders/${order_id}/offer_code_validators/${code}/validate_offer_code`)
}

export namespace V2 {
    export const agenda_items = {
        upsert: (agenda_item: AgendaItem) => {
            if (agenda_item.id) {
                return axios.patch<AgendaItem>(`/agenda_items/${agenda_item.id}`, agenda_item)
            } else {
                return axios.post<AgendaItem>('/agenda_items', agenda_item)
            }
        },
    };
    export const map_items = {
        getAll: (params: MapItemFilters) => axios.get<MapItem[]>(`/v2/dashboard/map_items${parseParams(params)}`),
    };
    export const products = {
        getAll: (params: ParseParamsType) => axios.get<Product[]>(`/v2/dashboard/products${parseParams(params)}`),
    };
    export const requests = {
        getAll: (params: ParseParamsType) => axios.get<Request[]>(`/v2/dashboard/requests${parseParams(params)}`),
        get: (id: number, params: ParseParamsType) => axios.get<Request>(`/v2/dashboard/requests/${id}${parseParams(params)}`),
        post: (body: any, params: ParseParamsType) => axios.post<Request>(`/v2/dashboard/requests${parseParams(params)}`, body),
        patch: (id: number, body: any, params: ParseParamsType) => axios.patch<Request>(`/v2/dashboard/requests/${id}${parseParams(params)}`, body),
        upsert: (id: number | null | undefined, body: any, params: ParseParamsType) => id ?
            axios.patch<Request>(`/v2/dashboard/requests/${id}${parseParams(params)}`, body) :
            axios.post<Request>(`/v2/dashboard/requests${parseParams(params)}`, body),
        delete: (id: number, params: ParseParamsType) => axios.delete<Request>(`/v2/dashboard/requests/${id}${parseParams(params)}`),
    };
    export const request_types = {
        getAll: (params: ParseParamsType) => axios.get<RequestType[]>(`/v2/dashboard/request_types${parseParams(params)}`),
    };
    export const request_status = {
        getAll: (params: ParseParamsType) => axios.get<RequestStatus[]>(`/v2/dashboard/request_states${parseParams(params)}`),
    };
    export const request_priorities = {
        getAll: (params: ParseParamsType) => axios.get<RequestPriority[]>(`/v2/dashboard/request_priorities${parseParams(params)}`),
    };
    export const request_natures = {
        getAll: (params: ParseParamsType) => axios.get<RequestType[]>(`/v2/dashboard/request_natures${parseParams(params)}`),
    };
    export const interventions = {
        getAll: (params: ParseParamsType) => axios.get<InterventionFolder[]>(`/v2/dashboard/intervention_folders${parseParams(params)}`),
        get: (id: number, params: ParseParamsType) => axios.get<InterventionFolder>(`/v2/dashboard/intervention_folders/${id}${parseParams(params)}`),
        post: (body: any, params: ParseParamsType) => axios.post<InterventionFolder>(`/v2/dashboard/intervention_folders${parseParams(params)}`, body),
        patch: (id: number, body: any, params: ParseParamsType) => axios.patch<InterventionFolder>(`/v2/dashboard/intervention_folders/${id}${parseParams(params)}`, body),
        upsert: (id: number | null | undefined, body: any, params: ParseParamsType) => id ?
            axios.patch<InterventionFolder>(`/v2/dashboard/intervention_folders/${id}${parseParams(params)}`, body) :
            axios.post<InterventionFolder>(`/v2/dashboard/intervention_folders${parseParams(params)}`, body),
        delete: (id: number, params: ParseParamsType) => axios.delete<InterventionFolder>(`/v2/dashboard/intervention_folders/${id}${parseParams(params)}`),
    };
    export const invoices = {
        getAll: (params: ParseParamsType) => axios.get<Product[]>(`/v2/dashboard/invoices${parseParams(params)}`),
    };
    export const subscriptions = {
        getAll: (params: ParseParamsType) => axios.get<Subscription[]>(`/v2/dashboard/subscriptions${parseParams(params)}`),
    };
    export const mandates = {
        getMandateByIban: (iban: string, params: ParseParamsType) => axios.get<Mandate[]>(`/v2/dashboard/iban_mandates/${iban}${parseParams(params)}`),
    };
    export const customers = {
        getAll: (params: ParseParamsType) => axios.get<Client[]>(`/v2/dashboard/customers${parseParams(params)}`),
        get: (id: number, params: ParseParamsType) => axios.get<Client>(`/v2/dashboard/customers/${id}${parseParams(params)}`),
        generateCoordinates: (id: number) => axios.post<Client>(`/v2/dashboard/customers/${id}/coords`),
    };
    export const compta_exporter = {
        get: (params: ParseParamsType) => axios.get<any>(`/v2/dashboard/compta_exporter${parseParams(params)}`, {responseType: "blob"}),
    };
    export const payments_exporter = {
        get: (params: ParseParamsType) => axios.get<any>(`/v2/dashboard/payments_exporter${parseParams(params)}`, {responseType: "blob"}),
    };
    export const projects = {
        getAll: (params: ParseParamsType) => axios.get<Project[]>(`/v2/dashboard/projects${parseParams(params)}`),
        get: (id: number) => axios.get<Project>(`/v2/dashboard/projects/${id}`),
        upsert: (project: Project) => {
            if (project.id) {
                return axios.patch<Project>(`/projects/${project.id}`, project)
            } else {
                return axios.post<Project>('/projects', project)
            }
        },
        getDepositDetails: (params: ParseParamsType) => axios.get<Project[]>(`/v2/dashboard/projects/deposit_details${parseParams(params)}`),
    };
    export const users = {
        getAll: (params: ParseParamsType) => axios.get<User[]>(`/v2/dashboard/users${parseParams(params)}`),
    };
    export const bank_accounts = {
        getAll: (params: ParseParamsType) => axios.get<BankAccount[]>(`/v2/dashboard/bank_accounts${parseParams(params)}`),
    };
    export const check_deposits = {
        getAll: (params: ParseParamsType) => axios.get<CheckDeposit[]>(`/v2/dashboard/check_depots${parseParams(params)}`),
        get: (id: number, params: ParseParamsType) => axios.get<CheckDeposit>(`/v2/dashboard/check_depots/${id}${parseParams(params)}`),
        post: (body: any, params: ParseParamsType) => axios.post<CheckDeposit>(`/v2/dashboard/check_depots${parseParams(params)}`, body),
        patch: (id: number, body: any, params: ParseParamsType) => axios.patch<CheckDeposit>(`/v2/dashboard/check_depots/${id}${parseParams(params)}`, body),
        upsert: (id: number | null | undefined, body: any, params: ParseParamsType) => id ?
            axios.patch<CheckDeposit>(`/v2/dashboard/check_depots/${id}${parseParams(params)}`, body) :
            axios.post<CheckDeposit>(`/v2/dashboard/check_depots${parseParams(params)}`, body),
        delete: (id: number, params: ParseParamsType) => axios.delete<CheckDeposit>(`/v2/dashboard/check_depots/${id}${parseParams(params)}`),
    };
    export const check_payments = {
        create: (values: any) => {
            let data = new FormData();
            data.append('date', values.date);
            data.append('file', values.file);
            data.append('bank_account_id', values.bank_account_id);
            return axios.post('/v2/dashboard/check_payments', data, {
                responseType: "blob",
            });
        },
    }
    export const export_invoices = {
        get: (params: ParseParamsType) => axios.get<any>(`/v2/dashboard/invoices_exporter${parseParams(params)}`, {responseType: "blob"})
    }
}
