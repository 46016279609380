import {useCallback, useContext, useEffect, useState} from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, {Type} from "react-bootstrap-table2-editor";

import {computeTaxesfullPrice, computeTaxeslessPrice} from "./AddItemComponent";
import VatsContext from "../Context/VatsContext";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {computeTotalItemsPrices} from "../../../helpers/quotes_invoices_helper";

/**
 * Table displaying items that were validated by the item form
 * @param {array} datas table datas (array of objects)
 * @param {array} unitOptions array of {label, value} for the unit selector (the same as the item form)
 * @param {function} moveItem allow up and down items in the table
 * @param {function} deleteItem allow deleting items in the table
 * @param {function} setTableDatas setter for the table data
 * @param error
 */
const FreeFieldsTable = ({
    datas,
    unitOptions,
    moveItem,
    deleteItem,
    setTableDatas,
    error = undefined,
    showErrors,
    disabled,
 }) => {

    // Using index of the editing row to update table data
    const [indexEdit, setIndexEdit] = useState(null);

    // Using state to store data array length allowing to rerender buttons in columns
    const [dataLength, setDataLength] = useState(datas.length);

    // Get vats context to display in the selectors
    const VATS = useContext(VatsContext);

    // The vats options values are the index of the vat array to allow object as vat
    const vatsOptions = VATS.map(((vat, index) => (
        {
            label: vat.name,
            value: index
        }
    )));

    //console.log("vatsOptions", (vatsOptions.find(x => x)), "unitOptions", unitOptions.find);

    // Total price of the elements of the table
    const [totalTaxesfullPrice, setTotalTaxesfullPrice] = useState(0.);
    const [totalTaxeslessPrice, setTotalTaxeslessPrice] = useState(0.);

    // needed to put a compute function here because of the editable fields
    // TODO -> Detect witch field was edited to drop the complexity
    const computeTotalPrice = useCallback(() => {
        const {_taxeslessPrice, _taxesfullPrice} = computeTotalItemsPrices(datas);

        setTotalTaxesfullPrice(_taxesfullPrice.toFixed(2));
        setTotalTaxeslessPrice(_taxeslessPrice.toFixed(2));
    }, [datas]);

    useEffect(() => {
        computeTotalPrice();
        setDataLength(datas.length);
    }, [datas, disabled, computeTotalPrice])

    // Columns presents in the BootstrapTable
    // each of them are editable unless the action field that is used to delete, up and down items.
    const columns = [
        {
            dataField: 'description',
            text: 'Description',
            editor: {
                type: Type.TEXTAREA,
            },
            editable: !disabled,
        },
        {
            dataField: 'price',
            formatter: (cell, row) => <div>{parseFloat(row.price).toFixed(2)} €</div>,
            text: 'Prix HT',
            editable: !disabled,
        },
        {
            dataField: 'vat',
            text: 'TVA',
            formatter: (cell, row) => {
                return <div>{row.vat.name}</div>;
            },
            editor: {
                type: Type.SELECT,
                options: vatsOptions
            },
            editable: !disabled,
        },
        {
            dataField: 'price_ttc',
            formatter: (cell, row) => <div>{parseFloat(row.price_ttc).toFixed(2)} €</div>,
            text: 'Prix TTC',
            editable: !disabled,
        },
        {
            dataField: 'quantity',
            text: 'Quantité',
            editable: !disabled,
        },
        {
            dataField: 'quantity_unit',
            text: 'Unité',
            editable: !disabled,
            editor: {
                type: Type.SELECT,
                options: unitOptions
            }
        },
        {
            // TODO empêcher la modification pour les articles qui ne sont pas échéanciers
            dataField: 'timeline_begin_at',
            text: 'Date de début de l\'échéancier',
            editable: (content) => (content && !disabled),
            formatter: (cell, row) => {
                if(cell) {
                    if (cell instanceof Date) {
                        return cell.toLocaleDateString();
                    }else{
                        return new Date(cell).toLocaleDateString();
                    }
                }else {
                    return "";
                }
            },
            editor: {
                type: Type.DATE
            }
        },
        {
            dataField: 'eco_tax',
            text: 'Éco Taxe',
            editable: !disabled,
        },
        {
            dataField: 'actions',
            text: "",
            editable: false,
            classes: "px-0",
            style: {
                minWidth: "160px",
            },
            formatter: (cell, row, index, extraData) => {
                return (
                    disabled ? "" :
                      <UncontrolledDropdown>
                        <DropdownToggle className={"bg-transparent"}>
                          <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
                        </DropdownToggle>
                        <DropdownMenu right className="bordered important-default-colors">
                          <DropdownItem disabled={extraData.disabled} onClick={() => { deleteItem(index); computeTotalPrice(); }}>Supprimer</DropdownItem>
                          {index > 0 && <DropdownItem disabled={extraData.disabled} onClick={() => { moveItem(index, -1) }}><i className="bx bxs-up-arrow-alt" /> Mettre au dessus</DropdownItem> }
                          {index + 1 < extraData.length && <DropdownItem disabled={extraData.disabled} onClick={() => { moveItem(index, 1) }}><i className="bx bxs-down-arrow-alt" /> Mettre en dessous</DropdownItem> }
                        </DropdownMenu>
                      </UncontrolledDropdown>);
            },
            formatExtraData: {length: dataLength, disabled: disabled},
        }

    ];


    return (
        <div className="mb-4">
            <label htmlFor="table">Articles *</label>
            {error !== undefined && showErrors ? <span className="error"> {error}</span> : ""}
            <BootstrapTable
                classes="table-order-free-items-container table-centered"
                id="table"
                keyField="created_at"
                data={datas}
                columns={columns}
                cellEdit={cellEditFactory({
                    mode: "click",
                    blurToSave: true,
                    onStartEdit: (row, col, rowIndex, colIndex) => {
                          setIndexEdit(rowIndex); // save row index that is edit to allow data modifications
                    },
                    afterSaveCell: (oldValue, newValue, row, column) => {

                        // Modify the taxesfull price when the user manually modify taxesless price
                        if (column.dataField === "price") {

                            if(!newValue || isNaN(newValue))
                                row.price = "0";
                            else
                                row.price = newValue.replace(',', '.');

                            row.price_ttc = computeTaxesfullPrice(parseFloat(row.price.replace(',', '.')), row.vat.value);
                        }

                        // the same but for the taxesless price
                        if (column.dataField === "price_ttc") {
                            if(!newValue || isNaN(newValue))
                                row.price_ttc = "0";
                            else
                                row.price_ttc = newValue.replace(',', '.');

                            row.price = computeTaxeslessPrice(parseFloat(row.price_ttc.replace(',', '.')), row.vat.value);
                        }

                        // Compute the new vat selected
                        if (column.dataField === "vat") {
                            if(newValue)
                                row.vat = VATS[newValue];
                            else
                                row.vat = VATS[VATS.findIndex((e) => e.id === oldValue.id)]
                            row.price_ttc = computeTaxesfullPrice(row.price, row.vat.value);
                        }

                        // check quantity value
                        if (column.dataField === "quantity") {
                            if(newValue) {
                                if (isNaN(newValue.replace(',', '.')) || parseFloat(newValue) < 0) {
                                    row.quantity = oldValue;
                                }else{
                                    row.quantity = newValue.replace(',', '.');
                                }
                            }else{
                                row.quantity = 0;
                            }
                        }

                        // check start_date value
                        if(column.dataField === "timeline_begin_at") {
                            if (!(newValue instanceof Date)) {
                                row.timeline_begin_at = new Date(newValue);
                            }
                        }


                        computeTotalPrice();
                        setTableDatas(prev => {
                            prev.free_fields[indexEdit] = row;
                            return {...prev, free_fields: prev.free_fields};
                        })
                        setIndexEdit(null);
                    }
                })}
            />
            <div className="totalPricesTable">
                <div><strong>Total prix TTC : </strong>{totalTaxesfullPrice} €</div>
                <div><strong>Total prix HT : </strong>{totalTaxeslessPrice} €</div>
            </div>
        </div>
    );
}

export default FreeFieldsTable;