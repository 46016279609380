import {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {Container, Row, Col, Card, CardBody, CardTitle} from "reactstrap";

// Validation Schema

// Redux
import {connect} from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {
    createInvoiceFromOrder,
    updateOrder,
    showInvoice,
    getVats,
    ordersApiError,
    vatsApiError,
} from "../../store/actions";

import {
 typeForm,
} from "../../helpers/quotes_invoices_helper";
import QuoteInvoiceComponent from "../Quotes/Components/QuoteInvoiceComponent";

const OrderAdd = (props) => {

    useEffect(() => {
        props.getVats.call();
    }, [props.getVats]);

    return (
        <div className="page-content">
            <Container fluid className="quotes-container">
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Factures" breadcrumbItem="Ajouter une facture"/>

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4">Créer une facture</CardTitle>
                                <QuoteInvoiceComponent typeForm={typeForm.invoice} invoice_order={props.invoice_order}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const {order, invoice_order, file_url, loading, error} = state.Orders;
    const {vats} = state.Vats;

    return {order, invoice_order, file_url, vats, loading, error};
};

export default withRouter(
    connect(mapStatetoProps, {
        createInvoiceFromOrder,
        updateOrder,
        showInvoice,
        getVats,
        ordersApiError,
        vatsApiError,
    })(OrderAdd)
);
