import {
  GET_AGENCIES,
  GET_AGENCIES_SUCCESS,
  GET_AGENCY,
  GET_AGENCY_SUCCESS,
  CREATE_AGENCY,
  CREATE_AGENCY_SUCCESS,
  UPDATE_AGENCY,
  UPDATE_AGENCY_SUCCESS,
  DELETE_AGENCY,
  DELETE_AGENCY_SUCCESS,
  AGENCIES_API_ERROR,
} from "./actionTypes";

export const getAgencies = (query) => {
  return {
    type: GET_AGENCIES,
    payload: { query },
  };
};

export const getAgenciesSuccess = (agencies) => {
  return {
    type: GET_AGENCIES_SUCCESS,
    payload: agencies,
  };
};

export const getAgency = (id) => {
  return {
    type: GET_AGENCY,
    payload: { id },
  };
};

export const getAgencySuccess = (agency) => {
  return {
    type: GET_AGENCY_SUCCESS,
    payload: agency,
  };
};

export const createAgency = (agency, history) => {
  return {
    type: CREATE_AGENCY,
    payload: { agency, history },
  };
};

export const createAgencySuccess = (agency) => {
  return {
    type: CREATE_AGENCY_SUCCESS,
    payload: agency,
  };
};

export const updateAgency = (id, agency, history) => {
  return {
    type: UPDATE_AGENCY,
    payload: { id, agency, history },
  };
};

export const updateAgencySuccess = (agency) => {
  return {
    type: UPDATE_AGENCY_SUCCESS,
    payload: agency,
  };
};

export const deleteAgency = (id, history) => {
  return {
    type: DELETE_AGENCY,
    payload: { id, history },
  };
};

export const deleteAgencySuccess = (agency) => {
  return {
    type: DELETE_AGENCY_SUCCESS,
    payload: agency,
  };
};

export const agencyApiError = (error) => {
  return {
    type: AGENCIES_API_ERROR,
    payload: error,
  };
};
