import React, {useState} from "react";
import { searchCitiesByZipCode } from "../../../helpers/zip_code_helper";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { forceKeyPressUppercase } from "../../../helpers/input_helper";

const CustomerForm = (props) => {
  const [cities, setCities] = useState([]);
  const [cities_construction_site, setCities_construction_site] = useState([]);
  
  const getCities = async (postal_code, is_construction_site = false) => {
    const cities = await searchCitiesByZipCode(postal_code);

    let temp = cities.map((name) => {
      return { label: name, value: name };
    });

    is_construction_site ? setCities_construction_site(temp) : setCities(temp);
  };

  return (
    <Formik
      initialValues={props.initValues}
      validationSchema={props.validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        props.handleSubmit(values);
      }}
      render={({ values, errors, touched, setFieldValue }) => (
        <Form>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <Row>
                {props.initValues.client_type === "Professionnel" ? (
                  <Col lg="6">
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="company_name"
                        className="col-form-label col-lg-4"
                      >
                        Nom société {props.isUpdate ? null : "*"}
                      </Label>
                      <Col lg="8">
                        <Input
                          id="company_name"
                          name="company_name"
                          type="text"
                          tag={Field}
                          invalid={
                            errors.company_name && !!touched.company_name
                          }
                          placeholder="Entrer un nom de société"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="company_name"
                          component={FormFeedback}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                ) : null}
                {props.initValues.client_type === "Professionnel" ? (
                  <Col lg="6">
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="siret"
                        className="col-form-label col-lg-3"
                      >
                        Siret
                      </Label>
                      <Col lg="9">
                        <Input
                          id="siret"
                          name="siret"
                          type="text"
                          tag={Field}
                          invalid={errors.siret && !!touched.siret}
                          placeholder="Entrer un siret"
                          className="form-control"
                        />
                        <ErrorMessage name="siret" component={FormFeedback} />
                      </Col>
                    </FormGroup>
                  </Col>
                ) : null}
                <Col lg="4">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="civility"
                      className="col-form-label col-lg-6"
                    >
                      Civilité{" "}
                      {props.isUpdate
                        ? null
                        : props.initValues.client_type === "Professionnel"
                        ? null
                        : "*"}
                    </Label>
                    <Col lg="6">
                      <Select
                        className={`${errors.civility ? "selectorError" : ""}`}
                        noOptionsMessage={() => "Pas de civilité disponible"}
                        placeholder="Choisir"
                        value={{value: values.civility, label: values.civility}}
                        options={[
                          {value: "MME.", label: "MME."},
                          {value: "MLLE.", label: "MLLE."},
                          {value: "M.", label: "M."},
                          {value: "M. OU MME.", label: "M. OU MME."},
                        ]}
                        onChange={(civ) => {
                          setFieldValue("civility", civ.value);
                        }}
                      />
                      <ErrorMessage
                        className={errors.civility ? "errorMessages" : null}
                        name="civility"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="mb-4" row>
                <Label htmlFor="firstname" className="col-form-label col-lg-2">
                  Prénom{" "}
                  {props.isUpdate
                    ? null
                    : props.initValues.client_type === "Professionnel"
                    ? null
                    : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="firstname"
                    name="firstname"
                    type="text"
                    tag={Field}
                    invalid={errors.firstname && !!touched.firstname}
                    className="form-control"
                    placeholder="Entrer un prénom"
                  />
                  <ErrorMessage name="firstname" component={FormFeedback}/>
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label
                  htmlFor="lastname"
                  className="col-form-label col-lg-2"
                >
                  Nom{" "}
                  {props.isUpdate
                    ? null
                    : props.initValues.client_type === "Professionnel"
                      ? null
                      : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="lastname"
                    name="lastname"
                    type="text"
                    tag={Field}
                    invalid={errors.lastname && !!touched.lastname}
                    className="form-control"
                    placeholder="Entrer un nom"
                    onKeyPress={(e) =>{forceKeyPressUppercase(e) ; setFieldValue(e.target.name, e.target.value)}}
                  />
                  <ErrorMessage name="lastname" component={FormFeedback}/>
                </Col>
              </FormGroup>


              {values.civility === "M. OU MME." ?
                <React.Fragment>
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="firstname2" className="col-form-label col-lg-2">
                      Prénom 2
                    </Label>
                    <Col lg="10">
                      <Input
                        id="firstname2"
                        name="firstname2"
                        type="text"
                        tag={Field}
                        invalid={errors.firstname2 && !!touched.firstname2}
                        className="form-control"
                        placeholder="Entrer un prénom"
                      />
                      <ErrorMessage name="firstname2" component={FormFeedback}/>
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="lastname2"
                      className="col-form-label col-lg-2"
                    >
                      Nom 2{" "}
                    </Label>
                    <Col lg="10">
                      <Input
                        id="lastname2"
                        name="lastname2"
                        type="text"
                        tag={Field}
                        invalid={errors.lastname2 && !!touched.lastname2}
                        className="form-control"
                        placeholder="Entrer un nom"
                        onKeyPress={(e) =>{forceKeyPressUppercase(e) ; setFieldValue(e.target.name, e.target.value)}}
                      />
                      <ErrorMessage name="lastname2" component={FormFeedback}/>
                    </Col>
                  </FormGroup>
                </React.Fragment> : ''}


              <h5>Adresse principale</h5>
              <FormGroup className="mb-4" row>
                <Label htmlFor="address" className="col-form-label col-lg-2">
                  Rue {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="address"
                    name="address"
                    type="text"
                    tag={Field}
                    invalid={errors.address && !!touched.address}
                    placeholder="Entrer une adresse"
                    className="form-control"
                  />
                  <ErrorMessage name="address" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label
                  htmlFor="additional_address"
                  className="col-form-label col-lg-2"
                >
                  Complément d'adresse
                </Label>
                <Col lg="10">
                  <Input
                    id="additional_address"
                    name="additional_address"
                    type="text"
                    tag={Field}
                    invalid={
                      errors.additional_address && !!touched.additional_address
                    }
                    placeholder="Entrer un Complément d'adresse"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="additional_address"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="zip_code"
                      className="col-form-label col-lg-4"
                    >
                      Code postal {props.isUpdate ? null : "*"}
                    </Label>
                    <Col lg="8">
                      <Input
                        id="zip_code"
                        name="zip_code"
                        type="text"
                        tag={Field}
                        invalid={errors.zip_code && !!touched.zip_code}
                        placeholder="Entrer un code postal"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue("zip_code", event.currentTarget.value);
                          if (event.currentTarget.value.length === 5) {
                            getCities(event.currentTarget.value);
                          }
                        }}
                      />
                      <ErrorMessage name="zip_code" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="city" className="col-form-label col-lg-4">
                      Ville {props.isUpdate ? null : "*"}
                    </Label>
                    <Col lg="8">
                      <CreatableSelect
                        className={`w-100 ${
                          errors.city ? "selectorError" : ""
                        }`}
                        isClearable={true}
                        noOptionsMessage={() =>
                          "Veuillez entrer un code postal pour choisir une ville"
                        }
                        defaultValue={
                          values.city && values.city !== ""
                            ? {
                                value: values.city,
                                label: values.city,
                              }
                            : null
                        }
                        placeholder="Choisir une ville..."
                        options={cities}
                        onChange={(city) => {
                          setFieldValue(
                            "city",
                            city === null ? null : city.value
                          );
                        }}
                        formatCreateLabel={(inputValue) =>
                          `Choisir "${inputValue}"`
                        }
                      />
                      <ErrorMessage name="city" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <h5>Adresse du chantier (si différente)</h5>
              <FormGroup className="mb-4" row>
                <Label
                  htmlFor="address_construction_site"
                  className="col-form-label col-lg-2"
                >
                  Rue
                </Label>
                <Col lg="10">
                  <Input
                    id="address_construction_site"
                    name="address_construction_site"
                    type="text"
                    tag={Field}
                    invalid={
                      errors.address_construction_site &&
                      !!touched.address_construction_site
                    }
                    placeholder="Entrer une adresse"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="address_construction_site"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label
                  htmlFor="additional_address_construction_site"
                  className="col-form-label col-lg-2"
                >
                  Complément d'adresse du chantier
                </Label>
                <Col lg="10">
                  <Input
                    id="additional_address_construction_site"
                    name="additional_address_construction_site"
                    type="text"
                    tag={Field}
                    invalid={
                      errors.additional_address_construction_site &&
                      !!touched.additional_address_construction_site
                    }
                    placeholder="Entrer un Complément d'adresse du chantier"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="additional_address_construction_site"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="zip_code_construction_site"
                      className="col-form-label col-lg-4"
                    >
                      Code postal
                    </Label>
                    <Col lg="8">
                      <Input
                        id="zip_code_construction_site"
                        name="zip_code_construction_site"
                        type="text"
                        tag={Field}
                        invalid={
                          errors.zip_code_construction_site &&
                          !!touched.zip_code_construction_site
                        }
                        placeholder="Entrer un code postal"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            "zip_code_construction_site",
                            event.currentTarget.value
                          );
                          if (event.currentTarget.value.length === 5) {
                            getCities(event.currentTarget.value, true);
                          }
                        }}
                      />
                      <ErrorMessage
                        name="zip_code_construction_site"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="city_construction_site"
                      className="col-form-label col-lg-4"
                    >
                      Ville
                    </Label>
                    <Col lg="8">
                      <CreatableSelect
                        className={`w-100 ${
                          errors.city_construction_site ? "selectorError" : ""
                        }`}
                        isClearable={true}
                        noOptionsMessage={() =>
                          "Veuillez entrer un code postal pour choisir une ville"
                        }
                        defaultValue={
                          values.city_construction_site &&
                          values.city_construction_site !== ""
                            ? {
                                value: values.city_construction_site,
                                label: values.city_construction_site,
                              }
                            : null
                        }
                        placeholder="Choisir une ville..."
                        options={cities_construction_site}
                        onChange={(city_construction_site) => {
                          setFieldValue(
                            "city_construction_site",
                            city_construction_site === null
                              ? null
                              : city_construction_site.value
                          );
                        }}
                        formatCreateLabel={(inputValue) =>
                          `Choisir "${inputValue}"`
                        }
                      />
                      <ErrorMessage
                        name="city_construction_site"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="email" className="col-form-label col-lg-4">
                      Email
                    </Label>
                    <Col lg="8">
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        tag={Field}
                        invalid={errors.email && !!touched.email}
                        className="form-control"
                        placeholder="Entrer un email"
                      />
                      <ErrorMessage name="email" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="email2" className="col-form-label col-lg-4">
                      Email secondaire
                    </Label>
                    <Col lg="8">
                      <Input
                        id="email2"
                        name="email2"
                        type="email2"
                        tag={Field}
                        invalid={errors.email2 && !!touched.email2}
                        className="form-control"
                        placeholder="Entrer un email"
                      />
                      <ErrorMessage name="email2" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="fix_phone"
                      className="col-form-label col-lg-4"
                    >
                      Téléphone fixe
                    </Label>
                    <Col lg="8">
                      <Input
                        id="fix_phone"
                        name="fix_phone"
                        type="tel"
                        tag={Field}
                        invalid={errors.fix_phone && !!touched.fix_phone}
                        className="form-control"
                        placeholder="Entrer un numéro de téléphone fixe"
                      />
                      <ErrorMessage name="fix_phone" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="mobile_phone"
                      className="col-form-label col-lg-4"
                    >
                      Téléphone mobile
                    </Label>
                    <Col lg="8">
                      <Input
                        id="mobile_phone"
                        name="mobile_phone"
                        type="tel"
                        tag={Field}
                        invalid={errors.mobile_phone && !!touched.mobile_phone}
                        className="form-control"
                        placeholder="Entrer un numéro de téléphone mobile"
                      />
                      <ErrorMessage
                        name="mobile_phone"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="mobile_phone2"
                      className="col-form-label col-lg-4"
                    >
                      Téléphone mobile 2
                    </Label>
                    <Col lg="8">
                      <Input
                        id="mobile_phone2"
                        name="mobile_phone2"
                        type="tel"
                        tag={Field}
                        invalid={
                          errors.mobile_phone2 && !!touched.mobile_phone2
                        }
                        className="form-control"
                        placeholder="Entrer un numéro de téléphone mobile"
                      />
                      <ErrorMessage
                        name="mobile_phone2"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </div>
          <Row className="justify-content-end mt-4">
            <Col xs="12" className="text-center">
              <Button type="submit" color="primary">
                {props.isUpdate ? 'Modifier' : 'Créer'} le prospect
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default CustomerForm;
