import React from "react";
import { withRouter } from "react-router-dom";
import {Row, Col, CardBody, Card, Button, Label, Input, FormGroup, FormFeedback} from "reactstrap";
import {Formik, Field, Form, ErrorMessage} from 'formik';

// Redux
import { connect } from "react-redux";

// Actions
import { getProjectStates } from "../../../store/actions";

import {FormikAutocompleteUnselectable} from "../../../components/Common/AsyncAutoComplete2";

//Import Date Picker
import DatePicker from "../../../components/Common/DatePicker";
import {listFilterValidationSchema} from "../../Quotes/FormValidationSchemas/listFilterValidationSchema";
import {promisePaymentStatesOptions} from "../../../helpers/autocomplete_promise_options";

const Filter = (props) => {
  const {
    filters,
    setFilters,
  } = props;

  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      validationSchema={listFilterValidationSchema}
      onSubmit={(values) => {
        setFilters({
          ...filters,
          price_ht_min: values.price_ht_min,
          price_ht_max: values.price_ht_max,
          price_ttc_min: values.price_ttc_min,
          price_ttc_max: values.price_ttc_max,
          number: values.number,
          customer: values.customer,
          email_send: values.email_send,
          start_date: values.start_date,
          end_date: values.end_date,
          status: values.status,
          payment_state: values.payment_state
        })
      }}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched}) => (
        <Form>
          <Card>
            <CardBody>
              <Row>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="customer">Client</Label>
                    </Col>
                    <Col xs="12">
                      <Input
                        id="customer"
                        name="customer"
                        type="text"
                        className="form-control"
                        tag={Field}
                        placeholder="Entrer le nom d'un client"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="number">Numéro de facture</Label>
                    </Col>
                    <Col xs="12">
                      <div>
                        <Input
                          id="number"
                          name="number"
                          type="text"
                          className="form-control"
                          tag={Field}
                          placeholder="Entrer un numéro de facture"
                          invalid={errors.number && !!touched.number}
                        />
                          <ErrorMessage component={FormFeedback} name="number"/>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label htmlFor="email_send">
                        N'afficher que les factures non envoyés ?
                      </Label>
                    </Col>
                    <Col
                      xs="12"
                      className="custom-control-with-margin custom-control custom-switch"
                    >
                      <Input
                        id="email_send"
                        name="email_send"
                        type="checkbox"
                        tag={Field}
                        className="custom-control-input ml-3"
                      />
                      <Label
                        for="email_send"
                        className="custom-control-label"
                      >
                        {values.email_send ? "Oui" : "Non"}
                      </Label>
                    </Col>
                  </Row>
                </Col>
                <Col md="4">
                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label for="date_begin">Date</Label>
                    </Col>
                    <Col xs="12" className="d-flex align-items-center">
                      <span className="mr-1">du</span>
                      <DatePicker
                        className="form-control button-without-style"
                        name="start_date"
                        value={values.start_date}
                        onChange={setFieldValue}
                      />
                      <span className="ml-1 mr-1">au</span>
                      <DatePicker
                        className="form-control button-without-style"
                        name="end_date"
                        value={values.end_date}
                        onChange={setFieldValue}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md="4">
                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label>Prix HT</Label>
                    </Col>
                    <Col
                      xs="12"
                      className="d-flex align-items-center"
                    >
                      <span className="mr-1">de</span>
                      <div>
                        <Input
                          id="price_ht_min"
                          name="price_ht_min"
                          type="text"
                          className="overflow-hidden"
                          placeholder="Prix HT minimum"
                          tag={Field}
                          invalid={errors.price_ht_min && !!touched.price_ht_min}
                        />
                        <ErrorMessage component={FormFeedback} name="price_ht_min"/>
                      </div>
                      <span className="ml-1 mr-1">à</span>
                      <div>
                        <Input
                          id="price_ht_max"
                          name="price_ht_max"
                          type="text"
                          className="overflow-hidden"
                          placeholder="Prix HT maximum"
                          tag={Field}
                          invalid={errors.price_ht_max && !!touched.price_ht_max}
                        />
                        <ErrorMessage component={FormFeedback} name="price_ht_max"/>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md="4">
                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label>Prix TTC</Label>
                    </Col>
                    <Col
                      xs="12"
                      className="d-flex align-items-center"
                    >
                      <span className="mr-1">de</span>
                      <div>
                        <Input
                          id="price_ttc_min"
                          name="price_ttc_min"
                          type="text"
                          className="overflow-hidden"
                          placeholder="Prix TTC minimum"
                          tag={Field}
                          invalid={errors.price_ttc_min && !!touched.price_ttc_min}
                        />
                        <ErrorMessage component={FormFeedback} name="price_ttc_min"/>
                      </div>
                      <span className="ml-1 mr-1">à</span>
                      <div>
                        <Input
                          id="price_ttc_max"
                          name="price_ttc_max"
                          type="text"
                          className="overflow-hidden"
                          placeholder="Prix TTC maximum"
                          tag={Field}
                          invalid={errors.price_ttc_max && !!touched.price_ttc_max}
                        />
                        <ErrorMessage component={FormFeedback} name="price_ttc_max"/>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                    <Row>
                      <Col xs="12" className="mt-3">
                        <Label for="project_state_id">État</Label>
                      </Col>
                      <Col xs="12">
                        <FormikAutocompleteUnselectable
                          className="w-100"
                          name="status"
                          id="project_state_id"
                          isSearchable={true}
                          noOptionsMessage={() => "Aucun état disponible"}
                          placeholder="Choisir un état..."
                          loadOptions={[
                            { value: "all", label: "Tous" },
                            { value: "validate", label: "Validé" },
                            { value: "draft", label: "Brouillon" },
                          ]}
                        />
                      </Col>
                    </Row>
                </Col>
                <Col md="4">
                  <Row className='mt-3'>
                    <Col>
                      <FormGroup>
                        <Label for='payment_state'>État du paiement</Label>
                        <FormikAutocompleteUnselectable
                          name='payment_state'
                          id='payment_state'
                          loadOptions={promisePaymentStatesOptions}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs="12"
                  className="d-flex justify-content-center mt-3"
                >
                  <Button
                    color="none"
                    onClick={() => handleSubmit(values)}
                  >
                    Appliquer les filtres
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

const mapStatetoProps = (state) => {
  const { projectStates } = state.ProjectStates;

  return { projectStates };
};

export default withRouter(
  connect(mapStatetoProps, { getProjectStates })(Filter)
);
