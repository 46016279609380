import { History } from "history";
import { useState } from "react";
import {Button, Card, CardBody, CardTitle, Col, Container, Label, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FlashMessage from '../../components/Common/FlashMessage';
import CheckDeposit from "../../models/check_deposit";
import ChecksTable from "./Components/Tables/checks-table";
import Payment from "../../models/payment";
import {Form, Formik} from "formik";
import DatePicker from "react-datepicker";
import FormikAutocomplete from "../../components/Common/AsyncAutoComplete2";
import {promiseBankAccountsOptions} from "../../helpers/autocomplete_promise_options_api_v2";
import LoaderLayoutPulse from "../../components/Common/LoaderLayoutPulse";
import AddEditCheckAction from "./Components/Modals/addEditCheckAction";
import {V2} from "../../api";

const CheckDepositAdd = ({
  history
}: {
  history: History
}) => {
  const [checks, setChecks] = useState<Payment[]>([]);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [addingOrEditingCheck, setAddingOrEditingCheck] = useState<number|null>(null);

  // Filters

  function onValidate(values: any, ignoreChecks = false){
    setFormValid((checks.length > 0 || ignoreChecks) && values.depot_number !== undefined && values.date_depot !== null && values.bank_account_id !== null);
  }
  function onSubmit(values: any){
    setSending(true);
    let checksCopy = [...checks];
    checksCopy.forEach(v=>{
      delete v.client_id;
      delete v.client_name;
      delete v.invoice_reference;
      v.paid_at = values.date_depot;
      v.date_remise_bank_check_to_bank = values.date_depot;
      v.payment_type = "chèque";
      v.state = "acquitté";
      v.comment = null;
      v.bank_account_id = values.bank_account_id;
      return v;
    });
    console.log(checksCopy);
    V2.check_deposits.post({
      depot_number: values.depot_number,
      date_depot: values.date_depot,
      bank_account_id: values.bank_account_id,
      payments_attributes: checksCopy
    } as CheckDeposit, {}).then(() => {
      history.goBack()
    })
  }

  return (
    <>
      <div className="page-content">
        <Container fluid className="notifications-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Remise de chèques"
            breadcrumbTitle="Devis / Factures"
            breadcrumbItem="Remise de chèques"
          />
          <FlashMessage />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-0">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>Créer une remise de chèque</div>
                    </div>
                  </CardTitle>
                    <Formik
                      initialValues={({depot_number:undefined, date_depot:null, bank_account_id:null} as {depot_number:string|undefined,date_depot:Date|null, bank_account_id:number|null})}
                      enableReinitialize
                      onSubmit={onSubmit}
                      validateOnChange={true}
                      validate={onValidate}
                    >
                      {({ values, setFieldValue, handleSubmit }) => (
                        <Form className={"w-100"}>
                          <Row className="my-3">
                            <Col xs="12" lg="3" md="6" className='mb-4'>
                              <Label for="value"><b>Numero de dépot</b></Label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Entrer un numero de dépot"
                                defaultValue={values.depot_number}
                                onKeyUp={({currentTarget})=>{setFieldValue("depot_number", currentTarget.value)}}
                              />
                            </Col>
                            <Col xs="12" lg="3" md="6" className='mb-4 unova-datepicker'>
                              <Label for="value"><b>Date</b></Label>
                              <DatePicker
                                className="form-control button-without-style"
                                name="date_depot"
                                selected={values.date_depot?new Date(values.date_depot):null}
                                onChange={v => setFieldValue("date_depot",v)}
                                isClearable={true}
                                placeholderText={'dd/mm/aaaa'}
                                dateFormat={"dd/MM/yyyy"}
                              />
                            </Col>
                            <Col xs="12" lg="3" md="6" className='mb-4'>
                              <Label for="value"><b>Banque</b></Label>
                              <FormikAutocomplete
                                loadOptions={promiseBankAccountsOptions}
                                isSearchable={false}
                                multiple={false}
                                name={"bank_account_id"}
                              />
                            </Col>
                            <Col xs={"12"} className={"mt-3"}>
                              <div className="d-flex justify-content-between align-items-end">
                                <Label for="value" className={"mb-0"}><b>Chèques(s)</b></Label>
                                <button type="button" onClick={()=>setAddingOrEditingCheck(-1)} className="btn btn-green btn-none">
                                  <i className='bx bx-plus-circle fa-1.5x'/> Ajouter
                                </button>
                              </div>
                              {checks.length > 0 ?
                                <ChecksTable
                                  checks={checks}
                                  onDeleteAction={({check_key}) => {let cc = [...checks]; cc.splice(check_key,1);setChecks(cc);}}
                                  onEditAction={({check_key}) => {setAddingOrEditingCheck(check_key)}}
                                  history={history}
                                />
                                : <><br/>Aucun chèque n’a été ajouté pour le moment.</> }
                            </Col>
                            <Col xs="12" className="text-center mt-3">
                              <Button
                                color="none"
                                className="btn-green px-3"
                                onClick={() => handleSubmit(values as any)}
                                disabled={!formValid || sending}
                              >
                                {sending ? <LoaderLayoutPulse /> : "Créer la remise"}
                              </Button>
                            </Col>
                          </Row>
                          {addingOrEditingCheck !== null ?
                            <AddEditCheckAction
                              defaultValues={addingOrEditingCheck === -1 ? undefined : checks[addingOrEditingCheck]}
                              onAction={(c) =>{
                                if(addingOrEditingCheck === -1){
                                  setChecks([...checks, c]);
                                } else {
                                  let cc = [...checks]
                                  cc[addingOrEditingCheck] = c
                                  setChecks(cc);
                                }
                                setAddingOrEditingCheck(null);
                                onValidate(values, true);
                              }
                              }
                              onAbort={() => setAddingOrEditingCheck(null)}
                            />
                            : null}
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CheckDepositAdd;
