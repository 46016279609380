import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Alert,
    Modal,
} from "reactstrap";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import CardScale
import CardScale from "../Scales/CardScale";

// actions
import {
    getFinancer,
    getScales,
    deleteFinancer,
    financerApiError,
} from "../../store/actions";

const FinancersDetail = (props) => {
    const [flash_message, setFlash_message] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);

    useEffect(() => {
        props.getFinancer.call(undefined,parseInt(window.location.pathname.split("/")[3], 10));
    }, [props.getFinancer]);

    useEffect(() => {
        props.getScales.call(undefined,{
            financer_id: parseInt(window.location.pathname.split("/")[3], 10)
        })
    }, [props.getScales])

    const deleteThisFinancer = () => {
        props.deleteFinancer(
            parseInt(window.location.pathname.split("/")[3], 10),
            props.history
        );
    };

    function removeFlashMessage(){
        window.history.pushState({}, document.title, "/financer/detail/" + props.financer.id);
        setFlash_message(!flash_message);
    }

    function tog_standard(){
        setmodal_standard(!modal_standard);
        removeBodyCss();
      }
    
      function removeBodyCss() {
        document.body.classList.add("no_padding");
      }

    const urlParams = new URLSearchParams(window.location.search);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="collaborators-container">
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Financements" breadcrumbItem="Détail financement" />
                    <Row>
                        <Col lg="12">
                            <Alert color="success" isOpen={urlParams.get("success_update") === "1"} toggle={() => removeFlashMessage()}>
                                Le financement à bien mise à jour.
                            </Alert>
                        </Col>
                    </Row>
                    {props.error ? <Alert color="danger">{props.error}</Alert> : null}
                    {!props.financer ? null : (
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg="2">
                                                <div className="text-lg-center">
                                                    {
                                                        props.financer.url !== "Null"
                                                            ? <img src={props.financer.url.match(/http/) !== null ? props.financer.url : `${process.env.REACT_APP_API_URL}/files/${props.financer.url}`} className="avatar-lg mr-3 rounded-circle mb-4 float-left float-lg-none" alt="img" />
                                                            : <div className="avatar-lg mr-3 mx-lg-auto mb-4 float-left float-lg-none">
                                                                <span className={"avatar-title rounded-circle bg-soft-blue text-primary font-size-16"}></span>
                                                            </div>
                                                    }
                                                </div>
                                            </Col>

                                            <Col lg="10">
                                                <h5>{props.financer.name}</h5>
                                                <p className="mb-1">{props.financer.description}</p>
                                                <p className="mb-1"><i className="bx bx-phone mr-1"></i> {props.financer.phone}</p>
                                                <p className="mb-1"><i className="bx bx-envelope mr-1"></i> {props.financer.email}</p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <Row className="container-text-theme-color-2">
                                            <Col xs="12" className="d-flex justify-content-end">
                                                <Link
                                                    to={`/financer/${props.financer.id}/scale/add`}
                                                    className="d-flex align-items-center mr-5"
                                                >
                                                    <i className="bx bx-plus-circle mr-1"></i> Ajouter un barème
                                                </Link>
                                                <Link
                                                    to={`/financer/edit/${parseInt(
                                                        window.location.pathname.split("/")[3],
                                                        10
                                                    )}`}
                                                    className="d-flex align-items-center mr-5"
                                                >
                                                    <i className="bx bx-pencil mr-1"></i> Modifier
                                                </Link>
                                                <Link
                                                    to="#"
                                                    onClick={() => { tog_standard() }}
                                                    data-toggle="modal"
                                                    data-target="#productDelete"
                                                    className="d-flex align-items-center mr-5"
                                                >
                                                    <i className="bx bx-trash mr-1"></i> Supprimer
                                                </Link>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                    <Modal isOpen={modal_standard} toggle={() => { tog_standard() }}>
                                        <div className="modal-header">
                                            <h5 className="modal-title mt-0" id="myModalLabel">Financement - Suppression</h5>
                                            <button type="button" onClick={() =>  { setmodal_standard(false) } } className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <h5>Attention</h5>
                                            <p>Voulez vous supprimer le financeur definitivement ?</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" onClick={() => { tog_standard() }} className="btn btn-secondary waves-effect" data-dismiss="modal">
                                                Annuler
                                            </button>
                                            <button type="button" onClick={() => { deleteThisFinancer() }} className="btn btn-green waves-effect waves-light">
                                                Supprimer
                                            </button>
                                        </div>
                                    </Modal>
                                </Card>
                                <Row>
                                    <Col lg="12">
                                        <h5>Barèmes</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    {props.scales.length === 0
                                        ? <Col><p>Aucun barème</p></Col>
                                        : props.scales.map((scale, index) => (
                                            <CardScale key={"scale_"+index} financer={props.financer} scale={scale} />
                                        ))
                                    }
                                </Row>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { financer, error } = state.Financers;
    const { scales } = state.Scales;
    return { financer, scales, error };
};

export default withRouter(
    connect(mapStatetoProps, { getFinancer, getScales, deleteFinancer, financerApiError })(
        FinancersDetail
    )
);
