import * as Yup from "yup";

export const createDepositValidationSchema = Yup.object().shape({
  invoice_date: Yup.date()
    .required("Veuillez compléter ce champ"),

  percent: Yup.number()
    .typeError("Le pourcentage du prix doit être un nombre")
    .required("Veuillez compléter ce champ")
    .max(100, 'Le pourcentage du prix ne peut pas être supérieur à 100'),

  price: Yup.number()
    .typeError("Le prix doit être un nombre")
    .required("Veuillez compléter ce champ"),

  payment_mode: Yup.string()
    .required("Veuillez compléter ce champ"),
});
