import React from "react";
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import header_img from "../../assets/images/Logo/nwe_logo_blanc.png";
import header_img_2 from "../../assets/images/login/Objet_dynamique_vectoriel3.png";
import logo from "../../assets/images/login/Objet_dynamique_vectoriel2.png";

const ForgetPasswordPage = (props) => {
  function handleValidSubmit(event, values) {
    props.userForgetPassword(values, props.history);
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container className="login-container">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header-bg-soft">
                  <Row>
                    <Col xs={12} md={4}>
                      <div className="pt-4 pl-4 pb-md-4 pb-0 pr-0">
                        <h5 className="login-header-text-theme-color">
                          Bienvenue !
                        </h5>
                        <p className="login-header-text-theme-color">
                          Se connecter
                        </p>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={8}
                      className="align-self-end d-flex flex-row align-items-center justify-content-end pb-1 pb-md-0"
                    >
                      <img
                        src={header_img}
                        alt=""
                        className="img-fluid login-header-img mb-4"
                      />
                      <img
                        src={header_img_2}
                        alt=""
                        className="img-fluid login-header-img"
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Entrer un email"
                          type="email"
                          required
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-left">
                          <Link
                            to="reset-password"
                            className="font-weight-medium text-theme-color-2"
                          >
                            Code déjà reçus
                          </Link>
                        </Col>
                        <Col className="text-right">
                          <button
                            className="btn w-md waves-effect waves-light"
                            type="submit"
                          >
                            Ré-initialiser
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Retourner à la page de{" "}
                  <Link
                    to="login"
                    className="font-weight-medium text-theme-color-2"
                  >
                    Connexion
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
);
