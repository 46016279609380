import {Row, Col, Button, Label } from "reactstrap";
import { Formik, Form } from 'formik';

import { FormikAutocompleteUnselectable } from "../../../components/Common/AsyncAutoComplete2";

import {
  promiseBrandOptions,
  promiseCategoriesOptions,
  promiseSubCategoriesOptions
} from "../../../helpers/autocomplete_promise_options";

const Filter = ({filters, setFilters, emptyFilter}) => {
  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      onSubmit={(values) => {
        setFilters({
          ...filters,
          category_id: values.category_id,
          sub_category_id: values.sub_category_id,
          brand: values.brand
        })
      }}
    >
      {({ values, handleSubmit }) => (
        <Form>
          <Row>
            <Col xs="3" className="mt-3">
              <Row>
                <Col xs="12" className="mt-3">
                  <Label for="project_state_id">Famille</Label>
                </Col>
                <Col xs="12">
                  <FormikAutocompleteUnselectable
                    className="w-100"
                    name="category_id"
                    id="category_id"
                    isSearchable={false}
                    noOptionsMessage={() => "Aucunes familles disponibles"}
                    placeholder="Choisir une famille..."
                    loadOptions={promiseCategoriesOptions}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="3" className="mt-3">
              <Row>
                <Col xs="12" className="mt-3">
                  <Label for="project_state_id">Catégorie</Label>
                </Col>
                <Col xs="12">
                  <FormikAutocompleteUnselectable
                    className="w-100"
                    name="sub_category_id"
                    id="sub_category_id"
                    isSearchable={false}
                    noOptionsMessage={() => "Aucunes catégories disponibles"}
                    placeholder="Choisir une catégorie..."
                    loadOptions={() => promiseSubCategoriesOptions(values.category_id)}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="3" className="mt-3">
              <Row>
                <Col xs="12" className="mt-3">
                  <Label for="project_state_id">Marque</Label>
                </Col>
                <Col xs="12">
                  <FormikAutocompleteUnselectable
                    className="w-100"
                    name="brand"
                    id="brand"
                    isSearchable={false}
                    noOptionsMessage={() => "Aucunes marques disponibles"}
                    placeholder="Choisir une marque..."
                    loadOptions={promiseBrandOptions}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="3" className="mt-3 d-flex"
                  style={{justifyContent: "center", alignItems: "flex-end"}}>
              <Row>
                <Button
                  color="none"
                  className='mr-1'
                  onClick={() => handleSubmit(values)}
                >
                  Appliquer les filtres
                </Button>
                <Button
                  className='button-filter ml-1'
                  onClick={() => emptyFilter()}
                >
                  Vider les filtres
                </Button>

              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default Filter;