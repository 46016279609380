import * as Yup from "yup";

export const createValidationSchema = Yup.object().shape({
  customer: Yup.string().required("Veuillez compléter ce champ"),

  project: Yup.mixed().notRequired(),

  technician: Yup.string().notRequired(),

  reason: Yup.string().required("Veuillez compléter ce champ"),

  comment: Yup.string().notRequired(),

  date_begin: Yup.date().required("Veuillez compléter ce champ"),

  date_end: Yup.date().required("Veuillez compléter ce champ"),

  address: Yup.string().required("Veuillez compléter ce champ"),
});
