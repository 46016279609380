import React from "react";

import { Link } from "react-router-dom";

import {
  Row,
  Col,
} from "reactstrap";
import Project from "../../../../models/project";
import ProductItem from "../../../../models/product_item";

import BadgeState from "../../../Projects/badge-state";
import FreeItem from "../../../../models/free_item";

interface ProjectDetailProps {
  project: Project
}

const ProjectDetail = ({
  project
}: ProjectDetailProps) => {

  const showOrderItem = (item: ProductItem | FreeItem) => {
    let title = "";
    if ('product' in item) {
      title = item.product.title || item.custom_description.substr(0, 100);
    } else if ('free_field' in item) {
      title = item.free_field?.title || item.free_field?.description.substr(0, 100);
    }

    return (
      <Row>
        {item.quantity}&nbsp;
        {title}&nbsp;:&nbsp;
        {item.total_price_ttc.toFixed(2)}€
      </Row>
    )
  };

  return (
    <Row>
      {
        project.order
          ? <Col>
            <div className="text-center mb-2">
              <BadgeState state={project.project_state} />
            </div>
            <h5>Produits</h5>
            <ul>
              {
                (project.order.free_items as (FreeItem|ProductItem)[])
                  .concat(project.order.product_items)
                  .sort((a, b) => a.position - b.position)
                  .map(item => (
                    <li>
                      <Col>
                        {showOrderItem(item)}
                      </Col>
                    </li>
                  ))
              }
            </ul>
            <div className="text-center mt-4 pt-2">
              <Link
                to={`/projects-overview/${project.id}`}
                className="btn btn-green btn-sm"
              >
                Voir plus
              </Link>
            </div>
          </Col>
          : null
      }
    </Row>
  );
};

export default ProjectDetail;