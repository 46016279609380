import React from "react";
import { Field } from "formik";
import AsyncSelect from "react-select/async";

const colorStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? "#62d2a2"
      : isFocused
      ? "#62d2a2"
      : null,
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#62d2a2",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: "#dc3545",
      color: "white",
    },
  }),
};

const SelectField = ({
  loadOptions,
  field,
  form,
  disabled,
  multiple,
  className,
  onChangeField,
  defaultOptions,
  value,
}) => {
  const onChange = (option) => {
    if (onChangeField !== null) {
      onChangeField({ value: option.value, label: option.label });
    } else {
      if (option === null) {
        option = [];
      }

      form.setFieldValue(
        field.name,
        multiple ? option.map((item) => item.value) : option.value
      );
    }
  };
  return (
    <React.Fragment>
      <AsyncSelect
        placeholder="Sélectionner"
        loadOptions={loadOptions}
        cacheOptions={true}
        name={field.name}
        onChange={onChange}
        onBlur={field.onBlur}
        isLoading={disabled}
        isDisabled={disabled}
        defaultOptions={defaultOptions}
        value={value}
        noOptionsMessage={() => "Aucun résultat pour votre recherche"}
        isMulti={multiple}
        styles={colorStyles}
        className={className}
      />
    </React.Fragment>
  );
};
/**
 * @deprecated Use AsyncAutoComplete2 instead
 * @param {object} params
 * @param {string} params.name
 * @param {object} params.options
 * @param {string | null=} params.id
 * @param {boolean=} params.disabled
 * @param {boolean=} params.invalid
 * @param {boolean=} params.multiple - Is multiple selection possible
 * @param {object=} params.defaultValue
 * @param {object=} params.className
 * */
 export default function AsyncAutocomplete({
  name,
  loadOptions,
  defaultOptions,
  value,
  id = null,
  disabled = false,
  invalid = false,
  multiple = false,
  className = null,
  onChange = null,
}) {return(
  <Field
    id={id}
    name={name}
    component={SelectField}
    defaultOptions={defaultOptions}
    value={value}
    loadOptions={loadOptions}
    disabled={disabled}
    invalid={invalid}
    multiple={multiple}
    className={className}
    onChangeField={onChange}
  />
);}
