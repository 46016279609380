import React, { useEffect, useState } from "react";

import { Link, withRouter } from "react-router-dom";
import {
  Alert, Card, Col, Container, Modal, NavItem,
  NavLink, Row
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Redux
import { connect } from "react-redux";

// actions
import {
  createDeposit, deleteProject,
  deleteTechnicalVisitProject, getAbonnementStates, getGenerateInvoice, getProject, getProjectStates, projectsApiError, projectStatesApiError, updateDeposit, updateProject
} from "../../store/actions";

import GlobalLoader from "../../components/Common/GlobalLoader";

// Cards du projet
import FlashMessage from "../../components/Common/FlashMessage";
import { getLastChildOrder, parseQuery } from "../../helpers/helper";
import AdministrativeFolderCard from "./ProjectOverviewCard/AdministrativeFolderCard";
import CEEPrimeCard from "./ProjectOverviewCard/CEEPrimeCard";
import CustomerCard from "./ProjectOverviewCard/CustomerCard";
import DepositCard from "./ProjectOverviewCard/DepositCard";
import DetailCard from "./ProjectOverviewCard/DetailCard";
import DisponibilityCard from "./ProjectOverviewCard/DisponibilityCard";
import DocumentsCard from "./ProjectOverviewCard/DocumentsCard";
import FinancementCard from "./ProjectOverviewCard/FinancementCard";
import FournitureFeesCard from "./ProjectOverviewCard/FournitureFeesCard";
import GenerateDocumentCard from "./ProjectOverviewCard/GenerateDocumentCard";
import IndicatorCard from "./ProjectOverviewCard/IndicatorCard";
import MaintenanceContractCard from "./ProjectOverviewCard/MaintenanceContractCard";
import OfferCodeCard from "./ProjectOverviewCard/OfferCodeCard";
import TechnicalVisitCard from "./ProjectOverviewCard/TechnicalVisitCard";

const ProjectsOverview = (props) => {
  const [project_documents, setProject_documents] = useState({});
  const [projects_states, setProject_states] = useState(Array(0));
  const [abonnements_states, setAbonnements_states] = useState(Array(0));
  const [current_state, setCurrent_state] = useState(null);
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard_delete, setmodal_standard_delete] = useState(false);
  const [modal_deposit, setModal_deposit] = useState(false);
  const [modal_invoice, setModal_invoice] = useState(false);
  const [flash_message, setFlash_message] = useState(false);
  const [questionsCategoryLabel, setQuestionsCategoryLabel] = useState(Array(0));
  const [questionsAnswersArray, setQuestionsAnswersArray] = useState(Array(0));
  const [questionsIsOpen, setQuestionsIsOpen] = useState(Array(0));
  const [questionLabel, setQuestionLabel] = useState({
    label: "",
    value: true,
  });
  const query = parseQuery();
  const [activeTab, setActiveTab] = useState(query.tab || "general");

  useEffect(() => {
    props.getProject.call(null,parseInt(window.location.pathname.split("/")[2]));
  }, [props.getProject]);

  useEffect(() => {
    props.getProjectStates.call();
  }, [props.getProjectStates]);

  useEffect(() => {
    props.getAbonnementStates.call();
  }, [props.getAbonnementStates]);

  useEffect(() => {
    setQuestionsIsOpen(qio => {qio[questionLabel.label] = questionLabel.value; return qio});
  }, [questionLabel]);

  useEffect(() => {
    if (props.project) {
      let docs = {};
      let questions = {};
      let questionsLabel = Array(0);
      let questionsisOpen = {};

      // Documents
      props.project.documents.forEach((document, index) => {
        if (!docs[document.document_classification.name]) {
          docs[document.document_classification.name] = Array(0);
        }
        docs[document.document_classification.name].push(document);
      });

      // Factures
      if (props.project.order !== null) {
        props.project.order.all_invoices.forEach((i, index) => {
          if (i.is_refund) {
            if (!docs["Avoirs"]) {
              docs["Avoirs"] = Array(0);
            }
            docs["Avoirs"].push(i);
          } else {
            if (!docs["Factures"]) {
              docs["Factures"] = Array(0);
            }
            docs["Factures"].push(i);
          }
        });
      }

      // Devis
      if (
        props.project.order
      ) {
        if (!docs["Devis"]) {
          docs["Devis"] = Array(0);
        }
        props.project.order.all_quotes.forEach(quote => {
          docs["Devis"].push(quote)
        })

        props.project.subscriptions.forEach(subscription => {
          if(subscription.quote)
            docs["Devis"].push(subscription.quote)
        })
      }

      setProject_documents(docs);

      if (props.project.project_state) {
        setCurrent_state({
          label: props.project.project_state.name.toUpperCase(),
          value: props.project.project_state.id,
        });
      }

      props.project.project_input_responses.forEach((item, index) => {
        if (!questions[item.sub_category.name]) {
          questions[item.sub_category.name] = Array(0);
          questionsisOpen[item.sub_category.name] = false;
          questionsLabel.push(item.sub_category.name);
        }
        questions[item.sub_category.name].push(item);
      });
      setQuestionsAnswersArray(questions);
      setQuestionsCategoryLabel(questionsLabel);
      setQuestionsIsOpen(questionsisOpen);
    }
  }, [props.project, modal_invoice, modal_deposit]);


  useEffect(() => {
    if (props.projectStates) {
      let options = [];
      props.projectStates.forEach((projectState, index) => {
        if (
          [
            "quoted", "signed_quot", "financing_request", "refusal", "pre_acceptance", "acceptance", "pending_deposit", "invoiced",
            "pending_deposit_encasement", "deposit_receive", "technical_visit", "pending_amendment_signature",
            "pre_planned", "installed", "delivery_send", "late_payment", "finish"
          ].includes(projectState.label)
        )
          options.push({
            label: projectState.name.toUpperCase(),
            value: projectState.id,
          });
      });

      setProject_states([
        {
          options: options,
        },
      ]);
    }
  }, [props.projectStates]);

  useEffect(() => {
    if (props.abonnementStates) {
      let options = [];

      props.abonnementStates.forEach((abonnementState, index) => {
        if (
          abonnementState.name === 'refuse' ||
          abonnementState.name === 'accepted' ||
          abonnementState.name === 'waiting'
        ) {
          options.push({
            label: abonnementState.label.toUpperCase(),
            value: abonnementState.id,
          });
        }
      });

      setAbonnements_states(options);
    }
  }, [props.abonnementStates]);

  function handleSelectGroup(selectedGroup) {
    setCurrent_state(selectedGroup);
  }

  function handleUpdateProject() {
    if (props.project && props.project.project_state) {
      if (props.project.project_state.id === current_state.value) {
        tog_standard();
        setFlash_message(true);
      } else {
        props.updateProject(props.project.id, {
          project_state_id: current_state.value,
        });
        tog_standard();
        setFlash_message(true);
      }
    }
  }

  function handleDeleteProject() {
    props.deleteProject(props.project.id, props.history);
    setmodal_standard_delete(!modal_standard_delete);
    setFlash_message(true);
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_deposit() {
    setModal_deposit(!modal_deposit);
  }

  function tog_invoice() {
    setModal_invoice(!modal_invoice);
  }

  function tog_standard_delete() {
    setmodal_standard_delete(!modal_standard_delete);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toggleQuestionIsOpen = (label) => {
    setQuestionLabel({ label: label, value: !questionsIsOpen[label] });
  };

  const onSubmitDeposit = (values) => {
    if (values.id) {
      props.updateDeposit(
        values.id,
        {
          custom_deposit_date: values.invoice_date,
          ...values
        },
        false
      );
    } else {
      props.createDeposit(
        props.project.order.id,
        values,
        props.project.id,
        props.history
      );
    }
    props.getProject(parseInt(window.location.pathname.split("/")[2]));
    tog_deposit();
  };

  const onSubmitInvoice = (values) => {
    props.getGenerateInvoice(
      props.project.order,
      values,
      props.project.id,
      props.history
    );
    props.getProject(parseInt(window.location.pathname.split("/")[2]));
    tog_invoice();
  };

  let last_order = getLastChildOrder(props.project);

  return (
    <React.Fragment>
      {props.loading ? (
        <GlobalLoader />
      ) : !props.project ? null : (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title={
                props.project.id != null
                  ? `Projet n° ${props.project.id.toString().padStart(8, "0")}`
                  : ""
              }
              breadcrumbItem="Details du Projet"
            />
            <Row>
              <Col lg="12">
                <Alert
                  color="success"
                  isOpen={flash_message && !props.error}
                  toggle={() => setFlash_message(false)}
                >
                  Le projet à bien été mise a jour.
                </Alert>
              </Col>
            </Row>
            <FlashMessage/>
            <Row>
              <Col lg="12" className="d-flex justify-content-end">
                <Card className='d-flex flex-row align-items-center projects-actions'>
                  <Link
                    to={`/relance/add?client_id=${props.project.client.id}&user_id=${props.project.users[0]?.id}&project_id=${props.project.id}`}
                    className='btn btn-green btn-sm mr-2'
                  >
                    <span className="font-size-12">Créer une relance</span>
                  </Link>
                  <Link
                    to="#"
                    onClick={() => {
                      tog_standard_delete(props.project.id);
                    }}
                    data-toggle="modal"
                    data-target="#projectDelete"
                    className="btn btn-danger btn-sm"
                  >
                    <i className="bx bx-trash mr-1"></i>
                    <span className="font-size-12">Supprimer</span>
                  </Link>
                </Card>
                <Modal
                  id="projectDelete"
                  isOpen={modal_standard_delete}
                  toggle={() => {
                    tog_standard_delete();
                  }}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                      Projet - Suppression
                    </h5>
                    <button
                      type="button"
                      onClick={() => {
                        setmodal_standard_delete(false);
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <h5>Attention</h5>
                    <p>Voulez vous supprimer le projet definitivement ?</p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={() => {
                        tog_standard_delete();
                      }}
                      className="btn btn-secondary waves-effect"
                      data-dismiss="modal"
                    >
                      Annuler
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        handleDeleteProject();
                      }}
                      className="btn btn-danger waves-effect waves-light"
                    >
                      Supprimer
                    </button>
                  </div>
                </Modal>
              </Col>
            </Row>
            <ul className="nav nav-tabs nav-tabs-custom mb-3">
              <NavItem>
                <NavLink
                  className={activeTab === "general" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("general");
                  }}
                >
                  Général
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "financier" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("financier");
                  }}
                >
                  Financier
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "visite" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("visite");
                  }}
                >
                  Visites et suivi
                </NavLink>
              </NavItem>
              { props.project.subscriptions.length === 0 ? '' :
                <NavItem>
                  <NavLink
                    className={activeTab === "entretien" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("entretien");
                    }}
                  >
                    Entretien
                  </NavLink>
                </NavItem>}
            </ul>
            {activeTab === "general" ? (
              <Row>
                <Col xl="7" className="col-with-card-same-height">
                  <DetailCard
                    questionsCategoryLabel={questionsCategoryLabel}
                    toggleQuestionIsOpen={toggleQuestionIsOpen}
                    questionsIsOpen={questionsIsOpen}
                    questionsAnswersArray={questionsAnswersArray}
                    current_state={current_state}
                    handleSelectGroup={handleSelectGroup}
                    projects_states={projects_states}
                    tog_standard={tog_standard}
                    modal_standard={modal_standard}
                    setmodal_standard={setmodal_standard}
                    handleUpdateProject={handleUpdateProject}
                    setFlash_message={setFlash_message}
                  />
                </Col>
                <Col xl="5" className="col-with-card-same-height">
                  <CustomerCard />
                </Col>
              </Row>
            ) : null}
            {activeTab === "financier" ? (
              <Row>
                <Col xs="5" className="col-with-card-same-height">
                  <FinancementCard setFlash_message={setFlash_message} />
                </Col>
                {props.project.order &&
                props.project.order.deposits.filter(e => !e.invoice_refunded).length > 0 ? (
                  <Col xs="7" className="col-with-card-same-height">
                    <DepositCard setFlash_message={setFlash_message} />
                  </Col>
                ) : null}
                {props.project.order && props.project.order.cce_prime ? (
                  <Col xs="7" className="col-with-card-same-height">
                    <CEEPrimeCard setFlash_message={setFlash_message} />
                  </Col>
                ) : null}
                <Col xs="5" className="col-with-card-same-height">
                  <FournitureFeesCard setFlash_message={setFlash_message} />
                </Col>
                <Col xs="7" className="col-with-card-same-height">
                  <GenerateDocumentCard
                    onSubmitDeposit={onSubmitDeposit}
                    onSubmitInvoice={onSubmitInvoice}
                    tog_deposit={tog_deposit}
                    tog_invoice={tog_invoice}
                    modal_deposit={modal_deposit}
                    modal_invoice={modal_invoice}
                  />
                </Col>
                <Col xs="5" className="col-with-card-same-height">
                  <IndicatorCard project={props.project}/>
                </Col>
                <Col xs="7" className="col-with-card-same-height">
                  <DocumentsCard
                    documents={project_documents}
                  />
                </Col>
                <Col xs="5" className="col-with-card-same-height">
                  <OfferCodeCard project={props.project} offer_code={last_order?.offer_code} setFlash_message={setFlash_message}/>
                </Col>
              </Row>
            ) : null}
            {activeTab === "visite" ? (
              <Row>
                <Col xs="6" className="col-with-card-same-height">
                  <AdministrativeFolderCard
                    setFlash_message={setFlash_message}
                  />
                </Col>
                <Col xs="6" className="col-with-card-same-height">
                  <TechnicalVisitCard setFlash_message={setFlash_message} />
                </Col>
                <Col xs="6" className="col-with-card-same-height">
                  <DisponibilityCard setFlash_message={setFlash_message} />
                </Col>
              </Row>
            ) : null}
            {activeTab === "entretien" && !props.loading ? (
              <Row>
                <Col className="grid-cards">
                  <MaintenanceContractCard
                    setFlash_message={setFlash_message}
                    abonnements_states={abonnements_states}
                  />
                </Col>
              </Row>
            ) : null}
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { project, error, success, loading } = state.Projects;
  const { projectStates } = state.ProjectStates;
  const { abonnementStates } = state.AbonnementStates;

  return { project, projectStates, abonnementStates, error, success, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    getProject,
    updateProject,
    projectsApiError,
    getProjectStates,
    getAbonnementStates,
    deleteProject,
    deleteTechnicalVisitProject,
    projectStatesApiError,
    getGenerateInvoice,
    createDeposit,
    updateDeposit
  })(ProjectsOverview)
);
