import {useState} from 'react';
import Select from "react-select";

/**
 * The value set in duration will be {hour, minute}
 * @param duration state storing the duration
 * @param onChange
 * @param timeIntervals in minutes
 * @param {string} minTime under the form => 'hh:mm'
 * @param {string} maxTime under the form => 'hh:mm' exclusive
 */
const SelectTimeComponent = ({
                               duration,
                               onChange,
                               timeIntervals = 15,
                               minTime = '00:00',
                               maxTime = '24:00'
                             }) => {

  const computeLabel = (hour, minute) => {
    return hour > 0 ? `${hour} h ${minute > 0 ? minute : ''}` : `${minute} min`
  }

  const computeOptions = () => {
    const [minHours, minMinutes] = minTime.split(':').map(e => parseInt(e));
    const [maxHours] = maxTime.split(':').map(e => parseInt(e));

    const options = [];

    let currentHour = minHours;
    let currentMinute = minMinutes;

    while (currentHour <= maxHours - 1) {
      options.push({
        label: computeLabel(currentHour, currentMinute),
        value: {hour: currentHour, minute: currentMinute}
      });

      if (((currentMinute + timeIntervals) / 60) >= 1)
        currentHour += 1

      currentMinute = (currentMinute + timeIntervals) % 60;
    }

    return options
  }

  const [options] = useState(computeOptions());

  return (
    <div>
      <Select
        options={options}
        defaultValue={options[0]}
        value={duration ? {label: computeLabel(duration.hour, duration.minute), value: duration} : null}
        onChange={(option) => onChange(option.value)}
      />
    </div>
  );
}

export default SelectTimeComponent;