import React, { useCallback, useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import {
  Alert, Button, Card, CardBody,
  CardTitle, Col, Collapse, Container, Modal,
  ModalBody,
  ModalHeader, Row
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { connect } from "react-redux";
import Select from "react-select";
import GlobalLoader from "../../components/Common/GlobalLoader";
import {
  exportProjects, getProjects, getProjectStates, getUsers,
  projectsApiError
} from "../../store/actions";
import ExportForm from "./FormComponent/ExportForm";
import { createExportFormValidationSchema } from "./FormValidationSchemas/createExportFormValidationSchema";

// Table views
import AdministratifTable from "./Tables/AdministratifTable";
import DepositTable from "./Tables/DepositTable";
import GeneralTable from "./Tables/GeneralTable";
import PrimeTable from "./Tables/PrimeTable";
import VisiteTable from "./Tables/VisiteTable";

// Table filters
import AdministratifTableFilters from "./TableFilters/AdministratifTableFilters";
import DepositTableFilters from "./TableFilters/DepositTableFilters";
import GeneralTableFilters from "./TableFilters/GeneralTableFilters";
import PrimeTableFilters from "./TableFilters/PrimeTableFilters";
import VisiteTableFilters from "./TableFilters/VisiteTableFilters";

// Table sorts
// import GeneralTableSorts from "./TableSorts/GeneralTableSorts";

import { parseQuery, setQuery } from "../../helpers/helper";
import { onTableChange } from "../../helpers/table_helper";

const availableDisplayTypes = [
  { value: "general", label: "Général" },
  { value: "visite", label: "Suivi des projets" },
  /*{
    value: "entretien",
    label: "Suivi des contrats d'entretiens",
  },*/
  { value: "deposit", label: "Suivi des acomptes" },
  {
    value: "administratif",
    label: "Suivi administratif",
  },
];

const ProjectsList = (props) => {

  // Filters
  const query = parseQuery();

  const [filters, setFilters] = useState({
    sort_by: query.sort_by || '',
    sort_dir: query.sort_dir || '',
    page: query.page || 1,
    page_size: query.page_size || 25,
    user_id: query.user_id || '',
    date_begin: query.date_begin || '',
    date_end: query.date_end || '',
    agency_id: query.agency_id || '',
    project_state_id: query.project_state_id || '',
    customer: query.customer || '',
    subscription_state: query.subscription_state || '',
    display_type: query.display_type || 'general',
    product_ids: query.product_ids || [],
    category_ids: query.category_ids || [],
    sub_category_ids: query.sub_category_ids || [],
    ref: query.ref || ''
  });

  const getFilters = useCallback(() => {
    let newFilters = {
      page: filters.page,
      page_size: filters.page_size,
      order_by: filters.sort_by,
      order_direction: filters.sort_dir,
      commercial_id: filters.user_id || '',
      date_begin: filters.date_begin || '',
      date_end: filters.date_end || '',
      agency_id: filters.agency_id || '',
      state: filters.project_state_id || '',
      client: filters.customer || '',
      subscription_state: filters.subscription_state || '',
      display_type: filters.display_type || '',
      ref: filters.ref || ''
    };

    if (filters.product_ids && filters.product_ids.length > 0) {
      newFilters.product_ids = filters.product_ids.join(',');
    }

    if (filters.category_ids && filters.category_ids.length > 0) {
      newFilters.category_ids = filters.category_ids.join(',');
    }

    if (filters.sub_category_ids && filters.sub_category_ids.length > 0) {
      newFilters.sub_category_ids = filters.sub_category_ids.join(',');
    }

    if (
      (filters.display_type === "visite" &&
        filters.project_state_id &&
        ![
          "Acceptation",
          "Acompte perçu",
          "Visite technique",
          "Avenant en attente de signature",
          "Installation"
        ].includes(props.projectStates.find((item) => item.id === filters.project_state_id)?.label)) ||
      (filters.display_type === "deposit" &&
        filters.project_state_id &&
          [
            "Brouillon",
            "Devisé",
            "Demande de financement",
            "Refus",
            "Pré acceptation",
            "Acceptation"
          ].includes(props.projectStates.find((item) => item.id === filters.project_state_id)?.label))
    ) {
      delete newFilters.state;
    }

    return newFilters;
  }, [filters, props.projectStates])

  const applyFilter = useCallback(() => {
    props.getProjects.call(this,getFilters()); // Update interface with new filter
  }, [props.getProjects, getFilters]);


  const [filtersCollapseIsOpen, setFiltersCollapseIsOpen] = useState(false);
  const [flash_message, setFlash_message] = useState(false);
  const [modal_export, setModal_export] = useState(false);

  useEffect(() => {
    props.getUsers.call(null,{ pagination: false });
    props.getProjectStates.call(null,{ pagination: false });
  }, [props.getUsers, props.getProjectStates]);

  useEffect(() => {
    setQuery(filters, props.history); // Update query in path

    applyFilter();
  }, [filters, applyFilter, props.history])

  function resetFilters() {
    setFilters({
      sort_by: '',
      sort_dir: '',
      page: 1,
      page_size: 25,
      user_id: '',
      date_begin: '',
      date_end: '',
      agency_id: '',
      project_state_id: '',
      customer: '',
      subscription_state: '',
      display_type: '' || 'general',
      ref: ''
    })
  }

  function removeFlashMessage() {
    window.history.pushState({}, document.title, "/project-list");
    setFlash_message(!flash_message);
  }

  function toggle_modal_export() {
    setModal_export(!modal_export);
  }

  function onSubmitExportProjects(columns) {
    props.exportProjects({
      columns,
      filters: getFilters()
    });
  }

  return (
    <React.Fragment>
      {props.loading || !props.pagination ? (
        <GlobalLoader />
      ) : (
        <div className="page-content">
          <Container fluid className="projects-container">
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Projets" breadcrumbItem="Tous les Projets" />
            <Row>
              <Col lg="12">
                <Alert
                  color="success"
                  isOpen={query.success_add === "1"}
                  toggle={() => removeFlashMessage()}
                >
                  Le projets à bien été créé.
                </Alert>
                <Alert
                  color="success"
                  isOpen={query.success_update === "1"}
                  toggle={() => removeFlashMessage()}
                >
                  Le projets à bien été mise à jour.
                </Alert>
                <Alert
                  color="success"
                  isOpen={query.success_delete === "1"}
                  toggle={() => removeFlashMessage()}
                >
                  Le projets à bien été suprimé.
                </Alert>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">
                      <Row className="pr-2 pl-2 d-flex">
                        <Col>Tous les projets</Col>
                        <Col className="d-flex justify-content-end projects-container">
                          <Button
                            onClick={() => toggle_modal_export()}
                            className="btn btn-secondary"
                          >
                            <i className="bx bx-plus-circle mr-1"></i>{" "}
                            Exporter
                          </Button>
                        </Col>
                      </Row>
                    </CardTitle>
                    <Row>
                      <Col lg="4" xl="3">
                        <div className="d-flex flex-column mb-5">
                          <span className="mb-2">Type d'affichage :</span>
                          <Select
                            className="w-100"
                            value={{value: filters.display_type, label: availableDisplayTypes.find((item) => item.value === filters.display_type)?.label}}
                            options={availableDisplayTypes}
                            onChange={(displayType) =>
                              setFilters({
                                ...filters,
                                display_type: displayType.value
                              })
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <Row>
                          <Col xs="12" className="d-flex justify-content-start">
                            <span
                              className="d-flex text-link mb-3"
                              onClick={() => {
                                setFiltersCollapseIsOpen(!filtersCollapseIsOpen);
                              }} 
                            >
                              <i className="fas fa-filter mr-1"></i>Filtrer
                            </span>
                            <span
                              className="d-flex text-link text-color-unset mb-3"
                              onClick={resetFilters} 
                            >
                              <i className="fas fa-times mr-1"></i> Vider les
                              filtres
                            </span>
                          </Col>
                          <Col xs="12">
                            <Collapse isOpen={filtersCollapseIsOpen}>
                              {filters.display_type === "general" ? (
                                <GeneralTableFilters
                                  filters={filters}
                                  setFilters={setFilters}
                                />
                              ) : null}
                              {filters.display_type === "visite" ? (
                                <VisiteTableFilters
                                  filters={filters}
                                  setFilters={setFilters}
                                />
                              ) : null}
                              {filters.display_type === "entretien" ? (
                                <PrimeTableFilters
                                  filters={filters}
                                  setFilters={setFilters}
                                />
                              ) : null}
                              {filters.display_type === "deposit" ? (
                                <DepositTableFilters
                                  filters={filters}
                                  setFilters={setFilters}
                                />
                              ) : null}
                              {filters.display_type === "administratif" ? (
                                <AdministratifTableFilters
                                  filters={filters}
                                  setFilters={setFilters}
                                />
                              ) : null}
                            </Collapse>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                      <Col lg="4" sm="4">
                        <input
                          type="text"
                          id="ref"
                          className="form-control"
                          placeholder="Rechercher par référence"
                          defaultValue={filters.ref}
                        />
                      </Col>
                      <Col lg="3" sm="3">
                        <Button
                          onClick={() => setFilters(prev => ({
                            ...prev,
                            ref: document.getElementById('ref').value
                          }))}
                          className="btn-green"
                        >
                          Rechercher
                        </Button>
                      </Col>
                    </Row>
                    {filters.display_type === "general" &&
                    props.projects &&
                    props.projects.length > 0 &&
                    props.projects[0].client !== undefined &&
                    props.projects[0].project_state !== undefined &&
                    props.projects[0].users !== undefined ? (
                      <GeneralTable
                        sort={{dataField: filters.sort_by, order: (filters.sort_dir || "desc")}}
                        onTableChange={onTableChange.bind({setFilters, filters})}
                      />
                    ) : null}
                    {filters.display_type === "visite" &&
                    props.projects &&
                    props.projects.length > 0 &&
                    props.projects[0].client !== undefined &&
                    props.projects[0].client !== null &&
                    props.projects[0].project_state !== undefined &&
                    props.projects[0].project_state !== null &&
                    props.projects[0].disponibilities !== undefined &&
                    props.projects[0].disponibilities !== null ? (
                      <VisiteTable
                        sort={{ dataField: filters.sort_by, order: (filters.sort_dir || "desc") }}
                        onTableChange={onTableChange.bind({setFilters, filters})}
                      />
                    ) : null}
                    {filters.display_type === "entretien" &&
                    props.projects &&
                    props.projects.length > 0 &&
                    props.projects[0].project_state !== undefined &&
                    props.projects[0].project_state !== null &&
                    props.projects[0].order !== undefined &&
                    props.projects[0].order !== null &&
                    props.projects[0].order.client !== undefined &&
                    props.projects[0].order.client !== null ? (
                      <PrimeTable
                        sort={{ dataField: filters.sort_by, order: (filters.sort_dir || "desc") }}
                        onTableChange={onTableChange.bind({setFilters, filters})}
                      />
                    ) : null}
                    {filters.display_type === "deposit" ? (
                      <DepositTable
                        sort={{ dataField: filters.sort_by, order: (filters.sort_dir || "desc") }}
                        onTableChange={onTableChange.bind({setFilters, filters})}
                      />
                    ) : null}
                    {filters.display_type === "administratif" &&
                    props.projects &&
                    props.projects.length > 0 &&
                    props.projects[0].client !== undefined &&
                    props.projects[0].client !== null &&
                    props.projects[0].project_state !== undefined &&
                    props.projects[0].project_state !== null ? (
                      <AdministratifTable
                        sort={{ dataField: filters.sort_by, order: (filters.sort_dir || "desc") }}
                        onTableChange={onTableChange.bind({setFilters, filters})}
                      />
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={modal_export} toggle={() => toggle_modal_export()}>
              <ModalHeader toggle={() => toggle_modal_export()} tag="h4">
                Exporter les projets
              </ModalHeader>
              <ModalBody>
                <ExportForm
                  validationSchema={createExportFormValidationSchema}
                  handleSubmit={onSubmitExportProjects}
                />
              </ModalBody>
            </Modal>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { projects, pagination, loading } = state.Projects;
  const { users } = state.Users;
  const { projectStates } = state.ProjectStates;

  return { projectStates, users, projects, pagination, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    getProjectStates,
    getUsers,
    getProjects,
    exportProjects,
    projectsApiError,
  })(ProjectsList)
);
