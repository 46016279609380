import {History} from "history";
import {Button, Card, CardBody, CardTitle, Col, Container, Input, Label, Row} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import FlashMessage, {addFlashMessage, ImportantEnum} from "../../../../components/Common/FlashMessage";
import React, {useCallback, useEffect, useState} from "react";
import Client from "../../../../models/client";
import {match} from 'react-router';
import {V2} from "../../../../api";
import GlobalLoader from "../../../../components/Common/GlobalLoader";
import FormikAutocomplete from "../../../../components/Common/AsyncAutoComplete2";
import {Field, Formik} from "formik";
import moment from "moment";
import {
  promiseProjectsOptions,
  promiseRequestNaturesOptions,
  promiseRequestPriorityOptions,
  promiseRequestStatusOptions,
  promiseSubscriptionsOptions,
  promiseUsersOptions,
} from "../../../../helpers/autocomplete_promise_options_api_v2";
import User from "../../../../models/user";
import Request from "../../../../models/request";
import LoaderLayoutPulse from "../../../../components/Common/LoaderLayoutPulse";
import { parseCustomerName } from "../../../../helpers/customer_helper";


interface RequestCustomerRequestFormProps {
  match: match<{ id: string, requestId: string }>,
  history: History
}
export default function RequestCustomerRequestForm({
  match,
  history
}: RequestCustomerRequestFormProps){
  let isNew = !match.params.requestId; // cond to force return boolean
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState<Client|null>(null);
  const [user, setUser] = useState<User|null>(null);
  const [request, setRequest] = useState<Request|null>(null);
  // @ts-ignore
  const projectsHandle = useCallback(() => promiseProjectsOptions(client?.id), [client]);
  // @ts-ignore
  const subscriptionsHandle = useCallback(() => promiseSubscriptionsOptions(client?.id), [client]);

  useEffect(() => {
    V2.customers.get(parseInt(match.params.id),{}).then(({data}) => setClient(data))
    setUser(JSON.parse(localStorage.getItem("authUser")||"{}"));
    if(match.params.requestId) {
      V2.requests.get(parseInt(match.params.requestId), {})
        .then(({data}) => {setRequest(data);console.log(data)})
        .catch(() => {setRequest({})});
    } else {
      setRequest({})
    }
  }, [match.params.id, match.params.requestId]);

  function validate(values:any){
    let errors: string[] = [];
    if(!values.collaborator_id) errors = [...errors, "collaborator_id"];
    if(!values.nature_id) errors = [...errors, "nature_id"];
    if(!values.priority_id) errors = [...errors, "priority_id"];
    return errors;
  }

  function submit(values:any){
    let fd:FormData = new FormData();
    fd.append("request_at", request?.request_at || (new Date()).toString());
    fd.append("client_id", client?.id?.toString() || "");
    fd.append("zip_code", client?.zip_code?.toString() || "");
    fd.append("nature_id", values.nature_id || "");
    fd.append("interlocutor_id", user?.id?.toString() || "");
    fd.append("collaborator_id", values.collaborator_id || "");
    fd.append("maintenance_contract_id", values.maintenance_contract_id || "");
    fd.append("project_id", values.project_id || "");
    fd.append("client_phone", client?.mobile_phone?.toString() || "");
    fd.append("priority_id", values.priority_id || "");
    fd.append("status_id", values.status_id || "");
    fd.append("comment", values.comment || "");
    setLoading(true);
    V2.requests.upsert(request?.id, fd,{})
    .then(() => {
      if(isNew){
        addFlashMessage(ImportantEnum.SUCCESS, "La demande a correctement été ajoutée");
        history.push("/requests/customers/"+client?.id+"/show?tab=1");
      } else {
        addFlashMessage(ImportantEnum.SUCCESS, "La demande a correctement été modifiée");
        history.push("/requests/"+request?.id+"/details?tab=0");
      }
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  }


  return(
    <>
      <div className="page-content">
        <Container fluid className="notifications-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={isNew ? "Nouvelle demande" : "Modifier une demande"}
            breadcrumbTitle="Suivi des demandes"
            breadcrumbItem={isNew ? "Nouvelle demande" : "Modifier une demande"}
          />
          <FlashMessage/>
          {client && request?(
            <>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-0">
                        <Row className="pr-2 pl-2 d-flex">
                          <Col>Informations du contact</Col>
                        </Row>
                      </CardTitle>
                      <Row className="pr-2 pl-2 d-flex font-size-15 text-theme-color">
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">Client : </span> <br/>
                          <span className="font-weight-light">{parseCustomerName(client)}</span>
                        </Col>
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">Téléphone : </span> <br/>
                          <span className="font-weight-light">{client.mobile_phone}</span>
                        </Col>
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">Addresse : </span> <br/>
                          <span className="font-weight-light">{client.address}, {client.city}</span>
                        </Col>
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">CP : </span> <br/>
                          <span className="font-weight-light"> {client.zip_code}</span>
                        </Col>
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">Date : </span> <br/>
                          <span className="font-weight-light">{( isNew ? moment(new Date()) : moment(request?.request_at)).format('DD/MM/YYYY')}</span>
                        </Col>
                        <Col xs={"3"} className={"mt-3"}>
                          <span className="font-weight-semibold">Interlocuteur : </span> <br/>
                          <span className="font-weight-light">{user?.firstname} {user?.lastname}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Formik initialValues={{
                          collaborator_id: request.collaborator?.id || "",
                          maintenance_contract_id: request.maintenance_contract?.id || "",
                          nature_id: request.nature?.id || "",
                          priority_id: request.priority?.id || 2,
                          project_id: request.project_id || "",
                          status: request.status?.id || "",
                          comment: request.comment || "",
                        }} onSubmit={submit} validateOnBlur={true} validateOnMount={true} validate={validate}>
                          {({values, handleSubmit, setFieldValue, errors}:{values:any,errors:string[],handleSubmit:(e?: React.FormEvent<HTMLFormElement>) => void,setFieldValue:(field: string, value: any, shouldValidate?: boolean) => void})=>(
                            <form className={"w-100 d-flex flex-column justify-content-center align-items-center"}>
                              <Row className={"w-100 px-2 pt-4"}>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Collaborateur *</Label>
                                  <FormikAutocomplete
                                    loadOptions={(query) => promiseUsersOptions({query})}
                                    isSearchable={false}
                                    name={"collaborator_id"}
                                    className={"w-100"}
                                  />
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Projet/dossier rattaché</Label>
                                  <FormikAutocomplete
                                    loadOptions={projectsHandle}
                                    isSearchable={false}
                                    name={"project_id"}
                                    className={"w-100"}
                                  />
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Nature *</Label>
                                  <FormikAutocomplete
                                    loadOptions={promiseRequestNaturesOptions}
                                    isSearchable={false}
                                    name={"nature_id"}
                                    className={"w-100"}
                                  />
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Contrat d'entretien</Label>
                                  <FormikAutocomplete
                                    loadOptions={subscriptionsHandle}
                                    isSearchable={false}
                                    name={"maintenance_contract_id"}
                                    className={"w-100"}
                                  />
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Priorité *</Label>
                                  <FormikAutocomplete
                                    loadOptions={promiseRequestPriorityOptions}
                                    isSearchable={false}
                                    name={"priority_id"}
                                    className={"w-100"}
                                  />
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Status</Label>
                                  <FormikAutocomplete
                                    loadOptions={promiseRequestStatusOptions}
                                    isSearchable={false}
                                    name={"status_id"}
                                    className={"w-100"}
                                  />
                                </Col>
                                <Col xs={"3"} className={"mb-4"}>
                                  <Label className={"mb-1"}>Commentaire</Label>
                                  <Input
                                    id="comment"
                                    name="comment"
                                    tag={Field}
                                    className="form-control"
                                    placeholder="Entrer une valeur"
                                  />
                                </Col>
                              </Row>
                              {loading ? <LoaderLayoutPulse/> : <Button type="button" className={`btn btn-green mt-3 ${errors.length>0?"btn-disabled":""}`} onClick={() => handleSubmit(values as any)}>Valider</Button>}
                            </form>
                          )}
                        </Formik>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ):<GlobalLoader/>}
        </Container>
      </div>
    </>
  );
}