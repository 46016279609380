import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";
import Payment from "../../../../models/payment";

interface ChecksTableProps {
  checks: Payment[],
  onEditAction?: (data: { check_key: number }) => void,
  onDeleteAction?: (data: { check_key: number }) => void,
  history: RouteComponentProps["history"]
}

const ChecksTable = ({
  checks,
  onEditAction,
  onDeleteAction,
  history
}: ChecksTableProps) => {

  // Liste des colonnes qui seront affichées dans le tableau
  const columns: ColumnDescription<Payment>[] = [
    {
      dataField: "number",
      text: "Numéro de chèque",
      formatter: (cell, row) => row.reference || "",
      sort: true,
      classes: "align-middle"
    },
    {
      dataField: "amount",
      text: "Montant",
      formatter: (cell, row) => row.amount + " €" || "",
      sort: true,
      classes: "align-middle"
    },
    {
      dataField: "client_name",
      text: "Nom du client",
      formatter: (cell, row) => row.client_name || "",
      sort: true,
      classes: "align-middle"
    },
    {
      dataField: "invoice_ref",
      text: "Numéro de facture",
      formatter: (cell, row) => row.invoice_reference + "" || "",
      sort: true,
      classes: "align-middle"
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row, index) => (
        <>
          {(onEditAction || onDeleteAction) &&
            <UncontrolledDropdown>
              <DropdownToggle className={"bg-transparent"}>
                <i className="fas fa-ellipsis-v text-theme-color h4-font-size" />
              </DropdownToggle>
              <DropdownMenu right className="bordered">
                {onEditAction && <DropdownItem onClick={() => { onEditAction({ check_key: index }) }}>Editer</DropdownItem>}
                {onDeleteAction && <DropdownItem onClick={() => { onDeleteAction({ check_key: index }) }}>Supprimer</DropdownItem>}
              </DropdownMenu>
            </UncontrolledDropdown>
          }
        </>
      ),
    },
  ];


  return (
    <BootstrapTable
      classes={"light-header"}
      keyField="reference"
      data={checks}
      columns={columns}
      defaultSortDirection="asc"
      remote={{ sort: true }}
    />
  );
};

export default ChecksTable;
