import {V2} from "../api/index";

export function promiseProductsOptions(inputValue, workforce = false) {
  let filters = {};
  if (inputValue && inputValue !== "") {
    filters.query = inputValue;
    filters.pagination = false;
  } else {
    filters.pagination = true;
    filters.page_size = 15;
  }
  if (workforce) filters.workforce = true;
  filters.groups = ['autocomplete']
  return new Promise(async (resolve) => {
    let result = await V2.products.getAll(filters);
    resolve(
      result.data.map((product) => {
        return {
          value: product.id,
          label: product.title,
        };
      })
    );
  });
}

export function promiseProductsOptionsFromIds(ids) {
  let idsString = '';
  if (ids && ids.length > 0) {
    idsString = ids.join(',');
  } else {
    idsString = '-1';
  }
  return new Promise(async (resolve) => {
    let result = await V2.products.getAll({pagination: false, ids: idsString, groups: ['autocomplete']});
    resolve(
      result.data.map((product) => {
        return {
          value: product.id,
          label: product.title,
        };
      })
    );
  });
}
export function promiseRequestPriorityOptions() {
  return new Promise(async (resolve) => {
    let result = await V2.request_priorities.getAll({groups: ['autocomplete']});
    resolve(
      result.data.map((priority) => {
        return {
          value: priority.id,
          label: priority.label,
        };
      })
    );
  });
}
export function promiseRequestTypesOptions() {
  return new Promise(async (resolve) => {
    let result = await V2.request_types.getAll({groups: ['autocomplete']});
    resolve(
      result.data.map((priority) => {
        return {
          value: priority.id,
          label: priority.label,
        };
      })
    );
  });
}
export function promiseRequestStatusOptions() {
  return new Promise(async (resolve) => {
    let result = await V2.request_status.getAll({groups: ['autocomplete']});
    resolve(
      result.data.map((priority) => {
        return {
          value: priority.id,
          label: priority.label,
        };
      })
    );
  });
}
export function promiseRequestNaturesOptions() {
  return new Promise(async (resolve) => {
    let result = await V2.request_natures.getAll({groups: ['autocomplete']});
    resolve(
      result.data.map((priority) => {
        return {
          value: priority.id,
          label: priority.label,
        };
      })
    );
  });
}
export function promiseProjectsCustomOptions(client_id= null) {
  let filters = { pagination: false };
  if (client_id) filters.client_id = client_id;
  return new Promise(async (resolve) => {
    let result = await V2.projects.getAll({...filters, groups: ['autocomplete']});
    resolve(
        result.data.map((project) => {
          return {
            value: `${project.id}-|-${project.name_alias}`,
            label: `${project.id}${project.name_alias?" - ":""}${project.name_alias || ""}`,
          };
        })
    );
  });
}
export function promiseProjectsOptions(client_id= null) {
  let filters = { pagination: false };
  if (client_id) filters.client_id = client_id;
  return new Promise(async (resolve) => {
    let result = await V2.projects.getAll({...filters, groups: ['autocomplete']});
    resolve(
        result.data.map((project) => {
          return {
            value: `${project.id}`,
            label: `${project.id}${project.name_alias?" - ":``}${project.name_alias || ""}`,
          };
        })
    );
  });
}
export function promiseSubscriptionsOptions(client_id= null) {
  let filters = { pagination: false };
  if (client_id) filters.client_id = client_id;
  return new Promise(async (resolve) => {
    let result = await V2.subscriptions.getAll({...filters, groups: ['autocomplete']});
    resolve(
        result.data.map((sub) => {
          return {
            value: sub.id,
            label: sub.number,
          };
        })
    );
  });
}
export function promiseUsersOptions(filters = {}) {
  filters.pagination = false;
  return new Promise(async (resolve) => {
    let result = await V2.users.getAll({...filters, pagination: false, groups: ['autocomplete']});
    resolve(
        result.data.map((user) => {
          return {
            value: user.id,
            label: `${user.firstname} ${user.lastname}`,
          };
        })
    );
  });
}
export function promiseBankAccountsOptions() {
  return new Promise(async (resolve) => {
    let result = await V2.bank_accounts.getAll({groups: ['autocomplete']});
    resolve(
      result.data.map((bank_account) => {
        return {
          value: bank_account.id,
          label: bank_account.bank_label,
        };
      })
    );
  });
}
export function promiseBankAccountsOptionsWithAll() {
  return new Promise(async (resolve) => {
    let result = await V2.bank_accounts.getAll({groups: ['autocomplete']});
    resolve(
      [{value:"all", label: "Tous"}, ...result.data.map((priority) => {
        return {
          value: priority.id,
          label: priority.bank_label,
        };
      })]
    );
  });
}

export function promiseInvoicesOptionsForCheck(client) {
  return new Promise(async (resolve) => {
    let result = await V2.invoices.getAll({ order: 'left_amount_to_paid DESC', clients: client, is_draft: false, groups: ['check_depot_autocomplete'] });
    resolve(
      (result.data.objects || result.data).map((invoice) => {
        return {
          value: invoice.id+"#-#°"+invoice.number,
          label: (invoice.is_paid ? "(payée) " : "") + invoice.number
        }
      })
    );
  })
}
