import {useEffect, useState} from 'react';

import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import BootstrapTable from "react-bootstrap-table-next";
import {subscriptions} from "../../../api";
import {parseDate} from "../../../helpers/subscriptions_helpers";

const SubscriptionInvoicesComponent = ({
                                         subscription
                                       }) => {

  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    subscriptions.invoices(subscription.id, {pagination: false})
      .then(r => setInvoices(r.data))
  }, [subscription])

  const columns = [
    {
      dataField: 'number',
      text: 'N° de facture'
    },
    {
      dataField: 'invoice_date',
      text: 'Date facture',
      formatter: (cell => parseDate(cell))
    },
    {
      dataField: 'mail_send_at',
      text: 'Envoyé le',
      formatter: (cell => parseDate(cell))
    },
    {
      dataField: 'order.total_price_ttc',
      text: 'Total TTC',
      formatter: (cell => <div>{parseFloat(cell).toFixed(2)} €</div>)
    },
    {
      dataField: 'actions',
      formatter: (cell, row) =>
      <UncontrolledDropdown>
        <DropdownToggle tag={"a"} className={"bg-transparent px-3 py-2 d-inline-block"}>
          <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
        </DropdownToggle>
        <DropdownMenu right className="bordered important-default-colors">
          <DropdownItem onClick={() => { window.open(`${process.env.REACT_APP_API_URL}/files/${row.url}`, '_blank')?.focus() }}>Afficher le pdf de la facture dans un nouvel onglet</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ]

  return (
    <div>

      <BootstrapTable
        keyField='id'
        columns={columns}
        data={invoices}
      />
    </div>
  );
}

export default SubscriptionInvoicesComponent;