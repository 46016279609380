import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, logoutUserSuccess, apiError } from "./actions";

import { auth } from "../../../api";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(auth.login, user.email, user.password);
    const JWT = response.data.access_token;
    const refresh_JWT = response.data.refresh_token;
    localStorage.setItem("nwe_jwt", JWT);
    localStorage.setItem("nwe_refresh_jwt", refresh_JWT);
    localStorage.setItem("authUser", JSON.stringify(response.data.user));
    localStorage.setItem("roleUser", JSON.stringify(response.data.role));
    yield put(loginSuccess(response));
    history.push("/dashboard");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(apiError("Email ou mot de passe invalide"));
    } else if (error.response.data !== undefined) {
      yield put(apiError(error.response.data.message));
    } else {
      yield put(apiError(error.message));
    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("roleUser");
    const response = yield call(auth.logout);
    localStorage.removeItem("nwe_jwt");
    localStorage.removeItem("nwe_refresh_jwt");
    yield put(logoutUserSuccess(response));
    history.push("/login");
  } catch (error) {
    if (error.response && error.response.status === 401) {
      history.push("/login");
    } else if (error.response.data !== undefined) {
      yield put(apiError(error.response.data.message));
    } else {
      yield put(apiError(error.message));
    }
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
