import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import FinancerForm from "./FormComponent/FinancerForm";

// Validation Schema
import { createValidationSchema } from "./FormValidationSchemas/createValidationSchema";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import { createFinancer, financerApiError } from "../../store/actions";

const FinancerAdd = (props) => {
  const initValues = {
    name: "",
    url: "",
    description: "",
    phone: "",
    email: "",
  };

  const handleSubmit = (values) => {
    props.createFinancer(
      {
        name: values.name,
        url: values.url,
        description: values.description,
        phone: values.phone,
        email: values.email,
      },
      props.history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Financements" breadcrumbItem="Créer un financement" />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Créer un financement</CardTitle>
                {props.loading ? null : (
                  <FinancerForm
                    initValues={initValues}
                    validationSchema={createValidationSchema}
                    handleSubmit={handleSubmit}
                    financer={""}
                    error={props.error}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { loading, error } = state.Financers;
  return { loading, error };
};

export default withRouter(
  connect(mapStatetoProps, {
    createFinancer,
    financerApiError,
  })(FinancerAdd)
);
