import { History } from "history";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse, Container,
  Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { parseQuery, setQuery } from "../../helpers/helper";
import Offer, { OffersFilters } from "../../models/offer";
import OffersFilter from './Components/Filters/offers-filter';
import OffersTable from './Components/Tables/offers-table';
import FlashMessage, { addFlashMessage, ImportantEnum } from '../../components/Common/FlashMessage';
import OfferCodeGenerateModal from './Components/Modals/offer-code-generate-modal';
import { offer_codes as offerCodesApi } from "../../api";
import OfferCode from "../../models/offer_code";

const OffersList = ({
  history
}: {
  history: History
}) => {

  const [filtersCollapseIsOpen, setFiltersCollapseIsOpen] = useState(false);
  const [offerCode, setOfferCode] = useState<OfferCode | null>(null);

  // Filters

  const query = parseQuery();

  const [filters, setFilters] = useState<OffersFilters>({
    sort_by: query.sort_by as any || '',
    sort_dir: query.sort_dir as any || '',
    page: (query.page || 1) as number,
    page_size: (query.page_size || 25) as number,
    percent: (query.percent || undefined) as number | undefined,
    value: (query.value || undefined) as number | undefined,
    end_at: (query.end_at || null) as string | null,
    search: (query.search || null) as string | null,
  });

  useEffect(() => {
    setQuery(filters, history); // Update query in path
  }, [filters, history]);

  function resetFilters() {
    setFilters({
      sort_by: '',
      sort_dir: '',
      page: 1,
      page_size: 25,
      percent: null,
      value: null,
      end_at: null,
      search: null
    });
  }
  
  const generateCode = (offer: Offer) => {
    offerCodesApi.upsert({offer_id: offer.id}).then((response) => {
      addFlashMessage(ImportantEnum.SUCCESS, `Le code ${response.data.code} a été généré`);
      setOfferCode(response.data);
      setFilters({...filters, page: 1});
    }).catch(error => {
      addFlashMessage(ImportantEnum.DANGER, 'Le code n\'a pas pu être généré');
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="notifications-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Promotions"
            breadcrumbItem="Toutes les promotions"
          />
          <FlashMessage/>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">
                    <Row className="pr-2 pl-2 d-flex">
                      <Col>Toutes les promotions</Col>
                      <Col className="d-flex justify-content-end">
                        <Link
                          to="/offer/add"
                          className="d-flex align-items-center text-theme-color-2"
                        >
                          <i className="bx bx-plus-circle mr-1"></i> Ajouter
                        </Link>
                      </Col>
                    </Row>
                  </CardTitle>
                  <Row>
                    <Col xs="12">
                      <Row>
                        <Col xs="12" className="d-flex justify-content-start">
                          <span
                            className="d-flex text-link mb-3"
                            onClick={() => {
                              setFiltersCollapseIsOpen(!filtersCollapseIsOpen);
                            }}
                          >
                            <i className="fas fa-filter mr-1"></i>Filtrer
                          </span>
                          <span
                            className="d-flex text-link text-color-unset mb-3"
                            onClick={resetFilters}
                          >
                            <i className="fas fa-times mr-1"></i> Vider les
                            filtres
                          </span>
                        </Col>
                        <Col xs="12">
                          <Collapse isOpen={filtersCollapseIsOpen}>
                            <OffersFilter
                              filters={filters}
                              setFilters={setFilters}
                            />
                          </Collapse>
                        </Col>
                      </Row>
                      <Row className="mt-3 mb-3">
                        <Col lg="4" sm="4">
                          <input
                            type="text"
                            id="offer_query"
                            className="form-control"
                            placeholder="Rechercher une promotion"
                            defaultValue={filters.search || ""}
                          ></input>
                        </Col>
                        <Col lg="3" sm="3">
                          <Button
                            onClick={() => {
                              setFilters({
                                ...filters,
                                search: (document.getElementById('offer_query') as HTMLInputElement).value,
                              });
                            }}
                            color="none"
                            className="btn-green"
                          >
                            Rechercher
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <OffersTable filters={filters} setFilters={setFilters} generateCode={(offer: Offer) => generateCode(offer)} history={history} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {offerCode && <OfferCodeGenerateModal code={offerCode} onAbort={() => setOfferCode(null)}/>}
    </React.Fragment>
  );
};

export default OffersList;
