import React, { useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Formik } from 'formik';
import DatePicker from "react-datepicker";
import moment from "moment";
import { V2 } from "../../../api";
import FormikAutocomplete from '../../../components/Common/AsyncAutoComplete2';
import LoaderLayoutPulse from '../../../components/Common/LoaderLayoutPulse';

interface FormValues {
  date_begin: Date | null,
  date_end: Date | null,
  force: string | null,
  type: string | null
}

interface comptaExportModalProps {
  onAbort: () => void,
}

export default function ComptaExportModal({ onAbort }: comptaExportModalProps) {
  const [formValid, setFormValid] = useState(false);

  function onValidate(values: FormValues) {
    setFormValid(values.date_end != null && values.date_begin != null && values.date_begin < values.date_end)
  }
  async function onSubmit(values: FormValues) {
    let query = { date_begin: values.date_begin, date_end: values.date_end } as FormValues;
    if (values.force) query.force = values.force;
    if (values.type) query.type = values.type;
    let response = await V2.compta_exporter.get(query);
    window.open(window.URL.createObjectURL(response.data));
  }

  return (
    <Modal isOpen={true} toggle={onAbort} className={""}>
      <ModalHeader toggle={onAbort}>Exporter les écritures comptables</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={
            {
              date_begin: null,
              date_end: null,
              force: 'false',
              type: 'all'
            } as FormValues
          }
          onSubmit={onSubmit}
          validateOnChange={true}
          validate={onValidate}
        >
          {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
            <form className={"w-100 d-flex flex-column justify-content-center align-items-start"}>
              <Row className={"w-100"}>
                <Col xs={"12"}>
                  <p>
                    Choisissez la période correspondant aux écritures comptables à exporter.
                  </p>
                </Col>
                <Col xs={"8"}>
                  <Label for="date_begin" style={{ color: "#183254" }} className={"mt-4"}><b>Date de début :</b></Label>
                  <DatePicker
                    className="form-control button-without-style"
                    name="date_begin"
                    selected={values.date_begin ? new Date(values.date_begin) : null}
                    onChange={v => setFieldValue("date_begin", v)}
                    isClearable={true}
                    placeholderText={moment(new Date()).format('DD/MM/yyyy')}
                    dateFormat={"dd/MM/yyyy"}
                  />
                  <Label for="date_end" style={{ color: "#183254" }} className={"mt-4"}><b>Date de fin :</b></Label>
                  <DatePicker
                    className="form-control button-without-style"
                    name="date_end"
                    selected={values.date_end ? new Date(values.date_end) : null}
                    onChange={v => setFieldValue("date_end", v)}
                    isClearable={true}
                    placeholderText={moment(new Date()).format('DD/MM/yyyy')}
                    dateFormat={"dd/MM/yyyy"}
                  />
                  <Label for="type" style={{ color: "#183254" }} className={"mt-4"}><b>Type :</b></Label>
                  <FormikAutocomplete
                    loadOptions={[
                      { label: 'Tout', value: 'all' },
                      { label: 'Facture', value: 'invoice' },
                      { label: 'Paiement', value: 'payment' }
                    ]}
                    isSearchable={false}
                    multiple={false}
                    name={"type"}
                  />
                  <Label for="force" style={{ color: "#183254" }} className={"mt-4"}><b>Forcer la génération : </b></Label>
                  <FormikAutocomplete
                    loadOptions={[{ label: 'Oui', value: 'true' }, { label: 'Non', value: 'false' }]}
                    isSearchable={false}
                    multiple={false}
                    name={"force"}
                  />
                </Col>
              </Row>
              <div className={"d-flex justify-content-end mx-n3 px-3 mt-4 border-top"} style={{ width: "calc(100% + 2rem)" }}>
                <Button type="button" className={"btn btn-secondary mt-2 mr-3"} style={{ background: "#96988F", borderColor: "#96988F" }} onClick={onAbort}>Annuler</Button>
                <Button type="button" className={"btn btn-green mt-2"} disabled={!formValid} onClick={() => handleSubmit(values as any)}>Valider</Button>
              </div>
              {isSubmitting && <div className={"d-flex justify-content-center w-100"}><LoaderLayoutPulse/></div>}
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
}
