import {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {Container, Row, Col, Card, CardBody, CardTitle} from "reactstrap";


// Redux
import {connect} from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {
    getOrder,
    getInvoice,
} from "../../store/actions";

import {
  typeForm,
} from "../../helpers/quotes_invoices_helper";
import QuoteInvoiceComponent from "../Quotes/Components/QuoteInvoiceComponent";
import LoaderLayoutPulse from "../../components/Common/LoaderLayoutPulse";

const OrderEdit = (props) => {

    useEffect(() => {
        props.getInvoice.call(undefined, parseInt(window.location.pathname.split("/")[4], 10));
    }, [props.getInvoice]);

    return (
        <div className="page-content">
            <Container fluid className="quotes-container">
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Factures" breadcrumbItem="Modifier une facture"/>

                {!props.loading && props.invoice ?
                    <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4">
                                    Modifier la facture{" "}
                                    {props.invoice.number === null ||
                                    props.invoice.number === undefined ||
                                    props.invoice.is_draft
                                        ? ""
                                        : `N°${props.invoice.number}`}
                                </CardTitle>
                                <QuoteInvoiceComponent
                                    typeForm={typeForm.invoice}
                                    invoice={props.invoice}
                                    error={props.error}
                                    invoice_order={props.invoice_order}
                                    isUpdate={true}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row> : <LoaderLayoutPulse/>}
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    const {error, invoice_order } = state.Orders;
    const {invoice, loading} = state.Invoices;

    return { loading, error, invoice, invoice_order};
};

export default withRouter(
    connect(mapStateToProps, {
        getOrder,
        getInvoice,
    })(OrderEdit)
);
