import React, {useEffect, useState} from 'react';
import BootstrapTable, { ColumnDescription, SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {V2} from '../../../../../api';
import GlobalLoader from "../../../../../components/Common/GlobalLoader";
import { onTableChange } from "../../../../../helpers/table_helper";
import { RequestsFilters } from '../../../../../models/request';
import Pagination, { getPaginationFromResponse } from '../../../../../models/types/pagination';
import {Badge} from "reactstrap";
import moment from "moment";
import InterventionFolder from "../../../../../models/intervention_folder";
import {Link} from "react-router-dom";
import { getStatusColor } from '../../../../../models/requests_status';

interface ProjectTableProps {
  filters: any,
  setFilters: React.Dispatch<React.SetStateAction<RequestsFilters>>
}

const InterventionFileTable = ({
 filters,
 setFilters,
}: ProjectTableProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [interventionFolders, setInterventionFolders] = useState<InterventionFolder[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);

  const getRequests = () => {
    setLoading(true);
    V2.interventions.getAll(filters).then(response => {
      setInterventionFolders(response.data as InterventionFolder[]);
      setPagination(getPaginationFromResponse(response));
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };
  useEffect(getRequests, [filters]);

  // Liste des colonnes qui seront affichées dans le tableau
  const columns: ColumnDescription<InterventionFolder>[] = [
    {
      dataField: "type",
      text: "Type",
      formatter: (cell, row) => row.intervention_folder_type === "maintenance" ? "Entretien" : "SAV" || "",
      sort: true
    },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell, row) => moment(row.intervention_at).format("DD/MM/YY") || "",
      sort: true
    },
    {
      dataField: "status",
      text: "Etat",
      formatter: (cell, row) =>
          <Badge style={{ background: (getStatusColor(row.status || {name:"new"}))}}>
            {row.status?.label}
          </Badge> || "",
      sort: true
    },
    {
      dataField: "product",
      text: "Produit",
      formatter: (cell, row) => (row.project?row.project.first_product_label:"Non défini") || "",
    },
    {
      dataField: "comment",
      text: "Commentaire",
      formatter: (cell, row) => row.comment || "",
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (<Link to={`/interventions/${row.id}/details`} className={"text-nowrap"}>En détails <i className="fa fa-angle-right"/></Link>),
    },
  ];

  return (
    loading ? <GlobalLoader/>  : <BootstrapTable
      keyField="id"
      data={interventionFolders}
      columns={columns}
      defaultSortDirection="asc"
      sort={{ dataField: filters.sort_by, order: (filters.sort_dir || "asc")  as SortOrder}}
      remote={{ pagination: true, sort: true }}
      onTableChange={onTableChange.bind({ setFilters, filters })}
      pagination={paginationFactory({
        page: pagination?.current_page,
        totalSize: pagination?.total_count,
        sizePerPage: pagination?.current_page_size,
        sizePerPageList: [
          { text: "Afficher 10 éléments", value: 10 },
          { text: "Afficher 25 éléments", value: 25 },
          { text: "Afficher 50 éléments", value: 50 },
          { text: "Afficher 75 éléments", value: 75 },
          { text: "Afficher 100 éléments", value: 100 },
        ],
      })}
    />
  );
};

export default InterventionFileTable;
