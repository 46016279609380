import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";

// components
import InputComponent from "../Components/InputComponent";
import AddItemComponent from "../Components/AddItemComponent";
import QuoteCustomerComponent from "../Components/QuoteCustomerComponent";
import ValidationButtonsComponent from "../Components/ValidationButtonsComponent";

// Validations
import {invoiceValidationSchema} from "../FormValidationSchemas/invoiceValidationSchema";

// Modals
import CustomerModal from "../Modals/CustomerModal";
import DepositModal from "../Modals/DepositModal";
import InvoiceModal from "../Modals/InvoiceModal";
import ValidationModal from "../Modals/ValidationModal";

// helpers
import {computeName, containsWorkforce, typeForm} from "../../../helpers/quotes_invoices_helper";
import {computeButtons, canValidate} from "../../../helpers/validation_buttons_helpers";

// Context
import ValuesContext from "../Context/ValuesContext";
import SelectComponent from "../Components/SelectComponent";
import {Alert, Col} from "reactstrap";
import LoaderLayoutPulse from "../../../components/Common/LoaderLayoutPulse";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {createDeposit} from "../../../store/deposits/actions";
import {getGenerateInvoice} from "../../../store/orders/actions";
import {createCustomer} from "../../../store/customers/actions";
import {quoteValidationSchema} from "../FormValidationSchemas/quoteValidationSchema";

/**
 *
 * @param handleSubmit
 * @param showQuote
 * @param isLoading
 * @param isUpdate
 *
 * @return {JSX.Element}
 */
const GlobalQuoteInvoiceForm = ({
    handleSubmit,
    isLoading,
    isUpdate,
    error,
    createDeposit,
    getGenerateInvoice,
    order,
    history,
    customer,
    createCustomer,
    formType,
    isAddUpdate,
    invoice
    }) => {
    // Values of the form
    const valuesContext = useContext(ValuesContext);
    //const formType = useContext(FormTypeContext);

    // Modals states
    const [customerModal, setCustomerModal] = useState(false);
    const [depositeModal, setDepositModal] = useState(false);
    const [invoiceModal, setInvoiceModal] = useState(false);
    const [quoteValidationModal, setQuoteValidationModal] = useState(false);

    const [errors, setErrors] = useState({dummy: ""});

    const [btnIsLoading, setBtnIsLoading] = useState(false);

    // Set the ability of showing errors. Allow us to display errors only after the first submit
    const [showErrors, setShowErrors] = useState(false);

    const [disabled, setDisabled] = useState(false);


    /**
     * Compute if the form has errors
     * @return {boolean}
     */
    const submitForm = useCallback((show) => {
        //setQuoteValidationModal(true);
        setErrors({});

        if(!valuesContext.values.cce_prime)
            valuesContext.rowSetValues(prev => ({...prev, cce_prime: 0}))

        if(!valuesContext.values.other_prime_amount)
            valuesContext.rowSetValues(prev => ({...prev, other_prime_amount: 0}))

        const validation = formType === typeForm.quote || formType === typeForm.approval ?
            quoteValidationSchema.validate(valuesContext.values, {abortEarly: false}) :
            invoiceValidationSchema.validate(valuesContext.values, {abortEarly: false});

        validation.then(ret => {
            if(containsWorkforce(valuesContext.values.free_fields) || show) {
                handleSubmit(show);
            }else{
                if(formType !== typeForm.refund)
                    setQuoteValidationModal(true);
                else
                    handleSubmit(show);
            }

        }).catch(ret => {
            // browse each errors and put them in error state
            // looks like this : {name0: message0, name1: message1, ...}
            setShowErrors(true);
            ret.inner.forEach((err => {
                setErrors(prev => ({
                    ...prev,
                    [err.path]: err.message
                }));
            }));
        });
    }, [valuesContext, handleSubmit, formType])

    // ######### HANDLERS FOR BUTTONS #########
    const show = useCallback(() => {
        setBtnIsLoading(true);
        submitForm(true);
    }, [submitForm])

    const submit = useCallback(() => {
        setBtnIsLoading(true);
        submitForm(false);
      }, [submitForm])

    const deposit = useCallback(() => {
        setDepositModal(true);
    }, [])

    const balance = useCallback(() => {
        setInvoiceModal(true);
    }, [])

    const handlers = useMemo(() => ({
        creation: submit,
        update: submit,
        preview: show,
        deposit: deposit,
        balance: balance,
    }), [submit, show, deposit, balance]);
    // #######################################

    const [buttonsStates, setButtonsStates] = useState(
        computeButtons(
            isAddUpdate,
            order || invoice,
            false,
            formType,
            handlers,
            isUpdate
        ));

    useEffect(() => {

        const validation = formType === typeForm.quote || formType === typeForm.approval ?
            quoteValidationSchema.validate(valuesContext.values, {abortEarly: false}) :
            invoiceValidationSchema.validate(valuesContext.values, {abortEarly: false});

        validation.then(ret => {
            setErrors({});
        }).catch(ret => {
            let errorsTmp = {}
            // browse each errors and put them in error state
            // looks like this : {name0: message0, name1: message1, ...}
            ret.inner.forEach((err => {
                errorsTmp = {
                    ...errorsTmp,
                    [err.path]: err.message
                }
            }));

            setErrors(errorsTmp);
        });
    }, [valuesContext.values, formType])

    useEffect(() => {
        setButtonsStates(
            computeButtons(
                invoice ? (invoice.is_draft ? isAddUpdate : false) : isAddUpdate,
                order || invoice,
                (Object.keys(errors).length === 0),
                formType,
                handlers,
                isUpdate
            ));

        setDisabled(
            isUpdate &&
            (
                !canValidate((order || invoice), formType, isAddUpdate) ||
                (invoice ? !invoice.is_draft : false)
            )
        );
    }, [isUpdate, formType, errors, isAddUpdate, handlers, invoice, order])


    // Update customer if it is created from the customer form
    useEffect(() => {
        if(customer)
        {
            valuesContext.setValues.call(null,'customerType', customer.client_type);
            valuesContext.setValues.call(null,'customer', {
                value: customer.id,
                label: customer.client_type === "Professionnel" ?
                    customer.company_name :
                    (customer.civility + " " + customer.lastname + " " + customer.firstname)
            })
        }
    }, [customer, valuesContext.setValues])

    const toggleCustomerModal = () => {
        setCustomerModal(!customerModal);
    }

    return (

        <div>
            <form>
                <QuoteCustomerComponent
                    openModalCustomer={toggleCustomerModal}
                    errors={errors}
                    showErrors={showErrors}
                    disabled={disabled}
                    formType={formType}
                />

                {
                    formType === typeForm.refund ?
                        <InputComponent
                            name="refund_on"
                            value={valuesContext.values.refund_on}
                            handleChange={(target) => valuesContext.setValues(target.name, target.value)}
                            label="Facture de référence"
                            placeholder="Entrer la facture de référence"
                            classNameLabel="col-lg-2"
                            classNameInput="col-lg-10"
                            error={errors.refund_on}
                            showError={showErrors}
                            isDisabled={disabled}
                        />
                    :
                        ""
                }
                <AddItemComponent
                    tableDatas={valuesContext.values.free_fields}
                    setTableDatas={valuesContext.rowSetValues}
                    errors={errors}
                    showErrors={showErrors}
                    disabled={disabled}
                    formType={formType}
                />
                <div>
                    <InputComponent
                        name="cee_label"
                        value={valuesContext.values.cee_label}
                        handleChange={(target) => valuesContext.setValues(target.name, target.value)}
                        label="Intitulé de la prime CEE"
                        placeholder="Remise prime CEE"
                        classNameLabel="col-lg-2"
                        classNameInput="col-lg-10"
                        error={errors.cee_label}
                        showError={showErrors}
                        isDisabled={disabled}
                    />

                    <InputComponent
                        name="cce_prime"
                        value={valuesContext.values.cce_prime}
                        handleChange={(target) => valuesContext.setValues(target.name, target.value)}
                        label="Prime énergie"
                        placeholder="Entrer la prime d'énergie"
                        classNameLabel="col-lg-2"
                        classNameInput="col-lg-10"
                        error={errors.cce_prime}
                        showError={showErrors}
                        isDisabled={disabled}
                    />

                    <InputComponent
                        name="other_prime_label"
                        value={valuesContext.values.other_prime_label}
                        handleChange={(target) => valuesContext.setValues(target.name, target.value)}
                        label="Intitulé de l'autre prime"
                        placeholder="Intitulé de l'autre prime"
                        classNameLabel="col-lg-2"
                        classNameInput="col-lg-10"
                        error={errors.other_prime_label}
                        showError={showErrors}
                        isDisabled={disabled}
                    />

                    <InputComponent
                        name="other_prime_amount"
                        value={valuesContext.values.other_prime_amount}
                        handleChange={(target) => valuesContext.setValues(target.name, target.value)}
                        label="Autre prime"
                        placeholder="Entrer l'autre prime"
                        classNameLabel="col-lg-2"
                        classNameInput="col-lg-10"
                        error={errors.other_prime_amount}
                        showError={showErrors}
                        isDisabled={disabled}
                    />

                    {/* Fields only appearing when the form is an invoice one */}
                    {
                        formType === typeForm.invoice ?
                            <React.Fragment>
                                {/* taxesless market price */}
                                <InputComponent
                                    label="Montat HT du marché"
                                    placeholder="Entrer le montant HT du marché"
                                    name="total_price_deal_ht"
                                    value={valuesContext.values.total_price_deal_ht}
                                    handleChange={(target) => valuesContext.setValues(target.name, target.value)}
                                    classNameLabel="col-lg-2"
                                    classNameInput="col-lg-10"
                                    isDisabled={disabled}
                                />

                                {/* taxesfull market price */}
                                <InputComponent
                                    label="Montat TTC du marché"
                                    placeholder="Entrer le montant TTC du marché"
                                    name="total_price_deal_ttc"
                                    value={valuesContext.values.total_price_deal_ttc}
                                    handleChange={(target) => valuesContext.setValues(target.name, target.value)}
                                    classNameLabel="col-lg-2"
                                    classNameInput="col-lg-10"
                                    isDisabled={disabled}
                                />
                            </React.Fragment>
                                :
                                ""
                    }
                    {
                        formType === typeForm.invoice || formType === typeForm.refund ?

                                /* taxesless market price */
                                <SelectComponent
                                    label="Mode de paiement"
                                    placeholder="Choisir un mode de paiement"
                                    value={valuesContext.values.payment_mode ? {label: valuesContext.values.payment_mode, value: valuesContext.values.payment_mode} : null}
                                    handleChange={(val) => valuesContext.setValues('payment_mode', val)}
                                    classNameLabel="col-lg-2"
                                    classNameSelector="col-lg-10"
                                    className="row"
                                    isDisabled={disabled}
                                    options={[
                                        {
                                            value: "Chèque",
                                            label: "Chèque",
                                        },
                                        {
                                            value: "Virement",
                                            label: "Virement",
                                        },
                                        {
                                            value: "Prélèvement",
                                            label: "Prélèvement",
                                        },
                                    ]}
                                />
                        :
                        ""
                    }
                </div>
                <ValidationButtonsComponent
                    states={buttonsStates}
                />

                {/* Display if a request is loading*/}
                {isLoading && btnIsLoading ? (
                    <Col xs="12" className="text-center">
                        <div>
                            <p className="text-center">
                                {isUpdate
                                    ?
                                        computeName(formType, true) + " est en cours de modification"
                                    :
                                        computeName(formType, true) + " est en cours de création"}
                            </p>
                        </div>
                        <LoaderLayoutPulse/>
                    </Col>
                ) :
                    error ? (
                        <Col xs="12" className="text-center">
                            <Alert color="danger" className="d-inline-block">
                                {error}
                            </Alert>
                        </Col>
                    ) : null}
            </form>
            <CustomerModal
                isOpen={customerModal}
                toggler={() => toggleCustomerModal()}
                client_type={valuesContext.values.customerType}
                createCustomer={createCustomer}
            />
            <DepositModal
                isOpen={depositeModal}
                toggler={() => setDepositModal(!depositeModal)}
                handleSubmit={(values) => {
                    createDeposit(order.id, values, true, history);
                    setDepositModal(false);
                }}
                order={order}
            />
            <InvoiceModal
                isOpen={invoiceModal}
                toggler={() => setInvoiceModal(!invoiceModal)}
                handleSubmit={(values) => {
                    getGenerateInvoice(order, values, true, history);
                    setInvoiceModal(false);
                }}
            />
            <ValidationModal
                isOpen={quoteValidationModal}
                toggler={() => setQuoteValidationModal(!quoteValidationModal)}
                btnValidText="Créer"
                btnBackText="Annuler"
                title="Devis - Création"
                message={<div><h2>Attention</h2><p>Voulez vous créer le devis sans ajouter de main d'œuvre ?</p></div>}
                handleSubmit={() => handleSubmit(false)}
            />
        </div>
    );
}

const mapStatetoProps = (state) => {
    const {customer} = state.Customers;

    return {customer};
};

export default withRouter(
    connect(mapStatetoProps, {
        createDeposit,
        getGenerateInvoice,
        createCustomer,
    })(GlobalQuoteInvoiceForm)
);
