import {History} from "history";
import { match } from 'react-router';
import CustomerDetailsComponent from "../../Customers/Component/customer_details_component";
import FlashMessage from "../../../components/Common/FlashMessage";
import {Container} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

interface RequestCustomerDetailsProps {
  match: match<{ id: string }>,
  history: History
}

export default function RequestCustomerDetails({
  match,
  history
}: RequestCustomerDetailsProps){
  return(
    <>
      <div className="page-content">
        <Container fluid className="notifications-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Nouvelle demande"
            breadcrumbTitle="Suivi des demandes"
            breadcrumbItem="Nouvelle demande"
          />
          <FlashMessage/>
          <CustomerDetailsComponent customerId={parseInt(match.params.id)} history={history} />
        </Container>
      </div>
    </>
  );
}