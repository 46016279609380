import React, {useState} from 'react';
import {Card, CardBody, CardHeader, CardTitle, Collapse, Table} from "reactstrap";
import {Link} from "react-router-dom";

const DocumentsCard = ({
                         documents
                       }) => {

  const [selectedKey, setSelectedKey] = useState('');

  const keys = Object.keys(documents);


  // Compute the total number of documents
  const nbDocs = Object.entries(documents).reduce(
    (acc, current) => (acc + current[1].length), 0
  );

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">
          Documents {keys.length === 0 ? "(0)" : `(${nbDocs})`}
        </CardTitle>
        <div>
          {
            keys.map((key, index) => {

              return (

                <div className='accordion' key={index}>
                  <Card className="mb-1">
                    <CardHeader
                      className="p-3"
                      id={key}
                      onClick={() => {
                        if (selectedKey !== key || selectedKey === '')
                          setSelectedKey(key)
                        else
                          setSelectedKey('')
                      }}
                      style={{cursor: "pointer"}}
                    >
                      <h6 className="m-0 font-14 d-flex justify-content-between align-items-center">
                        <span className="text-dark">{`${key} (${documents[key].length})`}</span>
                        <i
                          className={`bx bx-chevron-${selectedKey === key ? 'up' : 'down'} mr-1`}
                        />
                      </h6>


                    </CardHeader>
                    <Collapse
                      isOpen={(selectedKey === key)}
                    >
                      <div className="table-responsive">
                        <Table className="table table-nowrap table-centered table-hover mb-0">
                          <tbody>
                          {documents[key].map((doc, indexDoc) => (
                              <tr key={`${key}_${indexDoc}`}>
                                <td
                                  style={{
                                    width: "45pw",
                                  }}
                                >
                                  <div className="avatar-sm">
                                    {doc && doc.is_canceled ? (
                                      <span
                                        className="avatar-title rounded-circle bg-soft-danger text-danger font-size-24">
                                      <i className="fas fa-times"/>
                                    </span>
                                    ) : (
                                      <span
                                        className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                                      <i className="bx bxs-file-doc"/>
                                    </span>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <h5 className="font-size-14 mb-1">
                                    <Link
                                      to="#"
                                      className="text-dark"
                                    >
                                      {doc?.number
                                        ? `n° ${doc?.number}`
                                        : "Brouillon"}
                                    </Link>
                                  </h5>
                                </td>
                                <td>
                                  <div className="text-center">
                                    <a
                                      href={
                                        doc?.url
                                          ? doc?.url?.match(
                                          /http/
                                          ) !== null
                                          ? doc?.url
                                          : `${process.env.REACT_APP_API_URL}/files/${doc?.url}`
                                          : `${process.env.REACT_APP_API_URL}/files/${doc?.number}/${doc?.number}.pdf`
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-dark"
                                    >
                                      <i className="bx bx-download h3 m-0"/>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                          </tbody>
                        </Table>
                      </div>
                    </Collapse>
                  </Card>
                </div>

              );

            })
          }
        </div>
      </CardBody>
    </Card>
  );
}

export default DocumentsCard;