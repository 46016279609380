import * as Yup from "yup";

export const formulasFormValidation = Yup.object().shape({

  formula_type: Yup.string().required("Le type de formule est requis"),

  label: Yup.string().required("Le libellé est requis"),

  description: Yup.string().required("Une description est requise"),

  price_ttc_monthly: Yup.number()
    .typeError("Le prix doit être un nombre")
    .positive("Le prix doit être positif")
    .required("Le prix est requis"),

  vat: Yup.mixed().required("La TVA est requise"),

});