import React from "react";

export default interface RequestPriority {
  id?: number|null,
  name?: string|null,
  label?: string|null,
}

export function getPriorityIcon(priority: RequestPriority) {
  switch (priority.name) {
    case "high": return (<i className="fas fa-arrow-up mr-1" style={{color: "#F02B2B"}}/>);
    case "normal": return (<i className="fas fa-equals mr-1" style={{color: "#696EDE"}}/>);
    case "low": return (<i className="fas fa-arrow-down mr-1" style={{color: "#6EB375"}}/>);
  }
}