import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CREATE_DEPOSIT, UPDATE_DEPOSIT } from "./actionTypes";
import {
  createDepositSuccess,
  updateDepositSuccess,
  depositsApiError,
} from "./actions";

import { deposits } from "../../api";

function* createDeposit({
  payload: { order_id, deposit, open = false, history },
}) {
  try {
    const response = yield call(deposits.create, order_id, deposit);
    yield put(createDepositSuccess(response));
    if (open) {
      window.open(
        process.env.REACT_APP_API_URL + "/files/" + response.data.invoice.url
      );
      history.push(`/invoices`);
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        depositsApiError(
          "Vous n'êtes pas autorisé à ajouter une facture d'acompte"
        )
      );
    } else if (error.response.data !== undefined) {
      yield put(depositsApiError(error.response.data.message));
    } else {
      yield put(depositsApiError(error.message));
    }
  }
}

function* updateDeposit({ payload: { deposit_id, deposit, history } }) {
  try {
    const response = yield call(deposits.update, deposit_id, deposit);
    yield put(updateDepositSuccess(response));
    if (history) {
      history.push(`/invoices`);
    }
  } catch (error) {
    if (error.response?.status === 401) {
      yield put(
        depositsApiError(
          "Vous n'êtes pas autorisé à modifier une facture d'acompte"
        )
      );
    } else if (error.response?.data !== undefined) {
      yield put(depositsApiError(error.response.data.message));
    } else {
      yield put(depositsApiError(error.message));
    }
  }
}

export function* watchCreateDeposit() {
  yield takeEvery(CREATE_DEPOSIT, createDeposit);
}

export function* watchUpdateDeposit() {
  yield takeEvery(UPDATE_DEPOSIT, updateDeposit);
}

function* depositsSaga() {
  yield all([fork(watchCreateDeposit)]);
  yield all([fork(watchUpdateDeposit)]);
}

export default depositsSaga;
