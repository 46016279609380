import { History } from "history";
import React, { useEffect, useState } from "react";
import { match } from 'react-router';
import { Link } from "react-router-dom";
import {
  Card, CardBody, CardFooter, CardTitle, Col, Container, Modal, NavItem,
  NavLink, Row
} from "reactstrap";
import { offers as offersApi, offer_codes as offerCodesApi } from "../../api";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GlobalLoader from "../../components/Common/GlobalLoader";
import { parseQuery, setQuery } from "../../helpers/helper";
import Offer from "../../models/offer";
import OfferCard from "./Components/Cards/offer-card";
import OfferCodesCard from "./Components/Cards/offer-codes-card";
import FlashMessage, { addFlashMessage, ImportantEnum } from '../../components/Common/FlashMessage';
import { useCallback } from "react";

export interface OffersDetailProps {
  match: match<{ id: string }>,
  history: History
}

const OffersDetail = ({
  match,
  history
}: OffersDetailProps) => {
  const query = parseQuery();
  const offerId = parseInt(match.params.id);
  const [activeTab, setActiveTab] = useState<string>(query.tab as string || 'general');
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [offer, setOffer] = useState<Offer | null>(null);

  const getOffer = useCallback(() => {
    setLoading(true);
    offersApi.getOne(offerId).then(response => {
      setOffer(response.data);
      setLoading(false);
    }).catch(offers => {
      setLoading(false);
    });
  }, [offerId]);

  useEffect(() => getOffer(), [getOffer]);

  const generateCode = () => {
    offerCodesApi.upsert({offer_id: offerId}).then((response) => {
      addFlashMessage(ImportantEnum.SUCCESS, `Le code ${response.data.code} a été généré`);
      getOffer(); // Refresh
    }).catch(error => {
      addFlashMessage(ImportantEnum.DANGER, 'Le code n\'a pas pu être généré');
      getOffer(); // Refresh
    })
  }

  return (
    loading || !offer ? <GlobalLoader /> : <React.Fragment>
      <div className="page-content">
        <Container fluid className="collaborators-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Promotions"
            breadcrumbItem="Details d'une promotion"
          />
          <FlashMessage/>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="d-flex justify-content-between invoices-container">
                    <div>{offer.name}</div>
                    {activeTab === "offer_codes" ? (
                      <button
                        onClick={generateCode}
                        className="d-flex align-items-center text-theme-color-2 ml-3 bg-transparent !text-theme-color-2"
                      >
                        <i className="bx bx-plus-circle mr-1"></i> Ajouter
                      </button>
                    ) : null}
                  </CardTitle>
                  <ul className="nav nav-tabs nav-tabs-custom mb-3">
                    <NavItem>
                      <NavLink
                        className={activeTab === "general" ? "active" : ""}
                        onClick={() => {
                          setActiveTab("general");
                          setQuery({
                            ...query,
                            tab: 'general'
                          }, history)
                        }}
                      >
                        Général
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === "offer_codes" ? "active" : ""}
                        onClick={() => {
                          setActiveTab("offer_codes");
                          setQuery({
                            ...query,
                            tab: 'offer_codes'
                          }, history)
                        }}
                      >
                        Codes
                      </NavLink>
                    </NavItem>
                  </ul>
                  {activeTab === "general" ? (
                    <Row>
                      <Col xl="12" className="col-with-card-same-height">
                        <OfferCard offer={offer} />
                      </Col>
                    </Row>
                  ) : null}
                  {activeTab === "offer_codes" ? (
                    <Row>
                      <Col xl="12" className="col-with-card-same-height">
                        <OfferCodesCard history={history} offerId={offerId} />
                      </Col>
                    </Row>
                  ) : null}
                </CardBody>
                {activeTab === "general" ? (
                  <CardFooter>
                    <Row className="container-text-theme-color-2">
                      <Col xs="12" className="d-flex justify-content-end">
                        <Link
                          to={`/offer/edit/${offerId}`}
                          className="d-flex align-items-center mr-5"
                        >
                          <i className="bx bx-pencil mr-1"></i> Modifier
                        </Link>
                        <Link
                          to="#"
                          onClick={() => setDeleteModal(true)}
                          data-toggle="modal"
                          data-target="#projectDelete"
                          className="d-flex align-items-center mr-5"
                        >
                          <i className="bx bx-trash mr-1"></i> Supprimer
                        </Link>
                      </Col>
                    </Row>
                  </CardFooter>
                ) : null}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)}>
        <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">Promotion - Suppression</h5>
            <button type="button" onClick={() => setDeleteModal(false)} className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <h5>Attention</h5>
            <p>Voulez-vous supprimer la promotion définitivement ?</p>
        </div>
        <div className="modal-footer">
            <button type="button" onClick={() => setDeleteModal(false)} className="btn btn-secondary waves-effect" data-dismiss="modal">
                Annuler
            </button>
            <button type="button" onClick={() => {
              offersApi.delete(offerId)
                .then(() => history.replace('/offers'))
                .catch(error => {
                  addFlashMessage(ImportantEnum.DANGER, 'Impossible de supprimer la promotion');
                  setDeleteModal(false);
                  getOffer(); // Refresh
                })
            }} className="btn btn-green waves-effect waves-light">
                Supprimer
            </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default OffersDetail;
