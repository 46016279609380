import React, { useEffect, useState } from 'react';
import BootstrapTable, { ColumnDescription, SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { RouteComponentProps } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { offers as offersApi } from '../../../../api/index';
import GlobalLoader from "../../../../components/Common/GlobalLoader";
import { getDateWithShortFormat } from '../../../../helpers/helper';
import { onTableChange } from "../../../../helpers/table_helper";
import Offer, { OffersFilters, OfferTypeEnum } from '../../../../models/offer';
import Pagination from '../../../../models/types/pagination';

interface OffersTableProps {
  filters: OffersFilters,
  setFilters: React.Dispatch<React.SetStateAction<OffersFilters>>,
  generateCode: (offer: Offer) => void,
  history: RouteComponentProps["history"]
}

const OffersTable = ({
  filters,
  setFilters,
  generateCode,
  history
}: OffersTableProps) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);

  const getOffers = () => {
    setLoading(true);
    offersApi.getAll(filters).then(response => {
      setOffers(response.data.objects);
      setPagination(response.data.pagination);
      setLoading(false);
    }).catch((_) => {
      setLoading(false);
    });
  };

  useEffect(getOffers, [filters]);

  // Liste des colonnes qui seront affichées dans le tableau
  const columns: ColumnDescription<Offer>[] = [
    {
      dataField: "name",
      text: "Noms",
      sort: true
    },
    {
      dataField: "offer_codes_count",
      text: "Nombre de codes",
    },
    {
      dataField: "start_at",
      text: "Date",
      formatter: (cell, row) => getDateWithShortFormat(row.start_at),
      sort: true
    },
    {
      dataField: "percent",
      text: "Pourcentage",
      formatter: (cell, row) => row.offer_type === OfferTypeEnum.PERCENT ? `${(row.amount || 0).toFixed(2)} %` : '',
      sort: true
    },
    {
      dataField: "amount",
      text: "Montant",
      formatter: (cell, row) => row.offer_type === OfferTypeEnum.VALUE ? `${(row.amount || 0).toFixed(2)} €` : '',
      sort: true
    },
    {
      dataField: "prefix",
      text: "Préfix",
      sort: true
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <>
          <UncontrolledDropdown>
            <DropdownToggle className={"bg-transparent"}>
              <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
            </DropdownToggle>
            <DropdownMenu right className="bordered important-default-colors">
              <DropdownItem onClick={() => { history.push(`/offer/detail/${row.id}`) }}>Voir</DropdownItem>
              <DropdownItem onClick={() => { window.open(`/offer/detail/${row.id}`, '_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
              <DropdownItem onClick={() => { generateCode(row) }}>Créer un code promotionnel</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      ),
    },
  ];

  return (
    loading ? <GlobalLoader /> : <BootstrapTable
      keyField="id"
      data={offers}
      columns={columns}
      defaultSortDirection="asc"
      classes="table-centered"
      sort={{ dataField: filters.sort_by, order: (filters.sort_dir || 'asc') as SortOrder }}
      remote={{ pagination: true, sort: true }}
      onTableChange={onTableChange.bind({ setFilters, filters })}
      pagination={paginationFactory({
        page: pagination?.current_page,
        totalSize: pagination?.total_count,
        sizePerPage: pagination?.current_page_size,
        sizePerPageList: [
          { text: "Afficher 10 éléments", value: 10 },
          { text: "Afficher 25 éléments", value: 25 },
          { text: "Afficher 50 éléments", value: 50 },
          { text: "Afficher 75 éléments", value: 75 },
          { text: "Afficher 100 éléments", value: 100 },
        ],
      })}
    />
  );
};

export default OffersTable;
