export function onTableChange(type, newState) {
  if (type === "pagination") {
    this.setFilters({
      ...this.filters,
      page: newState.page || 1,
      page_size: newState.sizePerPage,
    });
  }

  if (type === "sort" && newState.sortOrder !== this.filters.sort_dir) {
    this.setFilters({
      ...this.filters,
      sort_by: newState.sortField,
      sort_dir: newState.sortOrder,
    });
  }
}