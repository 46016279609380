import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Alert,
  Modal,
} from "reactstrap";

// Helpers
import { getDateWithFormat } from "../../helpers/helper";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import ReactApexChart from "react-apexcharts";

// actions
import {
  getUser,
  deleteUser,
  userApiError,
  getStatistics,
} from "../../store/actions";

import { isRH } from "../../helpers/roles_helper";

const CollaboratorsDetail = (props) => {
  const [currentUser, setCurrentUser] = useState(false);
  const [isUserEditable, setIsUserEditable] = useState(true);
  const [modal_standard, setmodal_standard] = useState(false);

  // diagramme
  const [tab1, setTab1] = useState(Array(0));
  const [tab2, setTab2] = useState(Array(0));
  const [tab3, setTab3] = useState(Array(0));
  const [tab4, setTab4] = useState(Array(0));

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    props.getUser.call(undefined,parseInt(window.location.pathname.split("/")[3], 10));
  }, [props.getUser]);

  useEffect(() => {
    let currentUser = JSON.parse(localStorage.getItem("authUser"));
    setCurrentUser(currentUser);
  }, []);

  useEffect(() => {
    props.getStatistics.call(undefined,{ commercial: props.user.id });
  }, [props.user, props.getStatistics]);

  useEffect(() => {
    if (props.user) {
      setIsUserEditable(
        isRH() && props.user.role.name === "Dirigeant" ? false : true
      );
    }
  }, [props.user]);

  useEffect(() => {
    if (props.statistics) {
      let tab11 = [];
      let tab12 = [];
      let tab13 = [];
      let tab14 = [];

      props.statistics.months.forEach((value, index) => {
        tab11.push(parseFloat(value.ca_brut_ht_current).toFixed(2));
        tab12.push(parseFloat(value.ca_brut_ht_last).toFixed(2));
        tab13.push(parseFloat(value.ca_net_ht_current).toFixed(2));
        tab14.push(parseFloat(value.ca_net_ht_last).toFixed(2));
      });

      setTab1(tab11);
      setTab2(tab12);
      setTab3(tab13);
      setTab4(tab14);
    }
  }, [props.statistics]);

  const deleteThisUser = () => {
    props.deleteUser(
      parseInt(window.location.pathname.split("/")[3], 10),
      props.history
    );
  };

  const seriesN = [
    {
      type: "area",
      name: "CA BRUT N",
      data: tab1,
    },
    {
      type: "area",
      name: "CA NET N",
      data: tab3,
    },
  ];

  const optionsN = {
    chart: {
      toolbar: { show: !1 },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: { enabled: !1 },
    stroke: { curve: "smooth", width: 2, dashArray: [0, 0] },
    fill: {
      type: "solid",
      opacity: 0,
      colors: ["#b9df3f", "#9cc31c"],
    },
    xaxis: {
      categories: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
    },
    colors: ["#b9df3f", "#9cc31c"],
  };

  const seriesN1 = [
    {
      type: "area",
      name: "CA BRUT N-1",
      data: tab2,
    },
    {
      type: "area",
      name: "CA NET N-1",
      data: tab4,
    },
  ];

  const optionsN1 = {
    chart: {
      toolbar: { show: !1 },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: { enabled: !1 },
    stroke: { curve: "smooth", width: 2, dashArray: [0, 0] },
    fill: {
      type: "solid",
      opacity: 0,
      colors: ["#00b6f0", "#001da9"],
    },
    xaxis: {
      categories: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
    },
    colors: ["#00b6f0", "#001da9"],
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="collaborators-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Collaborateurs"
            breadcrumbItem="Détails collaborateur"
          />
          {!props.user ? (
            props.error ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="mb-5">
                      <Col className="d-flex align-items-start">
                        {props.user.img === undefined ? (
                          <div className="avatar-xs d-inline-block">
                            <span className="avatar-title rounded-circle">
                              {props.user.firstname !== null
                                ? props.user.firstname.charAt(0)
                                : "A"}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <img
                              className="rounded-circle avatar-xs"
                              src={props.user.img}
                              alt=""
                            />
                          </div>
                        )}
                        <div className="ml-3 d-inline-block">
                          <h5 className="font-size-14 mb-1">
                            <Link to="#" className="table-text-black">
                              {props.user.firstname + " " + props.user.lastname}
                            </Link>
                          </h5>
                          <p className="mb-0">{props.user.role.name}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-5">
                      <Col>
                        <Row className="mb-3">
                          <Col>
                            <p>
                              <b>Commentaires : </b>
                              {props.user.comment}
                            </p>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg="4">
                            <p>
                              <b>Email pro : </b>
                              {props.user.email}
                            </p>
                          </Col>
                          <Col lg="4">
                            <p>
                              <b>Téléphone : </b>
                              {props.user.phone}
                            </p>
                          </Col>
                          <Col lg="4">
                            <p>
                              <b>Agences : </b>
                              {props.user.agencies &&
                              props.user.agencies.length > 0
                                ? props.user.agencies.map((agency, idx) => (
                                    <span key={idx}>
                                      {idx > 0 ? ", " : null}
                                      {agency.name}
                                    </span>
                                  ))
                                : "Pas d'agence"}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <p>
                              <b>Date d'entrée : </b>
                              {getDateWithFormat(props.user.entry_date)}
                            </p>
                          </Col>
                          {!props.user.leaving_date ? '' :
                            <Col lg='4'>
                              <p>
                                <b>Date de sortie : </b>
                                {getDateWithFormat(props.user.leaving_date)}
                              </p>
                            </Col>}
                          <Col lg="4">
                            <p>
                              <b>Type contrat : </b>
                              {props.user.contract_type}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-5 container-text-theme-color-2">
                      <Col>
                        <Row className="mb-3">
                          <Col lg="4">
                            <p>
                              <b>Résidence : </b>
                              {props.user.address}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row className="container-text-theme-color-2">
                      <Col xs="12" className="d-flex justify-content-end">
                        {isUserEditable ? (
                          <Link
                            to={`/collaborator/edit/${parseInt(
                              window.location.pathname.split("/")[3],
                              10
                            )}`}
                            className="d-flex align-items-center mr-5"
                          >
                            <i className="bx bx-pencil mr-1"></i> Modifier
                          </Link>
                        ) : null}
                        {currentUser.id !==
                        parseInt(window.location.pathname.split("/")[3], 10) ? (
                          <Link
                            to="#"
                            onClick={() => {
                              tog_standard();
                            }}
                            data-toggle="modal"
                            data-target="#userDelete"
                            className="d-flex align-items-center mr-5"
                          >
                            <i className="bx bx-trash mr-1"></i> Supprimer
                          </Link>
                        ) : null}
                      </Col>
                    </Row>
                    <Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard();
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          Collaborateurs - Suppression
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_standard(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <h5>Attention</h5>
                        <p>
                          Voulez vous supprimer le collaborateur definitivement
                          ?
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => {
                            tog_standard();
                          }}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Annuler
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            deleteThisUser();
                          }}
                          className="btn btn-green waves-effect waves-light"
                        >
                          Supprimer
                        </button>
                      </div>
                    </Modal>
                  </CardFooter>
                </Card>
              </Col>
              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-3">Activité commerciale</h4>

                    <div>
                      <div
                        id="overview-chart"
                        className="apex-charts"
                        dir="ltr"
                      >
                        <ReactApexChart
                          series={seriesN}
                          options={optionsN}
                          type="line"
                          height={240}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-3">Activité commerciale</h4>

                    <div>
                      <div
                        id="overview-chart"
                        className="apex-charts"
                        dir="ltr"
                      >
                        <ReactApexChart
                          series={seriesN1}
                          options={optionsN1}
                          type="line"
                          height={240}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { user, error } = state.Users;
  const { statistics } = state.Statistics;
  return { user, error, statistics };
};

export default withRouter(
  connect(mapStatetoProps, {
    getUser,
    deleteUser,
    userApiError,
    getStatistics,
  })(CollaboratorsDetail)
);
