import {takeEvery, fork, put, all, call} from "redux-saga/effects";

// Login Redux States
import {
  GET_FORMULAS,
  GET_FORMULA,
  CREATE_FORMULA,
  UPDATE_FORMULA,
  DELETE_FORMULA,
} from "./actionTypes";

import {
  getFormulasSuccess,
  getFormulaSuccess,
  createFormulaSuccess,
  updateFormulaSuccess,
  deleteFormulaSuccess,
  formulaApiError,
} from './actions'

import {formulas} from "../../api";
import {addMessage} from "../flash_messages/actions";

const UNAUTHORIZED_ERROR = 401;
const NOT_FOUND_ERROR = 404;

const UNAUTHORIZED_MESSAGE = "Vous n'êtes pas autorisé à accéder aux informations des formules";
const NOT_FOUND_MESSAGE = "Données non trouvées";
const BASE_ERROR_MESSAGE = "Erreur lors de l'envoi d'informations";

function computeError(error) {
  if (error.response.status === UNAUTHORIZED_ERROR) {
    put(addMessage(UNAUTHORIZED_ERROR));
    return put(formulaApiError(UNAUTHORIZED_MESSAGE));
  } else if (error.response.status === NOT_FOUND_ERROR) {
    put(addMessage(NOT_FOUND_ERROR));
    return put(formulaApiError(NOT_FOUND_MESSAGE));
  } else if (error.response.data !== undefined) {
    put(addMessage(error.response.data.message || BASE_ERROR_MESSAGE));
    return put(formulaApiError(error.response.data.message || BASE_ERROR_MESSAGE));
  } else {
    put(addMessage(BASE_ERROR_MESSAGE));
    return put(formulaApiError(BASE_ERROR_MESSAGE));
  }
}

function* getFormulas({payload: {query}}) {
  try {
    const response = yield call(formulas.getAll, query);
    yield put(getFormulasSuccess(response));
  } catch (error) {
    yield computeError(error);
  }
}

function* getFormula({payload: {id}}) {
  try {
    const response = yield call(formulas.getOne, id);
    yield put(getFormulaSuccess(response));
  } catch (error) {
    yield computeError(error);
  }
}

function* createFormula({payload: {formula, history}}) {
  try {
    const response = yield call(formulas.create, formula);
    yield put(createFormulaSuccess(response));
    yield put(addMessage("Formule créée"));
    history.push('/formulas');
  } catch (error) {
    yield computeError(error);
  }
}

function* updateFormula({payload: {id, formula, history}}) {
  try {
    const response = yield call(formulas.update, id, formula);
    yield put(updateFormulaSuccess(response));
    yield put(addMessage("Formule modifiée"));
    history.push('/formulas');
  } catch (error) {
    yield computeError(error);
  }
}

function* deleteFormula({payload: {id, history}}) {
  try {
    const response = yield call(formulas.delete, id);
    yield put(addMessage("Formule supprimée"));
    yield put(deleteFormulaSuccess(response));
    history.push('/formulas');
  } catch (error) {
    debugger;
    yield computeError(error);
  }
}


export function* watchGetFormulas() {
  yield takeEvery(GET_FORMULAS, getFormulas);
}

export function* watchGetFormula() {
  yield takeEvery(GET_FORMULA, getFormula);
}

export function* watchCreateFormula() {
  yield takeEvery(CREATE_FORMULA, createFormula);
}

export function* watchUpdateFormula() {
  yield takeEvery(UPDATE_FORMULA, updateFormula);
}

export function* watchDeleteFormula() {
  yield takeEvery(DELETE_FORMULA, deleteFormula);
}

function* formulasSaga() {
  yield all([
    fork(watchGetFormulas),
    fork(watchGetFormula),
    fork(watchCreateFormula),
    fork(watchUpdateFormula),
    fork(watchDeleteFormula)
  ]);
}

export default formulasSaga;