import { Field, Form, Formik } from 'formik';
import React from "react";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import FormikAutocomplete from '../../../../components/Common/AsyncAutoComplete2';
import { promiseUsersOptions } from '../../../../helpers/autocomplete_promise_options';
import { computeStatusLabel, OfferCodesFilters, OfferCodeStatusEnum } from "../../../../models/offer_code";

interface OffersFilterProps {
  filters: OfferCodesFilters,
  setFilters: React.Dispatch<React.SetStateAction<OfferCodesFilters>>
}

const OffersFilter = ({
  filters,
  setFilters,
}: OffersFilterProps) => {

  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      onSubmit={(values) => {
        setFilters({
          ...filters,
          status: values.status,
          amount: values.amount,
          client_search: values.client_search,
          created_by: values.created_by,
          apply_by: values.apply_by
        })
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form>
          <Card>
            <CardBody>
              <Row>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="status">Validation</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocomplete
                        name="status"
                        id="status"
                        className="w-100 flex-unset"
                        noOptionsMessage={() => "Pas de choix disponible"}
                        placeholder="Validation"
                        loadOptions={Object.values(OfferCodeStatusEnum).map(e => { return { value: e, label: computeStatusLabel(e) } })}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="amount">Montant</Label>
                    </Col>
                    <Col xs="12">
                      <Input
                        id="amount"
                        name="amount"
                        type="text"
                        className="form-control"
                        tag={Field}
                        placeholder="Entrer un montant"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="client_search">Client</Label>
                    </Col>
                    <Col xs="12">
                      <Input
                        id="client_search"
                        name="client_search"
                        type="text"
                        className="form-control"
                        tag={Field}
                        placeholder="Entrer le nom d'un client"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="created_by">Généré par</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocomplete
                        name="created_by"
                        id="created_by"
                        className="w-100 flex-unset"
                        noOptionsMessage={() => "Pas de choix disponible"}
                        placeholder="Validation"
                        loadOptions={promiseUsersOptions}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="apply_by">Appliqué par</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocomplete
                        name="apply_by"
                        id="apply_by"
                        className="w-100 flex-unset"
                        noOptionsMessage={() => "Pas de choix disponible"}
                        placeholder="Validation"
                        loadOptions={promiseUsersOptions}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="text-center mt-3">
                  <Button
                    color="none"
                    className="btn-green"
                    onClick={() => handleSubmit(values as any)}
                  >
                    Appliquer les filtres
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default OffersFilter;
