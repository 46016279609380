export default interface RequestStatus {
  id?: number|null,
  name?: string|null,
  label?: string|null,
}


export function getStatusColor(status: RequestStatus) {
  switch (status.name) {
    case "new": return "#A4A4A4";
    case "assigned": return "#2A3042";
    case "in_progress": return "#699FDE";
    case "processed": return "#9CC31C";
  }
}