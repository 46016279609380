import React from 'react';

// Component
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FormulaForm from "./Form/FormulaForm";

// Context
import {withRouter} from "react-router-dom";

// Redux
import {connect} from "react-redux";
import {createFormula} from "../../store/formulas/actions";

const FormulasAdd = ({
  createFormula,
  history
                     }) => {

  const initValues = {
    formula_type: '',
    label: '',
    description: '',
    price_ttc_monthly: '',
    vat: '',
    categories: [],
    sub_categories: [],
  }

  function handleSubmitFormula(values) {
    createFormula({
      formule_type: values.formula_type,
      label: values.label,
      description: values.description,
      price_ttc_monthly: values.price_ttc_monthly,
      vat_id: values.vat,
      category_ids: values.categories,
      sub_category_ids: values.sub_categories
    }, history);
    console.log(values);
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Formules" breadcrumbItem="Formules" breadcrumbTitle='Abonnements'/>
        <Card>
          <CardBody>
            {/* Card title */}
            <CardTitle className="mb-3">
              <Row className="pr-2 pl-2 d-flex justify-content-between">
                <Col>Créer une formule</Col>
              </Row>
            </CardTitle>

            {/* FORM */}
            <FormulaForm
              initialValues={initValues}
              handleSubmitFormula={handleSubmitFormula}
              handleSubmitButtonText='Créer la formule'
            />

          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    formula,
    loading,
    error,
    pagination,
  } = state.Formulas

  return {
    formula,
    loading,
    error,
    pagination
  }
}

export default withRouter(
  connect(mapStateToProps, {
    createFormula,
  })(FormulasAdd)
);