import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {sendInvoiceEmails} from "../../../store/invoices/actions";
import {connect} from "react-redux";

const MailModal = ({
                     isOpen,
                     toggle,
                     toggleShowButton,
                     getQuery,
                     isListUsersHaveMail,
                     allInvoicesSelected,
                     allListUsersHaveMail,
                     invoicesIdList,
                     sendInvoiceEmails
                   }) => {

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggle();
      }}
    >
      <ModalHeader toggle={() => toggle()} tag="h4">
        Devis - envoyer par mail
      </ModalHeader>
      <ModalBody>
        {isListUsersHaveMail ? (
          <p>
            Les factures sélectionnées vont être envoyées par mail
            au(x) client(s).
          </p>
        ) : (
          <React.Fragment>
            <p>
              {
                allInvoicesSelected
                  ? <>
                    Certains clients sélectionnés ne possèdent pas d'adresse
                    mail. Si vous choisissez de continuer, il ne recevront
                    pas la facture.
                  </>
                  : <>
                    Certains clients sélectionnés ne possèdent pas d'adresse
                    mail. Si vous choisissez de continuer, il ne recevront
                    pas la facture :
                    {" "}
                    {allListUsersHaveMail.map((name, index) => (
                      <span>
                                    <b>{`${name}`}</b>
                        {`${
                          allListUsersHaveMail.length === index + 1
                            ? "."
                            : ", "
                        }
                                    `}
                                  </span>
                    ))}
                  </>
              }

            </p>
          </React.Fragment>
        )}
        <p>Voulez-vous continuer ?</p>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            toggle();
          }}
          className="btn btn-secondary waves-effect"
        >
          Annuler
        </Button>
        <Button
          onClick={() => {
            toggle();
            toggleShowButton(Array(0));
            if (allInvoicesSelected) {
              sendInvoiceEmails({
                ...getQuery(),
                pagination: false,
                page: null,
                page_size: null,
                ids: 'all'
              })
            } else {
              sendInvoiceEmails({
                ...getQuery(),
                ids: `${invoicesIdList.map((id, index) => id)}`
              })
            }
          }}
          className="btn btn-green waves-effect waves-light"
        >
          Envoyer
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default connect(null, {
  sendInvoiceEmails,
})(MailModal);