import {useState} from 'react';

import {Formik} from 'formik';
import {
  Col,
  Row,
  Input,
  FormGroup,
  Label, Button
} from "reactstrap";
import AsyncAutoComplete from "../../../components/Common/AsyncAutoComplete2";
import {
  promiseCategoriesOptions, promiseFormulasTypesOptions,
  promiseSubCategoriesOptions,
  promiseVatsOptionsIds
} from "../../../helpers/autocomplete_promise_options";
import FormulaTypeFormModal from "../Modal/FormulaTypeFormModal"

// Form validation
import {formulasFormValidation} from "../FormValidationSchema/formulasFormValidation";

import {ReactComponent as ImgAdd} from "../../../assets/images/icons/plus.svg";
import {ErrorMessageCustom} from "../../../components/Common/ErrorMessageCustom";
import {parseInputToFloat} from "../../../helpers/helper";

const FormulaForm = ({
                       initialValues,
                       handleSubmitFormula,
                       handleSubmitButtonText,
                     }) => {

  const [formulaTypeModal, setFormulaTypeModal] = useState(false);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitFormula}
        validationSchema={formulasFormValidation}
      >
        {({
            values,
            handleSubmit,
            handleChange,
            errors,
            touched,
            setFieldValue
          }) => (

          <form>
            <Row>
              <Col lg='3'>
                {/* Niveau de la formule */}
                <Row>
                  <FormGroup
                    className='mb-4 col-lg-12'
                  >
                    <div className='d-flex justify-content-between align-items-center'>
                      <Label for='formula_type' className='col-form-label'>Niveau de la formule</Label>
                      <Button
                        className='custom-map-buttons add-button-map'
                        onClick={() => setFormulaTypeModal(true)}
                      ><ImgAdd/></Button>
                    </div>
                    <AsyncAutoComplete
                      name="formula_type"
                      id="formula_type"
                      isSearchable={true}
                      noOptionsMessage={() => "Aucun niveau de formules disponible"}
                      placeholder="Sélectionner"
                      loadOptions={promiseFormulasTypesOptions}
                      invalid={errors.formula_type && !!touched.formula_type}
                     />
                    <ErrorMessageCustom
                      name='formula_type'
                    />
                  </FormGroup>
                </Row>

                {/* Prix TTC mensuel */}
                <Row>
                  <FormGroup
                    className='mb-4 col-lg-12'
                  >
                    <Label for='price_ttc_monthly' className='col-form-label'>Prix TTC mensuel</Label>
                    <Input
                      name='price_ttc_monthly'
                      id='price_ttc_monthly'
                      value={values.price_ttc_monthly}
                      placeholder='Entrer un prix TTC'
                      onChange={handleChange}
                      invalid={errors.price_ttc_monthly && !!touched.price_ttc_monthly}
                      onBlur={(event) => {
                        setFieldValue(event.target.name, parseInputToFloat(event.target.value))
                      }}
                    />
                    <ErrorMessageCustom
                      name='price_ttc_monthly'
                    />
                  </FormGroup>
                </Row>

                {/* Catégories */}
                <Row>
                  <FormGroup
                    className='mb-4 col-lg-12'
                  >
                    <Label for='categories' className='col-form-label'>Catégories</Label>
                    <AsyncAutoComplete
                      className="w-100"
                      name="categories"
                      id="categories"
                      isSearchable={true}
                      multiple={true}
                      noOptionsMessage={() => "Aucune catégories"}
                      placeholder="Sélectionner"
                      loadOptions={promiseCategoriesOptions}
                      invalid={errors.categories && !!touched.categories}
                    />
                    <ErrorMessageCustom
                      name='categories'
                    />
                  </FormGroup>
                </Row>

              </Col>

              <Col lg='3'>

                {/* Libellé de la formule */}
                <Row>
                  <FormGroup
                    className='mb-4 col-lg-12'
                  >
                    <Label for='label' className='col-form-label'>Libellé</Label>
                    <Input
                      name='label'
                      id='label'
                      value={values.label}
                      placeholder='Entrer un libellé'
                      onChange={handleChange}
                      invalid={errors.label && !!touched.label}
                    />
                    <ErrorMessageCustom
                      name='label'
                    />
                  </FormGroup>
                </Row>

                {/* TVA */}
                <Row>
                  <FormGroup
                    className='mb-4 col-lg-12'
                  >
                    <Label for='vat' className='col-form-label'>TVA *</Label>
                    <AsyncAutoComplete
                      className="w-100"
                      name="vat"
                      id="vat"
                      isSearchable={false}
                      noOptionsMessage={() => "Aucune TVA disponible"}
                      placeholder="Sélectionner"
                      loadOptions={promiseVatsOptionsIds}
                      invalid={errors.vat && !!touched.vat}
                    />
                    <ErrorMessageCustom
                      name='vat'
                    />
                  </FormGroup>
                </Row>

                {/* Sous-catégories */}
                <Row>
                  <FormGroup
                    className='mb-4 col-lg-12'
                  >
                    <Label for='sub_categories' className='col-form-label'>Sous-catégories</Label>
                    <AsyncAutoComplete
                      className="w-100"
                      name="sub_categories"
                      id="sub_categories"
                      isSearchable={true}
                      multiple={true}
                      noOptionsMessage={() => "Aucune sous-catégories"}
                      placeholder="Sélectionner"
                      loadOptions={promiseSubCategoriesOptions}
                      invalid={errors.sub_categories && !!touched.sub_categories}
                    />
                    <ErrorMessageCustom
                      name='sub_categories'
                    />
                  </FormGroup>
                </Row>
              </Col>

              <Col lg='3'>

                {/* Description de la formule */}
                <Row>
                  <FormGroup
                    className='mb-4 col-lg-12'
                  >
                    <Label for='description' className='col-form-label'>Description *</Label>
                    <Input
                      name='description'
                      id='description'
                      value={values.description}
                      type='textarea'
                      placeholder='Ajouter une description'
                      onChange={handleChange}
                      invalid={errors.description && !!touched.description}
                    />
                    <ErrorMessageCustom
                      name='description'
                    />
                  </FormGroup>
                </Row>

              </Col>
            </Row>
            <Row>
              <Col lg="12 text-center">
                <Button
                  type="submit"
                  color="primary"
                  className='btn-green'
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit(values);
                  }}
                >
                  {handleSubmitButtonText}
                </Button>
              </Col>
            </Row>
            <FormulaTypeFormModal
              isOpen={formulaTypeModal}
              toggleModal={() => setFormulaTypeModal(!formulaTypeModal)}
              setFormulaType={(typeId) => setFieldValue('formula_type', typeId)}
            />
          </form>
        )}
      </Formik>
    </div>
  );
}

export default FormulaForm;