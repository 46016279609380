import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import FileDownloader from "../../../components/Common/FileDownloader";
import { computePeriodicity, parseDate } from "../../../helpers/subscriptions_helpers";
import "../subscriptions.scss";

const SubscriptionInformationsComponent = ({
  subscription,
}) => {

  const isMandated = subscription.mandates.length > 0;

  return (
    <div>
      <Row>
        <Col className='subscriptions-detail'>
          <Row>
            <Col>
              <p><span>Client :</span> <Link to={`/customer/detail/${subscription.client_id}`}>{subscription.client_name}</Link></p>
            </Col>

            <Col>
              <p><span>Formule :</span> {subscription.formule_label}</p>
            </Col>

            <Col>
              <p><span>Jour de prélèvement :</span> {subscription.debit_day}</p>
            </Col>
          </Row>

          <Row>

            <Col>
              <p><span>Type de prélèvement :</span> {subscription.debit_type}</p>
            </Col>

            <Col>
              <p><span>Date de validation :</span> {subscription.validated_at ? parseDate(subscription.validated_at) : 'Non validé'}</p>
            </Col>

            <Col>
              <p><span>Date de première échéance :</span> {parseDate(subscription.start_at)}</p>
            </Col>

          </Row>

          <Row>
            <Col>
              <p><span>Date de signature :</span> {parseDate(subscription.signed_at) || 'Aucune date de signature'}</p>
            </Col>

            <Col>
              <p><span>Périodicité :</span> {computePeriodicity(subscription.periodicity)}</p>
            </Col>

            <Col>
              <p><span>Durée :</span> {subscription.duration_type}</p>
            </Col>

          </Row>

          <Row>
            <Col lg='4'>
              <p><span>Date de fin :</span> {parseDate(subscription.end_at)}</p>
            </Col>

            <Col lg='4'>
              <p><span>Date de résiliation :</span> {parseDate(subscription.suspended_at) || 'Pas de résiliation'}</p>
            </Col>

            <Col lg='4'>
              <p><span>RUM :</span> {isMandated && subscription.mandates[0].rum ? subscription.mandates[0].rum : 'Aucun RUM'}</p>
            </Col>
          </Row>

          <Row>
            <Col lg='4'>
              <p><span>IBAN :</span> {isMandated && subscription.mandates[0].iban ? subscription.mandates[0].iban : 'Aucun IBAN'}</p>
            </Col>

            <Col lg='4'>
              <p><span>Mandat de prélèvement PDF :</span>
                { !isMandated || !subscription.mandates[0].document_path ? ' Aucun mandat' :
                  <FileDownloader
                    doc={{
                      url: (`${process.env.REACT_APP_API_URL}/files/${subscription.mandates[0].document_path}`),
                      name: subscription.mandates[0].document_path.split('/')[1] || 'Mandat'
                    }}
                  />
                }
              </p>
            </Col>

            <Col lg='4'>
              <p><span>Notes : </span>
                {
                  subscription.notes ? subscription.notes : 'Aucune note'
                }
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default SubscriptionInformationsComponent;