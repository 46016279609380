import * as Yup from "yup";
import { PHONE_REGEX } from "../../../helpers/validator";

export const createValidationSchema = Yup.object().shape({
  email: Yup.string().required("Veuillez compléter ce champ"),

  firstname: Yup.mixed().notRequired(),

  lastname: Yup.mixed().notRequired(),

  password: Yup.string()
    .min(6, "Mot de passe trop court")
    .required("Veuillez compléter ce champ"),

  password_confirmation: Yup.string()
    .min(6, "Mot de passe trop court")
    .oneOf([Yup.ref("password")], "Les mots de passes ne correspondent pas")
    .required("Veuillez compléter ce champ"),

  role: Yup.string().required("Veuillez compléter ce champ"),

  entryDate: Yup.mixed().notRequired(),

  birthDate: Yup.mixed().notRequired(),

  // agencies: Yup.string().required("Veuillez compléter ce champ"),

  phone: Yup.string()
    .test("format", "Format invalide", (val) => {
      return !isNaN(val);
    })
    .trim()
    .matches(PHONE_REGEX, "Veuillez saisir un numéro de fixe valide")
    .notRequired(),

  address: Yup.mixed().notRequired(),

  contractType: Yup.mixed().notRequired(),

  comment: Yup.mixed().notRequired(),
});
