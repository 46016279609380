export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";

export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";

export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";

export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";

export const DELETE_ORDER = "DELETE_ORDER";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";

export const CREATE_INVOICE_FROM_ORDER = "CREATE_INVOICE_FROM_ORDER";
export const CREATE_INVOICE_FROM_ORDER_SUCCESS =
  "CREATE_INVOICE_FROM_ORDER_SUCCESS";

export const CREATE_REFUND_FROM_ORDER = "CREATE_REFUND_FROM_ORDER";
export const CREATE_REFUND_FROM_ORDER_SUCCESS =
  "CREATE_REFUND_FROM_ORDER_SUCCESS";

export const GET_GENERATE_INVOICE = "GET_GENERATE_INVOICE";
export const GET_GENERATE_INVOICE_SUCCESS = "GET_GENERATE_INVOICE_SUCCESS";

export const GET_GENERATE_REFUND = "GET_GENERATE_REFUND";
export const GET_GENERATE_REFUND_SUCCESS = "GET_GENERATE_REFUND_SUCCESS";

export const GET_GENERATE_QUOTE = "GET_GENERATE_QUOTE";
export const GET_GENERATE_QUOTE_SUCCESS = "GET_GENERATE_QUOTE_SUCCESS";

export const SHOW_INVOICE = "SHOW_INVOICE";
export const SHOW_INVOICE_SUCCESS = "SHOW_INVOICE_SUCCESS";

export const SHOW_REFUND = "SHOW_REFUND";
export const SHOW_REFUND_SUCCESS = "SHOW_REFUND_SUCCESS";

export const ORDERS_API_ERROR = "ORDERS_API_ERROR";
