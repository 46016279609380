import React, {useState} from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Table } from "reactstrap";

// Redux
import { connect } from "react-redux";

// actions
import { getProject } from "../../../store/actions";
import CustomerModal from "./Modals/CustomerModal";

const CustomerCard = (props) => {

  const [customerModal, setCustomerModal] = useState(false);
  
  return (
    <Card>
      <CardBody>
        <CardTitle className="d-flex mb-4 justify-content-between">
          <div>Client</div>
        </CardTitle>
        <div className="table-responsive">
          <Table className="table-nowrap mb-0">
            {props.project.client ? (
              <tbody>
              {props.project.client.client_type === "Professionnel" ? (
                <tr>
                  <th scope="row">Nom de la société :</th>
                  <td>
                    {props.project.client.company_name &&
                    props.project.client.company_name !== ""
                      ? props.project.client.company_name
                      : "Non renseigné"}
                  </td>
                </tr>
              ) : null}
              {props.project.client.client_type === "Professionnel" ? (
                <tr>
                  <th scope="row">SIRET :</th>
                  <td>
                    {props.project.client.siret &&
                    props.project.client.siret !== ""
                      ? props.project.client.siret
                      : "Non renseigné"}
                  </td>
                </tr>
              ) : null}
              <tr>
                <th scope="row">Civilité :</th>
                <td>{`${props.project.client.civility} ${props.project.client.lastname} ${props.project.client.firstname}`}</td>
              </tr>
              <tr>
                <th scope="row">Mobile :</th>
                <td>{props.project.client.mobile_phone}</td>
              </tr>
              {props.project.client.mobile_phone2 ? (
                <tr>
                  <th>Mobile 2 :</th>
                  <td>{`${props.project.client.mobile_phone2}`}</td>
                </tr>
              ) : null}
              {props.project.client.fix_phone ? (
                <tr>
                  <th>Fixe :</th>
                  <td>{`${props.project.client.fix_phone}`}</td>
                </tr>
              ) : null}
              <tr>
                <th scope="row">E-mail :</th>
                <td>{props.project.client.email}</td>
              </tr>
              <tr>
                <th scope="row">Adresse :</th>
                <td>{`${props.project.client.address} ${props.project.client.zip_code} ${props.project.client.city}`}</td>
              </tr>
              {props.project.client.address_construction_site !== null &&
              props.project.client.address_construction_site !== "" ? (
                <tr>
                  <th>Adresse de chantier :</th>
                  <td>{`${props.project.client.address_construction_site} ${props.project.client.zip_code_construction_site} ${props.project.client.city_construction_site}`}</td>
                </tr>
              ) : null}
              </tbody>
            ) : null}
          </Table>
        </div>
        {props.project.client ? (
          <div className="text-center mt-4 pt-2">
            <Link
              to='#'
              onClick={() => setCustomerModal(true)}
              className="btn btn-green btn-sm mr-2"
            >
              Modifier  
            </Link>
            <Link
              to={`/customer/detail/${props.project.client.id}`}
              className="btn btn-green btn-sm"
            >
              Voir plus
            </Link>
          </div>
        ) : null}
      </CardBody>
      <CustomerModal
        isOpen={customerModal}
        toggler={() => setCustomerModal(!customerModal)}
        customer={props.project.client}
        handleSuccess={() => props.getProject(props.project.id)}
      />
    </Card>
  );
}
const mapStatetoProps = (state) => {
  const { project } = state.Projects;

  return { project };
};

export default withRouter(
  connect(mapStatetoProps, { getProject })(CustomerCard)
);
