import * as Yup from "yup";

export const createValidationSchema = Yup.object().shape({
  commercial_name: Yup.string().notRequired(),

  brand: Yup.mixed().notRequired(),

  description: Yup.string().required("Veuillez compléter ce champ"),

  category: Yup.string().required("Veuillez compléter ce champ"),

  sub_category: Yup.string().notRequired(),

  vat: Yup.string().required("Veuillez compléter ce champ"),

  price: Yup.number()
    .typeError("Format invalide")
    .required("Veuillez compléter ce champ"),

  price_ttc: Yup.number()
    .typeError("Format invalide")
    .required("Veuillez compléter ce champ"),

  promotional_offer: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .notRequired(),

  promotional_offer_ttc: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .notRequired(),

  eco_tax: Yup.number().typeError("Format invalide").notRequired(),

  image: Yup.string().required("Veuillez compléter ce champ"),
});
