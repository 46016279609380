import React from "react";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

const InvoicesForm = (props) => {
  return (
    <Formik
      initialValues={props.initValues}
      validationSchema={props.validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        props.handleSubmit({
          ...values,
          payment_mode: values.payment_mode.value
        });
      }}
      render={({ values, errors, touched, setFieldValue }) => (
        <Form>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <FormGroup className="mb-4" row>
                <Label htmlFor="invoice_date" className="col-form-label col">
                  Date de la facture *
                </Label>
                <Col xs="12">
                  <Input
                    name='invoice_date'
                    id='invoice_date'
                    type='date'
                    invalid={errors.invoice_date && !!touched.invoice_date}
                    onChange={(evt) => {
                      setFieldValue("invoice_date", evt.target.value);
                    }}
                    defaultValue={values.invoice_date}
                  />
                </Col>
              </FormGroup>
              {props.isDeposit ? (
                <React.Fragment>
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="percent" className="col-form-label col">
                      Pourcentage du prix TTC *
                    </Label>
                    <Col xs="12">
                      <Input
                        id="percent"
                        name="percent"
                        type="text"
                        tag={Field}
                        invalid={errors.percent && !!touched.percent}
                        className="form-control"
                        placeholder="Entrer un pourcentage du prix TTC"
                        onChange={(event) => {
                          setFieldValue("percent", event.target.value);
                          setFieldValue(
                            "price",
                            (
                              (props.total_price_ttc * event.target.value) /
                              100
                            ).toFixed(2)
                          );
                        }}
                      />
                      <ErrorMessage name="percent" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="price" className="col-form-label col">
                      Prix TTC *
                    </Label>
                    <Col xs="12">
                      <Input
                        id="price"
                        name="price"
                        type="text"
                        tag={Field}
                        invalid={errors.price && !!touched.price}
                        className="form-control"
                        placeholder="Entrer un prix TTC"
                        onChange={(event) => {
                          setFieldValue("price", event.target.value);
                          setFieldValue(
                            "percent",
                            (event.target.value * 100) /
                              props.total_price_ttc
                          );
                        }}
                      />
                      <ErrorMessage name="price" component={FormFeedback} />
                    </Col>
                  </FormGroup>
                </React.Fragment>
              ) : null}
              <FormGroup className="mb-4" row>
                <Label htmlFor="payment_mode" className="col-form-label col">
                  Mode de paiement *
                </Label>
                <Col xs="12">
                  <Select
                    value={values.payment_mode}
                    className={`w-100 ${
                      errors.payment_mode ? "selectorError" : ""
                    }`}
                    noOptionsMessage={() =>
                      "Pas de mode de paiement disponible"
                    }
                    placeholder="Choisir un mode de paiement"
                    options={[
                      {
                        value: "Chèque",
                        label: "Chèque",
                      },
                      {
                        value: "Virement",
                        label: "Virement",
                      },
                      {
                        value: "Prélèvement",
                        label: "Prélèvement",
                      },
                    ]}
                    onChange={(payment_mode) => {
                      setFieldValue("payment_mode", payment_mode);
                    }}
                  />
                  <ErrorMessage
                    className={errors.payment_mode ? "errorMessages" : null}
                    name="payment_mode"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
            </div>
          </div>
          <Row className="justify-content-end mt-4">
            <Col xs="12" className="text-center">
              <Button type="submit" color="primary">
                {`Générer la facture ${
                  props.isDeposit ? "d'acompte" : "de solde"
                }`}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default InvoicesForm;
