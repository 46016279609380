import React from 'react';
import {Link} from "react-router-dom";

const FileDownloader = ({
  doc
                        }) => {

  return (
    <table>
      <tbody>
      <tr>
        <td style={{
            width: '3vw',
            height: '6vh',
            display: 'flex',
        }}>
          <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
            <i className="bx bxs-file-doc"/>
          </span>
        </td>
        <td style={{textAlign: 'center'}}>
          <h5 className="font-size-14 mb-1">
            <Link
              to="#"
              className="text-dark"
            >
              {doc.name}
            </Link>
          </h5>
        </td>
        <td>
          <div className="text-center">
            <a
              href={doc.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-dark"
            >
              <i className="bx bx-download h3 m-0"/>
            </a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  );
}

export default FileDownloader;