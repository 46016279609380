import DefaultFilter from "./types/default_filter";

export interface OfferCodesFilters extends DefaultFilter {
  offer_id?: number,
  search?: string | null,
  status?: string | null,
  amount?: number | null,
  client_search?: string | null,
  created_by?: number | null,
  apply_by?: number | null,
}

export enum OfferCodeStatusEnum {
  USED = "used",
  REFUSE = "refuse",
  VALIDATE = "validate",
  DUPLICATED = "duplicated",
  CANCELED = "canceled",
  EXPIRED = "expired",
}

export function computeStatusLabel(status: OfferCodeStatusEnum) {
  switch (status) {
    case OfferCodeStatusEnum.USED:
      return 'Utilisé';
    case OfferCodeStatusEnum.REFUSE:
      return 'Refusé';
    case OfferCodeStatusEnum.VALIDATE:
      return 'Validé';
    case OfferCodeStatusEnum.DUPLICATED:
      return 'Dupliqué';
    case OfferCodeStatusEnum.CANCELED:
      return 'Annulé';
    case OfferCodeStatusEnum.EXPIRED:
      return 'Expiré';
  }
}

export function computeStatusColor(status: OfferCodeStatusEnum) {
  switch (status) {
    case OfferCodeStatusEnum.USED:
      return 'success';
    case OfferCodeStatusEnum.VALIDATE:
      return 'warning';
    case OfferCodeStatusEnum.DUPLICATED:
      return 'danger';
    case OfferCodeStatusEnum.REFUSE:
      return 'danger';
    case OfferCodeStatusEnum.CANCELED:
      return 'danger';
    case OfferCodeStatusEnum.EXPIRED:
      return 'danger';
  }
}

export default interface OfferCode {
  id?: number | null,
  status?: OfferCodeStatusEnum | null,
  code?: number | null,
  amount?: number | null,
  offer_id?: number | null,
  offer_name?: string | null,
  apply_by_name?: string | null,
  client_name?: string | null,
  created_by_name?: string | null,
  quote_number?: string | null
}