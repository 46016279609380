import {History} from "history";
import React, {useEffect, useRef, useState} from "react";
import {Card, CardBody, CardTitle, Col, Collapse, Container, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {parseQuery, setQuery} from "../../helpers/helper";
import InterventionFilter from './Components/Filters/intervention-filter';
import InterventionFoldersTab from './Components/Filters/interventions-tab';
import InterventionTable from './Components/Tables/intervention-table';
import FlashMessage, {addFlashMessage, ImportantEnum} from '../../components/Common/FlashMessage';
import SearchFilterWithButton from "../../components/Common/SearchFilterWithButton";
import {V2} from "../../api";
import {InterventionFolderFilter} from "../../models/intervention_folder";
import InterventionFolderEditModal from "./Components/Modals/editAction";

const InterventionList = ({
  history,
  InterventionsType
}: {
  history: History,
  InterventionsType: string
}) => {
  const [filtersCollapseIsOpen, setFiltersCollapseIsOpen] = useState(false);
  const translatedType = useRef<{sing:string,plur:string}>(
    InterventionsType === "sav" ? {sing:"SAV",plur:"SAV"} : {sing:"Entretien",plur:"Entretiens"}
  );
  // Filters

  const query = parseQuery();

  const [filters, setFilters] = useState<InterventionFolderFilter>({
    sort_by: query.sort_by as any || '',
    sort_dir: query.sort_dir as any || '',
    page: (query.page || 1) as number,
    page_size: (query.page_size || 25) as number,
    query: (query.query || null) as string | null,
    status: ((query.status as string[])?.map(i=>parseInt(i)) || null) as number[] | null,
    zip_code_query: (query.zip_code_query || null) as string | null,
    installation_type_query: (query.installation_type_query || null) as string | null,
    date_begin: (query.date_begin || null) as Date | null,
    date_end: (query.date_end || null) as Date | null,
    type: InterventionsType,
    is_processed: JSON.parse(query.is_processed as any || 'false'),
  } as InterventionFolderFilter);

  const [editActionModal, setEditActionModal] = useState({
    intervention_folder_id: null, action: "", values: ""
  } as {intervention_folder_id:number|null,action:string, values: any});

  useEffect(() => {
    setQuery(filters, history); // Update query in path
  }, [filters, history]);

  function resetFilters() {
    setFilters({
      sort_by: '',
      sort_dir: '',
      page: 1,
      page_size: 25,
      query: null,
      status: [],
      zip_code_query: '',
      installation_type_query: '',
      date_begin: null,
      date_end: null,
      type: InterventionsType,
      is_processed: false,
    } as InterventionFolderFilter);
  }

  function editAction(values:any){
    if(editActionModal.intervention_folder_id){
      let promise:Promise<any>|null = null;
      switch (editActionModal.action) {
        case "status":
          promise = V2.interventions.patch(editActionModal.intervention_folder_id, {status_id: values.value},{});
          break;
        case "priority":
          promise = V2.interventions.patch(editActionModal.intervention_folder_id, {priority_id: values.value},{});
          break;
        case "technicians":
          promise = V2.interventions.patch(editActionModal.intervention_folder_id, {technician_ids: values.value},{});
          break;
      }
      if(promise){
        promise.then(()=>{
          setEditActionModal({intervention_folder_id: null, action: "", values: ""})
          addFlashMessage(ImportantEnum.SUCCESS, "L'intervention à correctement été modifiée");
          setFilters({...filters});
        });
        return promise;
      } else {
        return Promise.reject("Action not supported");
      }
    } else {
      return Promise.reject("No intervention_folder_id");
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="notifications-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={translatedType.current.plur}
            breadcrumbTitle="Suivi des interventions"
            breadcrumbItem={translatedType.current.plur}
          />
          <FlashMessage/>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-0">
                    <Row className="pr-2 pl-2 d-flex">
                      <Col>Tous les {translatedType.current.plur.toLowerCase()}</Col>
                    </Row>
                  </CardTitle>
                  <Row className="mt-3">
                    <Col lg="12" className="d-flex justify-content-start">
                      <span
                        className="d-flex text-link mb-3"
                        onClick={() => {
                          setFiltersCollapseIsOpen(!filtersCollapseIsOpen);
                        }}
                      >
                        <i className="fas fa-filter mr-1"/>Filtrer
                      </span>
                      <span
                        className="d-flex text-link text-color-unset mb-3"
                        onClick={resetFilters}
                      >
                        <i className="fas fa-times mr-1"/> Vider les filtres
                      </span>
                    </Col>
                    <Col xs="12">
                      <Collapse isOpen={filtersCollapseIsOpen}>
                        <InterventionFilter
                          filters={filters}
                          setFilters={setFilters}
                        />
                      </Collapse>
                    </Col>
                    <Col xs={"12"}>
                      <SearchFilterWithButton
                        onSearch={(q)=>{setFilters({...filters, query:q} as InterventionFolderFilter)}}
                        placeholder={`Rechercher un ${translatedType.current.sing.toLowerCase()}`}
                        defaultValue={""}
                        containerClasses={"mt-2"}
                        inputClasses={"w-4xl"}
                      />
                    </Col>
                    <Col xs={"12"} className="mt-3">
                      <InterventionFoldersTab
                        filters={filters}
                        setFilters={setFilters}
                      />
                    </Col>
                  </Row>
                  <InterventionTable filters={filters} setFilters={setFilters} onEditAction={(editAction) => setEditActionModal(editAction)} history={history} InterventionsType={InterventionsType}/>
                  {editActionModal.intervention_folder_id && editActionModal.action !== "" ?
                    <InterventionFolderEditModal editAction={editActionModal} onAbort={()=>{setEditActionModal({intervention_folder_id:null, action:"", values: ""})}} onValidate={editAction} values={editActionModal.values}/>
                    :null
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InterventionList;
