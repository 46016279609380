import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle, Alert } from "reactstrap";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import CardAgency
import CardAgency from "./CardAgency";

// actions
import { getAgencies, agencyApiError } from "../../store/actions";

// Images fond
import fond_1 from "../../assets/images/collaborators/fleur.png";

const AgenciesList = (props) => {
  const [flash_message, setFlash_message] = useState(false);
  useEffect(() => {
    props.getAgencies.call();
  }, [props.getAgencies]);

  function removeFlashMessage(){
    window.history.pushState({}, document.title, "/agencies");
    setFlash_message(!flash_message);
  }

  const urlParams = new URLSearchParams(window.location.search);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="collaborators-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Agences" breadcrumbItem="Toutes les agences" />
          <Row>
            <Col lg="12">
                <Alert color="success" isOpen={urlParams.get("success_add") === "1"} toggle={() => removeFlashMessage()}>
                    L'agence à bien été créé.
                </Alert>
                <Alert color="success" isOpen={urlParams.get("success_update") === "1"} toggle={() => removeFlashMessage()}>
                    L'agence à bien été mise à jour.
                </Alert>
                <Alert color="success" isOpen={urlParams.get("success_delete") === "1"} toggle={() => removeFlashMessage()}>
                    L'agence à bien été suprimé.
                </Alert>
            </Col>
          </Row>
          {props.loading ? null : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">
                      <Row className="pr-2 pl-2 d-flex justify-content-between">
                        <Col>Toutes les agences</Col>
                        <Col className="d-flex justify-content-end">
                          <Link
                            to="/agency/add"
                            className="d-flex align-items-center text-theme-color-2"
                          >
                            <i className="bx bx-plus-circle mr-1"></i> Ajouter
                          </Link>
                        </Col>
                      </Row>
                    </CardTitle>
                  </CardBody>
                </Card>
                <Row>
                  {props.agencies.map((agency, index) => (
                    <CardAgency agency={agency} key={index} />
                  ))}
                </Row>
                <div className="collaborators-footer-img">
                  <div className="collaborators-footer-img-main-container">
                    <div className="collaborators-footer-img-container">
                      <img src={fond_1} alt="Fond numéro 1" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { agencies, pagination, loading } = state.Agencies;
  return { agencies, pagination, loading };
};

export default withRouter(
  connect(mapStatetoProps, { getAgencies, agencyApiError })(AgenciesList)
);
