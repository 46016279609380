import React from 'react';
import DatePicker from 'react-datepicker';
import fr from "date-fns/locale/fr"; // the locale you want

export interface DatePickerFieldProps {
  name: string,
  value: string | null | undefined,
  onChange: (name: string, value: string) => void,
  className: string,
  isClearable?: boolean,
  invalid?: boolean
}

const DatePickerField = ({ name, value, onChange, className, isClearable = false, invalid }: DatePickerFieldProps) => {
  return (
    <DatePicker
      className={`${className} ${
        invalid ? "form-control button-without-style datepicker-error" : ""
      }`}
      selected={(value && new Date(value)) || null}
      onChange={val => {
        onChange(name, val);
      }}
      dateFormat="dd/MM/yyyy"
      locale={fr}
      isClearable={isClearable}
    />
  );
};

export default DatePickerField;
