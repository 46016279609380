export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";

export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";

export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";

export const DELETE_PROJECT = "DELETE_PROJECTS";
export const DELETE_TECHNICAL_VISIT_PROJECT = "DELETE_TECHNICAL_VISIT_PROJECT";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECTS_SUCCESS";

export const PROJECTS_API_ERROR = "PROJECTS_API_ERROR";

export const EXPORT_PROJECTS = "EXPORT_PROJECTS";
export const EXPORT_PROJECTS_SUCCESS = "EXPORT_PROJECTS_SUCCESS";
