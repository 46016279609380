import {
  GET_FREE_ITEMS_SAVE,
  GET_FREE_ITEMS_SAVE_SUCCESS,
  CREATE_FREE_ITEM_SAVE,
  CREATE_FREE_ITEM_SAVE_SUCCESS,
  DELETE_FREE_FIELD,
  DELETE_FREE_FIELD_SUCCESS,
  FREE_FIELDS_API_ERROR,
} from "./actionTypes";

export const getFreeItemsSave = (query) => {
  return {
    type: GET_FREE_ITEMS_SAVE,
    payload: { query },
  };
};

export const getFreeItemsSaveSuccess = (orders) => {
  return {
    type: GET_FREE_ITEMS_SAVE_SUCCESS,
    payload: orders,
  };
};

export const createFreeItemSave = (freeItem) => {
  return {
    type: CREATE_FREE_ITEM_SAVE,
    payload: { freeItem },
  };
};

export const createFreeItemSaveSuccess = (freeItem) => {
  return {
    type: CREATE_FREE_ITEM_SAVE_SUCCESS,
    payload: freeItem,
  };
};

export const deleteFreeField = (id, history) => {
  return {
    type: DELETE_FREE_FIELD,
    payload: { id, history },
  };
};

export const deleteFreeFieldSuccess = (free_field) => {
  return {
    type: DELETE_FREE_FIELD_SUCCESS,
    payload: free_field,
  };
};

export const freeFieldsApiError = (error) => {
  return {
    type: FREE_FIELDS_API_ERROR,
    payload: error,
  };
};
