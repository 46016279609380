import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import AsyncSelect from "react-select/async";

//Import Date Picker
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

import { customers } from "../../../api/index";
import { promiseCustomersOptions, promiseUsersOptions, promiseProjectsOptions } from "../../../helpers/autocomplete_promise_options";
import FormikAutocomplete from "../../../components/Common/AsyncAutoComplete2";

const EventForm = (props:any) => {
  const [addresses_option, setAddresses_option] = useState(Array(0));
  const [project_options, setProject_options] = useState(Array(0));

  useEffect(function () {
    if (props?.initValues?.customer?.value) {
      updateProjectOptionsAndAddressOptionsFromClientID(props.initValues.customer.value);
    }
  }, [props.initValues.customer.value]);

  function updateProjectOptionsAndAddressOptionsFromClientID(client_id:any, onSuccess?: (options:{value: boolean,label: string}[], filtered:any)=>void) {
    // Use Promise.all to avoid multiple setState
    Promise.all([
      customers.getOne(client_id),
      promiseProjectsOptions(client_id)
    ]).then((promisesResults) => {
      let filtered_customer = promisesResults[0].data; // 1 -> result of customers.getOne
      let filtered_projects_options = promisesResults[1]; // 1 -> result of promiseProjectsOptions
      setProject_options(filtered_projects_options);
      let options = [];
      if (
        filtered_customer?.address_construction_site &&
        filtered_customer?.zip_code_construction_site &&
        filtered_customer?.city_construction_site
      ) {
        options.push({
          value: true,
          label: `Adresse du chantier - ${filtered_customer.address_construction_site}, ${filtered_customer.zip_code_construction_site} ${filtered_customer.city_construction_site}`,
        });
      }
      if (
        filtered_customer?.address &&
        filtered_customer?.zip_code &&
        filtered_customer?.city
      ) {
        options.push({
          value: false,
          label: `Adresse principale - ${filtered_customer.address}, ${filtered_customer.zip_code} ${filtered_customer.city}`,
        });
      }
      setAddresses_option(options);
      if (onSuccess) onSuccess(options, filtered_projects_options);
    });
  }

  return (
    <Formik
      initialValues={props.initValues}
      validationSchema={props.validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        props.handleSubmit("", values);
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <Row>
                <Col xs="6">
                  <FormGroup className="mb-2" row>
                    <Label htmlFor="date_begin" className="col-form-label col">
                      Date et heure de début {props.isUpdate ? null : "*"}
                    </Label>
                    <Col xs="12">
                      <DatePicker
                        showTimeSelect
                        className="form-control button-without-style"
                        selected={new Date(values.date_begin)}
                        onChange={(date) => {
                          let date_end = new Date(date as unknown as string);
                          setFieldValue("date_begin", date);
                          if (date && values.date_end <= date) {
                            date_end.setHours(date.getHours() + 1);
                            setFieldValue("date_end", date_end);
                          }
                        }}
                        timeCaption="Heure"
                        dateFormat="dd/MM/yyyy HH:mm"
                        locale={fr}
                        placeholderText="Choisir la date et l'heure de début"
                        popperClassName="react-datepicker-popper-custom"
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup className="mb-2" row>
                    <Label htmlFor="date_end" className="col-form-label col">
                      Date et heure de fin {props.isUpdate ? null : "*"}
                    </Label>
                    <Col xs="12">
                      <DatePicker
                        showTimeSelect
                        className="form-control button-without-style"
                        selected={new Date(values.date_end)}
                        onChange={(date) => {
                          if (date && values.date_begin < date) {
                            setFieldValue("date_end", date);
                          }
                        }}
                        timeCaption="Heure"
                        dateFormat="dd/MM/yyyy HH:mm"
                        locale={fr}
                        placeholderText="Choisir la date et l'heure de fin"
                        popperClassName="react-datepicker-popper-custom"
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="mb-2" row>
                <Label htmlFor="reason" className="col-form-label col">
                  Intervention {props.isUpdate ? null : "*"}
                </Label>
                <Col xs="12">
                  <Select
                    className={`w-100 ${errors.reason ? "selectorError" : ""}`}
                    noOptionsMessage={() => "Pas de motif disponible"}
                    defaultValue={
                      values.reason && values.reason !== ""
                        ? {
                            value: values.reason,
                            label: values.reason,
                          }
                        : null
                    }
                    placeholder="Choisir un motif..."
                    options={[
                      {
                        value: "Installation",
                        label: "Installation",
                      },
                      {
                        value: "Entretien",
                        label: "Entretien",
                      },
                      {
                        value: "Dépannage",
                        label: "Dépannage",
                      },
                      {
                        value: "Visite technique",
                        label: "Visite technique",
                      },
                      {
                        value: "Audit",
                        label: "Audit",
                      },
                    ]}
                    onChange={(reason) => {
                      setFieldValue("reason", reason?.value);
                    }}
                  />
                  <ErrorMessage
                    className={errors.reason ? "errorMessages" : undefined}
                    name="reason"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-2" row>
                <Label htmlFor="customer" className="col-form-label col">
                  Client {props.isUpdate ? null : "*"}
                </Label>
                <Col xs="12">
                  <FormikAutocomplete
                    name="customer"
                    id="customer"
                    invalid={!!(errors.customer && !!touched.customer)}
                    className={`w-100 selector-with-addon ${
                      errors.customer ? "selectorError" : ""
                    }`}
                    loadOptions={promiseCustomersOptions}
                    onChangeValue={(_,value) => {
                      setFieldValue("customer", value);
                      updateProjectOptionsAndAddressOptionsFromClientID(value, (address_options, projects_options) => {
                        setFieldValue("project", null);
                        if (address_options.length === 1) {
                          setFieldValue("address", {
                            value: false,
                            label: address_options[0].label,
                          });
                        } else {
                          setFieldValue("address", "");
                        }
                      });
                    }}
                  />
                  <ErrorMessage
                    className={errors.customer ? "errorMessages" : undefined}
                    name="customer"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-2" row>
                <Label htmlFor="project" className="col-form-label col">
                  Projet
                </Label>
                <Col xs="12">
                  <Select
                    className={`w-100 ${errors.project ? "selectorError" : ""}`}
                    isSearchable={true}
                    noOptionsMessage={() => "Pas de projet disponible"}
                    value={
                      values.project && values.project !== ""
                        ? values.project
                        : null
                    }
                    placeholder="Choisir un projet..."
                    options={project_options}
                    onChange={(project) => {
                      setFieldValue("project", {
                        value: project.value,
                        label: project.label,
                      });
                    }}
                  />
                  <ErrorMessage
                    className={errors.project ? "errorMessages" : undefined}
                    name="project"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-2" row>
                <Label htmlFor="address" className="col-form-label col">
                  Lieu {props.isUpdate ? null : "*"}
                </Label>
                <Col xs="12">
                  <Select
                    className={`w-100 ${errors.address ? "selectorError" : ""}`}
                    noOptionsMessage={() =>
                      "Pas de lieu disponible, choisissez le client d'abord"
                    }
                    defaultValue={
                      values.address && values.address !== ""
                        ? {
                            value: values.address.value,
                            label: values.address.label,
                          }
                        : null
                    }
                    value={values.address}
                    placeholder="Choisir un lieu..."
                    options={addresses_option}
                    onChange={(address) => {
                      setFieldValue("address", {
                        value: address.value,
                        label: address.label,
                      });
                    }}
                  />
                  <ErrorMessage
                    className={errors.address ? "errorMessages" : undefined}
                    name="address"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-2" row>
                <Label htmlFor="technicians" className="col-form-label col">
                  Technicien
                </Label>
                <Col xs="12">
                  <AsyncSelect
                    className={`w-100 ${
                      errors.technicians ? "selectorError" : ""
                    }`}
                    isSearchable={true}
                    isMulti={true}
                    cacheOptions
                    defaultOptions
                    value={values.technicians}
                    noOptionsMessage={() => "Pas de technicien disponible"}
                    placeholder="Choisir un technicien"
                    loadOptions={() => promiseUsersOptions("Technicien")}
                    onChange={(technicians) => {
                      let temp: any[] = [];
                      if (technicians !== null) {
                        technicians.forEach((technician, index) => {
                          temp.push({
                            value: technician.value,
                            label: technician.label,
                          });
                        });
                      }
                      setFieldValue("technicians", temp);
                    }}
                  />
                  <ErrorMessage
                    className={errors.technicians ? "errorMessages" : undefined}
                    name="technicians"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-2" row>
                <Label htmlFor="comment" className="col-form-label col">
                  Commentaire
                </Label>
                <Col xs="12">
                  <Input
                    id="comment"
                    name="comment"
                    type="text"
                    tag={Field}
                    invalid={!!(errors.comment && !!touched.comment)}
                    className="form-control"
                    placeholder="Entrer un commentaire"
                  />
                  <ErrorMessage name="comment" component={FormFeedback} />
                </Col>
              </FormGroup>
            </div>
          </div>
          <Row className="justify-content-end mt-4">
            <Col
              xs={`12 ${
                props.isUpdate
                  ? "d-flex justify-content-between"
                  : "text-center"
              }`}
            >
              <Button type="submit" color="primary">
                {props.isUpdate ? "Sauvegarder les modifications" : "Ajouter"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default EventForm;
