import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_PROJECTS,
  GET_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  DELETE_TECHNICAL_VISIT_PROJECT,
  EXPORT_PROJECTS,
} from "./actionTypes";

import {
  getProjectsSuccess,
  getProjectSuccess,
  updateProjectSuccess,
  deleteProjectSuccess,
  exportProjectsSuccess,
  projectsApiError,
} from "./actions";

import { projects, V2 } from "../../api";

function* getProjects({ payload: { query } }) {
  try {
    if (query.display_type === "deposit") {
      delete query.display_type;
      const response = yield call(V2.projects.getDepositDetails, query);
      yield put(getProjectsSuccess(response));
    } else {
      const response = yield call(projects.getAll, query);
      yield put(getProjectsSuccess(response));
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          projectsApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des projets"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(projectsApiError(error.response.data.message));
      }
    } else {
      yield put(projectsApiError(error.message));
    }
  }
}

function* getProject({ payload: { id } }) {
  try {
    const response = yield call(projects.getOne, id);
    yield put(getProjectSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        projectsApiError(
          "Vous n'êtes pas autorisé à accéder aux informations de ce projet."
        )
      );
    } else if (error.response.status === 404) {
      yield put(projectsApiError("Le projet n'a pas été trouvé"));
    } else if (error.response.status !== undefined) {
      yield put(projectsApiError(error.response.data.message));
    } else {
      yield put(projectsApiError(error.message));
    }
  }
}

function* updateProject({ payload: { id, project } }) {
  try {
    const response = yield call(projects.update, id, project);
    yield put(updateProjectSuccess(response));
    
    if (project.maintenance_order_attributes) { // UPDATE PDF
      axios.post(`/orders/${response.data.maintenance_order.id}/generate_quote`, {
        force_generate_pdf: true,
      })
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        projectsApiError("vous n'êtes pas autorisé à modifier le projet")
      );
    } else if (error.response.status === 404) {
      yield put(
        projectsApiError("vous n'êtes pas autorisé à modifier le projet")
      );
    } else if (
      error.response.status === 400 &&
      error.response.data.errors.length > 0
    ) {
      if (error.response.data.error[0].detail.project_state) {
        yield put(
          projectsApiError("Le projet ne peux pas revenir dans cette état")
        );
      }
    } else if (error.response.data !== undefined) {
      yield put(projectsApiError(error.response.data.message));
    } else {
      yield put(projectsApiError(error.message));
    }
  }
}

function* deleteTechnicalVisitProject({ payload: { id, history } }) {
  try {
    const response = yield call(projects.deleteTechnicalVisit, id);
    yield put(updateProjectSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        projectsApiError("vous n'êtes pas autorisé à modifier le projet")
      );
    } else if (error.response.status === 404) {
      yield put(
        projectsApiError("vous n'êtes pas autorisé à modifier le projet")
      );
    } else if (
      error.response.status === 400 &&
      error.response.data.errors.length > 0
    ) {
      if (error.response.data.error[0].detail.project_state) {
        yield put(
          projectsApiError("Le projet ne peux pas revenir dans cette état")
        );
      }
    } else if (error.response.data !== undefined) {
      yield put(projectsApiError(error.response.data.message));
    } else {
      yield put(projectsApiError(error.message));
    }
  }
}

function* deleteProject({ payload: { id, history } }) {
  try {
    const response = yield call(projects.delete, id);
    yield put(deleteProjectSuccess(response));
    history.push("/projects-list?success_delete=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        projectsApiError("Vous n'êtes pas autorisé à supprimer ce projet")
      );
    } else if (error.response.data !== undefined) {
      yield put(projectsApiError(error.response.data.message));
    } else {
      yield put(projectsApiError(error.message));
    }
  }
}

function* exportProjects({ payload: { values } }) {
  try {
    const response = yield call(projects.exportProjects, values);
    yield put(exportProjectsSuccess(response));
    window.open(window.URL.createObjectURL(response.data));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        projectsApiError(
          "Vous n'êtes pas autorisé à accéder aux informations de ce projet."
        )
      );
    } else if (error.response.status === 404) {
      yield put(projectsApiError("Le projet n'a pas été trouvé"));
    } else if (error.response.status !== undefined) {
      yield put(projectsApiError(error.response.data.message));
    } else {
      yield put(projectsApiError(error.message));
    }
  }
}

export function* watchGetProjects() {
  yield takeEvery(GET_PROJECTS, getProjects);
}
export function* watchGetProject() {
  yield takeEvery(GET_PROJECT, getProject);
}
export function* watchUpdateProject() {
  yield takeEvery(UPDATE_PROJECT, updateProject);
}
export function* watchDeleteProject() {
  yield takeEvery(DELETE_PROJECT, deleteProject);
}
export function* watchDeleteTechnicalVisitProject() {
  yield takeEvery(DELETE_TECHNICAL_VISIT_PROJECT, deleteTechnicalVisitProject);
}
export function* watchExportProjects() {
  yield takeEvery(EXPORT_PROJECTS, exportProjects);
}

function* projectsSaga() {
  yield all([
    fork(watchGetProjects),
    fork(watchGetProject),
    fork(watchUpdateProject),
    fork(watchDeleteProject),
    fork(watchDeleteTechnicalVisitProject),
    fork(watchExportProjects),
  ]);
}

export default projectsSaga;
