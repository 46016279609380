import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  CATEGORIES_API_ERROR,
} from "./actionTypes";

export const getCategories = (query) => {
  return {
    type: GET_CATEGORIES,
    payload: { query },
  };
};

export const getCategoriesSuccess = (categories) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: categories,
  };
};

export const categoryApiError = (error) => {
  return {
    type: CATEGORIES_API_ERROR,
    payload: error,
  };
};
