import React, { useState } from "react";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";

import Select from "react-select";

const NotificationsForm = (props) => {
  const [priority, setPriority] = useState(
    props.priority ? { value: props.priority, label: props.priority } : null
  );
  const [state, setState] = useState(
    props.state ? { value: props.state, label: props.state } : null
  );
  const [message, setMessage] = useState("");

  /**
   * Fonction qui permet de gérer la hauteur du textarea du champ commentaire
   * @param {object} e L'événement retourné par le textarea, qui contient la balise HTML du textarea
   */
  const updateTextareaHeight = (e) => {
    e.currentTarget.style.height = "auto";
    e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
  };

  return (
    <div className="message-form">
      <Row className="justify-content-center">
        <Col xs="4">
          <FormGroup className="mb-4" row>
            <Label htmlFor="priority" className="col-form-label col">
              Priorité
            </Label>
            <Col xs="12">
              <Select
                className="w-100 calendar-filter-select"
                isSearchable
                noOptionsMessage={() => "Pas de priorité disponible"}
                placeholder="Choisir une priorité..."
                options={[
                  { value: "Normal", label: "Normal" },
                  { value: "Haut", label: "Haut" },
                  { value: "Urgent", label: "Urgent" },
                ]}
                value={priority}
                onChange={(priority) => {
                  setPriority(priority);
                }}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col xs="4">
          <FormGroup className="mb-4" row>
            <Label htmlFor="state" className="col-form-label col">
              État
            </Label>
            <Col xs="12">
              <Select
                className="w-100 calendar-filter-select"
                isSearchable
                noOptionsMessage={() => "Pas d'état disponible"}
                placeholder="Choisir un état..."
                options={[
                  { value: "Nouveau", label: "Nouveau" },
                  { value: "Vu", label: "Vu" },
                  {
                    value: "En attente du client",
                    label: "En attente du client",
                  },
                  {
                    value: "Réponse envoyée à l'ADV",
                    label: "Réponse envoyée à l'ADV",
                  },
                  { value: "Terminée", label: "Terminée" },
                  { value: "Archivée", label: "Archivée" },
                ]}
                value={state}
                onChange={(state) => {
                  setState(state);
                }}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="8">
          <textarea
            rows="5"
            className="form-control"
            placeholder="Ajouter un commentaire"
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
            onFocus={(e) => updateTextareaHeight(e)}
            onInput={(e) => updateTextareaHeight(e)}
          />
        </Col>
      </Row>
      <Row className="mt-4 justify-content-center">
        <Col xs="8" className="text-center">
          <Button
            className="btn-green"
            color="none"
            onClick={props.closeMessageForm}
          >
            Annuler
          </Button>
          <Button
            className="btn-green ml-3"
            color="none"
            onClick={() => {
              console.log("priority", priority);
              console.log("state", state);
              props.createMessage({
                priority: priority ? priority.value : null,
                state: state ? state.value : null,
                message: message,
              });
              props.closeMessageForm();
            }}
          >
            Valider
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NotificationsForm;
