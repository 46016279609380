import React, { useEffect, useState } from 'react';
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Badge } from 'reactstrap';
import { offer_codes as offerCodesApi } from '../../../../api/index';
import GlobalLoader from "../../../../components/Common/GlobalLoader";
import { onTableChange } from "../../../../helpers/table_helper";
import OfferCode, { computeStatusColor, computeStatusLabel, OfferCodesFilters } from '../../../../models/offer_code';
import Pagination from '../../../../models/types/pagination';

interface OfferCodesTableProps {
  filters: OfferCodesFilters,
  setFilters: React.Dispatch<React.SetStateAction<OfferCodesFilters>>
}

const OfferCodesTable = ({
  filters,
  setFilters
}: OfferCodesTableProps) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [offers, setOfferCodes] = useState<OfferCode[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);

  const getOfferCodes = () => {
    setLoading(true);
    offerCodesApi.getAll(filters).then(response => {
      setOfferCodes(response.data.objects);
      setPagination(response.data.pagination);
      setLoading(false);
    }).catch((_) => {
      setLoading(false);
    });
  };

  useEffect(getOfferCodes, [filters]);

  // Liste des colonnes qui seront affichées dans le tableau
  const columns: ColumnDescription<OfferCode>[] = [
    {
      dataField: "code",
      text: "Code",
    },
    {
      dataField: "status",
      text: "Validation",
      formatter: (cell, row) => <Badge pill color={computeStatusColor(row.status!)}>{computeStatusLabel(row.status!)}</Badge>
    },
    {
      dataField: "amount",
      text: "Montant",
      formatter: (cell, row) => `${(row.amount || 0).toFixed(2)} €`
    },
    {
      dataField: "quote_number",
      text: "N°devis"
    },
    {
      dataField: "client_name",
      text: "Client"
    },
    {
      dataField: "created_by_name",
      text: "Généré par"
    },
    {
      dataField: "apply_by_name",
      text: "Appliqué par"
    }
  ];

  return (
    loading ? <GlobalLoader /> : <BootstrapTable
      wrapperClasses="w-100"
      classes="w-100"
      keyField="id"
      data={offers}
      columns={columns}
      defaultSortDirection="asc"
      remote={{ pagination: true }}
      onTableChange={onTableChange.bind({ setFilters, filters })}
      pagination={paginationFactory({
        page: pagination?.current_page,
        totalSize: pagination?.total_count,
        sizePerPage: pagination?.current_page_size,
        sizePerPageList: [
          { text: "Afficher 10 éléments", value: 10 },
          { text: "Afficher 25 éléments", value: 25 },
          { text: "Afficher 50 éléments", value: 50 },
          { text: "Afficher 75 éléments", value: 75 },
          { text: "Afficher 100 éléments", value: 100 },
        ],
      })}
    />
  );
};

export default OfferCodesTable;
