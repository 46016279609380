import { GET_ROLES, GET_ROLES_SUCCESS, ROLES_API_ERROR } from "./actionTypes";

const initialState = {
  roles: [],
  error: "",
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const roles = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ROLES_SUCCESS:
      state = {
        ...state,
        roles: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        loading: false,
      };
      break;
    case ROLES_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default roles;
