import React from "react";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

const CardFollowInfo = (props) => (
  <React.Fragment>
    {/* <Col xl="4" sm="6">
      <Card>
        <CardBody>
          <Row>
            <Col lg="12">
              <h5>Information de suivi</h5>
              <p className="mb-1"><strong>Date appel:</strong>  {props.customer.follow_up_call_date || "Aucune date d'appel renseigné"}</p>
              <p className="mb-1"><strong>Suivi devis:</strong>  {props.customer.follow_up_quote || "Aucune valeur renseigné"}</p>
              <p className="mb-1"><strong>Interlocuteur:</strong> {props.customer.interlocutor || "Aucune valeur renseigné"}</p>
              <p className="mb-1"><strong>Origin du contact:</strong> {props.customer.contact_origin || "Aucune valeur renseigné"}</p>
              <p className="mb-1"><strong>Disponibilités:</strong> {props.customer.disponibilities || "Aucune valeur renseigné"}</p>
              <p className="mb-1"><strong>Suivi du contact:</strong> {props.customer.follow_up_contact || "Aucune valeur renseigné"}</p>
              <p className="mb-1"><strong>Validation copro:</strong> {props.customer.validation_copro || "Aucune valeur renseigné"}</p>
              <p className="mb-1"><strong>Appartement:</strong> {props.customer.appartment || "Aucune valeur renseigné"}</p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col> */}
  </React.Fragment>
);

const mapStatetoProps = (state) => {
  return {  };
};


export default withRouter(
  connect(mapStatetoProps, {  })(
    CardFollowInfo
  )
);
