/**
 * Number of days triggering an alarm
 * @type {number}
 */
export const NB_DAYS_ALARM = 30;

/**
 * Parse date to local date string and return empty if there is no dates
 * @param date
 * @return {string}
 */
export function parseDate(date) {
  if(date) {
    return new Date(date).toLocaleDateString();
  }else {
    return "";
  }
}

/**
 * Check id the date is ending soon (the offset is set by the NB_DAYS_ALARM constant)
 * @see NB_DAYS_ALARM
 * @param date
 * @return {string}
 */
export function isEndSoon(date) {
  if(date) {
    let dateInstance = new Date(date);

    const today = new Date();
    today.setDate(today.getDate() + NB_DAYS_ALARM);

    if(today > dateInstance)
      return 'error';
    else
      return "";
  }else {
    return "";
  }
}

/**
 * Compute the verbose form for the periodicity
 * @param value
 * @return {string}
 */
export function computePeriodicity(value) {
  switch (value){
    case 1: return 'Mensuelle';
    case 2: return 'Semestrielle';
    case 3: return 'Quadrimestriel';
    case 4: return 'Trimestriel';
    case 6: return 'Bimestriel';
    case 12: return 'Annuel';
    default: return '';
  }
}
