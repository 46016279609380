import {Formik} from 'formik';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {subscriptions} from "../../../api";
import {connect} from "react-redux";
import {parseDate} from "../../../helpers/subscriptions_helpers";
import {getSubscription} from "../../../store/subscriptions/actions";
import { addFlashMessage, ImportantEnum } from '../../../components/Common/FlashMessage';

const ActivateSubscriptionModal = ({
  isOpen,
  toggleModal,
  subscription,
  action,
  text,
  btnText,
  getSubscription,
                              }) => {

  const initValues = {
    validated_at: subscription.validated_at ? subscription.validated_at.substr(0, 10) : new Date().toISOString().substr(0,10),
    start_at: new Date().toISOString().substr(0,10)
  }

  const handleSubmit = (values) => {
    subscriptions.subscriptionValidate(subscription.id, values.validated_at, values.start_at)
      .then(r => {
        toggleModal();
        addFlashMessage(ImportantEnum.SUCCESS, `L'abonnement a été validé le ${parseDate(values.validated_at)} et commencera le ${parseDate(values.start_at)}`)
        getSubscription(subscription.id);
      })
      .catch(err => console.error(err));
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
    >
      <Formik
        initialValues={initValues}
        onSubmit={handleSubmit}
      >
        {({handleSubmit, handleChange, values}) => (
          <div>
            <ModalHeader toggle={() => toggleModal()}>
              Abonnement - {action}
            </ModalHeader>
            <ModalBody>
              <div>
                <h1>Attention</h1>
                <p>{text}</p>
              </div>
              <FormGroup>
                <Label for='validated_at'>Date de validation mandat</Label>
                <Input
                  name='validated_at'
                  id='validated_at'
                  type='date'
                  onChange={handleChange}
                  defaultValue={values.validated_at}
                />
              </FormGroup>
              <FormGroup>
                <Label for='start_at'>Date du premier prélèvement</Label>
                <Input
                  name='start_at'
                  id='start_at'
                  type='date'
                  onChange={handleChange}
                  defaultValue={values.start_at}
                />
              </FormGroup>

            </ModalBody>
            <ModalFooter>
              <Button onClick={() => toggleModal()}>Annuler</Button>
              <Button type='submit' onClick={() => handleSubmit(values)} className='btn-green'>{btnText}</Button>
            </ModalFooter>
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default connect(null, {
  getSubscription,
})(ActivateSubscriptionModal);