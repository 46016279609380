export const GET_FREE_ITEMS_SAVE = "GET_FREE_ITEMS_SAVE";
export const GET_FREE_ITEMS_SAVE_SUCCESS = "GET_FREE_ITEMS_SAVE_SUCCESS";

export const CREATE_FREE_ITEM_SAVE = "CREATE_FREE_ITEM_SAVE";
export const CREATE_FREE_ITEM_SAVE_SUCCESS = "CREATE_FREE_ITEM_SAVE_SUCCESS";

export const DELETE_FREE_FIELD = "DELETE_FREE_FIELD";
export const DELETE_FREE_FIELD_SUCCESS = "DELETE_FREE_FIELD_SUCCESS";

export const FREE_FIELDS_API_ERROR = "FREE_FIELDS_API_ERROR";
