/**
 *
 * @param {array} categories
 * @param {object} selected
 */
export function getEnabledCategoryIds(families, selected) {
  let ids = [];

  families.forEach(item => {
    if(selected[item.category.name])
      ids.push(item.category.id)
  })

  return ids;
}

/**
 *
 * @param {array} categories
 * @param {object} selected
 */
export function getEnabledSubCategoryIds(categories, selected) {
  let ids = [];

  categories.forEach(item => {
    if(selected[item.sub_category.name])
      ids.push(item.sub_category.id)
  })

  return ids;
}

export function computeInitSelected(families, categories, products){
  const selected = {families: {}, categories: {}, products: {}}

  if(families)
    families.forEach(item => {
      selected.families[item.category.name] = true;
    })

  if(categories)
    categories.forEach(item => {
      selected.categories[item.sub_category.name] = true;
    })

  if(products)
    products.forEach(item => {
      selected.products[item.product.commercial_name] = true;
    })

  return selected;
}