import * as Yup from 'yup';

export const paymentValidationForm = Yup.object().shape({

  payment_date: Yup.mixed().required('La date est requise'),

  amount: Yup.number()
    .required('Le prix est requis')
    .typeError('Le prix doit être un nombre')
    .positive('Le prix doit être positif'),
    // .when(['total_price_ttc', 'paid_total_ttc', 'initPayment'], (total_price_ttc, paid_total_ttc, initPayment, schema) => {
    //   // Compute the rest of the payment left to cancel budget exceed
    //   // the initPayment is here when it's an update, so the payment amount is not used in the total
    //   const toPay = initPayment ? (total_price_ttc - paid_total_ttc + initPayment) : (total_price_ttc - paid_total_ttc);
    //   return toPay > 0 ?
    //     schema.max(toPay.toFixed(2), `Le prix maximal ne doit pas dépasser ${toPay.toFixed(2)} €`) :
    //     schema.max(0, 'Tout est déjà payé')
    // }),

  payment_type: Yup.mixed().required('Le type de paiement est requis'),
  bank_account_id: Yup.mixed().required('La banque est requise'),

  reference: ''
});