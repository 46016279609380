import { useEffect, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import FormikAutocomplete from "../../../../components/Common/AsyncAutoComplete2";
import {
  promiseRequestPriorityOptions,
  promiseRequestStatusOptions
} from "../../../../helpers/autocomplete_promise_options_api_v2";
import { promiseUsersOptions } from "../../../../helpers/autocomplete_promise_options";
import LoaderLayoutPulse from "../../../../components/Common/LoaderLayoutPulse";
import { Field, Formik } from 'formik';
interface requestEditModalProps {
  onValidate: (values: any) => Promise<any>,
  value: any,
  onAbort: () => void,
  editAction: { request_id: number | null, action: string }
}

export default function RequestEditModal({ onValidate, editAction, onAbort, value }: requestEditModalProps) {
  let getTitleFromAction = {
    status: "Modifier le statut",
    priority: "Modifier la priorité",
    assign: "Assigner à",
    comment: "Modifier le commentaire",
  }

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any | null>(null);
  const [type, setType] = useState<string>("options");

  useEffect(() => {
    switch (editAction.action) {
      case "status":
        setData(promiseRequestStatusOptions());
        setType("options");
        break;
      case "priority":
        setData(promiseRequestPriorityOptions());
        setType("options");
        break;
      case "assign":
        setData(promiseUsersOptions());
        setType("options");
        break;
      case "comment":
        setData('');
        setType("string");
        break;
    }
  }, [editAction.action]);

  return (
    <Modal isOpen={true} toggle={onAbort} className={""}>
      <ModalHeader toggle={onAbort}>{getTitleFromAction[editAction.action as keyof typeof getTitleFromAction]}</ModalHeader>
      <ModalBody className={"d-flex flex-column justify-content-center align-items-center"}>
        {data == null ? <LoaderLayoutPulse /> :
          <>
            <Formik initialValues={{ value }} onSubmit={values => {
              setLoading(true);
              onValidate(values).then(() => {
                setLoading(false);
              }).catch(() => {
                setLoading(false);
              });
            }}>
              {({ values, handleSubmit }) => (
                <form className={"w-100 d-flex flex-column justify-content-center align-items-center"}>
                  {type === "options" && <FormikAutocomplete
                    loadOptions={() => data}
                    name={"value"}
                    className={"w-100"}
                  />}
                  {type === "string" && <Input
                    id="value"
                    name="value"
                    type="text"
                    tag={Field}
                    className="form-control"
                    placeholder="Entrer une valeur"
                  />}
                  {loading ? <LoaderLayoutPulse /> : <Button type="button" className={"btn btn-green mt-3"} onClick={() => handleSubmit(values as any)}>Valider</Button>}
                </form>
              )}
            </Formik>
          </>
        }
      </ModalBody>
    </Modal>
  );
}