import { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  Col,
  UncontrolledDropdown,
} from "reactstrap";

import Select from "react-select";

const ActionButtons = (props) => {
  // Afficher la modal de changement de status
  const [modalStatus, setModalStatus] = useState(false);
  const [state, setState] = useState(
    props.row.state ? { value: props.row.state, label: props.row.state } : null
  );
  // Afficher la modal pour supprimer une relance
  const [modalRemove, setModalRemove] = useState(false);

  return (
    <div className="d-flex justify-content-between align-items-center">
      <UncontrolledDropdown>
        <DropdownToggle className={"bg-transparent"}>
          <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
        </DropdownToggle>
        <DropdownMenu right className="bordered">
          <DropdownItem onClick={() => { props.history.push(`/relance/detail/${props.row.id}`) }}>Voir</DropdownItem>
          <DropdownItem onClick={() => { window.open(`/relance/detail/${props.row.id}`,'_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
          <DropdownItem onClick={() => { props.history.push(`/relance/edit/${props.row.id}`) }}>Modifier</DropdownItem>
          <DropdownItem onClick={() => { setModalStatus(!modalStatus) }}>État</DropdownItem>
          <DropdownItem onClick={() => { setModalRemove(!modalRemove) }}>Supprimer</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <Modal
        isOpen={modalStatus}
        toggle={() => {
          setModalStatus(!modalStatus);
        }}
      >
        <ModalHeader>Changer l'état de la relance</ModalHeader>
        <ModalBody>
          <FormGroup className="mb-4" row>
            <Label htmlFor="state" className="col-form-label col">
              État
            </Label>
            <Col xs="12">
              <Select
                className="w-100 calendar-filter-select mb-3"
                isSearchable
                noOptionsMessage={() => "Pas d'état disponible"}
                placeholder="Choisir un état..."
                options={[
                  { value: "Nouveau", label: "Nouveau" },
                  { value: "Vu", label: "Vu" },
                  {
                    value: "En attente du client",
                    label: "En attente du client",
                  },
                  {
                    value: "Réponse envoyée à l'ADV",
                    label: "Réponse envoyée à l'ADV",
                  },
                  { value: "Terminée", label: "Terminée" },
                  { value: "Archivée", label: "Archivée" },
                ]}
                value={state}
                onChange={(state) => {
                  setState(state);
                }}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setModalStatus(!modalStatus);
            }}
            className="btn btn-secondary waves-effect"
          >
            Annuler
          </Button>
          <Button
            onClick={() => {
              setModalStatus(!modalStatus);
              props.updateNotification(props.row.id, { state: state.value });
            }}
            className="btn btn-green waves-effect waves-light"
          >
            Valider
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modalRemove}
        toggle={() => {
          setModalRemove(!modalRemove);
        }}
      >
        <ModalHeader>Confirmation - Supprimer la relance</ModalHeader>
        <ModalBody>
          <p>Êtes vous sûr de vouloir supprimer cette relance ?</p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setModalRemove(!modalRemove);
            }}
            className="btn btn-secondary waves-effect"
          >
            Annuler
          </Button>
          <Button
            onClick={() => {
              setModalRemove(!modalRemove);
              props.deleteNotification(props.row.id);
            }}
            className="btn btn-green waves-effect waves-light"
          >
            Valider
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ActionButtons;
