export const GET_QUOTES = "GET_QUOTES";
export const GET_QUOTES_SUCCESS = "GET_QUOTES_SUCCESS";

export const GET_QUOTE = "GET_QUOTE";
export const GET_QUOTE_SUCCESS = "GET_QUOTE_SUCCESS";

export const CREATE_QUOTE = "CREATE_QUOTE";
export const CREATE_QUOTE_SUCCESS = "CREATE_QUOTE_SUCCESS";

export const UPDATE_QUOTE = "UPDATE_QUOTE";
export const UPDATE_QUOTE_SUCCESS = "UPDATE_QUOTE_SUCCESS";

export const DELETE_QUOTE = "DELETE_QUOTE";
export const DELETE_QUOTE_SUCCESS = "DELETE_QUOTE_SUCCESS";

export const GENERATE_QUOTE_ZIP = "GENERATE_QUOTE_ZIP";
export const GENERATE_QUOTE_ZIP_SUCCESS = "GENERATE_QUOTE_ZIP_SUCCESS";

export const SEND_QUOTE_EMAILS = "SEND_QUOTE_EMAILS";
export const SEND_QUOTE_EMAILS_SUCCESS = "SEND_QUOTE_EMAILS_SUCCESS";

export const SHOW_QUOTE = "SHOW_QUOTE";
export const SHOW_QUOTE_SUCCESS = "SHOW_QUOTE_SUCCESS";

export const QUOTES_API_ERROR = "QUOTES_API_ERROR";

export const MIGRATE_ORDER_FROM_QUOTE = "MIGRATE_ORDER_FROM_QUOTE";
export const MIGRATE_ORDER_FROM_QUOTE_SUCCESS = "MIGRATE_ORDER_FROM_QUOTE_SUCCESS";
