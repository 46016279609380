import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  IMPORT_CSV,
  IMPORT_CSV_SUCCESS,
  CUSTOMERS_API_ERROR,
} from "./actionTypes";

const initialState = {
  customers: [],
  customer: false,
  error: false,
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const customers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUSTOMERS_SUCCESS:
      state = {
        ...state,
        customers: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        customer: false,
        loading: false,
        error: false,
      };
      break;
    case GET_CUSTOMER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUSTOMER_SUCCESS:
      state = {
        ...state,
        customer: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case CREATE_CUSTOMER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_CUSTOMER_SUCCESS:
      state = {
        ...state,
        customer: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case UPDATE_CUSTOMER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_CUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case DELETE_CUSTOMER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_CUSTOMER_SUCCESS:
      state = {
        ...state,
        customers:
          state.customers === undefined || state.customers === null
            ? []
            : state.customers.filter((e) => e.id !== action.payload.data.id),
        loading: false,
        error: false,
      };
      break;
    case IMPORT_CSV:
      state = {
        ...state,
        loading: true,
      };
      break;
    case IMPORT_CSV_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case CUSTOMERS_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default customers;
