import {
  GET_AGENDA_ITEMS,
  GET_AGENDA_ITEMS_SUCCESS,
  GET_AGENDA_ITEM,
  GET_AGENDA_ITEM_SUCCESS,
  CREATE_AGENDA_ITEM,
  CREATE_AGENDA_ITEM_SUCCESS,
  UPDATE_AGENDA_ITEM,
  UPDATE_AGENDA_ITEM_SUCCESS,
  DELETE_AGENDA_ITEM,
  DELETE_AGENDA_ITEM_SUCCESS,
  AGENDA_ITEMS_API_ERROR,
} from "./actionTypes";

const initialState = {
  agendaItems: [],
  agendaItem: false,
  error: false,
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const agendaItems = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENDA_ITEMS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_AGENDA_ITEMS_SUCCESS:
      state = {
        ...state,
        agendaItems: action.payload.agendaItems.data.objects
          ? action.payload.agendaItems.data.objects
          : action.payload.agendaItems.data,
        pagination: action.payload.agendaItems.data.pagination,
        loading: false,
        error: false,
      };
      break;
    case GET_AGENDA_ITEM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_AGENDA_ITEM_SUCCESS:
      state = {
        ...state,
        agendaItem: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case CREATE_AGENDA_ITEM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_AGENDA_ITEM_SUCCESS:
      state = {
        ...state,
        agendaItem: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case UPDATE_AGENDA_ITEM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_AGENDA_ITEM_SUCCESS:
      state = {
        ...state,
        agendaItem: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case DELETE_AGENDA_ITEM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_AGENDA_ITEM_SUCCESS:
      state = {
        ...state,
        agendaItems:
          state.agendaItems === undefined || state.agendaItems === null
            ? []
            : state.agendaItems.filter((e) => e.id !== action.payload.data.id),
        loading: false,
        error: false,
      };
      break;
    case AGENDA_ITEMS_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default agendaItems;
