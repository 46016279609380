// Helpers
import {
  getShortDateAndTimeWithFormat,
} from "../../../../helpers/helper";
import AgendaItem from "../../../../models/agenda_item";

interface AgendaItemHistoryProps {
  agendaItem: AgendaItem
}

const AgendaItemHistory = ({
  agendaItem,
}: AgendaItemHistoryProps) => {

  function getAgendaItemHistory(agendaItem: AgendaItem) {
    if (agendaItem === null || agendaItem === undefined) return [];
    let currentAgendaItem = agendaItem;
    let agendaItems = [agendaItem];
    while (currentAgendaItem.agenda_item != null) {
      currentAgendaItem = currentAgendaItem.agenda_item;
      agendaItems.unshift(currentAgendaItem);
    }
    return agendaItems;
  }

  return (
    <ul>
      {
        getAgendaItemHistory(agendaItem).map((agendaItem) => (
          <li>
            <strong>
              Rendez-vous le{" "}
              {getShortDateAndTimeWithFormat(
                agendaItem?.date_begin
              )}
            </strong>
            {
              agendaItem.agenda_item === null
                ? (
                  <p>
                    (créé le&nbsp;
                    {
                      getShortDateAndTimeWithFormat(agendaItem?.created_at)
                    }
                    &nbsp;par&nbsp;
                    {
                      `${agendaItem?.user?.firstname} ${agendaItem?.user?.lastname}`
                    }
                    )
                  </p>
                )
                : agendaItem.deleted_at === null
                  ? (
                    <p>
                      (modifié le&nbsp;
                      {
                        getShortDateAndTimeWithFormat(agendaItem?.updated_at)
                      }
                      &nbsp;par&nbsp;
                      {
                        `${agendaItem?.user?.firstname} ${agendaItem?.user?.lastname}`
                      }
                      )
                    </p>
                  )
                  : (
                    <p>
                      (supprimé le&nbsp;
                      {
                        getShortDateAndTimeWithFormat(agendaItem?.deleted_at)
                      }
                      &nbsp;par&nbsp;
                      {
                        `${agendaItem?.user?.firstname} ${agendaItem?.user?.lastname}`
                      }
                      )
                    </p>
                  )
            }
          </li>
        )
        )}
    </ul>
  );
}

export default AgendaItemHistory;