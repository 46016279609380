import React, { useState } from 'react';
import { Link } from "react-router-dom";
import ActivateSubscriptionModal from "../Modal/ActivateSubscriptionModal";
import StopSubscriptionModal from "../Modal/StopSubscriptionModal";
import ValidationModal from "../../Quotes/Modals/ValidationModal";
import SuspendSubscriptionModal from "../Modal/SuspendSubscriptionModal";

const SubscriptionDetailActions = ({
  subscription,
  handleDelete
}) => {

  const [validateModal, setValidateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [stopModal, setStopModal] = useState(false);
  const [suspendModal, setSuspendModal] = useState(false);
  const [modalText, setModalText] = useState({
    action: '',
    text: '',
    btnText: ''
  })

  const computeButtonText = () => {
    if (!subscription.validated_at) {
      return (
        <Link
          to='#'
          className="d-flex align-items-center text-theme-color-2"
          onClick={() => {
            setModalText({
              action: 'validation',
              text: 'Pour valider l\'abonnement, entrer les dates suivantes',
              btnText: 'Valider'
            });
            setValidateModal(true);
          }}
        >
          <i className="bx bx-check mr-1>" /> Valider
        </Link>);
    } else if (subscription.to_renew) {
      return (
        <Link
          to='#'
          className="d-flex align-items-center text-theme-color-2"
          onClick={() => {
            setStopModal(true);
          }}
        >
          <i className="bx bx-x mr-1>" /> Arrêter reconduction
        </Link>
      );
    } else {
      return (
        <Link
          to='#'
          className="d-flex align-items-center text-theme-color-2"
          onClick={() => {
            setModalText({
              action: 'réactiver',
              text: 'Pour réactiver l\'abonnement, entrer les dates suivantes',
              btnText: 'Réactiver'
            });
            setValidateModal(true);
          }}
        >
          <i className="bx bx-revision mr-1>" /> Réactiver
        </Link>
      );
    }
  }

  return (
    <div className='d-flex justify-content-end mr-4'>
      <Link
        to='#'
        className="mr-4 d-flex align-items-center text-theme-color-2"
        onClick={() => {
          setSuspendModal(true);
        }}
      >
        <i className={`bx ${subscription.suspended_at ? "bx-revision" : "bx-x"} mr-1`} /> {subscription.suspended_at ? 'Annuler la résiliation' : 'Résilier'}
      </Link>
      {computeButtonText()}
      <Link
        to={`/subscription/update/${subscription.id}`}
        className="ml-4 d-flex align-items-center text-theme-color-2"
      >
        <i className="bx bx-pencil mr-1>" /> Modifier
      </Link>
      <Link
        to='#'
        className="ml-4 d-flex align-items-center text-theme-color-2"
        onClick={() => setDeleteModal(true)}
      >
        <i className="bx bx-trash mr-1>" /> Supprimer
      </Link>

      <ActivateSubscriptionModal
        isOpen={validateModal}
        toggleModal={() => setValidateModal(!validateModal)}
        subscription={subscription}
        action={modalText.action}
        text={modalText.text}
        btnText={modalText.btnText}
      />
      <StopSubscriptionModal
        isOpen={stopModal}
        toggleModal={() => setStopModal(!stopModal)}
        subscription={subscription}
      />
      <ValidationModal
        isOpen={deleteModal}
        toggler={() => setDeleteModal(!deleteModal)}
        title='Abonnement - suppression'
        message={<div><h1>Attention</h1><p>Voulez-vous supprimer cet abonnement ?</p></div>}
        btnValidText='Supprimer'
        btnBackText='Annuler'
        handleSubmit={handleDelete}
      />
      <SuspendSubscriptionModal
        isOpen={suspendModal}
        toggleModal={() => setSuspendModal(!suspendModal)}
        subscription={subscription}
      />
    </div>
  );
}

export default SubscriptionDetailActions;