import React from "react";

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { loginUser, apiError } from "../../store/actions";

// import images
import header_img from "../../assets/images/Logo/nwe_logo_blanc.png";
import header_img_2 from "../../assets/images/login/Objet_dynamique_vectoriel3.png";
import logo from "../../assets/images/login/Objet_dynamique_vectoriel2.png";

const Login = (props) => {
  // handleValidSubmit
  function handleValidSubmit(event, values) {
    props.loginUser(values, props.history);
  }
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container className="login-container">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header-bg-soft">
                  <Row>
                    <Col xs={12} md={4}>
                      <div className="pt-4 pl-4 pb-md-4 pb-0 pr-0">
                        <h5 className="login-header-text-theme-color">
                          Bienvenue !
                        </h5>
                        <p className="login-header-text-theme-color">
                          Se connecter
                        </p>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={8}
                      className="align-self-end d-flex flex-row align-items-center justify-content-end pb-1 pb-md-0"
                    >
                      <img
                        src={header_img}
                        alt=""
                        className="img-fluid login-header-img mb-4"
                      />
                      <img
                        src={header_img_2}
                        alt=""
                        className="img-fluid login-header-img"
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {props.error && props.error ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          value=""
                          className="form-control"
                          placeholder="Entrer un email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Mot de passe"
                          value=""
                          type="password"
                          required
                          placeholder="Entrer un mot de passe"
                        />
                      </div>

                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customControlInline"
                        >
                          Se souvenir de moi
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Se Connecter
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password">
                          <i className="mdi mdi-lock mr-1"></i> Mot de passe
                          oublié ?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError })(Login)
);
