export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCES";

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCES";

export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";

export const UPDATE_SUBSCRIPTION_VALIDATED = "UPDATE_SUBSCRIPTION_VALIDATED";
export const UPDATE_SUBSCRIPTION_VALIDATED_SUCCESS = "UPDATE_SUBSCRIPTION_VALIDATED_SUCCESS";

export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS";

export const SUBSCRIPTIONS_API_ERROR = "SUBSCRIPTIONS_API_ERROR";
