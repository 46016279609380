import React from "react";

// Modals components
import {
    Modal,
    ModalHeader,
    ModalBody,
  } from "reactstrap";

  // Customer form 
import InvoicesForm from "../FormComponent/InvoicesForm";

// Validation schema
import {createDepositValidationSchema} from "../FormValidationSchemas/createDepositValidationSchema";

/**
 * Modal opened when a deposite needs to be created
 * 
 * @param isOpen defined if the modal is open
 * @param toggler function toggling the modal
 * @param handleSubmit handler for the submission of deposit
 * **/
const QuoteValidationModal = ({
    isOpen,
    toggler,
    handleSubmit,
    order,
}) => {

  let total_price_ttc = order?.total_price_ttc || 0;
  if (order && order.cce_prime) total_price_ttc -= order.cce_prime;
  if (order && order.other_prime_amount) total_price_ttc -= order.other_prime_amount / 100.0;

  return (
    <Modal
        isOpen={isOpen}
        toggle={() => {
            toggler();
        }}
      >
        <ModalHeader toggle={() => toggler()} tag="h4">
          Facture d'acompte
        </ModalHeader>
        <ModalBody>
          <InvoicesForm
            initValues={{
              invoice_date: "",
              percent: "",
              price: "",
              payment_mode: "",
            }}
            total_price_ttc={total_price_ttc}
            isDeposit={true}
            validationSchema={createDepositValidationSchema}
            handleSubmit={handleSubmit}
          />
        </ModalBody>
      </Modal>
  );

}

export default QuoteValidationModal;