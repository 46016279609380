export const GET_FINANCERS = "GET_FINANCERS";
export const GET_FINANCERS_SUCCESS = "GET_FINANCERS_SUCCESS";

export const GET_FINANCER = "GET_FINANCER";
export const GET_FINANCER_SUCCESS = "GET_FINANCER_SUCCESS";

export const CREATE_FINANCER = "CREATE_FINANCER";
export const CREATE_FINANCER_SUCCESS = "CREATE_FINANCER_SUCCESS";

export const UPDATE_FINANCER = "UPDATE_FINANCER";
export const UPDATE_FINANCER_SUCCESS = "UPDATE_FINANCER_SUCCESS";

export const DELETE_FINANCER = "DELETE_FINANCER";
export const DELETE_FINANCER_SUCCESS = "DELETE_FINANCER_SUCCESS";

export const FINANCERS_API_ERROR = "FINANCERS_API_ERROR";
