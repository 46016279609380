import {
  GET_AGENDA_ITEMS,
  GET_AGENDA_ITEMS_SUCCESS,
  GET_AGENDA_ITEM,
  GET_AGENDA_ITEM_SUCCESS,
  CREATE_AGENDA_ITEM,
  CREATE_AGENDA_ITEM_SUCCESS,
  UPDATE_AGENDA_ITEM,
  UPDATE_AGENDA_ITEM_SUCCESS,
  DELETE_AGENDA_ITEM,
  DELETE_AGENDA_ITEM_SUCCESS,
  AGENDA_ITEMS_API_ERROR,
} from "./actionTypes";

export const getAgendaItems = (
  query
) => {
  return {
    type: GET_AGENDA_ITEMS,
    payload: { query },
  };
};

export const getAgendaItemsSuccess = (
  agendaItems
) => {
  return {
    type: GET_AGENDA_ITEMS_SUCCESS,
    payload: { agendaItems },
  };
};

export const getAgendaItem = (id) => {
  return {
    type: GET_AGENDA_ITEM,
    payload: { id },
  };
};

export const getAgendaItemSuccess = (agendaItem) => {
  return {
    type: GET_AGENDA_ITEM_SUCCESS,
    payload: agendaItem,
  };
};

export const createAgendaItem = (agendaItem, history) => {
  return {
    type: CREATE_AGENDA_ITEM,
    payload: { agendaItem, history },
  };
};

export const createAgendaItemSuccess = (agendaItem) => {
  return {
    type: CREATE_AGENDA_ITEM_SUCCESS,
    payload: agendaItem,
  };
};

export const updateAgendaItem = (id, agendaItem, history) => {
  return {
    type: UPDATE_AGENDA_ITEM,
    payload: { id, agendaItem, history },
  };
};

export const updateAgendaItemSuccess = (agendaItem) => {
  return {
    type: UPDATE_AGENDA_ITEM_SUCCESS,
    payload: agendaItem,
  };
};

export const deleteAgendaItem = (id, history) => {
  return {
    type: DELETE_AGENDA_ITEM,
    payload: { id, history },
  };
};

export const deleteAgendaItemSuccess = (agendaItem) => {
  return {
    type: DELETE_AGENDA_ITEM_SUCCESS,
    payload: agendaItem,
  };
};

export const agendaItemsApiError = (error) => {
  return {
    type: AGENDA_ITEMS_API_ERROR,
    payload: error,
  };
};
