import React, { useCallback } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import FormikAutocomplete from "../../../../components/Common/AsyncAutoComplete2";
import {
  promiseRequestPriorityOptions,
  promiseRequestStatusOptions,
  promiseUsersOptions
} from "../../../../helpers/autocomplete_promise_options_api_v2";
import LoaderLayoutPulse from "../../../../components/Common/LoaderLayoutPulse";
import { Formik } from 'formik';
import { UserRoleEnum } from '../../../../models/user';
interface requestEditModalProps {
  values: any,
  onValidate: (values: any) => Promise<any>,
  onAbort: () => void,
  editAction: { intervention_folder_id: number | null, action: string }
}

export default function InterventionFolderEditModal({ onValidate, editAction, onAbort, values }: requestEditModalProps) {
  let getTitleFromAction = {
    status: "Modifier le statut",
    priority: "Modifier la priorité",
    technicians: "Modifier les techniciens"
  }

  const [loading, setLoading] = React.useState(false);

  const loadOptions = useCallback(async(query: string) => {
    switch (editAction.action) {
      case "status":
        return await promiseRequestStatusOptions();
      case "priority":
        return await promiseRequestPriorityOptions();
      case "technicians":
        return await promiseUsersOptions({ roles: [UserRoleEnum.TECHNICIAN], query });
      default:
        return [];
    }
  }, [editAction.action]);

  const loadOptionsByValue = useCallback((value: any) => {
    switch (editAction.action) {
      case "status":
        return promiseRequestStatusOptions();
      case "priority":
        return promiseRequestPriorityOptions();
      case "technicians":
        return promiseUsersOptions({ ids: value, roles: [UserRoleEnum.TECHNICIAN] });
      default:
        return [];
    }
  }, [editAction.action]);


  return (
    <Modal isOpen={true} toggle={onAbort} className={""}>
      <ModalHeader toggle={onAbort}>{getTitleFromAction[editAction.action as keyof typeof getTitleFromAction]}</ModalHeader>
      <ModalBody className={"d-flex flex-column justify-content-center align-items-center"}>
        {loadOptions == null ? <LoaderLayoutPulse /> :
          <>
            <Formik initialValues={{value: values}} onSubmit={values => { 
              setLoading(true);
              onValidate(values).then(() => {
                setLoading(false);
              }).catch(() => {
                setLoading(false);
              });
             }}>
              {({ values, handleSubmit }) => (
                <form className={"w-100 d-flex flex-column justify-content-center align-items-center"}>
                  <FormikAutocomplete
                    loadOptionsByValue={loadOptionsByValue}
                    loadOptions={loadOptions}
                    name={"value"}
                    className={"w-100"}
                    multiple={editAction.action === "technicians"}
                  />
                  {loading ? <LoaderLayoutPulse /> : <Button type="button" className={"btn btn-green mt-3"} onClick={() => handleSubmit(values as any)}>Valider</Button>}
                </form>
              )}
            </Formik>
          </>
        }
      </ModalBody>
    </Modal>
  );
}