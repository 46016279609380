import React, { useEffect, useState } from 'react';
import BootstrapTable, { ColumnDescription, SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {V2} from '../../../../../api';
import GlobalLoader from "../../../../../components/Common/GlobalLoader";
import { onTableChange } from "../../../../../helpers/table_helper";
import Request, { RequestsFilters } from '../../../../../models/request';
import Pagination, { getPaginationFromResponse } from '../../../../../models/types/pagination';
import {Badge} from "reactstrap";
import Project from "../../../../../models/project";
import moment from "moment";

interface ProjectTableProps {
  filters: any,
  setFilters: React.Dispatch<React.SetStateAction<RequestsFilters>>,
}

const ProjectTable = ({
 filters,
 setFilters,
}: ProjectTableProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [requests, setRequests] = useState<Request[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);

  const getRequests = () => {
    setLoading(true);
    V2.projects.getAll(filters).then(response => {
      setRequests(response.data as Project[]);
      console.log(response.data)
      setPagination(getPaginationFromResponse(response));
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };

  useEffect(getRequests, [filters]);

  // Liste des colonnes qui seront affichées dans le tableau
  const columns: ColumnDescription<Project>[] = [
    {
      dataField: "date",
      text: "Date",
      formatter: (cell, row) => moment(row.created_at).format("DD/MM/YY") || "",
      sort: true
    },
    {
      dataField: "status",
      text: "Etat",
      formatter: (cell, row) =>
          <Badge className={"badge-color-"+row.project_state?.label}>
            {row.project_state?.name}
          </Badge> || "",
      sort: true
    },
    {
      dataField: "product",
      text: "Produit",
      formatter: (cell, row) => row.first_product_label || "",
    },
    {
      dataField: "contract",
      text: "Contrat",
      formatter: (cell, row) =>  row.first_subscription_label || "",
    },
    {
      dataField: "details",
      text: "",
      classes: "text-nowrap",
      formatter: (cell, row) => <a href={"/projects-overview/"+row.id} target="_blank" rel='noreferrer' >En Détails <i className="fa fa-angle-right"/></a> || "",
    },
  ];

  return (
    loading ? <GlobalLoader/>  : <BootstrapTable
      keyField="id"
      data={requests}
      columns={columns}
      defaultSortDirection="asc"
      sort={{ dataField: filters.sort_by, order: (filters.sort_dir || "asc")  as SortOrder }}
      remote={{ pagination: true, sort: true }}
      onTableChange={onTableChange.bind({ setFilters, filters })}
      pagination={paginationFactory({
        page: pagination?.current_page,
        totalSize: pagination?.total_count,
        sizePerPage: pagination?.current_page_size,
        sizePerPageList: [
          { text: "Afficher 10 éléments", value: 10 },
          { text: "Afficher 25 éléments", value: 25 },
          { text: "Afficher 50 éléments", value: 50 },
          { text: "Afficher 75 éléments", value: 75 },
          { text: "Afficher 100 éléments", value: 100 },
        ],
      })}
    />
  );
};

export default ProjectTable;
