import {
  GET_QUOTES,
  GET_QUOTES_SUCCESS,
  GET_QUOTE,
  GET_QUOTE_SUCCESS,
  CREATE_QUOTE,
  CREATE_QUOTE_SUCCESS,
  UPDATE_QUOTE,
  UPDATE_QUOTE_SUCCESS,
  DELETE_QUOTE,
  DELETE_QUOTE_SUCCESS,
  GENERATE_QUOTE_ZIP,
  GENERATE_QUOTE_ZIP_SUCCESS,
  SEND_QUOTE_EMAILS,
  SEND_QUOTE_EMAILS_SUCCESS,
  SHOW_QUOTE,
  SHOW_QUOTE_SUCCESS,
  QUOTES_API_ERROR,
  MIGRATE_ORDER_FROM_QUOTE,
  MIGRATE_ORDER_FROM_QUOTE_SUCCESS
} from "./actionTypes";

const initialState = {
  quotes: [],
  quote_order: false,
  quote: false,
  error: false,
  loading: false,
  quote_zip_file: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
  list_success: [],
  list_already_sent: [],
  list_failed: [],
};

const quotes = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUOTES:
      state = {
        ...state,
        loading: true,
        list_success: [],
        list_already_sent: [],
        list_failed: [],
      };
      break;
    case GET_QUOTES_SUCCESS:
      state = {
        ...state,
        quotes: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        quote_order: false,
        loading: false,
        error: false,
      };
      break;
    case GET_QUOTE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_QUOTE_SUCCESS:
      state = {
        ...state,
        quote: action.payload.data,
        quote_order: false,
        loading: false,
        error: false,
      };
      break;
    case CREATE_QUOTE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_QUOTE_SUCCESS:
      state = {
        ...state,
        quote_order: false,
        loading: false,
        error: false,
      };
      break;
    case UPDATE_QUOTE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_QUOTE_SUCCESS:
      state = {
        ...state,
        quote_order: false,
        loading: false,
        error: false,
      };
      break;
    case DELETE_QUOTE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_QUOTE_SUCCESS:
      state = {
        ...state,
        quotes:
          state.quotes === undefined || state.quotes === null
            ? []
            : state.quotes.filter((e) => e.id !== action.payload.data.id),
        loading: false,
        error: false,
      };
      break;
    case GENERATE_QUOTE_ZIP:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GENERATE_QUOTE_ZIP_SUCCESS:
      state = {
        ...state,
        quote_zip_file: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case SEND_QUOTE_EMAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SEND_QUOTE_EMAILS_SUCCESS:
      state = {
        ...state,
        list_success: action.payload.data.success,
        list_failed: action.payload.data.failed,
        list_already_sent: action.payload.data.already_send,
        loading: false,
        error: false,
      };
      break;
    case SHOW_QUOTE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SHOW_QUOTE_SUCCESS:
      console.log("PAYLOAD", action.payload);
      state = {
        ...state,
        quote_order: action.payload.data.order,
        loading: false,
        error: false,
      };
      break;
    case QUOTES_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case MIGRATE_ORDER_FROM_QUOTE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case MIGRATE_ORDER_FROM_QUOTE_SUCCESS:
      let quotes = state.quotes || [];
      let quote = quotes.find((e) => e.order.id === action.payload.data.order.id)
      quote.order.project = action.payload.data
      quote.projects?.push(action.payload.data)
      state = {
        ...state,
        quotes,
        loading: false,
        error: false,
        success: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default quotes;
