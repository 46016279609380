import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import ProductForm from "./FormComponent/ProductForm";

// Validation Schema
import { createValidationSchema } from "./FormValidationSchemas/createValidationSchema";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import { createProduct, productApiError, getCategories, getVats } from "../../store/actions";

const ProductAdd = (props) => {

  useEffect(() => {
    props.getCategories.call();
  }, [props.getCategories]);

  useEffect(() => {
    props.getVats.call();
  }, [props.getVats]);

  const initValues = {
    title: "",
    quote_description: "",
    commercial_name: "",
    brand: "",
    description: "",
    reference: "",
    category: "",
    sub_category: "",
    vat: "",
    price: "",
    price_ttc: "",
    promotional_offer: "",
    promotional_offer_ttc: "",
    eco_tax: "",
    image: "",
    has_warm_water: "",
    phase: "",
    workforces_ids: []
  };

  const handleSubmit = (values) => {
    props.createProduct(
      {
        title: values.title,
        quote_description: values.quote_description,
        commercial_name: values.commercial_name,
        brand: values.brand,
        description: values.description,
        reference: values.reference,
        category: values.category,
        sub_category: values.sub_category,
        vat: values.vat,
        price: values.price,
        price_ttc: values.price_ttc,
        promotional_offer: values.promotional_offer,
        promotional_offer_ttc: values.promotional_offer_ttc,
        eco_tax: values.eco_tax,
        image: values.image,
        has_warm_water: values.has_warm_water,
        phase: values.phase,
        workforces_ids: values.workforces_ids,
      },
      props.history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Produits" breadcrumbItem="Créer une produit" />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Créer un produit</CardTitle>
                {props.loading ? null : (
                  <ProductForm
                    initValues={initValues}
                    validationSchema={createValidationSchema}
                    handleSubmit={handleSubmit}
                    categories={props.categories}
                    vats={props.vats}
                    product={""}
                    error={props.error}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { loading, error } = state.Products;
  const { categories } = state.Categories;
  const { vats } = state.Vats;
  return { loading, error, categories, vats };
};

export default withRouter(
  connect(mapStatetoProps, {
    createProduct,
    getCategories,
    getVats,
    productApiError,
  })(ProductAdd)
);
