import { Formik } from 'formik';
import moment from "moment";
import { useState } from 'react';
import DatePicker from "react-datepicker";
import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { V2 } from "../../../api";
import AsyncAutoComplete from "../../../components/Common/AsyncAutoComplete2";
import LoaderLayoutPulse from '../../../components/Common/LoaderLayoutPulse';
import { promiseBankAccountsOptions } from '../../../helpers/autocomplete_promise_options_api_v2';

interface FormValues {
  date: Date | null,
  file: File | null,
  bank_account_id: number | null
}

interface comptaExportModalProps {
  onAbort: () => void,
}

export default function CheckPaymentsModal({ onAbort }: comptaExportModalProps) {
  const [formValid, setFormValid] = useState(false);

  function onValidate(values: FormValues) {
    setFormValid(values.date != null)
  }

  async function onSubmit(values: FormValues) {
    let response = await V2.check_payments.create({
      ...values,
      date: values.date?.toISOString()
    });
    window.open(window.URL.createObjectURL(response.data));
  }

  return (
    <Modal isOpen={true} toggle={onAbort} className={""}>
      <ModalHeader toggle={onAbort}>Vérifier les paiements</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={
            {
              date: null,
              file: null,
              bank_account_id: null,
            } as FormValues
          }
          onSubmit={onSubmit}
          validateOnChange={true}
          validate={onValidate}
        >
          {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
            <form className={"w-100 d-flex flex-column justify-content-center align-items-start"}>
              <Row className={"w-100"}>
                <Col xs={"8"}>
                  <Label for='bank_account_id' style={{ color: "#183254" }} className={"mt-4"}><b>Banque :</b></Label>
                  <AsyncAutoComplete
                    name='bank_account_id'
                    id='bank_account_id'
                    noOptionsMessage={() => "Aucune banque disponible"}
                    placeholder="Sélectionner"
                    loadOptions={promiseBankAccountsOptions}
                  />
                  <Label for="date" style={{ color: "#183254" }} className={"mt-4"}><b>Mois :</b></Label>
                  <DatePicker
                    className="form-control button-without-style"
                    name="date"
                    selected={values.date ? new Date(values.date) : null}
                    onChange={v => setFieldValue("date", v)}
                    isClearable
                    showMonthYearPicker
                    placeholderText={moment(new Date()).format('MM/yyyy')}
                    dateFormat={"MM/yyyy"}
                  />
                  <Label for="file" style={{ color: "#183254" }} className={"mt-4"}><b>Fichier à vérifier :</b></Label>
                  <input id="file" name="file" type="file" onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                  }} />
                </Col>
              </Row>
              <div className={"d-flex justify-content-end mx-n3 px-3 mt-4 border-top"} style={{ width: "calc(100% + 2rem)" }}>
                <Button type="button" className={"btn btn-secondary mt-2 mr-3"} style={{ background: "#96988F", borderColor: "#96988F" }} onClick={onAbort}>Annuler</Button>
                <Button type="button" className={"btn btn-green mt-2"} disabled={!formValid} onClick={() => handleSubmit(values as any)}>Valider</Button>
              </div>
              {isSubmitting && <div className={"d-flex justify-content-center w-100"}><LoaderLayoutPulse/></div>}
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
}
