import {useRef, useEffect, useCallback} from "react";
import ReactEcharts from 'echarts-for-react';

import {
  Card,
  CardBody,
  Media,
} from "reactstrap";


export const FAMILY_REPARTION_CARD = "category"
export const CATEGORY_REPARTION_CARD = "sub_category"
export const PRODUCT_REPARTION_CARD = "product"


const StatsRepartitionCard = ({title, categories = ['N', 'N-1'], datas, type, loading, onEvents, selected}) => {

  const refCharts = useRef(null);

  const getOption = useCallback((categories, datas) => {
    return ({
      tooltip: {
        trigger: 'item',
        axisPointer: {            // Use axis to trigger tooltip
          type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: (params) => {
          let data = params.data
          //console.log("params : ", params)
          return params.seriesName + " <br/> montant : " + data.value + "€ <br/> part : " + data.percent + "%"
        }
      },
      legend: {
        // data: ['Direct', 'Mail Ad', 'Affiliate Ad', 'Video Ad', 'Search Engine']
        selected: selected
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        show: true,
        axisLine: {
          show: true
        },
        axisTick: {
          show: false
        },
        axisSplitLine: {
          show: false
        }
      },
      yAxis: {
        type: 'category',
        data: categories,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        }
      },
      label: {
        show: true,

        formatter: (params) => {
          let data = params.data
          return data.percent > 5 ? data.value + "\n (" + data.percent + "%)" : ""
        }
      },
      series: datas.map(item => {
        return (
          {
            name: item[type][type === PRODUCT_REPARTION_CARD ? "commercial_name" : "name"],
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },

            dimension: ["value", "percent"],
            data: [{
              value: item.current.total_ht.toFixed(2),
              percent: item.current.percent.toFixed(2)
            }, {value: item.previous.total_ht.toFixed(2), percent: item.previous.percent.toFixed(2)}]
          })
      })
    });
  }, [selected, type]);

  useEffect(() => {
    if (refCharts.current) {
      refCharts.current.getEchartsInstance().clear();
      refCharts.current.getEchartsInstance().setOption(getOption(categories, datas));
    }
  }, [datas, categories, getOption]);


  /*const onEvents = {
    'click': (event) => console.log(event),
    'legendselectchanged': (event) => console.log(event)
  }*/

  return (
    datas && !loading ?
      <>
        <Card className="mini-stats-wid">
          <CardBody>
            <Media>
              <Media body>
                <p className="text-muted font-weight-medium text-center">
                  {title}
                </p>
                <hr/>
                <div id="doughnut-chart" className="e-chart">
                  {/* <Doughnut /> */}
                  <ReactEcharts
                    option={getOption(categories, datas)}
                    ref={refCharts}
                    lazyUpdate={true}
                    onEvents={onEvents}
                  />
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </>
      : null
  )
}


export default StatsRepartitionCard