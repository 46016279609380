import {
  GET_SCALES,
  GET_SCALES_SUCCESS,
  GET_SCALE,
  GET_SCALE_SUCCESS,
  CREATE_SCALE,
  CREATE_SCALE_SUCCESS,
  UPDATE_SCALE,
  UPDATE_SCALE_SUCCESS,
  DELETE_SCALE,
  DELETE_SCALE_SUCCESS,
  SCALES_API_ERROR,
} from "./actionTypes";

export const getScales = (query) => {
  return {
    type: GET_SCALES,
    payload: { query },
  };
};

export const getScalesSuccess = (scales) => {
  return {
    type: GET_SCALES_SUCCESS,
    payload: scales,
  };
};

export const getScale = (id) => {
  return {
    type: GET_SCALE,
    payload: { id },
  };
};

export const getScaleSuccess = (scale) => {
  return {
    type: GET_SCALE_SUCCESS,
    payload: scale,
  };
};

export const createScale = (scale, history) => {
  return {
    type: CREATE_SCALE,
    payload: { scale, history },
  };
};

export const createScaleSuccess = (scale) => {
  return {
    type: CREATE_SCALE_SUCCESS,
    payload: scale,
  };
};

export const updateScale = (id, scale, history) => {
  return {
    type: UPDATE_SCALE,
    payload: { id, scale, history },
  };
};

export const updateScaleSuccess = (scale) => {
  return {
    type: UPDATE_SCALE_SUCCESS,
    payload: scale,
  };
};

export const deleteScale = (id, history) => {
  return {
    type: DELETE_SCALE,
    payload: { id, history },
  };
};

export const deleteScaleSuccess = (scale) => {
  return {
    type: DELETE_SCALE_SUCCESS,
    payload: scale,
  };
};

export const scaleApiError = (error) => {
  return {
    type: SCALES_API_ERROR,
    payload: error,
  };
};
