import {useContext, useState} from 'react';

import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import DateComponent from "./DateComponent";

import {
    promiseProductsOptionsItemsForm,
    promiseItemsSavedOptions,
    promiseWorkforceOptions
} from "../../../helpers/autocomplete_promise_options";

import {computeTaxesfullPrice, computeTaxeslessPrice} from "./AddItemComponent";
import VatsContext from "../Context/VatsContext";

import {Row, Col} from 'reactstrap';
import {typeForm} from "../../../helpers/quotes_invoices_helper";

/**
 * @description form for the adding of free fields rendered in the table.
 * Depending of the type of item.
 *
 * @param type type of product/item the user want to add
 * @param {function} handleSubmit handle the item created. The item value is passed as argument
 * @param {array} unitsOptions we put unitOptions as props so that they coincide with the table unit selector
 */
const FreeFieldsComponent = ({
                                 type,
                                 handleSubmit,
                                 unitsOptions,
    errors,
    formType,
                             }) => {

    // Get vats context to display in the selectors
    const VATS = useContext(VatsContext);

    const computePromise = () => {
        if (type === "PRODUCT")
            return (promiseProductsOptionsItemsForm);
        else if (type === "SAVED_ITEM")
            return (promiseItemsSavedOptions);
    }

    // Initial values of the item forms
    const [item, setItem] = useState({
        quote_description: "",
        description: "",
        price: null,
        price_ttc: null,
        vat: VATS[VATS.length - 1],
        workforce: [],
        quantity: (type === "TIMELINED_ITEM" ? 12 : null),
        quantity_unit: "U",
        timeline_begin_at: (type === "TIMELINED_ITEM" ? new Date() : null),
    });

    /**
     * handling change of the fields using one state
     * @param name name of the field
     * @param value value of the field
     */
    const handleChange = (name, value) => {
        setItem(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    // Compute the taxesfull price using augmentation equation when taxeless price and/or vat are given
    const computePriceTTC = (price, vatValue) => {
        handleChange("price_ttc", computeTaxesfullPrice(price, vatValue));
    }

    // Compute the taxesless price
    const computePrice = (price_ttc, vatValue) => {
        handleChange("price", computeTaxeslessPrice(price_ttc, vatValue));
    }

    // Compute the return of the workforce selector to fit in the item state.
    const computeWorkforce = (multiSelectArr) => {
        if (multiSelectArr !== null) {
            const arr = []
            multiSelectArr.forEach((e) => {
                e.value.quantity = 1;
                e.value.unit = "u";
                arr.push(e.value);
            });
            return arr;
        } else {
            return []
        }
    }

    return (
        <div className="sub-form-container ">
            {/* product selection present on type = PRODUCT and type = SAVED_ITEM */}
            <div>
                {
                    (type === "PRODUCT" || type === "SAVED_ITEM") ?
                        <SelectComponent
                            label={type === "PRODUCT" ? "Produits" : "Articles sauvegardés"}
                            placeholder={"Choisir un " + (type === "PRODUCT" ? "produit" : "article sauvegardé")}
                            isAsync={true}
                            options={computePromise()}
                            handleChange={(target => {
                                setItem(prev => (
                                    {...prev,
                                        ...target.value,
                                        price: formType === typeForm.refund ? target.value.price * -1 : target.value.price,
                                        price_ttc: formType === typeForm.refund ? target.value.price_ttc * -1 : target.value.price_ttc,
                                        quantity: target.value.quantity ? target.value.quantity : 1,
                                        timeline_begin_at: target.value.timeline_begin_at ? new Date() : null,
                                    }));
                            })}
                            classNameLabel="col-lg-2"
                            classNameSelector="col-lg-10"
                            className="row"
                        />
                        :
                        ""
                }
            </div>

            <Row className="form-group mb-4">
                <Col lg="2">
                    <label>Description</label>
                </Col>
                <Col lg="10">
                    <textarea
                        className="form-control"
                        rows="6"
                        placeholder="Entrer une description"
                        name="quote_description"
                        value={item.quote_description ? item.quote_description : item.description}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                    />
                </Col>

            </Row>

            {/* Common part composed by description, unit price taxeless, vat and unit price with all taxes */}
            <Row>
                {/* unit price taxeless */}
                <Col lg={{size: 4, offset: 2}}>
                    <InputComponent
                        placeholder="Entrer un prix (Exemples : 4 ou 9.90 ou -19.9 ect...)"
                        label="Prix unitaire HT"
                        value={item.price}
                        name="price"
                        handleChange={target => handleChange(target.name, target.value)}
                        handleBlur={target => computePriceTTC(target.value, item.vat.value)}
                        classNameInput="col-lg-12"
                        classNameLabel="col-lg-12 col-form-label"
                        error={errors.price}
                        showError={true}
                    />
                </Col>


                {/* VAT */}
                <Col lg="2">
                    <SelectComponent
                        label="TVA"
                        placeholder="Choisir une TVA"
                        isDisabled={(type === "PRODUCT")}
                        options={VATS.map((vat) => (
                            {
                                label: vat.name,
                                value: vat
                            }
                        ))}
                        // Select components allow {value, label} object
                        value={item.vat ? {value: item.vat.value, label: item.vat.name} : null}
                        name="vat"
                        handleChange={target => {
                            handleChange("vat", target);
                            console.log(target);
                            computePriceTTC(item.price, target.value);
                        }}
                        classNameSelector="col-lg-12"
                        classNameLabel="col-lg-12 col-form-label"
                        className="row"
                    />
                </Col>


                {/* unit price with taxes */}
                <Col lg="4">
                    <InputComponent
                        label="Prix unitaire TTC"
                        placeholder="Entrer un prix (Exemples : 4 ou 9.90 ou -19.9 ect...)"
                        value={item.price_ttc}
                        name="price_ttc"
                        handleChange={target => handleChange(target.name, target.value)}
                        handleBlur={target => computePrice(target.value, item.vat.value)}
                        classNameInput="col-lg-12"
                        classNameLabel="col-lg-12"
                        error={errors.price_ttc}
                        showError={true}
                    />
                </Col>

            </Row>
            <div>
                {/* starting timeline only with TIMELINED_ITEM */}
                {
                    (type === "TIMELINED_ITEM" || item.timeline_begin_at) ?
                        <DateComponent
                            label="Date de début de l'échéancier *"
                            placeholder="Choisir une date de début de l'échéancier"
                            value={item.timeline_begin_at ? new Date(item.timeline_begin_at) : new Date()}
                            handleChange={value => handleChange("timeline_begin_at", value)}
                            classNameLabel="col-lg-2"
                            classNameDate="col-lg-10"
                        />
                        :
                        ""
                }
            </div>
            {/* Quantity with unit, ecotaxe and workforce (blocked to 12 with the timelined item without unit) */}
            <div>
                {
                    (type !== "TIMELINED_ITEM" && !item.timeline_begin_at) ?
                        <>
                            <Row>
                                <Col lg="6">
                                    {/* Quantity */}
                                    <InputComponent
                                        label="Quantité"
                                        placeholder="Choisir une quantité"
                                        name="quantity"
                                        handleChange={target => handleChange(target.name, target.value)}
                                        value={item.quantity}
                                        classNameLabel="col-lg-4"
                                        classNameInput="col-lg-8"
                                        error={errors.quantity}
                                        showError={true}
                                    />
                                </Col>


                                <Col lg="6">
                                    {/* Unit */}
                                    <SelectComponent
                                        label="Unité"
                                        placeholder="Sélectionner"
                                        options={unitsOptions}
                                        value={{value: item.quantity_unit, label: item.quantity_unit}}
                                        name="quantity_unit"
                                        handleChange={value => handleChange("quantity_unit", value)}
                                        className="row"
                                        classNameLabel="col-lg-4"
                                        classNameSelector="col-lg-8"
                                    />
                                </Col>

                            </Row>
                            <div>
                                {/* Eco tax */}
                                <InputComponent
                                    label="Éco Taxe"
                                    placeholder="Entrer une éco contribution"
                                    name="eco_tax"
                                    value={item.eco_tax}
                                    handleChange={target => handleChange(target.name, target.value)}
                                    classNameInput="col-lg-10"
                                    classNameLabel="col-lg-2"
                                />
                            </div>
                            <div>
                                {/* Workforce */}
                                <SelectComponent
                                    label="Main d'œuvre"
                                    placeholder="Choisir une main d'œuvre"
                                    isAsync={true}
                                    options={promiseWorkforceOptions}
                                    isMulti={true}
                                    name="workforce"
                                    handleChange={target => handleChange("workforce", computeWorkforce(target))}
                                    classNameLabel="col-lg-2"
                                    classNameSelector="col-lg-10"
                                    className="row"
                                />
                            </div>
                        </>
                        :
                        <Row>
                            <Col lg="6">
                                <InputComponent
                                    label="Quantité"
                                    value={item.quantity}
                                    isDisabled={true}
                                    name="quantity"
                                    classNameLabel="col-lg-4"
                                    classNameInput="col-lg-8"
                                />
                            </Col>
                        </Row>
                }
            </div>

            {/* Save item only if it's a FREE_ITEM or a TIMELINED_ITEM*/}
            <div>
                {
                    (type === "FREE_ITEM" || type === "TIMELINED_ITEM") ?
                        <InputComponent
                            label="Sauvegarder l'article"
                            type="checkbox"
                            name="save"
                            handleChange={(value) => handleChange("save", value.checked)}
                            classNameInput="col-lg-10"
                            classNameLabel="col-lg-2"
                        />
                        :
                        ""
                }
            </div>

            {/* Sending button */}
            <div className="text-center">
                <button
                    className="btn btn-secondary"
                    onClick={(e) => {
                        e.preventDefault();

                        if(type === "PRODUCT" && item.id) {
                            item.product_id = item.id;
                        }else{
                            item.product_id = null;
                        }

                        handleSubmit(item);
                    }}
                >Ajouter l'article
                </button>

            </div>
        </div>
    );
}

export function FreeFieldProduct(props) {
    return (<FreeFieldsComponent type="PRODUCT" {...props}/>);
}

export function FreeFieldSavedItem(props) {
    return (<FreeFieldsComponent type="SAVED_ITEM" {...props}/>);
}

export function FreeFieldFreeItem(props) {
    return (<FreeFieldsComponent type="FREE_ITEM" {...props}/>);
}

export function FreeFieldTimelinedItem(props) {
    return (<FreeFieldsComponent type="TIMELINED_ITEM" {...props}/>);
}

