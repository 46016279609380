import FullCalendar, { EventInput } from "@fullcalendar/react";
import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Alert, Card,
  CardBody, Col, Container, NavItem,
  NavLink, Row, UncontrolledCollapse
} from "reactstrap";

import Select from "react-select";
import AsyncSelect from "react-select/async";

import BootstrapTheme from "@fullcalendar/bootstrap";

import frLocale from "@fullcalendar/core/locales/fr";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import loader
import GlobalLoader from "../../components/Common/GlobalLoader";

import {
  promiseAgenciesOptions,
  promiseUsersOptions
} from "../../helpers/autocomplete_promise_options";

// Helpers
import {
  getDateWithShortFormat, getTimeWithFormat
} from "../../helpers/helper";

import {
  changeReasons, reasonColors, reasons as v_reasons, setInterventionTypeIsShow
} from "../../helpers/planification_helper";

// actions
import {
  agendaItemsApiError, createAgendaItem, deleteAgendaItem, updateAgendaItem
} from "../../store/actions";

import { agendaItems } from "../../api/index";
import CommercialModals from "./Component/CommercialModals";
import InstallationModals from "./Component/InstallationModals";

import { parseCustomerName } from "../../helpers/customer_helper";

const Index = (props:any) => {
  const [currentCalendarDate, setCurrentCalendarDate] = useState(new Date());
  const [currentCalendarView, setCurrentCalendarView] = useState(
    "dayGridMonth"
  );
  const [calendarType, setCalendarType] = useState("installation");

  // Filter component
  const [user_id, setUser_id] = useState<any>(null);
  const [agency_id, setAgency_id] = useState<any>(null);
  const [technician_ids, setTechnician_ids] = useState<any[]>([]);
  const [reasons, setReasons] = useState(v_reasons);
  const [reasonsChange, setReasonsChange] = useState(false);
  const [status, setStatus] = useState<any>(null);

  // https://fr.reactjs.org/docs/refs-and-the-dom.html
  const calendarComponentRefInstallations = React.createRef<any>();
  const calendarComponentRefCommercials = React.createRef<any>();
  const installationModalsRef = React.createRef<any>();
  const commercialModalsRef = React.createRef<any>();

  function resetInstallationFilter() {
    setTechnician_ids([]);
  }

  function resetCommercialFilter() {
    setAgency_id(null);
    setUser_id(null);
    setStatus(null);
  }

  // Call by fullcalendar https://fullcalendar.io/docs/eventDrop
  function eventChange(eventInfo:any) {
    let extendedProps = eventInfo.event._def.extendedProps;
    let agendaItem = extendedProps.agendaItem;
    agendaItems
      .update(agendaItem.id, {
        client_id: agendaItem.client.id,
        project_id: agendaItem.project?.id,
        technicians: agendaItem.technicians.map(
          (technician:any, index:any) => technician.id
        ),
        reason: agendaItem.reason,
        comment: agendaItem.comment,
        date_begin: eventInfo.event.start,
        date_end: eventInfo.event.end,
        address: agendaItem.appointment_in_construction_site,
      })
      .then((response) => {
        for (const [key, value] of Object.entries(response.data)) {
          // update manually all keys to keep reference of the object
          agendaItem[key] = value;
        }
      })
      .catch(() => eventInfo.revert());
  }

  // Call by fullCalendar https://fullcalendar.io/docs/content-injection
  function renderEventContent(eventInfo:any) {
    let agendaItem = eventInfo.event._def.extendedProps.agendaItem;

    let hour = "";

    if (
      getDateWithShortFormat(agendaItem.date_begin) !==
      getDateWithShortFormat(agendaItem.date_end)
    ) {
      hour = `${getTimeWithFormat(agendaItem.date_begin)}`;
    } else {
      hour = `${getTimeWithFormat(agendaItem.date_begin)} à ${getTimeWithFormat(
        agendaItem.date_end
      )}`;
    }

    let address = agendaItem.appointment_in_construction_site
      ? agendaItem.client.address_construction_site +
        ", " +
        agendaItem.client.zip_code_construction_site +
        " " +
        agendaItem.client.city_construction_site
      : agendaItem.client.address +
        ", " +
        agendaItem.client.zip_code +
        " " +
        agendaItem.client.city;

    let technicians = agendaItem.technicians
      .map((technician:any) => `${technician.lastname} ${technician.firstname}`)
      .join(", ");

    if (currentCalendarView === "dayGridMonth") {
      return (
        <div className="fc-vertical-content little-font-size">
          <span>
            <b>{getTimeWithFormat(agendaItem.date_begin)}</b>&nbsp;
            <span>{eventInfo.event.title}</span>
          </span>
          <p className="technicians">
            <i>{technicians}</i>
          </p>
        </div>
      );
    } else if (currentCalendarView === "dayGridDay") {
      return (
        <div className="fc-vertical-content little-font-size">
          <span>
            <b>{getTimeWithFormat(agendaItem.date_begin)}</b>&nbsp;
            <span>{eventInfo.event.title}</span>
          </span>
          <p>{address}</p>
          <p className="technicians">
            <i>{technicians}</i>
          </p>
        </div>
      );
    } else {
      return (
        <div className="fc-vertical-content little-font-size">
          <p>
            <strong>{hour}</strong>
          </p>
          <span>{eventInfo.event.title}</span>
          <p>{address}</p>
          <p className="technicians">
            <i>{technicians}</i>
          </p>
        </div>
      );
    }
  }

  // Call by fullCalendar https://fullcalendar.io/docs/datesSet
  function datesSet(info:any) {
    let calendarApi =
      calendarType === "installation"
        ? calendarComponentRefInstallations?.current?.getApi()
        : calendarComponentRefCommercials?.current?.getApi();

    let calendar_date = calendarApi?.getDate();
    let calendar_view = calendarApi?.currentDataManager?.state?.currentViewType;

    if (calendar_date) setCurrentCalendarDate(calendar_date);
    if (calendar_view) setCurrentCalendarView(calendar_view);
  }

  // Call by fullCalendar https://fullcalendar.io/docs/events-function
  function getEventsAPI(info:any) {
    let filters:any = {
      pagination: false,
      date_begin: info.start,
      date_end: info.end,
    };
    filters.type =
      calendarType === "installation" ? "installation" : "commercial";

    // Because end_date is excluded because FullCalendar set the date_end to next day at midnight
    filters.date_end.setMinutes(filters.date_end.getMinutes() - 1);

    if (calendarType === "installation") {
      if (technician_ids.length !== 0)
        filters.technician_ids = technician_ids.map(
          (technician:any) => technician.value
        );
      if (reasons.length !== 0)
        filters.reasons = reasons.map((reason, index) => reason).join(',');
    } else {
      if (agency_id) filters.agency_id = agency_id.value;
      if (user_id) filters.user_id = user_id.value;
      if (status) filters.status = status.value;
    }

    return new Promise((resolve, reject) => {
      agendaItems
        .getAll(filters)
        .then((response) => {
          resolve(
            response.data.map((agendaItem:any) => ({
              id: agendaItem.id,
              title:
                agendaItem.client.client_type === "Particulier"
                  ? parseCustomerName(agendaItem.client)
                  : agendaItem.client.company_name,
              start: new Date(agendaItem.date_begin),
              end: new Date(agendaItem.date_end),
              className: `bg-${(reasonColors as unknown as any[])[agendaItem.reason]}`,
              extendedProps: {
                agendaItem: agendaItem,
              },
            }))
          );
        })
        .catch((error) => reject(error));
    });
  }

  // Call by fullCalendar https://fullcalendar.io/docs/loading
  function loading(isLoading:any) {
    // Use JS vanilla to avoid infinite setState or infinite API CALL by FullCalendar
    if (isLoading) {
      if (document.getElementById("fullcalendar_loader"))
        document
          .getElementById("fullcalendar_loader")!
          .classList.remove("d-none");
    } else {
      if (document.getElementById("fullcalendar_loader"))
        document.getElementById("fullcalendar_loader")!.classList.add("d-none");
    }
  }

  const openInstallationModal = (arg:any) => {
    if (installationModalsRef.current) {
      installationModalsRef.current.openAgendaEvent(arg);
    }
  };

  const openCommercialModal = (arg:any) => {
    if (commercialModalsRef.current) {
      commercialModalsRef.current.openAgendaEvent(arg);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="calendar-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Calendrier"
            breadcrumbItem="Prise de rendez-vous"
          />
          <div id="fullcalendar_loader" className="d-none">
            {" "}
            {/* Use for lazy loading of FullCalendar*/}
            <GlobalLoader />
          </div>
          {props.agendaItemLoading ? (
            <GlobalLoader />
          ) : (
            <Row>
              <Col id="calendar-container" lg="10">
                {props.error ? (
                  <Alert color="danger">{props.error}</Alert>
                ) : null}
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12">
                        <ul className="nav nav-tabs nav-tabs-custom mb-3">
                          <NavItem>
                            <NavLink
                              className={
                                calendarType === "installation" ? "active" : ""
                              }
                              onClick={() => {
                                setCalendarType("installation");
                                resetCommercialFilter();
                              }}
                            >
                              Calendrier des techniciens
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                calendarType === "commercial" ? "active" : ""
                              }
                              onClick={() => {
                                setCalendarType("commercial");
                                resetInstallationFilter();
                              }}
                            >
                              Calendrier des commerciaux
                            </NavLink>
                          </NavItem>
                        </ul>
                      </Col>
                    </Row>
                    {calendarType === "installation" ? (
                      <React.Fragment>
                        <Row>
                          <Col xs="12" className="d-flex justify-content-start">
                            <Link
                              to="#"
                              className="d-flex align-items-center text-theme-color-2 mb-3"
                              id="installation_filter"
                            >
                              <i className="fas fa-filter mr-1"></i> Filtrer
                            </Link>
                            <Link
                              to="#"
                              className="d-flex align-items-center text-theme-color mb-3 ml-3"
                              onClick={() => {
                                // Use JS vanilla to avoid infinite setState or infinite API CALL by FullCalendar
                                if (
                                  document
                                    .querySelector(
                                      "[toggler='#installation_filter']"
                                    )?.classList.contains("show")
                                )
                                  document
                                    .getElementById("installation_filter")?.click();
                                resetInstallationFilter();
                              }}
                            >
                              <i className="fas fa-times mr-1"></i> Vider les
                              filtres
                            </Link>
                          </Col>
                          <Col xs="12">
                            <UncontrolledCollapse toggler="#installation_filter">
                              <Row>
                                <Col md="6">
                                  <AsyncSelect
                                    className="w-100 calendar-filter-select mb-3"
                                    isSearchable={true}
                                    isMulti={true}
                                    noOptionsMessage={() =>
                                      "Pas de techniciens disponible"
                                    }
                                    placeholder="Choisir un ou plusieurs techniciens..."
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={() =>
                                      promiseUsersOptions("Technicien")
                                    }
                                    value={technician_ids}
                                    onChange={(technicians) => {
                                      setTechnician_ids(
                                        technicians
                                          ? technicians.map((technician) => {
                                              return {
                                                value: technician.value,
                                                label: technician.label,
                                              };
                                            })
                                          : []
                                      );
                                    }}
                                  />
                                </Col>
                              </Row>
                            </UncontrolledCollapse>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            {/* fullcalendar control */}
                            <FullCalendar
                              ref={calendarComponentRefInstallations}
                              initialView={currentCalendarView}
                              initialDate={currentCalendarDate}
                              plugins={[
                                BootstrapTheme,
                                dayGridPlugin,
                                interactionPlugin,
                              ]}
                              titleFormat={{
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }}
                              showNonCurrentDates={true}
                              fixedWeekCount={false}
                              locale={frLocale}
                              slotDuration={"00:30:00"}
                              handleWindowResize={true}
                              themeSystem="bootstrap"
                              headerToolbar={{
                                left: "prev,next today",
                                center: "title",
                                right: "dayGridMonth,dayGridWeek,dayGridDay",
                              }}
                              eventTimeFormat={{
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              }}
                              events={(info:any)=>getEventsAPI(info) as Promise<EventInput[]>}
                              datesSet={datesSet}
                              eventChange={eventChange}
                              editable={true}
                              droppable={true}
                              selectable={true}
                              weekNumbers={true}
                              dateClick={openInstallationModal}
                              eventClick={openInstallationModal}
                              eventContent={renderEventContent}
                              loading={loading}
                            />
                            <InstallationModals
                              wrappedComponentRef={installationModalsRef}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    ) : null}
                    {calendarType === "commercial" ? (
                      <React.Fragment>
                        <Row>
                          <Col xs="12" className="d-flex justify-content-start">
                            <Link
                              to="#"
                              className="d-flex align-items-center text-theme-color-2 mb-3"
                              id="commercial_filter"
                            >
                              <i className="fas fa-filter mr-1"></i> Filtrer
                            </Link>
                            <Link
                              to="#"
                              className="d-flex align-items-center text-theme-color mb-3 ml-3"
                              onClick={() => {
                                // Use JS vanilla to avoid infinite setState or infinite API CALL by FullCalendar
                                if (
                                  document
                                    .querySelector(
                                      "[toggler='#commercial_filter']"
                                    )
                                    .classList.contains("show")
                                )
                                  document
                                    .getElementById("commercial_filter")
                                    .click();
                                resetCommercialFilter();
                              }}
                            >
                              <i className="fas fa-times mr-1"></i> Vider les
                              filtres
                            </Link>
                          </Col>
                          <Col xs="12">
                            <UncontrolledCollapse toggler="#commercial_filter">
                              <Row>
                                <Col md="6">
                                  <AsyncSelect
                                    className="w-100 calendar-filter-select mb-3 filter-select-first-line"
                                    isSearchable={true}
                                    noOptionsMessage={() =>
                                      "Pas d'agence disponible"
                                    }
                                    placeholder="Choisir une agence..."
                                    value={agency_id}
                                    onChange={(agency) => {
                                      setAgency_id({
                                        value: agency.value,
                                        label: agency.label,
                                      });
                                    }}
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={() => promiseAgenciesOptions()}
                                  />
                                </Col>
                                <Col md="6">
                                  <AsyncSelect
                                    className="w-100 calendar-filter-select mb-3"
                                    isSearchable={true}
                                    noOptionsMessage={() =>
                                      "Pas de collaborateur disponible"
                                    }
                                    placeholder="Choisir un collaborateur..."
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={() =>
                                      promiseUsersOptions("Commercial")
                                    }
                                    value={user_id}
                                    onChange={(user) => {
                                      setUser_id({
                                        value: user.value,
                                        label: user.label,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <Select
                                    className="w-100 calendar-filter-select mb-3"
                                    isSearchable={true}
                                    noOptionsMessage={() =>
                                      "Pas de statut disponible"
                                    }
                                    placeholder="Choisir un statut..."
                                    options={[
                                      {
                                        value: "actifs",
                                        label: "Actif",
                                      },
                                      {
                                        value: "report",
                                        label: "Reporté",
                                      },
                                      {
                                        value: "cancel",
                                        label: "Annulé",
                                      },
                                    ]}
                                    value={status}
                                    onChange={(status) => {
                                      setStatus({
                                        value: status.value,
                                        label: status.label,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </UncontrolledCollapse>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <Row>
                              <Col xs="12">
                                {/* fullcalendar control */}
                                <FullCalendar
                                  ref={calendarComponentRefCommercials}
                                  initialView={currentCalendarView}
                                  initialDate={currentCalendarDate}
                                  plugins={[
                                    BootstrapTheme,
                                    dayGridPlugin,
                                    interactionPlugin,
                                  ]}
                                  titleFormat={{
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }}
                                  showNonCurrentDates={true}
                                  fixedWeekCount={false}
                                  locale={frLocale}
                                  slotDuration={"00:30:00"}
                                  handleWindowResize={true}
                                  themeSystem="bootstrap"
                                  headerToolbar={{
                                    left: "prev,next today",
                                    center: "title",
                                    right:
                                      "dayGridMonth,dayGridWeek,dayGridDay",
                                  }}
                                  eventTimeFormat={{
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: false,
                                  }}
                                  events={getEventsAPI}
                                  datesSet={datesSet}
                                  eventChange={eventChange}
                                  editable={true}
                                  droppable={true}
                                  selectable={true}
                                  weekNumbers={true}
                                  eventClick={openCommercialModal}
                                  eventContent={renderEventContent}
                                  loading={loading}
                                />

                                <CommercialModals
                                  wrappedComponentRef={commercialModalsRef}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
              {calendarType === "installation" ? (
                <>
                  <Col id="reasons-legend" lg="2">
                    <p
                      className="btn p-0 mb-0"
                      onClick={() => setInterventionTypeIsShow(false)}
                    >
                      <i className="fas fa-times mr-1"></i>
                      Masquer la légende
                    </p>
                    <div id="external-events" className="mt-3">
                      <p className="mb-4 font-weight-bold">
                        Type d'intervention
                      </p>
                      {Object.keys(reasonColors).map((key,i) => (
                        <Link
                          key={"reason_coloe_kudzugi_"+i}
                          to="#"
                          className="d-flex justify-content-start align-items-center mb-3"
                          onClick={() => changeReasons(key, reasons, setReasons, reasonsChange, setReasonsChange)}
                        >
                          <span
                            className={`calendar-caption-circle ${
                              reasons.includes(key)
                                ? `bg-${reasonColors[key]}`
                                : ""
                            } border-${reasonColors[key]} mr-2`}
                          ></span>{" "}
                          {key}
                        </Link>
                      ))}
                    </div>
                  </Col>
                  <Col
                    id="reasons-legend-show"
                    className="info-bar btn d-none"
                    onClick={() => setInterventionTypeIsShow(true)}
                  >
                    <i className="bx bxs-left-arrow-alt"></i>
                  </Col>
                </>
              ) : null}
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { agendaItem, error } = state.AgendaItems;
  const agendaItemLoading = state.AgendaItems.loading;
  return {
    error,
    agendaItem,
    agendaItemLoading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    createAgendaItem,
    updateAgendaItem,
    deleteAgendaItem,
    agendaItemsApiError,
  })(Index)
);
