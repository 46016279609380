import React, {useState} from 'react';
import {Button, Col, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import { Formik } from 'formik';
import DatePicker from "react-datepicker";
import moment from "moment";
import {V2} from "../../../api";
import FormikAutocomplete from "../../../components/Common/AsyncAutoComplete2";
import {
  promiseBankAccountsOptionsWithAll
} from "../../../helpers/autocomplete_promise_options_api_v2";
import LoaderLayoutPulse from '../../../components/Common/LoaderLayoutPulse';

interface FormValues {
  date_begin: Date | null,
  date_end: Date | null,
  bank_account_id: string | null
}

interface PaymentsExportModalProps {
  onAbort: () => void,
}
export default function PaymentsExportModal({ onAbort }: PaymentsExportModalProps) {
  const [formValid, setFormValid] = useState(false);

  function onValidate(values: FormValues){
    setFormValid(values.date_end != null && values.date_begin != null && values.date_begin < values.date_end && values.bank_account_id != null )
  }
  async function onSubmit(values: FormValues){
    let query = { date_begin: values.date_begin, date_end: values.date_end } as FormValues;
    if (values.bank_account_id) query.bank_account_id = values.bank_account_id;
    let response = await V2.payments_exporter.get(query);
    window.open(window.URL.createObjectURL(response.data));
  }

  return (
    <Modal isOpen={true} toggle={onAbort} className={""}>
      <ModalHeader toggle={onAbort}>Exporter le suivi des paiements</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={
            {
              date_begin: null,
              date_end: null,
              bank_account_id: "all",
            } as FormValues
          }
          onSubmit={onSubmit}
          validateOnChange={true}
          validate={onValidate}
        >
          {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
            <form className={"w-100 d-flex flex-column justify-content-center align-items-start"}>
              <Row className={"w-100"}>
                <Col xs={"12"}>
                  <p>
                    Choisissez la période, et la/les banques correspondant au suivi des paiements à exporter.
                  </p>
                </Col>
                <Col xs={"8"}>
                  <Label for="value" style={{color:"#183254"}} className={"mt-4"}><b>Date de début :</b></Label>
                  <DatePicker
                    className="form-control button-without-style"
                    name="date_depot"
                    selected={values.date_begin?new Date(values.date_begin):null}
                    onChange={v => setFieldValue("date_begin",v)}
                    isClearable={true}
                    placeholderText={moment(new Date()).format('DD/MM/yyyy')}
                    dateFormat={"dd/MM/yyyy"}
                  />
                  <Label for="value" style={{color:"#183254"}} className={"mt-4"}><b>Date de fin :</b></Label>
                  <DatePicker
                    className="form-control button-without-style"
                    name="date_depot"
                    selected={values.date_end?new Date(values.date_end):null}
                    onChange={v => setFieldValue("date_end",v)}
                    isClearable={true}
                    placeholderText={moment(new Date()).format('DD/MM/yyyy')}
                    dateFormat={"dd/MM/yyyy"}
                  />
                  <Label for="value" style={{color:"#183254"}} className={"mt-4"}><b>Banque :</b></Label>
                  <FormikAutocomplete
                    loadOptions={promiseBankAccountsOptionsWithAll}
                    isSearchable={false}
                    multiple={false}
                    name={"bank_account_id"}
                  />
                </Col>
              </Row>
              <div className={"d-flex justify-content-end mx-n3 px-3 mt-4 border-top"} style={{width: "calc(100% + 2rem)"}}>
                <Button type="button" className={"btn btn-secondary mt-2 mr-3"} style={{background:"#96988F",borderColor:"#96988F"}} onClick={onAbort}>Annuler</Button>
                <Button type="button" className={"btn btn-green mt-2"} disabled={!formValid} onClick={() => handleSubmit(values as any)}>Valider</Button>
              </div>
              {isSubmitting && <div className={"d-flex justify-content-center w-100"}><LoaderLayoutPulse/></div>}
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
}
