import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_SCALES,
  GET_SCALE,
  CREATE_SCALE,
  UPDATE_SCALE,
  DELETE_SCALE,
} from "./actionTypes";
import {
  getScalesSuccess,
  getScaleSuccess,
  createScaleSuccess,
  updateScaleSuccess,
  deleteScaleSuccess,
  scaleApiError,
} from "./actions";

import { scales } from "../../api";

function* getScales({ payload: { query } }) {
  try {
    const response = yield call(scales.getAll, query);
    yield put(getScalesSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          scaleApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des barèmes"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(scaleApiError(error.response.data.message));
      }
    } else {
      yield put(scaleApiError(error.message));
    }
  }
}

function* getScale({ payload: { id } }) {
  try {
    const response = yield call(scales.getOne, id);
    yield put(getScaleSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          scaleApiError("Vous n'êtes pas autorisé à accéder à ce barème")
        );
      } else if (error.response.status === 404) {
        yield put(scaleApiError("Le barème n'a pas été trouvée"));
      } else if (error.response.data !== undefined) {
        yield put(scaleApiError(error.response.data.message));
      }
    } else {
      yield put(scaleApiError(error.message));
    }
  }
}

function* createScale({ payload: { scale, history } }) {
  try {
    const response = yield call(scales.create, scale);
    yield put(createScaleSuccess(response));
    history.push(`/financer/detail/${scale.financer_id}`);
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          scaleApiError("Vous n'êtes pas autorisé à créer un barème")
        );
      } else if (
        error.response.status === 400 &&
        error.response.data.errors.length > 0
      ) {
        if (
          error.response.data.errors[0].detail.name &&
          error.response.data.errors[0].detail.name[0] === "can't be blank"
        ) {
          yield put(scaleApiError("Le nom doit être présent"));
        }
      } else if (error.response.data !== undefined) {
        yield put(scaleApiError(error.response.data.message));
      }
    } else {
      yield put(scaleApiError(error.message));
    }
  }
}

function* updateScale({ payload: { id, scale, history } }) {
  try {
    const response = yield call(scales.update, id, scale);
    yield put(updateScaleSuccess(response));
    history.push(`/financer/${scale.financer_id}/scale/detail/${id}`);
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          scaleApiError("Vous n'êtes pas autorisé à modifier ce barème")
        );
      } else if (error.response.status === 404) {
        yield put(scaleApiError("Le barème n'a pas été trouvée"));
      } else if (
        error.response.status === 400 &&
        error.response.data.errors.length > 0
      ) {
        if (
          error.response.data.errors[0].detail.name &&
          error.response.data.errors[0].detail.name[0] === "can't be blank"
        ) {
          yield put(scaleApiError("Le nom doit être présent"));
        }
      } else if (error.response.data !== undefined) {
        yield put(scaleApiError(error.response.data.message));
      }
    } else {
      yield put(scaleApiError(error.message));
    }
  }
}

function* deleteScale({ payload: { id, history } }) {
  try {
    const response = yield call(scales.delete, id);
    yield put(deleteScaleSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        scaleApiError("Vous n'êtes pas autorisé à supprimer ce barème")
      );
    } else if (error.response.data !== undefined) {
      yield put(scaleApiError(error.response.data.message));
    } else {
      yield put(scaleApiError(error.message));
    }
  }
}

export function* watchGetScales() {
  yield takeEvery(GET_SCALES, getScales);
}

export function* watchGetScale() {
  yield takeEvery(GET_SCALE, getScale);
}

export function* watchCreateScale() {
  yield takeEvery(CREATE_SCALE, createScale);
}

export function* watchUpdateScale() {
  yield takeEvery(UPDATE_SCALE, updateScale);
}

export function* watchDeleteScale() {
  yield takeEvery(DELETE_SCALE, deleteScale);
}

function* scalesSaga() {
  yield all([
    fork(watchGetScales),
    fork(watchGetScale),
    fork(watchCreateScale),
    fork(watchUpdateScale),
    fork(watchDeleteScale),
  ]);
}

export default scalesSaga;
