import {
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_PAYMENTS_DETAILS,
  GET_PAYMENTS_DETAILS_SUCCESS,
  CREATE_INVOICE,
  CREATE_INVOICE_SUCCESS,
  UPDATE_INVOICE,
  UPDATE_INVOICE_SUCCESS,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  VALIDATE_INVOICE,
  VALIDATE_INVOICE_SUCCESS,
  GENERATE_ZIP,
  GENERATE_ZIP_SUCCESS,
  SEND_INVOICE_EMAILS,
  SEND_INVOICE_EMAILS_SUCCESS,
  INVOICES_API_ERROR,
  MIGRATE_ORDER_FROM_INVOICE,
  MIGRATE_ORDER_FROM_INVOICE_SUCCESS
} from "./actionTypes";

const initialState = {
  invoices: [],
  invoice: false,
  error: false,
  loading: false,
  zip_file: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
  last_invoice_validate: false,
  list_success: [],
  list_already_sent: [],
  list_failed: [],
};

const invoices = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICES:
      state = {
        ...state,
        loading: true,
        list_success: [],
        list_already_sent: [],
        list_failed: [],
      };
      break;
    case GET_INVOICES_SUCCESS:
      state = {
        ...state,
        invoices: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        last_invoice_validate: action.payload.data.last_invoice_validate,
        invoice: false,
        loading: false,
        error: false,
      };
      break;
    case GET_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_INVOICE_SUCCESS:
      state = {
        ...state,
        invoice: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case GET_PAYMENTS_DETAILS:
      state = {
        ...state,
        loading: true,
      }
      break;
    case GET_PAYMENTS_DETAILS_SUCCESS:
      state = {
        ...state,
        invoice: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case CREATE_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_INVOICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case UPDATE_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_INVOICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case DELETE_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_INVOICE_SUCCESS:
      state = {
        ...state,
        invoices:
          state.invoices === undefined || state.invoices === null
            ? []
            : state.invoices.filter((e) => e.id !== action.payload.data.id),
        loading: false,
        error: false,
      };
      break;
    case VALIDATE_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VALIDATE_INVOICE_SUCCESS:
      state = {
        ...state,
        invoices:
          state.invoices === undefined || state.invoices === null
            ? []
            : state.invoices.map((e) =>
              e.id !== action.payload.data.id ? e : action.payload.data
            ),
        loading: false,
        error: false,
      };
      break;
    case GENERATE_ZIP:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GENERATE_ZIP_SUCCESS:
      state = {
        ...state,
        zip_file: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case SEND_INVOICE_EMAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SEND_INVOICE_EMAILS_SUCCESS:
      state = {
        ...state,
        list_success: action.payload.data.success,
        list_failed: action.payload.data.failed,
        list_already_sent: action.payload.data.already_send,
        loading: false,
        error: false,
      };
      break;
    case INVOICES_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case MIGRATE_ORDER_FROM_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case MIGRATE_ORDER_FROM_INVOICE_SUCCESS:
      let invoices = state.invoices || [];
      let invoicesFiltered = invoices.filter((e) => e.order.id === action.payload.data.order.id)
      invoicesFiltered.forEach((invoice) => {
        invoice.order.project_id = action.payload.data.id
      })
      state = {
        ...state,
        invoices,
        loading: false,
        error: false,
        success: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default invoices;
