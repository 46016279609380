import InterventionFolder from "../../../../models/intervention_folder";
import {Col, Row} from "reactstrap";
import moment from "moment";

export default function InterventionDetailLendTab({interventionFolder}:{interventionFolder:InterventionFolder}){
  console.log(interventionFolder)
  return (
    <Row className="pr-2 pl-2 d-flex font-size-14 text-theme-color">
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Date de pret : </span>
        <span className="font-weight-light">{interventionFolder?.lend?.lend_begin_at ? moment(interventionFolder?.lend?.lend_begin_at).format("DD/MM/YY") : "Non renseigné"}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Date de retour : </span>
        <span className="font-weight-light">{interventionFolder?.lend?.lend_end_at ? moment(interventionFolder?.lend?.lend_end_at).format("DD/MM/YY") : "Non renseigné"}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Demande de pret : </span>
        <span className="font-weight-light">
          {interventionFolder.lend?.file?(
            <a href={`${process.env.REACT_APP_API_URL}/files/${interventionFolder.lend.file}`}
               target={'_blank'} rel="noreferrer" className="d-flex align-items-center mt-2">
              <div className="avatar-sm">
              <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                <i className="bx bxs-file-doc"/>
              </span>
              </div>
              <h5 className="font-size-14 mb-0 text-dark ml-5 mr-3">
                Demande de pret
              </h5>
              <i className='bx bx-download'/>
            </a>
          ): "non disponible"}
        </span>
      </Col>
    </Row>
  );
}