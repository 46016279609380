import {useEffect, useState} from 'react';

// Components
import {Card, CardBody, CardTitle, Col, Container, NavItem, NavLink, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {withRouter} from "react-router-dom";

// Redux
import {connect} from "react-redux";
import {deleteSubscription, getSubscription} from "../../store/subscriptions/actions";
import SubscriptionInformationsComponent from "./Components/SubscriptionInformationsComponent";
import SubscriptionDetailActions from "./Components/SubscriptionDetailActions";
import SubscriptionInvoicesComponent from "./Components/SubscriptionInvoicesComponent";
import GlobalLoader from "../../components/Common/GlobalLoader";
import FlashMessage from '../../components/Common/FlashMessage';

const TABS = {
  INFORMATION: 'information',
  INVOICES: 'invoices'
}

const SubscriptionsDetail = ({
  getSubscription,
  subscription,
  deleteSubscription,
  history
  }) => {

  const [activeTab, setActiveTab] = useState(TABS.INFORMATION)

  useEffect(() => {
    console.log("test")
    const splitedPath = window.location.pathname.split('/');
    if(splitedPath.length === 4)
      getSubscription(splitedPath[3]);
  }, [getSubscription])

  return (
    <div className="page-content">
      <Container fluid className="invoices-container">
        <Breadcrumbs title="Listes" breadcrumbItem="Listes" breadcrumbTitle='Abonnements'/>
        <Card>
          <FlashMessage/>
          { !subscription ? <GlobalLoader /> :
            <CardBody>
              {/* Card title */}
              <CardTitle className="mb-3">
                <Row className="pr-2 pl-2 d-flex justify-content-between">
                  <Col>Abonnement de {subscription.client_name}</Col>
                </Row>
              </CardTitle>
              <ul className="nav nav-tabs nav-tabs-custom mb-3">
                <NavItem>
                  <NavLink
                    className={activeTab === TABS.INFORMATION ? "active nav-tabs-custom" : "nav-tabs-custom"}
                    onClick={() => setActiveTab(TABS.INFORMATION)}
                  >
                    Informations
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === TABS.INVOICES ? "active" : ""}
                    onClick={() => setActiveTab(TABS.INVOICES)}
                  >
                    Factures
                  </NavLink>
                </NavItem>
              </ul>
              { activeTab === TABS.INFORMATION ?
                <SubscriptionInformationsComponent subscription={subscription}/> :
                <SubscriptionInvoicesComponent subscription={subscription}/>
              }
            </CardBody>
          }
        </Card>
        { activeTab === TABS.INFORMATION && subscription ?
          <SubscriptionDetailActions
            subscription={subscription}
            handleDelete={() => deleteSubscription(subscription.id, history)}
          /> : ''}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    subscription,
    loading,
    error,
    pagination,
  } = state.Subscriptions

  return {
    subscription,
    loading,
    error,
    pagination
  }
}

export default withRouter(
  connect(mapStateToProps, {
    getSubscription,
    deleteSubscription
  })(SubscriptionsDetail)
);