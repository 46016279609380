import React from "react";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

// actions
import {
  getProject,
  updateProject,
  getGenerateInvoice, updateSubscription,
} from "../../../store/actions";
import MaintenanceCard from "./MaintenanceCard";
import {subscriptions} from "../../../api";

const MaintenanceContractCard = (props) => {

  /**
   * Met à jour le projet avec les nouvelles données
   */
  const updateChanges = (id, state, subscription) => {

    subscriptions.update(id, {
      abonnement_state_id: state,
      mandates_attributes: subscription.mandates,
      ...subscription
    })
      .then(r => props.getProject(props.project.id));
    props.setFlash_message(true);
  };

  return (
    <React.Fragment>
      { props.project.subscriptions.map((subscription => (
        <MaintenanceCard
          project={props.project}
          subscriptionStates={props.abonnements_states}
          subscription={subscription}
          updateChanges={(state) => updateChanges(subscription.id, state, subscription)}
        />
      )))}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { project } = state.Projects;

  return { project };
};

export default withRouter(
  connect(mapStatetoProps, {
    getProject,
    updateProject,
    getGenerateInvoice,
    updateSubscription
  })(
    MaintenanceContractCard
  )
);
