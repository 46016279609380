import React, { useState } from "react";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { projectIsEditable } from "../../../helpers/project_helper";
import OfferCode from "../../../models/offer_code";
import Project from "../../../models/project";
// actions
import { updateProject } from "../../../store/actions";
import ValidationModal from "../../Quotes/Modals/ValidationModal";
import OfferCodeModal from "./Modals/OfferCodeModal";

interface OfferCodeCardProps {
  project: Project,
  offer_code: OfferCode | undefined | null,
  updateProject: any,
  setFlash_message: any
}

const OfferCodeCard = ({
  offer_code,
  project,
  updateProject
}: OfferCodeCardProps) => {
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const editable = project.is_editable !== undefined ? project.is_editable : projectIsEditable(project);

  const deleteOfferCode = () => {
    setModalDelete(false);
    updateProject(project.id, {
      offer_code: null,
      offer_id: null,
    })
  }

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-3 d-flex justify-content-between">
          <div>Promotion</div>
          {editable && offer_code && <i
            onClick={() => setModalDelete(true)}
            data-toggle="tooltip"
            data-placement="top"
            title={'Supprimer la promotion'}
            className={'btn-icon-delete fas fa-trash-alt'}
          />}
          {editable && !offer_code && <i
            onClick={() => setModalAdd(true)}
            data-toggle="tooltip"
            data-placement="top"
            title={'Ajouter une promotion'}
            className={'btn-icon-add bx bx-plus-circle mr-1'}
          />}
        </CardTitle>
        {!offer_code && <Col className='subscriptions-detail'>
          <Row>
            <span>Aucune promotion</span>
          </Row>
        </Col>}
        {offer_code && <Col className='subscriptions-detail'>
          <Row>
            <strong>Code: </strong>&nbsp;<span>{offer_code.code}</span>
          </Row>
          <Row>
            <strong>Nom de l'offre: </strong>&nbsp;<span>{offer_code.offer_name}</span>
          </Row>
          <Row>
            <strong>Montant: </strong>&nbsp;<span>{offer_code.amount!.toFixed(2)} €</span>
          </Row>
        </Col>}
      </CardBody>

      <ValidationModal
        isOpen={modalDelete}
        toggler={() => setModalDelete(!modalDelete)}
        title="Promotion - suppression"
        message={<div><h2>Attention</h2><p>Voulez vous supprimer la promotion ?</p></div>}
        btnValidText="Supprimer"
        btnBackText="Annuler"
        handleSubmit={() => deleteOfferCode()}
      />

      <OfferCodeModal
        isOpen={modalAdd}
        project={project}
        toggle={() => setModalAdd(!modalAdd)}
        updateProject={updateProject}
      />
    </Card>
  );
};

const mapStatetoProps = (state: any) => {
  const { project } = state.Projects;

  return { project };
};

export default withRouter(
  connect(mapStatetoProps, { updateProject, setFlash_message: undefined, offer_code: undefined })(OfferCodeCard)
);
