import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import { Link } from "react-router-dom";

import axios from "axios";

const GodChildren = (props) => {

    const columns = [
        {
            dataField: "lastname",
            text: "Nom Prénom",
            classes: "w-50",
            formatter: (cell, row) => (
                <React.Fragment>
                <h5 className="font-size-14 mb-1">
                    <Link
                    to={`/customer/detail/${row.id}`}
                    className="table-text-black"
                    >
                        {row.client_type === "Professionnel"
                          ? row.company_name
                          : row.civility + " " + row.lastname + " " + row.firstname}
                    </Link>
                </h5>
                    <p className="mb-0">{`${row.address}, ${row.zip_code} ${row.city}`}</p>
                </React.Fragment>
            )
        },
        {
            dataField: 'godfather_comment',
            text: 'Commentaire'
        },
        {
            dataField: "amount",
            text: "Montant",
            classes: "w-25",
            editor: {
                type: Type.TEXTAREA
            },
            validator: (newValue, row, column) => {
                if (isNaN(newValue)) {
                    return {
                        valid: false,
                        message: "Veuillez entrer un nombre correct"
                    }
                }
                return true;
            },
            formatter: (cell, row) => (
                <div>
                    {row.amount || 0}€
                </div>
            ),
        },
        {
            dataField: "is_paid",
            text: "Payé ?",
            classes: "w-25",
            editor: {
                type: Type.SELECT,
                options: [
                    {
                        value: true,
                        label: "Oui"
                    },
                    {
                        value: false,
                        label: "Non"
                    }
                ],
            },
            formatter: (cell, row) => <div>{row.is_paid === false || row.is_paid === "false" ? "Non" : "Oui"}</div>,
        },
      ];

    return (
        <BootstrapTable
            keyField="id"
            data={props.customers}
            columns={columns}
            cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                beforeSaveCell: (oldValue, newValue, row, column, done) => {
                    let data = {id: row.id};
                    data[column.dataField] = newValue; // Convert string to bool
                    axios.patch(`/clients/${row.id}`, data).then(() => done(true)).catch(() => done(false)); // To update only the field and not all entity
                    return {async: true};
                } 
            })}
        />
    );
}

export default GodChildren;