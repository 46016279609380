import React, { useState } from "react";
import { getDateWithShortFormat, getTimeWithFormat } from "../../../helpers/helper";

import { InfoBox, Marker } from "@react-google-maps/api";
import { parseCustomerAddress, parseCustomerName } from "../../../helpers/customer_helper";
import { MapItem } from "../../../models/map_item";
import { MarkerIcon } from "../../../helpers/planification_helper";
import Disponibility from "../../../models/disponibility";

export interface CustomMarker {
  entity: MapItem,
  position: { lat: number, lng: number },
  selected: boolean,
  icon: MarkerIcon
}

export interface CustomMarkerComponentProps {
  marker: CustomMarker,
  onShowModal: (marker: CustomMarker) => void
}

export default function CustomMarkerComponent ({
  marker,
  onShowModal
} : CustomMarkerComponentProps) {
  const [showInfoBox, setShowInfoBox] = useState(false);

  let disponibilities : Disponibility[] = marker.entity.disponibilities!;

  return (
    <>
      <Marker
        key={`map-marker-${marker.entity.id}`}
        position={new (window as any).google.maps.LatLng(marker.position.lat, marker.position.lng)}
        clickable
        animation={marker.selected ? (window as any).google.maps.Animation.DROP : ''}
        icon={{
          url: marker.icon.url,
          size: marker.icon.size && new (window as any).google.maps.Size(marker.icon.size.x, marker.icon.size.y),
          origin: marker.icon.origin && new (window as any).google.maps.Point(marker.icon.origin.x, marker.icon.origin.y),
          anchor: marker.icon.anchor && new (window as any).google.maps.Point(marker.icon.anchor.x, marker.icon.anchor.y),
          scaledSize: marker.icon.scaledSize && new (window as any).google.maps.Size(marker.icon.scaledSize.x, marker.icon.scaledSize.y),
        }}
        onMouseOver={() => setShowInfoBox(true) }
        onMouseOut={() => setShowInfoBox(false) }
        onClick={() => onShowModal(marker)}
      >
        {showInfoBox ? (
          <InfoBox
            onCloseClick={() => setShowInfoBox(false)}
            options={{ closeBoxURL: ``, enableEventPropagation: true }}
          >
            <div className="info-box-container">
              <div className="info-box">
                <p>
                  {`Client : ${parseCustomerName(marker.entity.client)}`}
                </p>
                <p className="info-box-small">
                  {parseCustomerAddress(marker.entity.client)}
                </p>
                <p className="mb-2">{`Disponibilité${
                  disponibilities.length > 1 ? "s" : ""
                } :`}</p>
                {disponibilities.length > 0 ? (
                  disponibilities.map((disponibility : any, index: number) => (
                    <p
                      className="info-box-small mb-1"
                      key={`dispo-${index}`}
                    >
                      {` - Le ${getDateWithShortFormat(
                        disponibility.begin_at
                      )} de ${getTimeWithFormat(
                        disponibility.begin_at
                      )} à ${getTimeWithFormat(disponibility.end_at)}`}
                    </p>
                  ))
                ) : (
                  <p className="info-box-small mb-1">
                    Aucunes disponibilités n'a été renseignées
                  </p>
                )}
              </div>
            </div>
          </InfoBox>
        ) : null}
      </Marker>
    </>
  );
}