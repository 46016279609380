import {
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_PAYMENTS_DETAILS,
  GET_PAYMENTS_DETAILS_SUCCESS,
  CREATE_INVOICE,
  CREATE_INVOICE_SUCCESS,
  UPDATE_INVOICE,
  UPDATE_INVOICE_SUCCESS,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  VALIDATE_INVOICE,
  VALIDATE_INVOICE_SUCCESS,
  GENERATE_ZIP,
  GENERATE_ZIP_SUCCESS,
  SEND_INVOICE_EMAILS,
  SEND_INVOICE_EMAILS_SUCCESS,
  INVOICES_API_ERROR,
  MIGRATE_ORDER_FROM_INVOICE,
  MIGRATE_ORDER_FROM_INVOICE_SUCCESS
} from "./actionTypes";

export const getInvoices = (query) => {
  return {
    type: GET_INVOICES,
    payload: { query },
  };
};

export const getInvoicesSuccess = (invoices) => {
  return {
    type: GET_INVOICES_SUCCESS,
    payload: invoices,
  };
};

export const getInvoice = (id) => {
  return {
    type: GET_INVOICE,
    payload: { id },
  };
};

export const getInvoiceSuccess = (invoices) => {
  return {
    type: GET_INVOICE_SUCCESS,
    payload: invoices,
  };
};

export const getPaymentsDetails = (id) => {
  return {
    type: GET_PAYMENTS_DETAILS,
    payload: {id}
  };
};

export const getPaymentsDetailsSuccess = (invoice) => {
  return {
    type: GET_PAYMENTS_DETAILS_SUCCESS,
    payload: invoice
  };
};

export const createInvoice = (invoices, history) => {
  return {
    type: CREATE_INVOICE,
    payload: { invoices, history },
  };
};

export const createInvoiceSuccess = (invoices) => {
  return {
    type: CREATE_INVOICE_SUCCESS,
    payload: invoices,
  };
};

export const updateInvoice = (id, invoice, history) => {
  return {
    type: UPDATE_INVOICE,
    payload: { id, invoice, history },
  };
};

export const updateInvoiceSuccess = (invoices) => {
  return {
    type: UPDATE_INVOICE_SUCCESS,
    payload: invoices,
  };
};

export const deleteInvoice = (id, history) => {
  return {
    type: DELETE_INVOICE,
    payload: { id, history },
  };
};

export const deleteInvoiceSuccess = (invoice) => {
  return {
    type: DELETE_INVOICE_SUCCESS,
    payload: invoice,
  };
};

export const validateInvoice = (id, history, custom_invoice_date = false) => {
  return {
    type: VALIDATE_INVOICE,
    payload: { id, history, custom_invoice_date },
  };
};

export const validateInvoiceSuccess = (invoice) => {
  return {
    type: VALIDATE_INVOICE_SUCCESS,
    payload: invoice,
  };
};

export const generateZip = (list) => {
  return {
    type: GENERATE_ZIP,
    payload: { list },
  };
};

export const generateZipSuccess = (zip_file) => {
  return {
    type: GENERATE_ZIP_SUCCESS,
    payload: zip_file,
  };
};

export const sendInvoiceEmails = (list, query) => {
  return {
    type: SEND_INVOICE_EMAILS,
    payload: { list, query },
  };
};

export const sendInvoiceEmailsSuccess = (lists) => {
  return {
    type: SEND_INVOICE_EMAILS_SUCCESS,
    payload: lists,
  };
};

export const invoicesApiError = (error) => {
  return {
    type: INVOICES_API_ERROR,
    payload: error,
  };
};

export const migrateOrderFromInvoice = (values) => {
  return {
    type: MIGRATE_ORDER_FROM_INVOICE,
    payload: { values },
  };
};

export const migrateOrderFromInvoiceSuccess = (project) => {
  return {
    type: MIGRATE_ORDER_FROM_INVOICE_SUCCESS,
    payload: project,
  };
};
