import InterventionFolder, { InterventionFolderTypesEnum } from "../../../../models/intervention_folder";
import {Badge, Col, Row} from "reactstrap";
import moment from "moment";
import {getStatusColor} from "../../../../models/requests_status";
import {getPriorityIcon} from "../../../../models/requests_priority";

export default function InterventionDetailInterventionTab({interventionFolder}:{interventionFolder:InterventionFolder}){
  return (
    <Row className="pr-2 pl-2 d-flex font-size-14 text-theme-color">
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Statut : </span>
        <span className="font-weight-light"><Badge style={{ background: getStatusColor(interventionFolder.status || {}) }}>{interventionFolder.status?.label}</Badge></span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Projet/dossier rattaché : </span>
        <span className="font-weight-light">{interventionFolder.project?.name_alias || "Aucun projet/dossier"}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Priorité : </span>
        <span className="font-weight-light">{getPriorityIcon(interventionFolder?.priority || {})} {interventionFolder.priority?.label}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Description{interventionFolder.intervention_folder_type === InterventionFolderTypesEnum.SAV ? ' (Code erreur) ' : ''} : </span>
        <span className="font-weight-light">{interventionFolder?.description || "Aucune description"}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Commentaire : </span>
        <span className="font-weight-light">{interventionFolder?.comment || "Aucun commentaire"}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Contrat d'entretien : </span>
        <span className="font-weight-light">{interventionFolder?.maintenance_contract?.formule_label || "Aucun contrat"}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">RDV d'intervention : </span>
        <span className="font-weight-light">{interventionFolder?.intervention_appointment ? moment(interventionFolder?.intervention_appointment).format("DD/MM/YY") : "Non renseigné"}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Bon d'intervention : </span>
        <span className="font-weight-light">
          {interventionFolder.intervention_document?(
            <a href={`${process.env.REACT_APP_API_URL}/files/${interventionFolder.intervention_document}`}
               target={'_blank'} rel="noreferrer" className="d-flex align-items-center mt-2">
              <div className="avatar-sm">
              <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                <i className="bx bxs-file-doc"/>
              </span>
              </div>
              <h5 className="font-size-14 mb-0 text-dark ml-5 mr-3">
                Bon d'intervention
              </h5>
              <i className='bx bx-download'/>
            </a>
          ): "non disponible"}
        </span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Disponibilités client : </span>
        <span className="font-weight-light">{interventionFolder.disponibility || "Non renseigné"}</span>
      </Col>
      {interventionFolder.technicians && interventionFolder.technicians.length > 0 && <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Techniciens : </span>
        <span className="font-weight-light">{interventionFolder.technicians.map(tech => `${tech.firstname} ${tech.lastname}`).join(', ')}</span>
      </Col>}
    </Row>
  );
}