import React, { useState } from "react";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import UploadImage from "../../../components/Common/UploadImage";
import { getBase64 } from '../../../helpers/helper';

const FinancerForm = (props) => {

  const [imagesList, setImagesList] = useState([]);

  const handleChangeImages = async (files, field, setFieldValue, values) => {
    setFieldValue(field, files[0])
    let images = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let content = await getBase64(file);
      images.push({
        url_path: content,
        name: file.name
      });
    }
    setImagesList(images);
  };

  return (
    <Formik
      initialValues={props.initValues}
      validationSchema={props.validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        props.handleSubmit(values);
      }}
      render={({ errors, touched, setFieldValue, values }) => (
        <Form>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <FormGroup className="mb-4" row>
                <Label htmlFor="name" className="col-form-label col-lg-2">
                  Nom {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    tag={Field}
                    invalid={errors.name && !!touched.name}
                    className="form-control"
                    placeholder="Entrer un nom"
                  />
                  <ErrorMessage name="name" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="description" className="col-form-label col-lg-2">
                  Description {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="description"
                    name="description"
                    type="text"
                    tag={Field}
                    invalid={errors.description && !!touched.description}
                    className="form-control"
                    placeholder="Entrer une description"
                  />
                  <ErrorMessage name="description" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="phone" className="col-form-label col-lg-2">
                  Téléphone {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="phone"
                    name="phone"
                    type="text"
                    tag={Field}
                    invalid={errors.phone && !!touched.phone}
                    className="form-control"
                    placeholder="Entrer un numéro de téléphone"
                  />
                  <ErrorMessage name="phone" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="email" className="col-form-label col-lg-2">
                  Email {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="email"
                    name="email"
                    type="text"
                    tag={Field}
                    invalid={errors.email && !!touched.email}
                    className="form-control"
                    placeholder="Entrer une adresse email"
                  />
                  <ErrorMessage name="email" component={FormFeedback} />
                </Col>
              </FormGroup>
              <UploadImage
                files={
                  imagesList.length > 0 ? imagesList
                    : props.financer.url === '' ||
                      props.financer.url === undefined
                      ? []
                      : [
                        {
                          url_path: props.financer.url.match(/http/) !== null ? props.financer.url : `${process.env.REACT_APP_API_URL}/files/${props.financer.url}`,
                        },
                      ]
                }
                title={"Image du financement" + (props.isUpdate ? null : " *")}
                handleChangeImages={handleChangeImages}
                max_file={1}
                field="url"
                setFieldValue={setFieldValue}
                invalid={errors.url && !!touched.url}
                invalidError={errors.url}
                isInForm={true}
              />
            </div>
          </div>
          <Row className="justify-content-end">
            <Col lg="10">
              <Row>
                <Col lg="2">
                  <Button type="submit" color="primary">
                    {props.isUpdate ? "Modifier le financement" : "Créer le financement"}
                  </Button>
                </Col>
                <Col lg="10">
                  {props.error && props.error ? (
                    <Alert color="danger" className="d-inline-block">
                      {props.error}
                    </Alert>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default FinancerForm;
