import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import CustomerForm from "./FormComponent/CustomerForm";

// Validation Schema
import { createValidationSchema } from "./FormValidationSchemas/createValidationSchema";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {
  createCustomer,
  getUsers,
  customerApiError,
} from "../../store/actions";

const CustomerAdd = (props) => {
  useEffect(() => {
    props.getUsers.call(undefined,{ pagination: false });
  }, [props.getUsers]);

  const client_type =
    (new URLSearchParams(props.location.search).get("client_type") &&
      new URLSearchParams(props.location.search)
        .get("client_type")
        .match("Particulier")) ||
    new URLSearchParams(props.location.search)
      .get("client_type")
      .match("Professionnel")
      ? new URLSearchParams(props.location.search).get("client_type")
      : "Particulier";

  const initValues = {
    // Identification du prospect
    civility: "",
    firstname: "",
    lastname: "",
    firstname2: "",
    lastname2: "",
    address: "",
    additional_address: "",
    zip_code: "",
    city: "",
    mobile_phone: "",
    mobile_phone2: "",
    fix_phone: "",
    email: "",
    email2: "",
    address_construction_site: "",
    additional_address_construction_site: "",
    city_construction_site: "",
    zip_code_construction_site: "",
    exposed_city: "",
    is_owner: "",
    // Détail du logement
    main_resideence: "",
    owner_at: "",
    housing_seniority: "",
    surface_habitation: "",
    floors_number: "",
    is_isolate_floors: "",
    attic_type: "",
    attic_isulated_at: "",
    attic_isulation_type: "",
    is_attic_isulation: "",
    window_type: "",
    windows_number: "",
    ecs_production_type: "",
    ecs_production_type_annual_cost: "",
    heading_type: "",
    heading_type_annual_cost: "",
    appointment_explication: "",
    // Définition du projet potentiel
    building_work_type: "",
    already_benefit_energy_study: "",
    already_benefit_grant: "",
    // Rencontre du prospect
    first_contact_date: "",
    contact_origin: "",
    customer: "",
    user: "",
    // Positionnement du rendez-vous
    appointment_take_with: "",
    function1: "",
    work_council1: "",
    function2: "",
    work_council2: "",
    age: "",
    spouse_age: "",
    children_number: "",
    fiscal_income: "",
    decision_makers_present: "",
    ok_one_hours_thirty: "",
    appointment_method: "",
    // Le contact - suivi du rendez-vous
    follow_up_number_star: "",
    follow_up_call_date: "",
    follow_up_refusal_reason: "",
    follow_up_client_profil: "",
    client_type: client_type,
    company_name: "",
    siret: "",
  };

  const getCivility = () => {
    return [
      { value: "MME.", label: "MME." },
      { value: "MLLE.", label: "MLLE." },
      { value: "M.", label: "M." },
      { value: "M. OU MME.", label: "M. OU MME." },
    ];
  };

  const handleSubmit = (values) => {
    props.createCustomer(
      {
        // Identification du prospect
        civility: values.civility,
        firstname: values.firstname,
        lastname: values.lastname,
        firstname2: values.firstname2,
        lastname2: values.lastname2,
        address: values.address,
        additional_address: values.additional_address,
        zip_code: values.zip_code,
        city: values.city,
        address_construction_site: values.address_construction_site,
        additional_address_construction_site: values.additional_address_construction_site,
        city_construction_site: values.city_construction_site,
        zip_code_construction_site: values.zip_code_construction_site,
        mobile_phone: values.mobile_phone,
        mobile_phone2: values.mobile_phone2,
        fix_phone: values.fix_phone,
        email: values.email,
        email2: values.email2,
        client_type: client_type,
        company_name: values.company_name,
        siret: values.siret,
        exposed_city: values.exposed_city,
        is_owner: values.is_owner,
        // Détail du logement
        main_resideence: values.main_resideence,
        owner_at: values.owner_at,
        housing_seniority: values.housing_seniority,
        surface_habitation: values.surface_habitation,
        floors_number: values.floors_number,
        is_isolate_floors: values.is_isolate_floors,
        attic_type: values.attic_type,
        attic_isulated_at: values.attic_isulated_at,
        attic_isulation_type: values.attic_isulation_type,
        is_attic_isulation: values.is_attic_isulation,
        window_type: values.window_type,
        windows_number: values.windows_number,
        ecs_production_type: values.ecs_production_type,
        ecs_production_type_annual_cost: values.ecs_production_type_annual_cost,
        heading_type: values.heading_type,
        heading_type_annual_cost: values.heading_type_annual_cost,
        appointment_explication: values.appointment_explication,
        // Définition du projet potentiel
        building_work_type: values.building_work_type,
        already_benefit_energy_study: values.already_benefit_energy_study,
        already_benefit_grant: values.already_benefit_grant,
        // Rencontre du prospect
        first_contact_date: values.first_contact_date,
        contact_origin: values.contact_origin,
        client_id: values.customer?.value,
        user_id: values.user.value,
        // Positionnement du rendez-vous
        appointment_take_with: values.appointment_take_with,
        function1: values.function1,
        work_council1: values.work_council1,
        function2: values.function2,
        work_council2: values.work_council2,
        age: values.age,
        spouse_age: values.spouse_age,
        children_number: values.children_number,
        fiscal_income: values.fiscal_income,
        decision_makers_present: values.decision_makers_present,
        ok_one_hours_thirty: values.ok_one_hours_thirty,
        appointment_method: values.appointment_method,
        // Le contact - suivi du rendez-vous
        follow_up_number_star: values.follow_up_number_star,
        follow_up_call_date: values.follow_up_call_date,
        follow_up_refusal_reason: values.follow_up_refusal_reason,
        follow_up_client_profil: values.follow_up_client_profil,
      },
      props.history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs
          title="Prospects et clients"
          breadcrumbItem="Ajouter un prospect"
        />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Ajouter un prospect</CardTitle>
                <CustomerForm
                  initValues={initValues}
                  validationSchema={createValidationSchema}
                  handleSubmit={handleSubmit}
                  getCivility={getCivility}
                  users={props.users}
                  error={props.error}
                  isUpdate={false}
                  client_type={client_type}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { error } = state.Customers;
  const { users } = state.Users;
  return { users, error };
};

export default withRouter(
  connect(mapStatetoProps, {
    createCustomer,
    getUsers,
    customerApiError,
  })(CustomerAdd)
);
