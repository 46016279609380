import IBAN from "iban";
import * as Yup from "yup";

const durationType = {
  UNDETERMINED: "Indéterminé",
  END_DATE: "Date de fin",
  INSTALLMENTS_NUMBER: "Nombre d'échéance",
}
/*
    initValues = {
      customer: '',
      debit_day: '',
      signature_date: '',
      mandate: '',
      mandateImage: [],
      formula: '',
      validation_date: '',
      periodicity: '',
      ics: '',
      debit_type: '',
      start_at: '',
      duration: durationType.UNDETERMINED,
      end_at: null,
      rum: ''
    }
 */

export const subscriptionsFormValidation = Yup.object().shape({

  customer: Yup.mixed().required('Un client est requis'),

  formula: Yup.mixed().required('Une formule est requise'),

  debit_type: Yup.mixed().required('Un type de prélèvement est requis'),

  debit_day: Yup.mixed().required('Un jour de prélèvement est requis'),

  periodicity: Yup.mixed().required('Une périodicité est requise'),

  start_at: Yup.mixed().required('Une date de prise d\'effet est requise'),

  duration: Yup.mixed().required('Durée requise'),

  end_at: Yup.mixed().when(
    'duration', {
      is: durationType.END_DATE,
      then: Yup.mixed().required('La date de fin est requise'),
    }),

  term_number: Yup.mixed().when(
    'duration', {
      is: durationType.INSTALLMENTS_NUMBER,
      then: Yup.number()
        .typeError('Le nombre d\'échéance doit être nombre entier')
        .integer('Le nombre d\'échéance doit être nombre entier')
        .required('Le nombre d\'échéances est requis')
        .positive('Le nombre d\'échéances doit être positif')
    }),

  iban: Yup.string()
    .notRequired()
    .test("format", "Format d'IBAN invalide", (val) => {
      return !!!val || IBAN.isValid(val);
    })
    .trim(),

  /*ics: Yup.mixed().when(
    'mandateImage',
    (mandateImage, schema) => {
      return mandateImage.length === 0 ? schema.notRequired() : schema.required('Le numéro ICS est requis')
    }
  ),

  rum: Yup.mixed().when(
    'mandateImage',
    (mandateImage, schema) => {
      return mandateImage.length === 0 ? schema.notRequired() : schema.required('Le numéro RUM est requis')
    }
  ),

  signature_date: Yup.mixed().when(
    'mandateImage',
    (mandateImage, schema) => {
      return mandateImage.length === 0 ? schema.notRequired() : schema.required('La date de signature est requise')
    }
  ),*/


});