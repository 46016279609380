import React from 'react';
import {Button, Card, CardBody, Col, Collapse, Row} from "reactstrap";

import {Formik} from 'formik';

/**
 * This component is the default filter for all list components,
 * it contains the Formik and form tags, you just need to pass
 * whatever component you need as component child.
 *
 * @param filter state that will be changed when clicking the submit button
 * @param setFilter
 * @param defaultFilterValues default value for filter when he's empty
 * @param children Filter forms fields
 * @param {boolean} isFilterOpen
 * @param {function} setIsFilterOpen setter for isFilterOpen
 */
const FilterComponent = ({
                           filter,
                           setFilter,
                           defaultFilterValues,
                           children,
                           isFilterOpen,
                           setIsFilterOpen,
                         }) => {


  function handleFilterSubmit(values) {
    setFilter(values)
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={filter}
        enableReinitialize
        onSubmit={handleFilterSubmit}
      >
        {({values, setFieldValue, handleSubmit, handleReset}) => (
          <Row>
            <Col xs="12">
              <Row>
                <Col xs="12" className="d-flex justify-content-start">
            <span
              className="d-flex text-link mb-3"
              onClick={() => {
                setIsFilterOpen(!isFilterOpen);
              }}
            >
              <i className="fas fa-filter mr-1"/>Filtrer
            </span>
                  <span
                    className="d-flex text-link text-color-unset mb-3"
                    onClick={() => {
                      handleReset();
                      setFilter(defaultFilterValues);
                    }}
                  >
              <i className="fas fa-times mr-1"/>
              Vider les filtres
            </span>
                </Col>
                <Col xs="12">
                  <Collapse isOpen={isFilterOpen}>
                    <form>
                      <Card>
                        <CardBody>
                          {React.cloneElement(children, {values: values})}
                          <Row>
                            <Col
                              xs="12"
                              className="d-flex justify-content-center mt-3"
                            >
                              <Button
                                onClick={() => handleSubmit(values)}
                                className="btn-green"
                              >
                                Appliquer les filtres
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </form>
                  </Collapse>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default FilterComponent;