import {useCallback, useEffect, useState} from 'react';

import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {subscriptions} from "../../../api";
import PreviewYearComponent from "../Components/PreviewYearComponent";

import {durationType} from '../Form/SubscriptionForm';

const PreviewTermModal = ({
                            isOpen,
                            toggleModal,
                            values,
                          }) => {

  const computeAnniversaryMonth = useCallback(() => {
    const startDate = new Date(values.start_at);
    if (values.debit_day < startDate.getDate())
      return startDate.getMonth() === 11 ? 0 : startDate.getMonth() + 1;
    else
      return startDate.getMonth();
  }, [values.start_at, values.debit_day])

  const [previewData, setPreviewData] = useState(null);
  const [anniversaryMonth, setAnniversaryMonth] = useState(computeAnniversaryMonth());

  useEffect(() => {
    if (isOpen) {
      subscriptions.subscriptionPreview({
        start_at: values.start_at,
        end_at: values.duration === durationType.END_DATE ? values.end_at : null,
        periodicity: values.periodicity,
        term_number: values.duration === durationType.INSTALLMENTS_NUMBER ? values.term_number : null,
        debit_day: values.debit_day

      })
        .then(r => {
          setPreviewData(r.data);
          setAnniversaryMonth(computeAnniversaryMonth())
        })
        .catch(err => console.error(err));
    }
  }, [isOpen, computeAnniversaryMonth, values.debit_day, values.start_at, values.duration, values.end_at, values.periodicity, values.term_number]);

  return (
    <Modal
      className='preview-modal'
      isOpen={isOpen}
      toggle={toggleModal}
    >
      <ModalHeader toggle={() => toggleModal()}>Aperçu des échéances selon la périodicité</ModalHeader>
      {!previewData ? '' :
        <ModalBody>
          {previewData.map(data =>
            (<PreviewYearComponent
              data={data}
              price={values.formula.price_ttc_monthly}
              anniversaryMonth={anniversaryMonth}
            />)
          )}

        </ModalBody>
      }
    </Modal>
  );
}

export default PreviewTermModal;