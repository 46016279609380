export const getIndexOfFreeItemInFreeItemsArray = (freeItem, array) => {
    // ################################################################################
    // GET INDEX OF OBJECT IN ARRAY
    let index = -1;

    if (freeItem.cptId!== null && freeItem.cptId!== undefined) {
        index = array.findIndex((item) => item.cptId === freeItem.cptId);
    } else if (freeItem.id) {
        index = array.findIndex((item) => item.id === freeItem.id);
    }
    console.log("index : ", index);

    return index;
    // ################################################################################
};

export const deleteFreeItem = (freeItem, freeFieldsArray, setFreeFields, setFieldValue, values) => {
    freeFieldsArray = window.freeFieldsArray;
    console.log("##### [FREE FIELD HELPER] [DELETEFREEITEM] [BEFORE] freeItem, freeFieldsArray", freeItem, freeFieldsArray);
    let index = getIndexOfFreeItemInFreeItemsArray(freeItem, freeFieldsArray);
    if (index === -1) return;

    let temp = freeFieldsArray;
    let free_field = temp[index];
    let lastPositionId = null;
    let deletedItemPosition = temp[index].position;

    if (free_field.id) {
        temp[index]._destroy = true;
        temp[index].position = -1;
    } else {
        temp.splice(index, 1);
    }

    for (let i = 0; i < temp.length; i++) {
        if (lastPositionId === null) {
            lastPositionId = i;
            continue;
        }
        if (temp[lastPositionId].position < temp[i].position) {
            lastPositionId = i;
        }
    }

    for (let i = 0; i < temp.length; i++) {
        if (temp[i].position > deletedItemPosition) {
            temp[i].position--;
        }
    }

    if (lastPositionId !== null) {
        temp[lastPositionId].__position_version++;
    }
    console.log("##### [FREE FIELD HELPER] [DELETEFREEITEM] [AFTER] temp freeFieldsArray", temp);
    setFreeFields(temp, setFieldValue, values.__update);
};

export const upFreeItem = (freeItem, freeFieldsArray, setFreeFields, setFieldValue, values) => {
    freeFieldsArray = window.freeFieldsArray;
    console.log("##### [FREE FIELD HELPER] [UPFREEITEM] [BEFORE] freeItem, freeFieldsArray", freeItem, freeFieldsArray);
    let index = getIndexOfFreeItemInFreeItemsArray(freeItem, freeFieldsArray);
    if (index === -1) return;

    let currentPosition = freeFieldsArray[index].position;
    let newPosition = currentPosition - 1;
    if (newPosition < 0) return;
    for (let i = 0; i < freeFieldsArray.length; i++) {
        if (freeFieldsArray[i].position === newPosition) {
            freeFieldsArray[i].position = currentPosition;
            break;
        }
    }
    freeFieldsArray[index].position = newPosition;
    console.log("##### [FREE FIELD HELPER] [UPFREEITEM] [AFTER] temp freeFieldsArray", freeFieldsArray);
    setFreeFields(
        freeFieldsArray,
        setFieldValue,
        values.__update
    );
}

export const downFreeItem = (freeItem, freeFieldsArray, setFreeFields, setFieldValue, values) => {
    freeFieldsArray = window.freeFieldsArray;
    console.log("##### [FREE FIELD HELPER] [DOWNFREEITEM] [BEFORE] freeItem, freeFieldsArray", freeItem, freeFieldsArray);
    let index = getIndexOfFreeItemInFreeItemsArray(freeItem, freeFieldsArray);
    if (index === -1) return;
    let currentPosition = freeFieldsArray[index].position;
    let newPosition = currentPosition + 1;
    if (newPosition >= freeFieldsArray.length) return;
    for (let i = 0; i < freeFieldsArray.length; i++) {
        if (freeFieldsArray[i].position === newPosition) {
        freeFieldsArray[i].position = currentPosition;
        break;
        }
    }
    freeFieldsArray[index].position = newPosition;
    console.log("##### [FREE FIELD HELPER] [DOWNFREEITEM] [AFTER] temp freeFieldsArray", freeFieldsArray);
    setFreeFields(
        freeFieldsArray,
        setFieldValue,
        values.__update
    );
}

/**
 * Compute a product item to fit with the api args
 * @param item product needed to be computed
 * @param position position in the array
 */
function computeProductItem(item, position) {

    let obj = {
        _destroy: item._destroy ? true : null,
        description: item.description,
        eco_tax: item.eco_tax,
        isManpower: item.isManpower,
        price: item.price,
        price_ttc: item.price_ttc,
        product_id: item.product_id,
        quantity: item.quantity,
        quantity_unit: item.quantity_unit,
        timeline_begin_at: item.timeline_begin_at,
        vat: item.vat,
        position: position,
        vat_id: item.vat.id,
        __position_version: 0};

    // If an id is set, it means that the product is already in an order so we add it to the data
    if(item.id)
        obj.id = item.id;

    return obj
}

/**
 * Compute a free item to fit with the api args
 * @param item product needed to be computed
 * @param position position in the array
 */
function computeFreeItem(item, position) {

    let obj = {
        _destroy: item._destroy ? true : null,
        free_field_attributes: {
            description: item.description,
            eco_tax: item.eco_tax,
            price: item.price,
            price_ttc: item.price_ttc,
            vat: item.vat,
            vat_id: item.vat.id,
        },
        position: position,
        quantity: item.quantity,
        quantity_unit: item.quantity_unit,
        timeline_begin_at: item.timeline_begin_at,
    };

    if(item.id)
        obj.id = item.id;

    return obj
}



/**
 * Format items to send them to the api
 * @param {array} data array of product and free items
 * @param {array} removedData array of product and free items deleted from the array
 * @return {{freeItems: object[], productItems: object[]}}
 */
export function formatItems(data, removedData) {
    let productItems = []; // copy data to keep immutability of states
    let freeItems = []; // copy data to keep immutability of states

    // removed data are at the end of the item array so there is no problems with the position
    const items = data.concat(removedData);

    items.forEach((item, index) => {
        if(item._destroy) {
            if(item.product_id) {
                productItems.push(computeProductItem(item, -1));
            }else{
                freeItems.push(computeFreeItem(item, -1));
            }
        }else{
            if(item.product_id) {
                productItems.push(computeProductItem(item, index));
            }else{
                freeItems.push(computeFreeItem(item, index));
            }
        }
    });


    return {productItems: productItems, freeItems: freeItems};
}

/**
 * Sort the data array but their position attribut
 * @param {array} data array of products and free items containing a position attribut
 * @return {array} return data sorted by position attribut
 */
export function sortDataByPositions(data) {
    let copyData = [...data];
    copyData.sort(function (a, b) {
        return a.position - b.position;
    })
    return copyData;
}