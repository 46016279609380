import React from 'react';
import { Row } from 'reactstrap';

export interface SearchFilterProps {
  placeholder: string,
  defaultValue: string,
  containerClasses: string,
  inputClasses: string,
  onSearch: (value: string) => void,
}

const SearchFilter = ({
  placeholder,
  defaultValue,
  containerClasses,
  inputClasses,
  onSearch
}: SearchFilterProps) => {
  return (
    <Row className={`search-filter ${containerClasses}`}
      onClick={(e) => onSearch(e.currentTarget.querySelector('input')?.value || "")}
    >
      <input
        type="text"
        className={inputClasses}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onClick={(e) => e.stopPropagation()}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            onSearch(e.currentTarget.value);
          }
        }}
      />
    </Row>
  )
}

export default SearchFilter;