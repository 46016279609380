import { useEffect, useState } from 'react';

// Component
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Context
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { addFlashMessage, ImportantEnum } from '../../components/Common/FlashMessage';
import FlashMessageComponent from "../../components/Common/FlashMessageComponent";
import GlobalLoader from "../../components/Common/GlobalLoader";
import { getSubscription, updateSubscription, updateValidatedSubscription } from "../../store/subscriptions/actions";
import SubscriptionForm, { durationType } from "./Form/SubscriptionForm";
import ConfirmUpsertModal from "./Modal/ConfirmUpsertModal";

import { V2 } from "../../api";

let modalValues = {};

const SubscriptionsUpdate = ({
                               loading,
                               getSubscription,
                               subscription,
                               updateSubscription,
                               history,
                               error,
                               updateValidatedSubscription
                             }) => {

  useEffect(() => {
    const splitedPath = window.location.pathname.split('/');
    if (splitedPath.length === 4)
      getSubscription(splitedPath[3]);
  },[getSubscription])
  
  const [mandate, setMandate] = useState(null);

  let initValues;

  if (subscription) {
    const isMandated = subscription.mandates.length > 0;
    initValues = {
      customer: subscription.client_id,
      debit_day: subscription.debit_day,
      signature_date: subscription.signed_at ? subscription.signed_at.substr(0, 10) : null,
      mandate: {},
      mandateImage: isMandated && subscription.mandates[0].document_path ? [{
        url_path: `${process.env.REACT_APP_API_URL}/files/${subscription.mandates[0].document_path}`,
        name: subscription.mandates[0].document_path.split('/')[1] || 'Mandat'
      }] : [],
      formula: subscription.formule_id,
      validation_date: subscription.validated_at ? subscription.validated_at.substr(0, 10) : null,
      periodicity: subscription.periodicity,
      ics: isMandated ? subscription.mandates[0].ics : '',
      debit_type: subscription.debit_type,
      term_number: subscription.term_number,
      start_at: subscription.start_at ? subscription.start_at.substr(0, 10) : null,
      duration: subscription.duration_type,
      end_at: subscription.end_at ? subscription.end_at.substr(0, 10) : null,
      rum: isMandated ? subscription.mandates[0].rum : '',
      iban: isMandated ? subscription.mandates[0].iban : '',
      notes: subscription.notes,
      suspended_at: subscription.suspended_at ? subscription.suspended_at.substr(0, 10) : null,
    }
  } else {
    initValues = {
      customer: '',
      debit_day: '',
      signature_date: '',
      mandate: '',
      mandateImage: [],
      formula: '',
      validation_date: '',
      periodicity: '',
      ics: '',
      debit_type: '',
      term_number: null,
      start_at: '',
      duration: durationType.UNDETERMINED,
      end_at: null,
      rum: '',
      iban: '',
      notes: '',
      suspended_at: '',
    }
  }

  if (error) {
    addFlashMessage(ImportantEnum.DANGER, error);
  }

  function update() {
    let values = modalValues;
    const mandates = subscription.mandates.map(mandate => ({...mandate, _destroy: true}));

    // if (subscription.validated_at)
    //   updateValidatedSubscription(
    //     subscription.id,
    //     {
    //       debit_day: values.debit_day,
    //       start_at: values.start_at,
    //       signed_at: values.signature_date,
    //       mandates_attributes: [{
    //         ics: values.ics,
    //         rum: values.rum,
    //         document_path: values.mandate.name ? values.mandate : null
    //       }, ...mandates]
    //     }, history)
    // else
      updateSubscription(
        subscription.id,
        {
          debit_day: values.debit_day,
          validated_at: values.validation_date,
          signed_at: values.signature_date,
          start_at: values.start_at,
          end_at: values.duration === durationType.END_DATE ? values.end_at : null,
          formule_id: values.formula,
          client_id: values.customer,
          debit_type: values.debit_type,
          periodicity: values.periodicity,
          project_id: null,
          to_renew: true,
          term_number: values.duration === durationType.INSTALLMENTS_NUMBER ? values.term_number : null,
          duration_type: values.duration,
          notes: values.notes,
          suspended_at: values.suspended_at,
          mandates_attributes: [{
            ics: values.ics,
            rum: values.rum,
            iban: values.iban,
            document_path: values.mandate.name ? values.mandate : null
          }, ...mandates]
        }, history)
  }

  function handleSubmitSubscription(values) {
    modalValues = values;
    let iban = values.iban;
    if (!!iban) {
      V2.mandates.getMandateByIban(iban, { subscription_id: subscription.id }).then(response => {
        setMandate(response.data);
      }).catch(_ => {
        update();
      });
    } else {
      update();
    }
  }

  return (
    <div className="page-content">
      {loading || !subscription ? <GlobalLoader/> :
        <Container fluid>
          <Breadcrumbs title="Listes" breadcrumbItem="Abonnements" breadcrumbTitle='Listes'/>
          <Card>
            <FlashMessageComponent/>
            <CardBody>
              {/* Card title */}
              <CardTitle className="mb-3">
                <Row className="pr-2 pl-2 d-flex justify-content-between">
                  <Col>Modifier un abonnement</Col>
                </Row>
              </CardTitle>

              <SubscriptionForm
                initValues={initValues}
                handleSubmit={handleSubmitSubscription}
                isUpdate={true}
              />

            </CardBody>
          </Card>
          <ConfirmUpsertModal
            mandate={mandate}
            toggleModal={() => setMandate(null)}
            handle={update}
          />
        </Container>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    subscription,
    loading,
    error,
    pagination,
  } = state.Subscriptions

  return {
    subscription,
    loading,
    error,
    pagination
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateSubscription,
    updateValidatedSubscription,
    getSubscription
  })(SubscriptionsUpdate)
);