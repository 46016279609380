import { Formik } from 'formik';
import moment from "moment";
import { useState } from 'react';
import DatePicker from "react-datepicker";
import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import {V2} from "../../../api";
import LoaderLayoutPulse from '../../../components/Common/LoaderLayoutPulse';

interface FormValues {
  date_begin: Date | null,
  date_end: Date | null,
}

interface ExportInvoicesModalProps {
  onAbort: () => void,
}

export default function ExportInvoicesModal({ onAbort }: ExportInvoicesModalProps) {
  const [formValid, setFormValid] = useState(false);

  function onValidate(values: FormValues) {
    setFormValid(values.date_begin != null && values.date_end != null)
  }

  async function onSubmit(values: FormValues) {
    let response = await V2.export_invoices.get({
      date_begin: values.date_begin!.toISOString(),
      date_end: values.date_end!.toISOString(),
    });
    window.open(window.URL.createObjectURL(response.data));
  }

  return (
    <Modal isOpen={true} toggle={onAbort} className={""}>
      <ModalHeader toggle={onAbort}>Exporter les factures</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={
            {
              date_begin: null,
              date_end: null,
            } as FormValues
          }
          onSubmit={onSubmit}
          validateOnChange={true}
          validate={onValidate}
        >
          {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
            <form className={"w-100 d-flex flex-column justify-content-center align-items-start"}>
              <Row className={"w-100"}>
                <Col xs={"8"}>
                  <Label for="date" style={{ color: "#183254" }} className={"mt-4"}><b>Date de début :</b></Label>
                  <DatePicker
                    className="form-control button-without-style"
                    name="date_begin"
                    selected={values.date_begin ? new Date(values.date_begin) : null}
                    onChange={v => setFieldValue("date_begin", v)}
                    isClearable
                    placeholderText={moment(new Date()).format('DD/MM/yyyy')}
                    dateFormat={"dd/MM/yyyy"}
                  />
                </Col>
              </Row>
              <Row className={"w-100"}>
                <Col xs={"8"}>
                  <Label for="date" style={{ color: "#183254" }} className={"mt-4"}><b>Date de fin :</b></Label>
                  <DatePicker
                      className="form-control button-without-style"
                      name="date_end"
                      selected={values.date_end ? new Date(values.date_end) : null}
                      onChange={v => setFieldValue("date_end", v)}
                      isClearable
                      placeholderText={moment(new Date()).format('DD/MM/yyyy')}
                      dateFormat={"dd/MM/yyyy"}
                  />
                </Col>
              </Row>
              <div className={"d-flex justify-content-end mx-n3 px-3 mt-4 border-top"} style={{ width: "calc(100% + 2rem)" }}>
                <Button type="button" className={"btn btn-secondary mt-2 mr-3"} style={{ background: "#96988F", borderColor: "#96988F" }} onClick={onAbort}>Annuler</Button>
                <Button type="button" className={"btn btn-green mt-2"} disabled={!formValid} onClick={() => handleSubmit(values as any)}>Valider</Button>
              </div>
              {isSubmitting && <div className={"d-flex justify-content-center w-100"}><LoaderLayoutPulse/></div>}
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
}
