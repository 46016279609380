import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import { createRefundFromOrder } from "../../../store/actions";
import { withRouter } from "react-router-dom";
import { removeCeePrime } from "../../../helpers/helper";

const RefundModal = ({
  invoiceToRefund,
  isOpen,
  toggle,
  createRefundFromOrder,
  history
}) => {

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggle();
      }}
    >
      <ModalHeader toggle={() => toggle()} tag="h4">
        Création d'un avoir sur la facture N°
        {invoiceToRefund ? invoiceToRefund.number : ""}
      </ModalHeader>
      <ModalBody>
        <p>Voulez vous créer un avoir pour annuler la facture ?</p>
        <p>
          <b>Montant HT :</b>{" "}
          {invoiceToRefund
            ? removeCeePrime(invoiceToRefund, parseFloat(invoiceToRefund.total_ht))
            : ""}
        </p>
        <p>
          <b>TVA :</b>{" "}
          {invoiceToRefund
            ? `${parseFloat(invoiceToRefund.total_vats).toFixed(2)} €`
            : ""}
        </p>
        <p>
          <b>Montant TTC :</b>{" "}
          {invoiceToRefund
            ? removeCeePrime(invoiceToRefund, parseFloat(invoiceToRefund.total_ttc))
            : ""}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            toggle();
          }}
          className="btn btn-secondary waves-effect"
        >
          Annuler
        </Button>
        <Button
          onClick={() => {
            toggle();
            createRefundFromOrder(
              invoiceToRefund ? invoiceToRefund.id : -1,
              history
            );
          }}
          className="btn btn-green waves-effect waves-light"
        >
          Valider
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default withRouter(connect(null, {
  createRefundFromOrder
})(RefundModal));
