import React from "react";
import { withRouter } from "react-router-dom";
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    Button,
    Collapse
  } from "reactstrap";

// Redux
import { connect } from "react-redux";

import { createValidationSchema } from "../FormValidationSchemas/createValidationSchema";
import { updateValidationSchema } from "../FormValidationSchemas/updateValidationSchema";

import EventForm from "../FormComponent/EventForm.tsx";

// Helpers
import {
    getTimeWithFormat,
    getDateWithFormat,
    parseCustomer,
  } from "../../../helpers/helper";

// actions
import {
    createAgendaItem,
    updateAgendaItem,
    deleteAgendaItem,
    agendaItemsApiError,
} from "../../../store/actions";

import {
    projects
} from "../../../api/index";

import { agendaItems } from "../../../api/index";

import { parseTmpEvent, defaultEvent } from "./common";

import AgendaItemHistory from "./Common/AgendaItemHistory";
import ProjectDetail from "./Common/ProjectDetail";

// Must be a class to use React ref because function not have a reference
class InstallationModals extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStartDay: false,
            selectedEndDay: false,
            addresses_option: [],
            modal: false,
            modal1: false,
            historyCollapse: false,
            projectDetailsCollapse: false,
            modalEventInfo: true,
            event: this.props.event,  // if false then show edit form else show info
            agendaItem: null
        };
    }

    // event = Fullcalendar event
    openAgendaEvent(arg) {
        if (arg.event) {
            const eventNew = arg.event;

            // Get Full AgendaItem informations before set event
            agendaItems.getOne(eventNew.extendedProps.agendaItem.id).then((response) => {
                let agendaItem = response.data;
                let options = [];
                let c = agendaItem.client;
                if (
                    c.address_construction_site &&
                    c.zip_code_construction_site &&
                    c.city_construction_site
                ) {
                    options.push({
                        value: true,
                        label: `Adresse du chantier - ${c.address_construction_site}, ${c.zip_code_construction_site} ${c.city_construction_site}`,
                    });
                }
                if (c.address && c.zip_code && c.city) {
                    options.push({
                        value: false,
                        label: `Adresse principale - ${c.address}, ${c.zip_code} ${c.city}`,
                    });
                }

                this.setState({
                    event: parseTmpEvent(arg, agendaItem),
                    modalEventInfo: true,
                    addresses_option: options,
                    historyCollapse: false,
                    projectDetailsCollapse: false,
                    agendaItem
                });

                this.toggleEditEventModal();
            });
        } else {
            let eventDate = arg.date;
            let currentDate = new Date();

            this.setState({
                selectedStartDay: new Date(eventDate.setHours(currentDate.getHours())),
                selectedEndDay: new Date(eventDate.setHours(currentDate.getHours() + 1)),
                event: defaultEvent
            });

            this.toggleNewEventModal();
        }
    }

    /**
     * Handling the modal state
     */
    toggleNewEventModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleEditEventModal() {
        this.setState({
            modal1: !this.state.modal1
        });
    }

    /**
     * Toggle modalEventInfos
     */
    toggleModalInfo() {
        this.setState({
            modalEventInfo: !this.state.modalEventInfo
        });
    }

    /**
     * Handling submit event on event form
     */
    handleValidEventSubmitEvent(e, values) {
        let data = {
            client_id: values.customer,
            project_id: values.project?.value,
            technicians: values.technicians.map(
                (technician, index) => technician.value
            ),
            reason: values.reason,
            comment: values.comment,
            date_begin: values.date_begin,
            date_end: values.date_end,
            address: values.address.value,
        };

        if (this.state.event?.extendedProps?.agendaItem?.id) {
            this.props.updateAgendaItem(this.state.event.extendedProps.agendaItem.id, data);
            this.toggleEditEventModal();
        } else {
            this.props.createAgendaItem(data);
            this.toggleNewEventModal();
        }

        let project_id = this.state.event?.extendedProps?.project?.value
                            || values.project?.value
                            || null;

        if (values.reason === "Visite technique" && project_id) {
            projects.update(project_id, {
                disponibility_vt: values.date_begin,
                comment_vt: values.comment
            });
        }
    }

    deleteEvent(id) {
        let project_id = this.state.event?.extendedProps?.project?.value
                            || null;

        if (this.state.event?.extendedProps?.reason === "Visite technique" && project_id) {
            projects.update(project_id, {
                disponibility_vt: null,
                comment_vt: null
            });
        }

        this.props.deleteAgendaItem(id);
        this.toggleEditEventModal();
    }

    render() {
        if (!this.state.event) return null;
        else return (
            <div>
                {/* New event modal */}
                <Modal
                    isOpen={this.state.modal}
                    toggle={() => this.toggleNewEventModal()}
                    className=""
                >
                    <ModalHeader toggle={() => this.toggleNewEventModal()} tag="h4">
                        Ajouter un événement
                    </ModalHeader>
                    <ModalBody>
                        <EventForm
                            initValues={{
                            customer: "",
                            project: "",
                            technicians: [],
                            reason: "",
                            comment: "",
                            date_begin: this.state.selectedStartDay,
                            date_end: this.state.selectedEndDay,
                            address: "",
                            }}
                            validationSchema={createValidationSchema}
                            handleSubmit={this.handleValidEventSubmitEvent.bind(this)}
                            isUpdate={false}
                            projects={
                                this.props.projects ? this.props.projects : []
                            }
                        />
                    </ModalBody>
                </Modal>

                {/* edit event modal */}
                <Modal
                    isOpen={this.state.modal1}
                    toggle={() => this.toggleEditEventModal()}
                    className=""
                >
                    <ModalHeader className="calendar-modal-header" toggle={() => this.toggleEditEventModal()} tag="div">
                    {
                        this.state.modalEventInfo
                            ?   <span className="w-100 d-flex justify-content-between">
                                    <h4>Information du rendez-vous</h4>
                                    <span>
                                        <Button onClick={() => this.toggleModalInfo()} className="bx bx-pencil custom-header-button mr-2"></Button>
                                        <Button onClick={() => this.deleteEvent(this.state.agendaItem.id)} className="bx bx-trash custom-header-button danger"></Button>
                                    </span>
                                </span>
                            :   <span className="w-100 d-flex justify-content-between">
                                    <h4>Modifier un rendez-vous</h4>
                                    <span>
                                        <Button onClick={() => this.toggleModalInfo()} className="bx bx-arrow-back custom-header-button"></Button>
                                    </span>
                                </span>
                    }
                    </ModalHeader>
                    <ModalBody className="calendar-modal-body">
                    {
                        this.state.modalEventInfo
                            ?   <Container>
                                    <Row>
                                        <Col lg="1">
                                        <div className={"color-box " + this.state.event?.extendedProps?.className}></div>
                                        </Col>
                                        <Col lg="11">
                                        <h4>{parseCustomer(this.state.event?.extendedProps?.agendaItem?.client)}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <p>Du {getDateWithFormat(this.state.event.extendedProps.date_begin)}, {getTimeWithFormat(this.state.event.extendedProps.date_begin)} au {getDateWithFormat(this.state.event.extendedProps.date_end)}, {getTimeWithFormat(this.state.event.extendedProps.date_end)}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="1">
                                        <div className="bx bx-been-here"></div>
                                        </Col>
                                        <Col lg="11">
                                        <p>{this.state.event?.extendedProps?.address?.label}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="1">
                                        <div className="bx bx-phone"></div>
                                        </Col>
                                        <Col lg="11">
                                        <p>{this.state.event?.extendedProps?.agendaItem?.client?.mobile_phone || this.state.event?.extendedProps?.agendaItem?.client?.mobile_phone2 || this.state.event?.extendedProps?.agendaItem?.client?.fix_phone}</p>
                                        </Col>
                                    </Row>
                                    {
                                        this.state.event?.extendedProps?.comment
                                        ? <Row>
                                            <Col lg="1">
                                                <div className="bx bx-comment"></div>
                                            </Col>
                                            <Col lg="11">
                                                <p>{this.state.event?.extendedProps?.comment}</p>
                                            </Col>
                                            </Row>
                                        : null
                                    }
                                    <Row>
                                        <Col>
                                        <Row>
                                            <Col>
                                            <h5>Techniciens</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <ul>
                                            {
                                                this.state.event?.extendedProps?.technicians?.map((technician) => (
                                                <li key={technician.value}>{technician.label}</li>
                                                ))
                                            }
                                            </ul>
                                            </Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    {
                                        this.state.agendaItem && this.state.agendaItem.project
                                            ?   <>
                                                    <h5
                                                        className={`accordion-title`}
                                                        onClick={() => this.setState({ projectDetailsCollapse: !this.state.projectDetailsCollapse })}
                                                    >
                                                        <div className="d-flex align-items-center pt-3 pb-3">
                                                        <i
                                                            className={`bx bx-chevron-${
                                                            this.state.projectDetailsCollapse ? "up" : "down"
                                                            } mr-1`}
                                                        ></i>{" "}
                                                            Détails du projet
                                                        </div>
                                                        <hr className="m-0" />
                                                    </h5>
                                                    <Collapse isOpen={this.state.projectDetailsCollapse} className="mt-4" >
                                                        <ProjectDetail project={this.state.agendaItem.project}/>
                                                    </Collapse>
                                                </>
                                            :   null
                                    }
                                    {
                                        this.state.agendaItem
                                            ?   <>
                                                    <h5
                                                        className={`accordion-title`}
                                                        onClick={() => this.setState({ historyCollapse: !this.state.historyCollapse })}
                                                    >
                                                        <div className="d-flex align-items-center pt-3 pb-3">
                                                        <i
                                                            className={`bx bx-chevron-${
                                                            this.state.historyCollapse ? "up" : "down"
                                                            } mr-1`}
                                                        ></i>{" "}
                                                            Historique
                                                        </div>
                                                        <hr className="m-0" />
                                                    </h5>
                                                    <Collapse isOpen={this.state.historyCollapse} className="mt-4" >
                                                        <AgendaItemHistory agendaItem={this.state.agendaItem}/>
                                                    </Collapse>
                                                </>
                                            :   null
                                    }
                                </Container>
                            :   <EventForm
                                    initValues={{
                                        id: this.state.event.id,
                                        customer:
                                            this.state.event &&
                                            this.state.event.extendedProps.customer !== ""
                                                ? this.state.event.extendedProps.customer
                                                : "",
                                        project:
                                            this.state.event &&
                                            this.state.event.extendedProps.project !== ""
                                                ? this.state.event.extendedProps.project
                                                : "",
                                        technicians:
                                            this.state.event &&
                                            this.state.event.extendedProps.technicians !== ""
                                                ? this.state.event.extendedProps.technicians
                                                : [],
                                        reason:
                                            this.state.event && this.state.event.extendedProps.reason !== ""
                                                ? this.state.event.extendedProps.reason
                                                : "",
                                        comment:
                                            this.state.event &&
                                            this.state.event.extendedProps.comment !== ""
                                                ? this.state.event.extendedProps.comment
                                                : "",
                                        date_begin:
                                            this.state.event &&
                                            this.state.event.extendedProps.date_begin !== ""
                                                ? this.state.event.extendedProps.date_begin
                                                : "",
                                        date_end:
                                            this.state.event &&
                                            this.state.event.extendedProps.date_end !== ""
                                                ? this.state.event.extendedProps.date_end
                                                : "",
                                        address:
                                            this.state.event &&
                                            this.state.event.extendedProps.address !== ""
                                                ? this.state.event.extendedProps.address
                                                : "",
                                    }}
                                    validationSchema={updateValidationSchema}
                                    handleSubmit={this.handleValidEventSubmitEvent.bind(this)}
                                    isUpdate={true}
                                    projects={this.props.projects}
                                    addresses_option={this.state.addresses_option}
                                />
                    }
                    </ModalBody>
                </Modal>
            </div>
        );
    }
};

const mapStatetoProps = (state) => {
    const {
        error,
    } = state.AgendaItems;
    const agendaItemLoading = state.AgendaItems.loading;
    return {
        error,
        agendaItemLoading,
    };
};

// Use for forwardRef
const wrapper = React.forwardRef((props, ref) => (
    <InstallationModals {...props} ref={ref} />
));
  
export default withRouter(
    connect(mapStatetoProps, {
        createAgendaItem,
        updateAgendaItem,
        deleteAgendaItem,
        agendaItemsApiError,
    }, null, {forwardRef: true})(wrapper)
);