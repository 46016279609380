import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";

// Redux
import { connect } from "react-redux";

import MessagesForm from "../FormComponent/MessagesForm";

// Helpers
import { getShortDateAndTimeWithFormat } from "../../../../helpers/helper";

import { updateNotification } from "../../../../store/actions";

const MessagesList = (props) => {
  useEffect(() => {
    if (props.showMessageForm) {
      let form = document.getElementById("messageForm");
      form.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.showMessageForm]);

  /**
   * Création d'un message pour une relance
   * @param {object} values Liste des informations du message
   */
  const createMessage = (values) => {
    if (
      values.priority === null ||
      values.priority === props.notification.priority
    )
      delete values.priority;
    if (values.state === null || values.state === props.notification.state)
      delete values.state;
    if (values.message === "") delete values.message;

    if (Object.keys(values).length !== 0)
      props.updateNotification(props.notification.id, values);
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <div className="messages-list-header">
            <h5>Messages</h5>
          </div>
          <div>
            {props.notification.messages?.length === 0
              ? "Pas de message"
              : null}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Row className="messages-list">
            {props.notification.messages?.map((message, index) => (
              <Col xs="12" key={`message-${index}`}>
                <div className="message-container">
                  <div className="message">
                    <div className="message-header">
                      <div>
                        Mis à jour le{" "}
                        <span>
                          {getShortDateAndTimeWithFormat(message.updated_at)}
                        </span>{" "}
                        par{" "}
                        <span>{`${message.user.firstname} ${message.user.lastname}`}</span>
                      </div>
                      <div>{`#${index + 1}`}</div>
                    </div>
                    {message.has_update_priority || message.has_update_state ? (
                      <div
                        className={`message-content${
                          message.message
                            ? " message-content-border-bottom"
                            : ""
                        }`}
                      >
                        <ul>
                          {message.has_update_priority && (
                            <li>
                              Priorité changée
                              {message.old_priority ? ` de ` : null}
                              <span>
                                {message.old_priority
                                  ? message.old_priority
                                  : null}
                              </span>
                              {` à `}
                              <span>{message.new_priority}</span>
                            </li>
                          )}
                          {message.has_update_state && (
                            <li>
                              État changé
                              {message.old_state ? ` de ` : null}
                              <span>
                                {message.old_state ? message.old_state : null}
                              </span>
                              {` à `}
                              <span>{message.new_state}</span>
                            </li>
                          )}
                        </ul>
                      </div>
                    ) : null}
                    {message.message && (
                      <div className="message-text">
                        <p>{message.message}</p>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      {props.showMessageForm && (
        <Row id="messageForm">
          <Col xs="12">
            <MessagesForm
              priority={props.notification.priority}
              state={props.notification.state}
              createMessage={createMessage}
              closeMessageForm={() => props.setShowMessageForm(false)}
            />
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {
    updateNotification,
  })(MessagesList)
);
