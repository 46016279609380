import React from 'react';
import InvoiceDisplayNameComponent from "./InvoiceDisplayNameComponent";
import { getDateWithShortFormat, parseCustomer } from "../../../helpers/helper";
import InvoiceActionsComponent from "./InvoiceActionsComponent";
import { onTableChange } from "../../../helpers/table_helper";
import BootstrapTable from "react-bootstrap-table-next";
import { Badge } from "reactstrap";
import { computePaymentStateColor } from "../../../helpers/quotes_invoices_helper";

const InvoicesTraceTable = ({
  invoicesList,
  setFilters,
  filters,
  tablePagination,
  selectRow,
  isDepositInvoiceWithInvoice,
  toggleModalValidate,
  toggleModalDelete,
  toggleModalRefund,
  isDepositInvoice,
  fastInvoiceEditModalRef,
  removeCeePrime,
  projectConvert
}) => {
  const traceColumns = [
    {
      dataField: "number",
      text: "N° facture",
      sort: true,
      formatter: (cell, row) => (
        <InvoiceDisplayNameComponent
          row={row}
          isDepositInvoice={isDepositInvoice}
          fastInvoiceEditModalRef={fastInvoiceEditModalRef}
        />
      ),
    },
    {
      dataField: "invoice_date",
      text: "Date facture",
      formatter: (cell, row) => getDateWithShortFormat(row.invoice_date),
    },
    {
      dataField: "client",
      text: "Client",
      formatter: (cell, row) => (
        <React.Fragment>
          <h5 className="font-size-14 mb-1 table-text-black">
            {parseCustomer(row.order.client)}
          </h5>
          <p className="mb-0">
            {row.order.client.email === "" ? "Pas d'email" : null}
          </p>
        </React.Fragment>
      ),
    },
    {
      dataField: 'payment_state',
      text: 'État',
      formatter: cell => <Badge pill color={computePaymentStateColor(cell.name)}>{cell.label}</Badge>
    },
    {
      dataField: "total_price_ttc",
      text: "Total TTC",
      formatter: (cell, row) => removeCeePrime(row, row.total_ttc - row.total_price_deposits_ttc),
    },
    {
      dataField: "amount_left",
      text: "Restant à payer",
      formatter: (cell, row) => `${parseFloat(row.total_ttc - row.total_price_deposits_ttc - row.paid_total_ttc).toFixed(2)} €`,
    },
    {
      dataField: "action",
      text: '',
      formatter: (cell, row) => {
        return <InvoiceActionsComponent
          projectConvert={projectConvert}
          row={row}
          toggleModalValidate={toggleModalValidate}
          toggleModalDelete={toggleModalDelete}
          toggleModalRefund={toggleModalRefund}
          isDepositInvoiceWithInvoice={isDepositInvoiceWithInvoice}
        />
      },
      classes: "text-right"
    },
  ];

  return (
    <div>
      <BootstrapTable
        keyField="id"
        data={invoicesList}
        columns={traceColumns}
        defaultSortDirection="asc"
        remote={{ pagination: true, sort: true }}
        onTableChange={onTableChange.bind({ setFilters, filters })}
        pagination={tablePagination}
        selectRow={selectRow}
      />
    </div>
  );
}

export default InvoicesTraceTable;