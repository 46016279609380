import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_AGENDA_ITEMS,
  GET_AGENDA_ITEM,
  CREATE_AGENDA_ITEM,
  UPDATE_AGENDA_ITEM,
  DELETE_AGENDA_ITEM,
} from "./actionTypes";
import {
  getAgendaItemsSuccess,
  getAgendaItemSuccess,
  createAgendaItemSuccess,
  updateAgendaItemSuccess,
  deleteAgendaItemSuccess,
  agendaItemsApiError,
} from "./actions";

import { agendaItems } from "../../api";

function* getAgendaItems({ payload: { query } }) {
  try {
    const response = yield call(agendaItems.getAll, query);
    yield put(getAgendaItemsSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          agendaItemsApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des rendez-vous"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(agendaItemsApiError(error.response.data.message));
      }
    } else {
      yield put(agendaItemsApiError(error.message));
    }
  }
}

function* getAgendaItem({ payload: { id } }) {
  try {
    const response = yield call(agendaItems.getOne, id);
    yield put(getAgendaItemSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          agendaItemsApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des rendez-vous"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(agendaItemsApiError(error.response.data.message));
      }
    } else {
      yield put(agendaItemsApiError(error.message));
    }
  }
}

function* createAgendaItem({ payload: { agendaItem, history } }) {
  try {
    const response = yield call(agendaItems.create, agendaItem);
    yield put(createAgendaItemSuccess(response));
    // history.push("/agencies?success_add=1");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          agendaItemsApiError("Vous n'êtes pas autorisé à créer un rendez-vous")
        );
      } else if (
        error.response.status === 400 &&
        error.response.data.errors.length > 0
      ) {
        if (
          error.response.data.errors[0].detail.name &&
          error.response.data.errors[0].detail.name[0] === "can't be blank"
        ) {
          yield put(agendaItemsApiError("Le nom doit être présent"));
        }
      } else if (error.response.data !== undefined) {
        yield put(agendaItemsApiError(error.response.data.message));
      }
    } else {
      yield put(agendaItemsApiError(error.message));
    }
  }
}

function* updateAgendaItem({ payload: { id, agendaItem, history } }) {
  try {
    const response = yield call(agendaItems.update, id, agendaItem);
    yield put(updateAgendaItemSuccess(response));
    // history.push("/agencies?success_update=1");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          agendaItemsApiError(
            "Vous n'êtes pas autorisé à modifier ce rendez-vous"
          )
        );
      } else if (error.response.status === 404) {
        yield put(agendaItemsApiError("Le rendez-vous n'a pas été trouvé"));
      } else if (
        error.response.status === 400 &&
        error.response.data.errors.length > 0
      ) {
        if (
          error.response.data.errors[0].detail.name &&
          error.response.data.errors[0].detail.name[0] === "can't be blank"
        ) {
          yield put(agendaItemsApiError("Le nom doit être présent"));
        }
      } else if (error.response.data !== undefined) {
        yield put(agendaItemsApiError(error.response.data.message));
      }
    } else {
      yield put(agendaItemsApiError(error.message));
    }
  }
}

function* deleteAgendaItem({ payload: { id, history } }) {
  try {
    const response = yield call(agendaItems.delete, id);
    yield put(deleteAgendaItemSuccess(response));
    // history.push("/agencies?success_delete=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        agendaItemsApiError(
          "Vous n'êtes pas autorisé à supprimer ce rendez-vous"
        )
      );
    } else if (error.response.data !== undefined) {
      yield put(agendaItemsApiError(error.response.data.message));
    } else {
      yield put(agendaItemsApiError(error.message));
    }
  }
}

export function* watchGetAgendaItems() {
  yield takeEvery(GET_AGENDA_ITEMS, getAgendaItems);
}

export function* watchGetAgendaItem() {
  yield takeEvery(GET_AGENDA_ITEM, getAgendaItem);
}

export function* watchCreateAgendaItem() {
  yield takeEvery(CREATE_AGENDA_ITEM, createAgendaItem);
}

export function* watchUpdateAgendaItem() {
  yield takeEvery(UPDATE_AGENDA_ITEM, updateAgendaItem);
}

export function* watchDeleteAgendaItem() {
  yield takeEvery(DELETE_AGENDA_ITEM, deleteAgendaItem);
}

function* agendaItemsSaga() {
  yield all([
    fork(watchGetAgendaItems),
    fork(watchGetAgendaItem),
    fork(watchCreateAgendaItem),
    fork(watchUpdateAgendaItem),
    fork(watchDeleteAgendaItem),
  ]);
}

export default agendaItemsSaga;
