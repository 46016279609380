import * as Yup from "yup";
import { PHONE_REGEX } from "../../../helpers/validator";

export const updateValidationSchema = Yup.object().shape({
  name: Yup.string().required("Veuillez compléter ce champ"),
  description: Yup.string().required("Veuillez compléter ce champ"),
  phone: Yup.string()
    .test("format", "Format invalide", (val) => {
      return !isNaN(val);
    })
    .trim()
    .matches(PHONE_REGEX, "Veuillez saisir un numéro de fixe valide")
    .required(),
  email: Yup.string().test("format", "Le champ doit être une adresse email", (val) => val === undefined ? false : val.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null).required("Veuillez compléter ce champ"),
  url: Yup.mixed().required("Veuillez choisir une image"),
});
