export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";

export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";

export const GET_PAYMENTS_DETAILS = "GET_PAYMENTS_DETAILS";
export const GET_PAYMENTS_DETAILS_SUCCESS = "GET_PAYMENTS_DETAILS_SUCCESS";

export const CREATE_INVOICE = "CREATE_INVOICE";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";

export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";

export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";

export const VALIDATE_INVOICE = "VALIDATE_INVOICE";
export const VALIDATE_INVOICE_SUCCESS = "VALIDATE_INVOICE_SUCCESS";

export const GENERATE_ZIP = "GENERATE_ZIP";
export const GENERATE_ZIP_SUCCESS = "GENERATE_ZIP_SUCCESS";

export const SEND_INVOICE_EMAILS = "SEND_INVOICE_EMAILS";
export const SEND_INVOICE_EMAILS_SUCCESS = "SEND_INVOICE_EMAILS_SUCCESS";

export const INVOICES_API_ERROR = "INVOICES_API_ERROR";

export const MIGRATE_ORDER_FROM_INVOICE = "MIGRATE_ORDER_FROM_INVOICE";
export const MIGRATE_ORDER_FROM_INVOICE_SUCCESS = "MIGRATE_ORDER_FROM_INVOICE_SUCCESS";
