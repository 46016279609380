import {History} from "history";
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FlashMessage from "../../components/Common/FlashMessage";
import SearchFilterWithButton from "../../components/Common/SearchFilterWithButton";
import { match } from 'react-router';
import {useState} from "react";
import Client from "../../models/client";
import {customers} from "../../api";
import GlobalLoader from "../../components/Common/GlobalLoader";
import { parseCustomerName } from "../../helpers/customer_helper";

interface RequestsAddProps {
  match: match<{ id: string }>,
  history: History
}

export default function RequestsAdd({
  history
}: RequestsAddProps){
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function onSearchClient(q:string){
    setLoading(true);
    customers.getAll({query:q}).then(({data})=>{
      setClients(data.objects);
      setLoading(false);
    }).catch(()=>{
      setLoading(false);
    });
  }

  return(
    <>
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs
          title="Nouvelle demande"
          breadcrumbTitle="Suivi des demandes"
          breadcrumbItem="Nouvelle demande"
        />
        <FlashMessage/>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-0">
                  <Row className="pr-2 pl-2 d-flex">
                    <Col>Rechercher un client</Col>
                  </Row>
                </CardTitle>
                <Row className="mb-3 mt-3">
                  <Col xs={"12"}>
                    <SearchFilterWithButton
                      onSearch={(q)=>{onSearchClient(q)}}
                      placeholder={"Rechercher un client"}
                      defaultValue={""}
                      containerClasses={"mt-2"}
                      inputClasses={"w-4xl"}
                      autosearch={true}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {loading ? <GlobalLoader/> : [...clients].map((c)=>(
            <Col xs={"4"} key={c.id+"client_card"}>
              <div className="card p-3">
                <div className="d-flex align-items-center">
                  <div className="avatar-2sm d-inline-block">
                    <span className="avatar-title rounded-circle font-size-18">
                      {(c.lastname || c.firstname || "?").charAt(0)}
                    </span>
                  </div>
                  <h6 className="mb-0 ml-3 font-size-md font-weight-semibold">{parseCustomerName(c)}</h6>
                </div>
                <div className="d-flex flex-column pl-4 mt-3 font-size-md">
                  <div><i className="bx bx-map mr-2"/>{c.address} {c.zip_code} {c.city}</div>
                  <div><i className="bx bx-phone mr-2"/>{c.mobile_phone}</div>
                  <div><i className="bx bx-envelope mr-2"/>{c.email}</div>
                </div>
                <div className="d-flex justify-content-end py-1 px-3 bg-gray-300 mb-n3 mx-n3 mt-3 rounded-bottom">
                  <button className="btn bg-transparent shadow-none d-flex align-items-center text-link" type="button"
                    onClick={()=>history.push(`/requests/customers/${c.id}/show`)}
                  >
                    <i className="bx bx-show mr-1"/>Voir
                  </button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
    </>
  );
}