import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_PRODUCTS,
  GET_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from "./actionTypes";
import {
  getProductsSuccess,
  getProductSuccess,
  createProductSuccess,
  updateProductSuccess,
  deleteProductSuccess,
  productApiError,
} from "./actions";

import { products } from "../../api";

function* getProducts({ payload: { query } }) {
  try {
    const response = yield call(products.getAll, query);
    yield put(getProductsSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          productApiError(
            "Vous n'êtes pas autorisé à accéder au catalogue des produits"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(productApiError(error.response.data.message));
      }
    } else {
      yield put(productApiError(error.message));
    }
  }
}

function* getProduct({ payload: { id } }) {
  try {
    const response = yield call(products.getOne, id);
    yield put(getProductSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          productApiError(
            "Vous n'êtes pas autorisé à accéder aux informations de ce produit"
          )
        );
      } else if (error.response.status === 404) {
        yield put(productApiError("Le produit n'a pas été trouvé"));
      } else if (error.response.data !== undefined) {
        yield put(productApiError(error.response.data.message));
      }
    } else {
      yield put(productApiError(error.message));
    }
  }
}

function* createProduct({ payload: { product, history = false } }) {
  try {
    const response = yield call(products.create, product);
    yield put(createProductSuccess(response));
    if (history) {
      history.push("/products?success_add=1");
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          productApiError("Vous n'êtes pas autorisé à ajouter un produit")
        );
      } else if (
        error.response.status === 400 &&
        error.response.data.errors.length > 0
      ) {
        if (
          error.response.data.errors[0].detail.civility &&
          error.response.data.errors[0].detail.civility[0] === "can't be blank"
        ) {
          yield put(productApiError("La civilité doit être présente"));
        }
      } else if (error.response.data !== undefined) {
        yield put(productApiError(error.response.data.message));
      }
    } else {
      yield put(productApiError(error.message));
    }
  }
}

function* updateProduct({ payload: { id, product, history = false } }) {
  try {
    const response = yield call(products.update, id, product);
    yield put(updateProductSuccess(response));
    if (history) {
      history.push(`/product/detail/${id}?success_update=1`);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          productApiError("Vous n'êtes pas autorisé à modifier un produit")
        );
      } else if (error.response.status === 404) {
        yield put(productApiError("Le produit n'a pas été trouvé"));
      } else if (
        error.response.status === 400 &&
        error.response.data.errors.length > 0
      ) {
        if (
          error.response.data.errors[0].detail.civility &&
          error.response.data.errors[0].detail.civility[0] === "can't be blank"
        ) {
          yield put(productApiError("La civilité doit être présente"));
        }
      } else if (error.response.data !== undefined) {
        yield put(productApiError(error.response.data.message));
      }
    } else {
      yield put(productApiError(error.message));
    }
  }
}

function* deleteProduct({ payload: { id, history } }) {
  try {
    const response = yield call(products.delete, id);
    yield put(deleteProductSuccess(response));
    history.push("/products?success_delete=1");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          productApiError("Vous n'êtes pas autorisé à supprimer ce produit")
        );
      } else if (error.response.data !== undefined) {
        yield put(productApiError(error.response.data.message));
      }
    } else {
      yield put(productApiError(error.message));
    }
  }
}

export function* watchGetProducts() {
  yield takeEvery(GET_PRODUCTS, getProducts);
}

export function* watchGetProduct() {
  yield takeEvery(GET_PRODUCT, getProduct);
}

export function* watchCreateProduct() {
  yield takeEvery(CREATE_PRODUCT, createProduct);
}

export function* watchUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT, updateProduct);
}

export function* watchDeleteProduct() {
  yield takeEvery(DELETE_PRODUCT, deleteProduct);
}

function* productsSaga() {
  yield all([
    fork(watchGetProducts),
    fork(watchGetProduct),
    fork(watchCreateProduct),
    fork(watchUpdateProduct),
    fork(watchDeleteProduct),
  ]);
}

export default productsSaga;
