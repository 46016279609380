import { all } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import UsersSaga from "./users/saga";
import RolesSaga from "./roles/saga";
import AgenciesSaga from "./agencies/saga";
import FinancersSaga from "./financers/saga";
import ProjectsSaga from "./projects/saga";
import ProjectStatesSaga from "./project_states/saga";
import ScalesSaga from "./scales/saga";
import CustomersSaga from "./customers/saga";
import InvoicesSaga from "./invoices/saga";
import OrdersSaga from "./orders/saga";
import VatsSaga from "./vats/saga";
import FreeFieldsSaga from "./free_fields/saga";
import ProductsSaga from "./products/saga";
import CategoriesSaga from "./categories/saga";
import SubCategoriesSaga from "./sub_categories/saga";
import StatisticsSaga from "./statistics/saga";
import AgendaItemsSaga from "./calendar/saga";
import DepositsSaga from "./deposits/saga";
import QuotesSaga from "./quotes/saga";
import NotificationsSaga from "./notifications/saga";
import abonnementStatesSaga from "./abonnement_states/saga";
import formulasSaga from "./formulas/saga";
import subscriptionsSaga from "./subscriptions/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuthSaga(),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    UsersSaga(),
    RolesSaga(),
    AgenciesSaga(),
    FinancersSaga(),
    ProjectsSaga(),
    ProjectStatesSaga(),
    ScalesSaga(),
    CustomersSaga(),
    InvoicesSaga(),
    OrdersSaga(),
    VatsSaga(),
    FreeFieldsSaga(),
    ProductsSaga(),
    CategoriesSaga(),
    SubCategoriesSaga(),
    StatisticsSaga(),
    AgendaItemsSaga(),
    DepositsSaga(),
    QuotesSaga(),
    NotificationsSaga(),
    abonnementStatesSaga(),
    formulasSaga(),
    subscriptionsSaga(),
  ]);
}
