import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, CardBody, CardTitle, Card } from "reactstrap";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import NotificationsForm from "./FormComponent/NotificationsForm";

// Validation Schema
import { createValidationSchema } from "./FormValidationSchemas/createValidationSchema";

import { createNotification } from "../../../store/actions";

const NotificationAdd = (props) => {

  const params = new URLSearchParams(props.location.search);

  // Initialiser les valeurs du formulaire d'ajout de notification
  const initValues = {
    client_id: parseInt(params.get('client_id')) || null,
    user_to_id: parseInt(params.get('user_id')) || null,
    project_id: parseInt(params.get('project_id')) || null,
    priority: null,
    title: "",
    state: null,
    message: undefined,
  };

  /**
   * Créer une notification
   * @param {object} values Les valeurs pour créer la notification
   */
  const handleSubmit = (values) => {
    props.createNotification(
      {
        client_id: values.client_id,
        user_to_id: values.user_to_id,
        project_id: values.project_id,
        priority: values.priority?.value || null,
        title: values.title,
        state: values.state?.value || null,
        messages_attributes: !values.message ? null : [
          {
            message: values.message
          }
        ]
      },
      props.history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="notifications-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Relances" breadcrumbItem="Créer une relance" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-3">
                  <Row>
                    <Col>Créer une relance</Col>
                  </Row>
                </CardTitle>
                <Row>
                  <Col xs="12">
                    <NotificationsForm
                      initValues={initValues}
                      validationSchema={createValidationSchema}
                      handleSubmit={handleSubmit}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { loading } = state.Notifications;
  return { loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    createNotification,
  })(NotificationAdd)
);
