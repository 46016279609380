import {useContext, useState} from 'react';

import RadioButtonComponent from "./RadioButtonComponent";
import {FreeFieldProduct, FreeFieldFreeItem, FreeFieldSavedItem, FreeFieldTimelinedItem} from "./FreeFieldsComponent";

import {createItemValidationSchema} from "../FormValidationSchemas/itemValidationSchema";
import FreeFieldsTable from "./FreeFieldsTable";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {createFreeItemSave} from "../../../store/free_fields/actions";
import {Alert} from "reactstrap";
import VatsContext from "../Context/VatsContext";

export function computeTaxesfullPrice(taxeslessPrice, vat) {
    return (parseFloat(taxeslessPrice || "0") * ((vat / 100) + 1)).toFixed(2);
}

export function computeTaxeslessPrice(taxesfullPrice, vat) {
    return (parseFloat(taxesfullPrice || "0") / ((vat / 100) + 1)).toFixed(2)
}

// Compute the right form of item. Needed to re-render form
function computeForm(item, handleItemSubmit, unitsOptions, errors, formType) {
    const props = {handleSubmit: handleItemSubmit, unitsOptions: unitsOptions, errors: errors, formType: formType}
    switch (item) {
        case "PRODUCT" :
            return (<FreeFieldProduct {...props}/>);

        case "SAVED_ITEM" :
            return (<FreeFieldSavedItem {...props}/>);

        case "FREE_ITEM" :
            return (<FreeFieldFreeItem {...props}/>);

        case "TIMELINED_ITEM" :
            return (<FreeFieldTimelinedItem {...props}/>);

        default:
            return "";

    }
}

/**
 * Take care of render of items form and send items datas to the freeFieldTable
 **/
const AddItemComponent = ({
                              tableDatas,
                              setTableDatas,
                              errors,
                              error,
                              createFreeItemSave,
                              showErrors,
                              disabled,
                              formType,
                          }) => {

    // item selected from the checkbox
    const [item, setItem] = useState(null);
    const [targetCheckbox, setTargetCheckbox] = useState(null)

    const VATS = useContext(VatsContext);
    //const [tableDatas, setTableDatas] = useState([]);

    // Options for the unit selector
    const [unitsOptions] = useState([
        {
            label: "U",
            value: "U",
        },
        {
            label: "m",
            value: "m",
        },
        {
            label: "ml",
            value: "ml",
        },
        {
            label: "m2",
            value: "m2",
        },
        {
            label: "kg",
            value: "kg",
        },
        {
            label: "h",
            value: "h",
        },
    ])

    // errors messages in state for the free field form
    const [errorsItemForm, setErrorsItemForm] = useState({});


    // Called on inputs onClick, allow unchecking radio
    const handleClick = (event) => {
        if (event.target.value === item) {
            setItem(null);
            setTargetCheckbox(null);
            event.target.checked = false;
        } else {
            setItem(event.target.value);
            setTargetCheckbox(event.target);
        }
        setErrorsItemForm({});
    }

    // Up or down an item in the table depending of the offset
    const moveItem = (index, offset) => {
        setTableDatas(prev => {
            const tmpArray = [...prev.free_fields];
            const [tmp] = tmpArray.splice(index, 1);
            tmpArray.splice(index + offset, 0, tmp);
            return {...prev, free_fields: tmpArray};
        })
    }

    const deleteItem = (index) => {


        // Set the value _destroy to true allowing the api to deleting it
        const [item] = tableDatas.filter((_, i) => i === index);
        item._destroy = true;

        // set the
        setTableDatas(prev => ({
            ...prev,
            removed_free_fields: [...prev.removed_free_fields, item]
        }));
        setTableDatas(prev => ({
            ...prev,
            free_fields: prev.free_fields.filter((_, i) => i !== index)
        }));
    }

    const onCreateFreeItemSave = (values) => {
        createFreeItemSave(
            {
                quantity_unit: values.quantity_unit,
                timeline_begin_at: values.timeline_begin_at,
                free_field_save: {
                    title: `${values.quote_description.substring(0, 100)}...`,
                    description: values.quote_description,
                    price: values.price ? parseFloat(values.price) : 0.0,
                    price_ttc: values.price_ttc ? parseFloat(values.price_ttc) : 0.0,
                    vat: values.vat,
                    vat_id: values.vat.id,
                    eco_tax: values.eco_tax,
                },
            });
    };


    // handle function for the submit of the item forms directly send to items table
    const handleItemSubmit = (values) => {

        if (!values.quantity)
            values.quantity = 0;

        if (!values.vat)
            values.vat = {
                label: VATS[VATS.length - 1].name,
                value: VATS[VATS.length - 1]
            };

        if (!values.price) {
            values.price = 0;
            values.price_ttc = computeTaxesfullPrice(values.price, values.vat.value)
        }

        if (!values.eco_tax)
            values.eco_tax = 0;

        const validation = createItemValidationSchema.validate(values, {abortEarly: false});


        validation.then(ret => {

            setErrorsItemForm({});

            console.log(ret);

            // set time to the current time to update created_at field at creation
            let time = Date.now();

            if (values.save)
                onCreateFreeItemSave(values);

            // Add a quantity unit for display in the bootstrapTable
            ret.workforce = ret.workforce.map(work => {
                work.isManpower = true;
                work.quantity_unit = "U";
                work.product_id = work.id;
                work.id = null;
                work.created_at = time++;
                work.description = work.quote_description;
                return work;
            });

            // When we add a new item, the id need to be null because it's not a modified product
            ret.id = null;

            setTableDatas(prev => ({
                ...prev,
                free_fields: [
                    ...prev.free_fields,
                    {
                        ...ret,
                        description: ret.quote_description ? ret.quote_description : ret.description,
                        isManpower: false,
                        created_at: time,
                    },
                    ...ret.workforce]
            }));

            setItem(null);
            targetCheckbox.checked = false;
            setTargetCheckbox(null);
        }).catch(ret => {

            // browse each errors and put them in error state
            // looks like this : {name0: message0, name1: message1, ...}
            ret.inner.forEach((err => {
                setErrorsItemForm(prev => ({
                    ...prev,
                    [err.path]: err.message
                }));
            }));


        });
    }


    const nameChoice = "typeItem";

    return (
        <div className="mb-4">
            {/* Define what type of item will be added */}
            <FreeFieldsTable
                datas={tableDatas}
                setTableDatas={setTableDatas}
                unitOptions={unitsOptions}
                moveItem={moveItem}
                deleteItem={deleteItem}
                error={errors.free_fields}
                showErrors={showErrors}
                disabled={disabled}
            />
            <div className="items-container">
                <label>Ajouter un article</label>
                {/* Product */}
                <div className="custom-checkbox">
                    <RadioButtonComponent
                        id="productID"
                        label="Produits"
                        name={nameChoice}
                        type="radio"
                        value="PRODUCT"
                        handleClick={handleClick}
                        isDisabled={disabled}
                    />
                </div>


                {/* Saved item */}
                <div className="custom-checkbox">
                    <RadioButtonComponent
                        id="savedItemID"
                        label="Article sauvegardé"
                        name={nameChoice}
                        type="radio"
                        value="SAVED_ITEM"
                        handleClick={handleClick}
                        isDisabled={disabled}
                    />
                </div>

                {/* Free item */}
                <div className="custom-checkbox">
                    <RadioButtonComponent
                        id="freeItemID"
                        label="Article libre"
                        name={nameChoice}
                        type="radio"
                        value="FREE_ITEM"
                        handleClick={handleClick}
                        isDisabled={disabled}
                    />
                </div>

                {/* Timelined item */}
                <div className="custom-checkbox">
                    <RadioButtonComponent
                        id="timelinedItemID"
                        label="Article de type échéancier"
                        name={nameChoice}
                        type="radio"
                        value="TIMELINED_ITEM"
                        handleClick={handleClick}
                        isDisabled={disabled}
                    />
                </div>
            </div>
            {error && error ? (
                <Alert color="danger" className="d-inline-block">
                    {error}
                </Alert>
            ) : null}
            {/* The form is change regarding to the type of item asked */}
            {computeForm(item, handleItemSubmit, unitsOptions, errorsItemForm, formType)}
        </div>
    );
}

const mapStatetoProps = (state) => {
    const {loading, error} = state.FreeFields;
    return {loading, error};
};

export default withRouter(
    connect(mapStatetoProps, {
        createFreeItemSave,
    })(AddItemComponent)
);
