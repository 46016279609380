import Request from "../../../../models/request";
import {Col, Row} from "reactstrap";
import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

export default function RequestDetailClientTab({request}:{request:Request}){
  return (
    <Row className="pr-2 pl-2 d-flex font-size-14 text-theme-color">
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Client : </span>
        <Link to={!request?.client ? "#" : `/customer/detail/${request?.client?.id}`} className="font-weight-light text-theme-color hover:text-theme-color">{request?.client?.lastname} {request?.client?.firstname}</Link>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Date : </span>
        <span className="font-weight-light">{moment(request?.request_at).format("DD/MM/YY")}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Heure : </span>
        <span className="font-weight-light">{moment(request?.request_at).format("HH:mm")}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">CP : </span>
        <span className="font-weight-light">{request?.zip_code}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Adresse : </span>
        <span className="font-weight-light">{request?.client?.address}, {request?.client?.city}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Produit : </span>
        <Link to={!request?.project_id ? "#" : `/projects-overview/${request?.project_id}`} className="font-weight-light  text-theme-color hover:text-theme-color">{request?.project_name || "Aucun projet/dossier"}</Link>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Téléphone : </span>
        <span className="font-weight-light">{request?.client_phone}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Interlocuteur : </span>
        <Link  to={!request?.interlocutor ? "#" : `/collaborator/detail/${request?.interlocutor?.id}`} className="font-weight-light text-theme-color hover:text-theme-color">{request?.interlocutor?.lastname} {request?.interlocutor?.firstname}</Link>
      </Col>
    </Row>
  );
}