
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  GET_PROJECT_STATES,
} from './actionTypes';

import {
  getProjectStatesSuccess,
  projectStatesApiError,
} from "./actions";

import { projectStates } from "../../api";

function* getProjectStates({payload : { query }}){
  try {
    const response = yield call(projectStates.getAll, query);
    yield put(getProjectStatesSuccess(response));
  } catch (error) {
    if (error.response){
      if (error.response.status === 401){
          yield put(
            projectStatesApiError(
                  "Vous n'êtes pas autorisé à accéder à la liste des états d'un projet"
              )
          );
      } else if (error.response.data !== undefined) {
          yield put(projectStatesApiError(error.response.data.message));
      }
    } else {
        yield put(projectStatesApiError(error.message));
    }
  }
}

export function* watchGetProjectStates(){
  yield takeEvery(GET_PROJECT_STATES, getProjectStates);
}

function* projectStatesSaga(){
  yield all([
    fork(watchGetProjectStates)
  ])
}

export default projectStatesSaga;