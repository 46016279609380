import React from "react";

import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

// Helpers
import { getDateWithShortFormat } from "../../../../helpers/helper";

// icons
import { ReactComponent as ImgDelete } from "../../../../assets/images/icons/minus.svg";
import { ReactComponent as ImgAdd } from '../../../../assets/images/icons/plus.svg';
import {
  parseCustomerAddress,
  parseCustomerName,
  parseCustomerPhone
} from "../../../../helpers/customer_helper";
import { isProjectInTour } from "../../../../helpers/map_helper";
import TechnicalMeetingFormWrapper from "../../FormComponents/TechnicalMeetingFormWrapper";
import { CustomMarker } from "../CustomMarker";

interface ModalMapProps {
  waypoints: CustomMarker[],
  addWaypoint: (marker: CustomMarker) => void,
  deleteWaypoint: (marker: CustomMarker) => void,
  onUpsert: () => void,
}

interface ModalMapState {
  modalDetail: boolean,
  marker: CustomMarker | null,
}

const defaultState: ModalMapState = {
  marker: null,
  modalDetail: true
}

class ModalMap extends React.Component<ModalMapProps, ModalMapState> {
  formRef: React.RefObject<TechnicalMeetingFormWrapper>;
  constructor(props: ModalMapProps) {
    super(props);
    this.formRef = React.createRef();

    this.state = defaultState
  }

  open(marker: CustomMarker) {
    this.setState({ marker });
  }

  private closeMapModal() {
    this.setState(defaultState);
  }

  private toggleModalDetail() {
    this.setState({ modalDetail: !this.state.modalDetail });
  }

  private async onValid() {
    await this.formRef.current!.upsert();
    this.props.onUpsert();
    this.closeMapModal()
  }

  render() {
    const { waypoints, addWaypoint, deleteWaypoint } = this.props;
    const { modalDetail, marker } = this.state;

    let technicalVisitAlert: JSX.Element | null = null;

    if (!!!marker?.entity?.project) {
      technicalVisitAlert = (
        <Alert
          color='warning'
        >
          Pas de projet associé
        </Alert>
      )
    }

    return (
      <Modal isOpen={marker != null} toggle={this.closeMapModal.bind(this)}>
        <ModalHeader
          className="calendar-modal-header"
          toggle={this.closeMapModal.bind(this)}
          tag="div"
        >
          {marker && <>
            {modalDetail ? (
              <span className="w-100 d-flex justify-content-between">
                <h4>Détail de la visite technique</h4>
                {marker!.entity.project && isProjectInTour(waypoints, marker!)
                  ? <Button
                      className='custom-map-buttons delete-button-map'
                      onClick={() => {
                        this.closeMapModal();
                        deleteWaypoint(marker!);
                      }}
                    >
                      <ImgDelete />
                    </Button>
                  : <Button
                      className='custom-map-buttons add-button-map'
                      onClick={() => {
                        this.closeMapModal();
                        addWaypoint(marker!);
                      }}
                    >
                      <ImgAdd />
                    </Button>
                }
                {marker.entity.project?.id && <Button
                  onClick={this.toggleModalDetail.bind(this)}
                  className="bx bx-pencil custom-header-button"
                ></Button>}
              </span>
            ) : (
              <span className="w-100 d-flex justify-content-between">
                <h4>Ajouter une visite technique</h4>
                <Button
                  onClick={this.toggleModalDetail.bind(this)}
                  className="bx bx-arrow-back custom-header-button"
                ></Button>
              </span>
            )}</>}
        </ModalHeader>
        <ModalBody>
          {technicalVisitAlert}
          {marker && <>
            {modalDetail && <div>
              <h4 className="mb-3">
                {parseCustomerName(marker!.entity.client)}
              </h4>
              <div>
                <p>
                  <i className="bx bx-been-here mr-3"></i>
                  {parseCustomerAddress(marker!.entity.client)}
                </p>
                <p>
                  <i className="bx bx-phone mr-3"></i>
                  {parseCustomerPhone(marker!.entity.client)}
                </p>
                <p>
                  <i className="bx bx-calendar mr-3"></i>
                  {`Date de vente : ${marker!.entity.project?.sell_date
                    ? getDateWithShortFormat(marker!.entity.project?.sell_date)
                    : "NR"
                    }`}
                </p>
                <p className="d-flex align-items-baseline">
                  <i className="bx bx-comment mr-3"></i>
                  {`Observation : ${marker!.entity.project?.comment_vt || "NR"}`}
                </p>
              </div>
              <div>
                <h5>Produits vendus</h5>
                <div className="map-modal-list-items">
                  <ul>
                    <li>{ marker.entity.product_name }</li>
                    {/* {marker!.entity.project?.order?.free_items.map(
                      (free_item: FreeItem, index: number) => (
                        <li key={`f_i_${index}`}>{free_item.free_field.title}</li>
                      )
                    )}
                    {marker!.entity.project?.order?.product_items.map(
                      (product_item: ProductItem, index: number) => (
                        <li key={`f_i_${index}`}>
                          {product_item.product.commercial_name ||
                            product_item.product.title}
                        </li>
                      )
                    )} */}
                  </ul>
                </div>
              </div>
            </div>}
            {!modalDetail && <TechnicalMeetingFormWrapper marker={marker} ref={this.formRef} />}
          </>}
        </ModalBody>
        {!modalDetail && <ModalFooter>
          <Button
            onClick={this.closeMapModal.bind(this)}
            className="btn btn-secondary waves-effect"
          >
            Annuler
          </Button>
          <Button
            onClick={this.onValid.bind(this)}
            className="btn btn-green waves-effect waves-light"
          >
            Valider
          </Button>
        </ModalFooter>
        }
      </Modal>
    );
  }
};

export default ModalMap;
