import Financer from "./financer";
import Order from "./order";
import Scale from "./scale";
import ScaleItem from "./scale_item";
import ProjectState from "./project_state";
import AgendaItem from "./agenda_item";
import Client from "./client";
import Disponibility from "./disponibility";

export enum TypeOfFinancementEnum {
  COMPTANT = "comptant",
  FINANCEMENT = "financement"  
}

export enum ComptantPaiementTypeEnum {
  BANK_CHECK = "Chèque",
  BANK_TRANSFER = "Virement"
}

export default interface Project {
  id?: number | null,
  type_of_financement?: TypeOfFinancementEnum | null,
  comptant_paiement_type?: ComptantPaiementTypeEnum | null,
  order?: Order | null,
  order_id?: number | null,
  client: Client,
  financer?: Financer | null,
  financer_id?: number | null,
  scale?: Scale | null,
  project_state?: ProjectState | null,
  step?: string | null,
  scale_id?: number | null,
  scale_item?: ScaleItem | null,
  scale_item_id?: number | null,
  created_at?: Date | null,
  name_alias?: string | null,
  first_product_label?: string | null,
  first_subscription_label?: string | null,
  is_editable?: boolean,
  agenda_items?: AgendaItem[] | null,
  disponibility_vt?: Date | null,
  comment_vt?: string | null,
  sell_date?: Date | null,
  disponibilities?: Disponibility[],
}
