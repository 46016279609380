import * as Yup from "yup";

export const createValidationSchema = Yup.object().shape({
  label: Yup.string().required("Veuillez compléter ce champ"),
  min_monthly_payment: Yup.number().typeError("Format invalide").required("Veuillez compléter ce champ"),
  min_amount_loan: Yup.number().typeError("Format invalide").required("Veuillez compléter ce champ"),
  max_amount_loan: Yup.number().typeError("Format invalide").required("Veuillez compléter ce champ"),
  ref: Yup.string().required("Veuillez compléter ce champ"),
  starting_date: Yup.string().required("Veuillez compléter ce champ"),
  ending_date: Yup.string().required("Veuillez compléter ce champ"),
  first_monthly_payment_delay: Yup.number().typeError("Format invalide").required("Veuillez compléter ce champ"),
});
