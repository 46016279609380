import React, { useState, useEffect, useCallback } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Alert, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import loader
import GlobalLoader from "../../components/Common/GlobalLoader";

// Bootstrap Table
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import CategorySelector from "./ListComponents/CategorySelector";
import VatSelector from "./ListComponents/VatSelector";

// Helpers
import { isUnova, isLeader, isAdmin } from "../../helpers/roles_helper";
import { onTableChange } from "../../helpers/table_helper";

// actions
import {
  getProducts,
  createProduct,
  updateProduct,
  getCategories,
  getVats,
  productApiError,
  categoryApiError,
} from "../../store/actions";

// Image fond
import fond_1 from "../../assets/images/collaborators/fleur.png";

import { setQuery, parseQuery } from "../../helpers/helper";
import ProductFilter from "./Filters/ProductFilter";
import {products} from "../../api";
import SearchFilterWithButton from "../../components/Common/SearchFilterWithButton";

const ProductsList = (props) => {

  const query = parseQuery();

  const [filters, setFilters] = useState({
    sort_by: query.sort_by || '',
    sort_dir: query.sort_dir || '',
    page: query.page || 1,
    page_size: query.page_size || 25,
    category_id: query.category_id || '',
    sub_category_id: query.sub_category_id || '',
    brand: query.brand || '',
    query: query.query || ''
  });

  const applyFilter = useCallback(() => {
    props.getProducts.call(undefined,{
      page: filters.page,
      page_size: filters.page_size,
      sort: `${filters.sort_by} ${filters.sort_dir}`.trim(), // trim => remove space when filters is empty
      category_id: filters.category_id,
      sub_category_id: filters.sub_category_id,
      brand: filters.brand,
      query: filters.query
    }); // Update interface with new filter
  }, [filters, props.getProducts]);

  const emptyFilter = () => {
    setFilters({
      sort_by: '',
      sort_dir: '',
      page: 1,
      page_size: 25,
      category_id: '',
      sub_category_id: '',
      brand: '',
      query: ''
    })
  }

  const [flash_message, setFlash_message] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [newLineId, setNewLineId] = useState(-1);
  const [newLineError, setNewLineError] = useState(false);

  useEffect(() => {
    setProductsList(props.products);
  }, [props.products]);

  useEffect(() => {
    applyFilter();
    props.getCategories.call();
    props.getVats.call();
  }, [applyFilter, props.getCategories, props.getVats]);

  useEffect(() => {
    setQuery(filters, props.history); // Update query in path

    setNewLineId(-1);
    //productsList.shift(); WARN: if a bug is appeared on product list, it's maybe because of that, i cannot find what it does but create infinite loop glitch

    applyFilter();
  }, [filters, applyFilter, props.history]);

  function removeFlashMessage() {
    setFlash_message(!flash_message);
  }

  /**
   * Fonction permettant de définir si le rôle est autorisé ou non à ajouter et modifier des produits
   */
  const userIsAllowed = () => {
    return isUnova() || isLeader() || isAdmin() ? true : false;
  };

  /**
   * Ajoute une nouvelle ligne au tableau pour permettre un ajout rapide de produit
   */
  const addLineToTable = (duplicatedProduct = null) => {
    console.log(duplicatedProduct)
    if (newLineId === -1) {
      let newLine;
      if(!duplicatedProduct) {
        newLine = {
          id: Number.MAX_VALUE, // Temp id
          brand: "",
          category: null,
          commercial_name: "",
          price_ttc: "",
          promotional_offer_ttc: "",
          reference: "",
          sub_category: null,
          title: "",
          vat: null,
          workforces_ids: [],
          isNewLine: true
        };
      } else {
        newLine = {
          id: Number.MAX_VALUE, // Temp id
          brand: duplicatedProduct.brand,
          category: duplicatedProduct.category,
          commercial_name: duplicatedProduct.commercial_name,
          price_ttc: duplicatedProduct.price_ttc,
          promotional_offer_ttc: duplicatedProduct.promotional_offer_ttc,
          reference: duplicatedProduct.reference,
          sub_category: duplicatedProduct.sub_category,
          title: duplicatedProduct.title,
          vat: duplicatedProduct.vat,
          workforces_ids: [],
          isNewLine: true
        };
      }
      let tmp = productsList;
      tmp.unshift(newLine);
      setProductsList(tmp);
      setNewLineId(newLine.id);
    }
  };

  const cancelNewLine = () => {
    let tmp = productsList;
    tmp.splice(0, 1);
    setProductsList(tmp);
    setNewLineId(-1);
  }

  /**
   * Envoyer le nouveau produit à l'Api
   */
  const saveNewProduct = (row) => {
    if (row.category && row.vat) {
      let vatValue = props.vats.filter((vat) => vat.id === row.vat.id)[0];
      console.log(row)
      let product = {
        brand: row.brand,
        category: row.category ? row.category.id : "",
        commercial_name: row.commercial_name,
        price_ttc: row.price_ttc,
        price: row.price_ttc / (1 + vatValue.value / 100),
        promotional_offer_ttc: row.promotional_offer_ttc,
        promotional_offer:
          row.promotional_offer_ttc / (1 + vatValue.value / 100),
        reference: row.reference,
        workforces_ids: row.workforces_ids,
        sub_category: row.sub_category ? row.sub_category.id : "",
        title: row.title,
        vat: vatValue ? vatValue.id : "",
      };

      products.create(product).then(r =>{
        applyFilter();
        setNewLineId(-1);
        setNewLineError(false);
      });
    } else {
      if (row.category === null && row.vat === null) {
        setNewLineError(
          "Vous devez choisir une famille et une TVA pour créer le produit."
        );
      } else if (row.category === null) {
        setNewLineError(
          "Vous devez choisir une famille pour créer le produit."
        );
      } else if (row.vat === null) {
        setNewLineError("Vous devez choisir une TVA pour créer le produit.");
      }
    }
  };

  const columns = [
    {
      dataField: "title",
      text: "Titre (devis)",
      editable: userIsAllowed(),
      formatter:(cell, row) =>
      row.id === newLineId && cell === "" ? "Ajouter un titre" : cell,
    },
    {
      dataField: "commercial_name",
      text: "Nom commercial",
      classes: "isNotNecessary",
      headerClasses: "isNotNecessary",
      hidden:
        [
          "Main d'œuvre",
          "Contrat d'entretien",
          "Forfait révision"
        ].includes(props.categories.find((item) => item.id === filters.category_id)?.name),
      editable: userIsAllowed(),
      formatter:(cell, row) =>
      row.id === newLineId && cell === "" ? "Ajouter un nom commercial" : cell,
    },
    {
      dataField: "brand",
      text: "Marque",
      classes: "isNotNecessary",
      headerClasses: "isNotNecessary",
      hidden:
        [
          "Main d'œuvre",
          "Contrat d'entretien",
          "Forfait révision"
        ].includes(props.categories.find((item) => item.id === filters.category_id)?.name),
      editable: userIsAllowed(),
      formatter:(cell, row) =>
      row.id === newLineId && cell === "" ? "Ajouter une marque" : cell,
    },
    {
      dataField: "category",
      text: "Famille",
      formatter: (cell, row) =>
        row.category === null && row.id === newLineId ? "Ajouter une famille" : row.category === null ? "" : row.category.name,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <CategorySelector
          {...editorProps}
          rowIndex={rowIndex}
          value={row.category}
          categories={props.categories}
          placeholder="Famille"
        />
      ),
      editable: userIsAllowed(),
    },
    {
      dataField: "sub_category",
      text: "Catégorie",
      classes: "isNotNecessary",
      headerClasses: "isNotNecessary",
      formatter: (cell, row) =>
        row.sub_category === null && row.id === newLineId
          ? "Ajouter une catégorie"
          : row.sub_category?.name || "",
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        console.log("row input : ", row);
        
        return <CategorySelector
          {...editorProps}
          rowIndex={rowIndex}
          value={row.sub_category}
          categories={
            row.id === newLineId
              ? row.category === null
                ? []
                : props.categories.filter(
                    (category) => category.id === row.category.value
                  )[0].sub_categories
              : row.category
              ? row.category.sub_categories
              : []
          }
          placeholder="Catégorie"
          disabled={row.category ? false : true}
        />
      },
      editable: userIsAllowed(),
    },
    {
      dataField: "reference",
      text: "Référence",
      editable: userIsAllowed(),
      formatter:(cell, row) =>
      row.id === newLineId && cell === "" ? "Ajouter une référence" : cell,
    },
    {
      dataField: "vat",
      text: "TVA",
      formatter: (cell, row) =>
        row.vat === null && row.id === newLineId ? "Ajouter TVA" : row.vat === null ? "" : row.vat.name,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <VatSelector
          {...editorProps}
          rowIndex={rowIndex}
          value={row.vat}
          categories={props.vats}
          placeholder="TVA"
        />
      ),
      editable: userIsAllowed(),
    },
    {
      dataField: "price_ttc",
      text: "Prix TTC",
      formatter: (cell, row) =>
        row.price_ttc !== "" &&
        row.price_ttc !== null &&
        row.price_ttc !== undefined
          ? `${parseFloat(row.price_ttc).toFixed(2)} €`
          : row.id === newLineId ? "Ajouter un prix " : "",
      editable: userIsAllowed(),
    },
    {
      dataField: "promotional_offer_ttc",
      text: "Offre promo TTC",
      formatter: (cell, row) =>
        row.promotional_offer_ttc !== "" &&
        row.promotional_offer_ttc !== null &&
        row.promotional_offer_ttc !== undefined
          ? `${parseFloat(row.promotional_offer_ttc).toFixed(2)} €`
          : row.id === newLineId ? "Ajouter une offre " :"",
      classes: "isNotNecessary",
      headerClasses: "isNotNecessary",
      editable: userIsAllowed(),
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) =>
      
        row.id === newLineId ? (
          <>
            <Link
              to="#"
              className="d-flex align-items-center table-link pt-2 pb-2"
              onClick={() => saveNewProduct(row)}
            >
              Valider<i className="bx bx-plus-circle ml-1"/>
            </Link>
            <Link
              to="#"
              className="d-flex align-items-center table-link pt-2 pb-2"
              onClick={() => cancelNewLine()}
            >
              Annuler<i className="fas fa-times ml-1"/>
            </Link>
          </>
        ) : (
          <UncontrolledDropdown>
            <DropdownToggle className={"bg-transparent"}>
              <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
            </DropdownToggle>
            <DropdownMenu right className="bordered important-default-colors">
                <>
                  <DropdownItem onClick={() => { props.history.push(`/product/detail/${row.id}`) }}>Voir</DropdownItem>
                  <DropdownItem onClick={() => { window.open(`/product/detail/${row.id}`, '_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
                  <DropdownItem onClick={() => { addLineToTable(row) }}>Dupliquer</DropdownItem>
                </> 
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      
        ,
      editable: false,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="products-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Produits" breadcrumbItem="Tous les produits" />

          {props.loading ? (
            <GlobalLoader />
          ) : !props.pagination ? null : (
            <React.Fragment>
              <Row>
                <Col lg="12">
                  <Alert
                    color="success"
                    isOpen={query.success_add === "1"}
                    toggle={() => removeFlashMessage()}
                  >
                    Le produit à bien été créé.
                  </Alert>
                  <Alert
                    color="success"
                    isOpen={query.success_delete === "1"}
                    toggle={() => removeFlashMessage()}
                  >
                    Le produit à bien été suprimé.
                  </Alert>
                </Col>
              </Row>
              {newLineError ? (
                <Row>
                  <Col lg="12">
                    <Alert color="danger" toggle={() => setNewLineError(false)}>
                      {newLineError}
                    </Alert>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3">
                        <Row className="pr-2 pl-2 d-flex justify-content-between">
                          <Col>Tous les produits</Col>
                          <Col className="d-flex justify-content-end">
                            {userIsAllowed() ? (
                              <Link
                                to="#"
                                className="d-flex align-items-center text-theme-color-2"
                                onClick={() => addLineToTable()}
                              >
                                <i className="bx bx-plus-circle mr-1"></i>{" "}
                                Création rapide
                              </Link>
                            ) : null}
                            {userIsAllowed() ? (
                              <Link
                                to="/product/add"
                                className="d-flex align-items-center text-theme-color-2 ml-3"
                              >
                                <i className="bx bx-plus-circle mr-1"></i> Ajouter
                              </Link>
                            ) : null}
                          </Col>
                        </Row>
                      </CardTitle>
                      <Card>
                        <CardBody>
                          <ProductFilter filters={filters} setFilters={setFilters} emptyFilter={emptyFilter}/>
                          <div className="w-50 py-4">
                            <SearchFilterWithButton placeholder="Rechercher un produits" defaultValue={filters.query} onSearch={(query) => setFilters({...filters, query})}/>
                          </div>
                        </CardBody>
                      </Card>
                      <BootstrapTable
                        keyField="id"
                        data={productsList}
                        classes="table-centered"
                        columns={columns}
                        defaultSortDirection="asc"
                        sort={{dataField: filters.sort_by, order: (filters.sort_dir || "asc")}}
                        remote={{pagination: true, sort: true}}
                        onTableChange={onTableChange.bind({setFilters, filters})}
                        pagination={paginationFactory({
                          page: filters.page,
                          totalSize: props.pagination.total_count,
                          sizePerPage: filters.page_size,
                          sizePerPageList: [
                            { text: "Afficher 10 éléments", value: 10 },
                            { text: "Afficher 25 éléments", value: 25 },
                            { text: "Afficher 50 éléments", value: 50 },
                            { text: "Afficher 75 éléments", value: 75 },
                            { text: "Afficher 100 éléments", value: 100 },
                          ],
                        })}
                        rowStyle={(row, rowIndex) =>
                          row.id === newLineId
                            ? { backgroundColor: "#9cc31c33" }
                            : {}
                        }
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                          onStartEdit: (row, column, rowIndex, columnIndex) => {
                            let input = document.getElementsByClassName(
                              "editor edit-text"
                            );
                            input[0].placeholder = column.text;
                          },
                          afterSaveCell: (oldValue, newValue, row, column) => {
                            if (row.id === newLineId) {
                            } else if (oldValue !== newValue) {
                              console.log("row : ",row);
                              console.log("old value : ",oldValue);
                              console.log("new value : ",newValue);
                              let product = {
                                ...row,
                                category: row.category.id,
                                sub_category: row.sub_category
                                  ? row.sub_category.id
                                  : "",
                                vat: row.vat ? row.vat.id : "",
                              };
                              console.log("product starting : ", product);

                              if (
                                column.dataField === "category" ||
                                column.dataField === "sub_category"
                              ) {
                                product[column.dataField] = newValue.id;
                              } else if (column.dataField === "vat") {
                                product[column.dataField] = newValue.id;
                                product.price =
                                  row.price_ttc / (1 + newValue.value / 100);
                                  console.log("prix TTC: ", row.price_ttc);
                                  console.log("prix : ", product.price);
                                product.promotional_offer =
                                  row.promotional_offer_ttc /
                                  (1 + newValue.value / 100);
                                  console.log("prix offre TTC: ", row.promotional_offer_ttc);
                                  console.log("prix offre : ", product.promotional_offer);
                              } else if (column.dataField === "price_ttc") {
                                product[column.dataField] =
                                  newValue === "" ? 0.0 : newValue;

                                product.price =
                                  newValue === ""
                                    ? 0.0
                                    : newValue / (1 + row.vat.value / 100);
                                    console.log("TVA : ", row.vat);
                                    console.log("TVA : ", row.vat.value);
                                    console.log("prix TTC: ", newValue);
                                  console.log("prix : ", product.price);
                              } else if (
                                column.dataField === "promotional_offer_ttc"
                              ) {
                                product[column.dataField] =
                                  newValue === "" ? 0.0 : newValue;
                                product.promotional_offer =
                                  newValue === ""
                                    ? 0.0
                                    : newValue / (1 + row.vat.value / 100);
                              } else {
                                product[column.dataField] = newValue;
                              }

                              props.updateProduct(row.id, product);
                            }
                          },
                        })}
                      />
                    </CardBody>
                  </Card>
                  <div className="collaborators-footer-img">
                    <div className="collaborators-footer-img-main-container">
                      <div className="collaborators-footer-img-container">
                        <img src={fond_1} alt="Fond numéro 1" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { products, pagination, loading, error } = state.Products;
  const { categories } = state.Categories;
  const { vats } = state.Vats;
  return { products, categories, vats, pagination, loading, error };
};

export default withRouter(
  connect(mapStatetoProps, {
    getProducts,
    createProduct,
    updateProduct,
    getCategories,
    getVats,
    productApiError,
    categoryApiError,
  })(ProductsList)
);
