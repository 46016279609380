// Modals components
import {
    Modal,
    ModalHeader,
    ModalBody,
  } from "reactstrap";

  // Customer form 
import CustomerForm from "../FormComponent/CustomerForm";

// Validation for the customer form
import {createCustomerValidationSchema} from "../FormValidationSchemas/createCustomerValidationSchema";

/**
 * Modal opened when a new customer is created on the quote/invoices form 
 * 
 * @param isOpen defined if the modal is open
 * @param toggler function toggling the modal
 * **/
const CustomerModal = ({
    isOpen,
    toggler,
    client_type,
    createCustomer,
}) => {

    // create a new customer using an the createCustomer action 
    const handleSubmit = (values) => {
        createCustomer({
          civility: values.civility,
          firstname: values.firstname,
          lastname: values.lastname,
          firstname2: values.firstname2,
          lastname2: values.lastname2,
          address: values.address,
          additional_address: values.additional_address,
          zip_code: values.zip_code,
          city: values.city,
          address_construction_site: values.address_construction_site,
          additional_address_construction_site: values.additional_address_construction_site,
          city_construction_site: values.city_construction_site,
          zip_code_construction_site: values.zip_code_construction_site,
          mobile_phone: values.mobile_phone,
          mobile_phone2: values.mobile_phone2,
          fix_phone: values.fix_phone,
          email: values.email,
          email2: values.email2,
          client_type: values.client_type,
          company_name: values.company_name,
          siret: values.siret,
        });

          toggler();
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                toggler();
            }}
            size="lg"
        >
            <ModalHeader toggle={() => toggler()} tag="h4">
                Créer un prospect
            </ModalHeader>
            <ModalBody>
                <CustomerForm
                    initValues={{
                      civility: "",
                      firstname: "",
                      lastname: "",
                      firstname2: "",
                      lastname2: "",
                      address: "",
                      additional_address: "",
                      zip_code: "",
                      city: "",
                      mobile_phone: "",
                      mobile_phone2: "",
                      fix_phone: "",
                      email: "",
                      email2: "",
                      address_construction_site: "",
                      additional_address_construction_site: "",
                      city_construction_site: "",
                      zip_code_construction_site: "",
                      client_type: client_type,
                      company_name: "",
                      siret: "",
                    }}
                    validationSchema={createCustomerValidationSchema}
                    handleSubmit={handleSubmit}
                />
            </ModalBody>
        </Modal>
    );

}

export default CustomerModal;