import * as Yup from "yup";

export const createValidationSchema = Yup.object().shape({
  client_id: Yup.mixed().required("Veuillez compléter ce champ"),

  user_to_id: Yup.mixed().required("Veuillez compléter ce champ"),

  project_id: Yup.mixed().notRequired(),

  priority: Yup.mixed().required("Veuillez compléter ce champ"),

  title: Yup.string().required("Veuillez compléter ce champ"),

  state: Yup.mixed().notRequired(),
});
