import { Component, Dispatch, SetStateAction } from 'react';
import { Col, Row } from "reactstrap";
import { CustomMarker } from './CustomMarker';
import ListProjectWaypoints from "./ListProjectWaypoints";
import Map from "./Map";
import MapMarkerInfo from './MapMarkerInfo';

interface MapComponentProps {
  markers: CustomMarker[],
  waypoints: CustomMarker[],
  displayMapItemList: boolean,
  displayTourList: boolean,
  directionsResult?: google.maps.DirectionsResult,
  selectMarker: (entityId: number) => void,
  deleteWaypoint: (marker: CustomMarker) => void,
  moveWaypointOrder: (index: number, offset: number) => void,
  addWaypoint: (marker: CustomMarker) => void,
  setDirectionsResult: Dispatch<SetStateAction<google.maps.DirectionsResult | undefined>>,
  onShowModal: (marker: CustomMarker) => void
}

/**
 * Need to do a class component to be able to print it
 */
class MapComponent extends Component<MapComponentProps, {
  mapZoom: number,
  mapCenter: google.maps.LatLng | google.maps.LatLngLiteral
}> {

  constructor(props: MapComponentProps) {
    super(props);

    this.state = {
      mapZoom: 10,
      mapCenter: { lat: 45.84850742459741, lng: 1.2466925137565097 }
    }
  }

  selectMarker(coords: google.maps.LatLng | google.maps.LatLngLiteral, zoom: number, markerId: number) {
    this.setState({ mapCenter: coords, mapZoom: zoom });
    this.props.selectMarker(markerId);
  }


  render() {
    const {
      directionsResult,
      setDirectionsResult,
      displayMapItemList,
      displayTourList,
      markers,
      waypoints,
      moveWaypointOrder,
      deleteWaypoint,
      addWaypoint,
      onShowModal
    } = this.props;

    const {
      mapZoom,
      mapCenter,
    } = this.state;

    return (
      <Row className='printable-map-component'>
        <Col className='map-print' style={{ height: `75vh` }}>
          <Map
            defaultCenter={mapCenter}
            defaultZoom={mapZoom}
            markers={markers}
            onShowModal={onShowModal}
            waypoints={waypoints}
            setDirectionsResult={setDirectionsResult}
            directionsResult={directionsResult}
          />
        </Col>
        {!displayMapItemList ? '' :
          <Col lg="3" style={{ height: `75vh` }}>
            <div className="projects-list">
              <p className="projects-list-title">Liste des planifications</p>
              {this.props.markers
                .map((marker, index) => (<MapMarkerInfo marker={marker} index={index} onShowModal={onShowModal} waypoints={waypoints} addWaypoint={addWaypoint} deleteWaypoint={deleteWaypoint}/>))}
            </div>
          </Col>}
        {!displayTourList ? '' :
          <Col lg="3" style={{ height: `75vh` }}>
            <ListProjectWaypoints
              markers={waypoints}
              moveItemOrder={moveWaypointOrder.bind(this)}
              deleteItem={deleteWaypoint.bind(this)}
              selectMarker={this.selectMarker.bind(this)}
              directions={directionsResult}
            />
          </Col>}
      </Row>
    );
  }
}

export default MapComponent;