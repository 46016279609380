import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Row,
  Col,
  Alert,
} from 'reactstrap';


export enum ImportantEnum {
  DANGER = "danger",
  WARNING = "warning",
  SUCCESS = "success"
}

interface Message {
  importance: ImportantEnum,
  message: String
}

const messages : Message[] = [];
const event = new Event('on_flash_messages');

/**
 * Add Message with Importance level and string message
 *
 * To display Message, use FlashMessage.
 * @see FlashMessage
 * @author Florian RICHER
 */
export function addFlashMessage(importance: ImportantEnum, message: String) {
  messages.push({ importance, message });
  document.body.dispatchEvent(event);
}

/**
 * Display and consume all messages added with [addFlashMessage]
 *
 * @see addFlashMessage
 * @author Florian RICHER
 */
const FlashMessage = ({
  timeout = 6000
}: { timeout?: number | null }) => {
  // Avoid to consume Message early when page is not loaded
  // Only useful with Redux because page is loaded multiple times ;)
  let consumeTimer = useRef<NodeJS.Timeout | null>(null);
  let autoHideTimer = useRef<NodeJS.Timeout | null>(null);
  const [els, setEls] = useState<any>([]);

  const cleanConsumeTimer = useCallback(() => {
    if (consumeTimer.current) clearTimeout(consumeTimer.current);
    if (autoHideTimer.current) clearTimeout(autoHideTimer.current);
    consumeTimer.current = null;
    autoHideTimer.current = null;
  }, []);

  const consume = useCallback(() => {
    setEls(messages.map(message => (
      <Alert
        color={message.importance}
      >
        {message.message}
      </Alert>
    )))

    cleanConsumeTimer();

    consumeTimer.current = setTimeout(() => {
      messages.splice(0, messages.length);
      consumeTimer.current = null;
    }, 1000)

    if (timeout) {
      autoHideTimer.current = setTimeout(() => setEls([]), timeout)
    }
  }, [cleanConsumeTimer, timeout]);

  useEffect(() => {
    consume();
    document.body.addEventListener('on_flash_messages', consume);

    return () => {
      document.body.removeEventListener('on_flash_messages', consume);
      cleanConsumeTimer();
    }
  }, [cleanConsumeTimer, consume]);

  return (
    <Row>
      <Col lg="12">
        {els}
      </Col>
    </Row>
  )
}

export default FlashMessage;