import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import CustomerForm from "./FormComponent/CustomerForm";

// Validation Schema
import { updateValidationSchema } from "./FormValidationSchemas/updateValidationSchema";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {
  getCustomer,
  getUsers,
  updateCustomer,
  customerApiError,
} from "../../store/actions";

const CustomerEdit = (props) => {
  useEffect(() => {
    props.getCustomer.call(undefined,parseInt(window.location.pathname.split("/")[3], 10));
  }, [props.getCustomer]);

  useEffect(() => {
    props.getUsers.call(undefined,{ pagination: false });
  }, [props.getUsers]);

  const initValues = props.customer
    ? {
      // Identification du prospect
      civility:
        props.customer.civility === null ? "" : props.customer.civility,
      firstname:
        props.customer.firstname === null ? "" : props.customer.firstname,
      lastname:
        props.customer.lastname === null ? "" : props.customer.lastname,
      firstname2: props.customer.firstname2 || '',
      lastname2: props.customer.lastname2 || '',
      address: props.customer.address === null ? "" : props.customer.address,
      additional_address:
        props.customer.additional_address === null
          ? ""
          : props.customer.additional_address,
      zip_code:
        props.customer.zip_code === null ? "" : props.customer.zip_code,
      city: props.customer.city === null ? "" : props.customer.city,
      mobile_phone:
        props.customer.mobile_phone === null
          ? ""
          : props.customer.mobile_phone,
      mobile_phone2:
        props.customer.mobile_phone2 === null
          ? ""
          : props.customer.mobile_phone2,
      fix_phone:
        props.customer.fix_phone === null ? "" : props.customer.fix_phone,
      email: props.customer.email === null ? "" : props.customer.email,
      email2: props.customer.email2 === null ? "" : props.customer.email2,
      client_type:
        props.customer.client_type === null
          ? "Particulier"
          : props.customer.client_type,
      company_name:
        props.customer.company_name === null
          ? ""
          : props.customer.company_name,
      siret: props.customer.siret === null ? "" : props.customer.siret,
      address_construction_site:
        props.customer.address_construction_site === null
          ? ""
          : props.customer.address_construction_site,
      additional_address_construction_site:
        props.customer.additional_address_construction_site === null
          ? ""
          : props.customer.additional_address_construction_site,
      city_construction_site:
        props.customer.city_construction_site === null
          ? ""
          : props.customer.city_construction_site,
      zip_code_construction_site:
        props.customer.zip_code_construction_site === null
          ? ""
          : props.customer.zip_code_construction_site,
      exposed_city:
        props.customer.exposed_city === null
          ? ""
          : props.customer.exposed_city,
      is_owner: props.customer.is_owner,
      // Détail du logement
      main_resideence:
        props.customer.main_resideence === null
          ? ""
          : props.customer.main_resideence,
      owner_at:
        props.customer.owner_at === null ? "" : props.customer.owner_at,
      housing_seniority:
        props.customer.housing_seniority === null
          ? ""
          : props.customer.housing_seniority,
      surface_habitation:
        props.customer.surface_habitation === null
          ? ""
          : props.customer.surface_habitation,
      floors_number:
        props.customer.floors_number === null
          ? ""
          : props.customer.floors_number,
      is_isolate_floors:
        props.customer.is_isolate_floors === null
            ? ""
            : props.customer.is_isolate_floors,
        attic_type:
          props.customer.attic_type === null ? "" : props.customer.attic_type,
        attic_isulated_at:
          props.customer.attic_isulated_at === null
            ? ""
            : new Date(new Date(props.customer.attic_isulated_at).setHours(0)),
        attic_isulation_type:
          props.customer.attic_isulation_type === null
            ? ""
            : props.customer.attic_isulation_type,
        is_attic_isulation:
          props.customer.is_attic_isulation === null
            ? ""
            : props.customer.is_attic_isulation,
        window_type:
          props.customer.window_type === null ? "" : props.customer.window_type,
        windows_number:
          props.customer.windows_number === null
            ? ""
            : props.customer.windows_number,
        ecs_production_type:
          props.customer.ecs_production_type === null
            ? ""
            : props.customer.ecs_production_type,
        ecs_production_type_annual_cost:
          props.customer.ecs_production_type_annual_cost === null
            ? ""
            : props.customer.ecs_production_type_annual_cost,
        heading_type:
          props.customer.heading_type === null
            ? ""
            : props.customer.heading_type,
        heading_type_annual_cost:
          props.customer.heading_type_annual_cost === null
            ? ""
            : props.customer.heading_type_annual_cost,
        appointment_explication:
          props.customer.appointment_explication === null
            ? ""
            : props.customer.appointment_explication,
        // Définition du projet potentiel
        building_work_type:
          props.customer.building_work_type === null
            ? ""
            : props.customer.building_work_type,
        already_benefit_energy_study:
          props.customer.already_benefit_energy_study === null
            ? ""
            : props.customer.already_benefit_energy_study,
        already_benefit_grant:
          props.customer.already_benefit_grant === null
            ? ""
            : props.customer.already_benefit_grant,
        // Rencontre du prospect
        first_contact_date:
          props.customer.first_contact_date === null
            ? ""
            : new Date(props.customer.first_contact_date),
        contact_origin:
          props.customer.contact_origin === null
            ? ""
            : props.customer.contact_origin,
        customer: props.customer.client === null
            ? ""
            : {
                label:
                  props.customer.client.client_type === "Professionnel"
                    ? props.customer.client.company_name
                    : `${props.customer.client.civility} ${props.customer.client.lastname} ${props.customer.client.firstname}`,
                value: props.customer.client.id,
              },
        user: props.customer.user === null ? "" : props.customer.user,
        // Positionnement du rendez-vous
        appointment_take_with:
          props.customer.appointment_take_with === null
            ? ""
            : props.customer.appointment_take_with,
        function1:
          props.customer.function1 === null ? "" : props.customer.function1,
        work_council1:
          props.customer.work_council1 === null
            ? ""
            : props.customer.work_council1,
        function2:
          props.customer.function2 === null ? "" : props.customer.function2,
        work_council2:
          props.customer.work_council2 === null
            ? ""
            : props.customer.work_council2,
        age: props.customer.age === null ? "" : props.customer.age,
        spouse_age:
          props.customer.spouse_age === null ? "" : props.customer.spouse_age,
        children_number:
          props.customer.children_number === null
            ? ""
            : props.customer.children_number,
        fiscal_income:
          props.customer.fiscal_income === null
            ? ""
            : props.customer.fiscal_income,
        decision_makers_present:
          props.customer.decision_makers_present === null
            ? ""
            : props.customer.decision_makers_present,
        ok_one_hours_thirty:
          props.customer.ok_one_hours_thirty === null
            ? ""
            : props.customer.ok_one_hours_thirty,
        appointment_method:
          props.customer.appointment_method === null
            ? ""
            : props.customer.appointment_method,
        // Le contact - suivi du rendez-vous
        follow_up_number_star:
          props.customer.follow_up_number_star === null
            ? ""
            : props.customer.follow_up_number_star,
        follow_up_call_date:
          props.customer.follow_up_call_date === null
            ? ""
            : new Date(props.customer.follow_up_call_date),
      follow_up_refusal_reason:
        props.customer.follow_up_refusal_reason === null
          ? ""
          : props.customer.follow_up_refusal_reason,
      follow_up_client_profil:
        props.customer.follow_up_client_profil === null
          ? ""
          : props.customer.follow_up_client_profil,
    }
    : {
      // Identification du prospect
      civility: "",
      firstname: "",
      lastname: "",
      firstname2: "",
      lastname2: "",
      address: "",
      zip_code: "",
      city: "",
      mobile_phone: "",
      mobile_phone2: "",
      fix_phone: "",
      email: "",
      email2: "",
      address_construction_site: "",
      city_construction_site: "",
      zip_code_construction_site: "",
      exposed_city: "",
      is_owner: "",
      // Détail du logement
      main_resideence: "",
      owner_at: "",
      housing_seniority: "",
      surface_habitation: "",
      floors_number: "",
      is_isolate_floors: "",
      attic_type: "",
      attic_isulated_at: "",
      attic_isulation_type: "",
      is_attic_isulation: "",
      window_type: "",
      windows_number: "",
      ecs_production_type: "",
      ecs_production_type_annual_cost: "",
      heading_type: "",
      heading_type_annual_cost: "",
      appointment_explication: "",
      // Définition du projet potentiel
      building_work_type: "",
      already_benefit_energy_study: "",
      already_benefit_grant: "",
      // Rencontre du prospect
      first_contact_date: "",
      contact_origin: "",
      customer: "",
      user: "",
      // Positionnement du rendez-vous
      appointment_take_with: "",
      function1: "",
      work_council1: "",
      function2: "",
      work_council2: "",
      age: "",
      spouse_age: "",
      children_number: "",
      fiscal_income: "",
      decision_makers_present: "",
      ok_one_hours_thirty: "",
      appointment_method: "",
      // Le contact - suivi du rendez-vous
      follow_up_number_star: "",
      follow_up_call_date: "",
      follow_up_refusal_reason: "",
      follow_up_client_profil: "",
    };

  const getCivility = () => {
    return [
      { value: "MME.", label: "MME." },
      { value: "MLLE.", label: "MLLE." },
      { value: "M.", label: "M." },
      { value: "M. OU MME.", label: "M. OU MME." },
    ];
  };

  const handleSubmit = (values) => {
    props.updateCustomer(
      parseInt(window.location.pathname.split("/")[3], 10),
      {
        // Identification du prospect
        civility: values.civility,
        firstname: values.firstname,
        lastname: values.lastname,
        firstname2: values.firstname2,
        lastname2: values.lastname2,
        address: values.address,
        additional_address: values.additional_address,
        zip_code: values.zip_code,
        city: values.city,
        address_construction_site: values.address_construction_site,
        additional_address_construction_site:
          values.additional_address_construction_site,
        city_construction_site: values.city_construction_site,
        zip_code_construction_site: values.zip_code_construction_site,
        mobile_phone: values.mobile_phone,
        mobile_phone2: values.mobile_phone2,
        fix_phone: values.fix_phone,
        email: values.email,
        email2: values.email2,
        client_type: values.client_type,
        company_name: values.company_name,
        siret: values.siret,
        exposed_city: values.exposed_city,
        is_owner: values.is_owner,
        // Détail du logement
        main_resideence: values.main_resideence,
        owner_at: values.owner_at,
        housing_seniority: values.housing_seniority,
        surface_habitation: values.surface_habitation,
        floors_number: values.floors_number,
        is_isolate_floors: values.is_isolate_floors,
        attic_type: values.attic_type,
        attic_isulated_at: values.attic_isulated_at,
        attic_isulation_type: values.attic_isulation_type,
        is_attic_isulation: values.is_attic_isulation,
        window_type: values.window_type,
        windows_number: values.windows_number,
        ecs_production_type: values.ecs_production_type,
        ecs_production_type_annual_cost: values.ecs_production_type_annual_cost,
        heading_type: values.heading_type,
        heading_type_annual_cost: values.heading_type_annual_cost,
        appointment_explication: values.appointment_explication,
        // Définition du projet potentiel
        building_work_type: values.building_work_type,
        already_benefit_energy_study: values.already_benefit_energy_study,
        already_benefit_grant: values.already_benefit_grant,
        // Rencontre du prospect
        first_contact_date: values.first_contact_date,
        contact_origin: values.contact_origin,
        client_id: values.customer?.value,
        user_id: values.user.value,
        // Positionnement du rendez-vous
        appointment_take_with: values.appointment_take_with,
        function1: values.function1,
        work_council1: values.work_council1,
        function2: values.function2,
        work_council2: values.work_council2,
        age: values.age,
        spouse_age: values.spouse_age,
        children_number: values.children_number,
        fiscal_income: values.fiscal_income,
        decision_makers_present: values.decision_makers_present,
        ok_one_hours_thirty: values.ok_one_hours_thirty,
        appointment_method: values.appointment_method,
        // Le contact - suivi du rendez-vous
        follow_up_number_star: values.follow_up_number_star,
        follow_up_call_date: values.follow_up_call_date,
        follow_up_refusal_reason: values.follow_up_refusal_reason,
        follow_up_client_profil: values.follow_up_client_profil,
      },
      props.history
    );
  };
  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs
          title="Prospects et clients"
          breadcrumbItem="Modifier un prospect/client"
        />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Modifier un prospect/client
                </CardTitle>
                <CustomerForm
                  initValues={initValues}
                  validationSchema={updateValidationSchema}
                  handleSubmit={handleSubmit}
                  getCivility={getCivility}
                  users={props.users}
                  error={props.error}
                  isUpdate={true}
                  customerId={props.customer.id}
                  client_type={props.customer.client_type}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { loading, customer, error } = state.Customers;
  const { users } = state.Users;
  return { customer, users, loading, error };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCustomer,
    getUsers,
    updateCustomer,
    customerApiError,
  })(CustomerEdit)
);
