import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_CUSTOMERS,
  GET_CUSTOMER,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  IMPORT_CSV,
} from "./actionTypes";
import {
  getCustomersSuccess,
  getCustomerSuccess,
  createCustomerSuccess,
  updateCustomerSuccess,
  deleteCustomerSuccess,
  importCsvSuccess,
  customerApiError,
} from "./actions";

import { customers } from "../../api";

function* getCustomers({ payload: { query } }) {
  try {
    const response = yield call(customers.getAll, query);
    yield put(getCustomersSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        customerApiError(
          "Vous n'êtes pas autorisé à accéder à la liste des prospects/clients"
        )
      );
    } else if (error.response.data !== undefined) {
      yield put(customerApiError(error.response.data.message));
    } else {
      yield put(customerApiError(error.message));
    }
  }
}

function* getCustomer({ payload: { id } }) {
  try {
    const response = yield call(customers.getOne, id);
    yield put(getCustomerSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        customerApiError(
          "Vous n'êtes pas autorisé à accéder aux informations de ce prospect/client"
        )
      );
    } else if (error.response.status === 404) {
      yield put(customerApiError("Le client n'a pas été trouvé"));
    } else if (error.response.data !== undefined) {
      yield put(customerApiError(error.response.data.message));
    } else {
      yield put(customerApiError(error.message));
    }
  }
}

function* createCustomer({ payload: { customer, history } }) {
  try {
    const response = yield call(customers.create, customer);
    yield put(createCustomerSuccess(response));
    if (history) {
      history.push("/customers?success_add=1");
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        customerApiError(
          "Vous n'êtes pas autorisé à ajouter un prospect/client"
        )
      );
    } else if (
      error.response.status === 400 &&
      error.response.data.errors.length > 0
    ) {
      if (
        error.response.data.errors[0].detail.civility &&
        error.response.data.errors[0].detail.civility[0] === "can't be blank"
      ) {
        yield put(customerApiError("La civilité doit être présente"));
      } else if (
        error.response.data.errors[0].detail.firstname &&
        error.response.data.errors[0].detail.firstname[0] === "can't be blank"
      ) {
        yield put(customerApiError("La nom doit être présent"));
      } else if (
        error.response.data.errors[0].detail.lastname &&
        error.response.data.errors[0].detail.lastname[0] === "can't be blank"
      ) {
        yield put(customerApiError("Le prénom doit être présent"));
      } else if (
        error.response.data.errors[0].detail.email &&
        error.response.data.errors[0].detail.email[0] === "is invalid"
      ) {
        yield put(customerApiError("L'email n'est pas valide"));
      } else if (
        error.response.data.errors[0].detail.city &&
        error.response.data.errors[0].detail.city[0] === "can't be blank"
      ) {
        yield put(customerApiError("La ville doit être présente"));
      } else if (
        error.response.data.errors[0].detail.zip_code &&
        error.response.data.errors[0].detail.zip_code[0] === "can't be blank"
      ) {
        yield put(customerApiError("Le code postal doit être présent"));
      } else if (
        error.response.data.errors[0].detail.address &&
        error.response.data.errors[0].detail.address[0] === "can't be blank"
      ) {
        yield put(customerApiError("L'adresse doit être présente"));
      } else if (
        error.response.data.errors[0].detail.mobile_phone &&
        error.response.data.errors[0].detail.mobile_phone[0] ===
          "can't be blank"
      ) {
        yield put(
          customerApiError("Le numéro de téléphone mobile doit être présent")
        );
      }
    } else if (error.response.data !== undefined) {
      yield put(customerApiError(error.response.data.message));
    } else {
      yield put(customerApiError(error.message));
    }
  }
}

function* updateCustomer({ payload: { id, customer, history } }) {
  try {
    const response = yield call(customers.update, id, customer);
    yield put(updateCustomerSuccess(response));
    history.push("/customers?success_update=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        customerApiError(
          "Vous n'êtes pas autorisé à modifier un prospect/client"
        )
      );
    } else if (error.response.status === 404) {
      yield put(customerApiError("Le client n'a pas été trouvé"));
    } else if (
      error.response.status === 400 &&
      error.response.data.errors.length > 0
    ) {
      if (
        error.response.data.errors[0].detail.civility &&
        error.response.data.errors[0].detail.civility[0] === "can't be blank"
      ) {
        yield put(customerApiError("La civilité doit être présente"));
      } else if (
        error.response.data.errors[0].detail.firstname &&
        error.response.data.errors[0].detail.firstname[0] === "can't be blank"
      ) {
        yield put(customerApiError("La nom doit être présent"));
      } else if (
        error.response.data.errors[0].detail.lastname &&
        error.response.data.errors[0].detail.lastname[0] === "can't be blank"
      ) {
        yield put(customerApiError("Le prénom doit être présent"));
      } else if (
        error.response.data.errors[0].detail.email &&
        error.response.data.errors[0].detail.email[0] === "is invalid"
      ) {
        yield put(customerApiError("L'email n'est pas valide"));
      } else if (
        error.response.data.errors[0].detail.city &&
        error.response.data.errors[0].detail.city[0] === "can't be blank"
      ) {
        yield put(customerApiError("La ville doit être présente"));
      } else if (
        error.response.data.errors[0].detail.zip_code &&
        error.response.data.errors[0].detail.zip_code[0] === "can't be blank"
      ) {
        yield put(customerApiError("Le code postal doit être présent"));
      } else if (
        error.response.data.errors[0].detail.address &&
        error.response.data.errors[0].detail.address[0] === "can't be blank"
      ) {
        yield put(customerApiError("L'adresse doit être présente"));
      } else if (
        error.response.data.errors[0].detail.mobile_phone &&
        error.response.data.errors[0].detail.mobile_phone[0] ===
          "can't be blank"
      ) {
        yield put(
          customerApiError("Le numéro de téléphone mobile doit être présent")
        );
      }
    } else if (error.response.data !== undefined) {
      yield put(customerApiError(error.response.data.message));
    } else {
      yield put(customerApiError(error.message));
    }
  }
}

function* deleteCustomer({ payload: { id, history } }) {
  try {
    const response = yield call(customers.delete, id);
    yield put(deleteCustomerSuccess(response));
    history.push("/customers?success_delete=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        customerApiError(
          "Vous n'êtes pas autorisé à supprimer ce prospect/client"
        )
      );
    } else if (error.response.data !== undefined) {
      yield put(customerApiError(error.response.data.message));
    } else {
      yield put(customerApiError(error.message));
    }
  }
}

function* importCsv({ payload: { import_file } }) {
  try {
    const response = yield call(customers.import_csv, import_file);
    yield put(importCsvSuccess(response));
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield put(
        customerApiError(
          "Vous n'êtes pas autorisé à importer une liste de client"
        )
      );
    } else if (error.response && error.response.status === 404) {
      yield put(customerApiError("Le client n'a pas été trouvé"));
    } else if (error.response && error.response.data !== undefined) {
      yield put(customerApiError(error.response.data.message));
    } else {
      yield put(customerApiError(error.message));
    }
  }
}

export function* watchGetCustomers() {
  yield takeEvery(GET_CUSTOMERS, getCustomers);
}

export function* watchGetCustomer() {
  yield takeEvery(GET_CUSTOMER, getCustomer);
}

export function* watchCreateCustomer() {
  yield takeEvery(CREATE_CUSTOMER, createCustomer);
}

export function* watchUpdateCustomer() {
  yield takeEvery(UPDATE_CUSTOMER, updateCustomer);
}

export function* watchDeleteCustomer() {
  yield takeEvery(DELETE_CUSTOMER, deleteCustomer);
}

export function* watchImportCsv() {
  yield takeEvery(IMPORT_CSV, importCsv);
}

function* customersSaga() {
  yield all([
    fork(watchGetCustomers),
    fork(watchGetCustomer),
    fork(watchCreateCustomer),
    fork(watchUpdateCustomer),
    fork(watchDeleteCustomer),
    fork(watchImportCsv),
  ]);
}

export default customersSaga;
