import React from "react";

import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

// Helpers
import TechnicalMeetingForm from "../../FormComponent/TechnicalMeetingForm";

const TechnicalVisitModal = (props) => {



  return (
    <Modal isOpen={props.isOpen} toggle={() => props.toggle()}>
      <ModalHeader
        className="calendar-modal-header"
        toggle={() => props.toggle()}
        tag="div"
      >
        <span className="w-100 d-flex justify-content-between">
            <h4>Ajouter une visite technique</h4>
        </span>
      </ModalHeader>
      <ModalBody>
        <TechnicalMeetingForm
          disponibility_vt={props.disponibility_vt}
          setDisponibility_vt={props.setDisponibility_vt}
          disponibility_vt_end_at={props.disponibility_vt_end_at}
          setDisponibility_vt_end_at={props.setDisponibility_vt_end_at}
          technicians={props.technicians}
          setTechnicians={props.setTechnicians}
          comment_vt={props.comment_vt}
          setComment_vt={props.setComment_vt}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            props.toggle();
          }}
          className="btn btn-secondary waves-effect"
        >
          Annuler
        </Button>
        <Button
          onClick={() => {
            props.updateProjectWithDisponibilityVt();
            props.toggle();
          }}
          className="btn btn-green waves-effect waves-light"
        >
          Valider
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TechnicalVisitModal;