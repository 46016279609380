import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_VALIDATED,
  UPDATE_SUBSCRIPTION_VALIDATED_SUCCESS,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  SUBSCRIPTIONS_API_ERROR
} from "./actionTypes";

export const getSubscriptions = (query) => {
  return {
    type: GET_SUBSCRIPTIONS,
    payload: { query },
  };
};

export const getSubscriptionsSuccess = (subscriptions) => {
  return {
    type: GET_SUBSCRIPTIONS_SUCCESS,
    payload: subscriptions,
  };
};

export const getSubscription = (id) => {
  return {
    type: GET_SUBSCRIPTION,
    payload: {id},
  };
};

export const getSubscriptionSuccess = (subscription) => {
  return {
    type: GET_SUBSCRIPTION_SUCCESS,
    payload: subscription,
  };
};

export const createSubscription = (subscription, history) => {
  return {
    type: CREATE_SUBSCRIPTION,
    payload: {subscription, history},
  };
};

export const createSubscriptionSuccess = (subscription) => {
  return {
    type: CREATE_SUBSCRIPTION_SUCCESS,
    payload: subscription,
  };
};

export const updateSubscription = (id, subscription, history) => {
  return {
    type: UPDATE_SUBSCRIPTION,
    payload: {id, subscription, history}
  }
}

export const updateSubscriptionSuccess = (subscription) => {
  return {
    type: UPDATE_SUBSCRIPTION_SUCCESS,
    payload: subscription
  }
}

export const updateValidatedSubscription = (id, subscription, history) => {
  return {
    type: UPDATE_SUBSCRIPTION_VALIDATED,
    payload: {id, subscription, history}
  }
}

export const updateSubscriptionValidatedSuccess = (subscription) => {
  return {
    type: UPDATE_SUBSCRIPTION_VALIDATED_SUCCESS,
    payload: subscription
  }
}

export const deleteSubscription = (id, history) => {
  return {
    type: DELETE_SUBSCRIPTION,
    payload: {id, history}
  }
}

export const deleteSubscriptionSuccess = (subscription) => {
  return {
    type: DELETE_SUBSCRIPTION_SUCCESS,
    payload: subscription
  }
}

export const subscriptionApiError = (error) => {
  return {
    type: SUBSCRIPTIONS_API_ERROR,
    payload: error,
  };
};
