import {
  reasonColors
} from "../../../helpers/planification_helper";
import AgendaItem from "../../../models/agenda_item";

export const defaultEvent = {
    id: 0,
    title: "",
    start: "",
    end: "",
    className: "",
    extendedProps: {
        agendaItem: null,
        className: "",
        user: "",
        customer: "",
        project: "",
        technicians: [],
        reason: "",
        comment: "",
        date_begin: "",
        date_end: "",
        current_agenda_item: null,
    },
};

/**
* Parse TmpEvent
*/
export function parseTmpEvent(arg: AgendaItem, agendaItem: AgendaItem) {
 const eventNew = arg.event;

 return {
   id: eventNew.id,
   title: eventNew.title,
   start: eventNew.start,
   end: eventNew.end,
   className: eventNew.className,
   extendedProps: {
     agendaItem: eventNew.extendedProps.agendaItem,
     className: `bg-${reasonColors[agendaItem.reason]}`,
     user: agendaItem.user
       ? {
           value: agendaItem.user.id,
           label: `${agendaItem.user.firstname} ${agendaItem.user.lastname}`,
         }
       : null,
     customer: agendaItem.client
       ? {
           value: agendaItem.client.id,
           label:
             agendaItem.client.client_type === "Professionnel"
               ? agendaItem.client.company_name
               : `${agendaItem.client.firstname} ${agendaItem.client.lastname}`,
         }
       : null,
     project: agendaItem.project
       ? { value: agendaItem.project.id, label: agendaItem.project.id }
       : null,
     technicians: agendaItem.technicians.map((technician, index) => ({
       value: technician.id,
       label: `${technician.firstname} ${technician.lastname}`,
     })),
     reason: agendaItem.reason,
     comment: agendaItem.comment,
     date_begin: new Date(agendaItem.date_begin),
     date_end: new Date(agendaItem.date_end),
     current_agenda_item: agendaItem,
     address: agendaItem.client
       ? {
           value: agendaItem.appointment_in_construction_site,
           label: agendaItem.appointment_in_construction_site
             ? agendaItem.client.address_construction_site +
               ", " +
               agendaItem.client.zip_code_construction_site +
               " " +
               agendaItem.client.city_construction_site
             : agendaItem.client.address +
               ", " +
               agendaItem.client.zip_code +
               " " +
               agendaItem.client.city,
         }
       : null,
   },
 };
}