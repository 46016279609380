import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  NOTIFICATIONS_API_ERROR,
} from "./actionTypes";

const initialState = {
  notifications: [],
  notification: false,
  error: false,
  success: false,
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_NOTIFICATIONS_SUCCESS:
      state = {
        ...state,
        notifications: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        loading: false,
        error: false,
      };
      break;
    case GET_NOTIFICATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_NOTIFICATION_SUCCESS:
      state = {
        ...state,
        notification: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case CREATE_NOTIFICATION:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_NOTIFICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
      break;
    case UPDATE_NOTIFICATION:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_NOTIFICATION_SUCCESS:
      let temp = state.notifications;
      let index = temp.findIndex(
        (notification) => notification.id === action.payload.data.id
      );
      if (index !== -1) {
        temp[index] = action.payload.data;
      }
      state = {
        ...state,
        notification: action.payload.data,
        notifications: temp,
        loading: false,
        error: false,
        success: true,
      };
      break;
    case DELETE_NOTIFICATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_NOTIFICATION_SUCCESS:
      state = {
        ...state,
        notifications:
          state.notifications === undefined || state.notifications === null
            ? []
            : state.notifications.filter(
                (e) => e.id !== action.payload.data.id
              ),
        loading: false,
        error: false,
        success: true,
      };
      break;
    case NOTIFICATIONS_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        success: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default notifications;
