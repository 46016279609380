import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, CardBody, Card, Button, Label, FormGroup } from "reactstrap";
import { Formik, Form } from 'formik';

// Redux
import { connect } from "react-redux";

import {FormikAutocompleteUnselectable} from "../../../../components/Common/AsyncAutoComplete2";

// Helpers
import {
  promiseCustomersOptions,
  promiseUsersOptions,
} from "../../../../helpers/autocomplete_promise_options";

// Actions
import { getProjectStates } from "../../../../store/actions";

const Filter = (props) => {
  const {
    filters,
    setFilters,
  } = props;

  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      onSubmit={(values) => {
        setFilters({
          ...filters,
          client_id: values.client_id,
          user_id: values.user_id,
          priority: values.priority,
          state: values.state,
          archived: values.archived,
        })
      }}
    >
      {({ values, setFieldValue, handleSubmit}) => (
        <Form>
          <Card>
            <CardBody>
              <Row>
                <Col xs="3">
                  <FormGroup className="mb-4" row>
                    <Label
                      for="client_id"
                      className="col-form-label col"
                    >
                      Client
                    </Label>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        name="client_id"
                        id="client_id"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucun commerciaux disponible"}
                        placeholder="Choisir un commercial..."
                        loadOptions={promiseCustomersOptions}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col xs="3">
                  <FormGroup className="mb-4" row>
                    <Label
                      for="user_id"
                      className="col-form-label col"
                    >
                      Collaborateur *
                    </Label>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        name="user_id"
                        id="user_id"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucun collaborateur disponible"}
                        placeholder="Choisir un collaborateur..."
                        loadOptions={promiseUsersOptions}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col xs="2">
                  <FormGroup className="mb-4" row>
                    <Label
                      for="priority"
                      className="col-form-label col"
                    >
                      Priorité
                    </Label>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        name="priority"
                        id="priority"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucun priorité disponible"}
                        placeholder="Choisir un priorité..."
                        loadOptions={[
                          { value: "Normal", label: "Normal" },
                          { value: "Haut", label: "Haut" },
                          { value: "Urgent", label: "Urgent" },
                        ]}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col xs="2">
                  <FormGroup className="mb-4" row>
                    <Label
                      for="state"
                      className="col-form-label col"
                    >
                      État
                    </Label>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        name="state"
                        id="state"
                        isSearchable={true}
                        noOptionsMessage={() => "Pas d'état disponible"}
                        placeholder="Choisir un état..."
                        loadOptions={[
                          { value: "Nouveau", label: "Nouveau" },
                          { value: "Vu", label: "Vu" },
                          { value: "En attente du client", label: "En attente du client" },
                          { value: "Réponse envoyée à l'ADV", label: "Réponse envoyée à l'ADV" },
                          { value: "Terminée", label: "Terminée" },
                          {value: "Archivée", label: "Archivée"}
                        ]}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="text-center">
                  <Button
                    color="none"
                    className="btn-green"
                    onClick={() => handleSubmit(values)}
                  >
                    Filtrer
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

const mapStatetoProps = (state) => {
  const { projectStates } = state.ProjectStates;

  return { projectStates };
};

export default withRouter(
  connect(mapStatetoProps, { getProjectStates })(Filter)
);
