import { Form, Formik } from 'formik';
import React from "react";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import {
  promiseRequestStatusOptions
} from "../../../../helpers/autocomplete_promise_options_api_v2";
import { FormikAutocompleteUnselectable } from "../../../../components/Common/AsyncAutoComplete2";
import {InterventionFolderFilter} from "../../../../models/intervention_folder";

interface RequestsFilterProps {
  filters: InterventionFolderFilter,
  setFilters: React.Dispatch<React.SetStateAction<InterventionFolderFilter>>
}

const InterventionFilter = ({
  filters,
  setFilters,
}: RequestsFilterProps) => {

  // @ts-ignore
  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      onSubmit={(values) => {
        setFilters({
          ...filters,
          ...values
        })
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form>
          <Card>
            <CardBody>
              <Row>
                <Col xs="12" lg="3" md="4" className="mb-4">
                  <Row>
                    <Col xs="12">
                      <Label for="percent">Date</Label>
                    </Col>
                    <Col xs="6" className='mb-4 unova-datepicker'>
                      <Row>
                        <Col xs="3" className={"d-flex align-items-center"}>
                          <Label className={"mb-0"}>Du</Label>
                        </Col>
                        <Col xs="9">
                          <DatePicker
                            className="form-control button-without-style"
                            name="date_begin"
                            selected={values.date_begin?new Date(values.date_begin):null}
                            onChange={v => setFieldValue("date_begin",v)}
                            isClearable={true}
                            placeholderText={'dd/mm/aaaa'}
                            dateFormat={"dd/MM/yyyy"}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="6" className='mb-4 unova-datepicker'>
                      <Row>
                        <Col xs="3" className={"d-flex align-items-center"}>
                          <Label className={"mb-0"}>au</Label>
                        </Col>
                        <Col xs="9">
                          <DatePicker
                            className="form-control button-without-style"
                            name="date_end"
                            selected={values.date_end?new Date(values.date_end):null}
                            onChange={v => setFieldValue("date_end",v)}
                            isClearable={true}
                            placeholderText={'dd/mm/aaaa'}
                            dateFormat={"dd/MM/yyyy"}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="3" md="4" className='mb-4 unova-datepicker'>
                  <Row>
                    <Col xs="12">
                      <Label>CP</Label>
                    </Col>
                    <Col xs="12">
                      <input type="text"
                             placeholder={"Entrer le code postal"}
                             name={"zip_code_query"}
                             className={"form-control"}
                             defaultValue={values.zip_code_query || ""}
                             onKeyUp={e=>setFieldValue("zip_code_query",e.currentTarget.value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="3" md="4" className="mb-4">
                  <Row>
                    <Col xs="12">
                      <Label>Type d'installation</Label>
                    </Col>
                    <Col xs="12">
                      <input type="text"
                             placeholder={"Entrer le nom du collaborateur"}
                             name={"installation_type_query"}
                             className={"form-control"}
                             defaultValue={values.installation_type_query || ""}
                             onKeyUp={e=>setFieldValue("installation_type_query",e.currentTarget.value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="3" md="4" className="mb-4">
                  <Row>
                    <Col xs="12">
                      <Label for="value">Statut</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        loadOptions={promiseRequestStatusOptions}
                        isSearchable={false}
                        multiple={true}
                        name={"status"}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="text-center mt-3">
                  <Button
                    color="none"
                    className="btn-green"
                    onClick={() => handleSubmit(values as any)}
                  >
                    Appliquer les filtres
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default InterventionFilter;
