import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import AgencyForm from "./FormComponent/AgencyForm";

// Validation Schema
import { createValidationSchema } from "./FormValidationSchemas/createValidationSchema";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import { createAgency, agencyApiError, getUsers } from "../../store/actions";

const AgencyAdd = (props) => {
  useEffect(() => {
    props.getUsers.call();
  }, [props.getUsers]);

  const initValues = {
    name: "",
    url: "",
    leader: "",
    address: "",
    phone: "",
    email: "",
    comptable_account: "",
  };

  const handleSubmit = (values) => {
    props.createAgency(
      {
        name: values.name,
        url: values.url,
        leader: values.leader,
        address: values.address,
        phone: values.phone,
        email: values.email,
        comptable_account: values.comptable_account,
      },
      props.history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Agences" breadcrumbItem="Créer une agence" />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Créer une agence</CardTitle>
                {props.loading ? null : (
                  <AgencyForm
                    initValues={initValues}
                    validationSchema={createValidationSchema}
                    handleSubmit={handleSubmit}
                    users={props.users}
                    agency={""}
                    error={props.error}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { loading, error } = state.Agencies;
  const { users } = state.Users;
  return { loading, error, users };
};

export default withRouter(
  connect(mapStatetoProps, {
    createAgency,
    getUsers,
    agencyApiError,
  })(AgencyAdd)
);
