import {
  GET_SUB_CATEGORIES,
  GET_SUB_CATEGORIES_SUCCESS,
  SUB_CATEGORIES_API_ERROR,
} from "./actionTypes";

const initialState = {
  sub_categories: [],
  error: false,
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUB_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SUB_CATEGORIES_SUCCESS:
      state = {
        ...state,
        sub_categories: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        loading: false,
        error: false,
      };
      break;
    case SUB_CATEGORIES_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default categories;
