import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import Select from "react-select";
import {parseDate} from "../../../helpers/subscriptions_helpers";

const MaintenanceCard = ({
                           project,
                           subscriptionStates,
                           updateChanges,
                           subscription
                         }) => {

  const [subscriptionState, setSubscriptionState] = useState(subscription.abonnement_state);
  const [isDisabled, setIsDisabled] = useState(subscription.abonnement_state.label.toUpperCase() === "ACCEPTÉ");

  const order = project.order;

  useEffect(() => {
      setIsDisabled(subscription.abonnement_state.label.toUpperCase() === "ACCEPTÉ");
    },
    [project, subscription.abonnement_state.label])

  const computeQuoteButton = () => {
    return (
      <React.Fragment>
        <span className="table-link">{subscription.quote ? subscription.quote.number : "-"}</span>
        <div>
          <a
            href={subscription.quote ? `${process.env.REACT_APP_API_URL}/files/${subscription.quote.url}` : ""}
            className={`btn btn-green ${subscription.quote ? '' : 'btn-disabled'}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Aperçu
          </a>
        </div>
      </React.Fragment>
    );
  }

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-3 d-flex justify-content-between">
          <div>Contrat d'entretien</div>
        </CardTitle>
        {!subscription ? 'Aucun abonnement pour ce projet' :
          <Col className='subscriptions-detail'>
            <div className="deposit-container mt-3">
              <div className="deposit-row">
                <div className="deposit-item">
                  <p>État :</p>
                  <span>
                  <Select
                    className="w-100"
                    placeholder="État du contrat d'entretien"
                    value={{value: subscriptionState.id, label: subscriptionState.label.toUpperCase()}}
                    options={subscriptionStates}
                    isDisabled={isDisabled}
                    onChange={(maintenance_state) => {
                      setSubscriptionState(maintenance_state);
                    }}
                  />
                </span>
                </div>
              </div>
            </div>
            <div className="deposit-container mt-3">
              <div className="deposit-row">
                <div className="deposit-item">
                  <p><span>Date de première échéance :</span></p>
                  {parseDate(subscription.start_at)}
                </div>
              </div>

              <div className="deposit-row">
                <div className="deposit-item">
                  <p><span>Date de validation :</span></p>
                  {parseDate(subscription.validated_at)}
                </div>
              </div>

              <div className="deposit-row">
                <div className="deposit-item">
                  <p><span>Formule :</span></p>
                  {subscription.formule_label}
                </div>
              </div>

              <div className="deposit-row d-flex justify-content-center">
                <div>
                  <div className="text-center mr-3">
                    <a href={'/subscription/detail/' + subscription.id} className='btn btn-green'>
                      Voir les détails du contrat
                    </a>
                  </div>
                </div>
                <div>
                  {isDisabled ? '' :
                    <div className="text-center ml-3">
                      <Button
                        color="none"
                        className="btn-green"
                        onClick={() => updateChanges(subscriptionState.value)}
                      >
                        Sauvegarder les modifications
                      </Button>
                    </div>}
                </div>
              </div>

            </div>
          </Col>
        }
        {!order ? '' :
          <React.Fragment>
            <hr/>
            <Col className='subscriptions-detail'>
              <Row>
                <p className='d-flex align-items-center justify-content-between w-50'><span>Devis :</span>
                  {computeQuoteButton()}
                </p>
              </Row>
            </Col>
          </React.Fragment>
        }
      </CardBody>
    </Card>
  );
}

export default MaintenanceCard;