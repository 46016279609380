import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikAutocomplete from "../../../components/Common/Autocomplete";

//Import Date Picker
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

const CollaboratorForm = (props) => {
  return (
    <Formik
      initialValues={props.initValues}
      validationSchema={props.validationSchema}
      initialErrors={props.error}
      enableReinitialize
      onSubmit={(values) => {
        props.handleSubmit(values);
      }}
      render={({ values, errors, touched, setFieldValue }) => (
        <Form>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <FormGroup className="mb-4" row>
                <Label htmlFor="email" className="col-form-label col-lg-2">
                  Email {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    tag={Field}
                    invalid={errors.email && !!touched.email}
                    className="form-control"
                    placeholder="Entrer un email"
                  />
                  <ErrorMessage name="email" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="firstname" className="col-form-label col-lg-2">
                  Prénom
                </Label>
                <Col lg="10">
                  <Input
                    id="firstname"
                    name="firstname"
                    type="text"
                    tag={Field}
                    invalid={errors.firstname && !!touched.firstname}
                    className="form-control"
                    placeholder="Entrer un prénom"
                  />
                  <ErrorMessage name="firstname" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="lastname" className="col-form-label col-lg-2">
                  Nom
                </Label>
                <Col lg="10">
                  <Input
                    id="lastname"
                    name="lastname"
                    type="text"
                    tag={Field}
                    invalid={errors.lastname && !!touched.lastname}
                    className="form-control"
                    placeholder="Entrer un nom"
                  />
                  <ErrorMessage name="lastname" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="password" className="col-form-label col-lg-2">
                  Mot de passe {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    tag={Field}
                    invalid={errors.password && !!touched.password}
                    className="form-control"
                    placeholder="Entrer un mot de passe"
                  />
                  <ErrorMessage name="password" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label
                  htmlFor="password_confirmation"
                  className="col-form-label col-lg-2"
                >
                  Confirmation du mot de passe {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password"
                    tag={Field}
                    invalid={
                      errors.password_confirmation &&
                      !!touched.password_confirmation
                    }
                    className="form-control"
                    placeholder="Entrer la confirmation du mot de passe"
                  />
                  <ErrorMessage
                    name="password_confirmation"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="role" className="col-form-label col-lg-2">
                  Rôle {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <FormikAutocomplete
                    name="role"
                    id="role"
                    invalid={errors.role && !!touched.role}
                    className={errors.role ? "selectorError" : null}
                    options={props.roles}
                  />
                  <ErrorMessage
                    className={errors.role ? "errorMessages" : null}
                    name="role"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="entryDate" className="col-form-label col-lg-2">
                  Date d'entrée dans l'entreprise
                </Label>
                <Col lg="10">
                  <DatePicker
                    className="form-control button-without-style"
                    selected={
                      values.entryDate === ""
                        ? new Date()
                        : new Date(values.entryDate)
                    }
                    onChange={(date) => {
                      setFieldValue("entryDate", date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    locale={fr}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="birthDate" className="col-form-label col-lg-2">
                  Date de naissance
                </Label>
                <Col lg="10">
                  <DatePicker
                    className="form-control button-without-style"
                    selected={
                      values.birthDate === ""
                        ? new Date()
                        : new Date(values.birthDate)
                    }
                    onChange={(date) => {
                      setFieldValue("birthDate", date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    locale={fr}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="agencies" className="col-form-label col-lg-2">
                  Agences
                </Label>
                <Col lg="10">
                  <FormikAutocomplete
                    name="agencies"
                    id="agencies"
                    invalid={errors.agencies && !!touched.agencies}
                    className={errors.agencies ? "selectorError" : null}
                    options={props.agencies.map((item) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })}
                    multiple={true}
                    defaultValue={props.selectedAgencies}
                  />
                  <ErrorMessage
                    className={errors.agencies ? "errorMessages" : null}
                    name="agencies"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="phone" className="col-form-label col-lg-2">
                  Téléphone {props.isUpdate ? null : "*"}
                </Label>
                <Col lg="10">
                  <Input
                    id="phone"
                    name="phone"
                    type="tel"
                    tag={Field}
                    invalid={errors.phone && !!touched.phone}
                    className="form-control"
                    placeholder="Entrer un numéro de téléphone"
                  />
                  <ErrorMessage name="phone" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="address" className="col-form-label col-lg-2">
                  Adresse
                </Label>
                <Col lg="10">
                  <Input
                    id="address"
                    name="address"
                    type="text"
                    tag={Field}
                    invalid={errors.address && !!touched.address}
                    placeholder="Entrer une adresse"
                    className="form-control"
                  />
                  <ErrorMessage name="address" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label
                  htmlFor="contractType"
                  className="col-form-label col-lg-2"
                >
                  Type de contrat
                </Label>
                <Col lg="10">
                  <FormikAutocomplete
                    name="contractType"
                    id="contractType"
                    className={errors.contractType ? "selectorError" : null}
                    invalid={errors.contractType && !!touched.contractType}
                    options={props.getContractType()}
                  />
                  <ErrorMessage
                    className={errors.contractType ? "errorMessages" : null}
                    name="contractType"
                    component={FormFeedback}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="comment" className="col-form-label col-lg-2">
                  Commentaire
                </Label>
                <Col lg="10">
                  <Input
                    id="comment"
                    name="comment"
                    type="textarea"
                    tag={Field}
                    invalid={errors.comment && !!touched.comment}
                    placeholder="Entrer un commentaire"
                    className="form-control"
                  />
                  <ErrorMessage name="comment" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="agenda_item_card_color" className="col-form-label col-lg-2">
                  Couleur des éléments du calendrier
                </Label>
                <Col lg="10">
                  <Input
                    id="agenda_item_card_color"
                    name="agenda_item_card_color"
                    type="color"
                    tag={Field}
                    invalid={errors.agenda_item_card_color && !!touched.agenda_item_card_color}
                    className="form-color"
                  />
                  <ErrorMessage name="comment" component={FormFeedback} />
                </Col>
              </FormGroup>
            </div>
          </div>
          <Row className="justify-content-end">
            <Col lg="10">
              <Row>
                <Col lg="2">
                  <Button type="submit" color="primary">
                    {props.isUpdate
                      ? "Modifier le collaborateur"
                      : "Ajouter le collaborateur"}
                  </Button>
                </Col>
                  <Col lg="10">
                    {props.error ? (
                      <Alert color="danger" className="d-inline-block">
                        des erreurs se sont produites, veuillez consulter le formulaire
                      </Alert>
                    ) : null}
                  </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default CollaboratorForm;
