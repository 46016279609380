import React from "react";

// Modals components
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

// Customer form 
import ProjectConvertForm from "../FormComponent/ProjectConvertForm";
// Validation schema
import { projectConvertValidationSchema } from "../FormValidationSchemas/projectConvertValidationSchema";

/**
 * Modal opened when an project needs to be created
 * 
 * @param isOpen defined if the modal is open
 * @param toggler function toggling the modal
 * @param handleSubmit handler for the submission of an invoice
 * **/
class ProjectConvertModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      orderId: null
    }
  }

  toggle(orderId) {
    if (this.state.isOpen) {
      orderId = null;
    }
    this.setState({
      isOpen: !this.state.isOpen,
      orderId: orderId
    })
  }

  render() {
    return (
      <Modal
        isOpen={this.state.isOpen}
        toggle={this.toggle.bind(this)}
      >
        <ModalHeader toggle={this.toggle.bind(this)} tag="h4">
          Génération du projet
        </ModalHeader>
        <ModalBody>
          <ProjectConvertForm
            initValues={{
              project_state_id: null,
              user_id: null
            }}
            isDeposit={false}
            validationSchema={projectConvertValidationSchema}
            orderId={this.state.orderId}
            handleSubmit={(values) => {
              this.toggle()
              this.props.handleSubmit(values)
            }}
          />
        </ModalBody>
      </Modal>
    );
  }
}

export default ProjectConvertModal;