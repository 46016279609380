import {useState} from "react";
import {Link, withRouter} from "react-router-dom";
import { Card, CardBody, CardTitle, Button } from "reactstrap";

// Redux
import { connect } from "react-redux";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

// Helpers
import { getDateWithShortFormat } from "../../../helpers/helper";

// actions
import { getProject } from "../../../store/actions";

// Api
import {deposits, invoices} from "../../../api/index";
import DepositPaymentsTable from "../Components/DepositPaymentsTable";
import ValidationModal from "../../Quotes/Modals/ValidationModal";

const DepositCard = (props) => {



  const [invoiceData] = useState(() => {
    const depositsArray = props.project.order?.deposits.filter(e => !e.invoice_refunded);
    const lastDeposit = depositsArray.length > 0 ? depositsArray[depositsArray.length - 1] : null;
    return {
      deposit: lastDeposit,
      invoice: props.project.order?.invoices.find(invoice => invoice.id === lastDeposit.invoice_id)
    }
  })

  const [validateInvoiceModal, setValidateInvoiceModal] = useState(false);

  const initValues = {
    receipt_date: '',
    observation: '',
  }

  const computeDepositValues = () => {
    if(invoiceData.deposit)
      return invoiceData.deposit;
    else
      return initValues;
  }

  const [depositValues, setDepositValues] = useState(computeDepositValues());

  const handleChange = (name, value) => {
    setDepositValues(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const submitDepositChange = (values) => {
    deposits.update(invoiceData.deposit.id, values)
      .then(() => {
        props.getProject(props.project.id);
      });
  }

  /**
   * Fonction qui permet de gérer la hauteur du textarea du champ commentaire
   * @param {object} e L'événement retourné par le textarea, qui contient la balise HTML du textarea
   */
  const updateTextareaHeight = (e) => {
    e.currentTarget.style.height = "auto";
    e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
  };

  const validateDeposit = () => {
    invoices.update(invoiceData.invoice.id, {is_draft: false})
      .then(r => props.getProject(props.project.id))
  }

  return (
    <Card>
      <CardBody >
        <CardTitle className="mb-3 d-flex justify-content-between invoices-container">
          <div>Acompte</div>
          { invoiceData.invoice.is_draft ?
            <Link
              to='#'
              className="d-flex align-items-center text-theme-color-2"
              onClick={() => setValidateInvoiceModal(true)}
            >
              Valider la facture
            </Link>
            :
            <Link
              to={`/invoice/${invoiceData.invoice.id}/detail`}
              className="d-flex align-items-center text-theme-color-2"
            >
              Détails de la facture
            </Link>
          }
        </CardTitle>
        <div className="deposit-container mt-3">
          <div className="deposit-row">
            <div className="deposit-item">
              <p>Modalité de paiement :</p>
              <span>
                {props.project.order && props.project.order.deposits.length > 0
                  ? props.project.order.deposits[
                      props.project.order.deposits.length - 1
                    ].payment_mode
                  : "-"}
              </span>
            </div>
          </div>
          <div className="deposit-row">
            <div className="deposit-item">
              <p>Date de demande :</p>
              <span>
                {props.project.order && props.project.order.deposits.length > 0
                  ? getDateWithShortFormat(
                      new Date(
                        props.project.order.deposits[
                          props.project.order.deposits.length - 1
                        ].custom_deposit_date
                      )
                    )
                  : "-"}
              </span>
            </div>
          </div>
          <div className="deposit-row">
            <div className="deposit-item">
              <p>Montant à percevoir :</p>
              <span>
                {`${invoiceData.invoice.total_ttc} €`}
              </span>
            </div>
          </div>
          <div className="deposit-row">
            <div className="deposit-item">
              <p>Montant perçu :</p>
              <div>
                {parseFloat(invoiceData.invoice.received_payment_total_ttc).toFixed(2)} €
              </div>
            </div>
          </div>
          <div className="deposit-row">
            <div className="deposit-item deposit-item-block">
              <p>Observations :</p>
              <textarea
                rows="2"
                className="form-control"
                placeholder="Ajouter un commentaire"
                value={depositValues.observation}
                name='observation'
                onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                onFocus={(e) => updateTextareaHeight(e)}
                onInput={(e) => updateTextareaHeight(e)}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="text-center mb-4">
            <Button
              color="none"
              className="btn-green"
              onClick={() => {
                submitDepositChange(depositValues);
              }}
            >
              Sauvegarder les modifications
            </Button>
          </div>
        </div>
        { invoiceData.invoice.is_draft ? '' :
          <DepositPaymentsTable
            invoice={invoiceData.invoice}
            getProject={() => props.getProject(props.project.id)}
          />
        }
      </CardBody>
      <ValidationModal
        isOpen={validateInvoiceModal}
        toggler={() => setValidateInvoiceModal(!validateInvoiceModal)}
        title='Factures - valider'
        message={<div><h2>Attention</h2><p>Voulez-vous valider la facture ?</p></div>}
        btnValidText='Valider'
        btnBackText='Annuler'
        handleSubmit={validateDeposit}
      />
    </Card>
  );
};

const mapStatetoProps = (state) => {
  const { project } = state.Projects;

  return { project };
};

export default withRouter(
  connect(mapStatetoProps, { getProject })(DepositCard)
);
