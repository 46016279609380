import { History } from 'history';
import { useEffect, useState } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import { parseQuery, setQuery } from '../../../../helpers/helper';
import { OfferCodesFilters } from '../../../../models/offer_code';
import OfferCodesFilter from '../Filters/offer-codes-filter';
import OfferCodesTable from '../Tables/offer-codes-table';

export interface OfferCodesCardProps {
  history: History,
  offerId: number,
}

const OfferCodesCard = ({
  history,
  offerId
}: OfferCodesCardProps) => {

  const [filtersCollapseIsOpen, setFiltersCollapseIsOpen] = useState(false);

  // Filters

  const query = parseQuery();

  const [filters, setFilters] = useState<OfferCodesFilters>({
    sort_by: query.sort_by as any || '',
    sort_dir: query.sort_dir as any || '',
    page: (query.page || 1) as number,
    page_size: (query.page_size || 25) as number,
    offer_id: offerId,
    search: (query.search || null) as string | null,
    status: (query.status || null) as string | null,
    amount: (query.amount || null) as number | null,
    client_search: (query.client_search || null) as string | null,
    created_by: (query.created_by || null) as number | null,
    apply_by: (query.apply_by || null) as number | null
  });

  useEffect(() => {
    setQuery({
      ...filters,
      tab: 'offer_codes'
    }, history); // Update query in path
  }, [filters, history]);

  function resetFilters() {
    setFilters({
      sort_by: '',
      sort_dir: '',
      page: 1,
      page_size: 25,
      offer_id: offerId,
      search: null,
      status: null,
      amount: null,
      client_search: null,
      created_by: null,
      apply_by: null
    });
  }

  return (
    <Col xs="12">
      <Row>
        <Col xs="12">
          <Row>
            <Col xs="12" className="d-flex justify-content-start">
              <span
                className="d-flex text-link mb-3"
                onClick={() => {
                  setFiltersCollapseIsOpen(!filtersCollapseIsOpen);
                }}
              >
                <i className="fas fa-filter mr-1"></i>Filtrer
              </span>
              <span
                className="d-flex text-link text-color-unset mb-3"
                onClick={resetFilters}
              >
                <i className="fas fa-times mr-1"></i> Vider les
                filtres
              </span>
            </Col>
            <Col xs="12">
              <Collapse isOpen={filtersCollapseIsOpen}>
                <OfferCodesFilter
                  filters={filters}
                  setFilters={setFilters}
                />
              </Collapse>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col lg="4" sm="4">
              <input
                type="text"
                id="offer_code_query"
                className="form-control"
                placeholder="Rechercher un code"
                defaultValue={filters.search || ""}
              ></input>
            </Col>
            <Col lg="3" sm="3">
              <Button
                onClick={() => {
                  setFilters({
                    ...filters,
                    search: (document.getElementById('offer_code_query') as HTMLInputElement).value,
                  });
                }}
                color="none"
                className="btn-green"
              >
                Rechercher
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <OfferCodesTable filters={filters} setFilters={setFilters} />
      </Row>
    </Col>
  );
};

export default OfferCodesCard;