import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

const ExportVisitesSuiviComponent = (props) => {
  return (
    <Row>
        <Col>
            {
                [
                    {name: 'disponibilities', value: props.values.disponibilities, label: 'Disponibilités client'},
                    {name: 'disponibility_vt', value: props.values.disponibility_vt, label: 'Date et heure VT'},
                    {name: 'comment_vt', value: props.values.comment_vt, label: 'Commentaire VT'},
                    {name: 'call_date_vt', value: props.values.call_date_vt, label: 'Date appel VT'},
                    {name: 'reception_scan_at', value: props.values.reception_scan_at, label: 'Date de réception scan dossier de financement'},
                    {name: 'folder_sell_paper_receipt_date', value: props.values.folder_sell_paper_receipt_date, label: 'Date d\'acceptation de financement / date d\'acompte perçu'},
                    {name: 'pac_file_receipt_date', value: props.values.pac_file_receipt_date, label: 'Date de réception dossier papier'},
                    {name: 'acceptation_send_at', value: props.values.acceptation_send_at, label: 'Date de réception fiche de liaison'},
                    {name: 'decision_at', value: props.values.decision_at, label: 'Date d\'envoi du courrier d\'acceptation financement ou acompte perçu'},
                ].map(
                    (value) => (
                        <FormGroup className="d-flex mb-1">
                            <Input
                                type="checkbox"
                                name={value.name}
                                id={value.name}
                                className="form-control form-checkbox mr-3"
                                checked={value.value != null}
                                disabled={props.isFormDisabled}
                                onChange={(event) => {
                                    props.setFieldValue(
                                        value.name, value.value == null ? value.label : null
                                    )
                                }}
                            />
                            <Label htmlFor={value.name} className="col-form-label">
                                {value.label}
                            </Label>
                        </FormGroup>
                    )
                )
            }
        </Col>
    </Row>
  );
};

export default ExportVisitesSuiviComponent;