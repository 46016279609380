import { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";

// Redux
import { connect } from "react-redux";

// Forms component
import InvoicesForm from "../FormComponent/InvoicesForm";

// Validation schemas
import { createValidationSchema } from "../FormValidationSchemas/createValidationSchema";
import { createInvoiceValidationSchema } from "../FormValidationSchemas/createInvoiceValidationSchema";

const GenerateDocumentCard = (props) => {
  
  var invoices = props.project?.order?.invoices?.filter(e => !e.refund_on && !e.is_canceled);
  var deposits = useMemo(() => props.project?.order?.deposits?.filter(e => !e.invoice_refunded), [props.project]);

  const getInitValuesDeposit = useCallback(() => ({
    id: (deposits || [])[0]?.id || null,
    invoice_date: (deposits || [])[0]?.custom_deposit_date?.substr(0, 10) || "",
    percent: (deposits || [])[0]?.percent || "",
    price: (deposits || [])[0]?.total_price_ttc || "",
    payment_mode: { value: (deposits || [])[0]?.payment_mode || "", label: (deposits || [])[0]?.payment_mode || "" },
  }), [deposits]);
  const [initValuesDeposit, setInitValuesDeposit] = useState(getInitValuesDeposit());

  useEffect(() => {
    setInitValuesDeposit(getInitValuesDeposit())
  }, [props.project, getInitValuesDeposit])

  var depositUnlocked = props.project.order !== null && (
    deposits.length === 0 || // Pour la generation d'un accompte
    ( // Pour la modification d'un accompte | Il faut que l'on est un deposit et que le deposit soit pas en status is_draft = false représenter par le champs invoice_locked ( = !is_draft coté API)
      deposits.length !== 0 &&
      !deposits[0].invoice_locked
    )
  );

  var invoiceUnlock = props.project.order !== null && (
    invoices.length === 0 ||
    deposits.length === invoices.length
  );

  return(
    <Card>
      <CardBody>
        <CardTitle>Générer des documents</CardTitle>
        <Row>
          <Col xs="6" className="text-center">
            <Button
              color="none"
              className={`btn-green ${!depositUnlocked ? "btn-disabled" : ""}`}
              onClick={depositUnlocked ? props.tog_deposit : undefined}
            >
              {deposits === null || deposits.length === 0 ? 'Générer une facture d\'acompte' : 'Modifier la facture d\'acompte'}
            </Button>
          </Col>
          <Col xs="6" className="text-center">
            <Button
              color="none"
              className={`btn-green ${invoiceUnlock ? "" : "btn-disabled"}`}
              onClick={invoiceUnlock ? props.tog_invoice : undefined}
            >
              Générer une facture de solde
            </Button>
          </Col>
        </Row>
        <Modal isOpen={props.modal_deposit} toggle={props.tog_deposit}>
          <ModalHeader toggle={props.tog_deposit} tag="h4">
            Facture d'acompte
          </ModalHeader>
          <ModalBody>
            <InvoicesForm
              initValues={initValuesDeposit}
              total_price_ttc={
                props.project.order ? props.project.order.total_price_ttc : 0
              }
              isDeposit={true}
              validationSchema={createValidationSchema}
              handleSubmit={props.onSubmitDeposit}
            />
          </ModalBody>
        </Modal>
        <Modal isOpen={props.modal_invoice} toggle={props.tog_invoice}>
          <ModalHeader toggle={props.tog_invoice} tag="h4">
            Facture de solde
          </ModalHeader>
          <ModalBody>
            <InvoicesForm
              initValues={{
                invoice_date: "",
                payment_mode: "",
              }}
              isDeposit={false}
              validationSchema={createInvoiceValidationSchema}
              handleSubmit={props.onSubmitInvoice}
            />
          </ModalBody>
        </Modal>
      </CardBody>
    </Card>
  );
}

const mapStatetoProps = (state) => {
  const { project } = state.Projects;

  return { project };
};

export default withRouter(connect(mapStatetoProps, {})(GenerateDocumentCard));
