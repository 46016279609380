import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Alert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Cards
import CardProject from "./card-project";

//Import Image
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getProjects,
  getUsers,
  deleteProject,
  projectsApiError,
} from "../../store/actions";
import GlobalLoader from "../../components/Common/GlobalLoader";

const ProjectsGrid = (props) => {
  const [flash_message, setFlash_message] = useState(false);

  useEffect(() => {
    props.getProjects.call();
    props.getUsers.call(null,{ pagination: false });
  }, [props.getProjects, props.getUsers]);

  const getProjectsWithPage = (n) => {
    props.getProjects("?page=" + n + "&page_size=24");
  };

  function handleDeleteProject(project_id) {
    props.deleteProject(project_id);
    setFlash_message(true);
  }

  function getPaginations() {
    var paginations = [];
    for (var i = 2; i < props.pagination.total_pages; i++) {
      let page = i;
      if (
        i - props.pagination.current_page >= -2 &&
        i - props.pagination.current_page <= 2
      ) {
        if (i === props.pagination.current_page) {
          paginations.push(
            <PaginationItem active key={i}>
              <PaginationLink
                href="#"
                onClick={() => getProjectsWithPage(page)}
              >
                <span>{i}</span>
              </PaginationLink>
            </PaginationItem>
          );
        } else {
          paginations.push(
            <PaginationItem key={i}>
              <PaginationLink
                href="#"
                onClick={() => getProjectsWithPage(page)}
              >
                <span>{i}</span>
              </PaginationLink>
            </PaginationItem>
          );
        }
      }
    }

    return paginations;
  }

  return (
    <React.Fragment>
      {props.loading ? (
        <GlobalLoader />
      ) : (
        <div className="page-content">
          {props.projects && props.projects.length === 0 ? (
            <div className="text-center">Aucun projets à afficher</div>
          ) : (
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Projets" breadcrumbItem="Tous les Projets" />
              <Row>
                <Col lg="12">
                  <Alert
                    color="success"
                    isOpen={flash_message && !props.error}
                    toggle={() => setFlash_message(false)}
                  >
                    Le projet à bien été supprimé.
                  </Alert>
                </Col>
              </Row>
              <Row>
                {/* Import Cards */}
                <CardProject
                  projects={props.projects}
                  users={props.users}
                  handleDeleteProject={handleDeleteProject}
                />
              </Row>
              <Row>
                <Col lg="12">
                  <Pagination className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                    {props.pagination.current_page === 1 ? (
                      <PaginationItem disabled>
                        <PaginationLink previous href="#" />
                      </PaginationItem>
                    ) : (
                      <PaginationItem>
                        <PaginationLink
                          previous
                          href="#"
                          onClick={() =>
                            getProjectsWithPage(
                              props.pagination.current_page - 1
                            )
                          }
                        />
                      </PaginationItem>
                    )}
                    {props.pagination.current_page === 1 ? (
                      <PaginationItem active>
                        <PaginationLink href="#">1</PaginationLink>
                      </PaginationItem>
                    ) : (
                      <PaginationItem>
                        <PaginationLink
                          href="#"
                          onClick={() => getProjectsWithPage(1)}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    {props.pagination.current_page > 4 ? (
                      <li className="font-size-15">...</li>
                    ) : null}
                    {getPaginations()}
                    {props.pagination.current_page + 3 <
                    props.pagination.total_pages ? (
                      <li className="font-size-15">...</li>
                    ) : null}
                    {props.pagination.total_pages === 1 ? null : props
                        .pagination.current_page ===
                      props.pagination.total_pages ? (
                      <PaginationItem active>
                        <PaginationLink href="#">
                          {props.pagination.total_pages}
                        </PaginationLink>
                      </PaginationItem>
                    ) : (
                      <PaginationItem>
                        <PaginationLink
                          href="#"
                          onClick={() =>
                            getProjectsWithPage(props.pagination.total_pages)
                          }
                        >
                          {props.pagination.total_pages}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    {props.pagination.current_page ===
                    props.pagination.total_pages ? (
                      <PaginationItem disabled>
                        <PaginationLink next href="#" />
                      </PaginationItem>
                    ) : (
                      <PaginationItem>
                        <PaginationLink
                          next
                          href="#"
                          onClick={() =>
                            getProjectsWithPage(
                              props.pagination.current_page + 1
                            )
                          }
                        />
                      </PaginationItem>
                    )}
                  </Pagination>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { projects, pagination, loading } = state.Projects;
  const { users } = state.Users;
  return { users, projects, pagination, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    getUsers,
    getProjects,
    deleteProject,
    projectsApiError,
  })(ProjectsGrid)
);
