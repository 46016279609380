import React from "react";
import { withRouter } from "react-router-dom";
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledCollapse,
    Collapse
  } from "reactstrap";

// Redux
import { connect } from "react-redux";

// Helpers
import {
    getTimeWithFormat
} from "../../../helpers/helper";

// actions
import {
    getAgendaItem,
    createAgendaItem,
    updateAgendaItem,
    agendaItemsApiError,
} from "../../../store/actions";

import { agendaItems } from "../../../api/index";

import { parseTmpEvent } from "./common";

import AgendaItemHistory from "./Common/AgendaItemHistory";
import ProjectDetail from "./Common/ProjectDetail";

// Must be a class to use React ref because function not have a reference
class CommercialModals extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal2: false,
            historyCollapse: false,
            projectDetailsCollapse: false,
            event: this.props.event,  // if false then show edit form else show info
            agendaItem: null
        };
    }

    openAgendaEvent(arg) {
        if (arg.event) {
            const eventNew = arg.event;
            // Get Full AgendaItem informations before set event
            agendaItems.getOne(eventNew.extendedProps.agendaItem.id).then((response) => {
                let agendaItem = response.data;
                this.setState({
                    event: parseTmpEvent(arg, agendaItem),
                    historyCollapse: false,
                    projectDetailsCollapse: false,
                    agendaItem
                });
                this.toggleCommercialModalInfo();
            });
        }
    }

    toggleCommercialModalInfo() {
        this.setState({
            modal2: !this.state.modal2
        });
    }

    render() {
        if (!this.state.event) return null;
        else return (
            <div>
                {/* edit event modal */}
                <Modal
                    isOpen={this.state.modal2}
                    toggle={() => this.toggleCommercialModalInfo()}
                    className=""
                >
                    <ModalHeader
                        toggle={() => this.toggleCommercialModalInfo()}
                        tag="h4"
                    >
                        Rendez-vous commercial
                    </ModalHeader>
                    <ModalBody>
                        <h5
                            className={`accordion-title`}
                            id="commercial_edit_details"
                        >
                            <div className="d-flex align-items-center pt-3 pb-3">
                            <i
                                className={`bx bx-chevron-${
                                    document.querySelector("[toggler='#commercial_edit_details']")?.classList?.contains("show") ? "up" : "down"
                                } mr-1`}
                            ></i>{" "}
                                Détails
                            </div>
                            <hr className="m-0" />
                        </h5>
                        <UncontrolledCollapse toggler="#commercial_edit_details" className="mt-4">
                            <Row>
                            <Col xs="6">
                                <p>
                                <b>Heure de début :</b>{" "}
                                    {getTimeWithFormat(
                                        this.state.event.extendedProps.date_begin
                                    )}
                                </p>
                            </Col>
                            <Col xs="6">
                                <p>
                                <b>Heure de fin :</b>{" "}
                                {getTimeWithFormat(
                                    this.state.event.extendedProps.date_end
                                )}
                                </p>
                            </Col>
                            </Row>
                            {this.state.event.extendedProps.user ? (
                            <Row>
                                <Col>
                                <p>
                                    <b>Commercial :</b>{" "}
                                    {this.state.event.extendedProps.user.label}
                                </p>
                                </Col>
                            </Row>
                            ) : null}
                            <Row>
                            <Col>
                                <p>
                                <b>Prospect/client :</b>{" "}
                                {this.state.event.extendedProps.customer.label}
                                </p>
                            </Col>
                            </Row>
                            {this.state.event.extendedProps.address ? (
                            <Row>
                                <Col>
                                <p>
                                    <b>Lieu du rendez-vous :</b>{" "}
                                    {
                                    this.state.event.extendedProps.address
                                        .label
                                    }
                                </p>
                                </Col>
                            </Row>
                            ) : null}
                        </UncontrolledCollapse>
                        {
                            this.state.agendaItem && this.state.agendaItem.project
                                ?   <>
                                        <h5
                                            className={`accordion-title`}
                                            onClick={() => this.setState({ projectDetailsCollapse: !this.state.projectDetailsCollapse })}
                                        >
                                            <div className="d-flex align-items-center pt-3 pb-3">
                                            <i
                                                className={`bx bx-chevron-${
                                                this.state.projectDetailsCollapse ? "up" : "down"
                                                } mr-1`}
                                            ></i>{" "}
                                                Détails du projet
                                            </div>
                                            <hr className="m-0" />
                                        </h5>
                                        <Collapse isOpen={this.state.projectDetailsCollapse} className="mt-4" >
                                            <ProjectDetail project={this.state.agendaItem.project}/>
                                        </Collapse>
                                    </>
                                :   null
                        }
                        {
                            this.state.agendaItem
                                ?   <>
                                        <h5
                                            className={`accordion-title`}
                                            onClick={() => this.setState({ historyCollapse: !this.state.historyCollapse })}
                                        >
                                            <div className="d-flex align-items-center pt-3 pb-3">
                                            <i
                                                className={`bx bx-chevron-${
                                                this.state.historyCollapse ? "up" : "down"
                                                } mr-1`}
                                            ></i>{" "}
                                                Historique
                                            </div>
                                            <hr className="m-0" />
                                        </h5>
                                        <Collapse isOpen={this.state.historyCollapse} className="mt-4" >
                                            <AgendaItemHistory agendaItem={this.state.agendaItem}/>
                                        </Collapse>
                                    </>
                                :   null
                        }
                    </ModalBody>
                </Modal>
            </div>
        );
    }
};

const mapStatetoProps = (state) => {
    const {
        agendaItem,
        error,
    } = state.AgendaItems;
    const agendaItemLoading = state.AgendaItems.loading;
    return {
        error,
        agendaItem,
        agendaItemLoading,
    };
};

// Use for forwardRef
const wrapper = React.forwardRef((props, ref) => (
    <CommercialModals {...props} ref={ref} />
));
  
export default withRouter(
    connect(mapStatetoProps, {
        getAgendaItem,
        createAgendaItem,
        updateAgendaItem,
        agendaItemsApiError,
    }, null, {forwardRef: true})(wrapper)
);