import React, {useCallback, useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import SelectTimeComponent from "../Components/SelectTimeComponent";
import AsyncSelect from "react-select/async";
import {promiseUsersOptions} from "../../../helpers/autocomplete_promise_options";
import fr from "date-fns/locale/fr";

const TechnicalMeetingForm = ({
                                disponibility_vt,
                                setDisponibility_vt,
                                disponibility_vt_end_at,
                                setDisponibility_vt_end_at,
                                technicians,
                                setTechnicians,
                                comment_vt,
                                setComment_vt
                              }) => {

  /**
   * Fonction qui permet de gérer la hauteur du textarea du champ commentaire
   * @param {object} e L'événement retourné par le textarea, qui contient la balise HTML du textarea
   */
  const updateTextareaHeight = (e) => {
    e.currentTarget.style.height = "auto";
    e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
  };

  const computeTime = useCallback(() => {
      if (disponibility_vt_end_at && disponibility_vt) {
          const start = new Date(disponibility_vt);
          const end = new Date(disponibility_vt_end_at);

          // get the time past in seconds
          let timePast = (end.getTime() - start.getTime()) / 1000;

          return {hour: Math.trunc(timePast / 3600), minute: Math.round((timePast % 3600) / 60)}
      }

      return null;
  }, [disponibility_vt, disponibility_vt_end_at]);

  const [duration, setDuration] = useState(null);
  useEffect(() => {
      setDuration(computeTime())
  }, [computeTime])

  return (
    <div className="disponibility-vt-modal-container">
      <div className="disponibility-vt-flex-container">
        <div className="disponibility-vt-flex-item">
          <p>Date et heure de début</p>
          <DatePicker
            className="form-control button-without-style disponibility-vt-input"
            showTimeSelect
            timeIntervals={15}
            selected={
              typeof disponibility_vt === "string"
                ? new Date(disponibility_vt)
                : disponibility_vt
            }
            onChange={(date) => {
              setDisponibility_vt(date);
              let dateTmp = new Date(date);
              if (duration) {
                dateTmp.setTime(dateTmp.getTime() + ((duration.hour * 3600 + duration.minute * 60) * 1000));
                setDisponibility_vt_end_at(dateTmp);
              }
            }}
            timeCaption="Heure"
            dateFormat="dd/MM/yyyy HH:mm"
            locale={fr}
            placeholderText="Choisir la date et l'heure de début"
          />
        </div>
        <div className="disponibility-vt-flex-item">
          <p>Durée</p>
          <SelectTimeComponent
            maxTime='5:00'
            duration={duration}
            onChange={(value) => {
              setDuration(value);
              let dateTmp = new Date(disponibility_vt);
              if (dateTmp) {
                dateTmp.setTime(dateTmp.getTime() + ((value.hour * 3600 + value.minute * 60) * 1000));
                setDisponibility_vt_end_at(dateTmp);
              }
            }}
          />
        </div>
      </div>
      <p>Technicien</p>
      <AsyncSelect
        className="w-100 disponibility-vt-input"
        isSearchable={true}
        isMulti={true}
        value={technicians}
        noOptionsMessage={() => "Pas de technicien disponible"}
        placeholder="Choisir un technicien"
        cacheOptions
        defaultOptions
        loadOptions={() => promiseUsersOptions("Technicien")}
        onChange={(technicians) => {
          let temp = [];
          if (technicians !== null) {
            technicians.forEach((technician) => {
              temp.push({
                value: technician.value,
                label: technician.label,
              });
            });
          }
          setTechnicians(temp);
        }}
      />
      <p>Commentaire</p>
      <textarea
        rows="2"
        className="form-control"
        placeholder="Ajouter un commentaire"
        value={comment_vt}
        onChange={(e) => setComment_vt(e.currentTarget.value)}
        onFocus={(e) => updateTextareaHeight(e)}
        onInput={(e) => updateTextareaHeight(e)}
      />
    </div>
  );
}

export default TechnicalMeetingForm;