import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

import { Card, CardBody, CardTitle, Button } from "reactstrap";

//Import Date Picker
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

import {
  getDateWithShortFormat,
  getTimeWithFormat,
} from "../../../helpers/helper";

// actions
import { updateProject } from "../../../store/actions";

const DisponibilityCard = (props) => {
  const [disponibilityDay, setDisponibilityDay] = useState(null);
  const [disponibilityTimeStartAt, setDisponibilityTimeStartAt] = useState(
    null
  );
  const [disponibilityTimeEndAt, setDisponibilityTimeEndAt] = useState(null);
  const [listDisponibilityToAdd, setListDisponibilityToAdd] = useState([]);

  // Récupère les disponibilités à afficher
  useEffect(() => {
    setListDisponibilityToAdd(
      props.project.disponibilities.map((dispo) => ({
        id: dispo.id,
        begin_at: dispo.begin_at,
        end_at: dispo.end_at,
      }))
    );
  }, [props.project.disponibilities]);

  /**
   * Ajouter une nouvelle disponibilité au tableau
   */
  const addDisponibility = () => {
    let temp = listDisponibilityToAdd;
    if (
      disponibilityDay &&
      disponibilityTimeStartAt &&
      disponibilityTimeEndAt
    ) {
      temp.push({
        begin_at: new Date(
          disponibilityDay.getFullYear(),
          disponibilityDay.getMonth(),
          disponibilityDay.getDate(),
          disponibilityTimeStartAt.getHours(),
          disponibilityTimeStartAt.getMinutes()
        ),
        end_at: new Date(
          disponibilityDay.getFullYear(),
          disponibilityDay.getMonth(),
          disponibilityDay.getDate(),
          disponibilityTimeEndAt.getHours(),
          disponibilityTimeEndAt.getMinutes()
        ),
      });
      setDisponibilityDay(null);
      setDisponibilityTimeStartAt(null);
      setDisponibilityTimeEndAt(null);
    }
    setListDisponibilityToAdd(temp);
  };

  /**
   * Envoyer le nouveau tableau de disponibilités
   */
  const validateDisponibilities = () => {
    let temp = listDisponibilityToAdd
      .filter((elem) => (elem.id ? elem._destroy : true))
      .map((dispo) => dispo);
    if (temp.length > 0) {
      props.updateProject(props.project.id, {
        disponibilities_attributes: temp,
      });
      props.setFlash_message(true);
    }
  };

  /**
   * Supprimer une disponibilité
   * @param {number} disponibility_id L'identifiant de la disponibilité à supprimer
   * @param {number} index L'index de lélément ajouté et pas encore attaché au projet
   */
  const removeDisponibility = (disponibility_id, index) => {
    let temp = listDisponibilityToAdd.map((item) => item);
    if (disponibility_id === -1) {
      temp.splice(index, 1);
    } else {
      temp[index] = {
        id: disponibility_id,
        _destroy: true,
      };
    }
    setListDisponibilityToAdd(temp);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-3">Disponibilités client</CardTitle>
        <div className="disponibility-new">
          <div className="disponibility-datepickers">
            <div className="disponibility-datepicker-container">
              <DatePicker
                className="form-control button-without-style"
                selected={disponibilityDay}
                onChange={(date) => {
                  setDisponibilityDay(date);
                }}
                dateFormat="dd/MM/yyyy"
                locale={fr}
                placeholderText="Jour"
              />
            </div>
            <div className="disponibility-datepicker-container">
              <DatePicker
                className="form-control button-without-style"
                showTimeSelect
                showTimeSelectOnly
                selected={disponibilityTimeStartAt}
                onChange={(date) => {
                  setDisponibilityTimeStartAt(date);
                }}
                timeCaption="Heure"
                dateFormat="HH:mm"
                locale={fr}
                placeholderText="Heure de début"
              />
            </div>
            <div className="disponibility-datepicker-container">
              <DatePicker
                className="form-control button-without-style"
                showTimeSelect
                showTimeSelectOnly
                selected={disponibilityTimeEndAt}
                onChange={(date) => {
                  setDisponibilityTimeEndAt(date);
                }}
                timeCaption="Heure"
                dateFormat="HH:mm"
                locale={fr}
                placeholderText="Heure de fin"
              />
            </div>
            <div className="disponibility-add-input">
              <span onClick={addDisponibility}>
                <i className="fas fa-plus"></i>
              </span>
            </div>
          </div>
        </div>
        <div>
          {listDisponibilityToAdd &&
            listDisponibilityToAdd
              .sort((firstEl, secondEl) =>
                new Date(firstEl.begin_at).getTime() <
                new Date(secondEl.begin_at).getTime()
                  ? -1
                  : 1
              )
              .map((disponibility, index) =>
                disponibility._destroy ? null : (
                  <div
                    className="project-disponibility-info d-flex justify-content-between align-items-center"
                    key={`disponibility-${index}`}
                  >
                    <p className="m-0">
                      <span>Le </span>
                      <span className="table-link">
                        {getDateWithShortFormat(disponibility.begin_at)}
                      </span>
                      <span> de </span>
                      <span className="table-link">
                        {getTimeWithFormat(disponibility.begin_at)}
                      </span>
                      <span> à </span>
                      <span className="table-link">
                        {getTimeWithFormat(disponibility.end_at)}
                      </span>
                    </p>
                    <i
                      className="fas fa-times"
                      onClick={() =>
                        removeDisponibility(
                          disponibility.id ? disponibility.id : -1,
                          index
                        )
                      }
                    ></i>
                  </div>
                )
              )}
        </div>
        <div className="disponibility-new">
          <div className="text-center">
            <Button
              color="none"
              className="btn-green"
              onClick={validateDisponibilities}
            >
              Sauvegarder les modifications
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStatetoProps = (state) => {
  const { project } = state.Projects;

  return { project };
};

export default withRouter(
  connect(mapStatetoProps, { updateProject })(DisponibilityCard)
);
