import  { useEffect, Dispatch, SetStateAction } from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  LoadScript,
} from "@react-google-maps/api";

import { getDirectionsRequest } from "../../../helpers/map_helper";
import CustomMarkerComponent, { CustomMarker } from "./CustomMarker";
import { parseCustomerGPSCoord } from "../../../helpers/customer_helper";

const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY!;
const REACT_LIBRARIES : ("drawing" | "geometry" | "localContext" | "places" | "visualization")[] = ["geometry", "drawing", "places"];

interface MapProps {
  waypoints: CustomMarker[],
  defaultZoom?: number,
  defaultCenter?: google.maps.LatLng | google.maps.LatLngLiteral,
  markers: CustomMarker[],
  directionsResult?: google.maps.DirectionsResult,
  setDirectionsResult: Dispatch<SetStateAction<google.maps.DirectionsResult | undefined>>,
  onShowModal: (marker: CustomMarker) => void
}

const Map = ({
  waypoints,
  defaultZoom,
  defaultCenter,
  markers,
  directionsResult,
  setDirectionsResult,
  onShowModal
}: MapProps) => {

  const update = () => {
    if (!(window as any).google) return;
    const directionsService: google.maps.DirectionsService = new google.maps.DirectionsService();

    const directionsRequest = getDirectionsRequest(waypoints.map(marker => parseCustomerGPSCoord(marker.entity.client)));

    if (directionsRequest) {
      directionsService.route(
        directionsRequest,
        (result: any, status: any) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirectionsResult(result)
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      )
    } else {
      setDirectionsResult(undefined);
    }
  }

  useEffect(update, [setDirectionsResult, waypoints])

  return (
    <LoadScript
      googleMapsApiKey={REACT_APP_GOOGLE_API_KEY}
      libraries={REACT_LIBRARIES}
    >
      <GoogleMap
        zoom={defaultZoom}
        center={defaultCenter}
        options={{ fullscreenControl: false }}
        mapContainerClassName="w-100 h-100"
        onLoad={update}
      >
        {directionsResult && <DirectionsRenderer directions={directionsResult} />}
        {markers
          .filter(marker => !!!waypoints.find(waypoint => waypoint.entity.id === marker.entity.id))
          .map((marker: CustomMarker, _: any) => (
            <CustomMarkerComponent
              marker={marker}
              onShowModal={onShowModal}
            />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;