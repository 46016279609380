import {useEffect, useState} from "react";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";

import Select from "react-select";

// Helpers
import {
  promiseCustomersOptions,
  promiseUsersOptions,
  promiseProjectsOptions,
} from "../../../../helpers/autocomplete_promise_options";
import { FormikAutocompleteUnselectable } from "../../../../components/Common/AsyncAutoComplete2";

const NotificationsForm = (props) => {

  const [projectOptions, setProjectOptions] = useState([]);

  const computeProjectsOptions = (client_id) => {
    promiseProjectsOptions(client_id).then(r => setProjectOptions(r));
  }

  useEffect(() => {
    if (props.initValues.client_id)
      promiseProjectsOptions(props.initValues.client_id).then(r => setProjectOptions(r));
  }, [props.initValues.client_id]);

  return (
    <Formik
      initialValues={props.initValues}
      validationSchema={props.validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        props.handleSubmit(values);
      }}
    >{({values, errors, touched, setFieldValue}) => {
      return (
        <Form>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <Row>
                <Col xs="4">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="client_id" className="col-form-label col">
                      Client *
                    </Label>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        loadOptions={promiseCustomersOptions}
                        id='client_id'
                        name='client_id'
                        invalid={errors.client_id && !!touched.client_id}
                        noOptionsMessage={() => "Aucun clients disponible"}
                        placeholder="Sélectionner"
                        onChangeValue={(name, value) => computeProjectsOptions(value)}
                      />
                      <ErrorMessage
                        className={errors.client_id ? "errorMessages" : null}
                        name="client_id"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="user_to_id" className="col-form-label col">
                      Collaborateur *
                    </Label>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        loadOptions={promiseUsersOptions}
                        id='user_to_id'
                        name='user_to_id'
                        noOptionsMessage={() => "Aucun collaborateurs disponible"}
                        placeholder="Sélectionner"
                        invalid={errors.user_to_id || touched.user_to_id}
                      />
                      <ErrorMessage
                        className={errors.user_to_id ? "errorMessages" : null}
                        name="user_to_id"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="project_id" className="col-form-label col">
                      Projet
                    </Label>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        loadOptions={projectOptions}
                        id='project_id'
                        name='project_id'
                        noOptionsMessage={() => "Aucun projets disponible"}
                        placeholder="Sélectionner"
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="4">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="priority" className="col-form-label col">
                      Priorité *
                    </Label>
                    <Col xs="12">
                      <Select
                        className={`w-100 calendar-filter-select mb-3 ${errors.priority ? "selectorError" : ""}`}
                        isSearchable
                        noOptionsMessage={() => "Pas de priorité disponible"}
                        placeholder="Choisir une priorité..."
                        options={[
                          {value: "Normal", label: "Normal"},
                          {value: "Haut", label: "Haut"},
                          {value: "Urgent", label: "Urgent"},
                        ]}
                        value={values.priority}
                        onChange={(priority) => {
                          setFieldValue("priority", priority);
                        }}
                      />
                      <ErrorMessage
                        className={errors.priority ? "errorMessages" : null}
                        name="priority"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="title" className="col-form-label col">
                      Titre *
                    </Label>
                    <Col xs="12">
                      <Input
                        id="title"
                        name="title"
                        type="text"
                        tag={Field}
                        invalid={errors.title && !!touched.title}
                        className="form-control"
                        placeholder="Entrer un titre"
                        onChange={(event) => {
                          setFieldValue("title", event.target.value);
                        }}
                      />
                      <ErrorMessage
                        className={errors.title ? "errorMessages" : null}
                        name="title"
                        component={FormFeedback}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col xs="4">
                  {props.isUpdate &&
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="state" className="col-form-label col">
                        État
                      </Label>
                      <Col xs="12">
                        <Select
                          className="w-100 calendar-filter-select mb-3"
                          isSearchable
                          noOptionsMessage={() => "Pas d'état disponible"}
                          placeholder="Choisir un état..."
                          options={[
                            {value: "Nouveau", label: "Nouveau"},
                            {value: "Terminée", label: "Terminée"},
                            {value: "Archivée", label: "Archivée"},
                          ]}
                          value={values.state}
                          onChange={(state) => {
                            setFieldValue("state", state);
                          }}
                        />
                      </Col>
                    </FormGroup>}
                </Col>
              </Row>
              { !props.isUpdate && <Row>
                  <Col xs="4">
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="message" className="col-form-label col">
                        Message
                      </Label>
                      <Col xs="12">
                        <Input
                          id="message"
                          name="message"
                          type="textarea"
                          component="textarea"
                          tag={Field}
                          invalid={errors.message && !!touched.message}
                          className="form-control"
                          placeholder="Entrer un titre"
                          onChange={(event) => {
                            setFieldValue("message", event.target.value);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              }
            </div>
          </div>
          <Row className="justify-content-end mt-4">
            <Col xs="12" className="text-center">
              <Button type="submit" color="primary">
                {`${props.isUpdate ? "Modifier" : "Créer"} la relance`}
              </Button>
            </Col>
          </Row>
        </Form>
      );
    }}</Formik>
  );
};

export default NotificationsForm;
