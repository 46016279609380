import {useEffect, useState} from "react";

import {Container, Row, Col, Card, CardBody, CardTitle} from "reactstrap";

import QuoteInvoiceComponent from "./Components/QuoteInvoiceComponent";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {typeForm} from "../../helpers/quotes_invoices_helper";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getOrder} from "../../store/orders/actions";


const QuoteAdd = ({
    getOrder,
    order,
    loading,
    quote_order
                  }) => {

    const [type, setType] = useState(window.location.pathname.split("/").length === 4 ? typeForm.approval : typeForm.quote);

    useEffect(() => {
        if (window.location.pathname.split("/")[3] !== undefined) {
            console.log("use effect")
            setType(typeForm.approval);
            getOrder(window.location.pathname.split("/")[3]);
        }
    }, [getOrder]);

    return (
        <div className="page-content">
            <Container fluid className="quotes-container">
                {/* Render Breadcrumbs */}
                <Breadcrumbs
                    title="Devis"
                    breadcrumbItem={`Ajouter un ${
                        type === typeForm.approval
                            ? "avenant"
                            : "devis"
                    }`}
                />

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4">
                                    Créer un{" "}
                                    {type === typeForm.approval
                                        ? "avenant"
                                        : "devis"}
                                </CardTitle>
                                {!loading ?
                                    <QuoteInvoiceComponent
                                        typeForm={type}
                                        quote_order={quote_order}
                                        order={order}
                                    />
                                    :
                                    ""
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const {quote_order} = state.Quotes;
    const { order, loading } = state.Orders;

    return { order, loading, quote_order };
};

export default withRouter(
    connect(mapStatetoProps, {
        getOrder,
    })(QuoteAdd)
);
