import {
  CREATE_DEPOSIT,
  CREATE_DEPOSIT_SUCCESS,
  UPDATE_DEPOSIT,
  UPDATE_DEPOSIT_SUCCESS,
  DEPOSITS_API_ERROR,
} from "./actionTypes";

const initialState = {
  deposit: false,
  error: false,
  loading: false,
};

const deposits = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DEPOSIT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_DEPOSIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case UPDATE_DEPOSIT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_DEPOSIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case DEPOSITS_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default deposits;
