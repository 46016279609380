/**
 * Set the lists of users that have mails
 * @param idList
 * @param items
 * @param setIsListUsersHaveMail
 * @param setAllListUsersHaveMail
 */
export function listUsersHaveMail(idList, items, setIsListUsersHaveMail, setAllListUsersHaveMail) {
  let temp = [];
  let bool = true;

  items.forEach((item) => {
    if (
      idList.includes(item.id) &&
      (item.order.client.email === null || item.order.client.email === "")
    ) {
      temp.push(
        item.order.client.client_type === "Professionnel"
          ? item.order.client.company_name
          : `${item.order.client.firstname} ${item.order.client.lastname}`
      );
      bool = false;
    }
  });

  setIsListUsersHaveMail(bool);
  setAllListUsersHaveMail(temp);
}

/**
 * @param flash_message
 * @param setFlash_message
 */
export function removeFlashMessage(flash_message, setFlash_message) {
  window.history.pushState({}, document.title, "/invoices");
  setFlash_message(!flash_message);
}