import {History} from "history";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Row
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FlashMessage from "../../components/Common/FlashMessage";
import {useEffect, useState} from "react";
import { match } from 'react-router';
import {V2} from "../../api";
import GlobalLoader from "../../components/Common/GlobalLoader";
import RequestDetailClientTab from "./Components/Tabs/request-detail-client-tab";
import RequestDetailRequestTab from "./Components/Tabs/request-detail-request-tab";
import Request from "../../models/request";
import {parseQuery, setQuery} from "../../helpers/helper";

interface RequestDetailsProps {
  match: match<{ id: string }>,
  history: History
}

export default function RequestDetails({
  match,
  history
}: RequestDetailsProps){
  const query = parseQuery();
  const [request, setRequest] = useState<Request|null>(null);
  const [filters, setFilters] = useState({tab: query.tab ? parseInt(query.tab as string) : 0 });
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const enum tabs{DETAILS}

  useEffect(() => {
    V2.requests.get(parseInt(match.params.id), {groups:["request_info"]}).then(({data}) => {
      setRequest(data);
    })
  }, [match.params.id, history]);

  useEffect(() => {
    setQuery(filters, history)
  }, [filters, history]);

  function handleDelete(){
    if(!request?.id) {
      setDeleteModalShow(false);
      return;
    }
    V2.requests.delete(request?.id, {}).then(() => {
      history.push(`/requests-list`);
    })
  }

  return(
    <>
      {request?(
        <>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs
                title={"Détails de la demande"}
                breadcrumbTitle="Suivi des demandes"
                breadcrumbItem={"Détails de la demande"}
              />
              <FlashMessage/>
              <Row>
                <Col>
                  <Card className={"p-3"}>
                    <CardTitle className="mb-0">
                      <Row className="pr-2 pl-2 d-flex">
                        <Col>Détails de la demande</Col>
                      </Row>
                    </CardTitle>
                    <ul className="nav nav-tabs nav-tabs-custom my-2">
                      <NavItem>
                        <NavLink className={filters.tab === tabs.DETAILS ? "active" : ""}
                                 onClick={() => {setFilters({tab:tabs.DETAILS});}}
                        >Details</NavLink>
                      </NavItem>
                    </ul>
                    <div className={"mb-5"}>
                      {filters.tab === tabs.DETAILS ?
                        <>
                          <RequestDetailClientTab request={request}/>
                          <b className="d-block mt-4 pl-2 mb-n1">Demande :</b>
                          <RequestDetailRequestTab request={request}/>
                        </>
                        : null}
                    </div>
                    <div className="d-flex justify-content-end py-1 px-3 bg-gray-300 mb-n3 mx-n3 mt-3 rounded-bottom">
                      {filters.tab === tabs.DETAILS ?
                        <>
                          <button className="btn bg-transparent shadow-none d-flex align-items-center text-link mr-4" type="button"
                                  onClick={()=>history.push(`/customer/detail/${request?.client?.id}`)}
                          >
                            <i className="bx bx-show mr-1"/>Fiche client
                          </button>
                          <button className="btn bg-transparent shadow-none d-flex align-items-center text-link mr-4" type="button"
                                  onClick={()=>history.push(`/requests/customers/${request?.client?.id}/requests/edit/${request.id}`)}
                          >
                            <i className="bx bx-pencil mr-1"/>Modifier
                          </button>
                          <button className="btn bg-transparent shadow-none d-flex align-items-center text-link mr-4" type="button"
                                  onClick={()=>setDeleteModalShow(true)}
                          >
                            <i className="bx bx-trash mr-1"/>Supprimer
                          </button>
                        </>
                        : null}
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>

          <Modal isOpen={deleteModalShow} toggle={() => setDeleteModalShow(false)} className={""}>
            <ModalHeader toggle={() => setDeleteModalShow(false)}>Supprimer Demande?</ModalHeader>
            <ModalBody>
              <div>
                <h2>Attention</h2>
                <p>Cette action est irréversible</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button type="button" className={"btn btn-secondary"} onClick={() => setDeleteModalShow(false)}>Annuler</Button>
              <Button type="button" className={"btn btn-green"} onClick={handleDelete}>Confirmer</Button>
            </ModalFooter>
          </Modal>
        </>
      ):<GlobalLoader/>}
    </>
  );
}