import {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Collapse, NavLink, NavItem,
} from "reactstrap";
import Select from "react-select";

// Redux
import {connect} from "react-redux";

//Import Date Picker
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

// Pages Components
import ReactApexChart from "react-apexcharts";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {
  getStatistics,
  getAllRepartitions,
  getAgencies,
  getUsers,
  statisticApiError,
  agencyApiError,
  userApiError, getCategoryRepartition, getProductRepartition,
} from "../../store/actions";
import DashboardRepartitionComponent from "./DashboardRepartitionComponent";

const TABS = {
  GENERAL: 'general',
  REPARTITION: 'repartition'
}

const DIAGRAM_TYPE = {
  LINE: 'line',
  BAR: 'bar'
}

const Dashboard = (props) => {
  const [period, setPeriod] = useState("year");
  const [filters, setFilters] = useState(false);
  const [reset_filters, setReset_filters] = useState(false);
  const [users, setUsers] = useState(Array(0));
  const [agencies, setAgencies] = useState(Array(0));
  const [categories, setCategories] = useState(Array(0));
  const [user_id, setUser_id] = useState(null);
  const [agency_id, setAgency_id] = useState(null);
  const [category_id, setCategory_id] = useState(null);
  const [tab1, setTab1] = useState(Array(0));
  const [tab2, setTab2] = useState(Array(0));
  const [tab3, setTab3] = useState(Array(0));
  const [tab4, setTab4] = useState(Array(0));
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [diagramType, setDiagramType] = useState(DIAGRAM_TYPE.LINE);
  const [activeTab, setActiveTab] = useState(TABS.GENERAL);

  useEffect(() => {
    let params = {};
    if (agency_id !== null && agency_id !== undefined) {
      params.agency = agency_id.value;
      params.agency_ids = agency_id.value; // For family, products, categories repartition
    }
    if (user_id !== null && user_id !== undefined) {
      params.commercial = user_id.value;
      params.user_ids = user_id.value; // For family, products, categories repartition
    }
    if (category_id !== null && category_id !== undefined) {
      params.categorie = category_id.value;
      params.category_ids = category_id.value; // For family, products, categories repartition
    }
    if (period === "interval") {
      params.date_begin = `${startDate.getFullYear()}-${(
        "0" +
        (startDate.getMonth() + 1)
      ).slice(-2)}-${("0" + startDate.getDate()).slice(-2)}`;
      params.date_end = `${endDate.getFullYear()}-${(
        "0" +
        (endDate.getMonth() + 1)
      ).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`;
    } else if (period === "month") {
      params.current_month = "1";
    } else if (period === "quarter") {
      params.current_quarter = "1";
    } else if (period === "year") {
      params.current_year = "1";
    }
    props.getStatistics.call(undefined,params);
    props.getAllRepartitions.call(undefined,params);
  }, [period, user_id, agency_id, category_id, endDate, startDate, props.getStatistics, props.getAllRepartitions]);

  useEffect(() => {
    setAgency_id(null);
    setUser_id(null);
    setCategory_id(null);
    setPeriod("year");
    setStartDate(new Date());
    setEndDate(new Date());
  }, [reset_filters]);

  useEffect(() => {
    if (props.statistics) {
      let tab11 = [];
      let tab12 = [];
      let tab13 = [];
      let tab14 = [];

      props.statistics.months.forEach((value, index) => {
        tab11.push(parseFloat(value.ca_brut_ht_current).toFixed(2));
        tab12.push(parseFloat(value.ca_brut_ht_last).toFixed(2));
        tab13.push(parseFloat(value.ca_net_ht_current).toFixed(2));
        tab14.push(parseFloat(value.ca_net_ht_last).toFixed(2));
      });

      setTab1(tab11);
      setTab2(tab12);
      setTab3(tab13);
      setTab4(tab14);

      setAgencies(
        props.statistics.agencies.map((agency, index) => ({
          value: agency.id,
          label: agency.name,
        }))
      );

      setUsers(
        props.statistics.commercials.map((user, index) => ({
          value: user.id,
          label: user.firstname + " " + user.lastname,
        }))
      );

      setCategories(
        props.statistics.categories.map((category, index) => ({
          value: category.id,
          label: category.name,
        }))
      );
    }
  }, [props.statistics]);

  const year = period === "interval" ? endDate.getFullYear() : new Date().getFullYear();

  let series = [
    {
      name: "CA BRUT N-1",
      data: tab2,
    },
    {
      name: "CA NET N-1",
      data: tab4,
    },
    {
      name: "CA BRUT N",
      data: tab1,
    },
    {
      name: "CA NET N",
      data: tab3,
    },
  ];

  if (diagramType === DIAGRAM_TYPE.LINE) {
    series = series.map((serie) => {
      return {...serie, type: 'area'}
    })
  }

  const options = {
    chart: {
      toolbar: {show: !1},
      zoom: {
        enabled: false,
      },
      type: diagramType,
    },
    dataLabels: {enabled: !1},
    stroke: {curve: "smooth", width: 2, dashArray: [0, 0, 0, 0]},
    fill: {
      type: "solid",
      opacity: diagramType === DIAGRAM_TYPE.BAR ? 1 : 0,
      colors: ["#00b6f0", "#001da9", "#b9df3f", "#9cc31c"],
    },
    xaxis: {
      categories: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
    },
    colors: ["#00b6f0", "#001da9", "#b9df3f", "#9cc31c"],
  };

  const seriesRadial = [props.statistics.cancel_order_rate_current];
  const optionsRadial = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Taux d'annulation",
          },
        },
      },
    },
    labels: ["Taux d'annulation"],
    colors: ["#001da9"],
  };

  const seriesRadial2 = [props.statistics.conversion_order_rate_current];
  const optionsRadial2 = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Taux de transformation",
          },
        },
      },
    },
    labels: ["Taux de transformation"],
    colors: ["#001da9"],
  };

  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumb */}
        <Breadcrumbs
          title="Tableau de bord"
          breadcrumbItem="Visualisation des statistiques"
        />

        {props.loading ? null : props.statistics ? (
          <Row>
            <Col xs="12">
              <div>
                <Row>
                  <Col xs="12" className="d-flex justify-content-start">
                    <Link
                      to="#"
                      className="d-flex align-items-center text-theme-color-2 mb-3"
                      onClick={() => setFilters(!filters)}
                    >
                      <i className="fas fa-filter mr-1"></i> Filtrer
                    </Link>
                    <Link
                      to="#"
                      className="d-flex align-items-center text-theme-color mb-3 ml-3"
                      onClick={() => setReset_filters(!reset_filters)}
                    >
                      <i className="fas fa-times mr-1"></i> Vider les filtres
                    </Link>
                  </Col>
                  <Col xs="12">
                    <Collapse isOpen={filters}>
                      <Card>
                        <CardBody>
                          <div className="text-center">
                            <div className="btn-group btn-group-filter">
                              <button
                                type="button"
                                className={`btn button-filter${
                                  period !== "year" ? "" : " active"
                                }`}
                                onClick={() => {
                                  if (period !== "year") {
                                    setPeriod("year");
                                  }
                                }}
                              >
                                Année en cours
                              </button>
                              <button
                                type="button"
                                className={`btn button-filter${
                                  period !== "quarter" ? "" : " active"
                                }`}
                                onClick={() => {
                                  if (period !== "quarter") {
                                    setPeriod("quarter");
                                  }
                                }}
                              >
                                Trimestre en cours
                              </button>
                              <button
                                type="button"
                                className={`btn button-filter${
                                  period !== "month" ? "" : " active"
                                }`}
                                onClick={() => {
                                  if (period !== "month") {
                                    setPeriod("month");
                                  }
                                }}
                              >
                                Mois en cours
                              </button>
                              <button
                                type="button"
                                className={`btn button-filter${
                                  period !== "interval" ? "" : " active"
                                }`}
                                onClick={() => {
                                  if (period !== "interval") {
                                    setPeriod("interval");
                                  }
                                }}
                              >
                                Intervalle de temps
                              </button>
                            </div>
                            {period && period === "interval" ? (
                              <div className="mt-3 container-filter-datepicker">
                                <div className="d-flex justify-content-between">
                                  <div className="item-filter-datepicker">
                                    <p>Date de début</p>
                                    <DatePicker
                                      className="form-control button-without-style"
                                      selected={startDate}
                                      onChange={(date) => {
                                        setStartDate(date);
                                        if(date > endDate)
                                          setEndDate(date)
                                      }}
                                      dateFormat="dd/MM/yyyy"
                                      locale={fr}
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                    />
                                  </div>
                                  <div className="item-filter-datepicker">
                                    <p>Date de fin</p>
                                    <DatePicker
                                      className="form-control button-without-style"
                                      selected={endDate}
                                      onChange={(date) => {
                                        setEndDate(date);
                                        if(date < startDate)
                                          setStartDate(date)
                                      }}
                                      dateFormat="dd/MM/yyyy"
                                      locale={fr}
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                    />
                                  </div>
                                </div>
                                <hr/>
                              </div>
                            ) : null}
                          </div>
                          <Row>
                            <Col md="4">
                              <Select
                                className="w-100 mt-3"
                                isSearchable={true}
                                noOptionsMessage={() => "Pas d'agence disponible"}
                                placeholder="Choisir une agence..."
                                options={agencies}
                                defaultValue={agency_id}
                                onChange={(agency) => {
                                  setAgency_id({
                                    value: agency.value,
                                    label: agency.label,
                                  });
                                  setUser_id(null);
                                }}
                              />
                            </Col>
                            <Col md="4">
                              <Select
                                className="w-100 mt-3"
                                isSearchable={true}
                                noOptionsMessage={() =>
                                  "Pas de collaborateur disponible"
                                }
                                placeholder="Choisir un collaborateur..."
                                options={users}
                                defaultValue={user_id}
                                onChange={(user) => {
                                  setUser_id({
                                    value: user.value,
                                    label: user.label,
                                  });
                                }}
                              />
                            </Col>
                            <Col md="4">
                              <Select
                                className="w-100 mt-3"
                                isSearchable={true}
                                noOptionsMessage={() =>
                                  "Pas de famille de produit disponible"
                                }
                                placeholder="Choisir une famille de produit..."
                                options={categories}
                                defaultValue={category_id}
                                onChange={(category) => {
                                  setCategory_id({
                                    value: category.value,
                                    label: category.label,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </Col>
                </Row>
              </div>
              <ul className="nav nav-tabs nav-tabs-custom mb-3">
                <NavItem>
                  <NavLink
                    className={activeTab === TABS.GENERAL ? "active nav-tabs-custom" : "nav-tabs-custom"}
                    onClick={() => setActiveTab(TABS.GENERAL)}
                  >
                    Général
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === TABS.REPARTITION ? "active" : ""}
                    onClick={() => setActiveTab(TABS.REPARTITION)}
                  >
                    Répartition
                  </NavLink>
                </NavItem>
              </ul>
              {
                activeTab === TABS.GENERAL ?
                  <div>
                    <Row>
                      <Col>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="text-muted font-weight-medium text-center">
                                  CA HT Brut
                                </p>
                                <hr/>
                                <div className="d-flex justify-content-around">
                                  <div>
                                    {year}
                                    <h4 className="mb-0">
                                      {`${parseFloat(
                                        props.statistics.ca_brut_ht_current
                                      ).toFixed(2)} €`}
                                    </h4>
                                  </div>
                                  <div>
                                    {year - 1}
                                    <h4 className="mb-0">
                                      {`${parseFloat(
                                        props.statistics.ca_brut_ht_last
                                      ).toFixed(2)} €`}
                                    </h4>
                                  </div>
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="text-muted font-weight-medium text-center">
                                  CA HT Net
                                </p>
                                <hr/>
                                <div className="d-flex justify-content-around">
                                  <div>
                                    {year}
                                    <h4 className="mb-0">
                                      {`${parseFloat(
                                        props.statistics.ca_net_ht_current
                                      ).toFixed(2)} €`}
                                    </h4>
                                  </div>
                                  <div>
                                    {year - 1}
                                    <h4 className="mb-0">
                                      {`${parseFloat(
                                        props.statistics.ca_net_ht_last
                                      ).toFixed(2)} €`}
                                    </h4>
                                  </div>
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="text-muted font-weight-medium text-center">
                                  CA facturé
                                </p>
                                <hr/>
                                <div className="d-flex justify-content-center">
                                  <div>
                                    {year}
                                    <h4 className="mb-0">
                                      {`${parseFloat(
                                        props.statistics.total_invoice_ht
                                      ).toFixed(2)} €`}
                                    </h4>
                                  </div>
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="text-muted font-weight-medium text-center">
                                  Fourniture
                                </p>
                                <hr/>
                                <div className="d-flex justify-content-center">
                                  <div>
                                    {year}
                                    <h4 className="mb-0">
                                      {`${parseFloat(
                                        props.statistics.total_fourniture_fees
                                      ).toFixed(2)} €`}
                                    </h4>
                                  </div>
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="text-muted font-weight-medium text-center">
                                  Marge brut
                                </p>
                                <hr/>
                                <div className='d-flex justify-content-center'>
                                  {year}
                                </div>
                                <div className="d-flex justify-content-around">
                                  <div>
                                    <h4 className="mb-0">
                                      {`${parseFloat(
                                        props.statistics.excedent_brut
                                      ).toFixed(2)} €`}
                                    </h4>
                                  </div>
                                  <div>
                                    <h4 className="mb-0">
                                      {`${parseFloat(
                                        props.statistics.ca_net_ht_current === 0 ? '0.00'
                                          : ((props.statistics.ca_net_ht_current - props.statistics.total_fourniture_fees) / props.statistics.ca_net_ht_current) * 100
                                      ).toFixed(2)} %`}
                                    </h4>
                                  </div>
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="text-muted font-weight-medium text-center">
                                  Montant perçu
                                </p>
                                <hr/>
                                <div className="d-flex justify-content-center">
                                  <div>
                                    {year}
                                    <h4 className="mb-0">
                                      {`${parseFloat(
                                        props.statistics.total_percu_ttc
                                      ).toFixed(2)} €`}
                                    </h4>
                                  </div>
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="text-muted font-weight-medium text-center">
                                  Montant à percevoir
                                </p>
                                <hr/>
                                <div className="d-flex justify-content-center">
                                  <div>
                                    {year}
                                    <h4 className="mb-0">
                                      {`${parseFloat(
                                        props.statistics.total_left_to_percu_ttc
                                      ).toFixed(2)} €`}
                                    </h4>
                                  </div>
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      </Col>

                    </Row>
                    <Card>
                      <CardBody>
                        <div className="ml-3 custom-switch">
                          <input
                            id="diagram_type"
                            name="diagram_type"
                            type="checkbox"
                            className="custom-control-input ml-3"
                            checked={(diagramType === DIAGRAM_TYPE.BAR)}
                            onChange={(event => {
                              if (event.target.checked)
                                setDiagramType(DIAGRAM_TYPE.BAR)
                              else
                                setDiagramType(DIAGRAM_TYPE.LINE)
                            })}
                          />
                          <label
                            htmlFor="diagram_type"
                            className="custom-control-label"
                          >{diagramType === DIAGRAM_TYPE.BAR ? 'Histogramme' : 'Courbe'}</label>
                        </div>
                        <div className="text-center">
                          <ReactApexChart
                            key={diagramType}
                            series={series}
                            options={options}
                            type={diagramType}
                            height={240}
                          />

                        </div>
                      </CardBody>
                    </Card>
                    <Row>
                      <Col md="4">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="text-muted font-weight-medium text-center">
                                  Les projets validés
                                </p>
                                <hr/>
                                <div className="d-flex justify-content-around">
                                  <div>
                                    {year}
                                    <h4 className="mb-0">
                                      {props.statistics.nb_project_finished_current}
                                    </h4>
                                  </div>
                                  <div>
                                    {year - 1}
                                    <h4 className="mb-0">
                                      {props.statistics.nb_project_finished_last}
                                    </h4>
                                  </div>
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="text-muted font-weight-medium text-center">
                                  Les contacts validés
                                </p>
                                <hr/>
                                <div className="d-flex justify-content-around">
                                  <div>
                                    {year}
                                    <h4 className="mb-0">
                                      {props.statistics.client_validate_order_current}
                                    </h4>
                                  </div>
                                  <div>
                                    {year - 1}
                                    <h4 className="mb-0">
                                      {props.statistics.client_validate_order_last}
                                    </h4>
                                  </div>
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="text-muted font-weight-medium text-center">
                                  Panier moyen
                                </p>
                                <hr/>
                                <div className="d-flex justify-content-around">
                                  <div>
                                    {year}
                                    <h4 className="mb-0">
                                      {`${parseFloat(
                                        props.statistics
                                          .average_ca_brut_ht_by_order_current
                                      ).toFixed(2)} €`}
                                    </h4>
                                  </div>
                                  <div>
                                    {year - 1}
                                    <h4 className="mb-0">
                                      {`${parseFloat(
                                        props.statistics
                                          .average_ca_brut_ht_by_order_last
                                      ).toFixed(2)} €`}
                                    </h4>
                                  </div>
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <ReactApexChart
                          options={optionsRadial}
                          series={seriesRadial}
                          type="radialBar"
                          height="400"
                        />
                      </Col>
                      <Col md="6">
                        <ReactApexChart
                          options={optionsRadial2}
                          series={seriesRadial2}
                          type="radialBar"
                          height="400"
                        />
                      </Col>
                    </Row>
                  </div>
                  :

                  <DashboardRepartitionComponent
                    year={year}
                    categories={categories}
                    family_statistics={props.family_statistics}
                    category_statistics={props.category_statistics}
                    product_statistics={props.product_statistics}
                    loading_repartition={props.loading_repartition}
                  />
                /*<div>
                  <StatsRepartitionCard
                    type={FAMILY_REPARTION_CARD}
                    datas={props.family_statistics}
                    loading={props.loading_repartition}
                    title="Répartition du CA HT par familles"
                  />
                  <StatsRepartitionCard
                    type={CATEGORY_REPARTION_CARD}
                    datas={props.category_statistics}
                    loading={props.loading_repartition}
                    title="Répartition du CA HT par catégories"
                  />
                  <StatsRepartitionCard
                    type={PRODUCT_REPARTION_CARD}
                    datas={props.product_statistics}
                    loading={props.loading_repartition}
                    title="Répartition du CA HT par produits"/>
                </div>*/
              }

            </Col>
          </Row>
        ) : null}
      </Container>
    </div>
  );
}

const mapStatetoProps = (state) => {
  const {
    statistics,
    loading,
    error,
    family_statistics,
    category_statistics,
    product_statistics,
    loading_repartition
  } = state.Statistics;
  const {users} = state.Users;
  const {agencies} = state.Agencies;
  return {
    statistics,
    family_statistics,
    category_statistics,
    product_statistics,
    users,
    agencies,
    loading,
    error,
    loading_repartition
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getStatistics,
    getAllRepartitions,
    getCategoryRepartition,
    getProductRepartition,
    getAgencies,
    getUsers,
    statisticApiError,
    agencyApiError,
    userApiError,
  })(Dashboard)
);
