import * as Yup from "yup";
import { PHONE_REGEX } from "../../../helpers/validator";

export const createValidationSchema = Yup.object().shape({
  // Identification du prospect

  civility: Yup.string()
    .ensure()
    .when("client_type", {
      is: "Particulier",
      then: Yup
        .string()
        .required("Veuillez compléter ce champ")
        .test("civility", "La valeur ne correspond pas à ceux de la liste", (val) => {
          return ["MME.", "MLLE.", "M.", "M. OU MME."].includes(val);
        }),
    }),

  firstname: Yup.string()
    .ensure()
    .when("client_type", {
      is: "Particulier",
      then: Yup.string().required("Veuillez compléter ce champ"),
    }),

  lastname: Yup.string()
    .ensure()
    .when("client_type", {
      is: "Particulier",
      then: Yup.string().required("Veuillez compléter ce champ"),
    }),

  company_name: Yup.string()
    .ensure()
    .when("client_type", {
      is: "Professionnel",
      then: Yup.string().required("Veuillez compléter ce champ"),
    }),

  siret: Yup.string().notRequired(),

  email: Yup.string().notRequired(),

  email2: Yup.string().notRequired(),

  address: Yup.string().required("Veuillez compléter ce champ"),

  zip_code: Yup.string()
    .required("Veuillez compléter ce champ"),

  city: Yup.string().required("Veuillez compléter ce champ"),

  mobile_phone: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .trim()
    .matches(PHONE_REGEX, "Veuillez saisir un numéro de téléphone valide")
    .notRequired(),

  mobile_phone2: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .trim()
    .matches(PHONE_REGEX, "Veuillez saisir un numéro de téléphone valide")
    .notRequired(),

  fix_phone: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .trim()
    .matches(PHONE_REGEX, "Veuillez saisir un numéro de fixe valide")
    .notRequired(),

  address_construction_site: Yup.string().notRequired(),

  city_construction_site: Yup.string().notRequired().nullable(),

  zip_code_construction_site: Yup.string()
    .notRequired(),

  exposed_city: Yup.string().notRequired(),
  is_owner: Yup.boolean().notRequired(),
  // Détail du logement

  main_resideence: Yup.boolean().notRequired(),
  owner_at: Yup.date().notRequired(),
  housing_seniority: Yup.string().notRequired(),
  surface_habitation: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .notRequired(),
  floors_number: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .notRequired(),
  is_isolate_floors: Yup.boolean().notRequired(),
  attic_type: Yup.string().notRequired(),
  attic_isulated_at: Yup.date().notRequired(),
  attic_isulation_type: Yup.string().notRequired(),
  is_attic_isulation: Yup.boolean().notRequired(),
  window_type: Yup.string().notRequired(),
  windows_number: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .notRequired(),
  ecs_production_type: Yup.string().notRequired(),
  ecs_production_type_annual_cost: Yup.number().notRequired(),
  heading_type: Yup.string().notRequired(),
  heading_type_annual_cost: Yup.number().notRequired(),
  appointment_explication: Yup.string().notRequired(),

  // Définition du projet potentiel

  building_work_type: Yup.string().notRequired(),
  already_benefit_energy_study: Yup.boolean().notRequired(),
  already_benefit_grant: Yup.boolean().notRequired(),

  // Rencontre du prospect

  first_contact_date: Yup.date().notRequired(),
  contact_origin: Yup.string().notRequired(),
  user: Yup.string().notRequired(),

  // Positionnement du rendez-vous

  appointment_take_with: Yup.string().notRequired(),
  function1: Yup.string().notRequired(),
  work_council1: Yup.boolean().notRequired(),
  function2: Yup.string().notRequired(),
  work_council2: Yup.boolean().notRequired(),
  age: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .notRequired(),
  spouse_age: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .notRequired(),
  children_number: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .notRequired(),
  fiscal_income: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .notRequired(),
  decision_makers_present: Yup.boolean().notRequired(),
  ok_one_hours_thirty: Yup.boolean().notRequired(),
  appointment_method: Yup.string().notRequired(),

  // Le contact - suivi du rendez-vous

  follow_up_number_star: Yup.number().notRequired(),
  follow_up_call_date: Yup.date().notRequired(),
  follow_up_refusal_reason: Yup.string().notRequired(),
  follow_up_client_profil: Yup.string().notRequired(),
});
