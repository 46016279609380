import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { setInterventionTypeIsShow } from '../../../../helpers/planification_helper';
import { colorOfMapItemInterventionType, labelOfMapItemInterventionType, MapItemFilters, MapItemInterventionType } from '../../../../models/map_item';

interface InterventionTypeFilterProps {
  filters: MapItemFilters,
  setFilters: Dispatch<SetStateAction<MapItemFilters>>
}

const InterventionTypeFilter = ({
  filters,
  setFilters
}: InterventionTypeFilterProps) => {

  const toggleInterventionType = (type: MapItemInterventionType) => {
    const intervention_types = filters.intervention_types!.includes(type)
      ? filters.intervention_types!.filter(t => t !== type)
      : [...filters.intervention_types!, type];

    setFilters({ ...filters, intervention_types });
  }

  return (
    <>
      <Col id="reasons-legend" lg="2">
        <p
          className="btn p-0 mb-0"
          onClick={() => setInterventionTypeIsShow(false)}
        >
          <i className="fas fa-times mr-1"></i>
          Masquer la légende
        </p>
        <div id="external-events" className="mt-3">
          <p className="mb-4 font-weight-bold">
            Type d'intervention
          </p>
          {Object.values(MapItemInterventionType).map((key) => (
            <Link
              to="#"
              className="d-flex justify-content-start align-items-center mb-3"
              onClick={() => toggleInterventionType(key)}
              key={key}
            >
              <span
                className={`calendar-caption-circle ${filters.intervention_types!.includes(key)
                    ? `bg-${colorOfMapItemInterventionType(key)}`
                    : ""
                  } border-${colorOfMapItemInterventionType(key)} mr-2`}
              ></span>{" "}
              {labelOfMapItemInterventionType(key)}
            </Link>
          ))}
        </div>
      </Col>
      <Col
        id="reasons-legend-show"
        className="info-bar btn d-none"
        onClick={() => setInterventionTypeIsShow(true)}
      >
        <i className="bx bxs-left-arrow-alt"></i>
      </Col>
    </>
  )
};

export default InterventionTypeFilter;