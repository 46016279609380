import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

const ExportGeneralComponent = (props) => {
  return (
    <Row>
        <Col>
            {
                [
                    {name: 'id', value: props.values.id, label: 'Référence'},
                    {name: 'project_state.name', value: props.values.project_state.name, label: 'État du projet'},
                    {name: 'client.name', value: props.values.client.name, label: 'Client'},
                    {name: 'client.mobile', value: props.values.client.mobile, label: 'Numéro de mobile du client'},
                    {name: 'client.fixe', value: props.values.client.fixe, label: 'Numéro de fixe du client'},
                    {name: 'client.email', value: props.values.client.email, label: 'Émail du client'},
                    {name: 'client.address', value: props.values.client.address, label: 'Adresse du client'},
                    {name: 'client.address_construction_site', value: props.values.client.address_construction_site, label: 'Adresse de chantier du client'},
                    {name: 'users.name', value: props.values.users.name, label: 'Commercial'},
                    {name: 'users.agency_name', value: props.values.users.agency_name, label: 'Agence'},
                    {name: 'sell_date', value: props.values.sell_date, label: 'Date de vente'},
                    {name: 'installation_at', value: props.values.installation_at, label: 'Date d\'installation'},
                    {name: 'products', value: props.values.products, label: 'Produits'}
                ].map(
                    (value) => (
                        <FormGroup className="d-flex mb-1">
                            <Input
                                type="checkbox"
                                name={value.name}
                                id={value.name}
                                className="form-control form-checkbox mr-3"
                                checked={value.value != null}
                                disabled={props.isFormDisabled}
                                onChange={(event) => {
                                    props.setFieldValue(
                                        value.name, value.value == null ? value.label : null
                                    )
                                }}
                            />
                            <Label htmlFor={value.name} className="col-form-label">
                                {value.label}
                            </Label>
                        </FormGroup>
                    )
                )
            }
        </Col>
    </Row>
  );
};

export default ExportGeneralComponent;