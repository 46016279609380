import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_FINANCERS,
  GET_FINANCER,
  CREATE_FINANCER,
  UPDATE_FINANCER,
  DELETE_FINANCER,
} from "./actionTypes";
import {
  getFinancersSuccess,
  getFinancerSuccess,
  createFinancerSuccess,
  updateFinancerSuccess,
  deleteFinancerSuccess,
  financerApiError,
} from "./actions";

import { financers } from "../../api";

function* getFinancers({ payload: { query } }) {
  try {
    const response = yield call(financers.getAll, query);
    yield put(getFinancersSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          financerApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des financements"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(financerApiError(error.response.data.message));
      }
    } else {
      yield put(financerApiError(error.message));
    }
  }
}

function* getFinancer({ payload: { id } }) {
  try {
    const response = yield call(financers.getOne, id);
    yield put(getFinancerSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          financerApiError("Vous n'êtes pas autorisé à accéder à ce financement")
        );
      } else if (error.response.status === 404) {
        yield put(financerApiError("Le financement n'a pas été trouvé"));
      } else if (error.response.data !== undefined) {
        yield put(financerApiError(error.response.data.message));
      }
    } else {
      yield put(financerApiError(error.message));
    }
  }
}

function* createFinancer({ payload: { financer, history } }) {
  try {
    const response = yield call(financers.create, financer);
    yield put(createFinancerSuccess(response));
    history.push("/financers?success_add=1");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          financerApiError("Vous n'êtes pas autorisé à créer un financement")
        );
      } else if (
        error.response.status === 400 &&
        error.response.data.errors.length > 0
      ) {
        if (
          error.response.data.errors[0].detail.name &&
          error.response.data.errors[0].detail.name[0] === "can't be blank"
        ) {
          yield put(financerApiError("Le nom doit être présent"));
        }
      } else if (error.response.data !== undefined) {
        yield put(financerApiError(error.response.data.message));
      }
    } else {
      yield put(financerApiError(error.message));
    }
  }
}

function* updateFinancer({ payload: { id, financer, history } }) {
  try {
    const response = yield call(financers.update, id, financer);
    yield put(updateFinancerSuccess(response));
    history.push("/financers?success_update=1");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          financerApiError("Vous n'êtes pas autorisé à modifier ce financement")
        );
      } else if (error.response.status === 404) {
        yield put(financerApiError("Le financement n'a pas été trouvé"));
      } else if (
        error.response.status === 400 &&
        error.response.data.errors.length > 0
      ) {
        if (
          error.response.data.errors[0].detail.name &&
          error.response.data.errors[0].detail.name[0] === "can't be blank"
        ) {
          yield put(financerApiError("Le nom doit être présent"));
        }
      } else if (error.response.data !== undefined) {
        yield put(financerApiError(error.response.data.message));
      }
    } else {
      yield put(financerApiError(error.message));
    }
  }
}

function* deleteFinancer({ payload: { id, history } }) {
  try {
    const response = yield call(financers.delete, id);
    yield put(deleteFinancerSuccess(response));
    history.push("/financers?success_delete=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        financerApiError("Vous n'êtes pas autorisé à supprimer ce financement")
      );
    } else if (error.response.data !== undefined) {
      yield put(financerApiError(error.response.data.message));
    } else {
      yield put(financerApiError(error.message));
    }
  }
}

export function* watchGetFinancers() {
  yield takeEvery(GET_FINANCERS, getFinancers);
}

export function* watchGetFinancer() {
  yield takeEvery(GET_FINANCER, getFinancer);
}

export function* watchCreateFinancer() {
  yield takeEvery(CREATE_FINANCER, createFinancer);
}

export function* watchUpdateFinancer() {
  yield takeEvery(UPDATE_FINANCER, updateFinancer);
}

export function* watchDeleteFinancer() {
  yield takeEvery(DELETE_FINANCER, deleteFinancer);
}

function* financersSaga() {
  yield all([
    fork(watchGetFinancers),
    fork(watchGetFinancer),
    fork(watchCreateFinancer),
    fork(watchUpdateFinancer),
    fork(watchDeleteFinancer),
  ]);
}

export default financersSaga;
