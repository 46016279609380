import GreenAudit from "../assets/images/GoogleMap/green_audit.png";
import GreenDep from "../assets/images/GoogleMap/green_dep.png";
import GreenEntretien from "../assets/images/GoogleMap/green_entretien.png";
import GreenInstallation from "../assets/images/GoogleMap/green_installation.png";
import GreenPoint from "../assets/images/GoogleMap/green_point.png";
import GreenVT from "../assets/images/GoogleMap/green_vt.png";
import RedAudit from "../assets/images/GoogleMap/red_audit.png";
import RedDep from "../assets/images/GoogleMap/red_dep.png";
import RedEntretien from "../assets/images/GoogleMap/red_entretien.png";
import RedInstallation from "../assets/images/GoogleMap/red_installation.png";
import RedPoint from "../assets/images/GoogleMap/red_point.png";
import RedVT from "../assets/images/GoogleMap/red_vt.png";
import Project from "../models/project";

export const projectWhitelist = [
    "acceptance",
    "deposit_receive",
    "technical_visit",
    "installation",
    "invoiced"
];

export const reasons = [
    "Installation",
    "Entretien",
    "Dépannage",
    "Visite technique",
    "Audit",
];
  
export const reasonColors = {
    Installation: "brown",
    Entretien: "pink",
    Dépannage: "green",
    "Visite technique": "blue",
    Audit: "orange",
};

export interface ReasonMarkerIcon {
    noVt: MarkerIcon,
    withVt: MarkerIcon
}

// See https://developers.google.com/maps/documentation/javascript/reference/marker#Icon.scaledSize
// Vec2 is similar as Point or Size in google docs
export interface MarkerIcon {
    url: string,
    size?: Vec2 | null,
    scaledSize?: Vec2 | null,
    origin?: Vec2 | null,
    anchor?: Vec2 | null
}

export interface Vec2 {
    x: number,
    y: number,
}

export const DefaultRedMarkerIcon = {
    url: RedPoint,
    size: { x: 22, y: 32 },
    origin: { x: 0, y: 0 },
    anchor: { x: 11, y: 32 }
} as MarkerIcon

export const DefaultGreenMarkerIcon = {
    url: GreenPoint,
    size: { x: 22, y: 32 },
    origin: { x: 0, y: 0 },
    anchor: { x: 11, y: 32 }
} as MarkerIcon

export const interventionTypeIcons = {
    "installation": {
        noVt: {
            url: RedInstallation,
            size: { x: 32, y: 32 },
            scaledSize: { x: 32, y: 32},
            origin: { x: 0, y: 0 },
            anchor: { x: 32, y: 32 }
        } as MarkerIcon,
        withVt: {
            url: GreenInstallation,
            size: { x: 32, y: 32 },
            scaledSize: { x: 32, y: 32},
            origin: { x: 0, y: 0 },
            anchor: { x: 32, y: 32 }
        } as MarkerIcon
    } as ReasonMarkerIcon,
    "maintenance": {
        noVt: {
            url: RedEntretien,
            size: { x: 32, y: 32 },
            scaledSize: { x: 32, y: 32},
            origin: { x: 0, y: 0 },
            anchor: { x: 32, y: 32 }
        } as MarkerIcon,
        withVt: {
            url: GreenEntretien,
            size: { x: 32, y: 32 },
            scaledSize: { x: 32, y: 32},
            origin: { x: 0, y: 0 },
            anchor: { x: 32, y: 32 }
        } as MarkerIcon
    } as ReasonMarkerIcon,
    "troubleshooting": {
        noVt: {
            url: RedDep,
            size: { x: 32, y: 32 },
            scaledSize: { x: 32, y: 32},
            origin: { x: 0, y: 0 },
            anchor: { x: 32, y: 32 }
        } as MarkerIcon,
        withVt: {
            url: GreenDep,
            size: { x: 32, y: 32 },
            scaledSize: { x: 32, y: 32},
            origin: { x: 0, y: 0 },
            anchor: { x: 32, y: 32 }
        } as MarkerIcon
    } as ReasonMarkerIcon,
    "technical_visit": {
        noVt: {
            url: RedVT,
            size: { x: 32, y: 32 },
            scaledSize: { x: 32, y: 32},
            origin: { x: 0, y: 0 },
            anchor: { x: 32, y: 32 }
        } as MarkerIcon,
        withVt: {
            url: GreenVT,
            size: { x: 32, y: 32 },
            scaledSize: { x: 32, y: 32},
            origin: { x: 0, y: 0 },
            anchor: { x: 32, y: 32 }
        } as MarkerIcon
    } as ReasonMarkerIcon,
    "audit": {
        noVt: {
            url: RedAudit,
            size: { x: 32, y: 32 },
            scaledSize: { x: 32, y: 32},
            origin: { x: 0, y: 0 },
            anchor: { x: 32, y: 32 }
        } as MarkerIcon,
        withVt: {
            url: GreenAudit,
            size: { x: 32, y: 32 },
            scaledSize: { x: 32, y: 32},
            origin: { x: 0, y: 0 },
            anchor: { x: 32, y: 32 }
        } as MarkerIcon
    } as ReasonMarkerIcon,
};

export function changeReasons (
    reason: string,
    reasonsState: string[],
    setReasons: React.Dispatch<React.SetStateAction<string[]>>,
    reasonsChange: boolean,
    setReasonsChange: React.Dispatch<React.SetStateAction<boolean>>,
) {
    if (reasonsState.includes(reason)) {
        reasonsState.splice(reasonsState.indexOf(reason), 1);
    } else {
        reasonsState.push(reason);
    }
    setReasonsChange(!reasonsChange);
    setReasons(reasonsState);
};

// Use vannilla JS to avoid over rendering
export function setInterventionTypeIsShow (value: boolean) {
    let el = document.getElementById('calendar-container') || document.getElementById('map-container');
    if (value) {
        el!.classList.replace("col-lg-12", "col-lg-10");
        document.getElementById("reasons-legend")!.classList.remove("d-none");
        document.getElementById("reasons-legend-show")!.classList.add("d-none");
    } else {
        el!.classList.replace("col-lg-10", "col-lg-12");
        document.getElementById("reasons-legend")!.classList.add("d-none");
        document.getElementById("reasons-legend-show")!.classList.remove("d-none");
    }
};

export function getLastValidAgendaItem(project: Project) {
    return project.agenda_items?.filter(
        (item) =>
        item.child_agenda_item === null &&
        item.deleted_at === null
    ).slice(-1)[0] || null;
}

export function getReasonFromProject(project: Project) {
    return getLastValidAgendaItem(project)?.reason || null;
}
