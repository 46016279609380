import {
  GET_STATISTICS,
  GET_STATISTICS_SUCCESS,
  STATISTICS_API_ERROR,
  GET_FAMILY_REPARTITION,
  GET_FAMILY_REPARTITION_SUCCESS,
  GET_CATEGORY_REPARTITION,
  GET_CATEGORY_REPARTITION_SUCCESS,
  GET_PRODUCT_REPARTITION,
  GET_PRODUCT_REPARTITION_SUCCESS,
  GET_ALL_REPARTITIONS,
  GET_ALL_REPARTITIONS_SUCCESS
} from "./actionTypes";

const initialState = {
  statistics: false,
  error: false,
  loading: false,
};

const statistics = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATISTICS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_STATISTICS_SUCCESS:
      state = {
        ...state,
        statistics: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case GET_FAMILY_REPARTITION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FAMILY_REPARTITION_SUCCESS:
      state = {
        ...state,
        family_statistics: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case GET_CATEGORY_REPARTITION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CATEGORY_REPARTITION_SUCCESS:
      state = {
        ...state,
        category_statistics: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case GET_PRODUCT_REPARTITION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PRODUCT_REPARTITION_SUCCESS:
      state = {
        ...state,
        product_statistics: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case GET_ALL_REPARTITIONS:
      state = {
        ...state,
        loading_repartition: true,
      };
      break;
    case GET_ALL_REPARTITIONS_SUCCESS:
      state = {
        ...state,
        family_statistics: action.payload.family_repartitions?.data,
        category_statistics: action.payload.category_repartitions?.data,
        product_statistics: action.payload.product_repartitions?.data,
        loading_repartition: false,
        error: false,
      };
      break;
    case STATISTICS_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default statistics;
