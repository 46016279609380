import * as Yup from "yup";

export const quoteValidationSchema = Yup.object().shape({

    customerType: Yup.string().required("Veuillez choisir un type de client"),

    customer: Yup.string().required("Veuillez compléter ce champ"),

    /*eco_tax: Yup.number()
        .transform((cv, ov) => {
          return ov === null ? undefined : cv;
        })
        .typeError("Format invalide"),*/

    free_fields: Yup.array().min(1, "Il doit y avoir au moins un article"),

    cce_prime: Yup.number()
        .typeError("Format invalide")
        .notRequired()
        .nullable(),

});
