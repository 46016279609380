import { useState } from 'react';
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { invoices } from "../../../api";
import { isDepositInvoice, removeCeePrime } from "../../../helpers/helper";
import { deleteInvoice, validateInvoice } from "../../../store/invoices/actions";
import ValidationModal from "../../Quotes/Modals/ValidationModal";
import { displayTypes } from "../invoices-list";
import RefundModal from "../Modals/RefundModal";
import InvoicesGeneralTable from "./InvoicesGeneralTable";
import InvoicesTraceTable from "./InvoicesTraceTable";

const InvoicesTableComponent = ({
  invoicesList,
  fastInvoiceEditModalRef,
  filters,
  setFilters,
  last_invoice_validate,
  pagination,
  history,
  deleteInvoice,
  validateInvoice,
  setAllInvoicesSelected,
  setInvoicesIdList,
  invoicesIdList,
  setShowButton,
  displayType,
  projectConvert
}) => {

  // if allInvoicesSelected if true then call API with invoice_ids = all to call action
  // for all invoice in BD with current filters
  const [modalRefund, setModalRefund] = useState(false);
  const [invoiceToRefund, setInvoiceToRefund] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [invoiceIdToDelete, setInvoiceIdToDelete] = useState(-1);
  const [modalValidate, setModalValidate] = useState(false);
  const [modalValidateMessage, setModalValidateMessage] = useState("");
  const [invoiceIdToValidate, setInvoiceIdToValidate] = useState(-1);
  const [customInvoiceDate, setCustomInvoiceDate] = useState(false);

  const toggleShowButton = (array) => {
    if (array.length > 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    setInvoicesIdList(array);
  };

  function toggleModalRefund(invoice) {
    setModalRefund(!modalRefund);
    setInvoiceToRefund(invoice);
  }

  function toggleModalDelete(invoice_id) {
    setModalDelete(!modalDelete);
    setInvoiceIdToDelete(invoice_id);
  }

  /**
   * Fonction qui permet de savoir si la facture à valider est un doublon ou non
   * @param {object} invoice La facture à valider
   * @param {object} invoices Tableau contenant toutes les factures du client
   * @returns {object}
   */
  const invoiceWithSamePrice = (invoice, invoices) => {
    let isSame = false;
    let sameInvoice = null;
    invoices.forEach((i) => {
      if (invoice.id !== i.id && !i.is_canceled && !i.is_draft) {
        isSame = true;
        sameInvoice = i;
      }
    });
    return { isSame: isSame, sameInvoice: sameInvoice };
  };

  function toggleModalValidate(invoice_id, invoice) {
    setInvoiceIdToValidate(() => invoice_id);
    //console.log(invoice_id);
    setModalValidate(!modalValidate);

    let client = invoice?.order?.client;

    invoice &&
      invoices
        .getAll({
          client_id: client?.id,
          ht_min: invoice?.total_ht,
          ht_max: invoice?.total_ht,
        })
        .then((promisesResults) => {


          let invoices = promisesResults.data.objects;

          let { isSame, sameInvoice } = invoiceWithSamePrice(invoice, invoices);

          if (invoices.length > 0 && isSame) {
            setModalValidateMessage(
              `La facture n°${sameInvoice.number} du même montant existe déjà pour ce client, voulez-vous valider quand même ?`
            );
          } else if (
            last_invoice_validate &&
            invoice &&
            invoice.invoice_date &&
            new Date(new Date(invoice.invoice_date).toDateString()) <
            new Date(new Date(last_invoice_validate).toDateString())
          ) {
            setModalValidateMessage(
              "Des factures ont été établies après cette date. Voulez-vous valider la facture à la date d'aujourd'hui ?"
            );
            setCustomInvoiceDate(new Date());
          } else if (
            invoice &&
            invoice.invoice_date &&
            new Date(new Date(invoice.invoice_date).toDateString()) >
            new Date(new Date().toDateString())
          ) {
            setModalValidateMessage("Cette facture a une date dans le futur. Voulez-vous valider la facture à la date d'aujourd'hui ?");
            setCustomInvoiceDate(new Date());
          } else {
            setModalValidateMessage("Voulez vous valider la facture ?");
          }

        });
  }

  function isDepositInvoiceWithInvoice(invoice) {
    let depositHaveInvoice = false;
    invoicesList.forEach((inv) => {
      if (
        inv.order.id === invoice.order.id &&
        !isDepositInvoice(inv) &&
        !inv.is_canceled &&
        !inv.is_refund
      ) {
        depositHaveInvoice = true;
      }
    });
    return isDepositInvoice(invoice) && depositHaveInvoice;
  }

  const selectRow = {
    selected: invoicesIdList,
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      let temp = invoicesIdList;
      if (isSelect) {
        temp.push(row.id);
      } else {
        let index = temp.indexOf(row.id);
        if (index > -1) {
          temp.splice(index, 1);
        }
      }
      // If all checkbox set all invoices selected to true
      setAllInvoicesSelected(temp.length === invoicesList.length);
      toggleShowButton(temp);
    },
    onSelectAll: (isSelect, rows, e) => {
      let temp = invoicesIdList;
      if (isSelect) {
        rows.forEach((row, index) => {
          temp.push(row.id);
        });
      } else {
        rows.forEach((row, i) => {
          let index = temp.indexOf(row.id);
          if (index > -1) {
            temp.splice(index, 1);
          }
        });
      }
      setAllInvoicesSelected(isSelect);
      toggleShowButton(temp);
    },
    bgColor: "rgba(156,195,28,0.2)",
  };

  const tablePagination = paginationFactory({
    page: pagination.current_page,
    totalSize: pagination.total_count,
    sizePerPage: pagination.current_page_size,
    sizePerPageList: [
      { text: "Afficher 10 éléments", value: 10 },
      { text: "Afficher 25 éléments", value: 25 },
      { text: "Afficher 50 éléments", value: 50 },
      { text: "Afficher 75 éléments", value: 75 },
      { text: "Afficher 100 éléments", value: 100 },
      { text: "Afficher 1000 éléments", value: 1000 },
    ],
  })

  return (
    <div>
      {/*
        Needed to create two components that are practically the same du to
        columns that were not correctly refreshing.
       */}
      {displayType === displayTypes.GENERAL ?
        <InvoicesGeneralTable
          history={history}
          projectConvert={projectConvert}
          setFilters={setFilters}
          filters={filters}
          invoicesList={invoicesList}
          tablePagination={tablePagination}
          selectRow={selectRow}
          isDepositInvoiceWithInvoice={isDepositInvoiceWithInvoice}
          toggleModalValidate={toggleModalValidate}
          toggleModalDelete={toggleModalDelete}
          toggleModalRefund={toggleModalRefund}
          isDepositInvoice={isDepositInvoice}
          fastInvoiceEditModalRef={fastInvoiceEditModalRef}
          removeCeePrime={removeCeePrime}
        /> :
        <InvoicesTraceTable
          history={history}
          projectConvert={projectConvert}
          setFilters={setFilters}
          filters={filters}
          invoicesList={invoicesList}
          tablePagination={tablePagination}
          selectRow={selectRow}
          isDepositInvoiceWithInvoice={isDepositInvoiceWithInvoice}
          toggleModalValidate={toggleModalValidate}
          toggleModalDelete={toggleModalDelete}
          toggleModalRefund={toggleModalRefund}
          isDepositInvoice={isDepositInvoice}
          fastInvoiceEditModalRef={fastInvoiceEditModalRef}
          removeCeePrime={removeCeePrime}
        />
      }

      <RefundModal
        invoiceToRefund={invoiceToRefund}
        isOpen={modalRefund}
        toggle={toggleModalRefund}
      />

      {/* Delete validation modal */}
      <ValidationModal
        isOpen={modalDelete}
        toggler={() => toggleModalDelete()}
        title="Factures - suppression"
        message={<div><h2>Attention</h2><p>Voulez vous supprimer la facture definitivement ?</p></div>}
        btnValidText="Supprimer"
        btnBackText="Annuler"
        handleSubmit={() => {
          toggleModalDelete();
          deleteInvoice(invoiceIdToDelete, history);
        }}
      />

      {/* Validate invoice modal */}
      <ValidationModal
        isOpen={modalValidate}
        toggler={() => toggleModalValidate()}
        title="Factures - validation"
        message={<div><h2>Attention</h2><p>{modalValidateMessage}</p></div>}
        btnValidText="Confirmer"
        btnBackText="Annuler"
        handleSubmit={() => {
          toggleModalValidate();
          validateInvoice(
            invoiceIdToValidate,
            history,
            customInvoiceDate
          );
        }}
      />
    </div>
  );
}

export default withRouter(connect(null, {
  validateInvoice,
  deleteInvoice
})(InvoicesTableComponent));
