import { History } from "history";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Collapse, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { parseQuery, setQuery } from "../../helpers/helper";
import { RequestsFilters } from "../../models/request";
import RequestsFilter from './Components/Filters/requests-filter';
import RequestsTab from './Components/Filters/requests-tab';
import RequestsTable from './Components/Tables/requests-table';
import FlashMessage, { addFlashMessage, ImportantEnum } from '../../components/Common/FlashMessage';
import SearchFilterWithButton from "../../components/Common/SearchFilterWithButton";
import RequestEditModal from "./Components/Modals/editAction";
import { V2 } from "../../api";

const RequestsList = ({
  history
}: {
  history: History
}) => {
  const [filtersCollapseIsOpen, setFiltersCollapseIsOpen] = useState(false);

  // Filters

  const query = parseQuery();

  const [filters, setFilters] = useState<RequestsFilters>({
    sort_by: query.sort_by as any || '',
    sort_dir: query.sort_dir as any || '',
    page: (query.page || 1) as number,
    page_size: (query.page_size || 50) as number,
    search: (query.search || null) as string | null,
    status: ((query.status as string[])?.map(i => parseInt(i)) || null) as number[] | null,
    priorities: ((query.priorities as string[])?.map(i => parseInt(i)) || null) as number[] | null,
    collaborator_query: (query.collaborator_query || null) as string | null,
    interlocutor_query: (query.interlocutor_query || null) as string | null,
    types: ((query.types as string[])?.map(i => parseInt(i)) || null) as number[] | null,
    natures: ((query.natures as string[])?.map(i => parseInt(i)) || null) as number[] | null,
    date_begin: (query.date_begin || null) as Date | null,
    date_end: (query.date_end || null) as Date | null,
    is_processed: JSON.parse(query.is_processed as any || 'false'),
  } as RequestsFilters);

  const [editActionModal, setEditActionModal] = useState({
    request_id: null, action: "", value: null,
  } as { request_id: number | null, action: string, value: string | null });

  useEffect(() => {
    setQuery(filters, history); // Update query in path
  }, [filters, history]);

  function resetFilters() {
    setFilters({
      sort_by: '',
      sort_dir: '',
      page: 1,
      page_size: 50,
      search: null,
      status: [],
      priorities: [],
      collaborator_query: '',
      interlocutor_query: '',
      types: [],
      natures: [],
      date_begin: null,
      date_end: null,
      is_processed: false,
    } as RequestsFilters);
  }

  function editAction(values: any) {
    if (editActionModal.request_id) {
      let promise: Promise<any> | null = null;
      switch (editActionModal.action) {
        case "status":
          promise = V2.requests.patch(editActionModal.request_id, { status_id: values.value }, {});
          break;
        case "priority":
          promise = V2.requests.patch(editActionModal.request_id, { priority_id: values.value }, {});
          break;
        case "assign":
          promise = V2.requests.patch(editActionModal.request_id, { collaborator_id: values.value }, {});
          break;
        case "comment":
          promise = V2.requests.patch(editActionModal.request_id, { comment: values.value }, {});
          break;
      }
      if (promise) {
        promise.then(() => {
          setEditActionModal({ request_id: null, action: "", value: null });
          addFlashMessage(ImportantEnum.SUCCESS, "La demande à correctement été modifiée");
          setFilters({ ...filters });
        });
        return promise;
      } else {
        return Promise.reject("Action not supported");
      }
    } else {
      return Promise.reject("No request id");
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="notifications-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Historique"
            breadcrumbTitle="Suivi des demandes"
            breadcrumbItem="Historique"
          />
          <FlashMessage />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-0">
                    <Row className="pr-2 pl-2 d-flex">
                      <Col>Toutes les demandes</Col>
                    </Row>
                  </CardTitle>
                  <Row className="mt-3">
                    <Col lg="12" className="d-flex justify-content-start">
                      <span
                        className="d-flex text-link mb-3"
                        onClick={() => {
                          setFiltersCollapseIsOpen(!filtersCollapseIsOpen);
                        }}
                      >
                        <i className="fas fa-filter mr-1" />Filtrer
                      </span>
                      <span
                        className="d-flex text-link text-color-unset mb-3"
                        onClick={resetFilters}
                      >
                        <i className="fas fa-times mr-1" /> Vider les filtres
                      </span>
                    </Col>
                    <Col xs="12">
                      <Collapse isOpen={filtersCollapseIsOpen}>
                        <RequestsFilter
                          filters={filters}
                          setFilters={setFilters}
                        />
                      </Collapse>
                    </Col>
                    <Col xs={"12"}>
                      <SearchFilterWithButton
                        onSearch={(q) => { setFilters({ ...filters, search: q } as RequestsFilters) }}
                        placeholder={"Rechercher une demande"}
                        defaultValue={""}
                        containerClasses={"mt-2"}
                        inputClasses={"w-4xl"}
                      />
                    </Col>
                    <Col xs={"12"} className="mt-3">
                      <RequestsTab
                        filters={filters}
                        setFilters={setFilters}
                      />
                    </Col>
                  </Row>
                  <RequestsTable filters={filters} setFilters={setFilters} onEditAction={(editAction) => setEditActionModal(editAction)} history={history} />
                  {editActionModal.request_id && editActionModal.action !== "" ?
                    <RequestEditModal onValidate={async v => { editAction(v) }} onAbort={() => { setEditActionModal({ request_id: null, action: "", value: null }) }} editAction={editActionModal} value={editActionModal.value} />
                    : null
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RequestsList;
