import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {Container, Row, Col, Card, CardBody, CardTitle} from "reactstrap";

// Redux
import {connect} from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {
    createRefundFromOrder,
    updateOrder,
    showRefund,
    getVats,
    getInvoice,
    ordersApiError,
    vatsApiError,
} from "../../store/actions";

import { typeForm } from "../../helpers/quotes_invoices_helper";
import QuoteInvoiceComponent from "../Quotes/Components/QuoteInvoiceComponent";

const RefundAdd = ({getVats, getInvoice, invoice, invoice_order}) => {

    useEffect(() => {
        getVats();
    }, [getVats]);

    useEffect(() => {
        if (window.location.pathname.split("/")[3] !== undefined) {
            getInvoice(parseInt(window.location.pathname.split("/")[3], 10));
        }
    }, [getInvoice]);

    return (
        <div className="page-content">
            <Container fluid className="quotes-container">
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Factures" breadcrumbItem="Ajouter un avoir"/>

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4">
                                    {invoice
                                        ? `Créer un avoir sur la facture N°${invoice.number}`
                                        : "Créer un avoir"}
                                </CardTitle>
                                <QuoteInvoiceComponent
                                    typeForm={typeForm.refund}
                                    invoice_order={invoice_order}
                                    invoice={false}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const {order, invoice_order, file_url, loading, error} = state.Orders;
    const {invoice} = state.Invoices;
    const {vats} = state.Vats;

    return {order, invoice_order, file_url, vats, invoice, loading, error};
};

export default withRouter(
    connect(mapStatetoProps, {
        createRefundFromOrder,
        updateOrder,
        showRefund,
        getVats,
        getInvoice,
        ordersApiError,
        vatsApiError,
    })(RefundAdd)
);
