import {
  GET_ABONNEMENT_STATES,
  GET_ABONNEMENT_STATES_SUCCESS,
  ABONNEMENTS_STATES_API_ERROR,
} from "./actionTypes";

const initialState = {
  abonnementStates: [],
  error: false,
  loading: false,
};

const abonnementStates = (state = initialState, action) => {
  switch (action.type) {
    case GET_ABONNEMENT_STATES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ABONNEMENT_STATES_SUCCESS:
      state = {
        ...state,
        abonnementStates: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case ABONNEMENTS_STATES_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default abonnementStates;
