import React from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Media } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { getLastChildOrder } from "../../../helpers/helper";

const IndicatorCard = (props) => {

  var total_price_ht = 0;
  total_price_ht += getLastChildOrder(props.project)?.total_price_ht || 0;
  // total_price_ht += props.project.maintenance_order?.total_price_ht || 0;

  var total_fourniture_fees_ht = 0;
  for (let i in props.project.fourniture_fees) {
    total_fourniture_fees_ht += props.project.fourniture_fees[i].amount_ht || 0;
  }

  return (
    <Card>
      <CardBody>
        <Media>
          <Media body>
            <p className="text-muted font-weight-medium text-center">
              Indicateurs
            </p>
            <hr />
            <div className="d-flex justify-content-around">
              <div>
                Somme des BL
                <h4 className="mb-0">
                  {`${parseFloat(
                    total_fourniture_fees_ht
                  ).toFixed(2)} €`}
                </h4>
              </div>
              <div>
                Marge
                <h4 className="mb-0">
                  {`${parseFloat(
                    total_price_ht !== 0 ? ((total_price_ht - total_fourniture_fees_ht) * 100) / total_price_ht : 0
                  ).toFixed(2)} %`}
                </h4>
              </div>
            </div>
          </Media>
        </Media>
      </CardBody>
    </Card>
  );
};

const mapStatetoProps = (state) => {
  return { };
};

export default withRouter(
  connect(mapStatetoProps, { })(IndicatorCard)
);
