import { useState } from "react";
import {
  Row,
  Col,
  FormGroup,
  Button,
  NavItem,
  NavLink,
} from "reactstrap";
import { Formik, Form } from "formik";

import "react-datepicker/dist/react-datepicker.css";
import ExportGeneralComponent from "./ExportComponent/ExportGeneralComponent";
import ExportFinancerComponent from "./ExportComponent/ExportFinancerComponent";
import ExportVisitesSuiviComponent from "./ExportComponent/ExportVisitesSuiviComponent";
import ExportEntretienComponent from "./ExportComponent/ExportEntretienComponent";

const initValues = {
  id: null,
  type_of_financement: null,
  financer: null,
  fourniture_fees: null,
  disponibilities: null,
  maintenance_state: null,
  maintenance_order: null,
  maintenance_start_date: null,
  maintenance_formule: null,
  disponibility_vt: null,
  comment_vt: null,
  call_date_vt: null,
  reception_scan_at: null,
  folder_sell_paper_receipt_date: null,
  pac_file_receipt_date: null,
  acceptation_send_at: null,
  decision_at: null,
  sell_date: null,
  installation_at: null,
  client: {
    name: null,
    mobile: null,
    fix: null,
    email: null,
    address: null,
    address_construction_site: null
  },
  project_state: {
    name: null
  },
  users: {
    name: null,
    agency_name: null
  },
}

const ExportForm = (props) => {
  let [activeTab, setActiveTab] = useState('general');

  return (
    <Formik
      initialValues={initValues}
      validationSchema={props.validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        let cols = [];
        // General
        if (values.id != null) {
          cols.push({
            name: values.id,
            fields: [
              {
                content: "id",
                format: "rjust 8 0",
                type: "string"
              }
            ]
          })
        }
        if (values.project_state.name != null) {
          cols.push({
            name: values.project_state.name,
            fields: [
              {
                content: "project_state.name",
                type: "string"
              }
            ]
          })
        }
        if (values.client.name != null) {
          cols.push({
            name: values.client.name,
            fields: [
              {
                content: "client.civility",
                type: "string"
              },
              {
                content: " ",
                type: "value"
              },
              {
                content: "client.lastname",
                type: "string"
              },
              {
                content: " ",
                type: "value"
              },
              {
                content: "client.firstname",
                type: "string"
              }
            ]
          })
        }
        if (values.client.mobile != null) {
          cols.push({
            name: values.client.mobile,
            fields: [
              {
                content: "client.mobile_phone",
                type: "string"
              }
            ]
          })
        }
        if (values.client.fixe != null) {
          cols.push({
            name: values.client.fixe,
            fields: [
              {
                content: "client.fix_phone",
                type: "string"
              }
            ]
          })
        }
        if (values.client.email != null) {
          cols.push({
            name: values.client.email,
            fields: [
              {
                content: "client.email",
                type: "string"
              }
            ]
          })
        }
        if (values.client.address != null) {
          cols.push({
            name: values.client.address,
            fields: [
              {
                content: "client.address",
                type: "string"
              },
              {
                content: ", ",
                type: "value"
              },
              {
                content: "client.zip_code",
                type: "string"
              },
              {
                content: " ",
                type: "value"
              },
              {
                content: "client.city",
                type: "string"
              }
            ]
          })
        }
        if (values.client.address_construction_site != null) {
          cols.push({
            name: values.client.address_construction_site,
            fields: [
              {
                content: "client.address_construction_site",
                type: "string"
              },
              {
                content: " ",
                type: "value"
              },
              {
                content: "client.zip_code_construction_site",
                type: "string"
              },
              {
                content: " ",
                type: "value"
              },
              {
                content: "client.city_construction_site",
                type: "string"
              }
            ]
          })
        }
        if (values.users.name != null) {
          cols.push({
            name: values.users.name,
            fields: [
              {
                fields: [
                  {
                    content: "lastname",
                    type: "string"
                  },
                  {
                    content: " ",
                    type: "value"
                  },
                  {
                    content: "firstname",
                    type: "string"
                  }
                ],
                type: "entities",
                relation: "users"
              }
            ]
          })
        }
        if (values.users.agency_name != null) {
          cols.push({
            name: values.users.agency_name,
            fields: [
              {
                fields: [
                  {
                    fields: [
                      {
                        content: "name",
                        type: "string"
                      }
                    ],
                    relation: "agencies",
                    type: "entities"
                  }
                ],
                type: "entities",
                relation: "users"
              }
            ]
          })
        }
        if (values.sell_date != null) {
          cols.push({
            name: values.sell_date,
            fields: [
              {
                content: "sell_date",
                type: "date",
                format: "%d/%m/%Y",
                locale: ['en', 'fr'].indexOf(navigator.language) > -1 ? navigator.language : 'en',
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
              }
            ]
          })
        }
        if (values.installation_at != null) {
          cols.push({
            name: values.installation_at,
            fields: [
              {
                content: "installation_at",
                type: "date",
                format: "%d/%m/%Y",
                locale: ['en', 'fr'].indexOf(navigator.language) > -1 ? navigator.language : 'en',
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
              }
            ]
          })
        }
        if (values.products != null) {
          cols.push({
            name: 'Produits',
            fields: [
              {
                fields: [
                  {
                    content: "● ",
                    type: "value"
                  },
                  {
                    content: "product.commercial_name",
                    type: "string",
                    on_empty: "ignore"
                  }
                ],
                type: "entities",
                relation: "order.product_items"
              }
            ]
          })
          cols.push({
            name: 'Produits libres',
            fields: [
              {
                fields: [
                  {
                    content: "● ",
                    type: "value"
                  },
                  {
                    content: "free_field.title",
                    type: "string"
                  }
                ],
                type: "entities",
                relation: "order.free_items"
              }
            ]
          })
        }

        // Financer
        if (values.type_of_financement != null) {
          cols.push({
            name: values.type_of_financement,
            fields: [
              {
                content: "type_of_financement",
                type: "string"
              }
            ]
          })
        }
        if (values.financer != null) {
          cols.push({
            name: values.financer,
            fields: [
              {
                content: "financer.name",
                type: "string"
              }
            ]
          })
        }
        if (values.fourniture_fees != null) {
          cols.push({
            name: values.fourniture_fees,
            fields: [
              {
                fields: [
                  {
                    content: "N°",
                    type: "value"
                  },
                  {
                    content: "reference_bl",
                    type: "string"
                  },
                  {
                    content: " ",
                    type: "value"
                  },
                  {
                    content: "label",
                    type: "string"
                  },
                  {
                    content: " ",
                    type: "value"
                  },
                  {
                    content: "amount_ht",
                    type: "float",
                    format: "%.2f €"
                  }
                ],
                type: "entities",
                relation: "fourniture_fees"
              }
            ]
          })
        }

        // Visite et suivi
        if (values.disponibilities != null) {
          cols.push({
            name: values.disponibilities,
            fields: [
              {
                fields: [
                  {
                    content: "begin_at",
                    type: "date",
                    format: "Le %a %d %b %Y de %H:%M",
                    locale: ['en', 'fr'].indexOf(navigator.language) > -1 ? navigator.language : 'en',
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                  },
                  {
                    content: "end_at",
                    type: "date",
                    format: " à %H:%M",
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                  }
                ],
                type: "entities",
                relation: "disponibilities"
              }
            ]
          })
        }
        if (values.disponibility_vt != null) {
          cols.push({
            name: values.disponibility_vt,
            fields: [
              {
                content: "disponibility_vt",
                format: "%d/%m/%Y à %H:%M",
                type: "date",
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
              }
            ]
          })
        }
        if (values.comment_vt != null) {
          cols.push({
            name: values.comment_vt,
            fields: [
              {
                content: "comment_vt",
                type: "string"
              }
            ]
          })
        }
        if (values.call_date_vt != null) {
          cols.push({
            name: values.call_date_vt,
            fields: [
              {
                content: "call_date_vt",
                format: "%d/%m/%Y à %H:%M",
                type: "date",
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
              }
            ]
          })
        }
        if (values.reception_scan_at != null) {
          cols.push({
            name: values.reception_scan_at,
            fields: [
              {
                content: "reception_scan_at",
                format: "%d/%m/%Y à %H:%M",
                type: "date",
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
              }
            ]
          })
        }
        if (values.folder_sell_paper_receipt_date != null) {
          cols.push({
            name: values.folder_sell_paper_receipt_date,
            fields: [
              {
                content: "folder_sell_paper_receipt_date",
                format: "%d/%m/%Y à %H:%M",
                type: "date",
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
              }
            ]
          })
        }
        if (values.pac_file_receipt_date != null) {
          cols.push({
            name: values.pac_file_receipt_date,
            fields: [
              {
                content: "pac_file_receipt_date",
                format: "%d/%m/%Y à %H:%M",
                type: "date",
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
              }
            ]
          })
        }
        if (values.acceptation_send_at != null) {
          cols.push({
            name: values.acceptation_send_at,
            fields: [
              {
                content: "acceptation_send_at",
                format: "%d/%m/%Y à %H:%M",
                type: "date",
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
              }
            ]
          })
        }
        if (values.decision_at != null) {
          cols.push({
            name: values.decision_at,
            fields: [
              {
                content: "decision_at",
                format: "%d/%m/%Y à %H:%M",
                type: "date",
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
              }
            ]
          })
        }

        // Entretien
        if (values.maintenance_state != null) {
          cols.push({
            name: values.maintenance_state,
            fields: [
              {
                fields: [
                  {
                    content: "abonnement_state.label",
                    type: "string",
                  }
                ],
                type: "entities",
                relation: "subscriptions"
              }
            ]
          })
        }
        if (values.maintenance_order != null) {
          cols.push({
            name: values.maintenance_order,
            fields: [
              {
                content: "subscriptions",
                format: "not_empty Oui Non",
                type: "bool"
              }
            ]
          })
        }
        if (values.maintenance_start_date != null) {
          cols.push({
            name: values.maintenance_start_date,
            fields: [
              {
                fields: [
                  {
                    content: "start_at",
                    format: "%d/%m/%Y",
                    type: "date"
                  }
                ],
                type: "entities",
                relation: "subscriptions"
              }
            ]
          })
        }
        if (values.maintenance_formule != null) {
          cols.push({
            name: values.maintenance_formule,
            fields: [
              {
                fields: [
                  {
                    content: "formule.label",
                    type: "string"
                  }
                ],
                type: "entities",
                relation: "subscriptions"
              }
            ]
          })
        }
        props.handleSubmit(cols);
      }}
      render={({ values, errors, touched, setFieldValue }) => (
        <Form>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <FormGroup className="mb-4">
                <ul className="nav nav-tabs nav-tabs-custom mb-3">
                  <NavItem>
                    <NavLink
                      className={activeTab === "general" ? "active" : ""}
                      onClick={() => {
                        setActiveTab("general");
                      }}
                    >
                      Général
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "financier" ? "active" : ""}
                      onClick={() => {
                        setActiveTab("financier");
                      }}
                    >
                      Financier
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "visite" ? "active" : ""}
                      onClick={() => {
                        setActiveTab("visite");
                      }}
                    >
                      Visites et suivi
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "entretien" ? "active" : ""}
                      onClick={() => {
                        setActiveTab("entretien");
                      }}
                    >
                      Entretien
                    </NavLink>
                  </NavItem>
                </ul>
                {activeTab === "general" ? (
                  <ExportGeneralComponent values={values} errors={errors} touched={touched} setFieldValue={setFieldValue} />
                ) : null}
                {activeTab === "financier" ? (
                  <ExportFinancerComponent values={values} errors={errors} touched={touched} setFieldValue={setFieldValue} />
                ) : null}
                {activeTab === "visite" ? (
                  <ExportVisitesSuiviComponent values={values} errors={errors} touched={touched} setFieldValue={setFieldValue} />
                ) : null}
                {activeTab === "entretien" ? (
                  <ExportEntretienComponent values={values} errors={errors} touched={touched} setFieldValue={setFieldValue} />
                ) : null}
              </FormGroup>
            </div>
          </div>
          <Row className="justify-content-end mt-4">
            <Col xs="12" className="text-center">
              <Button type="submit" color="primary">
                Exporter les projets
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default ExportForm;