import {
  GET_QUOTES,
  GET_QUOTES_SUCCESS,
  GET_QUOTE,
  GET_QUOTE_SUCCESS,
  CREATE_QUOTE,
  CREATE_QUOTE_SUCCESS,
  UPDATE_QUOTE,
  UPDATE_QUOTE_SUCCESS,
  DELETE_QUOTE,
  DELETE_QUOTE_SUCCESS,
  GENERATE_QUOTE_ZIP,
  GENERATE_QUOTE_ZIP_SUCCESS,
  SEND_QUOTE_EMAILS,
  SEND_QUOTE_EMAILS_SUCCESS,
  SHOW_QUOTE,
  SHOW_QUOTE_SUCCESS,
  QUOTES_API_ERROR,
  MIGRATE_ORDER_FROM_QUOTE,
  MIGRATE_ORDER_FROM_QUOTE_SUCCESS
} from "./actionTypes";

export const getQuotes = (query) => {
  return {
    type: GET_QUOTES,
    payload: { query },
  };
};

export const getQuotesSuccess = (quote) => {
  return {
    type: GET_QUOTES_SUCCESS,
    payload: quote,
  };
};

export const getQuote = (id) => {
  return {
    type: GET_QUOTE,
    payload: { id },
  };
};

export const getQuoteSuccess = (quote) => {
  return {
    type: GET_QUOTE_SUCCESS,
    payload: quote,
  };
};

export const createQuote = (order, history) => {
  return {
    type: CREATE_QUOTE,
    payload: { order, history },
  };
};

export const createQuoteSuccess = (quote) => {
  return {
    type: CREATE_QUOTE_SUCCESS,
    payload: quote,
  };
};

export const updateQuote = (id, order, history) => {
  return {
    type: UPDATE_QUOTE,
    payload: { id, order, history },
  };
};

export const updateQuoteSuccess = (quote) => {
  return {
    type: UPDATE_QUOTE_SUCCESS,
    payload: quote,
  };
};

export const deleteQuote = (id, history) => {
  return {
    type: DELETE_QUOTE,
    payload: { id, history },
  };
};

export const deleteQuoteSuccess = (quote) => {
  return {
    type: DELETE_QUOTE_SUCCESS,
    payload: quote,
  };
};

export const generateQuoteZip = (list) => {
  return {
    type: GENERATE_QUOTE_ZIP,
    payload: { list },
  };
};

export const generateQuoteZipSuccess = (quote_zip_file) => {
  return {
    type: GENERATE_QUOTE_ZIP_SUCCESS,
    payload: quote_zip_file,
  };
};

export const sendQuoteEmails = (list, query) => {
  return {
    type: SEND_QUOTE_EMAILS,
    payload: { list, query },
  };
};

export const sendQuoteEmailsSuccess = (lists) => {
  return {
    type: SEND_QUOTE_EMAILS_SUCCESS,
    payload: lists,
  };
};

export const showQuote = (order, isUpdate) => {
  return {
    type: SHOW_QUOTE,
    payload: { order, isUpdate },
  };
};

export const showQuoteSuccess = (order) => {
  return {
    type: SHOW_QUOTE_SUCCESS,
    payload: order,
  };
};

export const quotesApiError = (error) => {
  return {
    type: QUOTES_API_ERROR,
    payload: error,
  };
};

export const migrateOrderFromQuote = (values) => {
  return {
    type: MIGRATE_ORDER_FROM_QUOTE,
    payload: { values },
  };
};

export const migrateOrderFromQuoteSuccess = (project) => {
  return {
    type: MIGRATE_ORDER_FROM_QUOTE_SUCCESS,
    payload: project,
  };
};
