import InterventionFolder from "../../../../models/intervention_folder";
import {Col, Row} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";

export default function InterventionDetailClientTab({interventionFolder}:{interventionFolder:InterventionFolder}){
  return (
    <Row className="pr-2 pl-2 d-flex font-size-14 text-theme-color">
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Client : </span>
        <Link to={`customer/detail/${interventionFolder?.client?.id}`} className="font-weight-light !text-theme-color">{interventionFolder?.client?.lastname} {interventionFolder?.client?.firstname}</Link>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Date : </span>
        <span className="font-weight-light">{interventionFolder?.intervention_at ? moment(interventionFolder?.intervention_at).format("DD/MM/YY") : "Non définie"}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Heure : </span>
        <span className="font-weight-light">{interventionFolder?.intervention_at ? moment(interventionFolder?.intervention_at).format("HH:mm") : "Non définie"}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">CP : </span>
        <span className="font-weight-light">{interventionFolder?.zip_code}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Adresse : </span>
        <span className="font-weight-light">{interventionFolder?.address}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Produit : </span>
        <span className="font-weight-light">{interventionFolder?.product_name}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Téléphone : </span>
        <span className="font-weight-light">{interventionFolder?.client_phone}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Interlocuteur : </span>
        <span className="font-weight-light">{interventionFolder?.interlocutor?.lastname} {interventionFolder?.interlocutor?.firstname}</span>
      </Col>
    </Row>
  );
}