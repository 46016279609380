import React from 'react';
import {
  NavItem,
  NavLink,
} from "reactstrap";

import {RequestsFilters} from "../../../../models/request";

export const PROCESSED_FILTER = true; // Status === processed (Traité) | API QUERY : is_processed = true
export const OTHERS_FILTER = false; // Status !== processed (Traité) | API QUERY : is_processed = false

export interface RequestsTabProps {
  setFilters: (filters: RequestsFilters) => void;
  filters: RequestsFilters;
}

const RequestsTab = ({
  setFilters,
  filters,
}: RequestsTabProps) => {
  return (
    <ul className="nav nav-tabs nav-tabs-custom mb-3">
      <NavItem>
        <NavLink
          className={filters.is_processed === OTHERS_FILTER ? "active" : ""}
          onClick={() => {
            setFilters({
              ...filters,
              is_processed: OTHERS_FILTER,
              sort_by: '',
              sort_dir: '',
              page: 1,
            });
          }}
        >
          À traiter
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={filters.is_processed === PROCESSED_FILTER ? "active" : ""}
          onClick={() => {
            setFilters({
              ...filters,
              is_processed: PROCESSED_FILTER,
              sort_by: '',
              sort_dir: '',
              page: 1,
            });
          }}
        >
          Traité
        </NavLink>
      </NavItem>
    </ul>
  );
}

export default RequestsTab;