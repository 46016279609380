import {useCallback, useState} from 'react';
import {Button, Col, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import FormikAutocomplete from "../../../../components/Common/AsyncAutoComplete2";
import {
  promiseInvoicesOptionsForCheck,
} from "../../../../helpers/autocomplete_promise_options_api_v2";
import {
  promiseCustomersOptionsForCheck
} from "../../../../helpers/autocomplete_promise_options";
import LoaderLayoutPulse from "../../../../components/Common/LoaderLayoutPulse";
import { Formik } from 'formik';
import Payment from "../../../../models/payment";


interface AddCheckActionProps {
  onAction: (check: Payment) => void,
  defaultValues: Payment | undefined
  onAbort: () => void,
}
export default function AddEditCheckAction({ onAction, onAbort, defaultValues }: AddCheckActionProps) {
  const [formValid, setFormValid] = useState(defaultValues !== undefined);
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState<string | null>(null);
  const promiseInvoiceOptions = useCallback(() => promiseInvoicesOptionsForCheck(clientId?.split('#-#°')[0]), [clientId])

  function onValidate(values: any){
    setFormValid(values.client_id != null && values.invoice_id != null && values.amount != null && !!parseFloat(values.amount) && values.reference)
  }
  function onSubmit(values: any){
    setLoading(true);
    onAction({
      client_id: parseInt(values.client_id.split('#-#°')[0]),
      reference: values.reference,
      invoice_id: parseInt(values.invoice_id.split('#-#°')[0]),
      amount: values.amount,
      client_name: values.client_id.split('#-#°')[1],
      invoice_reference: values.invoice_id.split('#-#°')[1]
    })
  }

  return (
    <Modal isOpen={true} toggle={onAbort} className={""}>
      <ModalHeader toggle={onAbort}>Ajouter un chèque</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={
            {
              reference: defaultValues? defaultValues.reference || undefined : undefined,
              amount: defaultValues? defaultValues.amount || undefined : undefined,
              invoice_id: defaultValues? defaultValues.invoice_id+'#-#°'+defaultValues.invoice_reference || undefined : undefined,
              client_id: defaultValues? defaultValues.client_id+'#-#°'+defaultValues.client_name || undefined : undefined,
            } as {reference:string|undefined,amount:string|undefined,invoice_id:string|undefined,client_id:string|undefined}
          }
          onSubmit={onSubmit}
          validateOnChange={true}
          validate={onValidate}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <form className={"w-100 d-flex flex-column justify-content-center align-items-start"}>
              <Row className={"w-100"}>
                <Col xs={"8"}>
                  <Label for="value" style={{color:"#183254"}}><b>Numero de chèque</b></Label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrer un numéro"
                    defaultValue={values.reference}
                    onKeyUp={({currentTarget})=>{setFieldValue("reference", currentTarget.value)}}
                  />
                  <Label for="value" style={{color:"#183254"}} className={"mt-4"}><b>Montant</b></Label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrer un montant"
                    defaultValue={values.amount}
                    onKeyUp={({currentTarget})=>{setFieldValue("amount", currentTarget.value)}}
                  />
                  <Label for="value" style={{color:"#183254"}} className={"mt-4"}><b>Nom du Client</b></Label>
                  <FormikAutocomplete
                    loadOptions={promiseCustomersOptionsForCheck}
                    isSearchable={true}
                    multiple={false}
                    name={"client_id"}
                    className={"w-100"}
                    onChangeValue={(key, value) => {
                      setFieldValue(key, value)
                      setClientId(value)
                    }}
                  />
                  <Label for="value" style={{color:"#183254"}} className={"mt-4"}><b>Numéro de facture</b></Label>
                  <FormikAutocomplete
                    loadOptions={promiseInvoiceOptions}
                    isSearchable={false}
                    multiple={false}
                    name={"invoice_id"}
                    className={"w-100"}
                    disabled={values.client_id == null}
                  />
                </Col>
              </Row>
              {loading ?
                <div className="align-self-center mt-3"><LoaderLayoutPulse/></div>
                :
                <div className={"align-self-end"}>
                  <Button type="button" className={"btn btn-secondary mt-3 mr-3"} style={{background:"#96988F",borderColor:"#96988F"}} onClick={onAbort}>Annuler</Button>
                  <Button type="button" className={"btn btn-green mt-3"} disabled={!formValid} onClick={() => handleSubmit(values as any)}>Valider</Button>
                </div>
              }
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
}
