const getRole = () => {
  return localStorage.getItem("roleUser") !== "undefined" &&
    JSON.parse(localStorage.getItem("roleUser"))
    ? JSON.parse(localStorage.getItem("roleUser")).name
    : null;
};

export const isUnova = () => {
  // Role Unova
  let role = getRole();
  return role && role === "Unova";
};

export const isLeader = () => {
  // Role Dirigeant
  let role = getRole();
  return role && role === "Dirigeant";
};

export const isAdmin = () => {
  // Role Administrateur
  let role = getRole();
  return role && role === "Administrateur";
};

export const isRH = () => {
  // Role Ressource humaine
  let role = getRole();
  return role && role === "Ressource humaine";
};

export const isSalesAdmin = () => {
  // Role Administrateur des ventes
  let role = getRole();
  return role && role === "Administrateur des ventes";
};

export const isDA = () => {
  // Role Ressource humaine
  let role = getRole();
  return role && role === "Responsable d'agence";
};

export const isDR = () => {
  // Role Ressource humaine
  let role = getRole();
  return role && role === "Responsable régional";
};