import {
  GET_ABONNEMENT_STATES,
  GET_ABONNEMENT_STATES_SUCCESS,
  ABONNEMENTS_STATES_API_ERROR,
} from "./actionTypes";

export const getAbonnementStates = (query) => {
  return {
    type: GET_ABONNEMENT_STATES,
    payload: { query },
  };
};

export const getAbonnementStatesSuccess = (abonnementStates) => {
  return {
    type: GET_ABONNEMENT_STATES_SUCCESS,
    payload: abonnementStates,
  };
};

export const abonnementStatesApiError = (error) => {
  return {
    type: ABONNEMENTS_STATES_API_ERROR,
    payload: error,
  };
};
