import {Badge} from "reactstrap";

const PreviewYearComponent = ({
                                data,
                                price,
                                anniversaryMonth
                              }) => {
  const options = {month: 'numeric', day: 'numeric'};
  return (
    <div>
      <h1>{data.year}</h1>
      <div className='d-flex'>
        <table>

          <thead className='preview-head'>
            <tr>
              {data.dates.map(date => {
                if(date.data) {
                  const dateInstance = new Date(date.data);
                  return (
                    <th><Badge
                      className={date.has_term ? (anniversaryMonth === dateInstance.getMonth() ? 'badge-danger' : 'btn-green') : ''}
                      pill>{dateInstance.toLocaleString('default', {month: 'short'})}</Badge></th>
                  );
                }else{
                  return '';
                }
              })}
            </tr>
          </thead>
          <tbody className='preview-body'>
            <tr>
              {data.dates.map(date => {
                if(date.data) {
                  const dateInstance = new Date(date.data);
                  return (
                    <td>
                      <div className='preview-cell'>
                        <span>{date.has_term ? dateInstance.toLocaleString(undefined, options) : '-'}</span>
                        <span>{date.has_term ? (price + ' €') : '-'}</span>
                      </div>
                    </td>
                  );
                }else{
                  return '';
                }

              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PreviewYearComponent;