import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Alert,
} from "reactstrap";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Bootstrap Table
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// actions
import {
    getScale,
    deleteScale,
    scaleApiError,
} from "../../store/actions";

import {
    scaleItems
} from "../../api/index";

import LoaderLayoutPulse from "../../components/Common/LoaderLayoutPulse";

const ScalesDetail = ({getScale, deleteScale, history, scale, error}) => {
    var [scaleItemsData, setScaleItemsData] = useState(null);

    useEffect(() => {
        var scaleId = parseInt(window.location.pathname.split("/")[5], 10);
        getScale(scaleId);
        scaleItems.getAll({scale_id: scaleId, pagination: false}).then(response => setScaleItemsData(response.data))
    }, [getScale]);

    const deleteThisScale = () => {
        deleteScale(
            parseInt(window.location.pathname.split("/")[5], 10),
            history
        );
    };

    const columns = [
        {
            dataField: "total_loan_amount",
            text: "Montant total du crédit",
        },
        {
            dataField: "total_duration",
            text: "Durée du contrat de crédit",
        },
        {
            dataField: "monthly_payment_qte",
            text: "Nombre d'échéance",
        },
        {
            dataField: "monthly_payment",
            text: "Montant des échéances sans assurance",
        },
        {
            dataField: "total_amount_without_insurance",
            text: "Montant total dû sans assurance",
        },
        {
            dataField: "fix_rate",
            text: "Taux débiteur fixe %",
        },
        {
            dataField: "annual_effective_rate",
            text: "Taux annuel effectif global %",
        },
        {
            dataField: "monthly_amount_insurance_1",
            text: scale?.insurance_label_1,
        },
        {
            dataField: "monthly_amount_insurance_2",
            text: scale?.insurance_label_1,
        },
        {
            dataField: "administration_fees",
            text: "Frais de dossier",
        },
        {
            dataField: "total_amount_insurance_1",
            text: scale?.optional_insurance_label_1 || "Montant des échéances assurance 1 assuré",
        },
        {
            dataField: "total_amount_insurance_2",
            text: scale?.optional_insurance_label_2 || "Montant des échéances assurance 2 assurés",
        },
        {
            dataField: "monthly_amount_with_insurance_1",
            text: scale?.optional_insurance_label_3 || "Montant total dû avec assurance 1 assuré",
        },
        {
            dataField: "monthly_amount_with_insurance_2",
            text: scale?.optional_insurance_label_4 || "Montant total dû avec assurance 2 assurés",
        },
        {
            dataField: "total_amount_with_insurance_1",
            text: scale?.optional_insurance_amount_label_1 || "Montant Total dû au titre de l'assurance pour l'emprunteur",
        },
        {
            dataField: "total_amount_with_insurance_2",
            text: scale?.optional_insurance_amount_label_2 || "Montant Total dû au titre de l'assurance pour le co-emprunteur",
        },
        {
            dataField: "taea",
            text: scale?.taea_label || "T.A.E.A",
        },
        {
            dataField: "taea_2",
            text: scale?.taea_2_label || "T.A.E.A 2",
        },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="collaborators-container">
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Barèmes" breadcrumbItem="Détail barème" />
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {!scale ? null : (
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg="12">
                                                <h5>{scale.label}</h5>
                                                <p className="mb-1">Échéance minimum: {scale.min_monthly_payment}</p>
                                                <p className="mb-1">Prêt minimum: {scale.min_amount_loan}</p>
                                                <p className="mb-1">Prêt maximum: {scale.max_amount_loan}</p>
                                                <p className="mb-1">Référence: {scale.ref}</p>
                                                <p className="mb-1">Date de début de l'échéancier: {(new Date(scale.starting_date)).toLocaleDateString()}</p>
                                                <p className="mb-1">Date de fin de l'échéancier: {(new Date(scale.ending_date)).toLocaleDateString()}</p>
                                                <p className="mb-1">Date de la première échéance: {scale.first_monthly_payment_delay}</p>
                                            </Col>
                                        </Row>
                                        {
                                            scale.documents.length > 0
                                                ? <Row className="mt-3">
                                                    <Col lg="12">
                                                        <p className="mb-1">Listes des documents :</p>
                                                        <ul>
                                                            {scale.documents.map((document, index) => (
                                                                <li>
                                                                    <a
                                                                        href={`${process.env.REACT_APP_API_URL}/files/${document.url}`}
                                                                        className="d-flex align-items-center mr-5 table-link"
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        {document.url}
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Col>
                                                </Row>
                                                : null
                                        }
                                    </CardBody>
                                    <CardFooter>
                                        <Row className="container-text-theme-color-2">
                                            <Col xs="12" className="d-flex justify-content-end">
                                                <Link
                                                    to={`/financer/detail/${parseInt(
                                                        window.location.pathname.split("/")[2],
                                                        10
                                                    )}`}
                                                    className="d-flex align-items-center mr-5"
                                                >
                                                    <i className="bx bx-arrow-back mr-1"></i> Retour au financement
                                                </Link>
                                                <Link
                                                    to={`/financer/${parseInt(
                                                        window.location.pathname.split("/")[2],
                                                        10
                                                    )}/scale/edit/${parseInt(
                                                        window.location.pathname.split("/")[5],
                                                        10
                                                    )}`}
                                                    className="d-flex align-items-center mr-5"
                                                >
                                                    <i className="bx bx-pencil mr-1"></i> Modifier
                                                </Link>
                                                <Link
                                                    to="#"
                                                    onClick={() => deleteThisScale()}
                                                    className="d-flex align-items-center mr-5"
                                                >
                                                    <i className="bx bx-trash mr-1"></i> Supprimer
                                                </Link>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                                <Row>
                                    <Col lg="12">
                                        <h5>Élément du barèmes</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <BootstrapTable
                                            keyField="id"
                                            data={scaleItemsData || []}
                                            columns={columns}
                                            defaultSortDirection="asc"
                                            pagination={paginationFactory({
                                                sizePerPage: 10,
                                                sizePerPageList: [
                                                    { text: "Afficher 10 éléments", value: 10 },
                                                    { text: "Afficher 20 éléments", value: 20 },
                                                    { text: "Afficher 50 éléments", value: 50 },
                                                ],
                                            })}
                                        />
                                        {scaleItemsData == null && <LoaderLayoutPulse/>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { scale, error } = state.Scales;
    return { scale, error };
};

export default withRouter(
    connect(mapStatetoProps, { getScale, deleteScale, scaleApiError })(
        ScalesDetail
    )
);
