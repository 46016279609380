import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { GET_SUB_CATEGORIES } from "./actionTypes";
import { getSubCategoriesSuccess, subCategoryApiError } from "./actions";

import { sub_categories } from "../../api";

function* getSubCategories({ payload: { query } }) {
  try {
    const response = yield call(sub_categories.getAll, query);
    yield put(getSubCategoriesSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          subCategoryApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des catégories de produit"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(subCategoryApiError(error.response.data.message));
      }
    } else {
      yield put(subCategoryApiError(error.message));
    }
  }
}

export function* watchGetSubCategories() {
  yield takeEvery(GET_SUB_CATEGORIES, getSubCategories);
}

function* subCategoriesSaga() {
  yield all([fork(watchGetSubCategories)]);
}

export default subCategoriesSaga;
