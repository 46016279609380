import {
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT,
  GET_PROJECT_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  DELETE_PROJECT,
  DELETE_TECHNICAL_VISIT_PROJECT,
  DELETE_PROJECT_SUCCESS,
  PROJECTS_API_ERROR,
  EXPORT_PROJECTS,
  EXPORT_PROJECTS_SUCCESS,
} from "./actionTypes";

export const getProjects = (query) => {
  return {
    type: GET_PROJECTS,
    payload: { query },
  };
};

export const getProjectsSuccess = (projects) => {
  return {
    type: GET_PROJECTS_SUCCESS,
    payload: projects,
  };
};

export const getProject = (id) => {
  return {
    type: GET_PROJECT,
    payload: { id },
  };
};

export const getProjectSuccess = (project) => {
  return {
    type: GET_PROJECT_SUCCESS,
    payload: project,
  };
};

export const updateProject = (id, project) => {
  return {
    type: UPDATE_PROJECT,
    payload: { id, project },
  };
};

export const updateProjectSuccess = (project) => {
  return {
    type: UPDATE_PROJECT_SUCCESS,
    payload: project,
  };
};
export const deleteTechnicalVisitProject = (id, history) => {
  return {
    type: DELETE_TECHNICAL_VISIT_PROJECT,
    payload: { id, history },
  };
};
export const deleteProject = (id, history) => {
  return {
    type: DELETE_PROJECT,
    payload: { id, history },
  };
};
export const deleteProjectSuccess = (project) => {
  return {
    type: DELETE_PROJECT_SUCCESS,
    payload: project,
  };
};

export const projectsApiError = (error) => {
  return {
    type: PROJECTS_API_ERROR,
    payload: error,
  };
};

export const exportProjects = (values) => {
  return {
    type: EXPORT_PROJECTS,
    payload: { values },
  };
};

export const exportProjectsSuccess = (file_data) => {
  return {
    type: EXPORT_PROJECTS_SUCCESS,
    payload: file_data,
  };
};
