import {computeName, typeForm} from "./quotes_invoices_helper";

/**
 * Compute if an order has invoices that are nor canceled nor refund
 * @param order
 * @return {{has_invoices: boolean, has_deposit: boolean, has_sold: boolean}}
 * {
 *     has_invoices : if order has at least one invoice
 *     has_deposit : if order has at least one deposit invoice
 *     has_sold : if order has at least one sold invoice
 * }
 */
export function hasInvoices(order) {
    let hasDeposit = false;
    let hasSold = false;

    if(order)
        order.invoices.forEach((invoice) => {
            if(!invoice.is_canceled && !invoice.is_refund) {
                if(invoice.is_deposit_invoice)
                    hasDeposit = true;

                if(invoice.is_sold_invoice)
                    hasSold = true;
            }
        });

    return {
        has_invoices: (hasDeposit || hasSold),
        has_deposit: hasDeposit,
        has_sold: hasSold,
    };
}

export function canValidate(order, type, isInvoiceOrder = false) {
    if(type === typeForm.quote || type === typeForm.approval) {
        const {has_invoices, has_sold} = hasInvoices(order);
        return !order.child_order && (!has_invoices || (order.parent_quote_number && !has_sold));
    }
    else {
        return isInvoiceOrder ? order.invoices[order.invoices.length - 1].is_draft : order.is_draft;
    }
}


/**
 * Compute array of state (defined in ValidationButtonsComponent) to change display
 *  0 : create button
 *  2 : save modif
 *  3 : preview
 *  4 : generate deposit
 *  5 : generate balance
 *
 * @param isAddUpdate
 * @param isCreating
 * @param canPreview allow the computing of the preview button
 * @param order
 * @param type type of the form :
 * quote: "QUOTE", // Devis
 * invoice: "INVOICE", // Facture
 * deposit: "DEPOSIT", // facture d'acompte
 * approval: "APPROVAL", // avenant
 * balance: "BALANCE", // facture de solde
 * @param {object} handlers functions handling by buttons :
 * {
 *     creation:
 *     update:
 *     preview:
 *     deposit:
 *     balance:
 * }
 *
 * @return {array} array of buttonsStates
 */
export function computeButtons(isAddUpdate, order, canPreview, type, handlers, isUpdate) {

    let btns = []

    // Create button
    if (!isAddUpdate && !order) {
        btns.push({
            label: "Créer " + computeName(type),
            disabled: !canPreview,
            onclick: handlers.creation,
        });
    }

    // Update button
    if (order || isAddUpdate) {
        btns.push({
            label: isUpdate ? "Sauvegarder les modifications" : "Valider",
            disabled: !canValidate(order, type, isAddUpdate) || !canPreview,
            onclick: handlers.update,
        });
    }

    // Preview button
    btns.push({
        label: "Aperçu",
        disabled: !canPreview,
        onclick: handlers.preview,
    });


    // Generate deposit button
    if ((type === typeForm.quote || type === typeForm.approval) && order && isUpdate) {

        let invoices = {has_invoices: false, has_deposit: false, has_sold: false};
        if(order)
            invoices = hasInvoices(order);

        btns.push({
            label: "Générer une facture d'acompte",
            disabled: (order.child_order || invoices.has_invoices),
            onclick: handlers.deposit
        });

        // Generate balance button
        btns.push({
            label: "Générer une facture de solde",
            disabled: (order.child_order || invoices.has_sold),
            onclick: handlers.balance
        });
    }

    return btns;
}