import { Dispatch, LegacyRef, SetStateAction, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import { Card, CardBody, Col, Row } from 'reactstrap';
import { V2 } from '../../../api';
import { moveItem } from '../../../helpers/array_helper';
import { parseCustomerGPSCoord, validCustomerGPSCoord } from '../../../helpers/customer_helper';
import { convertMapItemToCustomMarker } from '../../../helpers/map_helper';
import { MapItemFilters } from '../../../models/map_item';
import { CustomMarker } from './CustomMarker';
import MapFilter from './Filters/MapFilter';
import MapComponent from './MapComponent';
import ModalMap from './Modal/ModalMap';

interface MapItemComponentProps {
  filters: MapItemFilters,
  setFilters: Dispatch<SetStateAction<MapItemFilters>>
}

export default function MapItemComponent({
  filters,
  setFilters
}: MapItemComponentProps) {
  const printableRef = useRef<MapComponent>();
  const modalMapRef = useRef<ModalMap>();
  const [displayMapItemList, setDisplayMapList] = useState(true);
  const [displayTourList, setDisplayTourList] = useState(true);
  const [directionsResult, setDirectionsResult] = useState<google.maps.DirectionsResult | undefined>(undefined);
  const [waypoints, setWaypoints] = useState<CustomMarker[]>([]);
  const [markers, setMarkers] = useState<CustomMarker[]>([]);

  const loadMapItems = () => {
    V2.map_items.getAll(filters).then((response) => {
      setMarkers(convertMapItemToCustomMarker(response.data));
      setWaypoints([]);
      setDirectionsResult(undefined);
    });
  };

  useEffect(loadMapItems, [filters])

  const pageStyle = `
    .custom-map-buttons {
      display: none !important;
    }
    
    .project-info-container {
      border-bottom: 1px solid #e8eaed !important;
    }
  `;

  const handlePrint = useReactToPrint({
    content: () => printableRef.current!,
    pageStyle: pageStyle,
  });

  const moveWaypointOrder = (index: number, offset: number) => {
    setWaypoints(moveItem(waypoints, index, offset));
  }

  const deleteWaypoint = (marker: CustomMarker) => {
    setWaypoints(prev => {
      let tmpArray = [...prev];
      const indexDelete = tmpArray.findIndex((waypoint) => waypoint.entity.id === marker.entity.id);
      tmpArray.splice(indexDelete, 1);
      return tmpArray;
    })
  }

  const addWaypoint = async (marker: CustomMarker) => {
    if (!validCustomerGPSCoord(marker.entity.client)) {
      let response = await V2.customers.generateCoordinates(marker.entity.client.id!);
      let newMarker = {
        ...marker,
        entity: {
          ...marker.entity,
          client: {
            ...marker.entity.client,
            lat: response.data.lat,
            lon: response.data.lon,
            lat_construction_site: response.data.lat_construction_site,
            lon_construction_site: response.data.lon_construction_site
          }
        },
        position: parseCustomerGPSCoord(response.data)
      };

      setMarkers(prev => prev.map(m => newMarker.entity.id === m.entity.id ? newMarker : m));
    }
    setWaypoints([...waypoints, marker]);
  }

  const selectMarker = (entityId: number) => {
    const index = markers.findIndex((m) => entityId === m.entity.id);

    setWaypoints(prev => prev.map((marker, i) => {
      if (i === index)
        return { ...marker, selected: true }
      else
        return { ...marker, selected: false }
    }))
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row className="mb-3">
            <Col lg="6">
              <MapFilter
                filters={filters}
                setFilters={setFilters}
              />
            </Col>
            <Col lg='3' className='d-flex align-items-end'>
              <div>
                <label htmlFor='map_items_list' className='mr-1'>Liste des planifications</label>
                <input
                  id='map_items_list'
                  defaultChecked={displayMapItemList}
                  onClick={(e) => setDisplayMapList(e.currentTarget.checked)}
                  type='checkbox'
                />
              </div>
            </Col>
            <Col lg='3' className='d-flex align-items-end justify-content-between'>
              <div>
                <label htmlFor='tour' className='mr-1'>Tournée</label>
                <input
                  id='tour'
                  defaultChecked={displayTourList}
                  onClick={(e) => setDisplayTourList(e.currentTarget.checked)}
                  type='checkbox'
                />
              </div>
              <div>
                <Link
                  to='#'
                  onClick={handlePrint}
                >
                  <i className="bx bx-printer font-size-24 mr-2" />
                </Link>
              </div>
            </Col>
          </Row>
          <MapComponent
            ref={printableRef as LegacyRef<MapComponent>}
            directionsResult={directionsResult}
            setDirectionsResult={setDirectionsResult}
            displayMapItemList={displayMapItemList}
            displayTourList={displayTourList}
            markers={markers}
            waypoints={waypoints}
            deleteWaypoint={deleteWaypoint}
            moveWaypointOrder={moveWaypointOrder}
            selectMarker={selectMarker}
            addWaypoint={addWaypoint}
            onShowModal={(entity) => modalMapRef.current?.open(entity)}
          />
        </CardBody>
      </Card>
      <ModalMap
        ref={modalMapRef as LegacyRef<ModalMap>}
        waypoints={waypoints}
        addWaypoint={addWaypoint}
        deleteWaypoint={deleteWaypoint}
        onUpsert={loadMapItems}
      />
    </>
  )
}