import React, { useEffect, useState } from 'react';
import BootstrapTable, { ColumnDescription, SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { V2 } from '../../../../api';
import GlobalLoader from "../../../../components/Common/GlobalLoader";
import { getDateWithShortFormat, getTimeWithFormat } from '../../../../helpers/helper';
import { onTableChange } from "../../../../helpers/table_helper";
import Request, { RequestsFilters } from '../../../../models/request';
import Pagination, { getPaginationFromResponse } from '../../../../models/types/pagination';
import { getPriorityIcon } from "../../../../models/requests_priority";
import { getStatusColor } from "../../../../models/requests_status";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";
import { parseCustomerName } from '../../../../helpers/customer_helper';

interface RequestsTableProps {
  filters: RequestsFilters,
  setFilters: React.Dispatch<React.SetStateAction<RequestsFilters>>,
  onEditAction: (action: { request_id: number, action: string, value: string | null, loading: boolean }) => void,
  history: RouteComponentProps["history"]
}

const RequestsTable = ({
  filters,
  setFilters,
  onEditAction,
  history
}: RequestsTableProps) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [requests, setRequests] = useState<Request[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);

  const getRequests = () => {
    setLoading(true);
    V2.requests.getAll({groups: ['with_importance'], ...filters}).then(response => {
      setRequests(response.data as Request[]);
      setPagination(getPaginationFromResponse(response));
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };
  useEffect(getRequests, [filters]);

  // Liste des colonnes qui seront affichées dans le tableau
  const columns: ColumnDescription<Request>[] = [
    {
      dataField: "request_at",
      text: "Date/heure",
      formatter: (cell, row) =>
        <span className={row.is_important ? "text-danger" : ""}>
          {getDateWithShortFormat(row.request_at) + " " + getTimeWithFormat(row.request_at)}
        </span>,
      sort: true,
      classes: "align-middle"
    },
    {
      dataField: "client_name",
      text: "Client",
      formatter: (cell, row) =>
        <Link to={`customer/detail/${row.client?.id}`} className={row.is_important ? "text-danger" : ""}>
          {parseCustomerName(row.client!)}
        </Link>,
      sort: true,
      classes: "align-middle"
    },
    {
      dataField: "nature",
      text: "Nature",
      formatter: (cell, row) =>
        <span className={row.is_important ? "text-danger" : ""}>
          {row.nature?.label || ""}
        </span>,
      sort: true,
      classes: "align-middle"
    },
    {
      dataField: "comment",
      text: "Commentaire",
      formatter: (cell, row) =>
        <span className={row.is_important ? "text-danger" : ""}>
          <i className="fa fa-eye p-2" id={"request-" + row.id + "-comment-tooltip"}></i>
          <UncontrolledTooltip placement="top" target={"request-" + row.id + "-comment-tooltip"}>
              {row.comment}
          </UncontrolledTooltip >
        </span>,
      classes: "align-middle text-center"
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: (cell, row) =>
        <Badge style={{ background: (getStatusColor(row.status || { name: "new" })) }}>
          {row.status?.label}
        </Badge> || "",
      sort: true,
      classes: "align-middle"
    },
    {
      dataField: "priority",
      text: "Priorité",
      formatter: (cell, row) =>
        <span className={row.is_important ? "text-danger" : ""}>
          {getPriorityIcon(row.priority || { name: "low" })}{row.priority?.label}
        </span>,
      sort: true,
      classes: "align-middle"
    },
    {
      dataField: "zip_code",
      text: "CP",
      classes: "align-middle",
      formatter: (cell, row) =>
        <span className={row.is_important ? "text-danger" : ""}>
          {row.zip_code || ""}
        </span>,
    },
    {
      dataField: "interlocutor_name",
      text: "Interlocuteur",
      formatter: (cell, row) =>
        <Link to={`collaborator/detail/${row.interlocutor?.id}`} className={row.is_important ? "text-danger" : ""}>
          {row.interlocutor?.firstname || "" + row.interlocutor?.firstname || ""}
        </Link>,
      sort: true,
      classes: "align-middle"
    },
    {
      dataField: "collaborator_name",
      text: "Collaborateur assigné",
      formatter: (cell, row) =>
        <Link to={`collaborator/detail/${row.collaborator?.id}`} className={row.is_important ? "text-danger" : ""}>
          {row.collaborator?.firstname || "" + row.collaborator?.firstname || ""}
        </Link>,
      sort: true,
      classes: "align-middle"
    },
    {
      dataField: "client_phone",
      text: "Téléphone client",
      classes: "align-middle",
      formatter: (cell, row) =>
        <span className={row.is_important ? "text-danger" : ""}>
          {row.client_phone || ""}
        </span>,
    },
    {
      dataField: "attached_file_project",
      text: "Projet ou dossier rattaché",
      formatter: (cell, row) =>
        <Link to={`/projects-overview/${row.project_id}`} className={row.is_important ? "text-danger" : ""}>
          {row.project_name || ""}
        </Link>,
      classes: "align-middle"
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => (
        <UncontrolledDropdown>
          <DropdownToggle className={"bg-transparent"}>
            <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
          </DropdownToggle>
          <DropdownMenu right className="bordered">
            <DropdownItem onClick={() => { history.push(`/requests/${row.id}/details`) }}>Voir</DropdownItem>
            <DropdownItem onClick={() => { window.open(`/requests/${row.id}/details`,'_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
            <DropdownItem onClick={() => { onEditAction({ request_id: row.id || -1, action: "assign", value: null, loading: false }) }}>Assigner à</DropdownItem>
            <DropdownItem onClick={() => { onEditAction({ request_id: row.id || -1, action: "priority", value: null, loading: false }) }}>Modifier la priorité</DropdownItem>
            <DropdownItem onClick={() => { onEditAction({ request_id: row.id || -1, action: "status", value: null, loading: false }) }}>Modifier le statut</DropdownItem>
            <DropdownItem onClick={() => { onEditAction({ request_id: row.id || -1, action: "comment", value: row.comment || null, loading: false }) }}>Modifier le commentaire</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
      classes: "text-right"
    },
  ];

  return (
    loading ? <GlobalLoader /> : <BootstrapTable
      keyField="id"
      data={requests}
      columns={columns}
      defaultSortDirection="asc"
      sort={{ dataField: filters.sort_by, order: (filters.sort_dir || "asc")  as SortOrder }}
      remote={{ pagination: true, sort: true }}
      onTableChange={onTableChange.bind({ setFilters, filters })}
      pagination={paginationFactory({
        page: pagination?.current_page,
        totalSize: pagination?.total_count,
        sizePerPage: pagination?.current_page_size,
        sizePerPageList: [
          { text: "Afficher 10 éléments", value: 10 },
          { text: "Afficher 25 éléments", value: 25 },
          { text: "Afficher 50 éléments", value: 50 },
          { text: "Afficher 75 éléments", value: 75 },
          { text: "Afficher 100 éléments", value: 100 },
          { text: "Afficher 200 éléments", value: 200 },
        ],
      })}
    />
  );
};

export default RequestsTable;
