import React, { useEffect, useState } from 'react';
import BootstrapTable, { ColumnDescription, SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { V2 } from "../../../../api"
import GlobalLoader from "../../../../components/Common/GlobalLoader";
import { getDateWithShortFormat } from '../../../../helpers/helper';
import { onTableChange } from "../../../../helpers/table_helper";
import { RequestsFilters } from '../../../../models/request';
import Pagination, { getPaginationFromResponse } from '../../../../models/types/pagination';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";
import CheckDeposit, { CheckDepositFilters } from "../../../../models/check_deposit";
import moment from "moment";

interface CheckDepositTableProps {
  filters: RequestsFilters,
  setFilters: React.Dispatch<React.SetStateAction<CheckDepositFilters>>,
  history: RouteComponentProps["history"]
}

const CheckDepositTable = ({
  filters,
  setFilters,
  history
}: CheckDepositTableProps) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [checkDeposits, setCheckDeposits] = useState<CheckDeposit[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);

  const getCheckDeposits = () => {
    setLoading(true);
    let customFilters: any = { groups: [], ...filters };
    if (customFilters.amount_begin) {
      customFilters.amount_begin = customFilters.amount_begin * 100
    }
    if (customFilters.amount_end) {
      customFilters.amount_end = customFilters.amount_end * 100
    }
    customFilters.date_begin = customFilters.date_begin != null ? moment(customFilters.date_begin).format("yyyy-MM-DD") || null : null;
    customFilters.date_end = customFilters.date_end != null ? moment(customFilters.date_end).format("yyyy-MM-DD") || null : null;
    V2.check_deposits.getAll(customFilters).then(response => {
      setCheckDeposits(response.data as CheckDeposit[]);
      setPagination(getPaginationFromResponse(response));
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };

  useEffect(getCheckDeposits, [filters]);

  // Liste des colonnes qui seront affichées dans le tableau
  const columns: ColumnDescription<CheckDeposit>[] = [
    {
      dataField: "date_depot",
      text: "Date",
      formatter: (_, row) => getDateWithShortFormat(row.date_depot) as string || "",
      classes: "align-middle"
    },
    {
      dataField: "depot_number",
      text: "Numéro",
      formatter: (_, row) => row.depot_number || "",
      classes: "align-middle"
    },
    {
      dataField: "payments_count",
      text: "Nombre de chèques",
      formatter: (_, row) => row.payments_count + "" || "",
      classes: "align-middle"
    },
    {
      dataField: "total_amount",
      text: "Montant total",
      formatter: (_, row) => ((row.total_amount || 0) / 100.0).toFixed(2),
      classes: "align-middle"
    },
    {
      dataField: "bank_label",
      text: "Banque",
      classes: "align-middle"
    },
    {
      dataField: "",
      text: "Action",
      formatter: (_, row) => (
        <UncontrolledDropdown>
          <DropdownToggle className={"bg-transparent"}>
            <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
          </DropdownToggle>
          <DropdownMenu right className="bordered">
            <DropdownItem onClick={() => { history.push(`/check-deposit/${row.id}/detail`) }}>Voir</DropdownItem>
            <DropdownItem onClick={() => { window.open(`/check-deposit/${row.id}/detail`, '_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
      classes: "text-right"
    },
  ];


  return (
    loading ? <GlobalLoader /> : <BootstrapTable
      classes={"light-header"}
      keyField="id"
      data={checkDeposits}
      columns={columns}
      defaultSortDirection="asc"
      sort={{ dataField: filters.sort_by, order: (filters.sort_dir || "asc")  as SortOrder}}
      remote={{ pagination: true, sort: true }}
      onTableChange={onTableChange.bind({ setFilters, filters })}
      pagination={paginationFactory({
        page: pagination?.current_page,
        totalSize: pagination?.total_count,
        sizePerPage: pagination?.current_page_size,
        sizePerPageList: [
          { text: "Afficher 10 éléments", value: 10 },
          { text: "Afficher 25 éléments", value: 25 },
          { text: "Afficher 50 éléments", value: 50 },
          { text: "Afficher 75 éléments", value: 75 },
          { text: "Afficher 100 éléments", value: 100 },
          { text: "Afficher 200 éléments", value: 200 },
        ],
      })}
    />
  );
};

export default CheckDepositTable;
