import { Field, Form, Formik } from "formik";
import React from "react";
import { Button, Card, CardBody, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { ErrorMessageCustom } from '../../../../components/Common/ErrorMessageCustom';
import Project from "../../../../models/project";
import { offer_codes as offerCodesApi } from "../../../../api/index";

interface OfferCodeModalProps {
  toggle: () => void,
  project: Project,
  isOpen: boolean,
  updateProject: any,
}

const OfferCodeModal = ({
  isOpen,
  toggle,
  project,
  updateProject
}: OfferCodeModalProps) => {
  return (
    <Formik
      initialValues={{ code: '' }}
      enableReinitialize
      onSubmit={({ code }, actions) => {
        offerCodesApi.validateOfferCode(project.order?.id!, code).then(response => {
          const {
            valid,
            offer_id,
            offer_code
          } = response.data;
          if (valid) {
            actions.setFieldError('code', undefined);
            updateProject(project.id, { offer_code, offer_id });
          } else {
            actions.setFieldError('code', 'Le code saisi n\'est pas valide');
          }
        }).catch(({ response }) => {
          if (response.status === 404) {
            actions.setFieldError('code', 'Le code est introuvable');
          } else {
            actions.setFieldError('code', 'Erreur interne');
          }
        })
      }}
    >
      {({ handleSubmit, errors, touched }) => (
        <Modal isOpen={isOpen} toggle={() => toggle()}>
          <ModalHeader
            className="calendar-modal-header"
            toggle={() => toggle()}
            tag="div"
          >
            <span className="w-100 d-flex justify-content-between">
              <h4>Ajouter une promotion</h4>
            </span>
          </ModalHeader>
          <ModalBody>
            <Form>
              <Card>
                <CardBody>
                  <Row className="mt-4">
                    <Col xs="6">
                      <Label for="code">Code de promotion</Label>
                    </Col>
                    <Col xs="6">
                      <Input
                        id="code"
                        name="code"
                        type="text"
                        invalid={!!errors.code && !!touched.code}
                        className="form-control"
                        tag={Field}
                        placeholder="Entrer un code"
                      />
                      <ErrorMessageCustom name="code" />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                toggle();
              }}
              className="btn btn-secondary waves-effect"
            >
              Annuler
            </Button>
            <Button
              onClick={() => handleSubmit()}
              className="btn btn-green waves-effect waves-light"
            >
              Valider
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );
};

export default OfferCodeModal;