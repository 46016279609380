import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import FinancerForm from "./FormComponent/FinancerForm";

// Validation Schema
import { updateValidationSchema } from "./FormValidationSchemas/updateValidationSchema";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import { getFinancer, updateFinancer, financerApiError } from "../../store/actions";

const FinancerEdit = (props) => {
  useEffect(() => {
    props.getFinancer.call(undefined,parseInt(window.location.pathname.split("/")[3], 10));
  }, [props.getFinancer]);

  const initValues = props.financer
    ? {
      name: props.financer.name,
      url: props.financer.url,
      description: props.financer.description,
      email: props.financer.email,
      phone: props.financer.phone,
    }
    : {
      name: "",
      url: "",
      leader: "",
      address: "",
      phone: "",
      email: "",
    };

  const handleSubmit = (values) => {
    props.updateFinancer(
      parseInt(window.location.pathname.split("/")[3], 10),
      {
        name: values.name,
        url: values.url,
        description: values.description,
        phone: values.phone,
        email: values.email,
      },
      props.history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Financements" breadcrumbItem="Modifier un financement" />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Modifier un financement</CardTitle>
                {props.loading ? null : !props.financer ? null : (
                  <FinancerForm
                    initValues={initValues}
                    validationSchema={updateValidationSchema}
                    handleSubmit={handleSubmit}
                    financer={props.financer}
                    error={props.error}
                    isUpdate={true}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { financer, loading, error } = state.Financers;
  return { financer, loading, error };
};

export default withRouter(
  connect(mapStatetoProps, {
    getFinancer,
    updateFinancer,
    financerApiError,
  })(FinancerEdit)
);
