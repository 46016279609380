import React, { useEffect, useState } from 'react';
import BootstrapTable, { ColumnDescription, SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { V2 } from '../../../../api';
import GlobalLoader from "../../../../components/Common/GlobalLoader";
import { getDateWithShortFormat } from '../../../../helpers/helper';
import { onTableChange } from "../../../../helpers/table_helper";
import Pagination, { getPaginationFromResponse } from '../../../../models/types/pagination';
import {getPriorityIcon} from "../../../../models/requests_priority";
import {getStatusColor} from "../../../../models/requests_status";
import {Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import InterventionFolder, {InterventionFolderFilter, InterventionFolderTypesEnum} from "../../../../models/intervention_folder";
import {Link, RouteComponentProps} from "react-router-dom";
import { parseCustomerName } from '../../../../helpers/customer_helper';

interface InterventionTableProps {
  filters: InterventionFolderFilter,
  setFilters: React.Dispatch<React.SetStateAction<InterventionFolderFilter>>,
  onEditAction: (action:{intervention_folder_id:number, action:string, values:any}) => void,
  history: RouteComponentProps["history"],
  InterventionsType: string
}

const InterventionTable = ({
  filters,
  setFilters,
  onEditAction,
  history,
  InterventionsType
}: InterventionTableProps) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [interventions, setInterventions] = useState<InterventionFolder[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);

  const getRequests = () => {
    setLoading(true);
    V2.interventions.getAll(filters).then(response => {
      setInterventions(response.data);
      setPagination(getPaginationFromResponse(response));
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };

  useEffect(getRequests, [filters]);

  // Liste des colonnes qui seront affichées dans le tableau
  const columns: ColumnDescription<InterventionFolder>[] = [
    {
      dataField: "client_name",
      text: "Client",
      formatter: (cell, row) => <Link to={`/customer/detail/${row.client?.id}`}>{row.client ? parseCustomerName(row.client) : ""}</Link>,
      classes: "align-middle"
    },
    {
      dataField: "intervention_at",
      text: "Date",
      formatter: (cell, row) => getDateWithShortFormat(row.intervention_at),
      sort: true,
      classes: "align-middle"
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: (cell, row) =>
          <Badge style={{ background: (getStatusColor(row.status || {name:"new"}))}}>
            {row.status?.label}
          </Badge> || "",
      classes: "align-middle"
    },
    {
      dataField: "product",
      text: "Produit",
      formatter: (cell, row) => row.product_name || "",
      sort: InterventionsType === InterventionFolderTypesEnum.SAV,
      classes: "align-middle"
    },
    {
      dataField: "priority",
      text: "Priorité",
      formatter: (cell, row) => <>{getPriorityIcon(row.priority||{name:"low"})}{row.priority?.label}</> || "",
      classes: "align-middle"
    },
    {
      dataField: "zip_code",
      text: "CP",
      sort: InterventionsType === InterventionFolderTypesEnum.MAINTENANCE,
      classes: "align-middle"
    },
    {
      dataField: "client_phone",
      text: "Téléphone client",
      classes: "align-middle"
    },
    {
      dataField: "comment",
      text: InterventionsType === InterventionFolderTypesEnum.SAV ? "Commentaire (Code erreur)" : "Commentaire",
      classes: "align-middle"
    },
    {
      dataField: "technicians",
      text: "Techniciens",
      classes: "align-middle",
      formatter: (cell, row) => row.technicians!.map((t) => `${t.lastname} ${t.firstname}`).join(', ')
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => (
        <UncontrolledDropdown>
          <DropdownToggle className={"bg-transparent"}>
            <i className="fas fa-ellipsis-h text-theme-color h2-font-size"/>
          </DropdownToggle>
          <DropdownMenu right className="bordered">
            <DropdownItem onClick={() =>{history.push(`/interventions/${row.id}/details`)}}>En détails</DropdownItem>
            {row.lend?.file ?
              <DropdownItem onClick={() =>{window.open(`${process.env.REACT_APP_API_URL}/files/${row.lend?.file}`,'_blank')}}>demande de pret</DropdownItem>
            :null}
            {row.intervention_document ?
              <DropdownItem onClick={() =>{window.open(`${process.env.REACT_APP_API_URL}/files/${row.intervention_document}`,'_blank')}}>Bon d'intervention</DropdownItem>
            :null}
            <DropdownItem onClick={() =>{onEditAction({intervention_folder_id: row.id || -1, action: "priority", values: row.priority?.id })}}>Modifier la priorité</DropdownItem>
            <DropdownItem onClick={() =>{onEditAction({intervention_folder_id: row.id || -1, action: "status", values: row.status?.id })}}>Modifier le statut</DropdownItem>
            <DropdownItem onClick={() =>{onEditAction({intervention_folder_id: row.id || -1, action: "technicians", values: row.technicians?.map(tech => tech.id) || [] })}}>Assigner des techniciens</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  return (
    loading ? <GlobalLoader/>  : <BootstrapTable
      keyField="id"
      data={interventions}
      columns={columns}
      defaultSortDirection="asc"
      sort={{ dataField: filters.sort_by, order: (filters.sort_dir || "asc")  as SortOrder }}
      remote={{ pagination: true, sort: true }}
      onTableChange={onTableChange.bind({ setFilters, filters })}
      pagination={paginationFactory({
        page: pagination?.current_page,
        totalSize: pagination?.total_count,
        sizePerPage: pagination?.current_page_size,
        sizePerPageList: [
          { text: "Afficher 10 éléments", value: 10 },
          { text: "Afficher 25 éléments", value: 25 },
          { text: "Afficher 50 éléments", value: 50 },
          { text: "Afficher 75 éléments", value: 75 },
          { text: "Afficher 100 éléments", value: 100 },
        ],
      })}
    />
  );
};

export default InterventionTable;
