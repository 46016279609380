import { Badge, Button, Col, Row } from 'reactstrap';
import { parseCustomerAddress, parseCustomerName } from '../../../helpers/customer_helper';
import { getDateWithShortFormat, getShortDateAndTimeWithFormat, getTimeWithFormat } from '../../../helpers/helper';
import Disponibility from '../../../models/disponibility';
import { CustomMarker } from './CustomMarker';

// Icons
import { ReactComponent as ImgDelete } from '../../../assets/images/icons/minus.svg';
import { ReactComponent as ImgAdd } from '../../../assets/images/icons/plus.svg';
import { isProjectInTour } from '../../../helpers/map_helper';

interface MapMarkerInfoProps {
  marker: CustomMarker,
  index: number,
  waypoints: CustomMarker[],
  addWaypoint: (marker: CustomMarker) => void,
  deleteWaypoint: (marker: CustomMarker) => void,
  onShowModal: (entity: CustomMarker) => void
}

export default function MapMarkerInfo({
  marker,
  index,
  onShowModal,
  waypoints,
  addWaypoint,
  deleteWaypoint
} : MapMarkerInfoProps) {

  let disponibilities : Disponibility[] = marker.entity.project?.disponibilities || [];

  return (
    <div
      className="project-info-container buttons-waypoint"
      key={`project-${index}`}
    >
      <div
        className="project-info"
        onClick={() => onShowModal(marker)}
      >
        <p>
          {marker.entity.planified ? (
            <Badge color="success" pill>
              &nbsp;
            </Badge>
          ) : (
            <Badge color="danger" pill>
              &nbsp;
            </Badge>
          )}
          &nbsp;
          {`Client : ${parseCustomerName(marker.entity.client)}`}
        </p>
        <p className="project-info-small">
          {`${parseCustomerAddress(marker.entity.client)}`}
        </p>
        <Row className="mt-2 project-info-disponibilities">
          <Col>
            {`Disponibilité${
              disponibilities.length > 1 ? "s" : ""
            } :`}
          </Col>
          <Col>
            {disponibilities.length > 0 ? (
              disponibilities.map(
                (disponibility, index) => (
                  <p
                    className="project-info-small"
                    key={`dispo-${index}`}
                  >
                    {` - Le ${getDateWithShortFormat(
                      disponibility.begin_at
                    )} de ${getTimeWithFormat(
                      disponibility.begin_at
                    )} à ${getTimeWithFormat(
                      disponibility.end_at
                    )}`}
                  </p>
                )
              )
            ) : (
              <p className="project-info-small">
                Aucunes disponibilités n'a été renseignées
              </p>
            )}
          </Col>
        </Row>
        {marker.entity.product_name && (
          <Row className="mt-2 project-info-disponibility_vt">
            <Col>Produit :</Col>
            <Col>
              <p className="project-info-small">{ marker.entity.product_name }</p>
            </Col>
          </Row>
        )}
        {marker.entity.project?.disponibility_vt && (
          <Row className="mt-2 project-info-disponibility_vt">
            <Col>Visite technique :</Col>
            <Col>
              <p className="project-info-small">
                {getShortDateAndTimeWithFormat(
                  marker.entity.project?.disponibility_vt
                )}
              </p>
            </Col>
          </Row>
        )}
        {marker.entity.project?.comment_vt && (
          <Col className="mt-2">
            <Row>Commentaire :</Row>
            <Row>
              <p className="project-info-small white-space-pre-line">
                {marker.entity.project?.comment_vt.replace(
                  "\r\n",
                  "\\r\\n"
                )}
              </p>
            </Row>
          </Col>
        )}
      </div>
      <div>
        {isProjectInTour(waypoints, marker) ?
          <Button
            className='custom-map-buttons delete-button-map'
            onClick={() => deleteWaypoint(marker)}
          >
            <ImgDelete/>
          </Button>
          :
          <Button
            className='custom-map-buttons add-button-map'
            onClick={() => addWaypoint(marker)}
          >
            <ImgAdd/>
          </Button>
        }
      </div>
    </div>
  );
}