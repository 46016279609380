import { MapItem } from "../models/map_item";
import { CustomMarker } from "../pages/Map/Components/CustomMarker";
import { parseCustomerGPSCoord } from "./customer_helper";
import { interventionTypeIcons } from "./planification_helper";

/**
 * Get the coordinates for google direction,
 */
export function getDirectionsRequest(waypoints: { lat: number, lng: number }[]) : google.maps.DirectionsRequest  | undefined {
  if(waypoints.length > 1) {
    return {
      origin: waypoints[0],
      destination: waypoints[waypoints.length - 1],
      travelMode: google.maps.TravelMode.DRIVING,
      waypoints: waypoints.slice(1, waypoints.length - 1).map(item => ({location: new (window as any).google.maps.LatLng(item)}))
    }
  } else {
    return undefined;
  }
}

export function convertMapItemToCustomMarker(map_items: MapItem[]) : CustomMarker[] {
  return map_items.map(map_item => {
    return {
      entity: map_item,
      selected: false,
      icon: map_item.project?.disponibility_vt ? interventionTypeIcons[map_item.intervention_type]["withVt"] : interventionTypeIcons[map_item.intervention_type]["noVt"],
      position: parseCustomerGPSCoord(map_item.client)
    };
  });
}

export function computeTotalDurationDisplay(legs) {
  let totalDuration = 0;

  legs.forEach(leg => totalDuration += leg.duration.value);

  if(totalDuration > 3600)
    return `${Math.round(totalDuration / 3600)} heure(s) ${Math.round((totalDuration % 3600) / 60)} minute(s)`
  else
    return `${Math.round(totalDuration / 60)} minute(s)`
}

export function isProjectInTour(waypoints: CustomMarker[], marker: CustomMarker){
  return !!waypoints.some(waypoint => waypoint.entity.id === marker.entity.id)
}