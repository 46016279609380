import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Button } from "reactstrap";

// Redux
import { connect } from "react-redux";

//Import Date Picker
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

// actions
import { updateProject } from "../../../store/actions";

const AdministrativeFolderCard = (props) => {
  const [receptionScanAt, setReceptionScanAt] = useState(
    props.project.reception_scan_at && new Date(props.project.reception_scan_at)
  );
  const [folderSellPaperReceiptDate, setFolderSellPaperReceiptDate] = useState(
    props.project.folder_sell_paper_receipt_date &&
      new Date(props.project.folder_sell_paper_receipt_date)
  );
  const [pacFileReceiptDate, setPacFileReceiptDate] = useState(
    props.project.pac_file_receipt_date &&
      new Date(props.project.pac_file_receipt_date)
  );
  const [acceptationSendAt, setAcceptationSendAt] = useState(
    props.project.acceptation_send_at &&
      new Date(props.project.acceptation_send_at)
  );
  const [decisionAt, setDecisionAt] = useState(
    props.project.decision_at && new Date(props.project.decision_at)
  );
  const [withdrawalDate, setWithdrawalDate] = useState(
    props.project.withdrawal_date && new Date(props.project.withdrawal_date)
  );

  /**
   * Met à jour le projet avec les nouvelles données
   */
  const updateChanges = () => {
    let values = {};

    if (receptionScanAt) values.reception_scan_at = receptionScanAt;
    else delete values.reception_scan_at;

    if (folderSellPaperReceiptDate)
      values.folder_sell_paper_receipt_date = folderSellPaperReceiptDate;
    else delete values.folder_sell_paper_receipt_date;

    if (pacFileReceiptDate) values.pac_file_receipt_date = pacFileReceiptDate;
    else delete values.pac_file_receipt_date;

    if (acceptationSendAt) values.acceptation_send_at = acceptationSendAt;
    else delete values.acceptation_send_at;

    if (decisionAt) values.decision_at = decisionAt;
    else delete values.decision_at;

    
    if (withdrawalDate) values.withdrawal_date = withdrawalDate;
    else delete values.withdrawal_date;

    props.updateProject(props.project.id, values);
    props.setFlash_message(true);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-3 d-flex justify-content-between">
          <div>Dossier administratif</div>
        </CardTitle>
        <div className="administrative-folder-container mt-3">
          <div className="administrative-folder-row">
            <div className="administrative-folder-item">
              <p>Date de réception scan dossier de financement</p>
              <DatePicker
                isClearable={true}
                className="form-control button-without-style"
                selected={receptionScanAt}
                onChange={(date) => {
                  setReceptionScanAt(date);
                }}
                dateFormat="dd/MM/yyyy"
                locale={fr}
                placeholderText="Date de réception scan"
              />
            </div>
          </div>
          <div className="administrative-folder-row">
            <div className="administrative-folder-item">
              <p>{`Date ${
                props.project?.project_state?.label === "refusal"
                  ? "de refus de financement"
                  : "d'acceptation de financement / date d'acompte perçu"
              }`}</p>
              <DatePicker
                isClearable={true}
                className="form-control button-without-style"
                selected={decisionAt}
                onChange={(date) => {
                  setDecisionAt(date);
                }}
                dateFormat="dd/MM/yyyy"
                locale={fr}
                placeholderText="Date acceptation refus"
              />
            </div>
          </div>
          <div className="administrative-folder-row">
            <div className="administrative-folder-item">
              <p>Date de rétractation</p>
              <DatePicker
                className="form-control button-without-style"
                selected={withdrawalDate}
                onChange={(date) => {
                  setWithdrawalDate(date);
                }}
                dateFormat="dd/MM/yyyy"
                locale={fr}
                placeholderText="Date de rétractation"
              />
            </div>
          </div>
          <div className="administrative-folder-row">
            <div className="administrative-folder-item">
              <p>Date de réception dossier papier</p>
              <DatePicker
                className="form-control button-without-style"
                selected={folderSellPaperReceiptDate}
                onChange={(date) => {
                  setFolderSellPaperReceiptDate(date);
                }}
                dateFormat="dd/MM/yyyy"
                locale={fr}
                placeholderText="Date de réception dossier papier"
              />
            </div>
          </div>
          <div className="administrative-folder-row">
            <div className="administrative-folder-item">
              <p>Date de réception fiche de liaison</p>
              <DatePicker
                className="form-control button-without-style"
                selected={pacFileReceiptDate}
                onChange={(date) => {
                  setPacFileReceiptDate(date);
                }}
                dateFormat="dd/MM/yyyy"
                locale={fr}
                placeholderText="Date réception fiche de liaison"
              />
            </div>
          </div>
          <div className="administrative-folder-row">
            <div className="administrative-folder-item">
              <p>
                Date d'envoi du courrier d'acceptation financement ou acompte
                perçu
              </p>
              <DatePicker
                className="form-control button-without-style"
                selected={acceptationSendAt}
                onChange={(date) => {
                  setAcceptationSendAt(date);
                }}
                dateFormat="dd/MM/yyyy"
                locale={fr}
                placeholderText="Date envoi courrier accept"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="text-center">
            <Button color="none" className="btn-green" onClick={updateChanges}>
              Sauvegarder les modifications
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStatetoProps = (state) => {
  const { project } = state.Projects;

  return { project };
};

export default withRouter(
  connect(mapStatetoProps, { updateProject })(AdministrativeFolderCard)
);
