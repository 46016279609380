import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardFooter } from "reactstrap";

// Redux
import { connect } from "react-redux";

// actions
import { deleteAgency } from "../../store/actions";

const CardAgency = (props) => (
  <React.Fragment>
    <Col xl="4" sm="6">
      <Card>
        <CardBody>
          <Row>
            <Col lg="3">
              {props.agency.url !== "Null" ? (
                <div
                  className="text-lg-center rounded-circle-img"
                  style={{
                    background: `url(${
                      props.agency.url.match(/http/) !== null
                        ? props.agency.url
                        : `${process.env.REACT_APP_API_URL}/files/${props.agency.url}`
                    }) no-repeat center center`,
                    backgroundSize: "cover",
                  }}
                ></div>
              ) : (
                <div className="text-lg-center">
                  <div className="avatar-sm mr-3 mx-lg-auto mb-4 float-left float-lg-none">
                    <span
                      className={
                        "avatar-title rounded-circle bg-soft-blue text-primary font-size-16"
                      }
                    ></span>
                  </div>
                </div>
              )}
            </Col>
            <Col lg="9">
              <h5>{props.agency.name}</h5>
              <p className="mb-1">
                <i className="bx bx-user mr-1"></i> Responsable:{" "}
                <Link to={`collaborator/detail/${props.agency.leader?.id}`}>
                  {props.agency.leader ? props.agency.leader.firstname : ""}{" "}
                  {props.agency.leader ? props.agency.leader.lastname : ""}
                </Link>
              </p>
              <p className="mb-1">
                <i className="bx bx-been-here mr-1"></i> {props.agency.address}
              </p>
              <p className="mb-1">
                <i className="bx bx-phone mr-1"></i> {props.agency.phone}
              </p>
              <p className="mb-1">
                <i className="bx bx-envelope mr-1"></i> {props.agency.email}
              </p>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Row className="container-text-theme-color-2">
            <Col xs="12" className="d-flex justify-content-end">
              <Link
                to={`/agency/edit/${props.agency.id}`}
                className="d-flex align-items-center mr-5"
              >
                <i className="bx bx-pencil mr-1"></i> Modifier
              </Link>
              {/* <Link
                to="#"
                onClick={() => props.deleteAgency(props.agency.id, props.history)}
                className="d-flex align-items-center mr-5"
              >
                <i className="bx bx-trash mr-1"></i> Supprimer
              </Link> */}
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Col>
  </React.Fragment>
);

const mapStatetoProps = (state) => {
  const { agencies, loading } = state.Agencies;
  return { agencies, loading };
};

export default withRouter(
  connect(mapStatetoProps, { deleteAgency })(CardAgency)
);
