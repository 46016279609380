import AgendaItem from "../models/agenda_item";
import Project from "../models/project";

export function projectIsEditable(project: Project) : boolean {
  return ['draft', 'quoted'].indexOf(project.project_state?.label || '') > -1;
}

export function getLastVTAgendaItemForMap(project: Project | null | undefined) : AgendaItem | null {
  return project?.agenda_items?.filter((agenda_item) => 
    agenda_item.reason === 'Visite technique' &&
    !agenda_item.deleted_at && !agenda_item.child_agenda_item &&
    !agenda_item.child_agenda_item_id
    // && agenda_item.date_begin === project.disponibility_vt
  )[0] || null;
}

export function getCommentVtOfProjectForMap(
  project: Project | null | undefined,
  agenda_item?: AgendaItem | null
) : string | null {
  if (!project) return null;
  let commentVt = null;
  
  let last_child_agenda_item = agenda_item || getLastVTAgendaItemForMap(project);
  if (last_child_agenda_item && last_child_agenda_item.comment) {
    commentVt = last_child_agenda_item.comment;
  } else if (project.comment_vt) {
    commentVt = project.comment_vt;
  }

  return commentVt;
}
