import React from 'react';

import {Formik} from 'formik';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {formulasTypes} from "../../../api";

const FormulaTypeFormModal = ({
  isOpen,
  toggleModal,
  setFormulaType
                              }) => {

  const initValues = {
    name: ''
  }

  const handleSubmitType = (values) => {
    console.log(values)
    formulasTypes.create(values.name)
      .then(r => {
        setFormulaType(r.data.id);
        toggleModal();
      })
      .catch(err => console.error(err));
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
    >
      <Formik
        initialValues={initValues}
        onSubmit={handleSubmitType}
      >
        {({handleSubmit, handleChange, values}) => (
          <div>
            <ModalHeader toggle={() => toggleModal()}>
              Ajouter un type de formule
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for='name'>Libellé du type de formule</Label>
                <Input
                  name='name'
                  id='name'
                  onChange={handleChange}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => toggleModal()}>Annuler</Button>
              <Button onClick={() => handleSubmit(values)} className='btn-green'>Ajouter</Button>
            </ModalFooter>
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default FormulaTypeFormModal;