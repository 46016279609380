import * as Yup from "yup";
import { PHONE_REGEX } from "../../../helpers/validator";

export const updateValidationSchema = Yup.object().shape({
  email: Yup.string().notRequired(),

  firstname: Yup.mixed().notRequired(),

  lastname: Yup.mixed().notRequired(),

  password: Yup.string().min(6, "Mot de passe trop court").notRequired(),

  password_confirmation: Yup.string()
    .min(6, "Mot de passe trop court")
    .oneOf([Yup.ref("password")], "Les mots de passes ne correspondent pas")
    .notRequired(),

  role: Yup.string().notRequired(),

  entryDate: Yup.mixed().notRequired(),

  birthDate: Yup.mixed().notRequired(),

  agencies: Yup.string().notRequired(),

  phone: Yup.string()
    .test("format", "Format invalide", (val) => {
      return !isNaN(val);
    })
    .trim()
    .matches(PHONE_REGEX, "Veuillez saisir un numéro de fixe valide")
    .notRequired(),

  address: Yup.mixed().notRequired(),

  contractType: Yup.mixed().notRequired(),

  comment: Yup.mixed().notRequired(),
});
