import { Link, withRouter } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";

// Redux
import { connect } from "react-redux";

import BadgeState from "../badge-state";

// Bootstrap table
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { parseCustomerName } from "../../../helpers/customer_helper";
import { parseDate } from "../../../helpers/subscriptions_helpers";

const GeneralTable = (props) => {
  const columns = [
    {
      dataField: "id",
      text: "Référence",
      formatter: (cell, row) => {
        return <Link to={`/projects-overview/${row.id}`}>{row.id.toString().padStart(8, "0")}</Link>;
      },
      sort: true,
    },
    {
      dataField: 'sell_date',
      text: 'Date de vente',
      formatter: cell => cell ? parseDate(cell) : <div className='d-flex justify-content-center'>-</div>,
      sort: true,
    },
    {
      dataField: "client",
      text: "Client",
      formatter: (cell, row) => {
        return row.client ? (
          <Link to={`/customer/detail/${row.client.id}`}>
            <ul className="d-flex justify-content-start list-inline mb-0">
              <li className="list-inline-item mr-3">
                <i className="bx bx-user mr-1"/>
                <span className="font-size-12">
                  {parseCustomerName(row.client)}
                </span>
              </li>
              <li className="list-inline-item mr-3" id={`map-${row.id}`}>
                <i className="bx bx-map mr-1"/>
                <UncontrolledTooltip placement="top" target={`map-${row.id}`}>
                  {row.client.city} ({row.client.zip_code.charAt(0)}
                  {row.client.zip_code.charAt(1)})
                </UncontrolledTooltip>
              </li>
            </ul>
          </Link>
        ) : null;
      },
      sort: true,
      style: {
        minWidth: "250px",
      }
    },
    {
      dataField: "address",
      text: "Adresse",
      formatter: (cell, row) => {
        return `${row.client.address}, ${row.client.zip_code} ${row.client.city}`;
      }
    },
    {
      dataField: "zip_code",
      text: "Code postal",
      sort: true,
      formatter: (cell, row) => {
        return `${row.client.zip_code}`;
      },
    },
    {
      dataField: "state",
      text: "État",
      formatter: (cell, row) => {
        return <BadgeState state={row.project_state} />;
      },
      sort: true,
      style: {
        maxWidth: "150px",
      }
    },
    {
      dataField: "users",
      text: "Commercial",
      formatter: (cell, row) =>
        row.users
          ? row.users.map((user, key) => (
              <Link
                key={`user_${key}`}
                to={`/collaborator/detail/${user.id}`}
                className="team-member d-inline-block"
                id={"member" + user.id}
              >
                <div className="d-flex justify-content-start align-items-center">
                  <div className="avatar-xs">
                    <span
                      className={
                        "avatar-title rounded-circle bg-soft-" +
                        user.color +
                        " text-" +
                        user.color +
                        " font-size-16"
                      }
                      style={{ width: "32px" }}
                    >
                      {`${user.firstname.charAt(0)}${user.lastname.charAt(0)}`}
                    </span>
                  </div>
                  <div className="ml-2">
                    <span
                      className={
                        "bg-soft-" +
                        user.color +
                        " text-" +
                        user.color +
                        " font-size-12"
                      }
                    >
                      {`${user.firstname} ${user.lastname}`}
                    </span>
                  </div>
                </div>
              </Link>
            ))
          : null,
      sort: true,
    },
    {
      dataField: "actions",
      text: "",
      formatter: (cell, row) => (
        <UncontrolledDropdown>
          <DropdownToggle tag={"a"} className={"bg-transparent px-3 py-2 d-inline-block"}>
            <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
          </DropdownToggle>
          <DropdownMenu right className="bordered important-default-colors">
            <DropdownItem onClick={() => { props.history.push(`/projects-overview/${row.id}`) }}>Voir</DropdownItem>
            <DropdownItem onClick={() => { window.open(`/projects-overview/${row.id}`, '_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
      classes: "text-right"
    },
  ];

  return (
    <div className="general-table-container">
      <BootstrapTable
        keyField="id"
        data={props.projects}
        columns={columns}
        classes="table-centered"
        defaultSortDirection="desc"
        sort={{ dataField: props.sort.dataField || 'id', order: props.sort.order }}
        remote={{ pagination: true, sort: true }}
        onTableChange={props.onTableChange}
        pagination={paginationFactory({
          page: props.pagination.current_page,
          totalSize: props.pagination.total_count,
          sizePerPage: props.pagination.current_page_size,
          sizePerPageList: [
            { text: "Afficher 10 éléments", value: 10 },
            { text: "Afficher 25 éléments", value: 25 },
            { text: "Afficher 50 éléments", value: 50 },
            { text: "Afficher 75 éléments", value: 75 },
            { text: "Afficher 100 éléments", value: 100 },
          ],
        })}
      />
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { projects, pagination } = state.Projects;

  return { projects, pagination };
};

export default withRouter(connect(mapStatetoProps, {})(GeneralTable));
