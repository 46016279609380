import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col, CardBody, CardTitle, Card } from "reactstrap";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import GlobalLoader from "../../../components/Common/GlobalLoader";

import MessagesList from "./Messages/MessagesList";

import { getNotification } from "../../../store/actions";

const NotificationDetail = (props) => {
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );

  // Récupérer la relance à mettre à jour
  useEffect(() => {
    props.getNotification.call(undefined,parseInt(window.location.pathname.split("/")[3], 10));
  }, [props.getNotification]);

  return (
    <React.Fragment>
      {props.loading ? (
        <GlobalLoader />
      ) : (
        <div className="page-content">
          <Container fluid className="notifications-container">
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Relances"
              breadcrumbItem="Détail de la relance"
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">
                      <Row className="d-flex justify-content-between">
                        <Col xs="6">Détail de la relance</Col>
                        <Col xs="6 text-right">
                          <span
                            className="btn btn-green"
                            onClick={() => setShowMessageForm(!showMessageForm)}
                          >
                            <i className="fas fa-pencil-alt mr-2"></i>Mettre à jour
                          </span>
                        </Col>
                      </Row>
                    </CardTitle>
                    <Row>
                      <Col xs="8">
                        <Row>
                          <Col xs="6">
                            <p className="notification-detail-title">
                              Client :
                            </p>
                            <span>
                              {props.notification.client
                                ? 
                                <Link to={`/customer/detail/${props.notification.client.id}`}>{
                                  props.notification.client.client_type === "Professionnel"
                                  ? props.notification.client.company_name
                                  : `${props.notification.client.civility} ${props.notification.client.lastname} ${props.notification.client.firstname}`
                                }</Link>  
                                : "NR"}
                            </span>
                          </Col>
                          <Col xs="6">
                            <p className="notification-detail-title">
                              Collaborateur :
                            </p>
                            <span>
                              {props.notification.user_to &&
                              props.notification.user_from
                                ? props.notification.user_to.id ===
                                  currentUser?.id
                                  ? <Link to={`/collaborator/detail/${props.notification.user_from.id}`}>{props.notification.user_from.firstname} {props.notification.user_from.lastname}</Link>
                                  : <Link to={`/collaborator/detail/${props.notification.user_to.id}`}>{props.notification.user_to.firstname} {props.notification.user_to.lastname}</Link>
                                : null}
                            </span>
                          </Col>
                          <Col xs="6">
                            <p className="notification-detail-title">
                              Projet :
                            </p>
                            <span>
                              {props.notification.project
                                ? 
                                  <Link to={`/projects-overview/${props.notification.project.id}`}>
                                    {props.notification.project.id
                                    .toString()
                                    .padStart(8, "0")}
                                  </Link>
                                : "NR"}
                            </span>
                          </Col>
                          <Col xs="6">
                            <p className="notification-detail-title">
                              Priorité :
                            </p>
                            <span>{props.notification.priority || "NR"}</span>
                          </Col>
                          <Col xs="6">
                            <p className="notification-detail-title">Titre :</p>
                            <span>{props.notification.title || "NR"}</span>
                          </Col>
                          <Col xs="6">
                            <p className="notification-detail-title">État :</p>
                            <span>{props.notification.state || "NR"}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <MessagesList
                      notification={props.notification}
                      showMessageForm={showMessageForm}
                      setShowMessageForm={setShowMessageForm}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { notification } = state.Notifications;
  return { notification };
};

export default withRouter(
  connect(mapStatetoProps, {
    getNotification,
  })(NotificationDetail)
);
