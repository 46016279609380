import { Field, Form, Formik } from 'formik';
import { History } from "history";
import React from 'react';
import { Button, Card, CardBody, Col, Input, Label, NavItem, NavLink, Row } from 'reactstrap';
import ProductSelector from '../../../../components/Common/ProductSelector';
import { offers as offersApi } from "../../../../api";
import FormikAutocomplete from "../../../../components/Common/AsyncAutoComplete2";
import DatePicker from '../../../../components/Common/DatePicker';
import { ErrorMessageCustom } from '../../../../components/Common/ErrorMessageCustom';
import { addFlashMessage, ImportantEnum } from '../../../../components/Common/FlashMessage';
import { promiseCategoriesOptions, promiseSubCategoriesOptions } from '../../../../helpers/autocomplete_promise_options';
import { parseApiError } from '../../../../helpers/helper';
import Offer, { computePromotableLabel, OfferTypeEnum, PromotableTypeEnum } from '../../../../models/offer';

enum OfferFormTab {
  INFO = 'info',
  PRODUCTS = 'products',
}

export interface OfferFormProps {
  offer: Offer,
  validationSchema: any,
  history: History
}

const OfferForm = ({
  offer,
  validationSchema,
  history
}: OfferFormProps) => {
  const [tab, setTab] = React.useState(OfferFormTab.INFO);
  return (
    <Formik
      initialValues={{
        ...offer,
        promotable_type: offer.promotable_type || PromotableTypeEnum.ALL
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(offer, actions) => {
        offersApi.upsert({
          ...offer,
          amount: !offer.amount ? undefined : offer.amount * 100
        }).then((data) => {
          actions.setSubmitting(false);
          addFlashMessage(ImportantEnum.SUCCESS, offer.id ? 'La promotion a bien été modifiée' : 'La promotion code a bien été créée');
          history.goBack();
        }).catch(({ response }) => {
          parseApiError(response, actions.setFieldError);
          actions.setSubmitting(false);
        })
      }}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => (
        <Form>
          <Card>
            {values.promotable_type === PromotableTypeEnum.PRODUCT_GROUP && (
              <ul className="nav nav-tabs nav-tabs-custom mb-3">
                <NavItem>
                  <NavLink
                    className={tab === OfferFormTab.INFO ? "active" : ""}
                    onClick={() => setTab(OfferFormTab.INFO)}
                  >
                    Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={tab === OfferFormTab.PRODUCTS ? "active" : ""}
                    onClick={() => setTab(OfferFormTab.PRODUCTS)}
                  >
                    Produits
                  </NavLink>
                </NavItem>
              </ul>
            )}
            {
              tab === OfferFormTab.INFO && (
                <CardBody>
                  <Row>
                    <Col xs="3">
                      <Label for="name">Nom de la promotion</Label>
                    </Col>
                    <Col xs="9">
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        invalid={!!errors.name && !!touched.name}
                        className="form-control"
                        tag={Field}
                        placeholder="Entrer un nom de promotion"
                      />
                      <ErrorMessageCustom name="name" />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="3">
                      <Label for="start_at">Date de début de la promotion</Label>
                    </Col>
                    <Col xs="9">
                      <DatePicker
                        className="form-control button-without-style"
                        name="start_at"
                        value={values.start_at}
                        invalid={!!errors.start_at && !!touched.start_at}
                        onChange={setFieldValue}
                      />
                      <ErrorMessageCustom name="start_at" />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="3">
                      <Label for="end_at">Date de fin de la promotion</Label>
                    </Col>
                    <Col xs="9">
                      <DatePicker
                        className="form-control button-without-style"
                        name="end_at"
                        value={values.end_at}
                        invalid={!!errors.end_at && !!touched.end_at}
                        onChange={setFieldValue}
                      />
                      <ErrorMessageCustom name="end_at" />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="3">
                      <Label for="prefix">Type de promotion</Label>
                    </Col>
                    <Col xs="9">
                      <FormikAutocomplete
                        name="offer_type"
                        id="offer_type"
                        invalid={!!errors.offer_type && !!touched.offer_type}
                        className="w-100 flex-unset"
                        loadOptions={Object.values(OfferTypeEnum).map(e => { return { value: e, label: e === 'value' ? 'Montant' : 'Pourcentage' } })}
                      />
                      <ErrorMessageCustom name="offer_type" />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="3">
                      <Label for="amount">{values.offer_type === 'value' ? 'Montant' : 'Pourcentage'}</Label>
                    </Col>
                    <Col xs="9">
                      <Input
                        id="amount"
                        name="amount"
                        type="text"
                        invalid={!!errors.amount && !!touched.amount}
                        className="form-control"
                        tag={Field}
                        placeholder="Entrer une valeur"
                      />
                      <ErrorMessageCustom name="amount" />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="3">
                      <Label for="prefix">S'applique sur</Label>
                    </Col>
                    <Col xs="9">
                      <FormikAutocomplete
                        name="promotable_type"
                        id="promotable_type"
                        invalid={!!errors.promotable_type && !!touched.promotable_type}
                        className="w-100 flex-unset"
                        loadOptions={Object.values(PromotableTypeEnum).map(e => { return { value: e, label: computePromotableLabel(e) } })}
                      />
                      <ErrorMessageCustom name="promotable_type" />
                    </Col>
                  </Row>
                  {values.promotable_type === PromotableTypeEnum.PRODUCT_GROUP && <Row className="mt-4">
                    <Col xs="3">
                      <Label for="prefix">S'applique sur</Label>
                    </Col>
                    <Col xs="9">
                      <Button onClick={() => setTab(OfferFormTab.PRODUCTS)}>Accéder à l'onglet Produits</Button>
                      <ErrorMessageCustom name="promotable_entity_ids" />
                    </Col>
                  </Row>}
                  {values.promotable_type === PromotableTypeEnum.CATEGORY_GROUP && <Row className="mt-4">
                    <Col xs="3">
                      <Label for="prefix">S'applique sur</Label>
                    </Col>
                    <Col xs="9">
                      <FormikAutocomplete
                        name="promotable_entity_ids"
                        id="promotable_entity_ids"
                        invalid={!!errors.promotable_entity_ids && !!touched.promotable_entity_ids}
                        className="w-100 flex-unset"
                        multiple={true}
                        isSearchable={false}
                        loadOptions={promiseCategoriesOptions}
                      />
                      <ErrorMessageCustom name="promotable_entity_ids" />
                    </Col>
                  </Row>}
                  {values.promotable_type === PromotableTypeEnum.SUB_CATEGORY_GROUP && <Row className="mt-4">
                    <Col xs="3">
                      <Label for="prefix">S'applique sur</Label>
                    </Col>
                    <Col xs="9">
                      <FormikAutocomplete
                        name="promotable_entity_ids"
                        id="promotable_entity_ids"
                        invalid={!!errors.promotable_entity_ids && !!touched.promotable_entity_ids}
                        className="w-100 flex-unset"
                        multiple={true}
                        isSearchable={false}
                        loadOptions={(q)=>promiseSubCategoriesOptions(q)}
                      />
                      <ErrorMessageCustom name="promotable_entity_ids" />
                    </Col>
                  </Row>}
                  <Row className="mt-4">
                    <Col xs="12" className="text-center">
                      <Button
                        color="none"
                        className="btn-green"
                        onClick={() => handleSubmit(values as any)}
                      >
                        {offer?.id ? 'Modifier la promotion' : 'Ajouter la promotion'}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>)}
              {
                tab === OfferFormTab.PRODUCTS && (
                  <CardBody>
                    <ProductSelector name="promotable_entity_ids" setFieldValue={setFieldValue} values={values.promotable_entity_ids || []}/>
                  </CardBody>
                )
              }
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default OfferForm;