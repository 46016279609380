import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  CREATE_INVOICE_FROM_ORDER,
  CREATE_INVOICE_FROM_ORDER_SUCCESS,
  CREATE_REFUND_FROM_ORDER,
  CREATE_REFUND_FROM_ORDER_SUCCESS,
  GET_GENERATE_INVOICE,
  GET_GENERATE_INVOICE_SUCCESS,
  GET_GENERATE_REFUND,
  GET_GENERATE_REFUND_SUCCESS,
  GET_GENERATE_QUOTE,
  GET_GENERATE_QUOTE_SUCCESS,
  SHOW_INVOICE,
  SHOW_INVOICE_SUCCESS,
  SHOW_REFUND,
  SHOW_REFUND_SUCCESS,
  ORDERS_API_ERROR,
} from "./actionTypes";

const initialState = {
  orders: [],
  order: false,
  invoice_order: false,
  file_url: false,
  error: false,
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const orders = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ORDERS_SUCCESS:
      state = {
        ...state,
        orders: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        invoice_order: false,
        order: false,
        file_url: false,
        loading: false,
        error: false,
      };
      break;
    case GET_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ORDER_SUCCESS:
      state = {
        ...state,
        order: action.payload.data,
        invoice_order: false,
        loading: false,
        error: false,
      };
      break;
    case CREATE_ORDER:
      state = {
        ...state,
        loading: true,
      };
      console.log("##### [REDUCER] CREATE ORDER REQUEST ", state);
      break;
    case CREATE_ORDER_SUCCESS:
      state = {
        ...state,
        order: action.payload.data,
        invoice_order: false,
        loading: false,
        error: false,
      };
      console.log("##### [REDUCER] CREATE ORDER RESPONSE ", state);
      break;
    case UPDATE_ORDER:
      state = {
        ...state,
        loading: true,
      };
      console.log("##### [REDUCER] UPDATE ORDER REQUEST ", state);
      break;
    case UPDATE_ORDER_SUCCESS:
      state = {
        ...state,
        invoice_order: false,
        loading: false,
        error: false,
      };
      console.log("##### [REDUCER] UPDATE ORDER RESPONSE ", state);
      break;
    case DELETE_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_ORDER_SUCCESS:
      state = {
        ...state,
        orders:
          state.orders === undefined || state.orders === null
            ? []
            : state.orders.filter((e) => e.id !== action.payload.data.id),
        loading: false,
        error: false,
      };
      break;
    case CREATE_INVOICE_FROM_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_INVOICE_FROM_ORDER_SUCCESS:
      state = {
        ...state,
        order: action.payload.order.data,
        file_url: action.payload.file_url.data,
        loading: false,
        error: false,
      };
      break;
    case CREATE_REFUND_FROM_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_REFUND_FROM_ORDER_SUCCESS:
      state = {
        ...state,
        invoice_order: false,
        file_url: action.payload.file_url.data,
        loading: false,
        error: false,
      };
      break;
    case GET_GENERATE_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_GENERATE_INVOICE_SUCCESS:
      state = {
        ...state,
        file_url: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case GET_GENERATE_REFUND:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_GENERATE_REFUND_SUCCESS:
      state = {
        ...state,
        file_url: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case GET_GENERATE_QUOTE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_GENERATE_QUOTE_SUCCESS:
      state = {
        ...state,
        file_url: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case SHOW_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SHOW_INVOICE_SUCCESS:
      state = {
        ...state,
        invoice_order: action.payload.data.order,
        loading: false,
        error: false,
      };
      break;
    case SHOW_REFUND:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SHOW_REFUND_SUCCESS:
      state = {
        ...state,
        invoice_order: action.payload.data.order,
        loading: false,
        error: false,
      };
      break;
    case ORDERS_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default orders;
