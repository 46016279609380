import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_AGENCIES,
  GET_AGENCY,
  CREATE_AGENCY,
  UPDATE_AGENCY,
  DELETE_AGENCY,
} from "./actionTypes";
import {
  getAgenciesSuccess,
  getAgencySuccess,
  createAgencySuccess,
  updateAgencySuccess,
  deleteAgencySuccess,
  agencyApiError,
} from "./actions";

import { agencies } from "../../api";

function* getAgencies({ payload: { query } }) {
  try {
    const response = yield call(agencies.getAll, query);
    yield put(getAgenciesSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          agencyApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des agences"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(agencyApiError(error.response.data.message));
      }
    } else {
      yield put(agencyApiError(error.message));
    }
  }
}

function* getAgency({ payload: { id } }) {
  try {
    const response = yield call(agencies.getOne, id);
    yield put(getAgencySuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          agencyApiError("Vous n'êtes pas autorisé à accéder à cette agence")
        );
      } else if (error.response.status === 404) {
        yield put(agencyApiError("L'agence n'a pas été trouvée"));
      } else if (error.response.data !== undefined) {
        yield put(agencyApiError(error.response.data.message));
      }
    } else {
      yield put(agencyApiError(error.message));
    }
  }
}

function* createAgency({ payload: { agency, history } }) {
  try {
    const response = yield call(agencies.create, agency);
    yield put(createAgencySuccess(response));
    history.push("/agencies?success_add=1");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          agencyApiError("Vous n'êtes pas autorisé à créer une agence")
        );
      } else if (
        error.response.status === 400 &&
        error.response.data.errors.length > 0
      ) {
        if (
          error.response.data.errors[0].detail.name &&
          error.response.data.errors[0].detail.name[0] === "can't be blank"
        ) {
          yield put(agencyApiError("Le nom doit être présent"));
        }
      } else if (error.response.data !== undefined) {
        yield put(agencyApiError(error.response.data.message));
      }
    } else {
      yield put(agencyApiError(error.message));
    }
  }
}

function* updateAgency({ payload: { id, agency, history } }) {
  try {
    const response = yield call(agencies.update, id, agency);
    yield put(updateAgencySuccess(response));
    history.push("/agencies?success_update=1");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          agencyApiError("Vous n'êtes pas autorisé à modifier cette agence")
        );
      } else if (error.response.status === 404) {
        yield put(agencyApiError("L'agence n'a pas été trouvée"));
      } else if (
        error.response.status === 400 &&
        error.response.data.errors.length > 0
      ) {
        if (
          error.response.data.errors[0].detail.name &&
          error.response.data.errors[0].detail.name[0] === "can't be blank"
        ) {
          yield put(agencyApiError("Le nom doit être présent"));
        }
      } else if (error.response.data !== undefined) {
        yield put(agencyApiError(error.response.data.message));
      }
    } else {
      yield put(agencyApiError(error.message));
    }
  }
}

function* deleteAgency({ payload: { id, history } }) {
  try {
    const response = yield call(agencies.delete, id);
    yield put(deleteAgencySuccess(response));
    history.push("/agencies?success_delete=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        agencyApiError("Vous n'êtes pas autorisé à supprimer cette agence")
      );
    } else if (error.response.data !== undefined) {
      yield put(agencyApiError(error.response.data.message));
    } else {
      yield put(agencyApiError(error.message));
    }
  }
}

export function* watchGetAgencies() {
  yield takeEvery(GET_AGENCIES, getAgencies);
}

export function* watchGetAgency() {
  yield takeEvery(GET_AGENCY, getAgency);
}

export function* watchCreateAgency() {
  yield takeEvery(CREATE_AGENCY, createAgency);
}

export function* watchUpdateAgency() {
  yield takeEvery(UPDATE_AGENCY, updateAgency);
}

export function* watchDeleteAgency() {
  yield takeEvery(DELETE_AGENCY, deleteAgency);
}

function* agenciesSaga() {
  yield all([
    fork(watchGetAgencies),
    fork(watchGetAgency),
    fork(watchCreateAgency),
    fork(watchUpdateAgency),
    fork(watchDeleteAgency),
  ]);
}

export default agenciesSaga;
