import {
  GET_SCALES,
  GET_SCALES_SUCCESS,
  GET_SCALE,
  GET_SCALE_SUCCESS,
  CREATE_SCALE,
  CREATE_SCALE_SUCCESS,
  UPDATE_SCALE,
  UPDATE_SCALE_SUCCESS,
  DELETE_SCALE,
  DELETE_SCALE_SUCCESS,
  SCALES_API_ERROR,
} from "./actionTypes";

const initialState = {
  scales: [],
  scale: false,
  error: false,
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const scales = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCALES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SCALES_SUCCESS:
      state = {
        ...state,
        scales: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        loading: false,
        error: false,
      };
      break;
    case GET_SCALE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SCALE_SUCCESS:
      state = {
        ...state,
        scale: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case CREATE_SCALE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_SCALE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case UPDATE_SCALE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_SCALE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case DELETE_SCALE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_SCALE_SUCCESS:
      state = {
        ...state,
        scales:
          state.scales === undefined || state.scales === null
            ? []
            : state.scales.filter((e) => e.id !== action.payload.data.id),
        loading: false,
        error: false,
      };
      break;
    case SCALES_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default scales;
