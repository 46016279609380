import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";


/**
 * @description a date component
 * 
 * @param name name of the date field
 * @param label label in front of the date picker
 * @param placeholder
 * @param {Date} value
 * @param handleChange
 */
const InputComponent = ({
    isDisabled,
    name,
    label,
    placeholder,
    value,
    handleChange,
    className = "",
    classNameLabel = "",
    classNameDate = "",
}) => {

    return (
        <div className={'row form-group mb-4 ' + className}>
            <label className={"col-form-label " + classNameLabel} htmlFor={name}>{label}</label>
            <div className={classNameDate}>
                <DatePicker
                    id={name}
                    name={name}
                    placeholderText={placeholder}
                    dateFormat="dd/MM/yyyy"
                    selected={value}
                    value={value}
                    locale={fr}
                    className={"form-control button-without-style"}
                    onChange={(val) => {handleChange(val);}}
                    disabled={isDisabled}
                />
            </div>
        </div>
    ); 
}

export default InputComponent;