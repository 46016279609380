import { withRouter } from "react-router-dom";
import { Row, Col, CardBody, Card, Button, Label, Input } from "reactstrap";

// Redux
import { connect } from "react-redux";

import { Formik, Field, Form } from 'formik';

import { FormikAutocompleteUnselectable } from "../../../components/Common/AsyncAutoComplete2";

// Helpers
import {promiseCommercialOptions} from "../../../helpers/autocomplete_promise_options";

// Actions
import { getProjectStates } from "../../../store/actions";

const PrimeTableFilters = (props) => {
  const {
    filters,
    setFilters,
  } = props;

  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      onSubmit={(values) => {
        setFilters({
          ...filters,
          user_id: values.user_id,
          customer: values.customer,
          project_state_id: values.project_state_id
        })
      }}
    >
      {({ values, setFieldValue, handleSubmit}) => (
        <Form>
          <Card>
            <CardBody>
              <Row>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label for="user_id">Commercial</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        name="user_id"
                        id="user_id"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucun commerciaux disponible"}
                        placeholder="Choisir un commercial..."
                        loadOptions={promiseCommercialOptions}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label for="customer">Client</Label>
                    </Col>
                    <Col xs="12">
                      <Input
                        id="customer"
                        name="customer"
                        type="text"
                        className="form-control"
                        tag={Field}
                        placeholder="Entrer le nom d'un client"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label for="project_state_id">État</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        name="project_state_id"
                        id="project_state_id"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucun état disponible"}
                        placeholder="Choisir un état..."
                        loadOptions={props.projectStates.map((state, index) => ({
                          value: state.id,
                          label: state.name,
                        }))}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="d-flex justify-content-center mt-3">
                  <Button
                    color="none"
                    className="btn-green"
                    onClick={() => handleSubmit(values)}
                  >
                    Appliquer les filtres
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

const mapStatetoProps = (state) => {
  const { projectStates } = state.ProjectStates;

  return { projectStates };
};

export default withRouter(
  connect(mapStatetoProps, { getProjectStates })(PrimeTableFilters)
);
