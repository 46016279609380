import { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Row, Col, Button } from 'reactstrap';
import { promiseProductsOptions, promiseProductsOptionsFromIds } from '../../helpers/autocomplete_promise_options_api_v2';
import LoaderLayoutPulse from './LoaderLayoutPulse';

const ProductsSelector = ({
  selectedProducts,
  setSelectedProducts,
  validateProducts
}: any) => {
  const [productSearch, setProductSearch] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let response = productSearch === '' ? [] : await promiseProductsOptions(productSearch);
      response = response.filter((product:any) => !validateProducts.find((p:any) => p.value === product.value));
      setProducts(response);
      setLoading(false);
    })();
  }, [productSearch, validateProducts]);

  return (
    <Row className="w-100">
      <Col lg="12" sm="12">
        <Row>
          <Col xl="11" lg="11" md="11" sm="11" className='p-0'>
            <input
              type="text"
              id="product_query"
              className="form-control"
              placeholder="Rechercher un produits"
              defaultValue={productSearch}
            ></input>
          </Col>
          <Col xl="1" lg="1" md="1" sm="1" className="d-flex justify-content-center align-items-center">
            {loading && <LoaderLayoutPulse />}
            {!loading && (
              <span
                onClick={() => {
                  setProductSearch((document.getElementById('product_query') as HTMLInputElement)?.value);
                }}
                className="w-100 bx bx-search pointer"
                style={{fontSize: '1.5em'}}
              />
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="12" sm="12" className='p-0'>
            <BootstrapTable
              keyField="value"
              data={products}
              columns={[
                {
                  dataField: "label",
                  text: "Titre"
                }
              ]}
              selectRow={{
                mode: 'checkbox',
                selected: selectedProducts.map((product:any) => product.value),
                clickToSelect: true,
                onSelect: (row, isSelect, rowIndex, e) => {
                  if (isSelect) {
                    setSelectedProducts([...selectedProducts, row]);
                  } else {
                    setSelectedProducts(selectedProducts.filter((p:any) => p.value !== row.value));
                  }
                },
                onSelectAll: (isSelect, rows, e) => {
                  if (isSelect) {
                    setSelectedProducts(rows);
                  } else {
                    setSelectedProducts([]);
                  }
                }
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const SelectedProducts = ({ selectedProducts, deleteAll, deleteOne }: any) => {
  return (
    <Row className="w-100">
      <Col lg="12" sm="12">
        <Row>
          <Col xl="6" lg="12" md="12" sm="12" style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            {selectedProducts.length} produits sélectionnés
          </Col>
          <Col xl="6" lg="12" md="12" sm="12">
            <Button
              onClick={deleteAll}
              color="none"
              className="btn-green w-100"
            >
              Effacer la sélection
            </Button>
          </Col>
        </Row>
        <BootstrapTable
          keyField="value"
          classes={'mt-3'}
          data={selectedProducts}
          columns={[
            {
              dataField: "label",
              text: "Titre"
            },
            {
              dataField: "action",
              text: '',
              formatter: (cell, row) => {
                return (
                  <i
                    onClick={() => deleteOne(row)}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Supprimer le produit"
                    className={`btn-icon-delete fas fa-times`}
                  />
                )
              },
            }
          ]}
        />
      </Col>
    </Row>
  );
};

const ProductSelectorContainer = ({
  name,
  values,
  setFieldValue
}: any) => {
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [validateProducts, setValidateProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFieldValue(name, validateProducts.map(product => product.value));
  }, [validateProducts, name, setFieldValue]);


  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await promiseProductsOptionsFromIds(values);
      setValidateProducts(response);
      setLoading(false);
    })();
  }, [values]);

  function deleteOne(row:any) {
    setValidateProducts(validateProducts.filter((p:any) => p.value !== row.value));
  };

  function addAll() {
    let newSelectedProducts = selectedProducts.filter(product => !validateProducts.find(p => p.value === product.value));
    // TODO USE React.useCallback instead of time: Date.now()
    setValidateProducts([...validateProducts, ...newSelectedProducts].map(product => ({ ...product, time: Date.now() })));
    setSelectedProducts([]);
  }

  return (
    <Row>
      <Col lg="5" sm="5">
        <ProductsSelector selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} validateProducts={validateProducts} />
      </Col>
      <Col lg="2" sm="2" className="mt-5">
        <Button
          onClick={addAll}
          color="none"
          className="btn-green"
        >
          Ajouter la selection &gt;&gt;
        </Button>
      </Col>
      <Col lg="5" sm="5">
        <SelectedProducts selectedProducts={validateProducts} deleteAll={() => setValidateProducts([])} deleteOne={deleteOne} />
        {loading && <LoaderLayoutPulse />}
      </Col>
    </Row>
  )
};

export default ProductSelectorContainer;