import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  USERS_API_ERROR,
} from "./actionTypes";

export const getUsers = (query) => {
  return {
    type: GET_USERS,
    payload: { query },
  };
};

export const getUsersSuccess = (users) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: users,
  };
};

export const getUser = (id) => {
  return {
    type: GET_USER,
    payload: { id },
  };
};

export const getUserSuccess = (user) => {
  return {
    type: GET_USER_SUCCESS,
    payload: user,
  };
};

export const createUser = (user, history) => {
  return {
    type: CREATE_USER,
    payload: { user, history },
  };
};

export const createUserSuccess = (user) => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: user,
  };
};

export const updateUser = (id, user, history) => {
  return {
    type: UPDATE_USER,
    payload: { id, user, history },
  };
};

export const updateUserSuccess = (user) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: user,
  };
};

export const deleteUser = (id, history) => {
  return {
    type: DELETE_USER,
    payload: { id, history },
  };
};

export const deleteUserSuccess = (user) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: user,
  };
};

export const userApiError = (error) => {
  return {
    type: USERS_API_ERROR,
    payload: error,
  };
};
