import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import AgencyForm from "./FormComponent/AgencyForm";

// Validation Schema
import { updateValidationSchema } from "./FormValidationSchemas/updateValidationSchema";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {
  getAgency,
  updateAgency,
  agencyApiError,
  getUsers,
} from "../../store/actions";

const AgencyEdit = (props) => {
  useEffect(() => {
    props.getAgency.call(undefined,parseInt(window.location.pathname.split("/")[3], 10));
  }, [props.getAgency]);

  useEffect(() => {
    props.getUsers.call(undefined,{ agency_id: window.location.pathname.split("/")[3] });
  }, [props.getUsers]);

  const initValues = props.agency
    ? {
        name: props.agency.name,
        url: props.agency.url,
        leader: props.agency.leader === null ? null : props.agency.leader.id,
        address: props.agency.address,
        email: props.agency.email,
        phone: props.agency.phone,
        comptable_account: props.agency.comptable_account,
      }
    : {
        name: "",
        url: "",
        leader: "",
        address: "",
        phone: "",
        email: "",
        comptable_account: "",
      };

  const handleSubmit = (values) => {
    props.updateAgency(
      parseInt(window.location.pathname.split("/")[3], 10),
      {
        name: values.name,
        url: values.url,
        leader: values.leader,
        address: values.address,
        phone: values.phone,
        email: values.email,
        comptable_account: values.comptable_account,
      },
      props.history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Agences" breadcrumbItem="Modifier une agence" />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Modifier une agence</CardTitle>
                {props.loading ? null : !props.agency ? null : (
                  <AgencyForm
                    initValues={initValues}
                    validationSchema={updateValidationSchema}
                    handleSubmit={handleSubmit}
                    users={props.users}
                    agency={props.agency}
                    error={props.error}
                    isUpdate={true}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { agency, loading, error } = state.Agencies;
  const { users } = state.Users;
  return { agency, loading, error, users };
};

export default withRouter(
  connect(mapStatetoProps, {
    getAgency,
    getUsers,
    updateAgency,
    agencyApiError,
  })(AgencyEdit)
);
