import { parsePagination } from "../../helpers/pagination_helper";
import {
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT,
  GET_PROJECT_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  DELETE_PROJECT,
  DELETE_TECHNICAL_VISIT_PROJECT,
  DELETE_PROJECT_SUCCESS,
  PROJECTS_API_ERROR,
  EXPORT_PROJECTS,
  EXPORT_PROJECTS_SUCCESS,
} from "./actionTypes";

const initialState = {
  projects: [],
  project: false,
  error: false,
  success: false,
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const projects = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PROJECTS_SUCCESS:
      state = {
        ...state,
        projects: action.payload.data?.objects || action.payload.data,
        pagination: parsePagination(action.payload),
        loading: false,
        success: true,
        error: false,
      };
      break;
    case GET_PROJECT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PROJECT_SUCCESS:
      state = {
        ...state,
        project: action.payload.data,
        loading: false,
        error: false,
      };
      break;
    case UPDATE_PROJECT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_PROJECT_SUCCESS:
      let temp = state.projects;
      let index = temp.findIndex(
        (project) => project.id === action.payload.data.id
      );
      if (index !== -1) {
        temp[index] = action.payload.data;
      }
      state = {
        ...state,
        project: action.payload.data,
        projects: temp,
        loading: false,
        error: false,
        success: true,
      };
      break;
    case PROJECTS_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        success: false,
      };
      break;
    case DELETE_PROJECT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_TECHNICAL_VISIT_PROJECT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PROJECT_SUCCESS:
      state = {
        ...state,
        projects:
          state.projects === undefined || state.projects === null
            ? []
            : state.projects.filter((e) => e.id !== action.payload.data.id),
        loading: false,
        error: false,
        success: true,
      };
      break;
    case EXPORT_PROJECTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EXPORT_PROJECTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default projects;
