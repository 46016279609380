import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// Users
import Users from "./users/reducer";

// Roles
import Roles from "./roles/reducer";

// Agencies
import Agencies from "./agencies/reducer";

// Financers
import Financers from "./financers/reducer";

// Projects
import Projects from "./projects/reducer";

// Project States
import ProjectStates from "./project_states/reducer";

// Scales
import Scales from "./scales/reducer";

// Customers
import Customers from "./customers/reducer";

// Invoices
import Invoices from "./invoices/reducer";

// Orders
import Orders from "./orders/reducer";

// Vats
import Vats from "./vats/reducer";

// Free Fields
import FreeFields from "./free_fields/reducer";

// Products
import Products from "./products/reducer";

// Categories
import Categories from "./categories/reducer";

// Sub categories
import SubCategories from "./sub_categories/reducer";

// Statistics
import Statistics from "./statistics/reducer";

// Calendar
import AgendaItems from "./calendar/reducer";

// Deposits
import Deposits from "./deposits/reducer";

// Quotes
import Quotes from "./quotes/reducer";

// Notifications
import Notifications from "./notifications/reducer";

// Abonnement States
import AbonnementStates from "./abonnement_states/reducer";

// Formulas States
import Formulas from "./formulas/reducer";

// Subscriptions
import Subscriptions from "./subscriptions/reducer";

// Flash messages
import FlashMessages from "./flash_messages/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Users,
  Roles,
  Agencies,
  Financers,
  Projects,
  ProjectStates,
  Scales,
  Customers,
  Invoices,
  Orders,
  Vats,
  FreeFields,
  Products,
  Categories,
  SubCategories,
  Statistics,
  AgendaItems,
  Deposits,
  Quotes,
  Notifications,
  AbonnementStates,
  Formulas,
  Subscriptions,
  FlashMessages,
});

export default rootReducer;
