import * as Yup from "yup";
import { PHONE_REGEX } from "../../../helpers/validator";

export const createValidationSchema = Yup.object().shape({
  name: Yup.string().required("Veuillez compléter ce champ"),
  // leader: Yup.mixed().required("Veuillez compléter ce champ"),
  address: Yup.string().required("Veuillez compléter ce champ"),
  phone: Yup.string()
    .test("format", "Format invalide", (val) => {
      return !isNaN(val);
    })
    .trim()
    .matches(PHONE_REGEX, "Veuillez saisir un numéro de fixe valide")
    .required(),
  email: Yup.string().required("Veuillez compléter ce champ"),
  url: Yup.mixed().required("Veuillez choisir une image"),
  comptable_account: Yup.string().required("Veuillez choisir une image"),
});
