import { takeEvery, fork, put, all, call, delay } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

import { users } from "../../../api";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(users.sendResetPassword, user.email);
    if (response) yield put(userForgetPasswordSuccess("Email envoyé"));
    history.push("/reset-password");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        yield put(
          userForgetPasswordError(
            "Email invalide"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(userForgetPasswordError(error.response.data.message));
      }
    } else {
      yield put(userForgetPasswordError(error.message));
    }
  }
}

function* editPassword({ payload: { code, password, password_confirmation, history } }) {
  try {
    const response = yield call(users.resetPassword, code, password, password_confirmation);
    if (response) yield put(userForgetPasswordSuccess("Mot de passe modifié !"));
    yield delay(1500);
    history.push("/login");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        yield put(
          userForgetPasswordError(
            "Code invalide"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(userForgetPasswordError(error.response.data.message));
      }
    } else {
      yield put(userForgetPasswordError(error.message));
    }
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

export function* watchEditPassword() {
  yield takeEvery(RESET_PASSWORD, editPassword);
}

function* forgetPasswordSaga() {
  yield all([
    fork(watchUserPasswordForget),
    fork(watchEditPassword)
  ]);
}

export default forgetPasswordSaga;
