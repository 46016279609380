/**
 * Move an item in a items array depending of the offset
 */
export function moveItem<T>(items: Array<T>, index: number, offset: number): Array<T> {
  let tmpArray = [...items];
  if (items.length > 1) {
    const [obj] = tmpArray.splice(index, 1);
    tmpArray.splice(index + offset, 0, obj);
    return tmpArray;
  }

  return items;
}

/** 
 * Delete an item in a items array depending of the index
 */
export function deleteItem<T>(items: Array<T>, index: number): Array<T> {
  let tmpArray = [...items];
  if (items.length > 0) {
    tmpArray.splice(index, 1);
    return tmpArray;
  }

  return items;
}