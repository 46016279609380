import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  IMPORT_CSV,
  IMPORT_CSV_SUCCESS,
  CUSTOMERS_API_ERROR,
} from "./actionTypes";

export const getCustomers = (query) => {
  return {
    type: GET_CUSTOMERS,
    payload: { query },
  };
};

export const getCustomersSuccess = (customers) => {
  return {
    type: GET_CUSTOMERS_SUCCESS,
    payload: customers,
  };
};

export const getCustomer = (id) => {
  return {
    type: GET_CUSTOMER,
    payload: { id },
  };
};

export const getCustomerSuccess = (customer) => {
  return {
    type: GET_CUSTOMER_SUCCESS,
    payload: customer,
  };
};

export const createCustomer = (customer, history) => {
  return {
    type: CREATE_CUSTOMER,
    payload: { customer, history },
  };
};

export const createCustomerSuccess = (customer) => {
  return {
    type: CREATE_CUSTOMER_SUCCESS,
    payload: customer,
  };
};

export const updateCustomer = (id, customer, history) => {
  return {
    type: UPDATE_CUSTOMER,
    payload: { id, customer, history },
  };
};

export const updateCustomerSuccess = (customer) => {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
    payload: customer,
  };
};

export const deleteCustomer = (id, history) => {
  return {
    type: DELETE_CUSTOMER,
    payload: { id, history },
  };
};

export const deleteCustomerSuccess = (customer) => {
  return {
    type: DELETE_CUSTOMER_SUCCESS,
    payload: customer,
  };
};

export const importCsv = (import_file) => {
  return {
    type: IMPORT_CSV,
    payload: { import_file },
  };
};

export const importCsvSuccess = (customer) => {
  return {
    type: IMPORT_CSV_SUCCESS,
    payload: customer,
  };
};

export const customerApiError = (error) => {
  return {
    type: CUSTOMERS_API_ERROR,
    payload: error,
  };
};
