import React, {useState, useEffect, CSSProperties, useRef} from 'react';
import {Field} from 'formik';
import Select from 'react-select';

const colorStyles = {
    option: (styles: CSSProperties, {
        isDisabled,
        isFocused,
        isSelected
    }: { isDisabled?: boolean, isFocused?: boolean, isSelected?: boolean }): CSSProperties => ({
        ...styles,
        backgroundColor: isDisabled
            ? undefined
            : isSelected
                ? '#62d2a2'
                : isFocused
                    ? '#62d2a2'
                    : undefined,
    }),
    multiValue: (styles: CSSProperties): CSSProperties => ({
        ...styles,
        backgroundColor: '#62d2a2',
    }),
    multiValueLabel: (styles: CSSProperties, {data}: { data: { color: CSSProperties["color"] } }): CSSProperties => ({
        ...styles,
        color: data.color,
    }),
};

interface SelectFieldsProps {
    loadOptionsByValue?: ((values: any) => Promise<any[]> | any[]),
    loadOptions: ((query?: string) => Promise<any[]>) | any[],
    field?: any,
    form?: any,
    disabled?: boolean,
    multiple?: boolean,
    className?: string,
    onChangeValue?: ((key: string, value: string) => void) | null,
    isSearchable?: boolean,
    isClearable?: boolean,
}

const SelectField = ({
                         loadOptionsByValue,
                         loadOptions,
                         field,
                         form,
                         disabled,
                         multiple,
                         className,
                         onChangeValue,
                         isSearchable,
                         isClearable
                     }: SelectFieldsProps) => {
    const [options, setOptions] = useState<any[]>([]);
    let loadTimer = useRef<NodeJS.Timeout | null>(null);
    let searchTimer = useRef<NodeJS.Timeout | null>(null);


    useEffect(() => {
        if (loadOptions instanceof Array) {
            setOptions(loadOptions);
        } else {
            if (loadTimer.current) clearTimeout(loadTimer.current)
            loadTimer.current = setTimeout(() => {
                if (loadOptionsByValue) {
                    let p = loadOptionsByValue(field.value)
                    if (typeof (p as Promise<any[]>).then === "function") {
                        (p as Promise<any[]>).then((options) => {
                            setOptions(options)
                        });
                    } else {
                        setOptions(p as any[])
                    }
                } else {
                    loadOptions(undefined).then((options) => {
                        setOptions(options)
                    });
                }
                loadTimer.current = null;
            }, 250);
            return () => {
                if (loadTimer.current) clearTimeout(loadTimer.current);
            }
        }
    }, [loadOptions, field.value, loadOptionsByValue]);

    const applyInput = (inputValue: any) => {
        if (isSearchable && loadOptions instanceof Function) {
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
                loadOptions(inputValue).then((options) => {
                    setOptions(options)
                });
                searchTimer.current = null;
            }, 500);
        }
    };

    const onChange = (option: any) => {
        if (option === null) {
            option = [];
        }

        form.setFieldValue(
            field.name,
            multiple ? option.map((item: any) => item.value) : option.value
        );
        if (onChangeValue) {
            onChangeValue(field.name, option.value);
        }
    };

    const getValue = (selectOptions = options) => {
        if (selectOptions && field.value) {
            return multiple
                ? selectOptions.filter(option => field.value.indexOf(option.value) >= 0)
                : selectOptions.find(option => ("" + option.value) === ("" + field.value)); // Not === because sometimes the value can be a integer and field.value a string so to avoid any bug i use ==
        }
        return multiple ? [] : '';
    }

    return (
        <React.Fragment>
            {multiple ? (
                <Select
                    placeholder="Sélectionner"
                    options={options}
                    name={field.name}
                    value={getValue()}
                    onChange={onChange}
                    onBlur={field.onBlur}
                    isLoading={disabled}
                    isDisabled={disabled}
                    noOptionsMessage={() => 'Aucune correspondance'}
                    isMulti={multiple}
                    style={colorStyles}
                    className={className}
                    onInputChange={applyInput}
                    isClearable={isClearable}
                />
            ) : (
                <Select
                    placeholder="Sélectionner"
                    options={options}
                    name={field.name}
                    value={getValue()}
                    onChange={onChange}
                    onBlur={field.onBlur}
                    isLoading={disabled}
                    isDisabled={disabled}
                    noOptionsMessage={() => 'Aucune correspondance'}
                    isMulti={multiple}
                    style={colorStyles}
                    className={className}
                    onInputChange={applyInput}
                    isClearable={isClearable}
                />
            )}
        </React.Fragment>
    );
};

/**
 * @param {object} params.loadOptions
 * @param {string} params.name
 * @param {object} params.options
 * @param {string | null=} params.id
 * @param {boolean=} params.disabled
 * @param {boolean=} params.invalid
 * @param {boolean=} params.multiple - Is multiple selection possible
 * @param {object=} params.defaultValue
 * @param {object=} params.className
 * @param {object=} params.onChangeValue
 * */
interface FormikAutocompleteProps {
    loadOptionsByValue?: ((values: any) => Promise<any> | never[]) | null,
    loadOptions: ((query: string) => Promise<any>) | any[],
    name: string,
    options?: any,
    id?: any | null,
    disabled?: boolean,
    invalid?: boolean,
    multiple?: boolean,
    className?: string | null,
    onChangeValue?: ((key: string, value: string) => void) | null,
    isSearchable?: boolean,
    isClearable?: boolean
}

const FormikAutocomplete = ({
                                loadOptions,
                                loadOptionsByValue,
                                name,
                                options,
                                id = null,
                                disabled = false,
                                invalid = false,
                                multiple = false,
                                className = null,
                                onChangeValue = null,
                                isSearchable = true,
                                isClearable
                            }: FormikAutocompleteProps) => (
    <Field
        loadOptions={loadOptions}
        loadOptionsByValue={loadOptionsByValue}
        id={id}
        name={name}
        component={SelectField}
        options={options}
        disabled={disabled}
        invalid={invalid}
        multiple={multiple}
        className={className + (invalid ? ' parsley-error' : '')}
        onChangeValue={onChangeValue}
        isSearchable={isSearchable}
        isClearable={isClearable}
    />
);

/** used to have clearable to true by default */
const FormikAutocompleteUnselectable = ({
                                            loadOptions,
                                            loadOptionsByValue,
                                            name,
                                            options,
                                            id = null,
                                            disabled = false,
                                            invalid = false,
                                            multiple = false,
                                            className = null,
                                            onChangeValue = null,
                                            isSearchable = true,
                                            isClearable = true
                                        }: FormikAutocompleteProps) => {
    return <Field
        loadOptions={loadOptions}
        loadOptionsByValue={loadOptionsByValue}
        id={id}
        name={name}
        component={SelectField}
        options={options}
        disabled={disabled}
        invalid={invalid}
        multiple={multiple}
        className={className + (invalid ? ' parsley-error' : '')}
        onChangeValue={onChangeValue}
        isSearchable={isSearchable}
        isClearable={isClearable}
    />
};

export {FormikAutocompleteUnselectable};
export default FormikAutocomplete;
