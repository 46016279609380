import * as Yup from "yup";
import { PromotableTypeEnum } from "../../../../models/offer";

export const createValidationSchema = Yup.object().shape({
  name: Yup.string().required("Veuillez compléter ce champ"),
  start_at: Yup.string().required("Veuillez compléter ce champ"),
  end_at: Yup.string().required("Veuillez compléter ce champ"),
  promotable_type: Yup.string().required("Veuillez compléter ce champ"),
  promotable_entity_ids: Yup.string().when(['promotable_type'], (promotable_type: PromotableTypeEnum, schema) => {
    if (promotable_type === PromotableTypeEnum.ALL) {
      return schema.notRequired().nullable();
    } else {
      return schema.required("Veuillez compléter ce champ");
    }
  }),
  offer_type: Yup.string().required("Veuillez compléter ce champ"),
  amount: Yup.number().required("Veuillez compléter ce champ"),
});
