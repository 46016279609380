import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

const ExportEntretienComponent = (props) => {
  return (
    <Row>
        <Col>
            {
                [
                    {name: 'maintenance_state', value: props.values.maintenance_state, label: 'État du contrat d\'entretien'},
                    {name: 'maintenance_order', value: props.values.maintenance_order, label: 'Souscription à un contrat d\'entretien'},
                    {name: 'maintenance_start_date', value: props.values.maintenance_start_date, label: 'Date de début du contrat d\'entretien'},
                    {name: 'maintenance_formule', value: props.values.maintenance_formule, label: 'Formule du contrat d\'entretien'},
                ].map(
                    (value) => (
                        <FormGroup className="d-flex mb-1">
                            <Input
                                type="checkbox"
                                name={value.name}
                                id={value.name}
                                className="form-control form-checkbox mr-3"
                                checked={value.value != null}
                                disabled={props.isFormDisabled}
                                onChange={(event) => {
                                    props.setFieldValue(
                                        value.name, value.value == null ? value.label : null
                                    )
                                }}
                            />
                            <Label htmlFor={value.name} className="col-form-label">
                                {value.label}
                            </Label>
                        </FormGroup>
                    )
                )
            }
        </Col>
    </Row>
  );
};

export default ExportEntretienComponent;