export const splitProductItemsFromFreeItems = (freeItemsArr) => {
    let freeFieldsArr = [];
    let productItemsArr = [];

    freeItemsArr.forEach((item) => {
        item.product_id === null
            ? freeFieldsArr.push(item)
            : productItemsArr.push(item);
    });

    return {freeFieldsArr, productItemsArr};
};

export const splitProductItemsFromFreeItemsForRefund = (freeItemsArr) => {
    let freeFieldsArr = [];
    let productItemsArr = [];

    freeItemsArr.forEach((item, index) => {
        if (item.product_id === null) {
            freeFieldsArr.push(item);
        } else {
            productItemsArr.push(item);
        }
    });

    return {freeFieldsArr, productItemsArr};
};

export const changeItemsInProductItemsArr = (productItemsArr) => {
    let newProductItemsArr = [];

    productItemsArr.forEach((item, index) => {
        let obj_item = {
            isManpower: item.isManpower ? true : false,
            product_id: item.product_id,
            description: item.free_field_attributes.description,
            eco_tax: item.free_field_attributes.eco_tax,
            price: item.free_field_attributes.price,
            price_ttc: item.free_field_attributes.price_ttc,
            title: item.free_field_attributes.title,
            vat: item.free_field_attributes.vat,
            vat_id: item.free_field_attributes.vat_id,
            position: item.position,
            quantity: item.quantity,
            quantity_unit: item.quantity_unit,
            timeline_begin_at: item.timeline_begin_at,
            __position_version: item.__position_version,
        };

        if (item.id) {
            obj_item.id = item.id;
        }
        if (item._destroy) {
            obj_item._destroy = item._destroy;
        }

        newProductItemsArr.push(obj_item);
    });

    return newProductItemsArr;
};

export const checkManpowerInProductItems = (productItemsArr) => {
    let arrayHaveManpower = false;

    productItemsArr.forEach((productItem, index) => {
        if (productItem.isManpower) {
            arrayHaveManpower = true;
        }
    });

    return arrayHaveManpower;
};


export const manpowerCategoryId = 6;

/**
 * Check if the items array contains a workforce item
 * @param {array} items
 * @return {boolean}
 */
export const containsWorkforce = (items) => {
    console.log(items);
    for (let i = 0; i < items.length; i++) {
        if (items[i].isManpower)
            return true;
        else if(items[i].product)
            if(items[i].product.category.id === manpowerCategoryId)
                return true;
    }
    return false;
}

/**
 * Reference the types of form that would be send
 * @type {{quote: string, balance: string, approval: string, deposit: string, invoice: string}}
 */
export const typeForm = {
    quote: "QUOTE", // Devis
    invoice: "INVOICE", // Facture
    deposit: "DEPOSIT", // facture d'acompte
    approval: "APPROVAL", // avenant
    balance: "BALANCE", // facture de solde
    refund: "REFUND", // avoir
}

/**
 * Compute the name of the form with his type
 * @param {typeForm} type
 * @param {boolean} firstLetterUpCase by default: false, if true, return the string with the first letter in uppercase
 * @return {string}
 */
export function computeName(type, firstLetterUpCase = false) {

    let str = "";

    switch (type) {
        case typeForm.quote :
            str = "le devis";
            break;
        case typeForm.invoice :
            str = "la facture";
            break;
        case typeForm.deposit :
            str = "une facture d'acompte";
            break;
        case typeForm.approval :
            str = "l'avenant";
            break;
        case typeForm.balance :
            str = "une facture de solde";
            break;
        case typeForm.refund :
            str = "l'avoir";
            break;
        default:
            str = "";
            break;
    }

    if (firstLetterUpCase)
        return str.charAt(0).toUpperCase() + str.slice(1);
    else
        return str;
}

export function computeDefaultFormValues(type) {
     if(type === typeForm.quote || type === typeForm.approval) {
            return {
                eco_tax: "",
                customerType: "",
                customer: "",
                cce_prime: 0,
                cee_label: "Remise prime CEE",
                other_prime_amount: 0,
                other_prime_label: "Remise autre prime",
                free_fields: [],
                removed_free_fields: [],
                custom_quote_date: new Date(),
            }
        }else if(type === typeForm.invoice) {
            return {
                eco_tax: "",
                customerType: "",
                customer: "",
                cce_prime: 0,
                cee_label: "Remise prime CEE",
                other_prime_amount: 0,
                other_prime_label: "Remise autre prime",
                free_fields: [],
                removed_free_fields: [],
                custom_invoice_date: new Date(),
                payment_mode: "",
                total_price_deal_ht: "",
                total_price_deal_ttc: "",
            }
        }else if(type === typeForm.refund) {
            return {
                eco_tax: "",
                customerType: "",
                customer: "",
                cce_prime: 0,
                cee_label: "Remise prime CEE",
                other_prime_amount: 0,
                other_prime_label: "Remise autre prime",
                free_fields: [],
                removed_free_fields: [],
                custom_invoice_date: new Date(),
                payment_mode: "",
                refund_on: "",
            }
        }
    }

/**
 * Ensure field is a valid number
 * @param {object} field
 * @param {string} type | float or int
 * @param {number} defaultValue
 * @return {number} 
 */
export function ensureNumber(field, type, defaultValue = 0) {
    let result = type === "float" ? parseFloat(field) : parseInt(field);
    return isNaN(result) ? defaultValue : result;
}

/**
 * Compute the total price of values items (help to define if it's < or > to 0
 * @param items
 */
export function computeTotalItemsPrices(items) {
    let _taxeslessPrice = 0.;
    let _taxesfullPrice = 0.;

    items.forEach((item) => {
        _taxesfullPrice += ensureNumber(item.price_ttc, 'float') * ensureNumber(item.quantity, 'float', 1) + ensureNumber(item.eco_tax, 'float');
        _taxeslessPrice += ensureNumber(item.price, 'float') * ensureNumber(item.quantity, 'float', 1) + ensureNumber(item.eco_tax, 'float');
    });

    return {_taxeslessPrice, _taxesfullPrice};
}

/**
 * Compute initvalues for quote_order values (especially when a preview is done during a creation of a quote)
 * @param quote_order
 * @return {object} initValues
 * */
export function computeQuoteOrderValues(quote_order) {
    const client = quote_order.client;
    return {
        eco_tax: quote_order.eco_tax,
        customerType: client.client_type,
        customer: {
            value: client.id,
            label: client.client_type === "Professionnel" ?
                client.company_name :
                (client.civility + " " + client.lastname + " " + client.firstname)
        },
        cce_prime: quote_order.cce_prime,
        cee_label: quote_order.cee_label,
        other_prime_label: quote_order.other_prime_label,
        other_prime_amount: quote_order.other_prime_amount / 100.0,
        removed_free_fields: [],
        free_fields: [
            ...quote_order.product_items.map(d => ({
                ...d,
                description: d.description || d.quote_description || d.custom_description,
                product_id: d.product.id
                        })),
            ...quote_order.free_items,
        ],
        custom_quote_date: new Date(quote_order.quote.custom_quote_date),
    };
}

/**
 *
 * @param type type of the form
 * @param order
 * @param client
 * @param customQuoteDate
 * @return {object} init values for quote edition
 *  */
export function computeQuoteEditionValues(type, order, client, customQuoteDate) {
    return {
        eco_tax: order.eco_tax,
        customerType: client.client_type,
        customer: {
            value: client.id,
            label: client.client_type === "Professionnel" ?
                client.company_name :
                (client.civility + " " + client.lastname + " " + client.firstname)
        },
        cce_prime: order.cce_prime,
        cee_label: order.cee_label,
        other_prime_label: order.other_prime_label,
        other_prime_amount: order.other_prime_amount / 100.0,
        removed_free_fields: [],
        free_fields: [

            // As free_fields are separated in product items and free fields items in the database
            // we regroup them in one array
            ...order.product_items.map(d =>
                ({
                    ...d,
                    // take a copy when it's an approval to avoid overwriting quote
                    id: (type === typeForm.approval ? null : d.id),
                    description: d.description || d.quote_description || d.custom_description,
                    product_id: d.product.id
                })
            ),

            ...order.free_items.map(freeItem =>
                ({
                    ...freeItem,
                    description: freeItem.description || freeItem.quote_description || freeItem.custom_description,
                    id: (type === typeForm.approval ? null : freeItem.id)
                })),
        ],
        custom_quote_date: new Date(customQuoteDate),
    };
}

/**
 *
 * @param invoice_order
 * @param client
 * @return {object}
 * */
export function computeInvoiceOrderValues(invoice_order, client) {
    return {
        eco_tax: invoice_order.eco_tax,
        customerType: client.client_type,
        customer: {
            value: client.id,
            label: client.client_type === "Professionnel" ?
                client.company_name :
                (client.civility + " " + client.lastname + " " + client.firstname)
        },
        cce_prime: invoice_order.cce_prime,
        cee_label: invoice_order.cee_label,
        other_prime_label: invoice_order.other_prime_label,
        other_prime_amount: invoice_order.other_prime_amount / 100.0,
        removed_free_fields: [],
        free_fields: [
            // As free_fields are separated in product items and free fields items in the database
            // we regroup them in one array
            ...invoice_order.product_items.map(d => ({
                ...d,
                description: d.description || d.quote_description || d.custom_description,
                product_id: d.product.id
            })),
            ...invoice_order.free_items,
        ],
        custom_invoice_date: new Date(invoice_order.invoices[invoice_order.invoices.length - 1].custom_invoice_date),
        payment_mode: invoice_order.invoices[invoice_order.invoices.length - 1].payment_mode,
        total_price_deal_ht: invoice_order.total_price_deal_ht,
        total_price_deal_ttc: invoice_order.total_price_deal_ttc,
    };
}

export function computeInvoiceEditionValues(order, client, invoice) {
    return {
        eco_tax: order.eco_tax,
        customerType: client.client_type,
        customer: {
            value: client.id,
            label: client.client_type === "Professionnel" ?
                client.company_name :
                (client.civility + " " + client.lastname + " " + client.firstname)
        },
        cce_prime: order.cce_prime,
        cee_label: order.cee_label,
        other_prime_label: order.other_prime_label,
        other_prime_amount: order.other_prime_amount / 100.0,
        removed_free_fields: [],
        free_fields: [

            // As free_fields are separated in product items and free fields items in the database
            // we regroup them in one array
            ...order.product_items.map(d =>
                ({
                    ...d,
                    description: d.description || d.quote_description || d.custom_description,
                    product_id: d.product.id

                })
            ),

            ...order.free_items.map(freeItem =>
                ({
                    ...freeItem,
                })),
        ],
        custom_invoice_date: new Date(invoice.custom_invoice_date),
        payment_mode: invoice.payment_mode,
    };
}

/**
 * Compute the amount paid for an invoice
 * @param invoice
 * @return {string}
 */
export function computeAmountPayments(invoice) {
    return parseFloat(
      invoice.payments.reduce(((accumulator, current) => accumulator + current.amount), 0)
    ).toFixed(2);
}

/**
 * payment state colors constant
 * @type {{PARTIALLY_SOLVED: string, LATE: string, PAYMENT_RECEIVED: string, DRAFT: string, SOLVED: string, VALIDATED: string}}
 */
export const paymentStateColors = {
    LATE: 'danger',
    PARTIALLY_SOLVED: 'warning',
    SOLVED: 'success',
    PAYMENT_RECEIVED: 'warning',
    VALIDATED: 'dark',
    DRAFT: 'light',
}

/**
 *
 * @param name name of the payment state given by the api
 * @see paymentStateColors
 * @return {string}
 */
export function computePaymentStateColor(name) {
    return paymentStateColors[name.toUpperCase()];
}
