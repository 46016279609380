import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import ScaleForm from "./FormComponent/ScaleForm";

// Validation Schema
import { createValidationSchema } from "./FormValidationSchemas/createValidationSchema";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import { createScale, scaleApiError } from "../../store/actions";

const ScaleAdd = (props) => {
  const initValues = {
    label: "",
    min_monthly_payment: "",
    min_amount_loan: "",
    max_amount_loan: "",
    ref: "",
    starting_date: "",
    ending_date: "",
    first_monthly_payment_delay: "",
    import_file: null,
    documents: []
  };

  const handleSubmit = (values) => {
    props.createScale(
      {
        financer_id: parseInt(window.location.pathname.split("/")[2], 10),
        label: values.label,
        min_monthly_payment: parseFloat(values.min_monthly_payment),
        min_amount_loan: parseFloat(values.min_amount_loan),
        max_amount_loan: parseFloat(values.max_amount_loan),
        ref: values.ref,
        starting_date: values.starting_date,
        ending_date: values.ending_date,
        first_monthly_payment_delay: parseInt(values.first_monthly_payment_delay),
        documents: values.documents,
        import_file: values.import_file
      },
      props.history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Barèmes" breadcrumbItem="Créer un barème" />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Créer un barème</CardTitle>
                {props.loading ? null : (
                  <ScaleForm
                    initValues={initValues}
                    validationSchema={createValidationSchema}
                    handleSubmit={handleSubmit}
                    scale={props.scale}
                    loading={props.loading}
                    error={props.error}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { loading, error } = state.Scales;
  return { loading, error };
};

export default withRouter(
  connect(mapStatetoProps, {
    createScale,
    scaleApiError,
  })(ScaleAdd)
);
