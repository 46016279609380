import React, {useCallback, useState} from 'react';

import { Formik } from "formik";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  customers
} from '../../../api/index';
import { default as AsyncAutoComplete, default as FormikAutocomplete } from "../../../components/Common/AsyncAutoComplete2";
import { ErrorMessageCustom } from "../../../components/Common/ErrorMessageCustom";
import UploadImage from "../../../components/Common/UploadImage";
import { promiseCustomersOptions, promiseFormulasOptions } from "../../../helpers/autocomplete_promise_options";
import { getBase64 } from "../../../helpers/helper";
import { subscriptionsFormValidation } from "../FormValidationSchema/subscriptionsFormValidation";
import PreviewTermModal from "../Modal/PreviewTermModal";
import { parseCustomerName } from '../../../helpers/customer_helper';

const FormGroupCustom = ({children}) => (<FormGroup className='mb-4 col-lg-12'>{children}</FormGroup>)

const LabelCustom = (props) => (<Label for={props.for} className='col-form-label'>{props.children}</Label>)

const MAX_DAYS_DEBIT = 28;

export const durationType = {
  UNDETERMINED: "Indéterminé",
  END_DATE: "Date de fin",
  INSTALLMENTS_NUMBER: "Nombre d'échéance",
}

export const debitType = {
  PUNCTUAL: "Ponctuel",
  RECURRENT: "Récurrent"
}

const SubscriptionForm = ({
                            initValues,
                            handleSubmit,
                            isUpdate = false
                          }) => {

  const [previewModal, setPreviewModal] = useState(false);
  const promiseOneCustomer = useCallback(value => {
    // TODO: Replace by api v2 with ids filter in index
    if (!value) return promiseCustomersOptions();
    else return new Promise(async (resolve) => {
      let result = await customers.getOne(value);
      resolve(
          [
            {
              value: result.data.id,
              label: parseCustomerName(result.data),
            }
          ]
      );
    });
  }, []);

  const durationSelectOptions = [
    {
      value: durationType.UNDETERMINED,
      label: 'Indéterminé',
    },
    {
      value: durationType.END_DATE,
      label: 'Date de fin',
    },
    {
      value: durationType.INSTALLMENTS_NUMBER,
      label: 'Nombre d\'échéances',
    },
  ]

  const debitDaySelectOptions = [];
  for (let i = 1; i <= MAX_DAYS_DEBIT; i++)
    debitDaySelectOptions.push({value: i, label: i})

  const periodicitySelectOptions = [
    {
      value: 1,
      label: 'Mensuel',
    },
    {
      value: 2,
      label: 'Bimestriel',
    },
    {
      value: 3,
      label: 'Trimestriel'
    },
    {
      value: 4,
      label: 'Quadrimestriel'
    },
    {
      value: 6,
      label: 'Semestriel'
    },
    {
      value: 12,
      label: 'Annuel'
    },
  ];

  const debitTypeSelectOptions = [
    {
      value: debitType.RECURRENT,
      label: debitType.RECURRENT
    },
    {
      value: debitType.PUNCTUAL,
      label: debitType.PUNCTUAL
    },
  ];

  const handleChangeImages = async (files, field, setFieldValue, values) => {
    setFieldValue(field, files[0]);
    let images = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let content = await getBase64(file);
      images.push({
        url_path: content,
        name: file.name,
      });
    }
    setFieldValue('mandateImage', images);
  };

  const canPreview = (errors, values) => {
    return !(
      (errors.formula || !values.formula) ||
      (errors.start_at || !values.start_at) ||
      values.duration === durationType.UNDETERMINED ||
      (errors.end_at || (!values.end_at && values.duration === durationType.END_DATE) ) ||
      (errors.periodicity || !values.periodicity) ||
      (errors.debit_day || !values.debit_day) ||
      (errors.term_number || (!values.term_number && values.duration === durationType.INSTALLMENTS_NUMBER))
    );
  }

  // const disabled = isUpdate && initValues.validation_date;
  const disabled = false;

  return (
    <div>
      <Formik
        initialValues={initValues}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={subscriptionsFormValidation}
      >
        {({
            values,
            handleSubmit,
            handleChange,
            errors,
            touched,
            setFieldValue
          }) => (
          <form>
            <Row>
              <Col>
                <Row>
                  {/* Client (sélecteur) */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <LabelCustom for='customer' className='col-form-label'>Client *</LabelCustom>
                      <FormikAutocomplete
                        name="customer"
                        id="customer"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucun client disponible"}
                        placeholder="Sélectionner"
                        loadOptions={promiseCustomersOptions}
                        loadOptionsByValue={promiseOneCustomer}
                        invalid={errors.customer && !!touched.customer}
                        disabled={disabled}
                      />
                      <ErrorMessageCustom name='customer'/>
                    </FormGroupCustom>
                  </Col>

                  {/* Formule (sélecteur) */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <LabelCustom for='formula'>Formule *</LabelCustom>
                      <FormikAutocomplete
                        name="formula"
                        id="formula"
                        isSearchable={false}
                        noOptionsMessage={() => "Aucune formule disponible"}
                        placeholder="Sélectionner"
                        loadOptions={promiseFormulasOptions}
                        invalid={errors.formula && !!touched.formula}
                        disabled={disabled}
                      />
                      <ErrorMessageCustom name='formula'/>
                    </FormGroupCustom>
                  </Col>

                  {/* Type de prélèvement (sélecteur) */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <LabelCustom for='debit_type'>Type de prélèvement *</LabelCustom>
                      <AsyncAutoComplete
                        name="debit_type"
                        id="debit_type"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucun type de prévlèvement disponible"}
                        placeholder="Sélectionner"
                        loadOptions={debitTypeSelectOptions}
                        invalid={errors.debit_type && !!touched.debit_type}
                        disabled={disabled}
                      />
                      <ErrorMessageCustom name='debit_type'/>
                    </FormGroupCustom>
                  </Col>
                </Row>

                <Row>
                  {/* Date de prélèvement (sélecteur) */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <LabelCustom for='debit_day'>Jour de prélèvement *</LabelCustom>
                      <AsyncAutoComplete
                        name='debit_day'
                        id='debit_day'
                        noOptionsMessage={() => "Aucun jour de prélèvement disponible"}
                        placeholder='Sélectionner'
                        loadOptions={debitDaySelectOptions}
                        invalid={errors.debit_day && !!touched.debit_day}
                      />
                      <ErrorMessageCustom name='debit_day'/>
                    </FormGroupCustom>
                  </Col>

                  {/* Date de validation (date) */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <LabelCustom for='validation_date'>Date de validation</LabelCustom>
                      <Input
                        id='validation_date'
                        name='validation_date'
                        type='date'
                        invalid={errors.validation_date && !!touched.validation_date}
                        onChange={handleChange}
                        defaultValue={values.validation_date}
                        disabled={disabled}
                        className={disabled ? 'disabled' : ''}
                      />
                      <ErrorMessageCustom name='validation_date'/>
                    </FormGroupCustom>
                  </Col>

                  {/* Date de prise d'effet (date) */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <LabelCustom for='start_at'>Date de première échéance *</LabelCustom>
                      <Input
                        id='start_at'
                        name='start_at'
                        type='date'
                        invalid={errors.start_at && !!touched.start_at}
                        onChange={handleChange}
                        defaultValue={values.start_at}
                      />
                      <ErrorMessageCustom name='start_at'/>
                    </FormGroupCustom>
                  </Col>
                </Row>

                <Row>

                  {/* Date de signature du mandat (date) */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <LabelCustom for='signature_date'>Date de signature mandat</LabelCustom>
                      <Input
                        id='signature_date'
                        name='signature_date'
                        type='date'
                        onChange={handleChange}
                        defaultValue={values.signature_date}
                        invalid={errors.signature_date && !!touched.signature_date}
                      />
                      <ErrorMessageCustom name='signature_date'/>
                    </FormGroupCustom>
                  </Col>

                  {/* Périodicité (sélecteur) */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <LabelCustom for='periodicity'>Périodicité *</LabelCustom>
                      <AsyncAutoComplete
                        name="periodicity"
                        id="periodicity"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucune périodicité disponible"}
                        placeholder="Sélectionner"
                        loadOptions={periodicitySelectOptions}
                        invalid={errors.periodicity && !!touched.periodicity}
                        disabled={disabled}
                      />
                      <ErrorMessageCustom name='periodicity'/>
                    </FormGroupCustom>
                  </Col>


                  {/* Durée (sélecteur) (ajout d'un champ dépendant du choix dans la colonne d'après) */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <LabelCustom for='duration'>Durée *</LabelCustom>
                      <AsyncAutoComplete
                        name="duration"
                        id="duration"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucune durée disponible"}
                        placeholder="Sélectionner"
                        loadOptions={durationSelectOptions}
                        invalid={errors.duration && !!touched.duration}
                        disabled={disabled}
                      />
                      <ErrorMessageCustom name='duration'/>
                    </FormGroupCustom>
                  </Col>

                  {/* Champ dépendant du sélecteur "Durée" */}
                  {!values.duration || values.duration === durationType.UNDETERMINED ? '' :
                    <Col lg='3'>
                      <FormGroupCustom>
                        {values.duration === durationType.END_DATE ?
                          <React.Fragment>
                            <LabelCustom for='end_at'>Date de fin</LabelCustom>
                            <Input
                              id='end_at'
                              name='end_at'
                              type='date'
                              onChange={handleChange}
                              defaultValue={values.end_at}
                              invalid={errors.end_at && !!touched.end_at}
                              disabled={disabled}
                              className={disabled ? 'disabled' : ''}
                            />
                            <ErrorMessageCustom name='end_at'/>
                          </React.Fragment>
                          :
                          <React.Fragment>
                            <LabelCustom for='term_number'>Nombre d'échéances</LabelCustom>
                            <Input
                              id='term_number'
                              name='term_number'
                              onChange={handleChange}
                              invalid={errors.term_number && !!touched.term_number}
                              defaultValue={values.term_number}
                              disabled={disabled}
                              className={disabled ? 'disabled' : ''}
                            />
                            <ErrorMessageCustom className='danger' name='term_number'/>
                          </React.Fragment>
                        }
                      </FormGroupCustom>
                    </Col>}
                </Row>

                <Row>
                  {/* NOTES */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <LabelCustom for='rum'>Notes</LabelCustom>
                      <Input
                        id='notes'
                        type="textarea"
                        component="textarea"
                        invalid={errors.notes && !!touched.notes}
                        placeholder='Entrer une note'
                        onChange={handleChange}
                        defaultValue={values.notes}
                      />
                      <ErrorMessageCustom name='notes'/>
                    </FormGroupCustom>
                  </Col>

                  {/* RUM */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <LabelCustom for='rum'>RUM</LabelCustom>
                      <Input
                        id='rum'
                        name='rum'
                        invalid={errors.rum && !!touched.rum}
                        placeholder='Entrer un RUM'
                        onChange={handleChange}
                        defaultValue={values.rum}
                      />
                      <ErrorMessageCustom name='rum'/>
                    </FormGroupCustom>
                  </Col>

                  {/* IBAN */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <LabelCustom for='iban'>IBAN</LabelCustom>
                      <Input
                        id='iban'
                        name='iban'
                        invalid={errors.iban && !!touched.iban}
                        placeholder='Entrer un IBAN'
                        onChange={handleChange}
                        defaultValue={values.iban}
                      />
                      <ErrorMessageCustom name='iban'/>
                    </FormGroupCustom>
                  </Col>
                </Row>
                <Row>
                  {/* Mandat de prélèvement (pdf) */}
                  <Col lg='3'>
                    <FormGroupCustom>
                      <UploadImage
                        files={
                          values.mandateImage.length > 0
                            ? values.mandateImage
                            : []
                        }
                        title="Mandat de prélèvement PDF"
                        handleChangeImages={handleChangeImages}
                        max_file={1}
                        field="mandate"
                        setFieldValue={setFieldValue}
                        invalid={errors.mandate && !!touched.mandate}
                        invalidError={errors.mandate}
                        isInForm={true}
                      />
                    </FormGroupCustom>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg="12 text-center">
                <Button
                  color="primary"
                  className='btn-green'
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit(values);
                  }}
                >
                  {isUpdate ? 'Modifier' : 'Créer'} l'abonnement
                </Button>
                <Button
                  className={'ml-2 btn-green' + (canPreview(errors, values) ? '' : ' btn-disabled')}
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setPreviewModal(true);
                  }}
                  disabled={!canPreview(errors, values)}
                >
                  Aperçu
                </Button>
              </Col>

            </Row>
            <PreviewTermModal
              isOpen={previewModal}
              toggleModal={() => setPreviewModal(!previewModal)}
              values={values}
            />
          </form>
        )}
      </Formik>
    </div>
  );
}

export default SubscriptionForm;