import axios from "axios";

const url = "https://geo.api.gouv.fr/communes?codePostal=";

/**
 * Permet de rechercher l'ensembles des villes avec un code postal
 * 
 * zipCode =    Code postal
 * 
 * Renvoie une promesse qui n'échoue pas quelque soit le prob donc on peut utiliser await sans soucis
 */
export const searchCitiesByZipCode = (zipCode) => {
    return new Promise((resolve, reject) => {
        if (zipCode.length < 2) {
            resolve([]);
        } else {
            axios.get(
                `${url}${zipCode}`
            ).then((response) => {
                resolve(response.data.map((data, index) => {
                    return data.nom;
                }));
            }).catch((error) => {
                resolve([]);
            });
        }
    });
};
