import DefaultFilter from "./types/default_filter";

export interface OffersFilters extends DefaultFilter {
  end_at?: string | null,
  /**
   * Filter by absolute value 
   * offer_type = 'value' AND amount = :value
   */
  value?: number | null,
  /**
   * Filter by percent value 
   * offer_type = 'percent' AND amount = :value
   */
  percent?: number | null,
  search?: string | null
}

export enum OfferTypeEnum {
  PERCENT = "percent",
  VALUE = "value"
}

export enum PromotableTypeEnum {
  ALL = 'All',
  PRODUCT_GROUP = 'ProductGroup',
  CATEGORY_GROUP = 'CategoryGroup',
  SUB_CATEGORY_GROUP = 'SubCategoryGroup'
}

export function computePromotableLabel(type: PromotableTypeEnum) {
  switch (type) {
    case PromotableTypeEnum.ALL: return 'Tout'
    case PromotableTypeEnum.CATEGORY_GROUP: return 'Catégories'
    case PromotableTypeEnum.SUB_CATEGORY_GROUP: return 'Sous-catégories'
    case PromotableTypeEnum.PRODUCT_GROUP: return 'Produits'
  }
}

export default interface Offer {
  id?: number,
  name?: string,
  start_at?: string,
  end_at?: string,
  prefix?: string,
  offer_type?: OfferTypeEnum,
  amount?: number,
  offer_codes_count?: number,
  promotable_id?: number,
  promotable_type?: PromotableTypeEnum,
  promotable_entity_ids?: number[]
}