import { Col, Row } from 'reactstrap';
import Offer, { OfferTypeEnum } from '../../../../models/offer';

export interface OfferCardProps {
  offer: Offer
}

const OfferCard = ({
  offer
}: OfferCardProps) => {
  return (
    <Col xs="12" className="pt-3">
      <Row className="mt-4">
        <Col lg="4">
          <p>
            <b>Nom de la promotion : </b>
            {offer.name}
          </p>
        </Col>
        <Col lg="4">
          <p>
            <b>Nombre de codes : </b>
            {offer.offer_codes_count}
          </p>
        </Col>
        <Col lg="4">
          <p>
            <b>Date : </b>
            {offer.start_at}
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="4">
          <p>
            <b>Pourcentage : </b>
            {offer.offer_type === OfferTypeEnum.PERCENT ? `${(offer.amount || 0).toFixed(2)} %` : 'Non renseigné'}
          </p>
        </Col>
        <Col lg="4">
          <p>
            <b>Montant : </b>
            {offer.offer_type === OfferTypeEnum.VALUE ? `${(offer.amount || 0).toFixed(2)} €` : 'Non renseigné'}
          </p>
        </Col>
        <Col lg="4">
          <p>
            <b>Préfix : </b>
            {offer.prefix}
          </p>
        </Col>
      </Row>
    </Col>
  );
};

export default OfferCard;