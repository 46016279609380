import { Link, withRouter } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";

// Redux
import { connect } from "react-redux";

import BadgeState from "../badge-state";

// Bootstrap table
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// Helpers
import { parseCustomerName } from "../../../helpers/customer_helper";
import { getDateWithShortFormat } from "../../../helpers/helper";

const AdministratifTable = (props) => {
  const columns = [
    {
      dataField: "client",
      text: "Client",
      formatter: (cell, row) =>
        row.client ? (
          <Link to={`/customer/detail/${row.client?.id}`} className="d-flex justify-content-start mb-0">
            <div className="mr-3">
              <i className="bx bx-user mr-1"></i>
              <span className="font-size-12">
                {parseCustomerName(row.client)}
              </span>
            </div>
            <div className="mr-3" id={`map-${row.id}`}>
              <i className="bx bx-map mr-1"></i>
              <UncontrolledTooltip placement="top" target={`map-${row.id}`}>
                {row.client.city} ({row.client.zip_code.charAt(0)}
                {row.client.zip_code.charAt(1)})
              </UncontrolledTooltip>
            </div>
          </Link>
        ) : null,
      sort: true,
    },
    {
      dataField: "state",
      text: "État",
      formatter: (cell, row) => {
        return <BadgeState state={row.project_state} />;
      },
      sort: true,
    },
    {
      dataField: "sell_date",
      text: "Date vente",
      formatter: (cell, row) =>
        row.sell_date ? getDateWithShortFormat(row.sell_date) : "NR",
      sort: true,
    },
    {
      dataField: "decision_at",
      text: "Date acceptation refus",
      formatter: (cell, row) =>
        row.decision_at ? getDateWithShortFormat(row.decision_at) : "NR",
      sort: true,
    },
    {
      dataField: "reception_scan_at",
      text: "Date reception scan",
      formatter: (cell, row) =>
        row.reception_scan_at
          ? getDateWithShortFormat(row.reception_scan_at)
          : "NR",
      sort: true,
    },
    {
      dataField: "folder_sell_paper_receipt_date",
      text: "Date reception dossier papier",
      formatter: (cell, row) =>
        row.folder_sell_paper_receipt_date
          ? getDateWithShortFormat(row.folder_sell_paper_receipt_date)
          : "NR",
      sort: true,
    },
    {
      dataField: "pac_file_receipt_date",
      text: "Date reception fiche de liaison papier",
      formatter: (cell, row) =>
        row.pac_file_receipt_date
          ? getDateWithShortFormat(row.pac_file_receipt_date)
          : "NR",
      sort: true,
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <UncontrolledDropdown>
          <DropdownToggle tag={"a"} className={"bg-transparent px-3 py-2 d-inline-block"}>
            <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
          </DropdownToggle>
          <DropdownMenu right className="bordered important-default-colors">
            <DropdownItem onClick={() => { props.history.push(`/projects-overview/${row.id}`) }}>Voir</DropdownItem>
            <DropdownItem onClick={() => { window.open(`/projects-overview/${row.id}`, '_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
      classes: "text-right"
    },
  ];

  return (
    <div className="deposit-table-container">
      <BootstrapTable
        keyField="id"
        data={props.projects}
        classes="table-centered"
        columns={columns}
        defaultSortDirection="desc"
        sort={{ dataField: props.sort.dataField || 'id', order: props.sort.order }}
        remote={{ pagination: true, sort: true }}
        onTableChange={props.onTableChange}
        pagination={paginationFactory({
          page: props.pagination.current_page,
          totalSize: props.pagination.total_count,
          sizePerPage: props.pagination.current_page_size,
          sizePerPageList: [
            { text: "Afficher 10 éléments", value: 10 },
            { text: "Afficher 25 éléments", value: 25 },
            { text: "Afficher 50 éléments", value: 50 },
            { text: "Afficher 75 éléments", value: 75 },
            { text: "Afficher 100 éléments", value: 100 },
          ],
        })}
      />
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { projects, pagination } = state.Projects;

  return { projects, pagination };
};

export default withRouter(connect(mapStatetoProps, {})(AdministratifTable));
