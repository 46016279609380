import {
  ADD_MESSAGE,
  REMOVE_MESSAGE
} from "./actionTypes";

const initialState = {
  message: '',
  importance: ''
};

const flashMessages = (state = initialState, action) => {
  switch (action.type) {

    case ADD_MESSAGE:
      state = {
        ...state,
        message: action.payload.message,
        importance: action.payload.importance
      };
      break;

    case REMOVE_MESSAGE:
      state = {
        ...state,
        message: ''
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default flashMessages;
