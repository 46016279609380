import {
  GET_STATISTICS,
  GET_STATISTICS_SUCCESS,
  STATISTICS_API_ERROR,
  GET_FAMILY_REPARTITION,
  GET_FAMILY_REPARTITION_SUCCESS,
  GET_CATEGORY_REPARTITION,
  GET_CATEGORY_REPARTITION_SUCCESS,
  GET_PRODUCT_REPARTITION,
  GET_PRODUCT_REPARTITION_SUCCESS,
  GET_ALL_REPARTITIONS,
  GET_ALL_REPARTITIONS_SUCCESS
} from "./actionTypes";

export const getStatistics = (query) => {
  return {
    type: GET_STATISTICS,
    payload: { query },
  };
};

export const getStatisticsSuccess = (statistics) => {
  return {
    type: GET_STATISTICS_SUCCESS,
    payload: statistics,
  };
};

export const getFamilyRepartition = (query) => {
  return {
    type: GET_FAMILY_REPARTITION,
    payload: { query },
  };
};

export const getFamilyRepartitionSuccess = (family_repartitions) => {
  return {
    type: GET_FAMILY_REPARTITION_SUCCESS,
    payload: family_repartitions,
  };
};

export const getCategoryRepartition = (query) => {
  return {
    type: GET_CATEGORY_REPARTITION,
    payload: { query },
  };
};

export const getCategoryRepartitionSuccess = (category_repartitions) => {
  return {
    type: GET_CATEGORY_REPARTITION_SUCCESS,
    payload: category_repartitions,
  };
};

export const getProductRepartition = (query) => {
  return {
    type: GET_PRODUCT_REPARTITION,
    payload: { query },
  };
};

export const getProductRepartitionSuccess = (product_repartitions) => {
  return {
    type: GET_PRODUCT_REPARTITION_SUCCESS,
    payload: product_repartitions,
  };
};

export const getAllRepartitions = (query) => {
  return {
    type: GET_ALL_REPARTITIONS,
    payload: { query },
  };
};

export const getAllRepartitionsSuccess = (family_repartitions, category_repartitions, product_repartitions) => {
  return {
    type: GET_ALL_REPARTITIONS_SUCCESS,
    payload: { family_repartitions, category_repartitions, product_repartitions },
  };
};

export const statisticApiError = (error) => {
  return {
    type: STATISTICS_API_ERROR,
    payload: error,
  };
};
