import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Alert,
  Modal,
} from "reactstrap";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { isUnova, isLeader, isAdmin } from "../../helpers/roles_helper";

// actions
import {
  getProduct,
  deleteProduct,
  productApiError,
} from "../../store/actions";
import { getAssetsDistUrl } from "../../helpers/url_helper";

const ProductsDetail = (props) => {
  const [flash_message, setFlash_message] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    props.getProduct.call(undefined,parseInt(window.location.pathname.split("/")[3], 10));
  }, [props.getProduct]);

  function removeFlashMessage() {
    window.history.pushState(
      {},
      document.title,
      "/product/detail/" + props.product.id
    );
    setFlash_message(!flash_message);
  }

  /**
   * Fonction permettant de définir si le rôle est autorisé ou non à ajouter et modifier des produits
   */
   const userIsAllowed = () => {
    return isUnova() || isLeader() || isAdmin() ? true : false;
  };

  const urlParams = new URLSearchParams(window.location.search);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="collaborators-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Produits" breadcrumbItem="Détails produit" />
          <Row>
            <Col lg="12">
              <Alert
                color="success"
                isOpen={urlParams.get("success_update") === "1"}
                toggle={() => removeFlashMessage()}
              >
                Le produit à bien été mise a jour.
              </Alert>
            </Col>
          </Row>
          {props.error ? <Alert color="danger">{props.error}</Alert> : null}
          {!props.product ? null : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="mb-5">
                      <Col className="d-flex align-items-start">
                        {props.product.image === undefined ? (
                          <div className="avatar-lg d-inline-block">
                            <span className="avatar-title rounded-circle">
                              {props.product.commercial_name !== null
                                ? props.product.commercial_name.charAt(0)
                                : "A"}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <img
                              className="rounded-circle avatar-lg"
                              src={props.product.image && getAssetsDistUrl(props.product.image)}
                              alt=""
                            />
                          </div>
                        )}
                        <div className="ml-3 d-inline-block">
                          <h5 className="font-size-14 mb-1">
                            <Link to="#" className="table-text-black">
                              {props.product.commercial_name}
                            </Link>
                          </h5>
                          <p className="mb-0">{props.product.brand}</p>
                          <p className="mb-0">{props.product.reference}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-5">
                      <Col>
                        <Row className="mb-3">
                          <Col lg="3">
                            <p>
                              <b>Description : </b>
                              {props.product.description}
                            </p>
                          </Col>
                          <Col lg="3">
                            <p>
                              <b>Famille : </b>
                              {props.product.category.name}
                            </p>
                          </Col>
                          <Col lg="3">
                            <p>
                              <b>Catégorie : </b>
                              {props.product.sub_category === undefined ||
                              props.product.sub_category === null ||
                              props.product.sub_category === ""
                                ? "Pas de Catégorie"
                                : props.product.sub_category.name}
                            </p>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg="3">
                            <p>
                              <b>Prix HT : </b>
                              {`${parseFloat(props.product.price).toFixed(
                                2
                              )} €`}
                            </p>
                          </Col>
                          <Col lg="3">
                            <p>
                              <b>TVA : </b>
                              {props.product.vat.name}
                            </p>
                          </Col>
                          <Col lg="3">
                            <p>
                              <b>Prix TTC : </b>
                              {`${parseFloat(props.product.price_ttc).toFixed(
                                2
                              )} €`}
                            </p>
                          </Col>
                          <Col lg="3">
                            <p>
                              <b>Eco taxe : </b>
                              {`${parseFloat(props.product.eco_tax).toFixed(
                                2
                              )} €`}
                            </p>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg="3">
                            <p>
                              <b>Offre promo HT : </b>
                              {props.product.promotional_offer === null
                                ? ""
                                : `${parseFloat(
                                    props.product.promotional_offer
                                  ).toFixed(2)} €`}
                            </p>
                          </Col>
                          <Col lg="3">
                            <p>
                              <b>Offre promo TTC : </b>
                              {props.product.promotional_offer_ttc === null
                                ? ""
                                : `${parseFloat(
                                    props.product.promotional_offer_ttc
                                  ).toFixed(2)} €`}
                            </p>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg="3">
                            <p>
                              <b>Titre (devis) : </b>
                              {props.product.title === null
                                ? ""
                                : props.product.title}
                            </p>
                          </Col>
                          <Col lg="3">
                            <p>
                              <b>Description pour les devis et factures : </b>
                              {props.product.quote_description === null
                                ? ""
                                : props.product.quote_description}
                            </p>
                          </Col>
                          <Col lg="3">
                            <p>
                              <b>Main d'oeuvres : </b>
                            </p>
                            <ul>
                              {
                                props.product.workforces.map(w => <li>{w.description}</li>)
                              }
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row className="container-text-theme-color-2">
                      <Col xs="12" className="d-flex justify-content-end">
                        { userIsAllowed() ? (
                          <Link
                            to={`/product/edit/${props.product.id}`}
                            className="d-flex align-items-center mr-5"
                          >
                            <i className="bx bx-pencil mr-1"></i> Modifier
                          </Link>
                        ) : null}
                        { userIsAllowed() ? (
                          <Link
                            to="#"
                            onClick={() => {
                              tog_standard();
                            }}
                            data-toggle="modal"
                            data-target="#productDelete"
                            className="d-flex align-items-center mr-5"
                          >
                            <i className="bx bx-trash mr-1"></i> Supprimer
                          </Link>
                        ) : null}
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Produit - Suppression
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Attention</h5>
          <p>Voulez vous supprimer le produit definitivement ?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Annuler
          </button>
          <button
            type="button"
            onClick={() => {
              props.deleteProduct(props.product.id, props.history);
            }}
            className="btn btn-green waves-effect waves-light"
          >
            Supprimer
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { product, error } = state.Products;
  return { product, error };
};

export default withRouter(
  connect(mapStatetoProps, { getProduct, deleteProduct, productApiError })(
    ProductsDetail
  )
);
