import React, { useEffect, useState } from 'react';
import BootstrapTable, { ColumnDescription, SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { V2 } from '../../../../../api';
import GlobalLoader from "../../../../../components/Common/GlobalLoader";
import { getDateWithShortFormat } from '../../../../../helpers/helper';
import { onTableChange } from "../../../../../helpers/table_helper";
import Request, { RequestsFilters } from '../../../../../models/request';
import Pagination, { getPaginationFromResponse } from '../../../../../models/types/pagination';
import {getStatusColor} from "../../../../../models/requests_status";
import {Badge} from "reactstrap";
import {Link} from 'react-router-dom';

interface RequestsTableProps {
  filters: RequestsFilters,
  setFilters: React.Dispatch<React.SetStateAction<RequestsFilters>>,
  importantRequests: number[] | null
}

const HistoryTable = ({
 filters,
 setFilters,
 importantRequests
}: RequestsTableProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [requests, setRequests] = useState<Request[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);

  const getRequests = () => {
    setLoading(true);
    V2.requests.getAll(filters).then(response => {
      setRequests(response.data as Request[]);
      setPagination(getPaginationFromResponse(response));
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };
  useEffect(getRequests, [filters]);

  // Liste des colonnes qui seront affichées dans le tableau
  const columns: ColumnDescription<Request>[] = [
    {
      dataField: "nature",
      text: "Nature",
      formatter: (cell, row) =>
        <span className={row.id?(importantRequests?.includes(row.id)?"text-danger":""):""}>
          {row.nature?.label || ""}
        </span>,
      sort: true,
    },
    {
      dataField: "request_at",
      text: "Date",
      formatter: (cell, row) =>
        <span className={row.id?(importantRequests?.includes(row.id)?"text-danger":""):""}>
          {getDateWithShortFormat(row.request_at)}
        </span>,
      sort: true
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: (cell, row) =>
          <Badge style={{ background: (getStatusColor(row.status || {name:"new"}))}}>
            {row.status?.label}
          </Badge> || "",
      sort: true
    },
    {
      dataField: "attached_file_project",
      text: "Produit",
      formatter: (cell, row) =>
        <span className={row.id?(importantRequests?.includes(row.id)?"text-danger":""):""}>
          {row.project_name || ""}
        </span>,
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <Link to={`/requests/${row.id}/details`} className="text-nowrap">En détails <i className={"fa fa-angle-right"}/></Link>
      ),
    },
  ];

  return (
    loading ? <GlobalLoader/>  : <BootstrapTable
      keyField="id"
      data={requests}
      columns={columns}
      defaultSortDirection="asc"
      sort={{ dataField: filters.sort_by, order: (filters.sort_dir || "asc")  as SortOrder }}
      remote={{ pagination: true, sort: true }}
      onTableChange={onTableChange.bind({ setFilters, filters })}
      pagination={paginationFactory({
        page: pagination?.current_page,
        totalSize: pagination?.total_count,
        sizePerPage: pagination?.current_page_size,
        sizePerPageList: [
          { text: "Afficher 10 éléments", value: 10 },
          { text: "Afficher 25 éléments", value: 25 },
          { text: "Afficher 50 éléments", value: 50 },
          { text: "Afficher 75 éléments", value: 75 },
          { text: "Afficher 100 éléments", value: 100 },
        ],
      })}
    />
  );
};

export default HistoryTable;
