import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, CardBody, CardTitle, Card } from "reactstrap";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import NotificationsForm from "./FormComponent/NotificationsForm";

// Validation Schema
import { createValidationSchema } from "./FormValidationSchemas/createValidationSchema";

import { getNotification, updateNotification } from "../../../store/actions";

const NotificationEdit = (props) => {
  // Récupérer la relance à mettre à jour
  useEffect(() => {
    props.getNotification.call(undefined,parseInt(window.location.pathname.split("/")[3], 10));
  }, [props.getNotification]);

  // Initialiser les valeurs du formulaire d'ajout de notification
  const initValues = props.notification
    ? {
      client_id: props.notification.client.id,
      user_to_id: props.notification.user_to.id,
      project_id: props.notification.project?.id,
      priority: props.notification.priority
        ? {
          value: props.notification.priority,
          label: props.notification.priority,
        }
        : null,
      title: props.notification.title ? props.notification.title : "",
      state: props.notification.state
        ? {
          value: props.notification.state,
          label: props.notification.state,
        }
        : null,
    }
    : {
        client_id: null,
        user_to_id: null,
        project_id: null,
        priority: null,
        title: "",
        state: null,
      };

  /**
   * Créer une notification
   * @param {object} values Les valeurs pour créer la notification
   */
  const handleSubmit = (values) => {
    props.updateNotification(
      props.notification.id,
      {
        client_id: values.client_id,
        user_to_id: values.user_to_id,
        project_id: values.project_id,
        priority: values.priority?.value || null,
        title: values.title,
        state: values.state?.value || null,
      },
      props.history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="notifications-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Relances" breadcrumbItem="Modifier une relance" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-3">
                  <Row>
                    <Col>Modifier une relance</Col>
                  </Row>
                </CardTitle>
                <Row>
                  <Col xs="12">
                    <NotificationsForm
                      initValues={initValues}
                      validationSchema={createValidationSchema}
                      handleSubmit={handleSubmit}
                      isUpdate={true}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { notification, loading } = state.Notifications;
  return { notification, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    getNotification,
    updateNotification,
  })(NotificationEdit)
);
