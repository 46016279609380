import React, {useCallback, useEffect, useState} from 'react';

// Helpers
import {parseDate} from "../../../helpers/subscriptions_helpers";
import {
  promiseAgenciesOptions,
  promisePaymentStatesOptions
} from "../../../helpers/autocomplete_promise_options";

// Components
import {Card, CardBody, CardTitle, Col, Input, Row} from "reactstrap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Select from "react-select";

// Modals
import {invoices, orders} from "../../../api";

// Redux
import {getPaymentsDetails} from "../../../store/invoices/actions";
import { parseCustomerName } from '../../../helpers/customer_helper';

/**
 * Display invoice information in a card.
 * Allow changing state with a selector, it will change when another state is selected
 * @param invoice required - invoice information displayed
 * @param getInvoice not required - provided by redux context
 */
const InvoiceDetailComponent = ({
                                  invoice,
                                  getPaymentsDetails
                                }) => {

  const [stateOptions, setStateOptions] = useState([]);
  const [agencyOptions, setAgencyOptions] = useState([]);

  /**
   * Get payment state options from promise.
   * Remove the "Brouillon" option if the invoice is not a draft
   */
  const getStateOptions = useCallback(() => {
    promisePaymentStatesOptions().then(r => {
      if (invoice.is_draft)
        setStateOptions(r);
      else
        setStateOptions(r.filter(
          (option) => option.label !== "Brouillon"
        ));
    });
  }, [invoice.is_draft])
  const getAgenciesOptions = () => {
    promiseAgenciesOptions().then(o => {
      setAgencyOptions(o);
    });
  }

  useEffect(() => {getStateOptions();getAgenciesOptions();}, [getStateOptions]);

  const onStateChange = (stateId) => {
    const invoiceStateId = invoice.payment_state ? invoice.payment_state.id : null;
    if (invoiceStateId !== stateId)
      invoices.update_state(invoice.id, stateId)
        .then(r => getPaymentsDetails(invoice.id))
  }

  const onAgencyChange = (agencyId) => {
    const invoiceAgencyId = invoice.order ? invoice.order.agency_id : null;
    if (invoiceAgencyId !== agencyId)
      orders.update(invoice.order.id, {...invoice.order, agency_id: agencyId})
        .then(r => {
          invoice.order.agency_id = agencyId
          setAgencyOptions([...agencyOptions]);
        })
  }

  const onFundRequestDateChange = (date) => {
    const dateInstance = new Date(date);
    if (invoice.fund_request_at !== dateInstance.toLocaleDateString()) {
      invoices.update_fund_request(invoice.id, dateInstance)
        .then(r => getPaymentsDetails(invoice.id))
    }
  }

  let total_ttc = invoice.total_ttc - invoice.total_price_deposits_ttc;
  
  if (invoice.is_sold_invoice) {
    total_ttc += invoice.order.cce_prime + invoice.order.other_prime_amount / 100.0;
  }

  return (
    <Card>
      <CardBody>
        <CardTitle className='mb-4'>
          Facture {invoice.number}
        </CardTitle>
        <Col className='subscriptions-detail'>
          <Row>
            <Col lg='4'>
              <p><span>Date Facture : </span>{parseDate(invoice.invoice_date)}</p>
            </Col>

            <Col lg='4' className='d-flex align-items-center'>
              <Col lg='2'>
                <span>État :</span>
              </Col>
              <Col lg='8'>
                <Select
                  className='w-100'
                  value={invoice.payment_state}
                  noOptionsMessage={() => "Aucun client disponible"}
                  onChange={(option) => onStateChange(option.value)}
                  placeholder="Sélectionner"
                  options={stateOptions}
                />
              </Col>
            </Col>

            <Col lg='4'>
              <p><span>Client : </span>{parseCustomerName(invoice.order.client)}</p>
            </Col>


          </Row>

            <Row>
              <Col lg='4'>
                <p><span>Montant TTC : </span>{parseFloat(total_ttc).toFixed(2)} €</p>
              </Col>

              <Col lg='4'>
                <p>
                  <span>Montant en attente : </span>{parseFloat(invoice.paid_total_ttc - invoice.received_payment_total_ttc).toFixed(2)} €
                </p>
              </Col>

              <Col lg='4'>
                <p><span>Montant restant : </span>{parseFloat(total_ttc - invoice.paid_total_ttc).toFixed(2)} €
                </p>
              </Col>


            </Row>

          <Row>
            <Col lg='4'>
              <span>Date de déblocage des fonds :</span>
              <Input
                type='date'
                value={invoice.fund_request_at?.substr(0, 10)}
                onChange={(e) => onFundRequestDateChange(e.currentTarget.value)}
              />
            </Col>
            <Col lg='4' className={"d-flex text-nowrap align-items-end"}>
              <span className={"mb-2 mr-4"}>Agence rattachée : </span>
              <Select
                  className='w-100'
                  value={[...agencyOptions].find((a) => a.value === invoice.order.agency_id)}
                  noOptionsMessage={() => "Aucune agence"}
                  onChange={(option) => {onAgencyChange(option.value);console.log(option.value)}}
                  placeholder="Sélectionner"
                  options={agencyOptions}
              />
            </Col>
          </Row>

        </Col>


        </CardBody>
      </Card>
    );
  }

  export default withRouter(
    connect(null, {
      getPaymentsDetails,
    })(InvoiceDetailComponent)
  );
