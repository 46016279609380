import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Media,
  UncontrolledCollapse,
  Button,
  Label,
  Modal,
} from "reactstrap";

// Redux
import { connect } from "react-redux";

import Moment from "react-moment";
import Select from "react-select";

import { getLastChildOrder } from "../../../helpers/helper";

//Import Date Picker
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

import BadgeState from "../badge-state";

// actions
import { updateProject } from "../../../store/actions";
import UsersForm from "../FormComponent/UsersForm";

// modals
import SerialNumberModal from "./Modals/SerialNumberModal";

function showInputResponseValue(item) {
  switch (item.input_response.input_field.input_type) {
    case "bool":
      return item.input_response.name === "true" ? "Oui" : "Non";
    default:
      return item.input_response.label || "NR";
  }
}

const DetailCard = (props) => {
  const [sellDate, setSellDate] = useState(
    props.project.sell_date && new Date(props.project.sell_date)
  );
  const [installationAt, setInstallationAt] = useState(
    props.project.installation_at && new Date(props.project.installation_at)
  );
  const [serialNumberModal, setSerialNumberModal] = useState(false);

  /**
   * Met à jour le projet avec les nouvelles données
   */
  const updateChanges = () => {
    let values = {};

    if (sellDate) values.sell_date = sellDate;
    else delete values.sell_date;

    if (installationAt) values.installation_at = installationAt;
    else delete values.installation_at;

    props.updateProject(props.project.id, values);
    props.setFlash_message(true);
  };

  let last_order = getLastChildOrder(props.project);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Media>
            <div className="avatar-md mr-4">
              <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                <img src="" alt="" height="30" />
              </span>
            </div>
            <Media className="overflow-hidden" body>
              <h5 className="text-truncate font-size-15 d-flex">
                <span className="mr-2">Details</span>
                <BadgeState state={props.project.project_state} />
              </h5>
              <p className="text-muted">Bon de commande</p>
            </Media>
          </Media>
          <Row className='mt-4'>
            <Col>
              <h5 className="font-size-15">Information :</h5>
              <UsersForm
                initValues={{
                  users_ids: props.project.users.map(e => e.id)
                }}
                handleSubmit={
                  (values) => props.updateProject(props.project.id, { user_ids: values.users_ids })
                }
              />
            </Col>
            <Col className='mt-4'>
              <p>Montant HT : {last_order?.total_price_ht.toFixed(2)} €</p>
              {last_order?.offer_code && <p>Promotion : {last_order.offer_code.amount.toFixed(2)} €</p>}
              <p>Montant TTC : {last_order?.total_price_ttc.toFixed(2)} €</p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              Agence :{" "}
              {props.project.users[0]?.agencies[0]?.name || "Pas d'agence"}
            </Col>
          </Row>
          {/* <Row className="mt-2">
            <Col>
              Contrat de maintenance :{" "}
              {props.project.has_maintenance_contract ? "Oui" : "Non"}
            </Col>
          </Row> */}
          <div className="pt-4">
            {props.questionsCategoryLabel.map((label, index) => (
              <div key={`l_${index}`} className="pb-3">
                <h6
                  id={`toggler_${index}`}
                  className="mb-3 font-14 d-flex justify-content-start align-items-center"
                  onClick={() => props.toggleQuestionIsOpen(label)}
                >
                  <span className="text-dark">{label}</span>
                  <i
                    className={`bx bx-chevron-${props.questionsIsOpen[label] ? "up" : "down"
                      } mr-1`}
                  ></i>
                </h6>
                <UncontrolledCollapse toggler={`toggler_${index}`}>
                  <div className="text-muted">
                    {props.questionsAnswersArray[label].map((item, i) => {
                      return (
                        <span key={`q_${i}`} className="d-inline-block w-100">
                          <i className="mdi mdi-chevron-right text-primary mr-1"></i>{" "}
                          {`${item.input_response.input_field.label
                            } : ${showInputResponseValue(item)}`}
                        </span>
                      );
                    })}
                  </div>
                </UncontrolledCollapse>{" "}
              </div>
            ))}
          </div>
          <div className="pt-3">
            {last_order?.free_items.map((free_item, index) => (
              <div key={`f_i_${index}`} className="pb-2">
                <p className="font-14">
                  <span className="text-dark">
                    {free_item.free_field.title}
                  </span>
                </p>
              </div>
            ))}
            {last_order?.product_items.map((product_item, index) => (
              <div key={`f_i_${index}`} className="pb-2">
                <p className="font-14">
                  <span className="text-dark">
                    {product_item.product.commercial_name}
                  </span>
                </p>
              </div>
            ))}
            {last_order && (
              (last_order.product_items && last_order.product_items.length > 0) ||
              (last_order.free_items && last_order.free_items.length > 0)
            ) && <Row className="mb-4">
                <Col xs="6" className="text-center">
                  <Button type="submit" className="btn btn-green btn-sm" onClick={() => setSerialNumberModal(true)}>
                    Modifier les numéros de série
                  </Button>
                </Col>
              </Row>}
          </div>
          <Row className="commercial-comment">
            <Col sm="6" xs="6">
              <div className="mt-4">
                <h5 className="font-size-14">
                  Commentaire commercial
                </h5>
                <p>{props.project.commercial_comment || 'Pas de commentaire'}</p>
              </div>
            </Col>
          </Row>
          <SerialNumberModal isOpen={serialNumberModal} onClose={() => setSerialNumberModal(false) } order={last_order} />
          <Row className="task-dates">
            <Col sm="6" xs="6">
              <div className="mt-4">
                <h5 className="font-size-14">
                  <i className="bx bx-calendar mr-1 text-primary"></i>
                  Creation
                </h5>
                <Moment format="DD - MM - YYYY" className="text-muted mb-0">
                  {props.project.created_at}
                </Moment>
              </div>
            </Col>
            <Col sm="6" xs="6">
              <div className="mt-4">
                <h5 className="font-size-14">
                  <i className="bx bx-calendar-check mr-1 text-primary"></i>
                  Mise à jour
                </h5>
                <Moment format="DD - MM - YYYY" className="text-muted mb-0">
                  {props.project.updated_at}
                </Moment>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs="4" className="pt-3">
              <Label>Date vente</Label>
              <DatePicker
                className="form-control button-without-style"
                selected={sellDate}
                onChange={(date) => {
                  setSellDate(date);
                }}
                dateFormat="dd/MM/yyyy"
                locale={fr}
                placeholderText="Date vente"
              />
            </Col>
            <Col xs="4" className="pt-3">
              <Label>Date d'installation :</Label>
              <DatePicker
                className="form-control button-without-style"
                selected={installationAt}
                onChange={(date) => {
                  setInstallationAt(date);
                }}
                dateFormat="dd/MM/yyyy"
                locale={fr}
                placeholderText="Date d’installation"
              />
            </Col>
            <Col xs="4" className="pt-3 border-left-dark-gray">
              {props.project && props.project.project_state ? (
                <div className="select2-container">
                  <Label>Modifier l'état</Label>
                  <Select
                    value={props.current_state}
                    onChange={(val) => {
                      props.handleSelectGroup(val);
                    }}
                    options={props.projects_states}
                    classNamePrefix="select2-selection"
                    isDisabled={
                      props.project.project_state.label === "draft" ||
                        props.project.project_state.label === "finish"
                        ? true
                        : false
                    }
                  />
                </div>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs="8" className="text-center pt-3 pb-3">
              <Button
                color="none"
                className="btn-green"
                onClick={updateChanges}
              >
                Sauvegarder les modifications
              </Button>
            </Col>
            <Col xs="4" className="text-center pt-3 pb-3 border-left-dark-gray">
              <Link
                to="#"
                className="btn btn-green"
                onClick={props.tog_standard}
                data-toggle="modal"
                data-target="#projectStateUpdate"
              >
                Valider
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal isOpen={props.modal_standard} toggle={props.tog_standard}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Projet - Etat
          </h5>
          <button
            type="button"
            onClick={() => props.setmodal_standard(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Attention</h5>
          <p>
            Voulez vous mettre à jour l'etat du projet à{" "}
            {props.current_state ? props.current_state.label : null} ?
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={props.tog_standard}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Annuler
          </button>
          <button
            type="button"
            onClick={() => props.handleUpdateProject()}
            className="btn btn-green waves-effect waves-light"
          >
            Mettre à jour
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { project } = state.Projects;

  return { project };
};

export default withRouter(
  connect(mapStatetoProps, { updateProject })(DetailCard)
);
