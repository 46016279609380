import { useState } from "react";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import UploadImage from "../../../components/Common/UploadImage";
import { getBase64 } from '../../../helpers/helper';
import LoaderLayoutPulse from "../../../components/Common/LoaderLayoutPulse";

//Import Date Picker
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

const ScaleForm = (props) => {

  const [documentsList, setDocumentsList] = useState(props.initValues.documents.map(
    (url) => {
      return {
        name: url,
        url_path: `${process.env.REACT_APP_API_URL}/files/${url}`
      }
    }
  ));
  const [importFiles, setImportFiles] = useState([]);

  const handleChangeDocuments = async (event, field, setFieldValue, values) => {
    let files = event.target.files;
    let c_files = values.documents;
    let documents = documentsList;
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let content = await getBase64(file);
      documents.push({
        url_path: content,
        name: file.name
      });
      c_files.push(file);
    }
    setDocumentsList(documents);
    setFieldValue(field, c_files)
  };

  const deleteDocumentWithIndex = async (index, field, setFieldValue, values) => {
    let c_files = values.documents;
    let documents = documentsList;
    c_files.splice(index, 1);
    documents.splice(index, 1);
    setDocumentsList(documents);
    setFieldValue(field, c_files)
  }

  const handleChangeImportFiles = async (files, field, setFieldValue, values) => {
    let documents = [];
    setFieldValue(field, files[0])
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let content = await getBase64(file);
      documents.push({
        url_path: content,
        name: file.name
      });
    }
    setImportFiles(documents);
  };

  return (
    <Formik
      initialValues={props.initValues}
      validationSchema={props.validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        props.handleSubmit(values);
      }}
      render={({ errors, touched, setFieldValue, values }) => (
        <Form>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <FormGroup className="mb-4" row>
                <Label htmlFor="label" className="col-form-label col-lg-2">
                  Label *
                </Label>
                <Col lg="10">
                  <Input
                    id="label"
                    name="label"
                    type="text"
                    tag={Field}
                    invalid={errors.label && !!touched.label}
                    className="form-control"
                    placeholder="Entrer un label"
                  />
                  <ErrorMessage name="label" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="min_monthly_payment" className="col-form-label col-lg-2">
                  Échéance minimum *
                </Label>
                <Col lg="10">
                  <Input
                    type="text"
                    name="min_monthly_payment"
                    id="min_monthly_payment"
                    tag={Field}
                    invalid={errors.min_monthly_payment && !!touched.min_monthly_payment}
                    className="form-control"
                    placeholder="Entrer un paiement mensuel minimum"
                  />
                  <ErrorMessage name="min_monthly_payment" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="min_amount_loan" className="col-form-label col-lg-2">
                  Montant minimum *
                </Label>
                <Col lg="10">
                  <Input
                    type="text"
                    name="min_amount_loan"
                    id="min_amount_loan"
                    tag={Field}
                    invalid={errors.min_amount_loan && !!touched.min_amount_loan}
                    className="form-control"
                    placeholder="Entrer un prêt minimum"
                  />
                  <ErrorMessage name="min_amount_loan" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="max_amount_loan" className="col-form-label col-lg-2">
                  Montant maximum *
                </Label>
                <Col lg="10">
                  <Input
                    type="text"
                    name="max_amount_loan"
                    id="max_amount_loan"
                    tag={Field}
                    invalid={errors.max_amount_loan && !!touched.max_amount_loan}
                    className="form-control"
                    placeholder="Entrer une prêt maximum"
                  />
                  <ErrorMessage name="max_amount_loan" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="ref" className="col-form-label col-lg-2">
                  Référence *
                </Label>
                <Col lg="10">
                  <Input
                    id="ref"
                    name="ref"
                    type="text"
                    tag={Field}
                    invalid={errors.ref && !!touched.ref}
                    className="form-control"
                    placeholder="Entrer une référence"
                  />
                  <ErrorMessage name="ref" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="starting_date" className="col-form-label col-lg-2">
                  Date de début de l'échéancier
                  </Label>
                <Col lg="10">
                  <DatePicker
                    id="starting_date"
                    name="starting_date"
                    className="form-control button-without-style"
                    dateFormat="dd/MM/yyyy"
                    selected={values.starting_date}
                    onChange={(date) => {
                      setFieldValue("starting_date", date);
                    }}
                    locale={fr}
                  />
                  <ErrorMessage name="starting_date" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="ending_date" className="col-form-label col-lg-2">
                  Date de fin de l'échéancier
                  </Label>
                <Col lg="10">
                  <DatePicker
                    id="ending_date"
                    name="ending_date"
                    className="form-control button-without-style"
                    dateFormat="dd/MM/yyyy"
                    selected={values.ending_date}
                    onChange={(date) => {
                      setFieldValue("ending_date", date);
                    }}
                    locale={fr}
                  />
                  <ErrorMessage name="ending_date" component={FormFeedback} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="first_monthly_payment_delay" className="col-form-label col-lg-2">
                  Délai de la première échéance (mois) *
                </Label>
                <Col lg="10">
                  <Input
                    type="text"
                    name="first_monthly_payment_delay"
                    id="first_monthly_payment_delay"
                    tag={Field}
                    invalid={errors.first_monthly_payment_delay && !!touched.first_monthly_payment_delay}
                    className="form-control"
                    placeholder="Entrer un délai de la première échéance"
                  />
                  <ErrorMessage name="first_monthly_payment_delay" component={FormFeedback} />
                </Col>
              </FormGroup>
              <UploadImage
                files={
                  documentsList.length > 0 ? documentsList : []
                }
                title="Documents (Barème au format .pdf) *"
                handleChange={handleChangeDocuments}
                deleteImageWithIndex={deleteDocumentWithIndex}
                max_file={50}
                field="documents"
                setFieldValue={setFieldValue}
                values={values}
                isInForm={true}
              />
              <UploadImage
                files={
                  importFiles.length > 0 ? importFiles : []
                }
                title="Excel des éléments du barème (.xlsx) *"
                handleChangeImages={handleChangeImportFiles}
                max_file={1}
                field="import_file"
                setFieldValue={setFieldValue}
                values={values}
                isInForm={true}
              />
            </div>
          </div>
          <Row className="justify-content-end">
            <Col lg="10">
              <Row>
                <Col lg="2">
                  <Button type="submit" color="primary">
                    {props.isUpdate ? "Modifier le barème" : "Créer le barème"}
                  </Button>
                </Col>
                {
                  props.loading ? (
                    <Col lg="3" md="4">
                      <div>
                        <p class="text-center">
                          En cours de traitement
                      </p>
                      </div>
                      <LoaderLayoutPulse />
                    </Col>
                  ) : props.error && props.error ? (
                    <Col lg="3" md="4">
                      <Alert color="danger" className="d-inline-block">
                        {props.error}
                      </Alert>
                    </Col>
                  ) : null
                }
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default ScaleForm;
