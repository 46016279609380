import { useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Modal, Button } from "reactstrap";

// Redux
import { connect } from "react-redux";

// Add VT Modal
import TechnicalVisitModal from "./Modals/TechnicalVisitModal";

// Helpers
import { getShortDateAndTimeWithFormat } from "../../../helpers/helper";

// actions
import {
  deleteTechnicalVisitProject,
  updateProject,
} from "../../../store/actions";

import { agendaItems } from "../../../api/index";

const TechnicalVisitCard = (props) => {
  const [showModalDisponibilityVt, setShowModalDisponibilityVt] = useState(false);
  const [disponibilityVt, setDisponibilityVt] = useState(null);
  const [disponibilityVtEndAt, setDisponibilityVtEndAt] = useState(null);
  const [technicians, setTechnicians] = useState([]);
  const [modalCommentVt, setModalCommentVt] = useState("");
  const [modal_technical_visit, setTechnical_visit] = useState(false);
  const [commentVt, setCommentVt] = useState(props.project.comment_vt);

  /**
   * Fonction qui permet de gérer la hauteur du textarea du champ commentaire
   * @param {object} e L'événement retourné par le textarea, qui contient la balise HTML du textarea
   */
  const updateTextareaHeight = (e) => {
    e.currentTarget.style.height = "auto";
    e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
  };

  /**
   * Remplace le champ commentaire par un textarea
   */
  const openEditComment = () => {
    setCommentVt(props.project.comment_vt);
  };

  /**
   * Met à jour le projet avec le commentaire et remplace le textarea par un champ commentaire
   * @param {boolean} save Permet de savoir si le projet doit être mis à jour ou non
   */
  const closeEditComment = (save = false) => {
    if (save) {
      props.updateProject(props.project.id, { comment_vt: commentVt });
      props.setFlash_message(true);
    }
    setCommentVt(false);
  };

  function modalDeleteTechnicalProject() {
    setTechnical_visit(!modal_technical_visit);
  }

  function handleDeleteTechnicalProject() {
    props.deleteTechnicalVisitProject(props.project.id, props.history);
    modalDeleteTechnicalProject();
    props.setFlash_message(true);
  }
  /**
   * Fonction au clique sur Ajouter une visite technique
   */
  const onShowModalDisponibilityVt = () => {
    let agenda_items = props.project.agenda_items.filter(
      (item) =>
        item.reason === "Visite technique" &&
        item.date_begin === props.project.disponibility_vt &&
        item.child_agenda_item === null &&
        item.deleted_at === null
    );

    if (agenda_items.length > 0) {
      let agendaItem = agenda_items[agenda_items.length - 1]
      setDisponibilityVt(agendaItem.date_begin);
      setDisponibilityVtEndAt(agendaItem.date_end);
      setModalCommentVt(agendaItem.comment || props.project.comment_vt);
      setTechnicians(agendaItem.technicians?.map((technician) => ({
        value: technician.id,
        label: `${technician.lastname} ${technician.firstname}`,
      })));
    } else {
      setDisponibilityVt(null);
      setDisponibilityVtEndAt(null);
      setModalCommentVt(null);
      setTechnicians(null);
    }

    setShowModalDisponibilityVt(!showModalDisponibilityVt);
  };

  /**
   * Mise à jour d'un projet avec la date de visite technique
   */
  const updateProjectWithDisponibilityVt = async () => {

    let agenda_item_id = props.project.agenda_items.filter(
      (item) =>
        item.reason === "Visite technique" &&
        item.date_begin === props.project.disponibility_vt &&
        item.child_agenda_item === null &&
        item.deleted_at === null
    )[0]?.id

    let data = {
      client_id: props.project.client.id,
      project_id: props.project.id,
      technicians: technicians
        ? technicians.map((technician, index) => technician.value)
        : [],
      reason: "Visite technique",
      comment: modalCommentVt,
      date_begin: disponibilityVt,
      date_end: disponibilityVtEndAt,
      address:
        props.project.client.address_construction_site === null ? false : true,
    };

    if (agenda_item_id) {
      await agendaItems.update(agenda_item_id, data);
    } else {
      await agendaItems.create(data);
    }

    props.updateProject(props.project.id, {
      disponibility_vt: disponibilityVt,
      comment_vt: modalCommentVt,
    });

    onShowModalDisponibilityVt();
    setDisponibilityVt(null);
    setDisponibilityVtEndAt(null);
    setCommentVt(false);
    setTechnicians([]);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-3 d-flex justify-content-between technical-visite-header">
          <div>Visite technique</div>
          {props.project.disponibility_vt && (
            <div>
              <Button
                color="none"
                className="btn-green mr-1"
                onClick={modalDeleteTechnicalProject}
              >
                Annuler
              </Button>
              <Button
                color="none"
                className="btn-green ml-1"
                onClick={onShowModalDisponibilityVt}
              >
                Modifier
              </Button>
            </div>
          )}
        </CardTitle>
        <div className="technical-visite-container mt-3">
          {props.project.disponibility_vt ? (
            <div className="technical-visite-date">
              <p className="technical-visite-label">Date et heure :</p>
              <p className="ml-2">
                {getShortDateAndTimeWithFormat(props.project.disponibility_vt)}
              </p>
            </div>
          ) : (
            <p>
              Il n'y a pas encore de visite technique prévue pour ce projet{" "}
              <Button
                color="none"
                className="btn-green ml-2"
                onClick={onShowModalDisponibilityVt}
              >
                Ajouter une visite technique
              </Button>
            </p>
          )}
          {commentVt !== false ? (
            <div>
              <p className="technical-visite-label">Commentaire :</p>
              <div className="technical-visite-content">
                <textarea
                  rows="2"
                  className="form-control"
                  placeholder="Ajouter un commentaire"
                  value={commentVt}
                  onChange={(e) => setCommentVt(e.currentTarget.value)}
                  onFocus={(e) => updateTextareaHeight(e)}
                  onInput={(e) => updateTextareaHeight(e)}
                />
                <div className="ml-3">
                  <i
                    className="fas fa-check"
                    onClick={() => closeEditComment(true)}
                  ></i>
                  <i
                    className="fas fa-times"
                    onClick={() => closeEditComment()}
                  ></i>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p className="technical-visite-label">Commentaire :</p>
              <div className="technical-visite-content">
                <p className="white-space-pre-line">
                  {props.project.comment_vt
                    ? props.project.comment_vt.replace("\r\n", "\\r\\n")
                    : ""}
                </p>
                <div>
                  <i
                    className="fas fa-pencil-alt ml-3"
                    onClick={openEditComment}
                  ></i>
                </div>
              </div>
            </div>
          )}
        </div>
      </CardBody>
      <Modal
        isOpen={modal_technical_visit}
        toggle={() => {
          modalDeleteTechnicalProject();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Projet - visite technique
          </h5>
          <button
            type="button"
            onClick={() => {
              modalDeleteTechnicalProject();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Attention</h5>
          <p>Voulez vous vraiment supprimer cette visite technique ?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              modalDeleteTechnicalProject();
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Annuler
          </button>
          <button
            type="button"
            onClick={() => {
              handleDeleteTechnicalProject();
            }}
            className="btn btn-green waves-effect waves-light"
          >
            Supprimer
          </button>
        </div>
      </Modal>
      <TechnicalVisitModal
        disponibility_vt={disponibilityVt}
        setDisponibility_vt={setDisponibilityVt}
        disponibility_vt_end_at={disponibilityVtEndAt}
        setDisponibility_vt_end_at={setDisponibilityVtEndAt}
        technicians={technicians}
        setTechnicians={setTechnicians}
        comment_vt={modalCommentVt}
        setComment_vt={setModalCommentVt}
        isOpen={showModalDisponibilityVt}
        toggle={onShowModalDisponibilityVt}
        updateProjectWithDisponibilityVt={updateProjectWithDisponibilityVt}
      />
    </Card>
  );
};

const mapStatetoProps = (state) => {
  const { project } = state.Projects;

  return { project };
};

export default withRouter(
  connect(mapStatetoProps, {
    deleteTechnicalVisitProject,
    updateProject,
  })(TechnicalVisitCard)
);
