import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";

// Common
import commonEN from "./locales/en/common.json";
import commonFR from "./locales/fr/common.json";

// Financer
import financerEN from "./locales/en/financer.json";
import financerFR from "./locales/fr/financer.json";

// Offers
import offerEN from "./locales/en/offer.json";
import offerFR from "./locales/fr/offer.json";

// the translations
const resources = {
  en: {
    common: commonEN,
    financer: financerEN,
    offer: offerEN,
  },
  fr: {
    common: commonFR,
    financer: financerFR,
    offer: offerFR
  },
};

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr",
    fallbackLng: "en", // use en if detected lng is not available

    ns: ['common', 'financer', 'offer'],
    defaultNS: 'common',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
