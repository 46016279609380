import {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {Container, Row, Col, Card, CardBody, CardTitle} from "reactstrap";

// Redux
import {connect} from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {
    createRefundFromOrder,
    showRefund,
    getVats,
    getOrder,
    getInvoice,
    ordersApiError,
    vatsApiError,
} from "../../store/actions";

import { typeForm } from "../../helpers/quotes_invoices_helper";
import QuoteInvoiceComponent from "../Quotes/Components/QuoteInvoiceComponent";
import LoaderLayoutPulse from "../../components/Common/LoaderLayoutPulse";

const RefundEdit = ({getVats, getInvoice, loading, invoice, error, invoice_order, order, loadingOrder}) => {

    useEffect(() => {
      getVats();
    }, [getVats]);

    useEffect(() => {
        getInvoice(parseInt(window.location.pathname.split("/")[4], 10));
    }, [getInvoice]);

    return (
        <div className="page-content">
            <Container fluid className="quotes-container">
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Factures" breadcrumbItem="Modifier une facture"/>

                {!loading && invoice ?
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4">
                                    Modifier la facture{" "}
                                    {invoice.number === null ||
                                    invoice.number === undefined ||
                                    invoice.is_draft
                                        ? ""
                                        : `N°${invoice.number}`}
                                </CardTitle>
                                <QuoteInvoiceComponent
                                    typeForm={typeForm.refund}
                                    invoice={invoice}
                                    error={error}
                                    invoice_order={invoice_order}
                                    isUpdate={true}
                                    order={order}
                                    loadingOrder={loadingOrder}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row> : <LoaderLayoutPulse/>}
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const {order, invoice_order, file_url, error} = state.Orders;
    const loadingOrder = state.Orders.loading;
    const {invoice, loading} = state.Invoices;
    const {vats} = state.Vats;

    return {order, invoice_order, file_url, vats, loading, error, invoice, loadingOrder};
};

export default withRouter(
    connect(mapStatetoProps, {
        createRefundFromOrder,
        showRefund,
        getVats,
        getOrder,
        getInvoice,
        ordersApiError,
        vatsApiError,
    })(RefundEdit)
);
