import React from 'react';
import { OptionTypeBase } from 'react-select';
import { V2 } from '../../../api';
import LoaderLayoutPulse from "../../../components/Common/LoaderLayoutPulse";
import AgendaItem from '../../../models/agenda_item';
import User from '../../../models/user';
import TechnicalMeetingForm from '../../Projects/FormComponent/TechnicalMeetingForm';
import { CustomMarker } from '../Components/CustomMarker';

interface TechnicalMeetingFormWrapperProps {
  marker: CustomMarker
}

interface TechnicalMeetingFormWrapperState {
  disponibilityVt: Date | null,
  disponibilityVtEnd: Date | null,
  technicians: OptionTypeBase,
  commentVt: string | null,
  loading: boolean,
}

class TechnicalMeetingFormWrapper extends React.Component<TechnicalMeetingFormWrapperProps, TechnicalMeetingFormWrapperState> {

  constructor(props: TechnicalMeetingFormWrapperProps) {
    super(props);

    let { marker } = props;

    let commentVt : string | null = marker.entity.project?.comment_vt || null;
    let disponibilityVt : Date | null = null;
    let disponibilityVtEnd : Date | null = null;
    let technicians : OptionTypeBase[] = [];

    if (marker.entity.agenda_item) {
      disponibilityVt = marker.entity.agenda_item?.date_begin || disponibilityVt;
      disponibilityVtEnd = marker.entity.agenda_item?.date_end || disponibilityVtEnd;
      technicians = marker.entity.agenda_item.technicians!.map((technician: User) => ({
        label: `${technician.lastname} ${technician.firstname}`,
        value: technician.id
      }));
    }

    this.state = {
      disponibilityVt,
      disponibilityVtEnd,
      technicians,
      commentVt,
      loading: false
    };
  }

  async upsert() {
    this.setState({ loading: true });
    let {
      disponibilityVt,
      disponibilityVtEnd,
      technicians,
      commentVt
    } = this.state;

    let { marker } = this.props;

    await V2.agenda_items.upsert(
      {
        id: marker.entity.agenda_item?.id,
        date_begin: disponibilityVt,
        date_end: disponibilityVtEnd,
        comment: commentVt,
        technicians: technicians.map((technician: OptionTypeBase) => technician.value)
      } as AgendaItem);

    this.setState({ loading: false });
  }

  render() {
    let {
      disponibilityVt,
      disponibilityVtEnd,
      technicians,
      commentVt,
      loading
    } = this.state;

    return (
        <>
          <TechnicalMeetingForm
              disponibility_vt={disponibilityVt}
              setDisponibility_vt={(disponibilityVt: Date | null) => this.setState({ disponibilityVt: disponibilityVt })}
              disponibility_vt_end_at={disponibilityVtEnd}
              setDisponibility_vt_end_at={(disponibilityVtEnd: Date | null) => this.setState({ disponibilityVtEnd: disponibilityVtEnd })}
              technicians={technicians}
              setTechnicians={(technicians: OptionTypeBase) => this.setState({ technicians: technicians })}
              comment_vt={commentVt}
              setComment_vt={(commentVt: string | null) => this.setState({ commentVt: commentVt })}
          />
          { loading && <LoaderLayoutPulse/> }
        </>
    );
  }

}

export default TechnicalMeetingFormWrapper;