import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATION,
  CREATE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
} from "./actionTypes";

import {
  getNotificationsSuccess,
  getNotificationSuccess,
  createNotificationSuccess,
  updateNotificationSuccess,
  deleteNotificationSuccess,
  notificationsApiError,
} from "./actions";

import { notifications } from "../../api";

function* getNotifications({ payload: { query } }) {
  try {
    const response = yield call(notifications.getAll, query);
    yield put(getNotificationsSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          notificationsApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des relances"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(notificationsApiError(error.response.data.message));
      }
    } else {
      yield put(notificationsApiError(error.message));
    }
  }
}

function* getNotification({ payload: { id } }) {
  try {
    const response = yield call(notifications.getOne, id);
    yield put(getNotificationSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        notificationsApiError(
          "Vous n'êtes pas autorisé à accéder aux informations de cette relance."
        )
      );
    } else if (error.response.status === 404) {
      yield put(notificationsApiError("La relance n'a pas été trouvé"));
    } else if (error.response.status !== undefined) {
      yield put(notificationsApiError(error.response.data.message));
    } else {
      yield put(notificationsApiError(error.message));
    }
  }
}

function* createNotification({ payload: { notification, history } }) {
  try {
    const response = yield call(notifications.create, notification);
    yield put(createNotificationSuccess(response));
    history.push("/relances-list");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        notificationsApiError("vous n'êtes pas autorisé à créer une relance")
      );
    } else if (error.response.status === 404) {
      yield put(
        notificationsApiError("vous n'êtes pas autorisé à créer une relance")
      );
    } else if (error.response.data !== undefined) {
      yield put(notificationsApiError(error.response.data.message));
    } else {
      yield put(notificationsApiError(error.message));
    }
  }
}

function* updateNotification({ payload: { id, notification, history } }) {
  try {
    const response = yield call(notifications.update, id, notification);
    yield put(updateNotificationSuccess(response));
    if (history !== null) history.push("/relances-list");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        notificationsApiError("vous n'êtes pas autorisé à modifier la relance")
      );
    } else if (error.response.status === 404) {
      yield put(
        notificationsApiError("vous n'êtes pas autorisé à modifier la relance")
      );
    } else if (error.response.data !== undefined) {
      yield put(notificationsApiError(error.response.data.message));
    } else {
      yield put(notificationsApiError(error.message));
    }
  }
}

function* deleteNotification({ payload: { id, history } }) {
  try {
    const response = yield call(notifications.delete, id);
    yield put(deleteNotificationSuccess(response));
    if (history !== null) history.push("/projects-list?success_delete=1");
  } catch (error) {
    if (error.response?.status === 401) {
      yield put(
        notificationsApiError(
          "Vous n'êtes pas autorisé à supprimer cette relance"
        )
      );
    } else if (error.response?.data !== undefined) {
      yield put(notificationsApiError(error.response.data.message));
    } else {
      yield put(notificationsApiError(error.message));
    }
  }
}

export function* watchGetNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, getNotifications);
}
export function* watchGetNotification() {
  yield takeEvery(GET_NOTIFICATION, getNotification);
}
export function* watchCreateNotification() {
  yield takeEvery(CREATE_NOTIFICATION, createNotification);
}
export function* watchUpdateNotification() {
  yield takeEvery(UPDATE_NOTIFICATION, updateNotification);
}
export function* watchDeleteNotification() {
  yield takeEvery(DELETE_NOTIFICATION, deleteNotification);
}

function* notificationsSaga() {
  yield all([
    fork(watchGetNotifications),
    fork(watchGetNotification),
    fork(watchCreateNotification),
    fork(watchUpdateNotification),
    fork(watchDeleteNotification),
  ]);
}

export default notificationsSaga;
