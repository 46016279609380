import { withRouter } from "react-router-dom";
import { Row, Col, CardBody, Card, Button, Label, Input } from "reactstrap";
import { Formik, Field, Form } from 'formik';

// Redux
import { connect } from "react-redux";

import { FormikAutocompleteUnselectable } from "../../../components/Common/AsyncAutoComplete2";

// Actions
import { getProjectStates } from "../../../store/actions";
import { promiseUsersOptions } from "../../../helpers/autocomplete_promise_options";
import { ALL_FILTER, PARRAINAGE_FILTER } from "../customers-list";

const Filter = ({
  filters,
  setFilters,
  filterMode = ALL_FILTER
}) => {

  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      onSubmit={(values) => {
        setFilters({
          ...filters,
          user_id: values.user_id,
          zip_code: values.zip_code,
          type: values.type,
          is_waiting_paid: values.is_waiting_paid,
          without_mail: values.without_mail,
          without_phone: values.without_phone,
        })
      }}
    >
      {({ values, setFieldValue, handleSubmit}) => (
        <Form>
          <Card>
            <CardBody>
              <Row>
                <Col md="3">
                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label for="user_id">Commercial</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        name="user_id"
                        id="user_id"
                        isSearchable={false}
                        noOptionsMessage={() => "Aucun commerciaux disponible"}
                        placeholder="Choisir un commercial..."
                        loadOptions={() => promiseUsersOptions("Commercial")}
                      />
                    </Col>
                  </Row>
                </Col>
                {filterMode !== PARRAINAGE_FILTER ? <Col md="3">
                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label for="zip_code">Code postal</Label>
                    </Col>
                    <Col xs="12">
                      <Input
                        id="zip_code"
                        name="zip_code"
                        type="text"
                        tag={Field}
                      />
                    </Col>
                  </Row>
                </Col> : null}
                
               {/*  <Col md="3">
                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label for="type">Parrain/Filleul</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocomplete
                        className="w-100"
                        name="type"
                        id="type"
                        isSearchable={false}
                        noOptionsMessage={() => "Aucun type disponible"}
                        placeholder="Choisir un type"
                        loadOptions={[
                          {
                            label: "Parrain",
                            value: "godfathers",
                          },
                          {
                            label: "Filleul",
                            value: "godchildren",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col> */}
                {filterMode === PARRAINAGE_FILTER ? 
                  <Col md="3">
                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label for="is_waiting_paid">Paiement en attente ?</Label>
                    </Col>
                    <Col xs="12" /*className="custom-control-with-margin custom-control custom-switch"*/>
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        name="is_waiting_paid"
                        id="is_waiting_paid"
                        isSearchable={false}
                        noOptionsMessage={() => "Aucun type disponible"}
                        placeholder="Choisir un type"
                        loadOptions={[
                          {
                            label: "Oui",
                            value: true,
                          },
                          {
                            label: "Non",
                            value: false,
                          },
                        ]}
                      />
                      {/* <Input
                        id="is_waiting_paid"
                        name="is_waiting_paid"
                        type="checkbox"
                        tag={Field}
                        className="custom-control-input ml-3"
                      />
                      <Label
                        for="is_waiting_paid"
                        className="custom-control-label"
                      >
                        {values.is_waiting_paid
                          ? "Oui"
                          : "Non"}
                      </Label> */}
                    </Col>
                  </Row>
                </Col>: null}
           
              {filterMode !== PARRAINAGE_FILTER ? 
              <>
               <Col md="3">
                 <Row>
                   <Col xs="12" className="mt-3">
                     <Label for="without_mail">
                       Prospects/clients sans mail ?
                     </Label>
                   </Col>
                   <Col
                     xs="12"
                     className="custom-control-with-margin custom-control custom-switch"
                   >
                     <Input
                       id="without_mail"
                       name="without_mail"
                       type="checkbox"
                       tag={Field}
                       className="custom-control-input ml-3"
                     />
                     <Label
                       for="without_mail"
                       className="custom-control-label"
                     >
                       {values.without_mail
                         ? "Oui"
                         : "Non"}
                     </Label>
                   </Col>
                 </Row>
               </Col>
               <Col md="3">
                 <Row>
                   <Col xs="12" className="mt-3">
                     <Label for="without_phone">
                       Prospects/clients sans téléphone ?
                     </Label>
                   </Col>
                   <Col
                     xs="12"
                     className="custom-control-with-margin custom-control custom-switch"
                   >
                     <Input
                       id="without_phone"
                       name="without_phone"
                       type="checkbox"
                       tag={Field}
                       className="custom-control-input ml-3"
                     />
                     <Label
                       for="without_phone"
                       className="custom-control-label"
                     >
                       {values.without_phone
                         ? "Oui"
                         : "Non"}
                     </Label>
                   </Col>
                 </Row>
               </Col>
               </>
             : null}
              </Row>
               
              <Row>
                <Col
                  xs="12"
                  className="d-flex justify-content-center mt-3"
                >
                  <Button
                    color="none"
                    onClick={() => handleSubmit(values)}
                  >
                    Appliquer les filtres
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

const mapStatetoProps = (state) => {
  const { projectStates } = state.ProjectStates;

  return { projectStates };
};

export default withRouter(
  connect(mapStatetoProps, { getProjectStates })(Filter)
);
