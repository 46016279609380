import {Badge, Card, CardBody, CardFooter, CardTitle, Col, NavItem, NavLink, Row} from "reactstrap";
import {addFlashMessage, ImportantEnum} from "../../../components/Common/FlashMessage";
import React, {useEffect, useState} from "react";
import Client from "../../../models/client";
import {V2} from "../../../api";
import GlobalLoader from "../../../components/Common/GlobalLoader";
import {Link} from "react-router-dom";
import RequestCustomerTabGlobalView from "./Tabs/global-view";
import HistoryTable from "./Tabs/Tables/history-table";
import ProjectTable from "./Tabs/Tables/project-table";
import InterventionFileTable from "./Tabs/Tables/intervention-files-table";
import {parseQuery, setQuery} from "../../../helpers/helper";
import {parseCustomerName} from "../../../helpers/customer_helper";
import {History} from "history";

interface CustomerDetailsComponentProps {
    customerId: number,
    history: History
}

export default function CustomerDetailsComponent({
                                            customerId,
                                            history
                                        }: CustomerDetailsComponentProps) {
    const query = parseQuery();

    const [client, setClient] = useState<Client | null>(null);
    const [filters, setFilters] = useState({tab: query.tab ? parseInt(query.tab as string) : 0});
    const [historyFilters, setHistoryFilters] = useState(
        {sort_by: "id", sort_dir: "desc", page: 1, page_size: 25});
    const [projectsFilters, setProjectsFilters] = useState(
        {sort_by: "id", sort_dir: "desc", page: 1, page_size: 25, groups: ['card_info']});
    const [interventionFilesFilters, setInterventionFilesFilters] = useState(
        {sort_by: "id", sort_dir: "desc", page: 1, page_size: 25});

    const enum tabs {GLOBAL, HISTORY, PROJECTS, FILES}

    useEffect(() => {
        V2.customers.get(customerId, {groups: ["request_info"]}).then(({data}) => setClient(data))
    }, [customerId]);

    useEffect(() => {
        setQuery(filters, history, true)
    }, [filters, history]);

    return (
        <>
            {client ? (
                <>
                    <Row>
                        <Col lg="12">
                            <Card className={'collaborators-container'}>
                                <CardBody>
                                    <CardTitle className="mb-0">
                                        <Row className="pr-2 pl-2 d-flex">
                                            <Col>Informations du contact</Col>
                                        </Row>
                                    </CardTitle>
                                    <Row className="pr-2 pl-2 d-flex font-size-15 text-theme-color">
                                        <Col xs={"4"} className={"mt-3"}>
                                            <span className="font-weight-semibold">Nom : </span>
                                            <span
                                                className="font-weight-light">{parseCustomerName(client)}</span>
                                        </Col>
                                        <Col xs={"4"} className={"mt-3"}>
                                            <span className="font-weight-semibold">Téléphone : </span>
                                            <span className="font-weight-light">{client.mobile_phone}</span>
                                        </Col>
                                        <Col xs={"4"} className={"mt-3"}>
                                            <span className="font-weight-semibold">Mail : </span>
                                            <span className="font-weight-light">{client.email}</span>
                                        </Col>
                                        <Col xs={"4"} className={"mt-3"}>
                                            <span className="font-weight-semibold">Addresse : </span>
                                            <span
                                                className="font-weight-light">{client.address} {client.zip_code} {client.city}</span>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <Row className="container-text-theme-color-2">
                                        <Col xs="12" className="d-flex justify-content-end">
                                            <Link
                                                to={`/customer/detail/${customerId}`}
                                                className="d-flex align-items-center mr-5"
                                            >
                                                <i className="bx bx-pencil mr-1"></i> Voir les détails
                                            </Link>
                                            <Link
                                                to={`/customer/edit/${customerId}`}
                                                className="d-flex align-items-center mr-5"
                                            >
                                                <i className="bx bx-pencil mr-1"></i> Modifier
                                            </Link>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col>
                            <Card className={"p-3"}>
                                <ul className="nav nav-tabs nav-tabs-custom mb-3">
                                    <NavItem>
                                        <NavLink className={filters.tab === tabs.GLOBAL ? "active" : ""}
                                                 onClick={() => {
                                                     setFilters({tab: tabs.GLOBAL});
                                                 }}
                                        >Vue d'ensemble</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={(filters.tab === tabs.HISTORY ? "active " : "") + (client.important_requests ? "danger" : "")}
                                            onClick={() => {
                                                setFilters({tab: tabs.HISTORY});
                                            }}
                                        >Historique
                                            {client.important_requests ?
                                                <i className="fas fa-exclamation-triangle ml-1"/> : null}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={(filters.tab === tabs.PROJECTS ? "active" : "") + " d-flex align-items-center"}
                                            onClick={() => {
                                                setFilters({tab: tabs.PROJECTS});
                                            }}
                                        >Projets <Badge
                                            className={"ml-1" + (filters.tab === tabs.PROJECTS ? " bg-blue" : " bg-theme-color")}>{client.project_count}</Badge></NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={(filters.tab === tabs.FILES ? "active" : "") + " d-flex align-items-center"}
                                            onClick={() => {
                                                setFilters({tab: tabs.FILES});
                                            }}
                                        >Dossiers <Badge
                                            className={"ml-1" + (filters.tab === tabs.FILES ? " bg-blue" : " bg-theme-color")}>{client.intervention_folder_count}</Badge></NavLink>
                                    </NavItem>
                                    <div className="flex-grow-1"/>
                                    <Link
                                        onClick={() => {
                                            let win = window.open(`/subscription/add?customer_id=${customerId}&new_tab=true`, "_blank");
                                            if (win) {
                                                win.onbeforeunload = () => {
                                                    addFlashMessage(ImportantEnum.SUCCESS, "Votre abonnement a bien été créé");
                                                    window.location.reload();
                                                };
                                            }
                                        }}
                                        to={`/subscription/add?customer_id=${customerId}&new_tab=true`}
                                        className="d-flex align-items-center btn btn-green text-white mr-3 mb-1"
                                        data-toogle="modal"
                                        data-target="#selectClientType"
                                        target={'_blank'}
                                    >
                                        <i className="bx bx-plus-circle mr-2>"/> Abonnement
                                    </Link>
                                    <Link
                                        to={`/requests/customers/${client.id}/interventions/add/maintenance`}
                                        className="d-flex align-items-center btn btn-green text-white mr-3 mb-1"
                                        data-toogle="modal"
                                        data-target="#selectClientType"
                                    >
                                        <i className="bx bx-plus-circle mr-2>"/> Dossier entretien
                                    </Link>
                                    <Link
                                        to={`/requests/customers/${client.id}/interventions/add/sav`}
                                        className="d-flex align-items-center btn btn-green text-white mr-3 mb-1"
                                        data-toogle="modal"
                                        data-target="#selectClientType"
                                    >
                                        <i className="bx bx-plus-circle mr-2>"/> Dossier SAV
                                    </Link>
                                    <Link
                                        to={`/requests/customers/${client.id}/requests/add`}
                                        className="d-flex align-items-center btn btn-green text-white mr-3 mb-1"
                                        data-toogle="modal"
                                        data-target="#selectClientType"
                                    >
                                        <i className="bx bx-plus-circle mr-2>"/> Nouvelle demande
                                    </Link>
                                </ul>
                                <div>
                                    {filters.tab === tabs.GLOBAL ?
                                        <Row className="font-weight-semibold font-size-16">
                                            <Col xs={"4"}>Derniers projets</Col>
                                            <Col xs={"4"}>Derniers dossiers</Col>
                                            <Col xs={"4"}>Abonnements</Col>
                                        </Row> : null}
                                    {filters.tab === tabs.HISTORY ?
                                        (
                                            <HistoryTable importantRequests={client.important_requests || null}
                                                          filters={{...historyFilters, client_id: client.id}}
                                                          setFilters={(f) => {
                                                              setHistoryFilters({...historyFilters, ...f})
                                                          }}/>
                                        )
                                        : null}
                                    {filters.tab === tabs.PROJECTS ?
                                        (
                                            <ProjectTable filters={{...projectsFilters, client_id: client.id}}
                                                          setFilters={(f) => {
                                                              setProjectsFilters({...projectsFilters, ...f})
                                                          }}/>
                                        )
                                        : null}
                                    {filters.tab === tabs.FILES ?
                                        (
                                            <InterventionFileTable
                                                filters={{...interventionFilesFilters, client_id: client.id}}
                                                setFilters={(f) => {
                                                    setInterventionFilesFilters({...interventionFilesFilters, ...f})
                                                }}/>
                                        )
                                        : null}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    {filters.tab === tabs.GLOBAL ? <RequestCustomerTabGlobalView client={client}/> : null}
                </>
            ) : <GlobalLoader/>}
        </>
    );
}