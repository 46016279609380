import { History } from "history";
import { useCallback } from "react";
import { useEffect, useMemo, useState } from "react";
import { match } from 'react-router';
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { offers as offersApi } from "../../api";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GlobalLoader from "../../components/Common/GlobalLoader";
import Offer from "../../models/offer";
import OfferForm from "./Components/Forms/offer-form";
// Validation Schema
import { createValidationSchema } from "./Components/FormValidationSchemas/createOfferValidationSchema";
import { updateValidationSchema } from "./Components/FormValidationSchemas/updateOfferValidationSchema";

export interface OfferUpsertProps {
  match: match<{ id: string }>,
  history: History
}

const OfferUpsert = ({
  match,
  history
}: OfferUpsertProps) => {
  const offerId = useMemo(() => parseInt(match.params.id), [match.params.id]);
  let [offer, setOffer] = useState<Offer | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getOffer = useCallback(() => {
    offersApi.getOne(offerId).then(response => {
      setOffer(response.data);
      setLoading(false);
    }).catch(offers => {
      setLoading(false);
    });
  }, [offerId]);
  useEffect(() => { if (offerId) { getOffer() } else { setLoading(false) } }, [getOffer, offerId]);

  return (
    loading ? <GlobalLoader /> : <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Promotions" breadcrumbItem={offerId ? 'Modifier une promotion' : 'Créer une promotion'} />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">{offerId ? 'Modifier une promotion' : 'Créer une promotion'}</CardTitle>
                <OfferForm
                  offer={offer || {}}
                  validationSchema={offerId ? updateValidationSchema : createValidationSchema}
                  history={history}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OfferUpsert;
