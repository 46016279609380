export const GET_AGENCIES = "GET_AGENCIES";
export const GET_AGENCIES_SUCCESS = "GET_AGENCIES_SUCCESS";

export const GET_AGENCY = "GET_AGENCY";
export const GET_AGENCY_SUCCESS = "GET_AGENCY_SUCCESS";

export const CREATE_AGENCY = "CREATE_AGENCY";
export const CREATE_AGENCY_SUCCESS = "CREATE_AGENCY_SUCCESS";

export const UPDATE_AGENCY = "UPDATE_AGENCY";
export const UPDATE_AGENCY_SUCCESS = "UPDATE_AGENCY_SUCCESS";

export const DELETE_AGENCY = "DELETE_AGENCY";
export const DELETE_AGENCY_SUCCESS = "DELETE_AGENCY_SUCCESS";

export const AGENCIES_API_ERROR = "AGENCIES_API_ERROR";
