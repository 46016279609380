import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { GET_ROLES } from "./actionTypes";
import { getRolesSuccess, roleApiError } from "./actions";

import { roles } from "../../api";

function* getRoles({ payload: { query } }) {
  try {
    const response = yield call(roles.getAll, query);
    yield put(getRolesSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        roleApiError("Vous n'êtes pas autorisé à accéder à la liste des rôles")
      );
    } else if (error.response.data !== undefined) {
      yield put(roleApiError(error.response.data.message));
    } else {
      yield put(roleApiError(error.message));
    }
  }
}

export function* watchGetRoles() {
  yield takeEvery(GET_ROLES, getRoles);
}

function* rolesSaga() {
  yield all([fork(watchGetRoles)]);
}

export default rolesSaga;
