import { AxiosResponse } from "axios";

export default interface Pagination {
    current_page?: number,
    total_count?: number,
    current_page_size?: number,
}

export function getPaginationFromResponse<T>(response: AxiosResponse<T>) : Pagination {
    let pagination_headers = JSON.parse(response.headers["x-pagination"]);
    return pagination_headers as Pagination;
}