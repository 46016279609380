import { useEffect, useState } from "react";
import Project from "../../../../models/project";
import { Badge, Card, Col, Row } from "reactstrap";
import LoaderLayoutPulse from "../../../../components/Common/LoaderLayoutPulse";
import {V2 } from "../../../../api";
import Client from "../../../../models/client";
import moment from "moment";
import { getStatusColor } from "../../../../models/requests_status";
import Subscription from "../../../../models/subscription";
import InterventionFolder from "../../../../models/intervention_folder";
//<SearchFilterWithButton placeholder={"Rechercher une demande"} defaultValue={""} onSearch={() => {}} containerStyles={{width:"30rem"}}/>

interface RequestCustomerTabGlobalViewProps {
  client: Client,
}

export default function RequestCustomerTabGlobalView({
  client,
}: RequestCustomerTabGlobalViewProps) {
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [interventions, setInterventions] = useState<InterventionFolder[] | null>(null);
  const [subscriptions, setSubscriptions] = useState<Subscription[] | null>(null);

  useEffect(() => {
    let filters = { client_id: client.id, sort: "created_at DESC", page: 1, page_size: 3, groups:["card_info"]};
    Promise.all([
      V2.projects.getAll(filters),
      V2.interventions.getAll(filters),
      V2.subscriptions.getAll(filters)
    ]).then(([projectsResult, interventionsResult, subscriptionsResult]) => {
      setProjects(projectsResult.data);
      setInterventions(interventionsResult.data);
      setSubscriptions(subscriptionsResult.data);
    });
  }, [client.id]);


  return (
    <Row>
      <Col xs={4}>
        {projects ?
          projects.map(p => (
            <Card className={"p-3"} key={p.id + "project_card"}>
              <Row>
                <Col xs={"6"}>
                  <span className="font-weight-semibold">Date : </span>
                  <span className="font-weight-light">{moment(p.created_at).format('DD/MM/YYYY')}</span>
                </Col>
                <Col xs={"6"}>
                  <span className="font-weight-semibold">Etat : </span>
                  <span className="font-weight-light"><Badge className={`badge-color-${p.project_state?.label}`}>{p.project_state?.name}</Badge></span>
                </Col>
                <Col xs={"6"} className={"mt-3"}>
                  <span className="font-weight-semibold">Produit : </span>
                  <span className="font-weight-light">{p.first_product_label || "non défini"}</span>
                </Col>
                <Col xs={"6"} className={"mt-3"}>
                  <span className="font-weight-semibold">Contrat : </span>
                  <span className="font-weight-light">{p.first_subscription_label || "non défini"}</span>
                </Col>
                <Col xs={"6"} className={"mt-3"}>
                  <span className="font-weight-light text-theme-color">{<a href={"/projects-overview/"+p.id} target="_blank" rel="noreferrer">En Détails <i className="fa fa-angle-right"/></a>}</span>
                </Col>
              </Row>
            </Card>
          ))
          :
          <Col xs={"4"} className={"d-flex justify-content-center"}>
            <LoaderLayoutPulse />
          </Col>
        }
      </Col>
      <Col xs={4}>
        {interventions ?
          interventions.map(i => (
            <Card className={"p-3"} key={i.id + "intervention_card"}>
              <Row>
                <Col xs={"6"}>
                  <span className="font-weight-semibold">Date : </span>
                  <span className="font-weight-light">{moment(i.created_at).format('DD/MM/YYYY')}</span>
                </Col>
                <Col xs={"6"}>
                  <span className="font-weight-semibold">Statut : </span>
                  <span className="font-weight-light"><Badge style={{ background: getStatusColor(i.status || {}) }}>{i.status?.label}</Badge></span>
                </Col>
                <Col xs={"6"} className={"mt-3"}>
                  <span className="font-weight-semibold">Produit : </span>
                  <span className="font-weight-light">{i.project?i.project.first_product_label:"Non défini"}</span>
                </Col>
                <Col xs={"6"} className={"mt-3"}>
                  <span className="font-weight-semibold">Type : </span>
                  <span className="font-weight-light">{i.intervention_folder_type === "maintenance" ? "Entretien" : "SAV"}</span>
                </Col>
                <Col xs={"12"} className={"mt-3"}>
                  <span className="font-weight-semibold">Commentaire : </span>
                  <span className="font-weight-light">{i.comment}</span>
                </Col>
              </Row>
            </Card>
          ))
          :
          <Col xs={"4"} className={"d-flex justify-content-center"}>
            <LoaderLayoutPulse />
          </Col>
        }
      </Col>
      <Col xs={4}>
        {subscriptions ?
          subscriptions.map(s => (
            <Card className={"p-3"} key={s.id + "subscription_card"}>
              <Row>
                <Col xs={"6"}>
                  <span className="font-weight-semibold">Numero : </span>
                  <span className="font-weight-light">{s.number}</span>
                </Col>
                <Col xs={"6"}>
                  <span className="font-weight-semibold">Prix mensuel : </span>
                  <span className="font-weight-light">{s.formule_price} €</span>
                </Col>
                <Col xs={"6"} className={"mt-3"}>
                  <span className="font-weight-semibold">Prise d'effet le : </span>
                  <span className="font-weight-light">{moment(s.start_at).format('DD/MM/YYYY')}</span>
                </Col>
                <Col xs={"6"} className={"mt-3"}>
                  <span className="font-weight-semibold">Fin le : </span>
                  <span className="font-weight-light">{moment(s.end_at).format('DD/MM/YYYY')}</span>
                </Col>
                <Col xs={"6"} className={"mt-3"}>
                  <span className="font-weight-semibold">Formule : </span>
                  <span className="font-weight-light">{s.formule_label}</span>
                </Col>
              </Row>
            </Card>
          ))
          :
          <Col xs={"4"} className={"d-flex justify-content-center"}>
            <LoaderLayoutPulse />
          </Col>
        }
      </Col>
    </Row>
  );
}