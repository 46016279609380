import {useCallback, useEffect, useState} from 'react';
import Select from 'react-select';
import debounce from "debounce";

/**
 * @typedef {object} SelectComponentOptionParam
 * @property {any} value
 * @property {any} label
 */

/**
 * @typedef {object} SelectComponentParams
 * @property {string=} name form name of the component
 * @property {any=} value value by default
 * @property {import('react').ReactNode=} placeholder placeholder on the select field
 * @property {import('react').ReactNode=} label label rendered next to the select
 * @property {( SelectComponentOptionParam[] | ((query?:string)=>SelectComponentOptionParam[]) | ((query?:string)=>Promise<SelectComponentOptionParam[]>) )} options an object of options the type is [{label: "label1", value: "value1"}, {label: "label2", value: "value2"}, ....]
 * @property {string=} classNameSelector className for the selector
 * @property {boolean=} isDisabled false by default
 * @property {boolean=} isAsync specified if the selector is asynchrone, for databases requests if true, options are loadOptions
 * @property {((value:any)=>void)=} handleChange function handling the changement of value. The function is called with the value of the select as parameter
 * @property {string=} classNameLabel the class name of the label joining the select
 * @property {string=} className
 * @property {boolean=} isMulti
 * @property {object=} error errors from yup {name: message, ...}
 * @property {boolean=} showError
 */

/**
 * @description Selector component
 *
 * @param {SelectComponentParams} params form name of the component
 */
const SelectComponent = ({
    name = undefined,
    value = undefined,
    placeholder =  undefined,
    label = undefined,
    options,
    classNameSelector = "",
    classNameLabel = "",
    className = "",
    handleChange = undefined,
    isDisabled = false,
    isAsync = false,
    isMulti = false,
    error = undefined,
    showError = false
}) => {
    const [loadOptions, setLoadOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [touched, setTouched] = useState(false);

    const debouncedSearch = useCallback((searchTerms) => debounce((searchTerm) => {
      setIsLoading(true);
      options(searchTerm)
        .then((result) => {
            setLoadOptions(result);
            setIsLoading(false);
            setTouched(true);
        })
        .catch((error) => console.log(error))
    }, 350)(searchTerms), [options]);

    useEffect(()=>{
      if(!touched && typeof options === "function")debouncedSearch("");
    }, [touched, options, debouncedSearch])

    return (
        <div className={"form-group mb-4 " + className}>
            {label && <label className={"col-form-label " + classNameLabel} htmlFor={name}>{label}</label>}
            <div className={classNameSelector}>
            {isAsync ?
                <Select
                  name={name}
                  className={(error !== undefined && showError ? "parsley-error" : "")}
                  placeholder={placeholder}
                  options={loadOptions}
                  value={value}
                  onChange={(val) => handleChange(val)}
                  isDisabled={isDisabled}
                  defaultOptions
                  isMulti={isMulti}
                  onInputChange={debouncedSearch}
                  isLoading={isLoading}
                />
            :
                <Select
                    className={(error !== undefined && showError ? "parsley-error" : "")}
                    placeholder={placeholder}
                    value={value}
                    options={options}
                    onChange={(val) => handleChange(val.value)}
                    isDisabled={isDisabled}
                    isMulti={isMulti}
                />
            }
            {error !== undefined && showError ? <span className="error">{error}</span> : ""}
            </div>
        </div>

    );

}

export default SelectComponent;