import {
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS, DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS, GET_SUBSCRIPTION, GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCESS, GET_SUBSCRIPTION_SUCCESS, SUBSCRIPTIONS_API_ERROR, UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_VALIDATED,
  UPDATE_SUBSCRIPTION_VALIDATED_SUCCESS,
} from "./actionTypes";

const initialState = {
  subscriptions: [],
  subscription: null,
  error: false,
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {

    case GET_SUBSCRIPTIONS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_SUBSCRIPTIONS_SUCCESS:
      state = {
        ...state,
        subscriptions: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        loading: false,
        error: false,
      };
      break;

    case GET_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
      }
      break;

    case GET_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        subscription: action.payload.data,
        loading: false,
        error: false,
      }
      break;

    case CREATE_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
      }
      break;

    case CREATE_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false
      }
      break;

    case UPDATE_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
      }
      break;

    case UPDATE_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      }
      break;

    case UPDATE_SUBSCRIPTION_VALIDATED:
      state = {
        ...state,
        loading: true,
      }
      break;

    case UPDATE_SUBSCRIPTION_VALIDATED_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      }
      break;

    case DELETE_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
      }
      break;

    case DELETE_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      }
      break;


    case SUBSCRIPTIONS_API_ERROR:
      state = {...state, error: action.payload, loading: false};
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default subscriptions;
