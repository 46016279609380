export const GET_STATISTICS = "GET_STATISTICS";
export const GET_STATISTICS_SUCCESS = "GET_STATISTICS_SUCCESS";

export const STATISTICS_API_ERROR = "STATISTICS_API_ERROR";

export const GET_FAMILY_REPARTITION = "GET_FAMILY_REPARTITION";
export const GET_FAMILY_REPARTITION_SUCCESS = "GET_FAMILY_REPARTITION_SUCCESS";

export const GET_CATEGORY_REPARTITION = "GET_CATEGORY_REPARTITION";
export const GET_CATEGORY_REPARTITION_SUCCESS = "GET_CATEGORY_REPARTITION_SUCCESS";

export const GET_PRODUCT_REPARTITION = "GET_PRODUCT_REPARTITION";
export const GET_PRODUCT_REPARTITION_SUCCESS = "GET_PRODUCT_REPARTITION_SUCCESS";

export const GET_ALL_REPARTITIONS = "GET_ALL_REPARTITIONS";
export const GET_ALL_REPARTITIONS_SUCCESS = "GET_ALL_REPARTITIONS_SUCCESS";