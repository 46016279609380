import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Alert, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

// Bootstrap Table
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import loader
import GlobalLoader from "../../components/Common/GlobalLoader";

// actions
import { getUsers, userApiError } from "../../store/actions";

// Images fond
import fond_1 from "../../assets/images/collaborators/fleur.png";

const CollaboratorsList = (props) => {
  const [sort, setSort] = useState({ order: null, field: null });
  const [flash_message, setFlash_message] = useState(false);

  useEffect(() => {
    props.getUsers.call();
  }, [props.getUsers]);

  function removeFlashMessage() {
    window.history.pushState({}, document.title, "/collaborators");
    setFlash_message(!flash_message);
  }

  const urlParams = new URLSearchParams(window.location.search);

  function onTableChange(type, newState) {
    if (
      (type === "sort" && newState.sortOrder !== sort.order) ||
      type === "pagination"
    ) {
      let query = {
        page: newState.page || 1,
        page_size: newState.sizePerPage,
      };

      if (type === "sort") {
        setSort({
          field: newState.sortField,
          order: newState.sortOrder,
        });
      }

      if (newState.sortField && newState.sortOrder) {
        let sortQuery = `${newState.sortField} ${newState.sortOrder}`;
        query["sort"] = sortQuery;
      }

      props.getUsers(query);
    }
  }

  const columns = [
    {
      dataField: "img",
      text: "",
      formatter: (cell, row) => {
        return row.img === undefined ? (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              {row.firstname !== null ? row.firstname.charAt(0) : "A"}
            </span>
          </div>
        ) : (
          <div>
            <img className="rounded-circle avatar-xs" src={row.img} alt="" />
          </div>
        );
      },
    },
    {
      dataField: "lastname",
      text: "Nom",
      formatter: (cell, row) => (
        <React.Fragment>
          <h5 className="font-size-14 mb-1">
            <Link
              to={`/collaborator/detail/${row.id}`}
              className="table-text-black"
            >
              {row.firstname + " " + row.lastname}
            </Link>
          </h5>
          <p className="mb-0">{row.role.name}</p>
        </React.Fragment>
      ),
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "agencies",
      text: "Agence",
      formatter: (cell, row) => {
        return row.agencies && row.agencies.length > 0
          ? row.agencies.map((agency, idx) => (
              <span key={idx}>
                {idx > 0 ? ", " : null}
                {agency.name}
              </span>
            ))
          : "Pas d'agence";
      },
    },
    {
      dataField: "phone",
      text: "Téléphone",
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <UncontrolledDropdown>
          <DropdownToggle className={"bg-transparent"}>
            <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
          </DropdownToggle>
          <DropdownMenu right className="bordered important-default-colors">
            <DropdownItem onClick={() => { props.history.push(`/collaborator/detail/${row.id}`) }}>Voir</DropdownItem>
            <DropdownItem onClick={() => { window.open(`/collaborator/detail/${row.id}`, '_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
      classes: "isNotNecessary",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="collaborators-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Collaborateurs"
            breadcrumbItem="Tous les collaborateurs"
          />
          <Row>
            <Col lg="12">
              <Alert
                color="success"
                isOpen={urlParams.get("success_add") === "1"}
                toggle={() => removeFlashMessage()}
              >
                Le collaborateurs à bien été créé.
              </Alert>
              <Alert
                color="success"
                isOpen={urlParams.get("success_update") === "1"}
                toggle={() => removeFlashMessage()}
              >
                Le collaborateurs à bien été mise à jour.
              </Alert>
              <Alert
                color="success"
                isOpen={urlParams.get("success_delete") === "1"}
                toggle={() => removeFlashMessage()}
              >
                Le collaborateurs à bien été suprimé.
              </Alert>
            </Col>
          </Row>
          {props.loading ? (
            <GlobalLoader />
          ) : props.pagination ? (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">
                      <Row className="pr-2 pl-2 d-flex justify-content-between">
                        <Col>Tous les collaborateurs</Col>
                        <Col className="d-flex justify-content-end">
                          <Link
                            to="/collaborator/add"
                            className="d-flex align-items-center text-theme-color-2"
                          >
                            <i className="bx bx-plus-circle mr-1"></i> Ajouter
                          </Link>
                        </Col>
                      </Row>
                    </CardTitle>
                    <BootstrapTable
                      keyField="id"
                      data={props.users}
                      columns={columns}
                      classes="table-centered"
                      defaultSortDirection="asc"
                      sort={{ dataField: sort.field, order: sort.order }}
                      remote={{ pagination: true, sort: true }}
                      onTableChange={onTableChange}
                      pagination={paginationFactory({
                        page: props.pagination.current_page,
                        totalSize: props.pagination.total_count,
                        sizePerPage: props.pagination.current_page_size,
                        sizePerPageList: [
                          { text: "Afficher 10 éléments", value: 10 },
                          { text: "Afficher 25 éléments", value: 25 },
                          { text: "Afficher 50 éléments", value: 50 },
                          { text: "Afficher 75 éléments", value: 75 },
                          { text: "Afficher 100 éléments", value: 100 },
                        ],
                      })}
                    />
                  </CardBody>
                </Card>
                <div className="collaborators-footer-img">
                  <div className="collaborators-footer-img-main-container">
                    <div className="collaborators-footer-img-container">
                      <img src={fond_1} alt="Fond numéro 1" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { users, pagination, loading } = state.Users;
  return { users, pagination, loading };
};

export default withRouter(
  connect(mapStatetoProps, { getUsers, userApiError })(CollaboratorsList)
);
