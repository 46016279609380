import AgendaItem from "./agenda_item";
import Client from "./client";
import Disponibility from "./disponibility";
import InterventionFolder from "./intervention_folder";
import Project from "./project";
import DefaultFilter from "./types/default_filter";

export enum MapItemState {
  HIDDEN = 'hidden',
  VISIBLE = 'visible'
}

export enum MapItemInterventionType {
  INSTALLATION = 'installation',
  MAINTENANCE = 'maintenance',
  TROUBLESHOOTING = 'troubleshooting',
  TECHNICAL_VISIT = 'technical_visit',
  // AUDIT = 'audit'
}

export function labelOfMapItemInterventionType(type: MapItemInterventionType) {
  switch (type) {
    case MapItemInterventionType.INSTALLATION: return 'Installation';
    case MapItemInterventionType.MAINTENANCE: return 'Entretien';
    case MapItemInterventionType.TROUBLESHOOTING: return 'Dépannage';
    case MapItemInterventionType.TECHNICAL_VISIT: return 'Visite technique';
    // case MapItemInterventionType.AUDIT: return 'Audit';
  }
}

export function colorOfMapItemInterventionType(type: MapItemInterventionType) {
  switch (type) {
    case MapItemInterventionType.INSTALLATION: return 'brown';
    case MapItemInterventionType.MAINTENANCE: return 'pink';
    case MapItemInterventionType.TROUBLESHOOTING: return 'green';
    case MapItemInterventionType.TECHNICAL_VISIT: return 'blue';
    // case MapItemInterventionType.AUDIT: return 'orange';
  }
}

export interface MapItemFilters extends DefaultFilter {
  start_at?: string | null,
  end_at?: string | null,
  intervention_types?: MapItemInterventionType[] | null,
}

export interface MapItem {
  id: number | null,
  client: Client,
  project?: Project,
  agenda_item?: AgendaItem,
  product_name?: string,
  state: MapItemState,
  intervention_type: MapItemInterventionType,
  displayable: Project | InterventionFolder,
  planified: boolean,
  date?: Date | null,
  disponibilities?: Disponibility[]
}