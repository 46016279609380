import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import ProductForm from "./FormComponent/ProductForm";

// Validation Schema
import { updateValidationSchema } from "./FormValidationSchemas/updateValidationSchema";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {
  getProduct,
  updateProduct,
  productApiError,
  getCategories,
  getVats,
} from "../../store/actions";

const ProductEdit = (props) => {
  useEffect(() => {
    props.getProduct.call(undefined, parseInt(window.location.pathname.split("/")[3], 10));
  }, [props.getProduct]);

  useEffect(() => {
    props.getCategories.call();
  }, [props.getCategories]);

  useEffect(() => {
    props.getVats.call();
  }, [props.getVats]);

  const initValues = props.product
    ? {
        title: props.product.title,
        quote_description: props.product.quote_description,
        commercial_name: props.product.commercial_name,
        brand: props.product.brand,
        description: props.product.description,
        reference: props.product.reference,
        category: props.product.category.id,
        sub_category: props.product.sub_category
          ? props.product.sub_category.id
          : "",
        vat: props.product.vat.id,
        price: props.product.price,
        price_ttc: props.product.price_ttc,
        promotional_offer:
          props.product.promotional_offer === null
            ? ""
            : props.product.promotional_offer,
        promotional_offer_ttc:
          props.product.promotional_offer_ttc === null
            ? ""
            : props.product.promotional_offer_ttc,
        eco_tax: props.product.eco_tax,
        image: props.product.image,
        has_warm_water: props.product.has_warm_water,
        phase: props.product.phase,
        workforces_ids: props.product.workforces.map(workforce => workforce.id),
      }
    : {
        title: "",
        quote_description: "",
        commercial_name: "",
        brand: "",
        description: "",
        reference: "",
        category: "",
        sub_category: "",
        vat: "",
        price: "",
        price_ttc: "",
        promotional_offer: "",
        promotional_offer_ttc: "",
        eco_tax: "",
        image: "",
        has_warm_water: "",
        phase: "",
        workforces_ids: [],
      };

  const handleSubmit = (values) => {
    props.updateProduct(
      parseInt(window.location.pathname.split("/")[3], 10),
      {
        title: values.title,
        quote_description: values.quote_description,
        commercial_name: values.commercial_name,
        brand: values.brand,
        description: values.description,
        reference: values.reference,
        category: values.category,
        sub_category: values.sub_category,
        vat: values.vat,
        price: values.price,
        price_ttc: values.price_ttc,
        promotional_offer: values.promotional_offer,
        promotional_offer_ttc: values.promotional_offer_ttc,
        eco_tax: values.eco_tax,
        image: values.image,
        has_warm_water: values.has_warm_water,
        phase: values.phase,
        workforces_ids: values.workforces_ids,
      },
      props.history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Produits" breadcrumbItem="Modifier un produit" />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Modifier un produit</CardTitle>
                {props.loading ? null : !props.product ? null : (
                  <ProductForm
                    initValues={initValues}
                    validationSchema={updateValidationSchema}
                    handleSubmit={handleSubmit}
                    categories={props.categories}
                    vats={props.vats}
                    product={props.product}
                    error={props.error}
                    isUpdate={true}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { product, loading, error } = state.Products;
  const { categories } = state.Categories;
  const { vats } = state.Vats;
  return { product, loading, error, categories, vats };
};

export default withRouter(
  connect(mapStatetoProps, {
    getProduct,
    getCategories,
    getVats,
    updateProduct,
    productApiError,
  })(ProductEdit)
);
