import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Input, Button } from "reactstrap";
import Select from "react-select";

// Redux
import { connect } from "react-redux";

// actions
import { updateProject } from "../../../store/actions";
import { fourniture_label_options } from "../../../helpers/helper";

const FournitureFeesCard = (props) => {
  const [fournitureFeesRefBL, setFournitureFeesRefBL] = useState("");
  const [fournitureFeesLabel, setFournitureFeesLabel] = useState("");
  const [fournitureFeesAmountHt, setFournitureFeesAmountHt] = useState("");
  const [
    fournitureFeesRefBLToModify,
    setFournitureFeesRefBLToModify,
  ] = useState("");
  const [
    fournitureFeesLabelToModify,
    setFournitureFeesLabelToModify,
  ] = useState("");
  const [
    fournitureFeesAmountHtToModify,
    setFournitureFeesAmountHtToModify,
  ] = useState("");
  const [fournitureFeesList, setFournitureFeesList] = useState([]);
  const [
    fournitureFeesIndexToModify,
    setFournitureFeesIndexToModify,
  ] = useState(-1);

  // Récupère les dépenses à afficher
  useEffect(() => {
    setFournitureFeesList(
      props.project.fourniture_fees.map((fourniture) => ({
        id: fourniture.id,
        reference_bl: fourniture.reference_bl,
        label: fourniture_label_options.find(e => e.value === fourniture.label) || { label: fourniture.label, value: fourniture.label },
        amount_ht: fourniture.amount_ht,
      }))
    );
  }, [props.project.fourniture_fees]);

  /**
   * Ajouter la nouvelle dépense au tableau
   */
  const addFournitureFees = () => {
    let temp = fournitureFeesList.map((e) => e);
    if (
      fournitureFeesRefBL !== "" &&
      fournitureFeesLabel !== "" &&
      fournitureFeesAmountHt !== ""
    ) {
      temp.push({
        reference_bl: fournitureFeesRefBL,
        label: fournitureFeesLabel,
        amount_ht: fournitureFeesAmountHt,
      });
      setFournitureFeesRefBL("");
      setFournitureFeesLabel("");
      setFournitureFeesAmountHt("");
    }
    setFournitureFeesList(temp);
  };

  const toggleFournitureFeesToModify = (
    index,
    reference_bl,
    label,
    amount_ht
  ) => {
    setFournitureFeesIndexToModify(index);
    setFournitureFeesRefBLToModify(reference_bl);
    setFournitureFeesLabelToModify(label);
    setFournitureFeesAmountHtToModify(amount_ht);
  };

  /**
   * Modifier la dépense dans le tableau
   * @param {number} fourniture_id L'identifiant de la dépense à modifier
   * @param {number} index L'index de lélément ajouté et pas encore attaché au projet
   */
  const modifyFournitureFees = () => {
    let temp = fournitureFeesList.map((e) => e);

    temp[fournitureFeesIndexToModify] = {
      ...temp[fournitureFeesIndexToModify],
      reference_bl: fournitureFeesRefBLToModify,
      label: fournitureFeesLabelToModify,
      amount_ht: fournitureFeesAmountHtToModify,
      isModify: true,
    };

    setFournitureFeesList(temp);
    setFournitureFeesIndexToModify(-1);
  };

  /**
   * Supprimer une dépense du tableau
   * @param {number} fourniture_id L'identifiant de la dépense à supprimer
   * @param {number} index L'index de lélément ajouté et pas encore attaché au projet
   */
  const removeFournitureFees = (fourniture_id, index) => {
    let temp = fournitureFeesList.map((e) => e);

    if (fourniture_id === -1) {
      temp.splice(index, 1);
    } else {
      temp[index] = {
        id: fourniture_id,
        _destroy: true,
      };
    }

    setFournitureFeesList(temp);
  };

  /**
   * Met à jour le projet avec les nouvelles données des fournitures
   */
  const updateChanges = () => {
    let temp = fournitureFeesList
      .filter(
        (elem) =>
          (elem.id ? elem._destroy : true) || (elem.id ? elem.isModify : true)
      )
      .map((e) => e);

    if (temp.length > 0) {
      props.updateProject(props.project.id, {
        fourniture_fees_attributes: temp.map(e => {return {...e, label: e.label.value}}),
      });
      props.setFlash_message(true);
    }
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-3 d-flex justify-content-between">
          <div>Fournitures</div>
        </CardTitle>
        <div className="fourniture-fees-new">
          <div className="fourniture-fees-new-row">
            <div className="fourniture-fees-new-item">
              <Input
                type="text"
                value={fournitureFeesRefBL}
                onChange={(e) => {
                  setFournitureFeesRefBL(e.currentTarget.value);
                }}
                placeholder="N° BL"
              />
            </div>
            <div className="fourniture-fees-new-item">
              <Select
                value={fournitureFeesLabel}
                placeholder="Fournisseur"
                onChange={(val) => {
                  setFournitureFeesLabel(
                    val
                  );
                }}
                options={fourniture_label_options}
                classNamePrefix="select2-selection"
              />
            </div>
            <div className="fourniture-fees-new-item">
              <Input
                type="text"
                value={fournitureFeesAmountHt}
                onChange={(e) => {
                  setFournitureFeesAmountHt(e.currentTarget.value);
                }}
                placeholder="Montant"
              />
            </div>
            <div className="fourniture-fees-add-input">
              <span onClick={addFournitureFees}>
                <i className="fas fa-plus"></i>
              </span>
            </div>
          </div>
        </div>
        <div>
          {fournitureFeesList.map((fourniture, index) =>
            fourniture._destroy ? null : (
              <div
                className="fourniture-fees-info"
                key={`disponibility-${index}`}
              >
                <div className="fourniture-fees-new-row">
                  {fournitureFeesIndexToModify === index ? (
                    <React.Fragment>
                      <div className="fourniture-fees-new-item">
                        <Input
                          type="text"
                          value={fournitureFeesRefBLToModify}
                          onChange={(e) => {
                            setFournitureFeesRefBLToModify(
                              e.currentTarget.value
                            );
                          }}
                          placeholder="N° BL"
                        />
                      </div>
                      <div className="fourniture-fees-new-item">
                        <Select
                          value={fournitureFeesLabelToModify}
                          placeholder="Fournisseur"
                          onChange={(val) => {
                            setFournitureFeesLabelToModify(
                              val
                            );
                          }}
                          options={fourniture_label_options}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                      <div className="fourniture-fees-new-item">
                        <Input
                          type="text"
                          value={fournitureFeesAmountHtToModify}
                          onChange={(e) => {
                            setFournitureFeesAmountHtToModify(
                              e.currentTarget.value
                            );
                          }}
                          placeholder="Montant"
                        />
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="fourniture-fees-new-item">
                      {`N° BL : ${
                        fourniture.reference_bl || "NR"
                      } - fournisseur : ${
                        fourniture.label.label
                      } - montant : ${parseFloat(fourniture.amount_ht).toFixed(
                        2
                      )} €`}
                    </div>
                  )}
                  <div className="fourniture-fees-info-buttons">
                    {fournitureFeesIndexToModify === index ? (
                      <span onClick={modifyFournitureFees}>
                        <i className="fas fa-check"></i>
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          toggleFournitureFeesToModify(
                            index,
                            fourniture.reference_bl,
                            fourniture.label,
                            fourniture.amount_ht
                          )
                        }
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </span>
                    )}
                    <span
                      className="ml-2"
                      onClick={() =>
                        removeFournitureFees(
                          fourniture.id ? fourniture.id : -1,
                          index
                        )
                      }
                    >
                      <i className="fas fa-times"></i>
                    </span>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        <div className="fourniture-fees-new">
          <div className="text-center">
            <Button color="none" className="btn-green" onClick={updateChanges}>
              Sauvegarder les modifications
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStatetoProps = (state) => {
  const { project } = state.Projects;

  return { project };
};

export default withRouter(
  connect(mapStatetoProps, { updateProject })(FournitureFeesCard)
);
