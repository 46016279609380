import {Col, FormGroup, Label, Row} from "reactstrap";
import { FormikAutocompleteUnselectable } from "../../../components/Common/AsyncAutoComplete2";

export const subscriptionFilter = {
  YES: 1,
  NO: 2,
}

export const computeFilterSelect = (value) => {
  switch (value) {
    case subscriptionFilter.YES : return true;
    case subscriptionFilter.NO : return false;
    default: return null;
  }
}

const SubscriptionsFilter = ({
                               values,
                             }) => {

  const selectOptions = [
    {
      value: subscriptionFilter.YES,
      label: 'Oui',
    },
    {
      value: subscriptionFilter.NO,
      label: 'Non',
    },
  ];

  return (
    <Col lg='6'>
      <Row>
        <Col>
          <Label for='validated'>En cours de validité</Label>
          <FormGroup>
            <FormikAutocompleteUnselectable
              name='validated'
              id='validated'
              loadOptions={selectOptions}
            />
          </FormGroup>
        </Col>
        <Col>
          <Label for='to_renew'>A renouveler</Label>
          <FormGroup>
            <FormikAutocompleteUnselectable
              name='to_renew'
              id='to_renew'
              loadOptions={selectOptions}
            />
          </FormGroup>
        </Col>
      </Row>
    </Col>
  );
}

export default SubscriptionsFilter;