import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_ORDERS,
  GET_ORDER,
  CREATE_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
  CREATE_INVOICE_FROM_ORDER,
  CREATE_REFUND_FROM_ORDER,
  GET_GENERATE_INVOICE,
  GET_GENERATE_REFUND,
  GET_GENERATE_QUOTE,
  SHOW_INVOICE,
  SHOW_REFUND,
} from "./actionTypes";
import {
  getOrdersSuccess,
  getOrderSuccess,
  createOrderSuccess,
  updateOrderSuccess,
  deleteOrderSuccess,
  createInvoiceFromOrderSuccess,
  createRefundFromOrderSuccess,
  getGenerateInvoiceSuccess,
  getGenerateRefundSuccess,
  getGenerateQuoteSuccess,
  showInvoiceSuccess,
  showRefundSuccess,
  ordersApiError,
} from "./actions";
import { getInvoicesSuccess } from "../invoices/actions";

import { orders, invoices } from "../../api";

function* getOrders({ payload: { query } }) {
  try {
    const response = yield call(orders.getAll, query);
    yield put(getOrdersSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        ordersApiError(
          "Vous n'êtes pas autorisé à accéder à la liste des commandes"
        )
      );
    } else if (error.response.data !== undefined) {
      yield put(ordersApiError(error.response.data.message));
    } else {
      yield put(ordersApiError(error.message));
    }
  }
}

function* getOrder({ payload: { id } }) {
  try {
    const response = yield call(orders.getOne, id);
    yield put(getOrderSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        ordersApiError(
          "Vous n'êtes pas autorisé à accéder aux informations de cette commande"
        )
      );
    } else if (error.response.status === 404) {
      yield put(ordersApiError("La commande n'a pas été trouvée"));
    } else if (error.response.data !== undefined) {
      yield put(ordersApiError(error.response.data.message));
    } else {
      yield put(ordersApiError(error.message));
    }
  }
}

function* createOrder({ payload: { order, history } }) {
  try {
    console.log("##### [SAGA] CREATE ORDER REQUEST", order);
    const response = yield call(orders.create, order);
    console.log("##### [SAGA] CREATE ORDER RESPONSE", response);
    yield put(createOrderSuccess(response));
    history.push("/invoices?success_add=1");
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield put(
        ordersApiError("Vous n'êtes pas autorisé à ajouter une commande")
      );
    } else if (
      error.response.status === 400 &&
      error.response.data.errors.length > 0
    ) {
      if (
        error.response.data.errors[0].detail.civility &&
        error.response.data.errors[0].detail.free_items.quantity[0] ===
          "must be greater than or equal to 0.0"
      ) {
        yield put(
          ordersApiError(
            "La quantité d'un champ libre doit toujours être positive"
          )
        );
      }
    } else if (error.response && error.response.data !== undefined) {
      yield put(ordersApiError(error.response.data.message));
    } else {
      yield put(ordersApiError(error.message));
    }
  }
}

function* updateOrder({ payload: { id, order, history } }) {
  try {
    console.log("##### [SAGA] UDPATE ORDER REQUEST", order);
    const response = yield call(orders.update, id, order);
    console.log("##### [SAGA] UDPATE ORDER RESPONSE", response);
    yield put(updateOrderSuccess(response));
    history.push("/invoices?success_update=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        ordersApiError("Vous n'êtes pas autorisé à modifier cette commande")
      );
    } else if (error.response.status === 404) {
      yield put(ordersApiError("Le commande n'a pas été trouvée"));
    } else if (error.response.data !== undefined) {
      yield put(ordersApiError(error.response.data.message));
    } else {
      yield put(ordersApiError(error.message));
    }
  }
}

function* deleteOrder({ payload: { id, history } }) {
  try {
    const response = yield call(orders.delete, id);
    yield put(deleteOrderSuccess(response));
    history.push("/orders?success_delete=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        ordersApiError("Vous n'êtes pas autorisé à supprimer cette commande")
      );
    } else if (error.response.data !== undefined) {
      yield put(ordersApiError(error.response.data.message));
    } else {
      yield put(ordersApiError(error.message));
    }
  }
}

function* createInvoiceFromOrder({ payload: { order, history } }) {
  try {
    const responseOrder = yield call(orders.create, order);
    const response = yield call(
      orders.getGenerateInvoice,
      responseOrder.data.id,
      order,
      true
    );
    yield put(createInvoiceFromOrderSuccess(responseOrder, response));
    window.open(
      process.env.REACT_APP_API_URL + "/files/" + response.data.file_path
    );
    history.push("/invoices?success_add=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        ordersApiError(
          "Vous n'êtes pas autorisé à générer la facture de cette commande"
        )
      );
    } else if (error.response.status === 404) {
      yield put(ordersApiError("La commande n'a pas été trouvée"));
    } else if (error.response.data !== undefined) {
      yield put(ordersApiError(error.response.data.message));
    } else {
      yield put(ordersApiError(error.message));
    }
  }
}

function* createRefundFromOrder({
  payload: { invoice_id, history, order, is_free_order },
}) {
  try {
    if (is_free_order) {
      const response = yield call(
        orders.getGenerateRefundWithOrder,
        invoice_id,
        order,
        order.id !== undefined ? order.id : null
      );
      yield put(createRefundFromOrderSuccess(response));
      window.open(
        process.env.REACT_APP_API_URL + "/files/" + response.data.file_path
      );
    } else {
      const response = yield call(orders.getGenerateRefund, invoice_id);
      yield put(createRefundFromOrderSuccess(response));
      window.open(
        process.env.REACT_APP_API_URL + "/files/" + response.data.file_path
      );
    }
    const responseGetAll = yield call(invoices.getAll, {});
    yield put(getInvoicesSuccess(responseGetAll));
    history.push("/invoices?success_add=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        ordersApiError(
          "Vous n'êtes pas autorisé à générer l'avoir de cette commande"
        )
      );
    } else if (error.response.status === 404) {
      yield put(ordersApiError("La commande n'a pas été trouvée"));
    } else if (error.response.data !== undefined) {
      yield put(ordersApiError(error.response.data.message));
    } else {
      yield put(ordersApiError(error.message));
    }
  }
}

function* getGenerateInvoice({
  payload: { order, values, open = false, history },
}) {
  try {
    const response = yield call(
      orders.getGenerateInvoice,
      order.id,
      values,
      true
    );
    yield put(getGenerateInvoiceSuccess(response));
    if (open) {
      window.open(
        process.env.REACT_APP_API_URL + "/files/" + response.data.file_path
      );
      if (history) history.push(`/invoices`);
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        ordersApiError(
          "Vous n'êtes pas autorisé à générer la facture de cette commande"
        )
      );
    } else if (error.response.status === 404) {
      yield put(ordersApiError("La commande n'a pas été trouvée"));
    } else if (error.response.data !== undefined) {
      yield put(ordersApiError(error.response.data.message));
    } else {
      yield put(ordersApiError(error.message));
    }
  }
}

function* getGenerateRefund({
  payload: { order, values, open = false, history },
}) {
  try {
    const response = yield call(
      orders.getGenerateRefund,
      order.id,
      values,
      true
    );
    yield put(getGenerateRefundSuccess(response));
    if (open) {
      window.open(
        process.env.REACT_APP_API_URL + "/files/" + response.data.file_path
      );
      history.push(`/invoices`);
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        ordersApiError(
          "Vous n'êtes pas autorisé à générer l'avoir de cette commande"
        )
      );
    } else if (error.response.status === 404) {
      yield put(ordersApiError("La commande n'a pas été trouvée"));
    } else if (error.response.data !== undefined) {
      yield put(ordersApiError(error.response.data.message));
    } else {
      yield put(ordersApiError(error.message));
    }
  }
}

function* getGenerateQuote({ payload: { id } }) {
  try {
    const response = yield call(orders.getGenerateQuote, id);
    yield put(getGenerateQuoteSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        ordersApiError(
          "Vous n'êtes pas autorisé à générer le devis de cette commande"
        )
      );
    } else if (error.response.status === 404) {
      yield put(ordersApiError("La commande n'a pas été trouvée"));
    } else if (error.response.data !== undefined) {
      yield put(ordersApiError(error.response.data.message));
    } else {
      yield put(ordersApiError(error.message));
    }
  }
}

function* showInvoice({ payload: { order, isUpdate } }) {
  try {
    if (isUpdate) {
      const responseOrder = yield call(orders.update, isUpdate.id, order);
      const response = yield call(
        orders.getGenerateInvoice,
        responseOrder.data.id,
        order,
        true
      );
      yield put(showInvoiceSuccess(response));
      window.open(
        process.env.REACT_APP_API_URL + "/files/" + response.data.file_path
      );
    } else {
      const responseOrder = yield call(orders.create, order);
      const response = yield call(
        orders.getGenerateInvoice,
        responseOrder.data.id,
        order,
        true
      );
      yield put(showInvoiceSuccess(response));
      window.open(
        process.env.REACT_APP_API_URL + "/files/" + response.data.file_path
      );
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          ordersApiError("Vous n'êtes pas autorisé prévisualiser cette facture")
        );
      } else if (error.response.data !== undefined) {
        yield put(ordersApiError(error.response.data.message));
      }
    } else {
      yield put(ordersApiError(error.message));
    }
  }
}

function* showRefund({ payload: { invoice_id, history, order, isUpdate } }) {
  try {
    if (isUpdate) {
      const response = yield call(
        orders.getGenerateRefundWithOrder,
        invoice_id,
        order,
        isUpdate.id
      );
      yield put(showRefundSuccess(response));
      window.open(
        process.env.REACT_APP_API_URL + "/files/" + response.data.file_path
      );
    } else {
      const response = yield call(
        orders.getGenerateRefundWithOrder,
        invoice_id,
        order
      );
      yield put(showRefundSuccess(response));
      window.open(
        process.env.REACT_APP_API_URL + "/files/" + response.data.file_path
      );
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          ordersApiError("Vous n'êtes pas autorisé prévisualiser cet avoir")
        );
      } else if (error.response.data !== undefined) {
        yield put(ordersApiError(error.response.data.message));
      }
    } else {
      yield put(ordersApiError(error.message));
    }
  }
}

export function* watchGetOrders() {
  yield takeEvery(GET_ORDERS, getOrders);
}

export function* watchGetOrder() {
  yield takeEvery(GET_ORDER, getOrder);
}

export function* watchCreateOrder() {
  yield takeEvery(CREATE_ORDER, createOrder);
}

export function* watchUpdateOrder() {
  yield takeEvery(UPDATE_ORDER, updateOrder);
}

export function* watchDeleteOrder() {
  yield takeEvery(DELETE_ORDER, deleteOrder);
}

export function* watchCreateInvoiceFromOrder() {
  yield takeEvery(CREATE_INVOICE_FROM_ORDER, createInvoiceFromOrder);
}

export function* watchCreateRefundFromOrder() {
  yield takeEvery(CREATE_REFUND_FROM_ORDER, createRefundFromOrder);
}

export function* watchGetGenerateInvoice() {
  yield takeEvery(GET_GENERATE_INVOICE, getGenerateInvoice);
}

export function* watchGetGenerateRefund() {
  yield takeEvery(GET_GENERATE_REFUND, getGenerateRefund);
}

export function* watchGetGenerateQuote() {
  yield takeEvery(GET_GENERATE_QUOTE, getGenerateQuote);
}

export function* watchShowInvoice() {
  yield takeEvery(SHOW_INVOICE, showInvoice);
}

export function* watchShowRefund() {
  yield takeEvery(SHOW_REFUND, showRefund);
}

function* ordersSaga() {
  yield all([
    fork(watchGetOrders),
    fork(watchGetOrder),
    fork(watchCreateOrder),
    fork(watchUpdateOrder),
    fork(watchDeleteOrder),
    fork(watchCreateInvoiceFromOrder),
    fork(watchCreateRefundFromOrder),
    fork(watchGetGenerateInvoice),
    fork(watchGetGenerateRefund),
    fork(watchGetGenerateQuote),
    fork(watchShowInvoice),
    fork(watchShowRefund),
  ]);
}

export default ordersSaga;
