import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardFooter } from "reactstrap";

// Redux
import { connect } from "react-redux";

// actions
import { deleteScale } from "../../store/actions";

const CardScale = (props) => (
  <React.Fragment>
    <Col xl="4" sm="6">
      <Card>
        <CardBody>
          <Row>
            <Col lg="12">
              <h5>{props.scale.label}</h5>
              <p className="mb-1">Échéance minimum: {props.scale.min_monthly_payment}</p>
              <p className="mb-1">Prêt minimum: {props.scale.min_amount_loan}</p>
              <p className="mb-1">Prêt maximum: {props.scale.max_amount_loan}</p>
              <p className="mb-1">Référence: {props.scale.ref}</p>
              <p className="mb-1">Date de début de l'échéancier: {(new Date(props.scale.starting_date)).toLocaleDateString()}</p>
              <p className="mb-1">Date de fin de l'échéancier: {(new Date(props.scale.ending_date)).toLocaleDateString()}</p>
              <p className="mb-1">Date de la première échéance: {props.scale.first_monthly_payment_delay}</p>
            </Col>
          </Row>
          {
            props.scale.documents.length > 0
              ? <Row className="mt-3">
                <Col lg="12">
                  <p className="mb-1">Listes des documents :</p>
                  <ul>
                    {props.scale.documents.map((document, index) => (
                      <li>
                        <a
                          href={`${process.env.REACT_APP_API_URL}/files/${document.url}`}
                          className="d-flex align-items-center mr-5 table-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {document.url}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
              : null
          }
        </CardBody>
        <CardFooter>
          <Row className="container-text-theme-color-2">
            <Col xs="12" className="d-flex justify-content-end">
              <Link
                to={`/financer/${props.financer.id}/scale/detail/${props.scale.id}`}
                className="d-flex align-items-center mr-5"
              >
                <i className="bx bx-show mr-1"></i> Voir
              </Link>
              <Link
                to={`/financer/${props.financer.id}/scale/edit/${props.scale.id}`}
                className="d-flex align-items-center mr-5"
              >
                <i className="bx bx-pencil mr-1"></i> Modifier
              </Link>
              <Link
                to="#"
                onClick={() => props.deleteScale(props.scale.id, props.history)}
                className="d-flex align-items-center mr-5"
              >
                <i className="bx bx-trash mr-1"></i> Supprimer
              </Link>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Col>
  </React.Fragment>
);

const mapStatetoProps = (state) => {
  const { scales, loading } = state.Scales;
  return { scales, loading };
};


export default withRouter(
  connect(mapStatetoProps, { deleteScale })(
    CardScale
  )
);
