import React from 'react';

/**
 * @description input component for forms 
 *
 * @param id
 * @param name form name of the component
 * @param label label rendered next to the input field
 * @param type type of the input ('text' by default)
 * @param placeholder placeholder of the input fiel (empty by default) 
 * @param value default value (null by default)
 * @param isDisabled false by default
 * @param {function} handleChange handler for the change of the value (empty by default) / call the function with the "event.target.value" arg
 * @param {function} handleClick handler for the onClick props (empty by default) / call the function with the "event" arg
 * @param {function} handleBlur
 * @param className
 * @param classNameInput
 * @param classNameLabel
 * @param error error message, null if not specified
 *
 */
const InputComponent = ({
    id,
    name,
    label,
    type,
    placeholder,
    value,
    isDisabled,
    handleChange,
    handleClick,
    handleBlur,
    className = "",
    classNameInput = "",
    classNameLabel = "",
    error = undefined,
    showError = false,
}) => {
    return (
        <div className={"mb-4 row form-group " + className}>
            <label htmlFor={id ? id : name} className={"col-form-label " + classNameLabel}>{label}</label>
            <div className={classNameInput}>
                <input
                    id={id ? id : name}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    value={value || ''}
                    disabled={isDisabled}
                    onChange={handleChange === undefined ? undefined : ((e) => handleChange(e.target))}
                    onClick={handleClick === undefined ? undefined : ((e) => handleClick(e))}
                    className={
                        "form-control " +
                        (isDisabled ? "disabled " : " ") +
                        (type === "checkbox" ? "form-checkbox form-check-input " : " ") +
                        (error !== undefined && showError ? "parsley-error" : "")
                    }
                    onBlur={handleBlur === undefined ? undefined : (e) => handleBlur(e.target)}
                />
                {error !== undefined && showError ? <span className="error">{error}</span> : ""}
            </div>

        </div>
    ); 
}

export default InputComponent;