import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Alert,
  Modal,
  NavItem,
  NavLink,
} from "reactstrap";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import CardFollowInfo from "./CardFollowInfo";

import GodChildren from "./Component/GodChildren";

// Helpers
import { getDateWithFormat } from "../../helpers/helper";

// actions
import {
  getCustomer,
  deleteCustomer,
  customerApiError,
} from "../../store/actions";

const CustomersDetail = (props) => {
  const [modal_standard, setmodal_standard] = useState(false);
  const [activeTab, setActiveTab] = useState("Identité");

  useEffect(() => {
    props.getCustomer.call(undefined, parseInt(window.location.pathname.split("/")[3], 10));
  }, [props.getCustomer]);

  const deleteThisCustomer = () => {
    props.deleteCustomer(
      parseInt(window.location.pathname.split("/")[3], 10),
      props.history
    );
  };

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="collaborators-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Prospects et clients"
            breadcrumbItem="Détails prospect ou client"
          />
          {props.error ? <Alert color="danger">{props.error}</Alert> : null}
          {!props.customer ? null : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="mb-5">
                      <Col className="d-flex align-items-start">
                        {props.customer.img === undefined ? (
                          <div className="avatar-xs d-inline-block">
                            <span className="avatar-title rounded-circle">
                              {props.customer.firstname !== null
                                ? props.customer.firstname.charAt(0)
                                : "A"}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <img
                              className="rounded-circle avatar-xs"
                              src={props.customer.img}
                              alt=""
                            />
                          </div>
                        )}
                        <div className="ml-3 d-inline-block">
                          <h5 className="font-size-14 mb-1">
                            <Link to="#" className="table-text-black">
                              {props.customer.civility +
                                " " +
                                props.customer.firstname +
                                " " +
                                props.customer.lastname}
                            </Link>
                          </h5>
                          <p className="mb-0">{`${props.customer.address}, ${props.customer.zip_code} ${props.customer.city}`}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-5">
                      <Col xs="12">
                        <ul className="nav nav-tabs nav-tabs-custom mb-3">
                          <NavItem>
                            <NavLink
                              className={
                                activeTab === "Identité" ? "active" : ""
                              }
                              onClick={() => {
                                setActiveTab("Identité");
                              }}
                            >
                              Identité
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                activeTab === "Logement" ? "active" : ""
                              }
                              onClick={() => {
                                setActiveTab("Logement");
                              }}
                            >
                              Logement
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                activeTab === "Situation" ? "active" : ""
                              }
                              onClick={() => {
                                setActiveTab("Situation");
                              }}
                            >
                              Situation
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                activeTab === "Rendez-vous" ? "active" : ""
                              }
                              onClick={() => {
                                setActiveTab("Rendez-vous");
                              }}
                            >
                              Rendez-vous
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                activeTab === "Parrainages" ? "active" : ""
                              }
                              onClick={() => {
                                setActiveTab("Parrainages");
                              }}
                            >
                              Parrainages
                            </NavLink>
                          </NavItem>
                        </ul>
                      </Col>
                      {activeTab === "Identité" ? (
                        <Col>
                          {/* <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Date de prise de RDV : </b>
                                {props.customer.first_contact_date &&
                                props.customer.first_contact_date !== ""
                                  ? getDateWithFormat(
                                      props.customer.first_contact_date
                                    )
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Origine : </b>
                                {props.customer.contact_origin &&
                                props.customer.contact_origin !== ""
                                  ? props.customer.contact_origin
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          {props.customer.user !== null ? (
                            <Row className="mb-3">
                              <Col lg="6">
                                <p>
                                  <b>Prospecteur : </b>
                                  {`${props.customer.user.firstname} ${props.customer.user.lastname}`}
                                </p>
                              </Col>
                            </Row>
                          ) : null} */}
                          <Row>
                            <Col>
                              <h5 className="mb-4">Informations générales</h5>
                            </Col>
                          </Row>
                          {props.customer.client_type === "Professionnel" ? (
                            <Row className="mb-3">
                              <Col lg="6">
                                <p>
                                  <b>Nom société : </b>
                                  {props.customer.company_name &&
                                  props.customer.company_name !== ""
                                    ? props.customer.company_name
                                    : "NR"}
                                </p>
                              </Col>
                              <Col lg="6">
                                <p>
                                  <b>Siret : </b>
                                  {props.customer.siret &&
                                  props.customer.siret !== ""
                                    ? props.customer.siret
                                    : "NR"}
                                </p>
                              </Col>
                            </Row>
                          ) : null}
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Prospect / client : </b>
                                {props.customer.is_prospect
                                  ? "Prospect"
                                  : "Client"}
                                {" ("}
                                {props.customer.client_type}
                                {")"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Email : </b>
                                {props.customer.email &&
                                props.customer.email !== ""
                                  ? props.customer.email
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Adresse principale : </b>
                                {`${props.customer.address}, ${props.customer.zip_code} ${props.customer.city}`}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Adresse du chantier : </b>
                                {props.customer.address_construction_site &&
                                props.customer.address_construction_site !== ""
                                  ? `${props.customer.address_construction_site}, ${props.customer.zip_code_construction_site} ${props.customer.city_construction_site}`
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Téléphone : </b>
                                {props.customer.mobile_phone &&
                                props.customer.mobile_phone !== ""
                                  ? props.customer.mobile_phone
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Téléphone 2 : </b>
                                {props.customer.mobile_phone2 &&
                                props.customer.mobile_phone2 !== ""
                                  ? props.customer.mobile_phone2
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Téléphone fixe : </b>
                                {props.customer.fix_phone &&
                                props.customer.fix_phone !== ""
                                  ? props.customer.fix_phone
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Email secondaire: </b>
                                {props.customer.email2 &&
                                props.customer.email2 !== ""
                                  ? props.customer.email2
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                      {activeTab === "Logement" ? (
                        <Col>
                          <Row>
                            <Col>
                              <h5 className="mb-4">Détails du logement</h5>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Situation : </b>
                                {props.customer.is_owner == null ? 'Non renseigné'
                                  : props.customer.is_owner
                                    ? "Propriétaire"
                                    : "Locataire"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Résidence principale : </b>
                                {props.customer.main_resideence &&
                                props.customer.main_resideence !== null
                                  ? "Oui"
                                  : "Non"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Propriétaire depuis : </b>
                                {props.customer.owner_at &&
                                props.customer.owner_at !== ""
                                  ? new Date(
                                      props.customer.owner_at
                                    ).getFullYear()
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Ancienneté du logement : </b>
                                {props.customer.housing_seniority &&
                                props.customer.housing_seniority !== ""
                                  ? props.customer.housing_seniority
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Surface habitation (en m2) : </b>
                                {props.customer.surface_habitation &&
                                props.customer.surface_habitation !== ""
                                  ? props.customer.surface_habitation
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Nombre d'étages : </b>
                                {props.customer.floors_number &&
                                props.customer.floors_number !== ""
                                  ? props.customer.floors_number
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Type de combles : </b>
                                {props.customer.attic_type &&
                                props.customer.attic_type !== ""
                                  ? props.customer.attic_type
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Isolation des combles : </b>
                                {props.customer.is_attic_isulation &&
                                props.customer.is_attic_isulation !== null
                                  ? "Oui"
                                  : "Non"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Année de l'isolation des combles : </b>
                                {props.customer.attic_isulated_at &&
                                props.customer.attic_isulated_at !== ""
                                  ? new Date(
                                      props.customer.attic_isulated_at
                                    ).getFullYear()
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Type d'isolation : </b>
                                {props.customer.attic_isulation_type &&
                                props.customer.attic_isulation_type !== null
                                  ? props.customer.attic_isulation_type
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Type de production ECS : </b>
                                {props.customer.ecs_production_type &&
                                props.customer.ecs_production_type !== ""
                                  ? props.customer.ecs_production_type
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Coût annuel [ECS] : </b>
                                {props.customer
                                  .ecs_production_type_annual_cost &&
                                props.customer
                                  .ecs_production_type_annual_cost !== ""
                                  ? props.customer
                                      .ecs_production_type_annual_cost
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Type de production chauffage : </b>
                                {props.customer.heading_type &&
                                props.customer.heading_type !== ""
                                  ? props.customer.heading_type
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Coût annuel [chauffage] : </b>
                                {props.customer.heading_type_annual_cost &&
                                props.customer.heading_type_annual_cost !== ""
                                  ? props.customer.heading_type_annual_cost
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Type de fenêtre : </b>
                                {props.customer.window_type &&
                                props.customer.window_type !== ""
                                  ? props.customer.window_type
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Nombre de fenêtres : </b>
                                {props.customer.windows_number &&
                                props.customer.windows_number !== ""
                                  ? props.customer.windows_number
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Commentaire : </b>
                                {props.customer.appointment_explication &&
                                props.customer.appointment_explication !== ""
                                  ? props.customer.appointment_explication
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h5 className="mb-4">
                                Définition du projet potentiel
                              </h5>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>
                                  Le prospect a-t-il déjà profité du CITE
                                  (subvention) ?{" "}
                                </b>
                                {props.customer.already_benefit_grant
                                  ? "Oui"
                                  : "Non"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>
                                  Le prospect a-t-il déjà profité d'une étude
                                  énergétique ?{" "}
                                </b>
                                {props.customer.already_benefit_energy_study
                                  ? "Oui"
                                  : "Non"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Type de travaux : </b>
                                {props.customer.building_work_type &&
                                props.customer.building_work_type !== ""
                                  ? props.customer.building_work_type
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                      {activeTab === "Situation" ? (
                        <Col>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Profession : </b>
                                {props.customer.function1 &&
                                props.customer.function1 !== ""
                                  ? props.customer.function1
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Âge [client] : </b>
                                {props.customer.age && props.customer.age !== ""
                                  ? `${props.customer.age} ans`
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Profession conjoint : </b>
                                {props.customer.function2 &&
                                props.customer.function2 !== ""
                                  ? props.customer.function2
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Âge [conjoint] : </b>
                                {props.customer.spouse_age &&
                                props.customer.spouse_age !== ""
                                  ? `${props.customer.spouse_age} ans`
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Nombre d'enfants : </b>
                                {props.customer.children_number &&
                                props.customer.children_number !== ""
                                  ? props.customer.children_number
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Revenu fiscal : </b>
                                {props.customer.fiscal_income &&
                                props.customer.fiscal_income !== ""
                                  ? `${props.customer.fiscal_income} €`
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                      {activeTab === "Rendez-vous" ? (
                        <Col>
                          <Row>
                            <Col>
                              <h5 className="mb-4">
                                Positionnement du rendez-vous
                              </h5>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Date du RDV : </b>
                                {props.customer.first_contact_date &&
                                props.customer.first_contact_date !== ""
                                  ? getDateWithFormat(
                                      props.customer.first_contact_date
                                    )
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Source RDV : </b>
                                {props.customer.contact_origin &&
                                props.customer.contact_origin !== ""
                                  ? props.customer.contact_origin
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>RDV prit par : </b>
                                {props.customer.user &&
                                props.customer.user !== ""
                                  ? `${props.customer.user.firstname} ${props.customer.user.lastname}`
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          {/* <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Rendez-vous pris avec : </b>
                                {props.customer.appointment_take_with &&
                                props.customer.appointment_take_with !== ""
                                  ? props.customer.appointment_take_with
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row> */}
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Les décideurs étaient-ils présents ? </b>
                                {props.customer.decision_makers_present
                                  ? "Oui"
                                  : "Non"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>La durée d'1H30, OK ? </b>
                                {props.customer.ok_one_hours_thirty
                                  ? "Oui"
                                  : "Non"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Plan d'accès ou commentaires divers : </b>
                                {props.customer.appointment_method &&
                                props.customer.appointment_method !== ""
                                  ? props.customer.appointment_method
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h5 className="mb-4">Feeling</h5>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Profil du prospect : </b>
                                {props.customer.follow_up_client_profil &&
                                props.customer.follow_up_client_profil !== ""
                                  ? props.customer.follow_up_client_profil
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p>
                                <b>Date de rappel : </b>
                                {props.customer.follow_up_call_date &&
                                props.customer.follow_up_call_date !== ""
                                  ? getDateWithFormat(
                                      props.customer.follow_up_call_date
                                    )
                                  : "Non renseigné"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <p>
                                <b>Motif de refus : </b>
                                {props.customer.follow_up_refusal_reason &&
                                props.customer.follow_up_refusal_reason !== ""
                                  ? props.customer.follow_up_refusal_reason
                                  : "Non renseigné"}
                              </p>
                            </Col>
                            <Col lg="6">
                              <p className="ratings">
                                <b>Qualité du rendez-vous : </b>
                                <span>
                                  <i
                                    className={`${
                                      props.customer.follow_up_number_star < 1
                                        ? "empty "
                                        : ""
                                    }fas fa-star ml-3`}
                                  ></i>
                                </span>
                                <span>
                                  <i
                                    className={`${
                                      props.customer.follow_up_number_star < 2
                                        ? "empty "
                                        : ""
                                    }fas fa-star ml-3`}
                                  ></i>
                                </span>
                                <span>
                                  <i
                                    className={`${
                                      props.customer.follow_up_number_star < 3
                                        ? "empty "
                                        : ""
                                    }fas fa-star ml-3`}
                                  ></i>
                                </span>
                                <span>
                                  <i
                                    className={`${
                                      props.customer.follow_up_number_star < 4
                                        ? "empty "
                                        : ""
                                    }fas fa-star ml-3`}
                                  ></i>
                                </span>
                                <span>
                                  <i
                                    className={`${
                                      props.customer.follow_up_number_star < 5
                                        ? "empty "
                                        : ""
                                    }fas fa-star ml-3`}
                                  ></i>
                                </span>
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                      {activeTab === "Parrainages" ? (
                        <Col>
                          <Row>
                            <Col>
                              <h5 className="mb-4">Liste des filleuls</h5>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg="6">
                              <GodChildren
                                customers={props.customer.god_children}
                              />
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row className="container-text-theme-color-2">
                      <Col xs="12" className="d-flex justify-content-end">
                        <Link
                          to={`/customer/edit/${parseInt(
                            window.location.pathname.split("/")[3],
                            10
                          )}`}
                          className="d-flex align-items-center mr-5"
                        >
                          <i className="bx bx-pencil mr-1"></i> Modifier
                        </Link>
                        <Link
                          to="#"
                          onClick={() => {
                            tog_standard();
                          }}
                          data-toggle="modal"
                          className="d-flex align-items-center mr-5"
                        >
                          <i className="bx bx-trash mr-1"></i> Supprimer
                        </Link>
                      </Col>
                    </Row>
                  </CardFooter>
                  <Modal
                    isOpen={modal_standard}
                    toggle={() => {
                      tog_standard();
                    }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myModalLabel">
                        Prospect et client - Suppression
                      </h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <h5>Attention</h5>
                      <p>
                        Voulez vous supprimer le prospect/client definitivement
                        ?
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => {
                          tog_standard();
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                      >
                        Annuler
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          deleteThisCustomer();
                        }}
                        className="btn btn-green waves-effect waves-light"
                      >
                        Supprimer
                      </button>
                    </div>
                  </Modal>
                </Card>
                <Row>
                  <CardFollowInfo customer={props.customer} />
                </Row>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { customer, error } = state.Customers;
  return { customer, error };
};

export default withRouter(
  connect(mapStatetoProps, { getCustomer, deleteCustomer, customerApiError })(
    CustomersDetail
  )
);
