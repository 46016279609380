import * as Yup from "yup";

export const createValidationSchema = Yup.object().shape({
  invoice_date: Yup.date().required("Veuillez compléter ce champ"),

  percent: Yup.string().required("Veuillez compléter ce champ"),

  price: Yup.string().required("Veuillez compléter ce champ"),

  payment_mode: Yup.string().required("Veuillez compléter ce champ"),
});
