import * as Yup from "yup";
import { PHONE_REGEX } from "../../../helpers/validator";

export const createCustomerValidationSchema = Yup.object().shape({
  civility: Yup.string()
    .ensure()
    .when("client_type", {
      is: "Particulier",
      then: Yup.string().required("Veuillez compléter ce champ"),
    }),

  firstname: Yup.string()
    .ensure()
    .when("client_type", {
      is: "Particulier",
      then: Yup.string().required("Veuillez compléter ce champ"),
    }),

  lastname: Yup.string()
    .ensure()
    .when("client_type", {
      is: "Particulier",
      then: Yup.string().required("Veuillez compléter ce champ"),
    }),

  email: Yup.string().notRequired(),
  email2: Yup.string().notRequired(),

  company_name: Yup.string()
    .ensure()
    .when("client_type", {
      is: "Professionnel",
      then: Yup.string().required("Veuillez compléter ce champ"),
    }),

  address: Yup.string().required("Veuillez compléter ce champ"),

  additional_address: Yup.string().notRequired(),

  zip_code: Yup.string()
    .required("Veuillez compléter ce champ"),

  city: Yup.string().required("Veuillez compléter ce champ"),

  mobile_phone: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .trim()
    .matches(PHONE_REGEX, "Veuillez saisir un numéro de téléphone valide")
    .notRequired(),

  mobile_phone2: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .trim()
    .matches(PHONE_REGEX, "Veuillez saisir un numéro de téléphone valide")
    .notRequired(),

  fix_phone: Yup.string()
    .test("format", "Format invalide", (val) => {
      return val === undefined || val === "" || !isNaN(val);
    })
    .trim()
    .matches(PHONE_REGEX, "Veuillez saisir un numéro de fixe valide")
    .notRequired(),

  address_construction_site: Yup.string().notRequired(),

  additional_address_construction_site: Yup.string().notRequired(),

  city_construction_site: Yup.string().notRequired(),

  zip_code_construction_site: Yup.string()
    .notRequired(),
});
