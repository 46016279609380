import React, { useState, useEffect, useCallback } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Alert,
  Modal,
  NavItem,
  NavLink,
  Collapse,
  ModalHeader,
  Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
// Bootstrap Table
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import loader
import GlobalLoader from "../../components/Common/GlobalLoader";

import UploadImage from "../../components/Common/UploadImage";

// actions
import { getCustomers, importCsv, customerApiError } from "../../store/actions";

// Image fond
import fond_1 from "../../assets/images/collaborators/fleur.png";

// Helpers
import {getBase64} from "../../helpers/helper";

import { setQuery, parseQuery } from "../../helpers/helper";
import { onTableChange } from "../../helpers/table_helper";
import CustomerFilter from "./Filters/CustomerFilter";
import { parseCustomerName } from "../../helpers/customer_helper";

export const CLIENT_FILTER = "Clients";
export const PROSPECT_FILTER = "Prospects";
export const ALL_FILTER = "Tous";
export const PARRAINAGE_FILTER = "Parrainages";
export const GODFATHER_TYPE = "godfathers";

const CustomersList = (props) => {
  const [filtersCollapseIsOpen, setFiltersCollapseIsOpen] = useState(false);
  const [flash_message, setFlash_message] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);

  // Filters
  const query = parseQuery();

  const [filters, setFilters] = useState({
    sort_by: query.sort_by || '',
    sort_dir: query.sort_dir || '',
    page: query.page || 1,
    page_size: query.page_size || 25,
    user_id: query.user_id || '',
    tab: query.tab || CLIENT_FILTER,
    zip_code: query.zip_code || '',
    search: query.search || '',
    type: query.type || '',
    is_waiting_paid: query.is_waiting_paid || null,
    without_mail: query.without_mail || '',
    without_phone: query.without_phone || '',
  });

  const applyFilter = useCallback(() => {
    let query = {
      page: filters.page,
      page_size: filters.page_size,
      sort: `${filters.sort_by} ${filters.sort_dir}`.trim(), // trim => remove space when filters is empty
      commercial: filters.user_id,
      zip_code: filters.zip_code,
      query: filters.search,
      type: filters.type,
      is_paid: filters.is_waiting_paid === null || filters.is_waiting_paid === "" ? null : !filters.is_waiting_paid,
      with_out_mail: filters.without_mail,
      with_out_phone: filters.without_phone,
    };

    if (filters.tab !== ALL_FILTER && filters.tab !== PARRAINAGE_FILTER) query.is_prospect = PROSPECT_FILTER === filters.tab;

    props.getCustomers.call(undefined, query); // Update interface with new filter
  }, [filters, props.getCustomers]);

  useEffect(() => {
    setQuery(filters, props.history); // Update query in path

    applyFilter();
  }, [filters, applyFilter, props.history]);

  const [documentList, setDocumentsList] = useState([]);

  function resetFilters() {
    setFilters({
      ...filters,
      sort_by: '',
      sort_dir: '',
      page: 1,
      page_size: 25,
      user_id: '',
      zip_code: '',
      search: '',
      is_waiting_paid: null,
      without_mail: '',
      without_phone: '',
    });
  }

  function removeFlashMessage() {
    window.history.pushState({}, document.title, "/customers");
    setFlash_message(!flash_message);
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const handleChangeDocuments = async (files) => {
    let documents = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let content = await getBase64(file);
      documents.push({
        url_path: content,
        name: file.name,
      });
    }
    setDocumentsList(documents);
  };

  const handleSubmitDocuments = () => {
    documentList.forEach((doc) => {
      props.importCsv(doc);
    });
  };

  const columns = [
    {
      dataField: "lastname",
      text: "Nom Prénom",
      classes: "w-25",
      formatter: (cell, row) => (
        <>
          <h5 className="font-size-14 mb-1">
              {parseCustomerName(row)}
          </h5>
          <p className="mb-0">{`${row.address}, ${row.zip_code} ${row.city}`}</p>
        </>
      ),
      sort: true,
    },
    {
      dataField: "mobile_phone",
      text: "Téléphone",
      formatter: (cell, row) => (
        <div>
          {row.mobile_phone ||
            row.mobile_phone2 ||
            row.fix_phone ||
            "Pas de téléphone"}
        </div>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell, row) => <div>{row.email || "Pas d'email"}</div>,
    },
    {
      dataField: "is_prospect",
      text: "Prospect/client",
      formatter: (cell, row) => (row.is_prospect ? "Prospect" : "Client"),
    },
    {
      dataField: "client_type",
      text: "Type",
    },
    {
      dataField: "user",
      text: "Commercial",
      formatter: (cell, row) =>
        row.user ? `${row.user.firstname} ${row.user.lastname}` : "ADV",
    },
    {
      dataField: "actions",
      text: "",
      formatter: (cell, row) =>
        <UncontrolledDropdown>
          <DropdownToggle className={"bg-transparent"}>
            <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
          </DropdownToggle>
          <DropdownMenu right className="bordered important-default-colors">
              <>
                <DropdownItem onClick={() => { props.history.push(`/requests/customers/${row.id}/show?tab=0`) }}>Voir</DropdownItem>
                <DropdownItem onClick={() => { window.open(`/requests/customers/${row.id}/show?tab=0`, '_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
              </> 
          </DropdownMenu>
        </UncontrolledDropdown>,
    }
  ];

  const ParrainageColumns = [
    {
      dataField: "lastname",
      text: "Nom Prénom",
      classes: "w-25",
      formatter: (cell, row) => (
        <React.Fragment>
          <h5 className="font-size-14 mb-1">
            <Link
              to={`/customer/detail/${row.id}`}
              className="table-text-black"
            >
              {row.client_type === "Professionnel"
                ? row.company_name
                : row.civility + " " + row.lastname + " " + row.firstname}
            </Link>
          </h5>
          <p className="mb-0">{`${row.mobile_phone ||
            row.mobile_phone2 ||
            row.fix_phone ||
            "Pas de téléphone"}`}</p>
          <p className="mb-0">{`${row.email}`}</p>
        </React.Fragment>
      ),
      sort: true,
    },
    {
      dataField: "god_chidren_count",
      text: "Nb parrainages",
      formatter: (cell, row) => (
        <div>
          {row.god_children.length}
        </div>
      ),
    },
    {
      dataField: "god_children",
      text: "Montant",
      formatter: (cell, row) => (
        <div>
          {cell
              .reduce(
                (a, b) =>{

                  return (a) + (b.amount || 0)
                },
                0
              )
            .toFixed(2) + "€"}
        </div>
      ),
    },
    {
      dataField: "user",
      text: "Commercial",
      formatter: (cell, row) =>
        row.user ? `${row.user.firstname} ${row.user.lastname}` : "ADV",
    },
    {
      dataField: 'godfather_comment',
      text: 'Commentaire',
      formatter: (cell, row) => (
        <div className="mr-0" style={{width: "max-content"}}>
          <ul>
            {row.god_children.map((customer, i) =>
              <li key={"god_child_comment_"+i}>
                {customer.godfather_comment}
              </li>
            )}
          </ul>
        </div>
      )
    },
    {
      dataField: "test",
      text: "Filleuls",
      formatter: (cell, row) => (
        <div className="mr-0" style={{width: "max-content"}}>
          <ul>
            {row.god_children
              .map((client, i) =>
              <li key={"god_child_client_"+i}>
                  {parseCustomerName(client)}
                  {(client.amount || 0).toFixed(2) + "€, "}
                  {client.is_paid ? "payé" : "pas de paiement"}
                </li>)}
          </ul>
        </div>
      ),
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="collaborators-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Prospects et clients" breadcrumbItem="Clients" />
          <Row>
            <Col lg="12">
              <Alert
                color="success"
                isOpen={query.success_add === "1"}
                toggle={() => removeFlashMessage()}
              >
                Le prospect/client à bien été créé.
              </Alert>
              <Alert
                color="success"
                isOpen={query.success_update === "1"}
                toggle={() => removeFlashMessage()}
              >
                Le prospect/client à bien été mise à jour.
              </Alert>
              <Alert
                color="success"
                isOpen={query.success_delete === "1"}
                toggle={() => removeFlashMessage()}
              >
                Le prospect/client à bien été suprimé.
              </Alert>
            </Col>
          </Row>
          {props.loading ? (
            <GlobalLoader />
          ) : !props.pagination || !props.customers ? (
            props.error ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">
                      <Row className="pr-2 pl-2 d-flex justify-content-between">
                        <Col>Tous les prospects / clients</Col>
                        <Col className="d-flex justify-content-end">
                          {filters.tab === PARRAINAGE_FILTER ? '' :
                            <Link
                              to="#"
                              className="d-flex align-items-center text-theme-color-2"
                              onClick={() => tog_standard()}
                              data-toogle="modal"
                              data-target="#selectClientType"
                            >
                              <i className="bx bx-plus-circle mr-1>"/> Ajouter
                            </Link>}
                          <Modal
                            isOpen={modal_standard}
                            toggle={() => tog_standard()}
                          >
                            <ModalHeader className="d-block">
                              <div className="d-flex justify-between">
                                Type de client
                                <button
                                  type="button"
                                  onClick={() => {
                                    setmodal_standard(false);
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                            </ModalHeader>
                            <div className="modal-body">
                              <p>Quel type de client voulez vous ajouter ?</p>
                            </div>
                            <div className="modal-footer">
                              <Link
                                to="/customer/add?client_type=Particulier"
                                className="btn btn-secondary"
                              >
                                Particulier
                              </Link>
                              <Link
                                to="/customer/add?client_type=Professionnel"
                                className="btn btn-secondary"
                              >
                                Professionnel
                              </Link>
                            </div>
                            <div className="modal-header">
                              <h5 className="modal-title mt-0">
                                Importer un csv
                              </h5>
                            </div>
                            <div className="modal-body">
                              <UploadImage
                                files={
                                  documentList.length > 0 ? documentList : []
                                }
                                title="Documents"
                                handleChangeImages={handleChangeDocuments}
                                max_file={1}
                                field="import_csv"
                                isInForm={true}
                              />
                            </div>
                            <div className="modal-footer">
                              <Button
                                className="btn-green"
                                onClick={handleSubmitDocuments}
                              >
                                Importer
                              </Button>
                            </div>
                          </Modal>
                        </Col>
                      </Row>
                    </CardTitle>
                    <Row>
                      <Col xs="12">
                        <Row>
                          <Col xs="12" className="d-flex justify-content-start">
                            <span
                              className="d-flex text-link mb-3"
                              onClick={() => {
                                setFiltersCollapseIsOpen(!filtersCollapseIsOpen);
                              }} 
                            >
                              <i className="fas fa-filter mr-1"></i>Filtrer
                            </span>
                            <span
                              className="d-flex text-link text-color-unset mb-3"
                              onClick={resetFilters} 
                            >
                              <i className="fas fa-times mr-1"></i> Vider les
                              filtres
                            </span>
                          </Col>
                          <Col xs="12">
                            <Collapse isOpen={filtersCollapseIsOpen}>
                              <CustomerFilter
                                filters={filters}
                                setFilters={setFilters}
                                filterMode={filters.tab}
                              />
                            </Collapse>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                      <Col lg="4" sm="4">
                        <input
                          type="text"
                          id="customer_query"
                          className="form-control"
                          placeholder="Rechercher un client/prospect"
                          defaultValue={filters.search}
                        ></input>
                      </Col>
                      <Col lg="3" sm="3">
                        <Button
                          onClick={() => {
                            setFilters({
                              ...filters,
                              search: document.getElementById('customer_query').value,
                            });
                          }}
                          color="none"
                          className="btn-green"
                        >
                          Rechercher
                        </Button>
                      </Col>
                    </Row>
                    <ul className="nav nav-tabs nav-tabs-custom mb-3">
                      <NavItem>
                        <NavLink
                          className={filters.tab === ALL_FILTER ? "active" : ""}
                          onClick={() => {
                            setFilters({
                              ...filters,
                              type:'',
                              tab: ALL_FILTER,
                              sort_by: '',
                              sort_dir: '',
                              page: 1,
                              is_waiting_paid: '',
                            });
                          }}
                        >
                          Tous
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={filters.tab === PROSPECT_FILTER ? "active" : ""}
                          onClick={() => {
                            setFilters({
                              ...filters,
                              type:'',
                              tab: PROSPECT_FILTER,
                              sort_by: '',
                              sort_dir: '',
                              page: 1,
                              is_waiting_paid: '',
                            });
                          }}
                        >
                          Prospects
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={filters.tab === CLIENT_FILTER ? "active" : ""}
                          onClick={() => {
                            setFilters({
                              ...filters,
                              type:'',
                              tab: CLIENT_FILTER,
                              sort_by: '',
                              sort_dir: '',
                              page: 1,
                              is_waiting_paid: '',
                            });
                          }}
                        >
                          Clients
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                          className={filters.tab === PARRAINAGE_FILTER ? "active" : ""}
                          onClick={() => {
                            setFilters({
                              ...filters,
                              type: GODFATHER_TYPE,
                              tab: PARRAINAGE_FILTER,
                              sort_by: '',
                              sort_dir: '',
                              page: 1,
                              zip_code: '',
                              without_mail: '',
                              without_phone: '',
                            });
                          }}
                        >
                          Parrainages
                        </NavLink>
                      </NavItem>
                    </ul>
                    <BootstrapTable
                      keyField="id"
                      data={props.customers}
                      columns={filters.tab === PARRAINAGE_FILTER ? ParrainageColumns : columns}
                      defaultSortDirection="asc"
                      classes="table-centered"
                      sort={{ dataField: filters.sort_by, order: (filters.sort_dir || "asc") }}
                      remote={{ pagination: true, sort: true }}
                      onTableChange={onTableChange.bind({setFilters, filters})}
                      pagination={paginationFactory({
                        page: props.pagination.current_page,
                        totalSize: props.pagination.total_count,
                        sizePerPage: props.pagination.current_page_size,
                        sizePerPageList: [
                          { text: "Afficher 10 éléments", value: 10 },
                          { text: "Afficher 25 éléments", value: 25 },
                          { text: "Afficher 50 éléments", value: 50 },
                          { text: "Afficher 75 éléments", value: 75 },
                          { text: "Afficher 100 éléments", value: 100 },
                        ],
                      })}
                    />
                  </CardBody>
                </Card>
                <div className="collaborators-footer-img">
                  <div className="collaborators-footer-img-main-container">
                    <div className="collaborators-footer-img-container">
                      <img src={fond_1} alt="Fond numéro 1" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { customers, pagination, loading, error } = state.Customers;
  return { customers, pagination, loading, error };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCustomers,
    importCsv,
    customerApiError,
  })(CustomersList)
);
