import {Col, Label, Row} from "reactstrap";
import { FormikAutocompleteUnselectable } from "../../../components/Common/AsyncAutoComplete2";
import {promiseFormulasTypesOptions} from "../../../helpers/autocomplete_promise_options";

const FormulasFilter = () => {


  return (
    <Row>
      <Col md="3">
        <Row>
          <Col xs="12" className="mt-3">
            <Label for="type">Type de formule</Label>
          </Col>
          <Col xs="12">
            <FormikAutocompleteUnselectable
              className="w-100"
              name="formulas_type"
              id="formulas_type"
              isSearchable={false}
              noOptionsMessage={() => "Aucun type de formules disponible"}
              placeholder="Sélectionner"
              loadOptions={promiseFormulasTypesOptions}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FormulasFilter;