import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Media } from "reactstrap";

// Redux
import { connect } from "react-redux";

// actions
import { updateProject } from "../../../store/actions";
import Project, { TypeOfFinancementEnum } from "../../../models/project";
import FinancementForm, { FinancementFormValues } from "./Form/FinancementForm";

interface FinancementCardProps {
  project: Project,
  updateProject: any,
  setFlash_message: any
}

const FinancementCard = (props: FinancementCardProps) => {
  /**
   * Met à jour le projet avec les nouvelles données
   */
  const updateChanges = (formValues: FinancementFormValues) => {
    let values: Project = {client:{}};

    values.type_of_financement = formValues.typeOfFinancement;

    values.comptant_paiement_type = formValues.comptantPaiementType;

    values.financer_id = formValues.financer;

    values.scale_id = formValues.scale;

    values.scale_item_id = formValues.scaleItem;

    props.updateProject(props.project.id, values);
    props.setFlash_message(true);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-3 d-flex justify-content-between">
          <div>Financement</div>
        </CardTitle>
        <div>
          <FinancementForm
            project={props.project}
            handleSubmit={updateChanges}
          />
          
          {props.project.financer &&
          props.project.type_of_financement === TypeOfFinancementEnum.FINANCEMENT ? (
            <div className="financement-container">
              <div className="financement-row">
                <Media className="mt-3">
                  <div className="avatar-xs mr-2">
                    <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                      <i className="bx bxs-dollar-circle"></i>
                    </span>
                  </div>
                  <Media body>
                    <h5 className="font-size-13 mb-1">
                      {props.project.financer.name}
                    </h5>
                    <p className="text-muted mb-1">
                      {props.project.financer.description}
                    </p>
                  </Media>
                </Media>
              </div>
              <div className="financement-row">
                <div className="financement-item">
                  <p>Crédit : </p>
                  <span>{`${
                    props.project.scale ? props.project.scale.label : "-"
                  }, ${props.project.scale ? props.project.scale.ref : "-"}, ${
                    props.project.scale
                      ? props.project.scale.first_monthly_payment_delay
                      : "-"
                  }`}</span>
                </div>
              </div>
              <div className="financement-row">
                <div className="financement-item">
                  <p>Modalité : </p>
                  <span>{`${
                    props.project.scale_item
                      ? props.project.scale_item.total_loan_amount?.toFixed(2)
                      : "-"
                  } € sur ${
                    props.project.scale_item
                      ? props.project.scale_item.total_duration
                      : "-"
                  } mois`}</span>
                </div>
              </div>
              <div className="text-center mt-2">
                <Link
                  to={`/financer/detail/${props.project.financer.id}`}
                  className="btn btn-green btn-sm"
                >
                  Voir plus
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      </CardBody>
    </Card>
  );
};

const mapStatetoProps = (state:any) => {
  const { project } = state.Projects;

  return { project } as { project: Project };
};

export default withRouter(
  connect(mapStatetoProps, { updateProject, setFlash_message: undefined })(FinancementCard)
);
