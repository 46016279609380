import { Link, withRouter } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";

// Redux
import { connect } from "react-redux";

// Bootstrap table
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// Helpers
import { parseCustomerName } from "../../../helpers/customer_helper";
import { getDateWithShortFormat } from "../../../helpers/helper";

const DepositTable = (props) => {
  const columns = [
    {
      dataField: "order_client",
      text: "Client",
      formatter: (cell, row) => {
        return row.client ? (
          <Link to={`/customer/detail/${row.client?.id}`} className="d-flex justify-content-start mb-0">
            <div className="mr-3">
              <i className="bx bx-user mr-1"></i>
              <span className="font-size-12">
                {parseCustomerName(row.client)}
              </span>
            </div>
            <div className="mr-3" id={`map-${row.id}`}>
              <i className="bx bx-map mr-1"></i>
              <UncontrolledTooltip placement="top" target={`map-${row.id}`}>
                {row.client.city} ({row.client.zip_code.charAt(0)}
                {row.client.zip_code.charAt(1)})
              </UncontrolledTooltip>
            </div>
          </Link>
        ) : null;
      },
    },
    {
      dataField: "sell_date",
      text: "Date vente",
      formatter: (cell, row) =>
        row.sell_date ? getDateWithShortFormat(row.sell_date) : "NR",
      sort: true,
    },
    {
      dataField: "order_deposits_price",
      text: "Montant acompte",
      formatter: (cell, row) =>
        row.last_deposit?.price
          ? `${parseFloat(
            row.last_deposit.price
          ).toFixed(2)} €`
          : "NR",
    },
    {
      dataField: "order_deposits_payment_mode",
      text: "Modalité",
      formatter: (cell, row) =>
        row.last_deposit?.payment_mode
          ? row.last_deposit.payment_mode
          : "NR",
    },
    {
      dataField: "order_deposits_custom_deposit_date",
      text: "Date demande acompte",
      formatter: (cell, row) =>
        row.last_deposit?.custom_deposit_date
          ? getDateWithShortFormat(
            row.last_deposit.custom_deposit_date
          )
          : "NR",
    },
    {
      dataField: "order_deposits_relance_date",
      text: "Date relance",
      formatter: (cell, row) =>
        row.last_deposit?.relance_date
          ? getDateWithShortFormat(
            row.last_deposit.relance_date
          )
          : "NR",
    },
    {
      dataField: "order_deposits_receipt_date",
      text: "date reception cheque/virement",
      formatter: (cell, row) =>
        row.last_deposit?.receipt_date
          ? getDateWithShortFormat(
            row.last_deposit.receipt_date
          )
          : "NR",
    },
    {
      dataField: "order_deposits_receipt_amount",
      text: "Montant acompte reçu",
      formatter: (cell, row) =>
        row.last_deposit?.receipt_amount
          ? `${parseFloat(
            row.last_deposit.receipt_amount
          ).toFixed(2)} €`
          : "NR",
    },
    {
      dataField: "order_deposits_observation",
      text: "Observation",
      formatter: (cell, row) => (
        <span className="white-space-pre-line">
          {row.last_deposit?.observation
            ? row.last_deposit.observation.replace("\r\n", "\\r\\n")
            : "NR"}
        </span>
      ),
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <UncontrolledDropdown>
          <DropdownToggle tag={"a"} className={"bg-transparent px-3 py-2 d-inline-block"}>
            <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
          </DropdownToggle>
          <DropdownMenu right className="bordered important-default-colors">
            <DropdownItem onClick={() => { props.history.push(`/projects-overview/${row.id}?tab=financier`) }}>Voir</DropdownItem>
            <DropdownItem onClick={() => { window.open(`/projects-overview/${row.id}?tab=financier`, '_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
      classes: "text-right"
    },
  ];

  return (
    <div className="deposit-table-container">
      <BootstrapTable
        keyField="id"
        data={props.projects}
        columns={columns}
        classes="table-centered"
        defaultSortDirection="desc"
        sort={{ dataField: props.sort.dataField || 'id', order: props.sort.order }}
        remote={{ pagination: true, sort: true }}
        onTableChange={props.onTableChange}
        pagination={paginationFactory({
          page: props.pagination.current_page,
          totalSize: props.pagination.total_count,
          sizePerPage: props.pagination.current_page_size,
          sizePerPageList: [
            { text: "Afficher 10 éléments", value: 10 },
            { text: "Afficher 25 éléments", value: 25 },
            { text: "Afficher 50 éléments", value: 50 },
            { text: "Afficher 75 éléments", value: 75 },
            { text: "Afficher 100 éléments", value: 100 },
          ],
        })}
      />
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { projects, pagination } = state.Projects;

  return { projects, pagination };
};

export default withRouter(connect(mapStatetoProps, {})(DepositTable));
