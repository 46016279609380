import React, {useEffect, useState} from 'react';

import StatsRepartitionCard, {
  CATEGORY_REPARTION_CARD,
  FAMILY_REPARTION_CARD,
  PRODUCT_REPARTION_CARD
} from "./StatsRepartitionCard";
import {statistics} from "../../api";
import {computeInitSelected, getEnabledCategoryIds, getEnabledSubCategoryIds} from "../../helpers/dashboard_helpers";

const DashboardRepartitionComponent = ({
  family_statistics,
  category_statistics,
  product_statistics,
  loading_repartition,
  year
                                       }) => {

  const [categoryStatistics, setCategoryStatistics] = useState(category_statistics);
  const [productStatistics, setProductStatistics] = useState(product_statistics);

  useEffect(() => {
    setCategoryStatistics(category_statistics)
  }, [category_statistics])

  useEffect(() => {
    setProductStatistics(product_statistics)
  }, [product_statistics])

  const categories = [year.toString(), (year - 1).toString()];

  const [selectedStats, setSelectedStats] = useState(computeInitSelected(family_statistics, category_statistics, product_statistics))

  const refreshDataCategory = (selected) => {
    const categoryIds = getEnabledCategoryIds(family_statistics, selected).join();

    statistics.getCAtegoryRepartition({category_ids: categoryIds})
      .then(_categories => {
        let subCategoryIds = _categories.data.map(item => (item.sub_category.id)).join();
        statistics.getProductRepartition({sub_category_ids: subCategoryIds})
          .then(_products => {
            setProductStatistics(_products.data);
            setCategoryStatistics(_categories.data);
            setSelectedStats(prev => ({...prev, families: selected}));
          });
      });
  }

  const refreshDataProducts = (selected) => {
    const subCategoryIds = getEnabledSubCategoryIds(category_statistics, selected).join();

    statistics.getProductRepartition({sub_category_ids: subCategoryIds})
      .then(_products => {
        setProductStatistics(_products.data);
        setSelectedStats(prev => ({...prev, categories: selected}));
      })
  }

  return (
    loading_repartition ? '' :
    <div>
      <StatsRepartitionCard
        type={FAMILY_REPARTION_CARD}
        datas={family_statistics}
        loading={loading_repartition}
        title="Répartition du CA HT par familles"
        selected={selectedStats.families}
        onEvents={{
          'legendselectchanged': (event) => {
            refreshDataCategory(event.selected)
          }
        }}
        categories={categories}
      />
      <StatsRepartitionCard
        type={CATEGORY_REPARTION_CARD}
        datas={categoryStatistics}
        loading={loading_repartition}
        title="Répartition du CA HT par catégories"
        selected={selectedStats.categories}
        onEvents={{
          'legendselectchanged': (event) => {
            refreshDataProducts(event.selected)
          }
        }}
        categories={categories}
      />
      <StatsRepartitionCard
        type={PRODUCT_REPARTION_CARD}
        datas={productStatistics}
        loading={loading_repartition}
        title="Répartition du CA HT par produits"
        selected={selectedStats.products}
        categories={categories}
      />
    </div>
  );
}

export default DashboardRepartitionComponent;