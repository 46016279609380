import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import CollaboratorForm from "./FormComponent/CollaboratorForm";

// Validation Schema
import { updateValidationSchema } from "./FormValidationSchemas/updateValidationSchema";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {
  getUser,
  updateUser,
  userApiError,
  getRoles,
  roleApiError,
  getAgencies,
  agencyApiError,
} from "../../store/actions";

import { isRH } from "../../helpers/roles_helper";

const CollaboratorEdit = (props) => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    props.getUser.call(undefined,parseInt(window.location.pathname.split("/")[3], 10));
  }, [props.getUser]);

  useEffect(() => {
    props.getRoles.call();
  }, [props.getRoles]);

  useEffect(() => {
    props.getAgencies.call();
  }, [props.getAgencies]);

  useEffect(() => {
    let temp = [];

    props.roles.forEach((role) => {
      if (role.name === "Unova" || (isRH() && role.name === "Dirigeant")) {
      } else {
        temp.push({
          label: role.name,
          value: role.id,
        });
      }
    });

    setRoles(temp);
  }, [props.roles]);

  const initValues = props.user
    ? {
        email: props.user.email,
        firstname: props.user.firstname,
        lastname: props.user.lastname,
        password: "",
        password_confirmation: "",
        role: props.user.role.id,
        entryDate: props.user.entry_date,
        birthDate: props.user.birth_date,
        agencies: props.user.agencies.map((item) => item.id),
        phone: props.user.phone,
        address: props.user.address,
        contractType: props.user.contract_type,
        comment: props.user.comment,
        agenda_item_card_color: props.user.agenda_item_card_color,
      }
    : {
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        password_confirmation: "",
        role: "",
        entryDate: "",
        birthDate: "",
        agencies: [],
        phone: "",
        address: "",
        contractType: "",
        comment: "",
        agenda_item_card_color: "#FF5FBEDC",
      };

  const getContractType = () => {
    return [
      { value: "CDI", label: "CDI" },
      { value: "CDD", label: "CDD" },
      { value: "Stagiaire", label: "Stagiaire" },
      { value: "Alternant", label: "Alternant" },
      { value: "Autre", label: "Autre" },
    ];
  };

  const handleSubmit = (values) => {
    props.updateUser(
      parseInt(window.location.pathname.split("/")[3], 10),
      {
        ...values,
        entryDate: values.entryDate === "" ? new Date() : values.entryDate,
        birthDate: values.birthDate === "" ? new Date() : values.birthDate,
      },
      props.history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs
          title="Collaborateurs"
          breadcrumbItem="Modifier un collaborateur"
        />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Modifier un collaborateur
                </CardTitle>
                {props.userLoading ? null : (
                  <CollaboratorForm
                    initValues={initValues}
                    validationSchema={updateValidationSchema}
                    handleSubmit={handleSubmit}
                    getContractType={getContractType}
                    error={props.error}
                    roles={roles}
                    agencies={props.agencies}
                    isUpdate={true}
                    selectedAgencies={
                      props.user.agencies && props.user.agencies.length !== 0
                        ? props.user.agencies.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))
                        : []
                    }
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { userLoading, user, error } = state.Users;
  const { roles } = state.Roles;
  const { agencies } = state.Agencies;
  return { user, userLoading, error, roles, agencies };
};

export default withRouter(
  connect(mapStatetoProps, {
    getUser,
    updateUser,
    userApiError,
    getRoles,
    roleApiError,
    getAgencies,
    agencyApiError,
  })(CollaboratorEdit)
);
