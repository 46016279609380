import { History } from "history";
import { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Container, Label, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FlashMessage from '../../components/Common/FlashMessage';
import CheckDeposit from "../../models/check_deposit";
import ChecksTable from "./Components/Tables/checks-table";
import { V2 } from "../../api";
import { match } from "react-router";
import GlobalLoader from "../../components/Common/GlobalLoader";
import { getDateWithFormat, parseCentsAmount } from "../../helpers/helper";
import { useMemo } from "react";

const CheckDepositDetail = ({
  match,
  history
}: {
  match: match<{ id: string }>,
  history: History
}) => {
  const checkDepositId = useMemo(() => parseInt(match.params.id), [match.params.id]);
  const [checkDeposit, setCheckDeposit] = useState<CheckDeposit | null>(null);

  useEffect(() => {
    V2.check_deposits.get(checkDepositId, { groups: ['show'] }).then((response) => {
      setCheckDeposit(response.data);
    }).catch(_ => {
      console.error("Impossible de charger le dépôt de chèque")
    });
  }, [checkDepositId]);

  return (
    <>
      {!checkDeposit && <GlobalLoader />}
      {checkDeposit &&
        <div className="page-content">
          <Container fluid className="notifications-container">
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Remise de chèques"
              breadcrumbTitle="Devis / Factures"
              breadcrumbItem="Remise de chèques"
            />
            <FlashMessage />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-0">
                      <div className="d-flex justify-content-between align-items-start">
                        <div>Détail de la remise de chèque</div>
                      </div>
                    </CardTitle>
                    <Row className="my-3">
                      <Col xs="12" lg="3" md="6" className='mb-4'>
                        <Label for="value"><b>Numéro de dépot</b></Label>
                        <p>
                          { checkDeposit.depot_number }
                        </p>
                      </Col>
                      <Col xs="12" lg="3" md="6" className='mb-4 unova-datepicker'>
                        <Label for="value"><b>Date</b></Label>
                        <p>
                          { getDateWithFormat(checkDeposit.date_depot) }
                        </p>
                      </Col>
                      <Col xs="12" lg="3" md="6" className='mb-4'>
                        <Label for="value"><b>Banque</b></Label>
                        <p>
                          { checkDeposit.bank_label }
                        </p>
                      </Col>
                      <Col xs="12" lg="3" md="6" className='mb-4'>
                        <Label for="value"><b>Montant total des chèques</b></Label>
                        <p>
                          { `${parseCentsAmount(checkDeposit.total_amount!, true)}` }
                        </p>
                      </Col>
                      <Col xs={"12"} className={"mt-3"}>
                        <div className="d-flex justify-content-between align-items-end">
                          <Label for="value" className={"mb-0"}><b>Chèques(s)</b></Label>
                        </div>
                        {checkDeposit.payments!.length > 0 ?
                          <ChecksTable
                            checks={checkDeposit.payments!}
                            history={history}
                          />
                          : <><br />Aucun chèque n’a été ajouté pour le moment.</>}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </>
  );
};

export default CheckDepositDetail;
