import React from 'react';

/**
 * @description input component for forms
 *
 * @param id
 * @param name form name of the component
 * @param label label rendered next to the input field
 * @param placeholder placeholder of the input fiel (empty by default)
 * @param value default value (null by default)
 * @param isDisabled false by default
 * @param {function} handleChange handler for the change of the value (empty by default) / call the function with the "event.target.value" arg
 * @param {function} handleClick handler for the onClick props (empty by default) / call the function with the "event" arg
 */
const RadioButtonComponent = ({
    id,
    name,
    label,
    placeholder,
    value,
    isDisabled,
    handleChange,
    handleClick,
}) => {
    return (
        <div>
            <input
                id={id ? id : name}
                name={name}
                type="radio"
                placeholder={placeholder}
                value={value}
                disabled={isDisabled}
                onChange={handleChange === undefined ? undefined : ((e) => handleChange(e.target.value))}
                onClick={handleClick === undefined ? undefined : ((e) => handleClick(e))}
            />
            <label htmlFor={id ? id : name}>{label}</label>
        </div>
    );
}

export default RadioButtonComponent;