import Request from "../../../../models/request";
import {Badge, Col, Row} from "reactstrap";
import {getStatusColor} from "../../../../models/requests_status";
import {getPriorityIcon} from "../../../../models/requests_priority";
import { Link } from "react-router-dom";

export default function RequestDetailRequestTab({request}:{request:Request}){
  return (
    <Row className="pr-2 pl-2 d-flex font-size-14 text-theme-color">
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Statut : </span>
        <span className="font-weight-light"><Badge style={{ background: getStatusColor(request.status || {}) }}>{request.status?.label}</Badge></span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Projet/dossier rattaché : </span>
        <Link to={!request?.project_id ? "#" : `/projects-overview/${request?.project_id}`} className="font-weight-light  text-theme-color hover:text-theme-color">{request?.project_name || "Aucun projet/dossier"}</Link>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Priorité : </span>
        <span className="font-weight-light">{getPriorityIcon(request?.priority || {})} {request.priority?.label}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Canal d'entrée : </span>
        <span className="font-weight-light">{request?.type?.label || "Aucun canal"}</span>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Collaborateur assigné : </span>
        <Link to={!request?.collaborator ? "#" : `/collaborator/detail/${request?.collaborator?.id}`} className="font-weight-light  text-theme-color hover:text-theme-color">{request?.collaborator?.lastname} {request?.collaborator?.firstname}</Link>
      </Col>
      <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Nature : </span>
        <span className="font-weight-light">{request?.nature?.label}</span>
      </Col>
      {request?.maintenance_contract?.formule_label && <Col xs={"4"} className={"mt-3"}>
        <span className="font-weight-semibold">Contrat de maintenance : </span>
        <span className="font-weight-light">{request?.maintenance_contract?.formule_label}</span>
      </Col>}
      <Col xs={"12"} className={"mt-3"}>
        <span className="font-weight-semibold">Commentaire : </span>
        <span className="font-weight-light">{request?.comment || "Aucun commentaire"}</span>
      </Col>
    </Row>
  );
}