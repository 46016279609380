import { withRouter } from "react-router-dom";
import { Row, Col, CardBody, Card, Button, Label, Input } from "reactstrap";
import { Formik, Field, Form } from 'formik';

// Redux
import { connect } from "react-redux";

import { FormikAutocompleteUnselectable } from "../../../components/Common/AsyncAutoComplete2";

// Helpers
import {
  promiseAgenciesOptions,
  promiseCategoriesOptions,
  promiseSubCategoriesOptions,
  promiseProductsOptions,
  promiseCommercialOptions
} from "../../../helpers/autocomplete_promise_options";

// Actions
import { getProjectStates } from "../../../store/actions";

//Import Date Picker
import DatePicker from "../../../components/Common/DatePicker";

const GeneralTableFilters = (props) => {
  const {
    filters,
    setFilters,
  } = props;

  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      onSubmit={(values) => {
        setFilters({
          ...filters,
          user_id: values.user_id,
          customer: values.customer,
          project_state_id: values.project_state_id,
          subscription_state: values.subscription_state,
          agency_id: values.agency_id,
          date_begin: values.date_begin,
          date_end: values.date_end,
          product_ids: values.product_ids,
          category_ids: values.category_ids,
          sub_category_ids: values.sub_category_ids,
        })
      }}
    >
      {({ values, setFieldValue, handleSubmit}) => (
        <Form>
          <Card>
            <CardBody>
              <Row>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="user_id">Commercial</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        name="user_id"
                        id="user_id"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucun commerciaux disponible"}
                        placeholder="Choisir un commercial..."
                        loadOptions={promiseCommercialOptions}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="customer">Client</Label>
                    </Col>
                    <Col xs="12">
                      <Input
                        id="customer"
                        name="customer"
                        type="text"
                        className="form-control"
                        tag={Field}
                        placeholder="Entrer le nom d'un client"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="project_state_id">État</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        name="project_state_id"
                        id="project_state_id"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucun état disponible"}
                        placeholder="Choisir un état..."
                        loadOptions={props.projectStates.map((state, index) => ({
                          value: state.id,
                          label: state.name,
                        }))}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="subscription_state">État du contrat d'entretien</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        name="subscription_state"
                        id="subscription_state"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucun état disponible"}
                        placeholder="Choisir un état..."
                        loadOptions={[
                          { value: "validate", label: "Validé" },
                          { value: "waiting", label: "En attente" },
                          { value: "refuse", label: "Refusé" },
                          { value: "without_contract", label: "Sans contrat" },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="agency_id">Agence</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        id="agency_id"
                        name="agency_id"
                        isSearchable={true}
                        noOptionsMessage={() => "Aucune agence disponible"}
                        placeholder="Choisir une agence..."
                        cacheOptions
                        defaultOptions
                        loadOptions={promiseAgenciesOptions}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label for="date_begin">Date de début</Label>
                    </Col>
                    <Col xs="12">
                      <DatePicker
                        className="form-control button-without-style"
                        name="date_begin"
                        value={values.date_begin}
                        onChange={setFieldValue}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label for="date_end">Date de fin</Label>
                    </Col>
                    <Col xs="12">
                      <DatePicker
                        className="form-control button-without-style"
                        name="date_end"
                        value={values.date_end}
                        onChange={setFieldValue}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="category_ids">Famille</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        name="category_ids"
                        id="category_ids"
                        isSearchable={false}
                        multiple={true}
                        noOptionsMessage={() => "Aucune famille disponible"}
                        placeholder="Choisir une famille..."
                        loadOptions={promiseCategoriesOptions}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="sub_category_ids">Catégorie</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        id="sub_category_ids"
                        name="sub_category_ids"
                        isSearchable={false}
                        multiple={true}
                        noOptionsMessage={() => "Aucune categorie disponible"}
                        placeholder="Choisir une categorie..."
                        cacheOptions
                        loadOptions={promiseSubCategoriesOptions}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4">
                  <Row>
                    <Col xs="12">
                      <Label for="product_ids">Produit</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        className="w-100"
                        id="product_ids"
                        name="product_ids"
                        isSearchable={true}
                        multiple={true}
                        noOptionsMessage={() => "Aucun produit disponible"}
                        placeholder="Choisir un produit..."
                        cacheOptions
                        defaultOptions
                        loadOptions={promiseProductsOptions}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="d-flex justify-content-center mt-3">
                  <Button
                    color="none"
                    className="btn-green"
                    onClick={() => handleSubmit(values)}
                  >
                    Appliquer les filtres
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

const mapStatetoProps = (state) => {
  const { projectStates } = state.ProjectStates;

  return { projectStates };
};

export default withRouter(
  connect(mapStatetoProps, { getProjectStates })(GeneralTableFilters)
);
