import React, {useState} from "react";

import {searchCitiesByZipCode} from "../../../helpers/zip_code_helper";

import {
    Row,
    Col,
    Input,
    FormGroup,
    Label,
    Button,
    FormFeedback,
    Alert,
    Collapse,
} from "reactstrap";
import {Formik, Form, Field, ErrorMessage} from "formik";
import FormikAutocomplete from "../../../components/Common/Autocomplete";
import FormikAsyncAutocomplete from "../../../components/Common/AsyncAutocomplete";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import {promiseCustomersOptions} from "../../../helpers/autocomplete_promise_options";

import {forceKeyPressUppercase} from "../../../helpers/input_helper";

//Import Date Picker
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

const CustomerForm = (props) => {
    const [section_1, setSection_1] = useState(!props.isUpdate);
    const [section_2, setSection_2] = useState(false);
    const [section_3, setSection_3] = useState(false);
    const [section_4, setSection_4] = useState(false);
    const [section_5, setSection_5] = useState(false);
    const [section_6, setSection_6] = useState(false);
    const [section_7, setSection_7] = useState(false);
    const [cities, setCities] = useState([]);
    const [cities_construction_site, setCities_construction_site] = useState([]);
    const [atticType, setAtticType] = useState(null); // Use to switch between

    const getCities = async (postal_code, is_construction_site = false) => {
        const cities = await searchCitiesByZipCode(postal_code);

        let temp = cities.map((name) => {
            return {label: name, value: name};
        });

        is_construction_site ? setCities_construction_site(temp) : setCities(temp);
    };

    const errorInForm = (errors, touched, section) => {
        switch (section) {
            case "section_1":
                return (errors.civility && !!touched.civility) ||
                (props.client_type === "Professionnel" &&
                    errors.company_name &&
                    !!touched.company_name) ||
                (errors.firstname && !!touched.firstname) ||
                (errors.lastname && !!touched.lastname) ||
                (errors.address && !!touched.address) ||
                (errors.additional_address && !!touched.additional_address) ||
                (errors.zip_code && !!touched.zip_code) ||
                (errors.city && !!touched.city) ||
                (errors.mobile_phone && !!touched.mobile_phone) ||
                (errors.mobile_phone2 && !!touched.mobile_phone2) ||
                (errors.fix_phone && !!touched.fix_phone) ||
                (errors.email && !!touched.email) ||
                (errors.email2 && !!touched.email2) ||
                (errors.address_construction_site &&
                    !!touched.address_construction_site) ||
                (errors.additional_address_construction_site &&
                    !!touched.additional_address_construction_site) ||
                (errors.city_construction_site && !!touched.city_construction_site) ||
                (errors.zip_code_construction_site &&
                    !!touched.zip_code_construction_site)
                    ? true
                    : false;
            case "section_2":
                return (errors.main_resideence && !!touched.main_resideence) ||
                (errors.owner_at && !!touched.owner_at) ||
                (errors.housing_seniority && !!touched.housing_seniority) ||
                (errors.surface_habitation && !!touched.surface_habitation) ||
                (errors.floors_number && !!touched.floors_number) ||
                (errors.is_isolate_floors && !!touched.is_isolate_floors) ||
                (errors.attic_type && !!touched.attic_type) ||
                (errors.attic_isulated_at && !!touched.attic_isulated_at) ||
                (errors.attic_isulation_type && !!touched.attic_isulation_type) ||
                (errors.is_attic_isulation && !!touched.is_attic_isulation) ||
                (errors.window_type && !!touched.window_type) ||
                (errors.windows_number && !!touched.windows_number) ||
                (errors.ecs_production_type && !!touched.ecs_production_type) ||
                (errors.ecs_production_type_annual_cost &&
                    !!touched.ecs_production_type_annual_cost) ||
                (errors.heading_type && !!touched.heading_type) ||
                (errors.heading_type_annual_cost &&
                    !!touched.heading_type_annual_cost) ||
                (errors.appointment_explication && !!touched.appointment_explication)
                    ? true
                    : false;
            case "section_3":
                return (errors.building_work_type && !!touched.building_work_type) ||
                (errors.already_benefit_energy_study &&
                    !!touched.already_benefit_energy_study) ||
                (errors.already_benefit_grant && !!touched.already_benefit_grant)
                    ? true
                    : false;
            case "section_4":
                return (errors.first_contact_date && !!touched.first_contact_date) ||
                (errors.contact_origin && !!touched.contact_origin)
                    ? true
                    : false;
            case "section_5":
                return (errors.function1 && !!touched.function1) ||
                (errors.work_council1 && !!touched.work_council1) ||
                (errors.function2 && !!touched.function2) ||
                (errors.work_council2 && !!touched.work_council2) ||
                (errors.age && !!touched.age) ||
                (errors.spouse_age && !!touched.spouse_age) ||
                (errors.children_number && !!touched.children_number)
                    ? true
                    : false;
            case "section_6":
                return (errors.appointment_date && !!touched.appointment_date) ||
                (errors.appointment_take_with && !!touched.appointment_take_with) ||
                (errors.appointment_in_construction_site &&
                    !!touched.appointment_in_construction_site) ||
                (errors.decision_makers_present &&
                    !!touched.decision_makers_present) ||
                (errors.ok_one_hours_thirty && !!touched.ok_one_hours_thirty) ||
                (errors.appointment_method && !!touched.appointment_method)
                    ? true
                    : false;
            case "section_7":
                return (errors.follow_up_number_star &&
                    !!touched.follow_up_number_star) ||
                (errors.follow_up_call_date && !!touched.follow_up_call_date) ||
                (errors.follow_up_refusal_reason &&
                    !!touched.follow_up_refusal_reason) ||
                (errors.follow_up_client_profil && !!touched.follow_up_client_profil)
                    ? true
                    : false;
            default:
                return false;
        }
    };

    return (
        <Formik
            initialValues={props.initValues}
            validationSchema={props.validationSchema}
            enableReinitialize
            onSubmit={(values) => {
                props.handleSubmit(values);
            }}
            render={({values, errors, touched, setFieldValue, isValid}) => (
                <Form>
                    <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                            <h5
                                className={`accordion-title${
                                    errorInForm(errors, touched, "section_1")
                                        ? " accordion-error"
                                        : ""
                                }`}
                                onClick={() => setSection_1(!section_1)}
                            >
                                <div className="d-flex align-items-center pt-3 pb-3">
                                    <i
                                        className={`bx bx-chevron-${
                                            section_1 ? "up" : "down"
                                        } mr-1`}
                                    ></i>{" "}
                                    Identification du prospect
                                    {errorInForm(errors, touched, "section_1") ? (
                                        <i className="fas fa-times ml-3"></i>
                                    ) : null}
                                </div>
                                <hr className="m-0"/>
                            </h5>
                            <Collapse className="mt-4" isOpen={section_1}>
                                {props.client_type === "Professionnel" ? (
                                    <FormGroup className="mb-4" row>
                                        <Label
                                            htmlFor="company_name"
                                            className="col-form-label col-lg-2"
                                        >
                                            Nom de la société {props.isUpdate ? null : "*"}
                                        </Label>
                                        <Col lg="10">
                                            <Input
                                                id="company_name"
                                                name="company_name"
                                                type="text"
                                                tag={Field}
                                                invalid={errors.company_name && !!touched.company_name}
                                                className="form-control"
                                                placeholder="Entrer un nom de société"
                                            />
                                            <ErrorMessage
                                                name="company_name"
                                                component={FormFeedback}
                                            />
                                        </Col>
                                    </FormGroup>
                                ) : null}
                                {props.client_type === "Professionnel" ? (
                                    <FormGroup className="mb-4" row>
                                        <Label htmlFor="siret" className="col-form-label col-lg-2">
                                            Siret
                                        </Label>
                                        <Col lg="10">
                                            <Input
                                                id="siret"
                                                name="siret"
                                                type="text"
                                                tag={Field}
                                                invalid={errors.siret && !!touched.siret}
                                                className="form-control"
                                                placeholder="Entrer un numéro siret"
                                            />
                                            <ErrorMessage name="siret" component={FormFeedback}/>
                                        </Col>
                                    </FormGroup>
                                ) : null}
                                <FormGroup className="mb-4" row>
                                    <Label htmlFor="civility" className="col-form-label col-lg-2">
                                        Civilité{" "}
                                        {props.isUpdate
                                            ? null
                                            : props.client_type === "Professionnel"
                                                ? null
                                                : "*"}
                                    </Label>
                                    <Col lg="10">
                                        <FormikAutocomplete
                                            name="civility"
                                            id="civility"
                                            value={{value: values.civility, label: values.civility}}
                                            className={errors.civility ? "selectorError" : null}
                                            invalid={errors.civility && !!touched.civility}
                                            options={props.getCivility()}
                                        />
                                        <ErrorMessage
                                            className={errors.civility ? "errorMessages" : null}
                                            name="civility"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup className="mb-4" row>
                                    <Label htmlFor="lastname" className="col-form-label col-lg-2">
                                        Nom{" "}
                                        {props.isUpdate
                                            ? null
                                            : props.client_type === "Professionnel"
                                                ? null
                                                : "*"}
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="lastname"
                                            name="lastname"
                                            type="text"
                                            tag={Field}
                                            invalid={errors.lastname && !!touched.lastname}
                                            className="form-control"
                                            placeholder="Entrer un nom"
                                            onKeyPress={(e) => {
                                                forceKeyPressUppercase(e);
                                                setFieldValue(e.target.name, e.target.value)
                                            }}
                                        />
                                        <ErrorMessage name="lastname" component={FormFeedback}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="firstname"
                                        className="col-form-label col-lg-2"
                                    >
                                        Prénom{" "}
                                        {props.isUpdate
                                            ? null
                                            : props.client_type === "Professionnel"
                                                ? null
                                                : "*"}
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="firstname"
                                            name="firstname"
                                            type="text"
                                            tag={Field}
                                            invalid={errors.firstname && !!touched.firstname}
                                            className="form-control"
                                            placeholder="Entrer un prénom"
                                        />
                                        <ErrorMessage name="firstname" component={FormFeedback}/>
                                    </Col>
                                </FormGroup>

                                {values.civility === "M. OU MME." ?
                                    <React.Fragment>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="lastname2"
                                                className="col-form-label col-lg-2"
                                            >
                                                Nom 2{" "}
                                            </Label>
                                            <Col lg="10">
                                                <Input
                                                    id="lastname2"
                                                    name="lastname2"
                                                    type="text"
                                                    tag={Field}
                                                    invalid={errors.lastname2 && !!touched.lastname2}
                                                    className="form-control"
                                                    placeholder="Entrer un nom"
                                                    onKeyPress={(e) => {
                                                        forceKeyPressUppercase(e);
                                                        setFieldValue(e.target.name, e.target.value)
                                                    }}
                                                />
                                                <ErrorMessage name="lastname2" component={FormFeedback}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label htmlFor="firstname2" className="col-form-label col-lg-2">
                                                Prénom 2
                                            </Label>
                                            <Col lg="10">
                                                <Input
                                                    id="firstname2"
                                                    name="firstname2"
                                                    type="text"
                                                    tag={Field}
                                                    invalid={errors.firstname2 && !!touched.firstname2}
                                                    className="form-control"
                                                    placeholder="Entrer un prénom"
                                                />
                                                <ErrorMessage name="firstname2" component={FormFeedback}/>
                                            </Col>
                                        </FormGroup>
                                    </React.Fragment> : ''}


                                <Row>
                                    <Col lg="6">
                                        <h5>Adresse principale</h5>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="address"
                                                className="col-form-label col-lg-4"
                                            >
                                                Rue {props.isUpdate ? null : "*"}
                                            </Label>
                                            <Col lg="8">
                                                <Input
                                                    id="address"
                                                    name="address"
                                                    type="text"
                                                    tag={Field}
                                                    invalid={errors.address && !!touched.address}
                                                    placeholder="Entrer une adresse"
                                                    className="form-control"
                                                />
                                                <ErrorMessage name="address" component={FormFeedback}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="additional_address"
                                                className="col-form-label col-lg-4"
                                            >
                                                Complément d'adresse
                                            </Label>
                                            <Col lg="8">
                                                <Input
                                                    id="additional_address"
                                                    name="additional_address"
                                                    type="text"
                                                    tag={Field}
                                                    invalid={
                                                        errors.additional_address &&
                                                        !!touched.additional_address
                                                    }
                                                    placeholder="Entrer un Complément d'adresse"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name="additional_address"
                                                    component={FormFeedback}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="zip_code"
                                                className="col-form-label col-lg-4"
                                            >
                                                Code postal {props.isUpdate ? null : "*"}
                                            </Label>
                                            <Col lg="8">
                                                <Input
                                                    id="zip_code"
                                                    name="zip_code"
                                                    type="text"
                                                    tag={Field}
                                                    invalid={errors.zip_code && !!touched.zip_code}
                                                    placeholder="Entrer un code postal"
                                                    className="form-control"
                                                    onChange={(event) => {
                                                        setFieldValue(
                                                            "zip_code",
                                                            event.currentTarget.value
                                                        );
                                                        if (event.currentTarget.value.length === 5) {
                                                            getCities(event.currentTarget.value);
                                                        }
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="zip_code"
                                                    component={FormFeedback}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label htmlFor="city" className="col-form-label col-lg-4">
                                                Ville {props.isUpdate ? null : "*"}
                                            </Label>
                                            <Col lg="8">
                                                <CreatableSelect
                                                    className={`w-100 ${
                                                        errors.city ? "selectorError" : ""
                                                    }`}
                                                    isClearable={true}
                                                    noOptionsMessage={() =>
                                                        "Veuillez entrer un code postal pour choisir une ville"
                                                    }
                                                    defaultValue={
                                                        values.city && values.city !== ""
                                                            ? {
                                                                value: values.city,
                                                                label: values.city,
                                                            }
                                                            : null
                                                    }
                                                    placeholder="Choisir une ville..."
                                                    options={cities}
                                                    onChange={(city) => {
                                                        setFieldValue(
                                                            "city",
                                                            city === null ? null : city.value
                                                        );
                                                    }}
                                                    formatCreateLabel={(inputValue) =>
                                                        `Choisir "${inputValue}"`
                                                    }
                                                />
                                                <ErrorMessage
                                                    className={errors.city ? "errorMessages" : null}
                                                    name="city"
                                                    component={FormFeedback}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <h5>Adresse du chantier</h5>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="address_construction_site"
                                                className="col-form-label col-lg-4"
                                            >
                                                Rue
                                            </Label>
                                            <Col lg="8">
                                                <Input
                                                    id="address_construction_site"
                                                    name="address_construction_site"
                                                    type="text"
                                                    tag={Field}
                                                    invalid={
                                                        errors.address_construction_site &&
                                                        !!touched.address_construction_site
                                                    }
                                                    placeholder="Entrer une adresse"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name="address_construction_site"
                                                    component={FormFeedback}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="additional_address_construction_site"
                                                className="col-form-label col-lg-4"
                                            >
                                                Complément d'adresse du chantier
                                            </Label>
                                            <Col lg="8">
                                                <Input
                                                    id="additional_address_construction_site"
                                                    name="additional_address_construction_site"
                                                    type="text"
                                                    tag={Field}
                                                    invalid={
                                                        errors.additional_address_construction_site &&
                                                        !!touched.additional_address_construction_site
                                                    }
                                                    placeholder="Entrer un Complément d'adresse du chantier"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name="additional_address_construction_site"
                                                    component={FormFeedback}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="zip_code_construction_site"
                                                className="col-form-label col-lg-4"
                                            >
                                                Code postal
                                            </Label>
                                            <Col lg="8">
                                                <Input
                                                    id="zip_code_construction_site"
                                                    name="zip_code_construction_site"
                                                    type="text"
                                                    tag={Field}
                                                    invalid={
                                                        errors.zip_code_construction_site &&
                                                        !!touched.zip_code_construction_site
                                                    }
                                                    placeholder="Entrer un code postal"
                                                    className="form-control"
                                                    onChange={(event) => {
                                                        setFieldValue(
                                                            "zip_code_construction_site",
                                                            event.currentTarget.value
                                                        );
                                                        if (event.currentTarget.value.length === 5) {
                                                            getCities(event.currentTarget.value, true);
                                                        }
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="zip_code_construction_site"
                                                    component={FormFeedback}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="city_construction_site"
                                                className="col-form-label col-lg-4"
                                            >
                                                Ville
                                            </Label>
                                            <Col lg="8">
                                                <CreatableSelect
                                                    className={`w-100 ${
                                                        errors.city_construction_site ? "selectorError" : ""
                                                    }`}
                                                    isClearable={true}
                                                    noOptionsMessage={() =>
                                                        "Veuillez entrer un code postal pour choisir une ville"
                                                    }
                                                    defaultValue={
                                                        values.city_construction_site &&
                                                        values.city_construction_site !== ""
                                                            ? {
                                                                value: values.city_construction_site,
                                                                label: values.city_construction_site,
                                                            }
                                                            : null
                                                    }
                                                    placeholder="Choisir une ville..."
                                                    options={cities_construction_site}
                                                    onChange={(city_construction_site) => {
                                                        setFieldValue(
                                                            "city_construction_site",
                                                            city_construction_site === null
                                                                ? null
                                                                : city_construction_site.value
                                                        );
                                                    }}
                                                    formatCreateLabel={(inputValue) =>
                                                        `Choisir "${inputValue}"`
                                                    }
                                                />
                                                <ErrorMessage
                                                    className={
                                                        errors.city_construction_site
                                                            ? "errorMessages"
                                                            : null
                                                    }
                                                    name="city_construction_site"
                                                    component={FormFeedback}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="mobile_phone"
                                        className="col-form-label col-lg-2"
                                    >
                                        Téléphone mobile
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="mobile_phone"
                                            name="mobile_phone"
                                            type="tel"
                                            tag={Field}
                                            invalid={errors.mobile_phone && !!touched.mobile_phone}
                                            className="form-control"
                                            placeholder="Entrer un numéro de téléphone mobile"
                                        />
                                        <ErrorMessage
                                            name="mobile_phone"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="mobile_phone2"
                                        className="col-form-label col-lg-2"
                                    >
                                        Téléphone mobile 2
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="mobile_phone2"
                                            name="mobile_phone2"
                                            type="tel"
                                            tag={Field}
                                            invalid={errors.mobile_phone2 && !!touched.mobile_phone2}
                                            className="form-control"
                                            placeholder="Entrer un numéro de téléphone mobile"
                                        />
                                        <ErrorMessage
                                            name="mobile_phone2"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="fix_phone"
                                        className="col-form-label col-lg-2"
                                    >
                                        Téléphone fixe
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="fix_phone"
                                            name="fix_phone"
                                            type="tel"
                                            tag={Field}
                                            invalid={errors.fix_phone && !!touched.fix_phone}
                                            className="form-control"
                                            placeholder="Entrer un numéro de téléphone fixe"
                                        />
                                        <ErrorMessage name="fix_phone" component={FormFeedback}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label htmlFor="email" className="col-form-label col-lg-2">
                                        Email
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            tag={Field}
                                            invalid={errors.email && !!touched.email}
                                            className="form-control"
                                            placeholder="Entrer un email"
                                        />
                                        <ErrorMessage name="email" component={FormFeedback}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label htmlFor="email2" className="col-form-label col-lg-2">
                                        Email secondaire
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="email2"
                                            name="email2"
                                            type="email2"
                                            tag={Field}
                                            invalid={errors.email2 && !!touched.email2}
                                            className="form-control"
                                            placeholder="Entrer un email"
                                        />
                                        <ErrorMessage name="email2" component={FormFeedback}/>
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <h5
                                className={`accordion-title${
                                    errorInForm(errors, touched, "section_2")
                                        ? " accordion-error"
                                        : ""
                                }`}
                                onClick={() => setSection_2(!section_2)}
                            >
                                <div className="d-flex align-items-center pt-3 pb-3">
                                    <i
                                        className={`bx bx-chevron-${
                                            section_2 ? "up" : "down"
                                        } mr-1`}
                                    ></i>{" "}
                                    Détails du logement
                                    {errorInForm(errors, touched, "section_2") ? (
                                        <i className="fas fa-times ml-3"></i>
                                    ) : null}
                                </div>
                                <hr className="m-0"/>
                            </h5>
                            <Collapse className="mt-4" isOpen={section_2}>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="is_owner"
                                        className="col-form-label col-lg-2"
                                    >
                                        Situation
                                    </Label>
                                    <Col lg="10">
                                        <Select
                                            className={`w-100 ${
                                                errors.is_owner ? "selectorError" : ""
                                            }`}
                                            isSearchable={true}
                                            noOptionsMessage={() =>
                                                "Pas d'élément disponible"
                                            }
                                            defaultValue={
                                                values.is_owner !== null
                                                    ? {
                                                        value: values.is_owner,
                                                        label: values.is_owner ? "Propriétaire" : "Locataire",
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir une situation..."
                                            options={[
                                                {value: false, label: "Locataire"},
                                                {value: true, label: "Propriétaire"},
                                            ]}
                                            onChange={(is_owner) => {
                                                setFieldValue("is_owner", is_owner.value);
                                            }}
                                        />
                                        <ErrorMessage
                                            name="is_owner"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Col xs="auto">
                                        <Label htmlFor="main_resideence">
                                            Résidence principale ?
                                        </Label>
                                    </Col>
                                    <Col className="custom-control custom-switch">
                                        <Input
                                            id="main_resideence"
                                            name="main_resideence"
                                            type="checkbox"
                                            tag={Field}
                                            invalid={
                                                errors.main_resideence && !!touched.main_resideence
                                            }
                                            className="custom-control-input ml-3"
                                        />
                                        <Label
                                            htmlFor="main_resideence"
                                            className="custom-control-label"
                                        ></Label>
                                        <ErrorMessage
                                            name="main_resideence"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label htmlFor="owner_at" className="col-form-label col-lg-2">
                                        <span className="d-block">Propriétaire depuis</span>
                                        <small className="text-muted">
                                            (si vide, le champ vaut NR)
                                        </small>
                                    </Label>
                                    <Col lg="10">
                                        <DatePicker
                                            className="form-control button-without-style"
                                            selected={
                                                values.owner_at && values.owner_at !== ""
                                                    ? new Date(values.owner_at)
                                                    : null
                                            }
                                            onChange={(date) => {
                                                date.getHours() === 12
                                                    ? date.setHours(date.getHours() + 0)
                                                    : date.setHours(date.getHours() + 12);
                                                setFieldValue("owner_at", date);
                                            }}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            yearItemNumber={18}
                                            locale={fr}
                                            placeholderText="Entrer une année"
                                        />
                                        <ErrorMessage name="owner_at" component={FormFeedback}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="housing_seniority"
                                        className="col-form-label col-lg-2"
                                    >
                                        <span className="d-block">Ancienneté du logement</span>
                                    </Label>
                                    <Col lg="10">
                                        <Select
                                            className={`w-100 ${
                                                errors.housing_seniority ? "selectorError" : ""
                                            }`}
                                            isSearchable={true}
                                            noOptionsMessage={() =>
                                                "Pas d'élément disponible"
                                            }
                                            defaultValue={
                                                values.housing_seniority !== null
                                                    ? {
                                                        value: values.housing_seniority,
                                                        label: values.housing_seniority,
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir une ancienneté..."
                                            options={[
                                                {value: "Avant 1950", label: "Avant 1950"},
                                                {value: "1950-1985", label: "1950-1985"},
                                                {value: "1985-2000", label: "1985-2000"},
                                                {value: "2000-2005", label: "2000-2005"},
                                                {value: "2005-2010", label: "2005-2010"},
                                                {value: "Après 2010", label: "Après 2010"},
                                            ]}
                                            onChange={(housing_seniority) => {
                                                setFieldValue("housing_seniority", housing_seniority.value);
                                            }}
                                        />
                                        <ErrorMessage
                                            name="housing_seniority"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="surface_habitation"
                                        className="col-form-label col-lg-2"
                                    >
                                        Superficie de l'habitation (m2)
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="surface_habitation"
                                            name="surface_habitation"
                                            type="text"
                                            tag={Field}
                                            invalid={
                                                errors.surface_habitation &&
                                                !!touched.surface_habitation
                                            }
                                            className="form-control"
                                            placeholder="Entrer une superficie en m2"
                                        />
                                        <ErrorMessage
                                            name="surface_habitation"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="floors_number"
                                        className="col-form-label col-lg-2"
                                    >
                                        Nombre d'étages
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="floors_number"
                                            name="floors_number"
                                            type="text"
                                            tag={Field}
                                            invalid={errors.floors_number && !!touched.floors_number}
                                            className="form-control"
                                            placeholder="Entrer le nombre d'étage"
                                        />
                                        <ErrorMessage
                                            name="floors_number"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="attic_type"
                                        className="col-form-label col-lg-2"
                                    >
                                        Type de combles
                                    </Label>
                                    <Col lg="10">
                                        <Select
                                            className={`w-100 ${
                                                errors.attic_type ? "selectorError" : ""
                                            }`}
                                            isSearchable={true}
                                            noOptionsMessage={() =>
                                                "Pas de type de combles disponible"
                                            }
                                            defaultValue={
                                                values.attic_type && values.attic_type !== ""
                                                    ? {
                                                        value: values.attic_type,
                                                        label: values.attic_type,
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir un type de combles..."
                                            options={[
                                                {
                                                    value: "Combles aménagés",
                                                    label: "Combles aménagés",
                                                },
                                                {value: "Combles perdus", label: "Combles perdus"},
                                            ]}
                                            onChange={(attic_type) => {
                                                setAtticType(attic_type.value);
                                                setFieldValue("attic_type", attic_type.value);
                                            }}
                                        />
                                        <ErrorMessage
                                            className={errors.attic_type ? "errorMessages" : null}
                                            name="attic_type"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Col xs="auto">
                                        <Label htmlFor="is_attic_isulation">
                                            Isolation des combles
                                        </Label>
                                    </Col>
                                    <Col className="custom-control custom-switch">
                                        <Input
                                            id="is_attic_isulation"
                                            name="is_attic_isulation"
                                            type="checkbox"
                                            tag={Field}
                                            invalid={
                                                errors.is_attic_isulation &&
                                                !!touched.is_attic_isulation
                                            }
                                            className="custom-control-input ml-3"
                                        />
                                        <Label
                                            htmlFor="is_attic_isulation"
                                            className="custom-control-label"
                                        ></Label>
                                        <ErrorMessage
                                            name="is_attic_isulation"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                {values.is_attic_isulation ? (
                                    <FormGroup className="mb-4" row>
                                        <Label
                                            htmlFor="attic_isulated_at"
                                            className="col-form-label col-lg-2"
                                        >
                      <span className="d-block">
                        Date de l'isolation des combles
                      </span>
                                            <small className="text-muted">
                                                (si vide, le champ vaut NR)
                                            </small>
                                        </Label>
                                        <Col lg="10">
                                            <DatePicker
                                                className="form-control button-without-style"
                                                selected={
                                                    values.attic_isulated_at &&
                                                    values.attic_isulated_at !== ""
                                                        ? values.attic_isulated_at
                                                        : null
                                                }
                                                onChange={(date) => {
                                                    date.getHours() === 12
                                                        ? date.setHours(date.getHours() + 0)
                                                        : date.setHours(date.getHours() + 12);
                                                    setFieldValue("attic_isulated_at", date);
                                                }}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                yearItemNumber={18}
                                                locale={fr}
                                                placeholderText="Entrer une année"
                                            />
                                            <ErrorMessage
                                                name="attic_isulated_at"
                                                component={FormFeedback}
                                            />
                                        </Col>
                                    </FormGroup>
                                ) : null}
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="attic_isulation_type"
                                        className="col-form-label col-lg-2"
                                    >
                                        Type d'isolation
                                    </Label>
                                    <Col lg="10">
                                        <Select
                                            className={`w-100 ${
                                                errors.attic_isulation_type ? "selectorError" : ""
                                            }`}
                                            isSearchable={true}
                                            noOptionsMessage={() =>
                                                "Pas de type de combles disponible"
                                            }
                                            defaultValue={
                                                values.attic_isulation_type &&
                                                values.attic_isulation_type !== ""
                                                    ? {
                                                        value: values.attic_isulation_type,
                                                        label: values.attic_isulation_type,
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir un type de combles..."
                                            options={
                                                // When component is mount for the first time then atticType is null
                                                // TODO: Replace by props params
                                                (atticType || values.attic_type) === "Combles aménagés"
                                                    ? [
                                                        {value: "Isolation sous rampant", label: "Isolation sous rampant"},
                                                        {value: "Non isolé", label: "Non isolé"},
                                                    ]
                                                    : (atticType || values.attic_type) === "Combles perdus"
                                                        ? [
                                                            {value: "Laine de roche", label: "Laine de roche"},
                                                            {value: "Laine de verre", label: "Laine de verre"},
                                                            {value: "Ouatte de cellulose", label: "Ouatte de cellulose"},
                                                            {value: "Autre", label: "Autre"},
                                                        ]
                                                        : []
                                            }
                                            onChange={(attic_isulation_type) => {
                                                setFieldValue(
                                                    "attic_isulation_type",
                                                    attic_isulation_type.value
                                                );
                                            }}
                                        />
                                        <ErrorMessage
                                            className={
                                                errors.attic_isulation_type ? "errorMessages" : null
                                            }
                                            name="attic_isulation_type"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="window_type"
                                        className="col-form-label col-lg-2"
                                    >
                                        Type de fenêtre
                                    </Label>
                                    <Col lg="10">
                                        <Select
                                            className={`w-100 ${
                                                errors.window_type ? "selectorError" : ""
                                            }`}
                                            isSearchable={true}
                                            noOptionsMessage={() =>
                                                "Pas de type de fenêtre disponible"
                                            }
                                            defaultValue={
                                                values.window_type && values.window_type !== ""
                                                    ? {
                                                        value: values.window_type,
                                                        label: values.window_type,
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir un type de fenêtre..."
                                            options={[
                                                {value: "Simple vitrage", label: "Simple vitrage"},
                                                {value: "Double vitrage", label: "Double vitrage"},
                                            ]}
                                            onChange={(window_type) => {
                                                setFieldValue("window_type", window_type.value);
                                            }}
                                        />
                                        <ErrorMessage
                                            className={errors.window_type ? "errorMessages" : null}
                                            name="window_type"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="windows_number"
                                        className="col-form-label col-lg-2"
                                    >
                                        Nombre de fenêtre
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="windows_number"
                                            name="windows_number"
                                            type="text"
                                            tag={Field}
                                            invalid={
                                                errors.windows_number && !!touched.windows_number
                                            }
                                            className="form-control"
                                            placeholder="Entrer un nombre de fenêtre"
                                        />
                                        <ErrorMessage
                                            name="windows_number"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="ecs_production_type"
                                        className="col-form-label col-lg-2"
                                    >
                                        Type de produit ECS
                                    </Label>
                                    <Col lg="10">
                                        <Select
                                            className={`w-100 ${
                                                errors.ecs_production_type ? "selectorError" : ""
                                            }`}
                                            isSearchable={true}
                                            noOptionsMessage={() =>
                                                "Pas de type de produit ECS disponible"
                                            }
                                            defaultValue={
                                                values.ecs_production_type &&
                                                values.ecs_production_type !== ""
                                                    ? {
                                                        value: values.ecs_production_type,
                                                        label: values.ecs_production_type,
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir un type de produit ECS..."
                                            options={[
                                                {value: "Elec", label: "Elec"},
                                                {value: "Fioul", label: "Fioul"},
                                                {value: "Gaz", label: "Gaz"},
                                                {value: "Bois", label: "Bois"},
                                                {value: "Propane", label: "Propane"},
                                            ]}
                                            onChange={(ecs_production_type) => {
                                                setFieldValue(
                                                    "ecs_production_type",
                                                    ecs_production_type.value
                                                );
                                            }}
                                        />
                                        <ErrorMessage
                                            className={
                                                errors.ecs_production_type ? "errorMessages" : null
                                            }
                                            name="ecs_production_type"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="ecs_production_type_annual_cost"
                                        className="col-form-label col-lg-2"
                                    >
                                        Coût annuel [ECS]
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="ecs_production_type_annual_cost"
                                            name="ecs_production_type_annual_cost"
                                            type="text"
                                            tag={Field}
                                            invalid={
                                                errors.ecs_production_type_annual_cost &&
                                                !!touched.ecs_production_type_annual_cost
                                            }
                                            className="form-control"
                                            placeholder="Entrer un coût annuel"
                                        />
                                        <ErrorMessage
                                            name="ecs_production_type_annual_cost"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="heading_type"
                                        className="col-form-label col-lg-2"
                                    >
                                        Type de production chauffage
                                    </Label>
                                    <Col lg="10">
                                        <Select
                                            className={`w-100 ${
                                                errors.heading_type ? "selectorError" : ""
                                            }`}
                                            isSearchable={true}
                                            noOptionsMessage={() =>
                                                "Pas de type de production chauffage disponible"
                                            }
                                            defaultValue={
                                                values.heading_type && values.heading_type !== ""
                                                    ? {
                                                        value: values.heading_type,
                                                        label: values.heading_type,
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir un type de production chauffage..."
                                            options={[
                                                {value: "Elec", label: "Elec"},
                                                {value: "Fioul", label: "Fioul"},
                                                {value: "Gaz", label: "Gaz"},
                                                {value: "Bois", label: "Bois"},
                                                {value: "Propane", label: "Propane"},
                                            ]}
                                            onChange={(heading_type) => {
                                                setFieldValue("heading_type", heading_type.value);
                                            }}
                                        />
                                        <ErrorMessage
                                            className={errors.heading_type ? "errorMessages" : null}
                                            name="heading_type"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="heading_type_annual_cost"
                                        className="col-form-label col-lg-2"
                                    >
                                        Coût annuel [chauffage]
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="heading_type_annual_cost"
                                            name="heading_type_annual_cost"
                                            type="text"
                                            tag={Field}
                                            invalid={
                                                errors.heading_type_annual_cost &&
                                                !!touched.heading_type_annual_cost
                                            }
                                            className="form-control"
                                            placeholder="Entrer un coût annuel"
                                        />
                                        <ErrorMessage
                                            name="heading_type_annual_cost"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="appointment_explication"
                                        className="col-form-label col-lg-2"
                                    >
                                        Commentaire
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="appointment_explication"
                                            name="appointment_explication"
                                            type="textarea"
                                            component="textarea"
                                            rows="5"
                                            tag={Field}
                                            invalid={
                                                errors.appointment_explication &&
                                                !!touched.appointment_explication
                                            }
                                            className="form-control"
                                            placeholder="Ajouter un commentaire"
                                        />
                                        <ErrorMessage
                                            name="appointment_explication"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <h5
                                className={`accordion-title${
                                    errorInForm(errors, touched, "section_3")
                                        ? " accordion-error"
                                        : ""
                                }`}
                                onClick={() => setSection_3(!section_3)}
                            >
                                <div className="d-flex align-items-center pt-3 pb-3">
                                    <i
                                        className={`bx bx-chevron-${
                                            section_3 ? "up" : "down"
                                        } mr-1`}
                                    ></i>{" "}
                                    Définition du projet potentiel
                                    {errorInForm(errors, touched, "section_3") ? (
                                        <i className="fas fa-times ml-3"></i>
                                    ) : null}
                                </div>
                                <hr className="m-0"/>
                            </h5>
                            <Collapse className="mt-4" isOpen={section_3}>
                                <FormGroup className="mb-4" row>
                                    <Col xs="auto">
                                        <Label htmlFor="already_benefit_grant">
                                            Le prospect a-t-il déjà bénéficier d'une subvention (CITE) ?
                                        </Label>
                                    </Col>
                                    <Col className="custom-control custom-switch">
                                        <Input
                                            id="already_benefit_grant"
                                            name="already_benefit_grant"
                                            type="checkbox"
                                            tag={Field}
                                            invalid={
                                                errors.already_benefit_grant &&
                                                !!touched.already_benefit_grant
                                            }
                                            className="custom-control-input ml-3"
                                            onChange={(already_benefit_grant) => {
                                                if (already_benefit_grant.target.checked) {
                                                    document.getElementById('building_work_type_form_group').classList.remove('d-none');
                                                } else {
                                                    document.getElementById('building_work_type_form_group').classList.add('d-none');
                                                }
                                                setFieldValue("already_benefit_grant", already_benefit_grant.target.checked);
                                            }}
                                        />
                                        <Label
                                            htmlFor="already_benefit_grant"
                                            className="custom-control-label"
                                        ></Label>
                                        <ErrorMessage
                                            name="already_benefit_grant"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup id="building_work_type_form_group"
                                           className={`mb-4 ${values.already_benefit_grant ? '' : 'd-none'}`} row>
                                    <Label
                                        htmlFor="building_work_type"
                                        className="col-form-label col-lg-2"
                                    >
                                        Type de travaux
                                    </Label>
                                    <Col lg="10">
                                        <CreatableSelect
                                            className={`w-100 ${
                                                errors.building_work_type ? "selectorError" : ""
                                            }`}
                                            isClearable={true}
                                            noOptionsMessage={() =>
                                                "Pas de type de travaux disponible"
                                            }
                                            defaultValue={
                                                values.building_work_type &&
                                                values.building_work_type !== ""
                                                    ? {
                                                        value: values.building_work_type,
                                                        label: values.building_work_type,
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir un type de travaux..."
                                            options={[
                                                {value: "Chauffage", label: "Chauffage"},
                                                {value: "Ecs", label: "Ecs"},
                                                {value: "Isolation", label: "Isolation"},
                                            ]}
                                            onChange={(building_work_type) => {
                                                setFieldValue(
                                                    "building_work_type",
                                                    building_work_type !== null
                                                        ? building_work_type.value
                                                        : ""
                                                );
                                            }}
                                            formatCreateLabel={(inputValue) =>
                                                `Choisir "${inputValue}"`
                                            }
                                        />
                                        <ErrorMessage
                                            className={
                                                errors.building_work_type ? "errorMessages" : null
                                            }
                                            name="building_work_type"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Col xs="auto">
                                        <Label htmlFor="already_benefit_energy_study">
                                            Le prospect a-t-il déjà profité d'une étude énergétique ?
                                        </Label>
                                    </Col>
                                    <Col className="custom-control custom-switch">
                                        <Input
                                            id="already_benefit_energy_study"
                                            name="already_benefit_energy_study"
                                            type="checkbox"
                                            tag={Field}
                                            invalid={
                                                errors.already_benefit_energy_study &&
                                                !!touched.already_benefit_energy_study
                                            }
                                            className="custom-control-input ml-3"
                                        />
                                        <Label
                                            htmlFor="already_benefit_energy_study"
                                            className="custom-control-label"
                                        ></Label>
                                        <ErrorMessage
                                            name="already_benefit_energy_study"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <h5
                                className={`accordion-title${
                                    errorInForm(errors, touched, "section_4")
                                        ? " accordion-error"
                                        : ""
                                }`}
                                onClick={() => setSection_4(!section_4)}
                            >
                                <div className="d-flex align-items-center pt-3 pb-3">
                                    <i
                                        className={`bx bx-chevron-${
                                            section_4 ? "up" : "down"
                                        } mr-1`}
                                    ></i>{" "}
                                    Rencontre du prospect
                                    {errorInForm(errors, touched, "section_4") ? (
                                        <i className="fas fa-times ml-3"></i>
                                    ) : null}
                                </div>
                                <hr className="m-0"/>
                            </h5>
                            <Collapse className="mt-4" isOpen={section_4}>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="first_contact_date"
                                        className="col-form-label col-lg-2"
                                    >
                                        Date de prise de RDV
                                    </Label>
                                    <Col lg="10">
                                        <DatePicker
                                            className="form-control button-without-style"
                                            selected={values.first_contact_date}
                                            onChange={(date) => {
                                                date.getHours() === 12
                                                    ? date.setHours(date.getHours() + 0)
                                                    : date.setHours(date.getHours() + 12);
                                                setFieldValue("first_contact_date", date);
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            locale={fr}
                                            placeholderText="Entrer la date de prise de RDV"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="contact_origin"
                                        className="col-form-label col-lg-2"
                                    >
                                        Origine
                                    </Label>
                                    <Col lg="10">
                                        <Select
                                            className={`w-100 ${
                                                errors.contact_origin ? "selectorError" : ""
                                            }`}
                                            isSearchable={true}
                                            noOptionsMessage={() => "Pas d'origine disponible"}
                                            defaultValue={
                                                values.contact_origin && values.contact_origin !== ""
                                                    ? {
                                                        value: values.contact_origin,
                                                        label: values.contact_origin,
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir une origine..."
                                            options={[
                                                {
                                                    value: "Téléphone",
                                                    label: "Téléphone",
                                                },
                                                {
                                                    value: "Porte à porte",
                                                    label: "Porte à porte",
                                                },
                                                {
                                                    value: "Marché",
                                                    label: "Marché",
                                                },
                                                {
                                                    value: "Visite d'installation",
                                                    label: "Visite d'installation",
                                                },
                                                {
                                                    value: "Galerie (GMS)",
                                                    label: "Galerie (GMS)",
                                                },
                                                {
                                                    value: "Foire expo",
                                                    label: "Foire expo",
                                                },
                                                {
                                                    value: "Site NWE",
                                                    label: "Site NWE",
                                                },
                                                {
                                                    value: "Mailing",
                                                    label: "Mailing",
                                                },
                                                {
                                                    value: "Recommandation",
                                                    label: "Recommandation",
                                                },
                                                {
                                                    value: "Ancien client",
                                                    label: "Ancien client",
                                                },
                                                {
                                                    value: "Contact entrant",
                                                    label: "Contact entrant",
                                                },
                                            ]}
                                            onChange={(contact_origin) => {
                                                if (contact_origin.value === "Galerie (GMS)" || contact_origin.value === "Foire expo") {
                                                    document.getElementById('exposed_city_form_group').classList.remove('d-none')
                                                } else {
                                                    document.getElementById('exposed_city_form_group').classList.add('d-none')
                                                }
                                                setFieldValue("contact_origin", contact_origin.value);
                                            }}
                                        />
                                        <ErrorMessage
                                            className={errors.contact_origin ? "errorMessages" : null}
                                            name="contact_origin"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup id="exposed_city_form_group"
                                           className={`mb-4 ${values.contact_origin.value !== "Galerie (GMS)" && values.contact_origin.value !== "Foire expo" ? 'd-none' : ''}`}
                                           row>
                                    <Label
                                        htmlFor="exposed_city"
                                        className="col-form-label col-lg-2"
                                    >
                                        Ville d'origine du contact
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="exposed_city"
                                            name="exposed_city"
                                            type="text"
                                            tag={Field}
                                            invalid={
                                                errors.exposed_city &&
                                                !!touched.exposed_city
                                            }
                                            className="form-control"
                                            placeholder="Entrer la ville d'origine du contact"
                                        />
                                        <ErrorMessage
                                            name="exposed_city"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="customer"
                                        className="col-form-label col-lg-2"
                                    >
                                        Parrain
                                    </Label>
                                    <Col lg="10">
                                        <div className="w-100 d-flex">
                                            {(!props.isUpdate || props.customerId) && <FormikAsyncAutocomplete
                                                name="customer"
                                                id="customer"
                                                value={values.customer}
                                                invalid={errors.customer && !!touched.customer}
                                                className={`selector-with-addon w-100 ${
                                                    errors.customer ? "selectorError" : null
                                                }`}
                                                defaultOptions={true}
                                                loadOptions={async (q) => {
                                                    return (await promiseCustomersOptions.bind({exclude: props.isUpdate ? props.customerId : undefined})(q))
                                                }}
                                                onChange={(customer) => {
                                                    setFieldValue("customer", customer);
                                                }}
                                            />}
                                        </div>
                                        <ErrorMessage
                                            className={errors.customer ? "errorMessages" : null}
                                            name="customer"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label htmlFor="user" className="col-form-label col-lg-2">
                                        RDV prit par
                                    </Label>
                                    <Col lg="10">
                                        <Select
                                            className={`w-100 ${errors.user ? "selectorError" : ""}`}
                                            isSearchable={true}
                                            noOptionsMessage={() => "Pas de collaborateur disponible"}
                                            defaultValue={
                                                values.user && values.user !== ""
                                                    ? {
                                                        value: values.user.id,
                                                        label: `${values.user.firstname} ${values.user.lastname}`,
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir un collaborateur..."
                                            options={
                                                props.users
                                                    ? props.users.map((user, index) => ({
                                                        value: user.id,
                                                        label: `${user.firstname} ${user.lastname}`,
                                                    }))
                                                    : []
                                            }
                                            onChange={(user) => {
                                                setFieldValue("user", user);
                                            }}
                                        />
                                        <ErrorMessage
                                            className={errors.user ? "errorMessages" : null}
                                            name="user"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <h5
                                className={`accordion-title${
                                    errorInForm(errors, touched, "section_5")
                                        ? " accordion-error"
                                        : ""
                                }`}
                                onClick={() => setSection_5(!section_5)}
                            >
                                <div className="d-flex align-items-center pt-3 pb-3">
                                    <i
                                        className={`bx bx-chevron-${
                                            section_5 ? "up" : "down"
                                        } mr-1`}
                                    ></i>{" "}
                                    Situation
                                    {errorInForm(errors, touched, "section_5") ? (
                                        <i className="fas fa-times ml-3"></i>
                                    ) : null}
                                </div>
                                <hr className="m-0"/>
                            </h5>
                            <Collapse className="mt-4" isOpen={section_5}>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="function1"
                                        className="col-form-label col-lg-2"
                                    >
                                        Profession
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="function1"
                                            name="function1"
                                            type="text"
                                            tag={Field}
                                            invalid={errors.function1 && !!touched.function1}
                                            className="form-control"
                                            placeholder="Entrer une profession"
                                        />
                                        <ErrorMessage name="function1" component={FormFeedback}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label htmlFor="age" className="col-form-label col-lg-2">
                                        Âge
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="age"
                                            name="age"
                                            type="text"
                                            tag={Field}
                                            invalid={errors.age && !!touched.age}
                                            className="form-control"
                                            placeholder="Entrer l'âge du client"
                                        />
                                        <ErrorMessage name="age" component={FormFeedback}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="function2"
                                        className="col-form-label col-lg-2"
                                    >
                                        Profession conjoint
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="function2"
                                            name="function2"
                                            type="text"
                                            tag={Field}
                                            invalid={errors.function2 && !!touched.function2}
                                            className="form-control"
                                            placeholder="Entrer une profession"
                                        />
                                        <ErrorMessage name="function2" component={FormFeedback}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="spouse_age"
                                        className="col-form-label col-lg-2"
                                    >
                                        Âge conjoint
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="spouse_age"
                                            name="spouse_age"
                                            type="text"
                                            tag={Field}
                                            invalid={errors.spouse_age && !!touched.spouse_age}
                                            className="form-control"
                                            placeholder="Entrer l'âge du conjoint"
                                        />
                                        <ErrorMessage name="spouse_age" component={FormFeedback}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="children_number"
                                        className="col-form-label col-lg-2"
                                    >
                                        Nombre d'enfants
                                    </Label>
                                    <Col lg="10">
                                        <Select
                                            className={`w-100 ${
                                                errors.children_number ? "selectorError" : ""
                                            }`}
                                            isSearchable={true}
                                            noOptionsMessage={() =>
                                                "Pas d'élément disponible"
                                            }
                                            defaultValue={
                                                values.children_number && values.children_number !== ""
                                                    ? {
                                                        value: values.children_number,
                                                        label: values.children_number,
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir le nombre d'enfants..."
                                            options={[
                                                {value: 0, label: "0"},
                                                {value: 1, label: "1"},
                                                {value: 2, label: "2"},
                                                {value: 3, label: "3"},
                                                {value: 4, label: "4"},
                                                {value: 5, label: "5"},
                                                {value: 6, label: "6"},
                                            ]}
                                            onChange={(children_number) => {
                                                setFieldValue("children_number", children_number.value);
                                            }}
                                        />
                                        <ErrorMessage
                                            name="children_number"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="fiscal_income"
                                        className="col-form-label col-lg-2"
                                    >
                                        Revenu fiscal
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="fiscal_income"
                                            name="fiscal_income"
                                            type="text"
                                            tag={Field}
                                            invalid={errors.fiscal_income && !!touched.fiscal_income}
                                            className="form-control"
                                            placeholder="Entrer un revenu fiscal"
                                        />
                                        <ErrorMessage
                                            name="fiscal_income"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <h5
                                className={`accordion-title${
                                    errorInForm(errors, touched, "section_6")
                                        ? " accordion-error"
                                        : ""
                                }`}
                                onClick={() => setSection_6(!section_6)}
                            >
                                <div className="d-flex align-items-center pt-3 pb-3">
                                    <i
                                        className={`bx bx-chevron-${
                                            section_6 ? "up" : "down"
                                        } mr-1`}
                                    ></i>{" "}
                                    Positionnement du rendez-vous
                                    {errorInForm(errors, touched, "section_6") ? (
                                        <i className="fas fa-times ml-3"></i>
                                    ) : null}
                                </div>
                                <hr className="m-0"/>
                            </h5>
                            <Collapse className="mt-4" isOpen={section_6}>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="appointment_take_with"
                                        className="col-form-label col-lg-2"
                                    >
                                        Rendez-vous pris avec
                                    </Label>
                                    <Col lg="10">
                                        <Select
                                            className={`w-100 ${
                                                errors.appointment_take_with ? "selectorError" : ""
                                            }`}
                                            isSearchable={true}
                                            noOptionsMessage={() => "Pas de choix disponible"}
                                            defaultValue={
                                                values.appointment_take_with &&
                                                values.appointment_take_with !== ""
                                                    ? {
                                                        value: values.appointment_take_with,
                                                        label: values.appointment_take_with,
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir dans la liste..."
                                            options={[
                                                {value: "Madame", label: "Madame"},
                                                {value: "Monsieur", label: "Monsieur"},
                                                {value: "Les deux", label: "Les deux"},
                                            ]}
                                            onChange={(appointment_take_with) => {
                                                setFieldValue(
                                                    "appointment_take_with",
                                                    appointment_take_with.value
                                                );
                                            }}
                                        />
                                        <ErrorMessage
                                            className={
                                                errors.appointment_take_with ? "errorMessages" : null
                                            }
                                            name="appointment_take_with"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Col xs="auto">
                                        <Label htmlFor="decision_makers_present">
                                            Les décideurs étaient-ils présents ?
                                        </Label>
                                    </Col>
                                    <Col className="custom-control custom-switch">
                                        <Input
                                            id="decision_makers_present"
                                            name="decision_makers_present"
                                            type="checkbox"
                                            tag={Field}
                                            invalid={
                                                errors.decision_makers_present &&
                                                !!touched.decision_makers_present
                                            }
                                            className="custom-control-input ml-3"
                                        />
                                        <Label
                                            htmlFor="decision_makers_present"
                                            className="custom-control-label"
                                        ></Label>
                                        <ErrorMessage
                                            name="decision_makers_present"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Col xs="auto">
                                        <Label htmlFor="ok_one_hours_thirty">
                                            La durée d'1H30, OK ?
                                        </Label>
                                    </Col>
                                    <Col className="custom-control custom-switch">
                                        <Input
                                            id="ok_one_hours_thirty"
                                            name="ok_one_hours_thirty"
                                            type="checkbox"
                                            tag={Field}
                                            invalid={
                                                errors.ok_one_hours_thirty &&
                                                !!touched.ok_one_hours_thirty
                                            }
                                            className="custom-control-input ml-3"
                                        />
                                        <Label
                                            htmlFor="ok_one_hours_thirty"
                                            className="custom-control-label"
                                        ></Label>
                                        <ErrorMessage
                                            name="ok_one_hours_thirty"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="appointment_method"
                                        className="col-form-label col-lg-2"
                                    >
                                        Plan d'accès ou commentaires divers
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="appointment_method"
                                            name="appointment_method"
                                            type="text"
                                            tag={Field}
                                            invalid={
                                                errors.appointment_method &&
                                                !!touched.appointment_method
                                            }
                                            className="form-control"
                                            placeholder="Entrer un commentaire"
                                        />
                                        <ErrorMessage
                                            name="appointment_method"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <h5
                                className={`accordion-title${
                                    errorInForm(errors, touched, "section_7")
                                        ? " accordion-error"
                                        : ""
                                }`}
                                onClick={() => setSection_7(!section_7)}
                            >
                                <div className="d-flex align-items-center pt-3 pb-3">
                                    <i
                                        className={`bx bx-chevron-${
                                            section_7 ? "up" : "down"
                                        } mr-1`}
                                    ></i>{" "}
                                    Le contact - suivi du rendez-vous
                                    {errorInForm(errors, touched, "section_7") ? (
                                        <i className="fas fa-times ml-3"></i>
                                    ) : null}
                                </div>
                                <hr className="m-0"/>
                            </h5>
                            <Collapse className="mt-4" isOpen={section_7}>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="follow_up_call_date"
                                        className="col-form-label col-lg-2"
                                    >
                                        Date de rappel
                                    </Label>
                                    <Col lg="10">
                                        <DatePicker
                                            className="form-control button-without-style"
                                            selected={values.follow_up_call_date}
                                            onChange={(date) => {
                                                setFieldValue("follow_up_call_date", date);
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            locale={fr}
                                            placeholderText="Entrer la date de rappel du client"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="follow_up_client_profil"
                                        className="col-form-label col-lg-2"
                                    >
                                        Profil du prospect
                                    </Label>
                                    <Col lg="10">
                                        <Select
                                            className={`w-100 ${
                                                errors.follow_up_client_profil ? "selectorError" : ""
                                            }`}
                                            isSearchable={true}
                                            noOptionsMessage={() => "Pas de choix disponible"}
                                            defaultValue={
                                                values.follow_up_client_profil &&
                                                values.follow_up_client_profil !== ""
                                                    ? {
                                                        value: values.follow_up_client_profil,
                                                        label: values.follow_up_client_profil,
                                                    }
                                                    : null
                                            }
                                            placeholder="Choisir le profil du prospect..."
                                            options={[
                                                {value: "Sympathique", label: "Sympathique"},
                                                {value: "Réceptif", label: "Réceptif"},
                                                {value: "Méfiant", label: "Méfiant"},
                                            ]}
                                            onChange={(follow_up_client_profil) => {
                                                setFieldValue(
                                                    "follow_up_client_profil",
                                                    follow_up_client_profil.value
                                                );
                                            }}
                                        />
                                        <ErrorMessage
                                            className={
                                                errors.follow_up_client_profil ? "errorMessages" : null
                                            }
                                            name="follow_up_client_profil"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="follow_up_refusal_reason"
                                        className="col-form-label col-lg-2"
                                    >
                                        Motif de refus
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                            id="follow_up_refusal_reason"
                                            name="follow_up_refusal_reason"
                                            type="text"
                                            tag={Field}
                                            invalid={
                                                errors.follow_up_refusal_reason &&
                                                !!touched.follow_up_refusal_reason
                                            }
                                            className="form-control"
                                            placeholder="Entrer un motif de refus"
                                        />
                                        <ErrorMessage
                                            name="follow_up_refusal_reason"
                                            component={FormFeedback}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="follow_up_number_star"
                                        className="col-form-label col-lg-2"
                                    >
                                        Qualité du rendez-vous
                                    </Label>
                                    <Col lg="10" className="ratings">
                    <span>
                      <i
                          className={`${
                              values.follow_up_number_star < 1 ? "empty " : ""
                          }fas fa-star mr-3`}
                          onClick={() =>
                              setFieldValue("follow_up_number_star", 1)
                          }
                      ></i>
                    </span>
                                        <span>
                      <i
                          className={`${
                              values.follow_up_number_star < 2 ? "empty " : ""
                          }fas fa-star mr-3`}
                          onClick={(e) =>
                              setFieldValue("follow_up_number_star", 2)
                          }
                      ></i>
                    </span>
                                        <span>
                      <i
                          className={`${
                              values.follow_up_number_star < 3 ? "empty " : ""
                          }fas fa-star mr-3`}
                          onClick={() =>
                              setFieldValue("follow_up_number_star", 3)
                          }
                      ></i>
                    </span>
                                        <span>
                      <i
                          className={`${
                              values.follow_up_number_star < 4 ? "empty " : ""
                          }fas fa-star mr-3`}
                          onClick={() =>
                              setFieldValue("follow_up_number_star", 4)
                          }
                      ></i>
                    </span>
                                        <span>
                      <i
                          className={`${
                              values.follow_up_number_star < 5 ? "empty " : ""
                          }fas fa-star`}
                          onClick={() =>
                              setFieldValue("follow_up_number_star", 5)
                          }
                      ></i>
                    </span>
                                    </Col>
                                </FormGroup>
                            </Collapse>
                        </div>
                    </div>
                    <Row className="justify-content-end mt-4">
                        <Col lg="10">
                            <Row>
                                <Col lg="2">
                                    <Button type="submit" color="primary">
                                        {props.isUpdate
                                            ? "Modifier le prospect/client"
                                            : "Ajouter le prospect"}
                                    </Button>
                                </Col>
                                <Col lg="10">
                                    {errorInForm(errors, touched, "section_1") ||
                                    errorInForm(errors, touched, "section_2") ||
                                    errorInForm(errors, touched, "section_3") ||
                                    errorInForm(errors, touched, "section_4") ||
                                    errorInForm(errors, touched, "section_5") ||
                                    errorInForm(errors, touched, "section_6") ||
                                    errorInForm(errors, touched, "section_7") ? (
                                        <Alert color="danger">
                                            Vous avez des erreurs dans le formulaire
                                        </Alert>
                                    ) : null}
                                    {props.error && props.error ? (
                                        <Alert color="danger" className="d-inline-block">
                                            {props.error}
                                        </Alert>
                                    ) : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            )}
        />
    );
};

export default CustomerForm;
