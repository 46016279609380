import { GET_VATS, GET_VATS_SUCCESS, VATS_API_ERROR } from "./actionTypes";

const initialState = {
  vats: [],
  error: false,
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const vats = (state = initialState, action) => {
  switch (action.type) {
    case GET_VATS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_VATS_SUCCESS:
      state = {
        ...state,
        vats: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        loading: false,
        error: false,
      };
      break;
    case VATS_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default vats;
