import React, { useState } from 'react';
import { parseDate } from "../../../helpers/subscriptions_helpers";
import { Badge, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Link, withRouter } from "react-router-dom";
import PaymentModal from "../Modals/PaymentModal";

// style
import '../invoicesDetails.scss';
import ValidationModal from "../../Quotes/Modals/ValidationModal";
import { payments } from "../../../api";
import { connect } from "react-redux";
import { getPaymentsDetails } from "../../../store/invoices/actions";

const PaymentTableComponent = ({
  invoice,
  getPaymentsDetails
}) => {

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentToModify, setPaymentToModify] = useState(null);
  const [toDeletePayment, setToDeletePayment] = useState(false);

  const handlePaymentDelete = (id) => {
    payments.delete(id)
      .then(_ => {
        getPaymentsDetails(invoice.id);
        setToDeletePayment(false);
      })
  }

  const total_ttc = invoice.total_ttc - invoice.total_price_deposits_ttc + invoice.order.cce_prime + invoice.order.other_prime_amount / 100.0;
  const isTotalPaid = total_ttc <= invoice.paid_total_ttc

  const columns = [
    {
      dataField: 'paid_at',
      text: 'Date',
      formatter: cell => parseDate(cell),
    },
    {
      dataField: 'amount',
      text: 'Montant',
      formatter: cell => <div>{parseFloat(cell).toFixed(2)} €</div>
    },
    {
      dataField: 'payment_type',
      text: 'Moyen paiement',
      formatter: cell => cell.charAt(0).toUpperCase() + cell.slice(1)
    },
    {
      dataField: 'bank_label',
      text: 'Banque',
    },
    {
      dataField: 'date_remise_bank_check_to_bank',
      text: 'Date d\'encaissement',
      formatter: cell => parseDate(cell),
    },
    {
      dataField: 'reference',
      text: 'Référence'
    },
    {
      dataField: 'state',
      text: 'État',
      formatter: cell => <Badge pill color={cell === 'reçu' ? 'warning' : 'success'}>{cell}</Badge>
    },
    {
      dataField: 'comment',
      text: 'Commentaire'
    },
    {
      dataField: 'actions',
      text: '',
      formatter: (_, row) => (
        <div className='actions'>
          <Link
            to={'#'}
            onClick={() => {
              setPaymentToModify(row);
              setIsPaymentModalOpen(true);
            }}
          >
            <i className="bx bx-pencil font-size-17" />
          </Link>
          <Link
            to={'#'}
            data-toggle="tooltip"
            data-placement="top"
            title="Supprimer le paiement"
            onClick={() => setToDeletePayment(row.id)}
          >
            <i className="btn-icon-delete fas fa-trash-alt" />
          </Link>
        </div>
      ),
    }
  ];

  return (
    <Card>
      <CardBody>
        <CardTitle className='mb-4'>
          <Row className="pr-2 pl-2 d-flex justify-content-between">
            <Col>Paiements</Col>
            <Col className="d-flex justify-content-end">
              {!isTotalPaid ?
                <Link
                  to="#"
                  className="d-flex align-items-center text-theme-color-2"
                  onClick={() => setIsPaymentModalOpen(true)}
                >
                  <i className="bx bx-plus-circle mr-1>" /> Ajouter
                </Link> :
                <span
                  className="d-flex align-items-center text-theme-color-2"
                >
                  Tout à été payé
                </span>}
            </Col>
          </Row>
        </CardTitle>
        <BootstrapTable
          keyField='id'
          data={invoice.payments}
          columns={columns}
          classes='payments-table'
        />
      </CardBody>

      <PaymentModal
        isOpen={isPaymentModalOpen}
        toggle={() => {
          setIsPaymentModalOpen(!isPaymentModalOpen);
          setPaymentToModify(null);
        }}
        thenExecutedFunction={() => getPaymentsDetails(invoice.id)}
        invoice={invoice}
        payment={paymentToModify}
      />

      <ValidationModal
        isOpen={toDeletePayment}
        toggler={() => setToDeletePayment(!toDeletePayment)}
        title='Paiement - suppression'
        message={<React.Fragment><h1>Attention</h1><p>Voulez-vous supprimer ce paiement ?</p></React.Fragment>}
        btnValidText='Supprimer'
        btnBackText='Annuler'
        handleSubmit={() => handlePaymentDelete(toDeletePayment)}
      />
    </Card>
  );
}

export default withRouter(
  connect(null, {
    getPaymentsDetails,
  })(PaymentTableComponent)
);
