import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS, // Send reset email
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS, // Change password from code
  FORGET_PASSWORD_ERROR
} from "./actionTypes";

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history }
  };
};

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message
  };
};

export const userResetPassword = (code, password, password_confirmation, history) => {
  return {
    type: RESET_PASSWORD,
    payload: { code, password, password_confirmation, history }
  };
};

export const userResetPasswordSuccess = message => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: message
  };
};

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message
  };
};
