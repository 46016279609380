import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_USERS,
  GET_USER,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
} from "./actionTypes";
import {
  getUsersSuccess,
  getUserSuccess,
  createUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
  userApiError,
} from "./actions";

import { users } from "../../api";

function* getUsers({ payload: { query } }) {
  try {
    const response = yield call(users.getAll, query);
    yield put(getUsersSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        userApiError(
          "Vous n'êtes pas autorisé à accéder à la liste des collaborateurs"
        )
      );
    } else if (error.response.data !== undefined) {
      yield put(userApiError(error.response.data.message));
    } else {
      yield put(userApiError(error.message));
    }
  }
}

function* getUser({ payload: { id } }) {
  try {
    const response = yield call(users.getOne, id);
    yield put(getUserSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        userApiError(
          "Vous n'êtes pas autorisé à accéder aux informations de ce collaborateur"
        )
      );
    } else if (error.response.status === 404) {
      yield put(userApiError("Le collaborateur n'a pas été trouvé"));
    } else if (error.response.data !== undefined) {
      yield put(userApiError(error.response.data.message));
    } else {
      yield put(userApiError(error.message));
    }
  }
}

function* createUser({ payload: { user, history } }) {
  try {
    const response = yield call(users.create, user);
    yield put(createUserSuccess(response));
    history.push("/collaborators?success_add=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        userApiError("Vous n'êtes pas autorisé à ajouter un collaborateur")
      );
    } else if (
      error.response.status === 400 &&
      error.response.data.errors.length > 0
    ) {
        yield put(userApiError(error.response.data.errors[0].detail));
    } else if (error.response.data !== undefined) {
      yield put(userApiError(error.response.data.message));
    } else {
      yield put(userApiError(error.message));
    }
  }
}

function* updateUser({ payload: { id, user, history } }) {
  try {
    if (user.password === "" && user.password_confirmation === "") {
      const response = yield call(users.updateWithoutPassword, id, user);
      yield put(updateUserSuccess(response));
    } else {
      const response = yield call(users.update, id, user);
      yield put(updateUserSuccess(response));
    }
    history.push("/collaborators?success_update=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        userApiError("Vous n'êtes pas autorisé à ajouter un collaborateur")
      );
    } else if (error.response.status === 404) {
      yield put(userApiError("Le collaborateur n'a pas été trouvé"));
    } else if (
      error.response.status === 400 &&
      error.response.data.errors.length > 0
    ) {
      if (
        error.response.data.errors[0].detail.email &&
        error.response.data.errors[0].detail.email[0] ===
        "has already been taken"
      ) {
        yield put(userApiError("L'adresse email est déjà utilisée"));
      } else if (
        error.response.data.errors[0].detail.email &&
        error.response.data.errors[0].detail.email[0] === "is invalid"
      ) {
        yield put(userApiError("L'adresse email n'est pas valide"));
      } else if (
        error.response.data.errors[0].detail.password &&
        error.response.data.errors[0].detail.password[0] === "can't be blank"
      ) {
        yield put(userApiError("Le mot de passe doit être présent"));
      } else if (
        error.response.data.errors[0].detail.password &&
        error.response.data.errors[0].detail.password[0] ===
        "is too short (minimum is 6 characters)"
      ) {
        yield put(
          userApiError("Le mot de passe doit contenir au moins 6 caractères")
        );
      } else if (
        error.response.data.errors[0].detail.role &&
        error.response.data.errors[0].detail.role[0] === "must exist"
      ) {
        yield put(userApiError("Le rôle du collaborateur doit exister"));
      } else if (
        error.response.data.errors[0].detail.agencies &&
        error.response.data.errors[0].detail.agencies[0] === "must exist"
      ) {
        yield put(userApiError("Les agences du collaborateur doivent exister"));
      }
    } else if (error.response.data !== undefined) {
      yield put(userApiError(error.response.data.message));
    } else {
      yield put(userApiError(error.message));
    }
  }
}

function* deleteUser({ payload: { id, history } }) {
  try {
    const response = yield call(users.delete, id);
    yield put(deleteUserSuccess(response));
    history.push("/collaborators?success_delete=1");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        userApiError("Vous n'êtes pas autorisé à supprimer ce collaborateur")
      );
    } else if (error.response.data !== undefined) {
      yield put(userApiError(error.response.data.message));
    } else {
      yield put(userApiError(error.message));
    }
  }
}

export function* watchGetUsers() {
  yield takeEvery(GET_USERS, getUsers);
}

export function* watchGetUser() {
  yield takeEvery(GET_USER, getUser);
}

export function* watchCreateUser() {
  yield takeEvery(CREATE_USER, createUser);
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser);
}

function* usersSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchGetUser),
    fork(watchCreateUser),
    fork(watchUpdateUser),
    fork(watchDeleteUser),
  ]);
}

export default usersSaga;
