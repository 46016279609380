import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_FREE_ITEMS_SAVE,
  CREATE_FREE_ITEM_SAVE,
  DELETE_FREE_FIELD,
} from "./actionTypes";
import {
  getFreeItemsSaveSuccess,
  createFreeItemSaveSuccess,
  deleteFreeFieldSuccess,
  freeFieldsApiError,
} from "./actions";

import { free_fields, free_items_save } from "../../api";

function* getFreeItemsSave({ payload: { query } }) {
  try {
    const response = yield call(free_items_save.getAll, query);
    yield put(getFreeItemsSaveSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        freeFieldsApiError(
          "Vous n'êtes pas autorisé à accéder à la liste des articles sauvegardés"
        )
      );
    } else if (error.response.data !== undefined) {
      yield put(freeFieldsApiError(error.response.data.message));
    } else {
      yield put(freeFieldsApiError(error.message));
    }
  }
}

function* createFreeItemSave({ payload: { freeItem } }) {
  try {
    const response = yield call(free_items_save.create, freeItem);
    yield put(createFreeItemSaveSuccess(response));
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield put(
        freeFieldsApiError("Vous n'êtes pas autorisé à ajouter un article")
      );
    } else if (error.response && error.response.data !== undefined) {
      yield put(freeFieldsApiError(error.response.data.message));
    } else {
      yield put(freeFieldsApiError(error.message));
    }
  }
}

function* deleteFreeField({ payload: { id, history } }) {
  try {
    const response = yield call(free_fields.delete, id);
    yield put(deleteFreeFieldSuccess(response));
    history.push("/orders");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        freeFieldsApiError(
          "Vous n'êtes pas autorisé à supprimer ce champ libre"
        )
      );
    } else if (error.response.data !== undefined) {
      yield put(freeFieldsApiError(error.response.data.message));
    } else {
      yield put(freeFieldsApiError(error.message));
    }
  }
}

export function* watchGetFreeItemsSave() {
  yield takeEvery(GET_FREE_ITEMS_SAVE, getFreeItemsSave);
}

export function* watchCreateFreeItemSave() {
  yield takeEvery(CREATE_FREE_ITEM_SAVE, createFreeItemSave);
}

export function* watchDeleteFreeField() {
  yield takeEvery(DELETE_FREE_FIELD, deleteFreeField);
}

function* freeFieldsSaga() {
  yield all([fork(watchGetFreeItemsSave)]);
  yield all([fork(watchCreateFreeItemSave)]);
  yield all([fork(watchDeleteFreeField)]);
}

export default freeFieldsSaga;
