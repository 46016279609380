import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import CollaboratorForm from "./FormComponent/CollaboratorForm";

// Validation Schema
import { createValidationSchema } from "./FormValidationSchemas/createValidationSchema";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {
  createUser,
  userApiError,
  getRoles,
  roleApiError,
  getAgencies,
  agencyApiError,
} from "../../store/actions";

import { isRH } from "../../helpers/roles_helper";

const CollaboratorAdd = (props) => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    props.getRoles.call();
  }, [props.getRoles]);

  useEffect(() => {
    props.getAgencies.call();
  }, [props.getAgencies]);

  useEffect(() => {
    let temp = [];

    props.roles.forEach((role) => {
      if (role.name === "Unova" || (isRH() && role.name === "Dirigeant")) {
      } else {
        temp.push({
          label: role.name,
          value: role.id,
        });
      }
    });

    setRoles(temp);
  }, [props.roles]);

  const initValues = {
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    password_confirmation: "",
    role: "",
    entryDate: "",
    birthDate: "",
    agencies: [],
    phone: "",
    address: "",
    contractType: "",
    comment: "",
    agenda_item_card_color: "#5fbedc",
  };

  const getContractType = () => {
    return [
      { value: "CDI", label: "CDI" },
      { value: "CDD", label: "CDD" },
      { value: "Stagiaire", label: "Stagiaire" },
      { value: "Alternant", label: "Alternant" },
      { value: "Autre", label: "Autre" },
    ];
  };

  const handleSubmit = (values) => {
    props.createUser(
      {
        email: values.email,
        firstname: values.firstname,
        lastname: values.lastname,
        password: values.password,
        password_confirmation: values.password_confirmation,
        role: values.role,
        entryDate: values.entryDate === "" ? new Date() : values.entryDate,
        birthDate: values.birthDate === "" ? new Date() : values.birthDate,
        agencies: values.agencies,
        phone: values.phone,
        address: values.address,
        contractType: values.contractType,
        comment: values.comment,
        agenda_item_card_color: values.agenda_item_card_color,
      },
      props.history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs
          title="Collaborateurs"
          breadcrumbItem="Ajouter un collaborateur"
        />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Ajouter un collaborateur</CardTitle>
                <CollaboratorForm
                  initValues={initValues}
                  validationSchema={createValidationSchema}
                  handleSubmit={handleSubmit}
                  getContractType={getContractType}
                  error={props.error}
                  roles={roles}
                  agencies={props.agencies}
                  isUpdate={false}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { userLoading, error } = state.Users;
  const { roles } = state.Roles;
  const { agencies } = state.Agencies;
  return { userLoading, error, roles, agencies };
};

export default withRouter(
  connect(mapStatetoProps, {
    createUser,
    userApiError,
    getRoles,
    roleApiError,
    getAgencies,
    agencyApiError,
  })(CollaboratorAdd)
);
