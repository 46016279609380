import * as Yup from "yup";

const msgNumber = "Le prix doit être un nombre";

export const listFilterValidationSchema = Yup.object().shape({

  price_ht_min: Yup.number()
    .typeError(msgNumber)
    .when("price_ht_max", {
      is: null,
      otherwise: Yup.number().lessThan(Yup.ref("price_ht_max"), "Le prix minimal HT doit être inférieur au prix maximal HT"),
    }),

  price_ht_max: Yup.number()
    .typeError(msgNumber),

  price_ttc_min: Yup.number()
    .typeError(msgNumber)
    .when("price_ttc_max", {
      is: null,
      otherwise: Yup.number().lessThan(Yup.ref("price_ttc_max"), "Le prix minimal TTC doit être inférieur au prix maximal TTC"),
    }),

  price_ttc_max: Yup.number()
    .typeError(msgNumber),

  number: Yup.number().typeError(msgNumber),

  start_date: Yup.date()
    .when("end_date", {
      is: null,
      otherwise: Yup.date().max(Yup.ref("end_date"), "La date de début doit être inférieur à la date de départ")
    }),
  end_date: Yup.date(),
}, ['price_ht_min', 'price_ht_max', 'price_ttc_min', 'price_ttc_max']);
