import React from "react";
import { Redirect } from "react-router-dom";

//role helper
import { isDR, isDA, isRH, isSalesAdmin } from "../helpers/roles_helper";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

// //Projects
import ProjectsGrid from "../pages/Projects/projects-grid";
import ProjectsList from "../pages/Projects/projects-list";
import ProjectsOverview from "../pages/Projects/projects-overview";

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list";
import InvoiceDetail from "../pages/Invoices/invoices-detail";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPwd from "../pages/Authentication/ResetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//Collaborators
import CollaboratorsList from "../pages/Collaborators/collaborators-list";
import CollaboratorsDetail from "../pages/Collaborators/collaborators-detail";
import CollaboratorAdd from "../pages/Collaborators/collaborator-add";
import CollaboratorEdit from "../pages/Collaborators/collaborator-edit";

//Customers
import CustomersList from "../pages/Customers/customers-list";
import CustomersDetail from "../pages/Customers/customers-detail";
import CustomerAdd from "../pages/Customers/customer-add";
import CustomerEdit from "../pages/Customers/customer-edit";

//Orders
import OrderAdd from "../pages/Orders/order-add";
import OrderEdit from "../pages/Orders/order-edit";

// Agencies
import AgenciesList from "../pages/Agencies/agencies-list";
import AgencyAdd from "../pages/Agencies/agency-add";
import AgencyEdit from "../pages/Agencies/agency-edit";

// Financers
import FinancersList from "../pages/Financers/financers-list";
import FinancersDetail from "../pages/Financers/financers-detail";
import FinancerAdd from "../pages/Financers/financer-add";
import FinancerEdit from "../pages/Financers/financer-edit";

// Scales
import ScalesDetail from "../pages/Scales/scales-detail";
import ScaleAdd from "../pages/Scales/scale-add";
import ScaleEdit from "../pages/Scales/scale-edit";

// Offers
import OffersList from "../pages/Offers/offers-list";
import OffersDetail from "../pages/Offers/offers-detail";
import OfferUpsert from "../pages/Offers/offer-upsert";

// Products
import ProductsList from "../pages/Products/products-list";
import ProductsDetail from "../pages/Products/products-detail";
import ProductAdd from "../pages/Products/product-add";
import ProductEdit from "../pages/Products/product-edit";

// Quotes
import QuotesList from "../pages/Quotes/quotes-list";
import QuoteAdd from "../pages/Quotes/quote-add";
import QuoteEdit from "../pages/Quotes/quote-edit";

//Check deposit
import CheckDepositList from "../pages/Check-deposit/check-deposit-list"
import CheckDepositAdd from "../pages/Check-deposit/check-deposit-add"
import CheckDepositDetail from "../pages/Check-deposit/check-deposit-detail"

// Refunds
import RefundAdd from "../pages/Refunds/refund-add";
import RefundEdit from "../pages/Refunds/refund-edit";

// Requests
import RequestsList from "../pages/Requests/requests-list";
import RequestsAdd from "../pages/Requests/requests-add";
import RequestCustomerDetails from "../pages/Requests/Customers/customer-details";
import RequestCustomerInterventionAdd from "../pages/Requests/Customers/Interventions/intervention-form";
import RequestCustomerRequestForm from "../pages/Requests/Customers/Requests/request-form";
import RequestDetails from "../pages/Requests/request-details";
import RequestCustomerInterventionForm from "../pages/Requests/Customers/Interventions/intervention-form";

//Interventions
import InterventionList from "../pages/Interventions/intervention-list";
import InterventionFolderDetails from "../pages/Interventions/intervention-detail";

// Map
import MapContainer from "../pages/Map/index";

// NOTIFICATIONS
import NotificationsList from "../pages/Projects/Notifications/notifications-list";
import NotificationDetail from "../pages/Projects/Notifications/notification-detail";
import NotificationAdd from "../pages/Projects/Notifications/notification-add";
import NotificationEdit from "../pages/Projects/Notifications/notification-edit";

// Formulas
import FormulasList from '../pages/Formulas/formulas-list';
import FormulasAdd from "../pages/Formulas/formulas-add";
import FormulasUpdate from "../pages/Formulas/formulas-update";

// Subscriptions
import SubscriptionsList from "../pages/Subscriptions/subscriptions-list";
import SubscriptionsAdd from "../pages/Subscriptions/subscriptions-add";
import SubscriptionsDetail from "../pages/Subscriptions/subscriptions-detail";
import SubscriptionsUpdate from "../pages/Subscriptions/subscriptions-update";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  //calendar
  { path: "/calendar", component: Calendar },

  //Invoices
  {
    path: "/invoices",
    component: isRH() ? () => <Redirect to="/dashboard" /> : InvoicesList,
  },
  {
    path: "/invoice/:id/detail",
    component: isRH() ? () => <Redirect to="/dashboard" /> : InvoiceDetail,
  },

  //Projects
  {
    path: "/projects-grid",
    component: isRH() ? () => <Redirect to="/dashboard" /> : ProjectsGrid,
  },
  {
    path: "/projects-list",
    component: isRH() ? () => <Redirect to="/dashboard" /> : ProjectsList,
  },
  {
    path: "/projects-overview/:id",
    component: isRH()
      ? () => <Redirect to="/dashboard" />
      : ProjectsOverview,
  },

  //Collaborators
  {
    path: "/collaborators",
    component: isSalesAdmin()
      ? () => <Redirect to="/dashboard" />
      : CollaboratorsList,
  },
  {
    path: "/collaborator/detail/:id",
    component: isSalesAdmin()
      ? () => <Redirect to="/dashboard" />
      : CollaboratorsDetail,
  },
  {
    path: "/collaborator/add",
    component: isSalesAdmin()
      ? () => <Redirect to="/dashboard" />
      : CollaboratorAdd,
  },
  {
    path: "/collaborator/edit/:id",
    component: isSalesAdmin()
      ? () => <Redirect to="/dashboard" />
      : CollaboratorEdit,
  },

  //Customers
  {
    path: "/customers",
    component: isRH() ? () => <Redirect to="/dashboard" /> : CustomersList,
  },
  {
    path: "/customer/detail/:id",
    component: isRH() ? () => <Redirect to="/dashboard" /> : CustomersDetail,
  },
  {
    path: "/customer/add",
    component: isRH() ? () => <Redirect to="/dashboard" /> : CustomerAdd,
  },
  {
    path: "/customer/edit/:id",
    component: isRH() ? () => <Redirect to="/dashboard" /> : CustomerEdit,
  },

  //Orders
  {
    path: "/order/add",
    component: isRH() ? () => <Redirect to="/dashboard" /> : OrderAdd,
  },
  {
    path: "/order/edit/:order_id/:invoice_id",
    component: isRH() ? () => <Redirect to="/dashboard" /> : OrderEdit,
  },

  //Agencies
  {
    path: "/agencies",
    component: isSalesAdmin()
      ? () => <Redirect to="/dashboard" />
      : AgenciesList,
  },
  {
    path: "/agency/add",
    component: isSalesAdmin() ? () => <Redirect to="/dashboard" /> : AgencyAdd,
  },
  {
    path: "/agency/edit/:id",
    component: isSalesAdmin() ? () => <Redirect to="/dashboard" /> : AgencyEdit,
  },

  //Financers
  {
    path: "/financers",
    component: isRH() ? () => <Redirect to="/dashboard" /> : FinancersList,
  },
  {
    path: "/financer/detail/:id",
    component: isRH() ? () => <Redirect to="/dashboard" /> : FinancersDetail,
  },
  {
    path: "/financer/add",
    component: isRH() ? () => <Redirect to="/dashboard" /> : FinancerAdd,
  },
  {
    path: "/financer/edit/:id",
    component: isRH() ? () => <Redirect to="/dashboard" /> : FinancerEdit,
  },

  //Scales
  {
    path: "/financer/:financer_id/scale/detail/:id",
    component: isRH() ? () => <Redirect to="/dashboard" /> : ScalesDetail,
  },
  {
    path: "/financer/:financer_id/scale/add",
    component: isRH() ? () => <Redirect to="/dashboard" /> : ScaleAdd,
  },

  {
    path: "/financer/:financer_id/scale/edit/:id",
    component: isRH() ? () => <Redirect to="/dashboard" /> : ScaleEdit,
  },

  //Offers
  {
    path: "/offers",
    component: OffersList,
  },
  {
    path: "/offer/detail/:id",
    component: OffersDetail,
  },
  {
    path: "/offer/add",
    component: OfferUpsert,
  },
  {
    path: "/offer/edit/:id",
    component: OfferUpsert,
  },

  //Products
  {
    path: "/products",
    component: isRH() ? () => <Redirect to="/dashboard" /> : ProductsList,
  },
  {
    path: "/product/detail/:id",
    component: isRH() ? () => <Redirect to="/dashboard" /> : ProductsDetail,
  },
  {
    path: "/product/add",
    component: isRH() || isDA() || isDR() ? () => <Redirect to="/dashboard" /> : ProductAdd,
  },
  {
    path: "/product/edit/:id",
    component: isRH() || isDA() || isDR() ? () => <Redirect to="/dashboard" /> : ProductEdit,
  },

  //Quotes
  {
    path: "/quotes",
    component: isRH() ? () => <Redirect to="/dashboard" /> : QuotesList,
  },
  {
    path: "/quote/add",
    component: isRH() ? () => <Redirect to="/dashboard" /> : QuoteAdd,
  },
  {
    path: "/quote/edit/:order_id/:quote_id",
    component: isRH() ? () => <Redirect to="/dashboard" /> : QuoteEdit,
  },

  //Check deposits
  {
    path: "/check-deposit-list",
    component: CheckDepositList,
  },
  {
    path: "/check-deposit/add",
    component: CheckDepositAdd,
  },
  {
    path: "/check-deposit/:id/detail",
    component: CheckDepositDetail,
  },

  //Refunds
  {
    path: "/refund/add",
    component: isRH() ? () => <Redirect to="/dashboard" /> : RefundAdd,
  },
  {
    path: "/refund/edit/:order_id/:invoice_id",
    component: isRH() ? () => <Redirect to="/dashboard" /> : RefundEdit,
  },

  //Requests
  {
    path: "/requests-list",
    component: RequestsList,
  },
  {
    path: "/requests/add",
    component: RequestsAdd,
  },
  {
    path: "/requests/customers/:id/show",
    component: RequestCustomerDetails,
  },
  {
    path: "/requests/customers/:id/interventions/add/:type",
    component: RequestCustomerInterventionAdd,
  },
  {
    path: "/requests/customers/:id/interventions/edit/:interventionId/:type",
    component: RequestCustomerInterventionForm,
  },
  {
    path: "/requests/customers/:id/requests/add",
    component: RequestCustomerRequestForm,
  },
  {
    path: "/requests/:id/details",
    component: RequestDetails,
  },
  {
    path: "/requests/customers/:id/requests/edit/:requestId",
    component: RequestCustomerRequestForm,
  },


  //Interventions
  {
    path: "/interventions/maintenance/list",
    component: ({history}) => <InterventionList history={history} InterventionsType={"maintenance"} />,
  },
  {
    path: "/interventions/sav/list",
    component: ({history}) => <InterventionList history={history} InterventionsType={"sav"} />,
  },
  {
    path: "/interventions/:id/details",
    component: InterventionFolderDetails,
  },

  //Map
  {
    path: "/map",
    component: MapContainer,
  },

  //Notifications
  {
    path: "/relances-list",
    component: NotificationsList,
  },
  {
    path: "/relance/add",
    component: NotificationAdd,
  },
  {
    path: "/relance/edit/:id",
    component: NotificationEdit,
  },
  {
    path: "/relance/detail/:id",
    component: NotificationDetail,
  },

  // Formulas
  {
    path: "/formulas",
    component: FormulasList,
  },
  {
    path: "/formula/add",
    component: FormulasAdd,
  },
  {
    path: "/formula/:id",
    component: FormulasUpdate
  },

  // Subscriptions
  {
    path: "/subscriptions",
    component: SubscriptionsList,
  },
  {
    path: "/subscription/add",
    component: SubscriptionsAdd,
  },
  {
    path: "/subscription/update/:id",
    component: SubscriptionsUpdate
  },
  {
    path: "/subscription/detail/:id",
    component: SubscriptionsDetail
  },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd }, // Send code
  { path: "/reset-password", component: ResetPwd }, // Change password
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
];

export { userRoutes, authRoutes };
