import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import ScaleForm from "./FormComponent/ScaleForm";

// Validation Schema
import { updateValidationSchema } from "./FormValidationSchemas/updateValidationSchema";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import { getScale, updateScale, scaleApiError } from "../../store/actions";

const ScaleEdit = ({getScale, scale, loading, error, updateScale, history}) => {
  useEffect(() => {
    getScale(parseInt(window.location.pathname.split("/")[5], 10));
  }, [getScale]);

  const initValues = scale
    ? {
      label: scale.label,
      min_monthly_payment: scale.min_monthly_payment,
      min_amount_loan: scale.min_amount_loan,
      max_amount_loan: scale.max_amount_loan,
      ref: scale.ref,
      starting_date: new Date(scale.starting_date),
      ending_date: new Date(scale.ending_date),
      first_monthly_payment_delay: scale.first_monthly_payment_delay,
      import_file: null,
      documents: scale.documents.map((d) => d.url)
    }
    : {
      label: "",
      min_monthly_payment: "",
      min_amount_loan: "",
      max_amount_loan: "",
      ref: "",
      starting_date: "",
      ending_date: "",
      first_monthly_payment_delay: "",
      import_file: null,
      documents: []
    };

  const handleSubmit = (values) => {
    updateScale(
      parseInt(window.location.pathname.split("/")[5], 10),
      {
        financer_id: parseInt(window.location.pathname.split("/")[2], 10),
        label: values.label,
        min_monthly_payment: parseFloat(values.min_monthly_payment),
        min_amount_loan: parseFloat(values.min_amount_loan),
        max_amount_loan: parseFloat(values.max_amount_loan),
        ref: values.ref,
        starting_date: values.starting_date,
        ending_date: values.ending_date,
        first_monthly_payment_delay: parseInt(values.first_monthly_payment_delay),
        documents: values.documents,
        import_file: values.import_file
      },
      history
    );
  };

  return (
    <div className="page-content">
      <Container fluid className="collaborators-container">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Barèmes" breadcrumbItem="Modifier un barème" />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Modifier un barème</CardTitle>
                {loading ? null : !scale ? null : (
                  <ScaleForm
                    initValues={initValues}
                    validationSchema={updateValidationSchema}
                    handleSubmit={handleSubmit}
                    scale={scale}
                    loading={loading}
                    error={error}
                    isUpdate={true}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { scale, loading, error } = state.Scales;
  return { scale, loading, error };
};

export default withRouter(
  connect(mapStatetoProps, {
    getScale,
    updateScale,
    scaleApiError,
  })(ScaleEdit)
);
