import React, {useState, useEffect, createRef, useCallback} from "react";
import {withRouter, Link} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Collapse,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Alert,
    UncontrolledAlert,
    Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from "reactstrap";

// Bootstrap Table
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// Redux
import {connect} from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import loader
import GlobalLoader from "../../components/Common/GlobalLoader";

import {getDateWithShortFormat, orderHasProject} from "../../helpers/helper";

// actions
import {
    getQuotes,
    deleteQuote,
    quotesApiError,
    generateQuoteZip,
    sendQuoteEmails,
    migrateOrderFromQuote,
} from "../../store/actions";
import ValidationModal from "./Modals/ValidationModal";

// helpers
import {listUsersHaveMail, removeFlashMessage} from "../../helpers/lists_helpers";

import {setQuery, parseQuery} from "../../helpers/helper";
import {onTableChange} from "../../helpers/table_helper";
import QuoteFilter from "./Filters/QuoteFilter";
import ProjectConvertModal from "./Modals/ProjectConvertModal";
import {parseCustomerName} from "../../helpers/customer_helper";

const QuotesList = ({
                        getQuotes,
                        history,
                        quotes,
                        list_failed,
                        list_success,
                        list_already_sent,
                        loading,
                        generateQuoteZip,
                        pagination,
                        migrateOrderFromQuote,
                        deleteQuote,
                        sendQuoteEmails
                    }) => {
    const [flash_message, setFlash_message] = useState(false);
    const [filtersCollapseIsOpen, setFiltersCollapseIsOpen] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [quoteIdToDelete, setQuoteIdToDelete] = useState(-1);
    const [modalMail, setModalMail] = useState(false);
    const [isListUsersHaveMail, setIsListUsersHaveMail] = useState(true);
    const [allListUsersHaveMail, setAllListUsersHaveMail] = useState(Array(0));
    const projectConvertModalRef = createRef();

    // Filters
    const query = parseQuery();

    const [filters, setFilters] = useState({
        sort_by: query.sort_by || '',
        sort_dir: query.sort_dir || '',
        page: query.page || 1,
        page_size: query.page_size || 25,
        price_ht_min: query.price_ht_min || '',
        price_ht_max: query.price_ht_max || '',
        price_ttc_min: query.price_ttc_min || '',
        price_ttc_max: query.price_ttc_max || '',
        number: query.number || '',
        customer: query.customer || '',
        email_send: query.email_send || '',
        start_date: query.start_date || '',
        end_date: query.end_date || '',
    });


    const getQuery = useCallback(() => {
        let start_date = '';
        if (filters.start_date) {
            if (filters.start_date instanceof Date) {
                start_date = filters.start_date.toISOString().slice(0, 10); // Keep only YYYY-MM-DD
            } else {
                start_date = filters.start_date.slice(0, 10); // Keep only YYYY-MM-DD
            }
        }

        let end_date = '';
        if (filters.end_date) {
            if (filters.start_date instanceof Date) {
                end_date = filters.end_date.toISOString().slice(0, 10); // Keep only YYYY-MM-DD
            } else {
                end_date = filters.end_date.slice(0, 10); // Keep only YYYY-MM-DD
            }
        }

        return {
            page: filters.page,
            page_size: filters.page_size,
            sort: `${filters.sort_by} ${filters.sort_dir}`.trim(), // trim => remove space when filters is empty
            ht_min: filters.price_ht_min,
            ht_max: filters.price_ht_max,
            ttc_min: filters.price_ttc_min,
            ttc_max: filters.price_ttc_max,
            state: filters.state,
            number: filters.number,
            client: filters.customer,
            email_send: filters.email_send,
            start_date,
            end_date,
        };
    }, [filters]);

    const applyFilter = useCallback(() => {
        getQuotes(getQuery()); // Update interface with new filter
    }, [getQuotes, getQuery]);

    useEffect(() => {
        setQuery(filters, history); // Update query in path

        setQuotesIdList([]);

        applyFilter();
    }, [filters, applyFilter, history]);

    const [quotesIdList, setQuotesIdList] = useState(Array(0));

    function resetFilters() {
        setFilters({
            sort_by: '',
            sort_dir: '',
            page: 1,
            page_size: 25,
            price_ht_min: '',
            price_ht_max: '',
            price_ttc_min: '',
            price_ttc_max: '',
            number: '',
            customer: '',
            email_send: '',
            start_date: '',
            end_date: '',
        });
    }

    function toggleModalDelete(quote_id) {
        setModalDelete(!modalDelete);
        setQuoteIdToDelete(quote_id);
    }

    function toggleModalMail() {
        setModalMail(!modalMail);
        listUsersHaveMail(quotesIdList, quotes, setIsListUsersHaveMail, setAllListUsersHaveMail);
    }

    const toggleShowButton = (array) => {
        if (array.length > 0) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
        setQuotesIdList(array);
    };

    // const canEndorsementOnQuote = (quote) => {
    //   let invoices = quote.invoices;
    //   let endorsementOnQuote = true;

    //   if(!quote.order.child_order) {
    //     for(let i = 0; i < invoices.length; i++) {
    //       if(invoices[i].is_sold_invoice && !invoices[i].is_canceled) {
    //         endorsementOnQuote = false;
    //         break;
    //       }
    //     }
    //   }else{
    //     endorsementOnQuote = false;
    //   }
    //   return endorsementOnQuote;
    // };

    /**
     *
     * @type {{
     * INVOICE_LESS: string,
     * WAITING_DEPOSIT: string,
     * WAITING_INVOICE: string,
     * VALID_DEPOSIT: string,
     * VALID_INVOICE: string
     * }}
     */
    const quoteStates = {
        INVOICE_LESS: "invoice_less",
        WAITING_DEPOSIT: "waiting_deposit",
        VALID_DEPOSIT: "valid_deposit",
        WAITING_INVOICE: "waiting_invoice",
        VALID_INVOICE: "valid_invoice"
    };

    const computeStateMessage = (quoteState) => {
        let message = "";
        let color = "";

        switch (quoteState) {
            case quoteStates.INVOICE_LESS :
                message = "Non facturé";
                color = "danger";
                break;

            case quoteStates.WAITING_DEPOSIT :
                message = "En attente de facturation";
                color = "warning";
                break;

            case quoteStates.VALID_DEPOSIT :
                message = "Acompte facturé";
                color = "warning";
                break;

            case quoteStates.WAITING_INVOICE :
                message = "En attente de facturation";
                color = "success";
                break;

            case quoteStates.VALID_INVOICE :
                message = "Solde facturé";
                color = "success";
                break;
            default:
                break;
        }

        return (
            <h5>
                <Badge color={color}>{message}</Badge>
            </h5>
        );
    }

    /**
     * Compute quote state depending of the invoices
     * @param invoices
     */
    const computeQuoteState = (invoices) => {

        let state = quoteStates.INVOICE_LESS;

        for (let i = 0; i < invoices.length; i++) {
            let invoice = invoices[i];
            if (!invoice.is_canceled && !invoice.is_refund) {
                if (invoice.is_deposit_invoice) {
                    if (invoice.is_draft)
                        state = quoteStates.WAITING_DEPOSIT;
                    else
                        state = quoteStates.VALID_DEPOSIT;

                } else if (invoice.is_sold_invoice) {
                    if (invoice.is_draft)
                        state = quoteStates.WAITING_INVOICE;
                    else
                        state = quoteStates.VALID_INVOICE;

                    break;
                }
            }
        }

        return state;
    };

    const columns = [
        {
            dataField: "number",
            text: "N° devis",
            formatter: (cell, row) => (
                <React.Fragment>
                    <h5 className="font-size-14 mb-1">
                        <Link
                            to={`/quote/edit/${row.order.id}/${row.id}`}
                            className={
                                row.number !== null
                                    ? "table-text-black"
                                    : "table-text-important"
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Modifier ${row.order.order ? "l'avenant" : "le devis"}`}
                        >
                            {row.number}
                        </Link>
                    </h5>
                    {row.order.child_order !== null && row.order.order === null
                        ?
                        <p className="mb-0 min-height-paragraphe"> {`Avec avenant ${row.order.child_quote_number}`} </p>
                        : null}
                    {row.order.order !== null
                        ? <>
                            Sur <span
                            className="mb-0 min-height-paragraphe"> {row.order.order.order_id !== null ? "avenant" : "devis"} </span>
                            {row.order.parent_quote_number}
                        </>
                        : null}
                </React.Fragment>
            ),
            classes: "align-middle"
        },
        {
            dataField: "custom_quote_date",
            text: "Date devis",
            formatter: (cell, row) => getDateWithShortFormat(row.quote_date),
            classes: "align-middle"
        },
        {
            dataField: "client",
            text: "Client",
            formatter: (cell, row) => (
                <React.Fragment>
                    <Link to={`/customer/detail/${row.order.client.id}`}>
                        <h5 className="font-size-14 mb-1 table-text-black">
                            {parseCustomerName(row.order.client)}
                        </h5>
                    </Link>
                    <p className="mb-0">
                        {row.order.client.email === "" ? "Pas d'email" : null}
                    </p>
                </React.Fragment>
            ),
            classes: "align-middle"
        },
        {
            dataField: "invoice_status",
            text: "Facture",
            formatter: (cell, row) => computeStateMessage(computeQuoteState(row.order.invoices)),
            classes: "align-middle"
        },
        {
            dataField: "mail_send_at",
            text: "Envoyé le",
            formatter: (cell, row) =>
                row.mail_send_at
                    ? getDateWithShortFormat(row.mail_send_at)
                    : "Non envoyé",
            classes: "align-middle"
        },
        {
            dataField: "user",
            text: "Créé par",
            formatter: (cell, row) =>
                row.user ? `${row.user.firstname} ${row.user.lastname}` : null,
            classes: "align-middle"
        },
        {
            dataField: "total_price_ht",
            text: "Total HT",
            formatter: (cell, row) => `${parseFloat(row.total_ht).toFixed(2)} €`,
            classes: "align-middle"
        },
        {
            dataField: "total_vat_value",
            text: "Total TVA",
            formatter: (cell, row) => `${parseFloat(row.total_vats).toFixed(2)} €`,
            classes: "align-middle"
        },
        {
            dataField: "total_price_ttc",
            text: "Total TTC",
            formatter: (cell, row) => `${parseFloat(row.total_ttc).toFixed(2)} €`,
            classes: "align-middle"
        },
        {
            dataField: "",
            text: "",
            formatter: (cell, row) => {
                return (
                    <>
                        <UncontrolledDropdown>
                            <DropdownToggle className={"bg-transparent"}>
                                <i className="fas fa-ellipsis-h text-theme-color h4-font-size"/>
                            </DropdownToggle>
                            <DropdownMenu right className="bordered important-default-colors">
                                <DropdownItem onClick={() => {
                                    window.open(`${process.env.REACT_APP_API_URL}/files/${row.url}?filename=${row.number}.pdf`)?.focus()
                                }}>Voir le pdf dans un nouvel onglet</DropdownItem>
                                <DropdownItem onClick={() => {
                                    toggleModalDelete(row.id)
                                }}>Supprimer</DropdownItem>
                                {
                                    row.order && row.order.is_childable && <DropdownItem onClick={() => {
                                        history.push(`/quote/add/${row.order.id}`)
                                    }}>Créer un avenant</DropdownItem>
                                }
                                {row.order && !orderHasProject(row.order) && <DropdownItem onClick={() => {
                                    if (row.order && !orderHasProject(row.order)) {
                                        projectConvertModalRef.current?.toggle(row.order.id)
                                    }
                                }}>Générer le projet</DropdownItem>}
                                <DropdownItem onClick={() => {
                                    toggleShowButton([row.id]);
                                    setModalMail(!modalMail);
                                }}>Envoyer par mail</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>
                );
            },
            classes: "text-right"
        },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="invoices-container">
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Devis" breadcrumbItem="Tous les devis"/>
                    <Row>
                        <Col lg="12">
                            <Alert
                                color="success"
                                isOpen={query.success_add === "1"}
                                toggle={() => removeFlashMessage(flash_message, setFlash_message)}
                            >
                                La facture à bien été créé.
                            </Alert>
                            <Alert
                                color="success"
                                isOpen={query.success_update === "1"}
                                toggle={() => removeFlashMessage(flash_message, setFlash_message)}
                            >
                                La facture à bien été mise à jour.
                            </Alert>
                            <Alert
                                color="success"
                                isOpen={query.success_delete === "1"}
                                toggle={() => removeFlashMessage(flash_message, setFlash_message)}
                            >
                                La facture à bien été suprimé.
                            </Alert>
                            {list_failed.length !== 0 ? (
                                <UncontrolledAlert color="danger">
                                    Les devis des personnes ci-dessous n'ont pas pus être envoyés
                                    :
                                    <br/>
                                    {list_failed
                                        .map((e) => parseCustomerName(e))
                                        .join(", ")}
                                </UncontrolledAlert>
                            ) : null}
                            {list_already_sent.length !== 0 ? (
                                <UncontrolledAlert color="warning">
                                    Les devis des personnes ci-dessous ont déjà été envoyés:
                                    <br/>
                                    {list_already_sent
                                        .map((e) => parseCustomerName(e))
                                        .join(", ")}
                                </UncontrolledAlert>
                            ) : null}
                            {list_success.length !== 0 ? (
                                <UncontrolledAlert color="success">
                                    {list_success.length} devis
                                    {list_success.length > 1 ? " ont" : " a"} bien été
                                    envoyé{list_success.length > 1 ? "s" : ""}.
                                </UncontrolledAlert>
                            ) : null}
                        </Col>
                    </Row>
                    {loading ? (
                        <GlobalLoader/>
                    ) : (
                        <Row>
                            <Col lg="12">
                                <Row>
                                    <Col xs="12" className="d-flex justify-content-start">
                    <span
                        className="d-flex text-link mb-3"
                        onClick={() => {
                            setFiltersCollapseIsOpen(!filtersCollapseIsOpen);
                        }}
                    >
                      <i className="fas fa-filter mr-1"></i>Filtrer
                    </span>
                                        <span
                                            className="d-flex text-link text-color-unset mb-3"
                                            onClick={resetFilters}
                                        >
                      <i className="fas fa-times mr-1"></i> Vider les
                      filtres
                    </span>
                                    </Col>
                                    <Col xs="12">
                                        <Collapse isOpen={filtersCollapseIsOpen}>
                                            <QuoteFilter
                                                filters={filters}
                                                setFilters={setFilters}
                                            />
                                        </Collapse>
                                    </Col>
                                </Row>
                                <Card>
                                    <CardBody className="table-invoices-container">
                                        <CardTitle className="mb-3">
                                            <Row className="pr-2 pl-2 d-flex justify-content-between">
                                                <Col xs="12" className="title-button">
                                                    Tous les devis
                                                    {showButton ? (
                                                        <button
                                                            className="ml-3 !text-theme-color-2 bg-transparent py-0 mb-n1 mt-n1"
                                                            onClick={() => {
                                                                generateQuoteZip(quotesIdList);
                                                                toggleShowButton(Array(0));
                                                            }}
                                                        >
                                                            Télécharger
                                                        </button>
                                                    ) : null}
                                                    {showButton ? (
                                                        <button
                                                            className="ml-3 !text-theme-color-2 bg-transparent py-0 mb-n1 mt-n1"
                                                            onClick={() => toggleModalMail()}
                                                        >
                                                            Envoyer par mail
                                                        </button>
                                                    ) : null}
                                                </Col>
                                                <Col className="d-flex justify-content-end">
                                                    <Link
                                                        to="/quote/add"
                                                        className="d-flex align-items-center text-theme-color-2"
                                                    >
                                                        <i className="bx bx-plus-circle mr-1"></i> Créer un
                                                        devis
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </CardTitle>
                                        <BootstrapTable
                                            keyField="id"
                                            data={quotes}
                                            columns={columns}
                                            defaultSortDirection="asc"
                                            remote={{pagination: true}}
                                            classes={"table-centered"}
                                            onTableChange={onTableChange.bind({setFilters, filters})}
                                            pagination={paginationFactory({
                                                page: pagination.current_page,
                                                totalSize: pagination.total_count,
                                                sizePerPage: pagination.current_page_size,
                                                sizePerPageList: [
                                                    {text: "Afficher 10 éléments", value: 10},
                                                    {text: "Afficher 25 éléments", value: 25},
                                                    {text: "Afficher 50 éléments", value: 50},
                                                    {text: "Afficher 75 éléments", value: 75},
                                                    {text: "Afficher 100 éléments", value: 100},
                                                ],
                                            })}
                                            selectRow={{
                                                selected: quotesIdList,
                                                mode: "checkbox",
                                                onSelect: (row, isSelect, rowIndex, e) => {
                                                    let temp = quotesIdList;
                                                    if (isSelect) {
                                                        temp.push(row.id);
                                                    } else {
                                                        let index = temp.indexOf(row.id);
                                                        if (index > -1) {
                                                            temp.splice(index, 1);
                                                        }
                                                    }
                                                    toggleShowButton(temp);
                                                },
                                                onSelectAll: (isSelect, rows, e) => {
                                                    let temp = quotesIdList;
                                                    if (isSelect) {
                                                        rows.forEach((row, index) => {
                                                            temp.push(row.id);
                                                        });
                                                    } else {
                                                        rows.forEach((row, i) => {
                                                            let index = temp.indexOf(row.id);
                                                            if (index > -1) {
                                                                temp.splice(index, 1);
                                                            }
                                                        });
                                                    }
                                                    toggleShowButton(temp);
                                                },
                                                bgColor: "rgba(156,195,28,0.2)"
                                            }}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>

                            <ProjectConvertModal
                                ref={projectConvertModalRef}
                                handleSubmit={migrateOrderFromQuote}
                            />

                            <ValidationModal
                                isOpen={modalDelete}
                                toggler={() => toggleModalDelete()}
                                title="Devis - suppression"
                                message={<div><h2>Attention</h2><p>Voulez vous supprimer le devis definitivement ?</p>
                                </div>}
                                btnValidText="Supprimer"
                                btnBackText="Annuler"
                                handleSubmit={() => {
                                    toggleModalDelete();
                                    deleteQuote(quoteIdToDelete, history);
                                }}
                            />

                            <Modal
                                isOpen={modalMail}
                                toggle={() => {
                                    toggleModalMail();
                                }}
                            >
                                <ModalHeader toggle={() => toggleModalMail()} tag="h4">
                                    Devis - envoyer par mail
                                </ModalHeader>
                                <ModalBody>
                                    {isListUsersHaveMail ? (
                                        <p>
                                            Les devis sélectionnés vont être envoyés par mail au(x)
                                            client(s).
                                        </p>
                                    ) : (
                                        <React.Fragment>
                                            <p>
                                                Certains clients sélectionnés ne possèdent pas d'adresse
                                                mail. Si vous choisissez de continuer, il ne recevront
                                                pas le devis :{" "}
                                                {allListUsersHaveMail.map((name, index) => (
                                                    <span>
                            <b>{`${name}`}</b>
                                                        {`${
                                                            allListUsersHaveMail.length === index + 1
                                                                ? "."
                                                                : ", "
                                                        }
                            `}
                          </span>
                                                ))}
                                            </p>
                                        </React.Fragment>
                                    )}
                                    <p>Voulez-vous continuer ?</p>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        onClick={() => {
                                            toggleModalMail();
                                        }}
                                        className="btn btn-secondary waves-effect"
                                    >
                                        Annuler
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            toggleModalMail();
                                            toggleShowButton(Array(0));
                                            sendQuoteEmails(quotesIdList, getQuery());
                                        }}
                                        className="btn btn-green waves-effect waves-light"
                                    >
                                        Envoyer
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </Row>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const {
        quotes,
        pagination,
        loading,
        error,
        list_success,
        list_failed,
        list_already_sent,
    } = state.Quotes;
    return {
        quotes,
        pagination,
        loading,
        error,
        list_success,
        list_failed,
        list_already_sent,
    };
};

export default withRouter(
    connect(mapStatetoProps, {
        getQuotes,
        deleteQuote,
        quotesApiError,
        generateQuoteZip,
        sendQuoteEmails,
        migrateOrderFromQuote
    })(QuotesList)
);
