import React from 'react';
import {Button, Col, Row} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {isEndSoon, parseDate} from "../../../helpers/subscriptions_helpers";

import "../../../assets/scss/custom/plugins/icons/_fontawesome.scss"
import paginationFactory from "react-bootstrap-table2-paginator";
import {onTableChange} from "../../../helpers/table_helper";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Link } from 'react-router-dom';

const SubscriptionsTable = ({
  data,
  pagination,
  setFilters,
  filters,
  history
                            }) => {


  const columns = [
    {
      dataField: 'customer',
      text: 'Client',
      formatter: (cell, row) => (
        <Link to={`/customer/detail/${row.client_id}`}>{row.client_name}</Link>
      )
    },
    {
      dataField: 'number',
      text: 'Numéro d\'abonnement'
    },
    {
      dataField: 'start_at',
      text: 'Date de prise d\'effet',
      formatter: (cell, row) => parseDate(cell)
    },
    {
      dataField: 'end_at',
      text: 'Date de fin',
      formatter: (cell) => <div className={isEndSoon(cell)}>{parseDate(cell)}</div>
    },
    {
      dataField: 'formule_label',
      text: 'Formule'
    },
    {
      dataField: 'details',
      formatter: (cell, row) => (
        <>
          <UncontrolledDropdown>
            <DropdownToggle className={"bg-transparent"}>
              <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
            </DropdownToggle>
            <DropdownMenu right className="bordered important-default-colors">
              <DropdownItem onClick={() => { history.push(`/subscription/detail/${row.id}`) }}>Voir</DropdownItem>
              <DropdownItem onClick={() => { window.open(`/subscription/detail/${row.id}`, '_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
              {row.quote && <DropdownItem onClick={() => { window.open(`${process.env.REACT_APP_API_URL}/files/${row.quote.url}`, '_blank')?.focus() }}>Afficher le pdf du devis dans un nouvel onglet</DropdownItem>}
              {
                !(row.mandates.length === 0 || !row.mandates[0].document_path) &&
                <DropdownItem onClick={() => { window.open(`${process.env.REACT_APP_API_URL}/files/${row.mandates[0].document_path}`, '_blank')?.focus() }}>Afficher le mandat</DropdownItem>
              }
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      ),
      classes: "text-right"
    }
  ];

  return (
    <div>
      <Row className="mt-3 mb-3">
        <Col lg="4" sm="4">
          <input
            type="text"
            id="subscription_query"
            className="form-control"
            placeholder="Rechercher un abonnement"
            defaultValue={filters.query}
          />
        </Col>
        <Col lg="3" sm="3">
          <Button
            onClick={() => setFilters(prev => ({...prev, query: document.getElementById('subscription_query').value}))}
            className="btn-green"
          >
            Rechercher
          </Button>
        </Col>
      </Row>
      <BootstrapTable
        keyField='id'
        columns={columns}
        data={data}
        defaultSortDirection="asc"
        remote={{ pagination: true }}
        onTableChange={onTableChange.bind({setFilters, filters})}
        pagination={paginationFactory({
          page: pagination.current_page,
          totalSize: pagination.total_count,
          sizePerPage: pagination.current_page_size,
          sizePerPageList: [
            { text: "Afficher 10 éléments", value: 10 },
            { text: "Afficher 25 éléments", value: 25 },
            { text: "Afficher 50 éléments", value: 50 },
            { text: "Afficher 75 éléments", value: 75 },
            { text: "Afficher 100 éléments", value: 100 },
          ],
        })}
      />
    </div>
  );
}

export default SubscriptionsTable;