import {CSSProperties, useState} from 'react';
import {Button} from 'reactstrap';

export interface SearchFilterWithButtonProps {
  placeholder: string,
  defaultValue: string,
  onSearch: (value: string) => void,
  autosearch?: boolean,
  autosearchLength?: number,
  containerClasses?: string|null,
  inputClasses?: string|null,
  buttonClasses?: string|null,
  containerStyles?: CSSProperties|null,
  inputStyles?: CSSProperties|null,
  buttonStyles?: CSSProperties|null,
}

const SearchFilterWithButton = ({
    placeholder,
    defaultValue,
    onSearch,
    autosearch,
    autosearchLength,
    containerClasses,
    inputClasses,
    buttonClasses,
    containerStyles,
    inputStyles,
    buttonStyles,

  }: SearchFilterWithButtonProps) => {
  autosearch = autosearch || false;
  autosearchLength = autosearchLength || 3;
  let [query, setQuery] = useState(defaultValue);
  return (
    <div className={"d-flex " + (containerClasses || "")} style={containerStyles || {}}>
        <input
          type="text"
          placeholder={placeholder}
          defaultValue={defaultValue}
          onClick={(e) => e.stopPropagation()}
          className={"form-control d-inline-block " + (inputClasses || "")}
          style={inputStyles || {}}
          onKeyUp={(e) => {
            setQuery(e.currentTarget.value)
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              onSearch(query);
            } else if (autosearch) {
              if (e.currentTarget.value.length >= autosearchLength!) {
                onSearch(query)
              }
            }
          }}
        />
        <Button
          onClick={(e) => {onSearch(query);}}
          color="none"
          className={"btn-green ml-3 " + (buttonClasses || "")}
          style={buttonStyles || {}}
        >
          Rechercher
        </Button>
    </div>
  )
}

export default SearchFilterWithButton;