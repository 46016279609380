export function getAssetsDistUrl(url: string, apiUrl: string = process.env.REACT_APP_API_URL || "") {
  if (url.match(/http/)) return url;
  let finalUrl : string;
  // if url not contains /assets or /files use /files/ directory for the client
  if (!url.match(/assets/) && !url.match(/files/)) {
    finalUrl = `${apiUrl}/files/${url}`;
  } else {
    finalUrl = `${apiUrl}${url}`;
  }

  let split = finalUrl.split('://');
  split[1] = split[1].replace(/\/\//g, '/');
  return split.join('://');
}

export function isImageUrl(url: string) {
  return url.match(/(\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$)|data:image/) !== null;
}