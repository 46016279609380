import React, { useState } from 'react';

// Component
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GlobalLoader from "../../components/Common/GlobalLoader";

// Context
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { V2 } from "../../api";
import { parseQuery } from "../../helpers/helper";
import { createSubscription } from "../../store/subscriptions/actions";
import SubscriptionForm, { durationType } from "./Form/SubscriptionForm";
import ConfirmUpsertModal from "./Modal/ConfirmUpsertModal";

let modalValues = {};

const SubscriptionsAdd = ({
  createSubscription,
  history,
  loading
}) => {

  const query = parseQuery();
  const [mandate, setMandate] = useState(null);

  const initValues = {
    customer: query.customer_id ? parseInt(query.customer_id) : '',
    debit_day: '',
    signature_date: '',
    mandate: {},
    mandateImage: [],
    formula: '',
    validation_date: '',
    periodicity: '',
    ics: '',
    debit_type: '',
    start_at: '',
    term_number: null,
    duration: durationType.UNDETERMINED,
    end_at: null,
    rum: '',
    iban: '',
    notes: '',
    suspended_at: '',
  }

  function create() {
    let values = modalValues;
    createSubscription({
      debit_day: values.debit_day,
      validated_at: values.validation_date,
      start_at: values.start_at,
      end_at: values.end_at,
      formule_id: values.formula,
      client_id: values.customer,
      debit_type: values.debit_type,
      periodicity: values.periodicity,
      to_renew: true,
      project_id: null,
      term_number: values.term_number,
      duration_type: values.duration,
      signed_at: values.signature_date,
      notes: values.notes,
      suspended_at: values.suspended_at,
      mandates_attributes: [{
        ics: values.ics,
        rum: values.rum,
        iban: values.iban,
        document_path: values.mandate.name ? values.mandate : null
      }]
    }, history)
  }

  function handleSubmitSubscription(values) {
    modalValues = values;
    let iban = values.iban;
    if (!!iban) {
      V2.mandates.getMandateByIban(iban, {}).then(response => {
        setMandate(response.data);
      }).catch(_ => {
        create();
      });
    } else {
      create();
    }
  }

  return (
    <div className="page-content">
      {loading ? <GlobalLoader /> : <Container fluid >
        <Breadcrumbs title="Listes" breadcrumbItem="Abonnements" breadcrumbTitle='Listes' />
        <Card>
          <CardBody>
            {/* Card title */}
            <CardTitle className="mb-3">
              <Row className="pr-2 pl-2 d-flex justify-content-between">
                <Col>Créer un abonnement</Col>
              </Row>
            </CardTitle>
            <SubscriptionForm
              initValues={initValues}
              handleSubmit={handleSubmitSubscription}
            />
          </CardBody>
        </Card>
        <ConfirmUpsertModal
          mandate={mandate}
          toggleModal={() => setMandate(null)}
          handle={create}
        />
      </Container>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    subscription,
    loading,
    error,
    pagination,
  } = state.Subscriptions

  return {
    subscription,
    loading,
    error,
    pagination
  }
}

export default withRouter(
  connect(mapStateToProps, {
    createSubscription,
  })(SubscriptionsAdd)
);
