import React from 'react';
import { Badge, Button } from "reactstrap";

import { ReactComponent as ImgDelete } from '../../../assets/images/icons/minus.svg';
import { ReactComponent as ImgUpArrow } from '../../../assets/images/icons/arrow_up.svg';
import { ReactComponent as ImgDownArrow } from '../../../assets/images/icons/arrow_down.svg';
import { computeTotalDurationDisplay } from "../../../helpers/map_helper";
import { parseCustomerName, parseCustomerAddress, parseCustomerPhone } from '../../../helpers/customer_helper';
import { CustomMarker } from './CustomMarker';


interface ListProjectWaypointsProps {
  markers: CustomMarker[],
  directions?: google.maps.DirectionsResult,
  moveItemOrder: (index: number, direction: number) => void,
  deleteItem: (marker: CustomMarker) => void,
  selectMarker: (position: google.maps.LatLng | google.maps.LatLngLiteral, zoom: number, id: number) => void
}

const ListProjectWaypoints = ({
  markers,
  moveItemOrder,
  deleteItem,
  selectMarker,
  directions
}: ListProjectWaypointsProps) => {

  return (
    <div className='projects-list'>
      <p className="projects-list-title">Tournée</p>
      {markers.map((marker: CustomMarker, index: number) => (
        <div
          className='project-info-container'
          onClick={() => selectMarker(marker.position, 3, marker.entity.id!)}
          key={`project-${index}`}
        >
          <div className='d-flex justify-content-between'>
            <div>
              <p>
                <Badge color="danger" pill>
                  {String.fromCharCode(('A'.charCodeAt(0) + (index % 26)))}
                </Badge>
                {` Client : ${parseCustomerName(marker.entity.client)}`}
              </p>
              <p className="project-info-small">
                {parseCustomerAddress(marker.entity.client)}
              </p>
              <p>
                Téléphone : {parseCustomerPhone(marker.entity.client)}
              </p>
              <p>
                {directions && index > 0 ?
                  (directions.routes[0].legs.length === markers.length - 1 ?
                    directions.routes[0].legs[index - 1].duration?.text || '' : '')
                  : ''}
              </p>
            </div>
            <Button
              onClick={() => deleteItem(marker)}
              className='custom-map-buttons delete-button-map'
            ><ImgDelete /></Button>
          </div>
          <div className='buttons-waypoint'>
            <Button
              onClick={() => moveItemOrder(index, -1)}
              className={`custom-map-buttons arrow-button-map ${index === 0 ? "invisible" : ""}`}
            ><ImgUpArrow /></Button>
            <Button
              onClick={() => moveItemOrder(index, 1)}
              className={`custom-map-buttons arrow-button-map ${index === (markers.length - 1) ? "invisible" : ""}`}
            ><ImgDownArrow /></Button>
          </div>
        </div>
      ))}
      <div>
        {directions && markers.length > 1 ?
          <p>Temps total : {computeTotalDurationDisplay(directions.routes[0].legs)}</p>
          : ''}
      </div>
    </div>
  );
}

export default ListProjectWaypoints;