import { withRouter } from "react-router-dom";

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
// Redux
import { connect } from "react-redux";

// Bootstrap table
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// Helpers
import { parseCustomerName } from "../../../helpers/customer_helper";
import { getDateWithShortFormat } from "../../../helpers/helper";

const PrimeTable = (props) => {
  const columns = [
    {
      dataField: "client",
      text: "Client",
      formatter: (cell, row) =>
        row.order && row.order.client ? (
          <ul className="d-flex justify-content-start list-inline mb-0">
            <li className="list-inline-item mr-3">
              <i className="bx bx-user mr-1"></i>
              <span className="font-size-12">
                {parseCustomerName(row.order.client)}
              </span>
            </li>
          </ul>
        ) : null,
    },
    {
      dataField: "address",
      text: "Adresse",
      formatter: (cell, row) =>
        row.order && row.order.client ? (
          <p>
            {row.order.client.address}, {row.order.client.zip_code} {row.order.client.city}
          </p>
        ) : null,
    },
    {
      dataField: "maintenance_order",
      text: "Type de contrat",
      formatter: (cell, row) =>
        row && row.maintenance_order && row.maintenance_order.free_items[0] ? (
          <p>
            {row.maintenance_order.free_items[0].free_field.title}
          </p>
        ) : null,
    },
    {
      dataField: "maintenance_order_begin_at",
      text: "Date de premier prélèvement",
      formatter: (cell, row) =>
        row && row.maintenance_at ? (
          <p>
            {getDateWithShortFormat(row.maintenance_at)}
          </p>
        ) : null,
    },
    {
      dataField: "maintenance_comment",
      text: "Commentaire",
      formatter: (cell, row) =>
        row && row.maintenance_comment ? (
          <p>
            {row.maintenance_comment}
          </p>
        ) : null,
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <UncontrolledDropdown>
          <DropdownToggle tag={"a"} className={"bg-transparent px-3 py-2 d-inline-block"}>
            <i className="fas fa-ellipsis-h text-theme-color h4-font-size" />
          </DropdownToggle>
          <DropdownMenu right className="bordered important-default-colors">
            <DropdownItem onClick={() => { props.history.push(`/projects-overview/${row.id}?tab=entretien`) }}>Voir</DropdownItem>
            <DropdownItem onClick={() => { window.open(`/projects-overview/${row.id}?tab=entretien`, '_blank')?.focus() }}>Voir dans un nouvel onglet</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
      classes: "text-right"
    },
  ];

  return (
    <div className="prime-table-container">
      <BootstrapTable
        keyField="id"
        data={props.projects}
        columns={columns}
        classes="table-centered"
        defaultSortDirection="desc"
        sort={{ dataField: props.sort.dataField || 'id', order: props.sort.order }}
        remote={{ pagination: true, sort: true }}
        onTableChange={props.onTableChange}
        pagination={paginationFactory({
          page: props.pagination.current_page,
          totalSize: props.pagination.total_count,
          sizePerPage: props.pagination.current_page_size,
          sizePerPageList: [
            { text: "Afficher 10 éléments", value: 10 },
            { text: "Afficher 25 éléments", value: 25 },
            { text: "Afficher 50 éléments", value: 50 },
            { text: "Afficher 75 éléments", value: 75 },
            { text: "Afficher 100 éléments", value: 100 },
          ],
        })}
      />
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { projects, pagination } = state.Projects;

  return { projects, pagination };
};

export default withRouter(connect(mapStatetoProps, {})(PrimeTable));
