import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Media,
  Badge,
  Modal,
  Row,
  Collapse,
} from "reactstrap";
import Moment from "react-moment";
import "moment-timezone";

//Import Date Picker
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

// Redux
import { connect } from "react-redux";

import { projectsApiError } from "../../store/actions";
import Select from "react-select";

const CardProject = (props) => {
  const [modal_standard, setmodal_standard] = useState(false);

  const [user_id, setUser_id] = useState(null);

  const [clients] = useState(Array(0));
  const [client_id, setClient_id] = useState(null);

  const [project_states] = useState(Array(0));
  const [project_state_id] = useState(null);

  const [project_id, setProject_id] = useState(-1);
  const [filters, setFilters] = useState(false);
  const [reset_filters, setReset_filters] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  function tog_standard(project_id) {
    setmodal_standard(!modal_standard);
    setProject_id(project_id);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    setUser_id(null);
    // setStartDate(new Date());
    // setEndDate(new Date());
  }, [reset_filters]);

  return (
    <React.Fragment>
      {props.loading ? null : (
        <Col xs="12">
          <Row>
            <Col xs="12" className="d-flex justify-content-start">
              <Link
                to="#"
                className="d-flex align-items-center color-green mb-3"
                onClick={() => setFilters(!filters)}
              >
                <i className="fas fa-filter mr-1"></i>Filtrer
              </Link>
              <Link
                to="#"
                className="d-flex align-items-center text-theme-color mb-3 ml-3"
                onClick={() => setReset_filters(!reset_filters)}
              >
                <i className="fas fa-times mr-1"></i> Vider les filtres
              </Link>
            </Col>
            <Col xs="12">
              <Collapse isOpen={filters}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <Select
                          className="w-100 mt-3"
                          isSearchable={true}
                          noOptionsMessage={() =>
                            "Aucun commerciaux disponible"
                          }
                          placeholder=" Choisir un commercial..."
                          options={props.users.map((user, index) => ({
                            value: user.id,
                            label: user.firstname + " " + user.lastname,
                          }))}
                          defaultValue={user_id}
                          onChange={(user) => {
                            setUser_id({
                              value: user.value,
                              label: user.label,
                            });
                          }}
                        />
                      </Col>
                      <Col md="6">
                        <Select
                          className="w-100 mt-3"
                          isSearchable={true}
                          noOptionsMessage={() => "Aucun clients disponible"}
                          placeholder=" Choisir un clients..."
                          options={clients}
                          defaultValue={client_id}
                          onChange={(client) => {
                            setClient_id({
                              value: client.value,
                              label: client.label,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Select
                          className="w-100 mt-3"
                          isSearchable={true}
                          noOptionsMessage={() => "Aucun état disponible"}
                          placeholder=" Choisir un état..."
                          options={project_states}
                          defaultValue={project_state_id}
                          onChange={(state) => {
                            setClient_id({
                              value: state.value,
                              label: state.label,
                            });
                          }}
                        />
                      </Col>
                      <Col md="6">
                        <div className="mt-3 container-filter-datepicker">
                          <div className="d-flex justify-content-between">
                            <div className="item-filter-datepicker pr-2">
                              <p>Date de début</p>
                              <DatePicker
                                className="form-control button-without-style"
                                selected={startDate}
                                onChange={(date) => {
                                  setStartDate(date);
                                }}
                                dateFormat="dd/MM/yyyy"
                                locale={fr}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                            </div>
                            <div className="item-filter-datepicker pl-2">
                              <p>Date de fin</p>
                              <DatePicker
                                className="form-control button-without-style"
                                selected={endDate}
                                onChange={(date) => {
                                  setEndDate(date);
                                }}
                                dateFormat="dd/MM/yyyy"
                                locale={fr}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Collapse>
            </Col>
          </Row>
        </Col>
      )}
      {props.projects.map((project, key) => (
        <Col xl="4" sm="6" key={"_project_" + key}>
          <Card>
            <CardBody>
              <Media>
                <div className="avatar-md mr-4">
                  <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                    <img src="" alt="" height="30" />
                  </span>
                </div>

                <Media className="overflow-hidden" body>
                  <h5 className="text-truncate font-size-15">
                    <Link
                      to={`/projects-overview/${project.id}`}
                      className="text-dark"
                    >
                      {project.id}
                    </Link>
                  </h5>
                  <p className="text-muted mb-4"></p>

                  <div className="team">
                    {project.users.map((user, key) =>
                      // team.img !== "Null"
                      false ? (
                        <Link
                          to="#"
                          className="team-member d-inline-block"
                          id={"member" + user.id}
                          key={"_user_" + key}
                        >
                          <img
                            src={user.img}
                            className="rounded-circle avatar-xs m-1"
                            alt=""
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={"member" + user.id}
                          >
                            {user.name}
                          </UncontrolledTooltip>
                        </Link>
                      ) : (
                        <div key={"_user_" + key}>
                          <Link
                            to="#"
                            className="team-member d-inline-block"
                            id={"member" + user.id}
                          >
                            <div className="avatar-xs m-1">
                              <span
                                className={
                                  "avatar-title rounded-circle bg-soft-" +
                                  user.color +
                                  " text-" +
                                  user.color +
                                  " font-size-16"
                                }
                              >
                                {user.firstname.charAt(0)}
                                {user.lastname.charAt(0)}
                              </span>
                            </div>
                          </Link>
                          <Link
                            to="#"
                            className="team-member d-inline-block"
                            id={"member" + user.id}
                          >
                            <div className="m-1">
                              <span
                                className={
                                  "bg-soft-" +
                                  user.color +
                                  " text-" +
                                  user.color +
                                  " font-size-12"
                                }
                              >
                                {user.firstname}
                                {user.lastname}
                              </span>
                            </div>
                          </Link>
                        </div>
                      )
                    )}
                  </div>
                </Media>
              </Media>
            </CardBody>
            <div className="px-4 py-3 border-top">
              <ul className="d-flex justify-content-center list-inline mb-0">
                <li className="list-inline-item mr-3">
                  <Badge>{project.project_state.name}</Badge>
                </li>
                <li className="list-inline-item mr-3">
                  <i className="bx bx-calendar mr-1"></i>
                  <span className="font-size-12">
                    <Moment format="DD MM YYYY">{project.created_at}</Moment>
                  </span>
                </li>
                <li className="list-inline-item mr-3">
                  <i className="bx bx-user mr-1"></i>
                  <span className="font-size-12">
                    {project.client.civility}. {project.client.lastname}
                  </span>
                </li>
                <li className="list-inline-item mr-3" id={`map-${project.id}`}>
                  <i className="bx bx-map mr-1"></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={`map-${project.id}`}
                  >
                    {project.client.city} ({project.client.zip_code.charAt(0)}
                    {project.client.zip_code.charAt(1)})
                  </UncontrolledTooltip>
                </li>
              </ul>
            </div>
            <div className="px-4 py-3 border-top">
              <ul className="d-flex justify-content-end list-inline mb-0 color-green">
                {/* <li className="list-inline-item mr-3">
                                  <i className="bx bxs-edit-alt mr-1"></i>
                                  <span className="font-size-12">
                                      Modifier
                                  </span>
                              </li> */}
                <li className="list-inline-item mr-3">
                  <Link
                    to="#"
                    onClick={() => {
                      tog_standard(project.id);
                    }}
                    data-toggle="modal"
                    data-target="#projectDelete"
                    className="color-green"
                  >
                    <i className="bx bx-trash mr-1"></i>
                    <span className="font-size-12">Supprimer</span>
                  </Link>
                </li>
              </ul>
            </div>
          </Card>
        </Col>
      ))}
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Projet - Suppression
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Attention</h5>
          <p>Voulez vous supprimer le projet definitivement ?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Annuler
          </button>
          <button
            type="button"
            onClick={() => {
              props.handleDeleteProject(project_id);
            }}
            className="btn btn-green waves-effect waves-light"
          >
            Supprimer
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, { projectsApiError })(CardProject)
);
