import axios from 'axios';
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Order from '../../../../models/order';
import FreeItem from '../../../../models/free_item';
import ProductItem from '../../../../models/product_item';
import { addFlashMessage, ImportantEnum } from '../../../../components/Common/FlashMessage';
import LoaderLayoutPulse from '../../../../components/Common/LoaderLayoutPulse';

enum SerialNumberOrderItemTypeEnum {
  PRODUCT_ITEM = 'product_item',
  FREE_ITEM = 'free_item'
}

interface SerialNumberOrderItem {
  id: number;
  name: string;
  serialNumber: string;
  type: SerialNumberOrderItemTypeEnum;
}

function mapOrderToOrderItemArray(order: Order | null | undefined): SerialNumberOrderItem[] {
  if (!order) return [];
  return (order.product_items || []).map<SerialNumberOrderItem>(pi => ({
    id: pi.id as number,
    name: pi.product?.title as string,
    serialNumber: pi.serial_number as string,
    type: SerialNumberOrderItemTypeEnum.PRODUCT_ITEM
  })).concat((order.free_items || []).map<SerialNumberOrderItem>(fi => ({
    id: fi.id as number,
    name: fi.title as string,
    serialNumber: fi.serial_number as string,
    type: SerialNumberOrderItemTypeEnum.FREE_ITEM
  })));
}

function saveOrderItems(orderId: number, serialNumberOrderItems: SerialNumberOrderItem[], onClose: () => void, setLoading: (loading: boolean) => void) {
  let data : {
    free_items_attributes: FreeItem[],
    product_items_attributes: ProductItem[]
  } = { free_items_attributes: [], product_items_attributes: [] };
  data.free_items_attributes = serialNumberOrderItems.filter(item => item.type === SerialNumberOrderItemTypeEnum.FREE_ITEM).map<FreeItem>(item => ({
    id: item.id,
    serial_number: item.serialNumber
  }));
  data.product_items_attributes = serialNumberOrderItems.filter(item => item.type === SerialNumberOrderItemTypeEnum.PRODUCT_ITEM).map<ProductItem>(item => ({
    id: item.id,
    serial_number: item.serialNumber
  }));
  setLoading(true);
  axios.patch(`/orders/${orderId}`, data).then(() => {
    onClose();
    window.scrollTo(0, 0);
    setLoading(false);
    addFlashMessage(ImportantEnum.SUCCESS, 'Les numéros de série ont été mis à jour');
  }).catch(() => {
    onClose();
    setLoading(false);
    addFlashMessage(ImportantEnum.DANGER, 'Impossible de mettre à jour les numéros de série');
  });
}

const columns = [
  {
    dataField: 'name',
    text: 'Nom',
    classes: 'w-50'
  },
  {
    dataField: 'serialNumber',
    text: 'Numéro de série',
    classes: 'w-50',
    editor: {
      type: Type.TEXTAREA,
    }
  }
];

export interface SerialNumberModalProps {
  isOpen: boolean;
  onClose: () => void;
  order: Order;
}

const SerialNumberModal = ({
  isOpen,
  onClose,
  order
}: SerialNumberModalProps) => {
  let [loading, setLoading] = React.useState(false);
  let [data, setData] = React.useState<SerialNumberOrderItem[]>(mapOrderToOrderItemArray(order));

  React.useEffect(() => {
    setData(mapOrderToOrderItemArray(order));
  }, [order]);

  return (
    <Modal size="xl" isOpen={isOpen} toggle={onClose} className={"w-50"}>
      <ModalHeader toggle={onClose}>Mettre à jour les numéros de série</ModalHeader>
      <ModalBody className={"d-flex flex-column justify-content-center align-items-center"}>
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          cellEdit={cellEditFactory({ mode: 'click', blurToSave: true, })}
        />
      </ModalBody>
      <ModalFooter>
        <Col xs="12" className="text-center pt-3 pb-3">
          {loading ? <LoaderLayoutPulse/> : <Button
            color="none"
            className="btn-green"
            onClick={() => saveOrderItems(order.id!, data, onClose, setLoading)}
          >
            Sauvegarder les modifications
          </Button>}
        </Col>
      </ModalFooter>
    </Modal>
  );
}

export default SerialNumberModal;