// Modals components
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from "reactstrap";

/**
 * @typedef {Object} ValidationModalProps
 * @property {boolean}        isOpen defined if the modal is open
 * @property {function}       toggler function toggling the modal
 * @property {function}       handleSubmit handler for the submit of the quote
 * @property {string}         title
 * @property {JSX.Element}    message
 *
 * If those params are null (by default they are), the button will not be set
 * @property {string}         btnValidText
 * @property {string}         btnBackText -  Indicates type of toast.
 * Also useful to show different icons.
 */

/**
 * Modal opened when a quote is about to be created without workforce
 * @param {ValidationModalProps} props
 * **/
const ValidationModal = ({
    isOpen,
    toggler,
    title,
    message,
    btnValidText = null,
    btnBackText = null,
    handleSubmit
}) => {

  return (
    <Modal
        isOpen = {isOpen}
        toggle = {() => {
          toggler();
        }}
      >
        <ModalHeader toggle = {() => toggler()} tag = "h4">
            {title}
        </ModalHeader>
        <ModalBody>
            {message}
        </ModalBody>
        <ModalFooter>
            {btnBackText ? <Button
                onClick={() => {
                    toggler();
                }}
                className="btn btn-secondary waves-effect"
            >
                {btnBackText}
            </Button> : ""}
            {btnValidText ? <Button
            onClick={handleSubmit}
            className = "btn btn-green waves-effect waves-light"
          >
              {btnValidText}
          </Button> : ""}
        </ModalFooter>
      </Modal>
  );

}

export default ValidationModal;