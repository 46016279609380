import React from "react";

import Select from "react-select";

class CategorySelector extends React.Component {
  state = {
    category: this.props.value
      ? {
          value: this.props.value.id,
          label: this.props.value.name,
        }
      : null,
  };

  render() {
    const { category } = this.state;
    const { onUpdate, categories, placeholder, disabled } = this.props;

    return [
      <Select
        isDisabled={disabled}
        className="w-100"
        value={category}
        noOptionsMessage={() => "Pas de catégorie disponible"}
        placeholder={placeholder}
        options={
          categories
            ? categories.map((item) => ({
                label: item.name,
                value: item.id,
                sub_categories: item.sub_categories
              }))
            : []
        }
        onChange={(value) => {
          this.setState({ category: value });
          console.log(value);
          onUpdate({
            value: value.value,
            name: value.label,
            id: value.value,
            sub_categories: value.sub_categories
          });
        }}
        key="category"
      />,
    ];
  }
}

export default CategorySelector;
