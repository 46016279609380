import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import OfferCode from '../../../../models/offer_code';

export interface OfferCodeGenerateModalProps {
  onAbort: () => void,
  code: OfferCode
}

const OfferCodeGenerateModal = ({
  onAbort,
  code
} : OfferCodeGenerateModalProps) => {

  const copyCode = async () => {
    try {
      await navigator.clipboard.writeText(code.code?.toString() || '');
      alert('Le code a été copié dans le presse papier');
    } catch (e) {
      alert('Le code n\'a pas été copié dans le presse papier. Veuillez le copier manuellement.');
    }
  }
  return (
    <Modal isOpen={true} toggle={onAbort} className={""}>
      <ModalHeader toggle={onAbort}>Code promo : {code.offer_name}</ModalHeader>
      <ModalBody className={"d-flex flex-column justify-content-center align-items-center"}>
        <Row>
          <Col>
            Voici le code généré
          </Col>
        </Row>
        <Row>
          <Col>
             <span className='offer-code-modal'>{code.code}</span>
             <i onClick={copyCode} className="bx bx-copy-alt ml-3" aria-hidden={true} style={{ fontSize: "1.5em", cursor: "pointer" }} data-toggle="tooltip" data-placement="top" title="Copier le code dans le presse papier"></i>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default OfferCodeGenerateModal;