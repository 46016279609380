import {
  GET_FREE_ITEMS_SAVE,
  GET_FREE_ITEMS_SAVE_SUCCESS,
  CREATE_FREE_ITEM_SAVE,
  CREATE_FREE_ITEM_SAVE_SUCCESS,
  DELETE_FREE_FIELD,
  DELETE_FREE_FIELD_SUCCESS,
  FREE_FIELDS_API_ERROR,
} from "./actionTypes";

const initialState = {
  free_items: [],
  error: false,
  loading: false,
  pagination: {
    current_page: 0,
    current_page_size: 0,
    current_count: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
};

const freeFields = (state = initialState, action) => {
  switch (action.type) {
    case GET_FREE_ITEMS_SAVE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FREE_ITEMS_SAVE_SUCCESS:
      state = {
        ...state,
        free_items: action.payload.data.objects
          ? action.payload.data.objects
          : action.payload.data,
        pagination: action.payload.data.pagination,
        loading: false,
        error: false,
      };
      break;
    case CREATE_FREE_ITEM_SAVE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_FREE_ITEM_SAVE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case DELETE_FREE_FIELD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_FREE_FIELD_SUCCESS:
      state = {
        ...state,
        free_fields:
          state.free_fields === undefined || state.free_fields === null
            ? []
            : state.free_fields.filter((e) => e.id !== action.payload.data.id),
        loading: false,
        error: false,
      };
      break;
    case FREE_FIELDS_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default freeFields;
