import {History} from "history";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody, ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Row
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FlashMessage from "../../components/Common/FlashMessage";
import {useEffect, useState} from "react";
import { match } from 'react-router';
import {V2} from "../../api";
import GlobalLoader from "../../components/Common/GlobalLoader";
import InterventionFolder from "../../models/intervention_folder";
import InterventionDetailClientTab from "./Components/Tabs/intervention-detail-client-tab";
import InterventionDetailInterventionTab from "./Components/Tabs/intervention-detail-intervention-tab";
import InterventionDetailLendTab from "./Components/Tabs/intervention-detail-lend-tab";
import {parseQuery, setQuery} from "../../helpers/helper";

interface InterventionFolderDetailsProps {
  match: match<{ id: string }>,
  history: History
}

export default function InterventionFolderDetails({
  match,
  history
}: InterventionFolderDetailsProps){
  const query = parseQuery();
  const [intervention, setIntervention] = useState<InterventionFolder|null>(null);
  const [translatedType, setTranslatedType] = useState<{sing:string,plur:string,title:string}>({sing:"",plur:"",title:""});
  const [filters, setFilters] = useState({tab: query.tab ? parseInt(query.tab as string) : 0 });
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const enum tabs{INTERVENTION, CLIENT, LEND}

  useEffect(() => {
    V2.interventions.get(parseInt(match.params.id), {groups:["request_info"]}).then(({data}) => {
      setIntervention(data);
      setTranslatedType(data.intervention_folder_type === "sav" ? {sing:"SAV",plur:"SAV",title:"du SAV"} : {sing:"Entretien",plur:"Entretiens", title:"de l'entretien"});
    })
  }, [match.params.id]);


  useEffect(() => {
    setQuery(filters, history, true)
  }, [filters, history]);

  function handleDelete(){
    if(!intervention?.id) {
      setDeleteModalShow(false);
      return;
    }
    V2.interventions.delete(intervention?.id, {}).then(() => {
      history.push(`/interventions/${intervention.intervention_folder_type}/list`);
    })
  }

  return(
    <>
      {intervention?(
        <>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs
                title={translatedType.plur}
                breadcrumbTitle="Suivi des interventions"
                breadcrumbItem={translatedType.plur}
              />
              <FlashMessage/>
              <Row>
                <Col>
                  <Card className={"p-3"}>
                    <CardTitle className="mb-0">
                      <Row className="pr-2 pl-2 d-flex">
                        <Col>Détails {translatedType.title}</Col>
                      </Row>
                    </CardTitle>
                    <ul className="nav nav-tabs nav-tabs-custom my-2">
                      <NavItem>
                        <NavLink className={filters.tab === tabs.INTERVENTION ? "active " : ""}
                                 onClick={() => {setFilters({tab:tabs.INTERVENTION});}}
                        >Intervention</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink className={filters.tab === tabs.CLIENT ? "active" : ""}
                                 onClick={() => {setFilters({tab:tabs.CLIENT});}}
                        >Client</NavLink>
                      </NavItem>
                      {intervention.intervention_folder_type === "sav" ?
                        <NavItem>
                          <NavLink className={(filters.tab === tabs.LEND ? "active" : "") + " d-flex align-items-center"}
                                   onClick={() => {setFilters({tab:tabs.LEND});}}
                          >Prêt</NavLink>
                        </NavItem>
                        :null}
                    </ul>
                    <div className={"mb-5"}>
                      {filters.tab === tabs.CLIENT ?
                        <InterventionDetailClientTab interventionFolder={intervention}/>
                        : null}
                      {filters.tab === tabs.INTERVENTION ?
                        <InterventionDetailInterventionTab interventionFolder={intervention}/>
                        : null}
                      {filters.tab === tabs.LEND ?
                        <InterventionDetailLendTab interventionFolder={intervention}/>
                        : null}
                    </div>
                    <div className="d-flex justify-content-end py-1 px-3 bg-gray-300 mb-n3 mx-n3 mt-3 rounded-bottom">
                      <button className="btn bg-transparent shadow-none d-flex align-items-center text-link mr-4" type="button"
                              onClick={()=>history.push(`/requests/customers/${intervention?.client?.id}/interventions/edit/${intervention.id}/${intervention?.intervention_folder_type}`)}
                      >
                        <i className="bx bx-pencil mr-1"/>Modifier
                      </button>
                      <button className="btn bg-transparent shadow-none d-flex align-items-center text-link mr-4" type="button"
                              onClick={()=>setDeleteModalShow(true)}
                      >
                        <i className="bx bx-trash mr-1"/>Supprimer
                      </button>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>

          <Modal isOpen={deleteModalShow} toggle={() => setDeleteModalShow(false)} className={""}>
            <ModalHeader toggle={() => setDeleteModalShow(false)}>Supprimer {translatedType.sing}?</ModalHeader>
            <ModalBody>
              <div>
                <h2>Attention</h2>
                <p>Cette action est irréversible</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button type="button" className={"btn btn-secondary"} onClick={() => setDeleteModalShow(false)}>Annuler</Button>
              <Button type="button" className={"btn btn-green"} onClick={handleDelete}>Confirmer</Button>
            </ModalFooter>
          </Modal>
        </>
      ):<GlobalLoader/>}
    </>
  );
}