import React from 'react';
import {Link} from "react-router-dom";

const InvoiceDisplayNameComponent = ({
  row,
  isDepositInvoice,
  fastInvoiceEditModalRef,

                                     }) => {

  return (
    <div>
        <h5 className="font-size-14 mb-1">
            {!isDepositInvoice(row) && !row.is_refund ? (
              <Link
                to={row.is_draft ? `/order/edit/${row.order.id}/${row.id}` : `/invoice/${row.id}/detail`}
                className={
                    row.number != null
                      ? "table-text-black"
                      : "table-text-important"
                }
                data-toggle="tooltip"
                data-placement="top"
                title="Modifier la facture"
              >
                  {row.number || "Brouillon"}
              </Link>
            ) : (row.refund_on && !row.is_free_order) ||
            isDepositInvoice(row) ? (
              <p
                className={`mb-0 ${
                  row.number != null
                    ? "table-text-black"
                    : "table-text-important"
                }`}
                data-toggle="tooltip"
                data-placement="top"
                title="Modifier la facture"
                style={{
                    cursor: "pointer"
                }}
                onClick={() => fastInvoiceEditModalRef.current.open(row)}
              >
                  {row.number || "Brouillon"}
              </p>
            ) : (
              <Link
                to={`/refund/edit/${row.order.id}/${row.id}`}
                className={
                    row.number != null
                      ? "table-text-black"
                      : "table-text-important"
                }
                data-toggle="tooltip"
                data-placement="top"
                title="Modifier l'avoir"
              >
                  {row.number || "Brouillon"}
              </Link>
            )}
        </h5>
        <p className="mb-0">
            {row.is_refund
              ? `Avoir${row.refund_on ? ` sur N°${row.refund_on}` : ""}`
              : !isDepositInvoice(row)
                ? "Facture de solde"
                : "Facture d'acompte"}
        </p>
    </div>
  );
}

export default InvoiceDisplayNameComponent;