import React, {useState} from 'react';
import {Alert, Badge} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import PaymentModal from "../../Invoices/Modals/PaymentModal";
import {Link} from "react-router-dom";
import {parseDate} from "../../../helpers/subscriptions_helpers";
import cellEditFactory, {Type} from "react-bootstrap-table2-editor";
import {payments} from "../../../api";
import {parseInputToFloat, payment_state_options} from "../../../helpers/helper";
import ValidationModal from "../../Quotes/Modals/ValidationModal";

const DepositPaymentsTable = ({
  invoice,
  getProject
                              }) => {

  const [paymentModal, setPaymentModal] = useState(false);
  const [toDeletePayment, setToDeletePayment] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handlePaymentDelete = (id) => {
    payments.delete(id)
      .then(r => {
        getProject();
        setToDeletePayment(false);
      })
  }

  const columns = [
    {
      dataField: 'paid_at',
      text: 'Date de paiement',
      editor: {
        type: Type.DATE,
      },
      formatter: cell => parseDate(cell)
    },
    {
      dataField: 'amount',
      text: 'Montant',
      editor: {
        type: Type.TEXT,
      },
      formatter: cell => <div>{parseFloat(cell).toFixed(2)} €</div>
    },
    {
      dataField: 'state',
      text: 'État',
      editor: {
        type: Type.SELECT,
        options: payment_state_options,
      },
      formatter: cell => <Badge pill color={cell === 'reçu' ? 'warning' : 'success'}>{cell}</Badge>
    },
    {
      dataField: 'action',
      editable: false,
      formatter: (cell, row) => (
        <i
          onClick={() => setToDeletePayment(row.id)}
          data-toggle="tooltip"
          data-placement="top"
          title="Supprimer la facture"
          className={`btn-icon-delete fas fa-trash-alt`}
        />
      )
    }
  ];

  const cellEdit = cellEditFactory({
    mode: 'click',
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row, column) => {

      if(newValue !== oldValue) {
        if(column.dataField === 'amount') {
          if(!newValue || isNaN(newValue)) {
            row.amount = oldValue;
          } else {
            if((invoice.total_ttc - invoice.paid_total_ttc - parseFloat(newValue) + oldValue) < 0) {
              row.amount = oldValue;
              setErrorMessage(`Le restant à payer est ${parseFloat(invoice.total_ttc - invoice.paid_total_ttc).toFixed(2)} €`)
            } else {
              row.amount = parseInputToFloat(newValue);
              handlePaymentUpdate(row);
            }
          }
        }else if(column.dataField === 'paid_at'){
          if(new Date(oldValue).toString() !== new Date(newValue).toString())
            handlePaymentUpdate(row);
        }else{
          handlePaymentUpdate(row);
        }


      }
    }
  })

  const handlePaymentUpdate = (payment) => {
    payments.update(payment.id, invoice.id,{
      paid_at: new Date(payment.paid_at),
      amount: payment.amount,
      state: payment.state,
    })
      .then(r => {
        getProject()
      })
  }

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between invoices-container mb-3'>
        <div>Paiements :</div>
        { invoice.total_ttc === invoice.paid_total_ttc ?
          <div className="d-flex align-items-center text-theme-color-2">Tout a été payé</div>
          :
          <Link
            to="#"
            className="d-flex align-items-center text-theme-color-2"
            onClick={() => setPaymentModal(true)}
          >
            <i className="bx bx-plus-circle mr-1>"/> Ajouter
          </Link>
        }
      </div>
      { invoice.payments.length === 0 ?
        <div className='d-flex justify-content-center'><p>Il n'y a aucun paiements</p></div>
        :
        <BootstrapTable
          keyField='id'
          columns={columns}
          data={invoice.payments}
          cellEdit={cellEdit}
        />}
      <Alert
        color="danger"
        isOpen={errorMessage}
        toggle={() => setErrorMessage(false)}
      >
        {errorMessage}
      </Alert>
      <PaymentModal
        isOpen={paymentModal}
        toggle={() => setPaymentModal(!paymentModal)}
        invoice={invoice}
        thenExecutedFunction={getProject}
      />
      <ValidationModal
        isOpen={toDeletePayment}
        toggler={() => setToDeletePayment(!toDeletePayment)}
        title='Paiement - suppression'
        message={<React.Fragment><h1>Attention</h1><p>Voulez-vous supprimer ce paiement ?</p></React.Fragment>}
        btnValidText='Supprimer'
        btnBackText='Annuler'
        handleSubmit={() => handlePaymentDelete(toDeletePayment)}
      />
    </React.Fragment>
  );
}

export default DepositPaymentsTable;