import React, { useCallback, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  CardBody,
  CardTitle,
  Card,
  Collapse,
} from "reactstrap";

import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import GlobalLoader from "../../../components/Common/GlobalLoader";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import ActionButtons from "./ActionButtons/ActionButtons";

// Helpers
import { getDateWithShortFormat } from "../../../helpers/helper";

import {
  getNotifications,
  updateNotification,
  deleteNotification,
} from "../../../store/actions";

import { setQuery, parseQuery } from "../../../helpers/helper";
import { onTableChange } from "../../../helpers/table_helper";
import NotificationFilter from "./Filters/NotificationFilter";

const NotificationsList = (props) => {
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [filtersCollapseIsOpen, setFiltersCollapseIsOpen] = useState(false);

  // Filters
  const query = parseQuery();

  const [filters, setFilters] = useState({
    sort_by: query.sort_by || '',
    sort_dir: query.sort_dir || '',
    page: query.page || 1,
    page_size: query.page_size || 25,
    client_id: query.client_id || '',
    user_id: query.user_id || '',
    priority: query.priority || '',
    state: query.state || '',
  });

  const applyFilter = useCallback(() => {
    props.getNotifications.call(undefined, {
      page: filters.page,
      page_size: filters.page_size,
      sort: `${filters.sort_by} ${filters.sort_dir}`.trim(), // trim => remove space when filters is empty
      client_id: filters.client_id,
      user_to_id: filters.user_id,
      user_from_id: filters.user_id,
      priority: filters.priority,
      state: filters.state,
    }); // Update interface with new filter
  }, [props.getNotifications, filters]);

  useEffect(() => {
    setQuery(filters, props.history); // Update query in path

    applyFilter();
  }, [filters, props.history, applyFilter]);

  function resetFilters() {
    setFilters({
      sort_by: '',
      sort_dir: '',
      page: 1,
      page_size: 25,
      client_id: '',
      user_id: '',
      priority: '',
      state: '',
      archived: '',
    });
  }

  // Liste des colonnes qui seront affichées dans le tableau
  const columns = [
    {
      dataField: "updated_at",
      text: "Date",
      formatter: (cell, row) => getDateWithShortFormat(row.updated_at),
      classes: "align-middle"
    },
    {
      dataField: "state",
      text: "État",
      formatter: (cell, row) => row.state || "NR",
      classes: "align-middle"
    },
    {
      dataField: "priority",
      text: "Priorité",
      formatter: (cell, row) => <div>
        <i className={"mr-1 fa " + (row.priority === "Haut" ? "fa-minus text-warning" : (row.priority === "Normal" ? "fa-arrow-down text-blue" : "fa-arrow-up text-danger"))}></i>
        {row.priority || "NR"}
      </div>,
      classes: "align-middle"
    },
    {
      dataField: "client",
      text: "Client",
      formatter: (cell, row) =>
        row.client
          ? 
          <Link to={`/customer/detail/${row.client.id}`}>
            {
              row.client.client_type === "Professionnel"
                ? row.client.company_name
                : `${row.client.civility} ${row.client.lastname} ${row.client.firstname}`
            }
          </Link> 
          : "NR",
          classes: "align-middle"
    },
    {
      dataField: "title",
      text: "Titre",
      formatter: (cell, row) => row.title || "NR",
      classes: "align-middle"
    },
    {
      dataField: "user_from",
      text: "Commercial",
      formatter: (cell, row) =>
        row.user_to.id === currentUser?.id
          ? <Link to={`/collaborator/detail/${row.user_from.id}`}>{row.user_from.firstname} {row.user_from.lastname}</Link>
          : <Link to={`/collaborator/detail/${row.user_to.id}`}>{row.user_to.firstname} {row.user_to.lastname}</Link>,
      classes: "align-middle"
    },
    {
      dataField: "id",
      text: "",
      formatter: (cell, row) => (
        <ActionButtons
          history={props.history}
          row={row}
          updateNotification={props.updateNotification}
          deleteNotification={props.deleteNotification}
        />
      ),
      classes: "text-right"
    },
  ];

  return (
    <React.Fragment>
      {props.loading || !props.pagination ? (
        <GlobalLoader />
      ) : (
        <div className="page-content">
          <Container fluid className="notifications-container">
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Relances"
              breadcrumbItem="Toutes les relances"
            />
            <Row>
              <Col lg="12">
                <Row>
                  <Col xs="12">
                    <Row>
                      <Col xs="12" className="d-flex justify-content-start">
                        <span
                          className="d-flex text-link mb-3"
                          onClick={() => {
                            setFiltersCollapseIsOpen(!filtersCollapseIsOpen);
                          }} 
                        >
                          <i className="fas fa-filter mr-1"></i>Filtrer
                        </span>
                        <span
                          className="d-flex text-link text-color-unset mb-3"
                          onClick={resetFilters} 
                        >
                          <i className="fas fa-times mr-1"></i> Vider les
                          filtres
                        </span>
                      </Col>
                      <Col xs="12">
                        <Collapse isOpen={filtersCollapseIsOpen}>
                          <NotificationFilter
                            filters={filters}
                            setFilters={setFilters}
                          />
                        </Collapse>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">
                      <Row className="pr-2 pl-2 d-flex">
                        <Col>Toutes les relances</Col>
                        <Col className="d-flex justify-content-end">
                          <Link
                            to="/relance/add"
                            className="d-flex align-items-center text-theme-color-2"
                          >
                            <i className="bx bx-plus-circle mr-1"></i> Créer une
                            relance
                          </Link>
                        </Col>
                      </Row>
                    </CardTitle>
                    <BootstrapTable
                      keyField="id"
                      data={props.notifications}
                      columns={columns}
                      defaultSortDirection="asc"
                      remote={{ pagination: true }}
                      onTableChange={onTableChange.bind({setFilters, filters})}
                      pagination={paginationFactory({
                        page: props.pagination.current_page,
                        totalSize: props.pagination.total_count,
                        sizePerPage: props.pagination.current_page_size,
                        sizePerPageList: [
                          { text: "Afficher 10 éléments", value: 10 },
                          { text: "Afficher 25 éléments", value: 25 },
                          { text: "Afficher 50 éléments", value: 50 },
                          { text: "Afficher 75 éléments", value: 75 },
                          { text: "Afficher 100 éléments", value: 100 },
                        ],
                      })}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { notifications, pagination, loading } = state.Notifications;
  return { notifications, pagination, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    getNotifications,
    updateNotification,
    deleteNotification,
  })(NotificationsList)
);
