import axios from "axios";
import { LOGIN_USER, LOGOUT_USER } from "../../store/auth/login/actionTypes";

/**
 * On login save the JWT in localStorage and set it as a axios header
 * On logout remove localStorage value and axios header
 */
export const localStorageMiddleware = (_store) => (next) => (action) => {
  try {
    if (action.type === `${LOGIN_USER}`) {
      if (!action.error) {
      }
    } else if (action.type === `${LOGOUT_USER}`) {
      // localStorage.clear();
      delete axios.defaults.headers["Authorization"];
    }
  } catch (err) {
    console.error(err);
  }

  next(action);
};
