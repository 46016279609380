import React from 'react';

import { Formik } from 'formik';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { subscriptions } from "../../../api";
import { connect } from "react-redux";
import { parseDate } from "../../../helpers/subscriptions_helpers";
import { getSubscription } from "../../../store/subscriptions/actions";
import { addFlashMessage, ImportantEnum } from "../../../components/Common/FlashMessage.tsx";

const SuspendSubscriptionModal = ({
  isOpen,
  toggleModal,
  subscription,
  getSubscription,
}) => {

  const initValues = {
    suspended_at: '',
  }

  const handleSubmit = (values) => {
    subscriptions.suspendAt(subscription.id, values.suspended_at)
      .then(r => {
        toggleModal();
        addFlashMessage(ImportantEnum.SUCCESS, values.suspended_at ? `L'abonnement sera résilié le ${parseDate(values.suspended_at)}` : 'La résiliation de l\'abonnement a été annulée');
        getSubscription(subscription.id);
      })
      .catch(err => console.error(err));
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
    >
      <Formik
        initialValues={initValues}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleChange, values }) => (
          <div>
            <ModalHeader toggle={() => toggleModal()}>
              Abonnement - Résiliation
            </ModalHeader>
            <ModalBody>
              <div>
                <h1>Attention</h1>
                <p>{subscription.suspended_at ? 'Êtes-vous sûr de vouloir annuler la résiliation ?' : 'Pour valider la résiliation, veuillez entrer la date suivante'}</p>
              </div>
              {!!!subscription.suspended_at && <FormGroup>
                <Label for='suspended_at'>Date de résiliation</Label>
                <Input
                  name='suspended_at'
                  id='suspended_at'
                  type='date'
                  onChange={handleChange}
                  defaultValue={values.suspended_at}
                />
              </FormGroup>}
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => toggleModal()}>Annuler</Button>
              <Button type='submit' onClick={() => handleSubmit(values)} className='btn-green'>{subscription.suspended_at ? 'Annuler la résiliation' : 'Résilier'}</Button>
            </ModalFooter>
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default connect(null, {
  getSubscription,
})(SuspendSubscriptionModal);