import React from 'react';
import { onTableChange } from "../../../helpers/table_helper";
import BootstrapTable from "react-bootstrap-table-next";
import InvoiceDisplayNameComponent from "./InvoiceDisplayNameComponent";
import { getDateWithShortFormat, parseCustomer } from "../../../helpers/helper";
import InvoiceActionsComponent from "./InvoiceActionsComponent";
import {Badge} from "reactstrap";
import {computePaymentStateColor} from "../../../helpers/quotes_invoices_helper";

const InvoicesGeneralTable = ({
  invoicesList,
  setFilters,
  filters,
  tablePagination,
  selectRow,
  isDepositInvoiceWithInvoice,
  toggleModalValidate,
  toggleModalDelete,
  toggleModalRefund,
  isDepositInvoice,
  fastInvoiceEditModalRef,
  removeCeePrime,
  projectConvert,
  history
}) => {

  const generalColumns = [
    {
      dataField: "number",
      text: "N° facture",
      sort: true,
      formatter: (cell, row) => (
        <InvoiceDisplayNameComponent
          row={row}
          isDepositInvoice={isDepositInvoice}
          fastInvoiceEditModalRef={fastInvoiceEditModalRef}
        />
      ),
    },
    {
      dataField: "invoice_date",
      text: "Date facture",
      formatter: (cell, row) => getDateWithShortFormat(row.invoice_date),
    },
    {
      dataField: "client",
      text: "Client",
      formatter: (cell, row) => (
        <React.Fragment>
          <h5 className="font-size-14 mb-1 table-text-black">
            {parseCustomer(row.order.client)}
          </h5>
          <p className="mb-0">
            {row.order.client.email === "" ? "Pas d'email" : null}
          </p>
        </React.Fragment>
      ),
    },
    {
      dataField: 'payment_state',
      text: 'État',
      formatter: cell => <Badge pill color={computePaymentStateColor(cell.name)}>{cell.label}</Badge>
    },
    {
      dataField: "mail_send_at",
      text: "Envoyé le",
      formatter: (cell, row) =>
        row.mail_send_at
          ? getDateWithShortFormat(row.mail_send_at)
          : "Non envoyé",
    },
    {
      dataField: "user",
      text: "Créé par",
      formatter: (cell, row) =>
        row.user ? `${row.user.firstname} ${row.user.lastname}` : null,
    },
    {
      dataField: "total_price_ht",
      text: "Total HT",
      formatter: (cell, row) => removeCeePrime(row, row.total_ht),
    },
    {
      dataField: "total_vat_value",
      text: "Total TVA",
      formatter: (cell, row) => `${parseFloat(row.total_vats).toFixed(2)} €`,
    },
    {
      dataField: "total_price_ttc",
      text: "Total TTC",
      formatter: (cell, row) => removeCeePrime(row, row.total_ttc),
    },
    {
      dataField: "action",
      text: '',
      formatter: (cell, row) => {
        return <InvoiceActionsComponent
          history={history}
          projectConvert={projectConvert}
          row={row}
          toggleModalValidate={toggleModalValidate}
          toggleModalDelete={toggleModalDelete}
          toggleModalRefund={toggleModalRefund}
          isDepositInvoiceWithInvoice={isDepositInvoiceWithInvoice}
        />
      },
      classes: "text-right"
    },
  ];

  return (
    <BootstrapTable
      classes="table-centered"
      keyField="id"
      data={invoicesList}
      columns={generalColumns}
      defaultSortDirection="asc"
      remote={{ pagination: true, sort: true }}
      onTableChange={onTableChange.bind({ setFilters, filters })}
      pagination={tablePagination}
      selectRow={selectRow}
    />
  );
}

export default InvoicesGeneralTable;