import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_INVOICES,
  GET_INVOICE,
  GET_PAYMENTS_DETAILS,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  DELETE_INVOICE,
  VALIDATE_INVOICE,
  GENERATE_ZIP,
  SEND_INVOICE_EMAILS,
  MIGRATE_ORDER_FROM_INVOICE
} from "./actionTypes";
import {
  getInvoicesSuccess,
  getInvoiceSuccess,
  createInvoiceSuccess,
  updateInvoiceSuccess,
  deleteInvoiceSuccess,
  validateInvoiceSuccess,
  generateZipSuccess,
  sendInvoiceEmailsSuccess,
  invoicesApiError,
  getPaymentsDetailsSuccess,
  migrateOrderFromInvoiceSuccess
} from "./actions";

import { invoices, projects } from "../../api";

function* getInvoices({ payload: { query } }) {
  try {
    const response = yield call(invoices.getAll, query);
    yield put(getInvoicesSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        invoicesApiError(
          "Vous n'êtes pas autorisé à accéder à la liste des factures"
        )
      );
    } else if (error.response.data !== undefined) {
      yield put(invoicesApiError(error.response.data.message));
    } else {
      yield put(invoicesApiError(error.message));
    }
  }
}

function* getInvoice({ payload: { id } }) {
  try {
    const response = yield call(invoices.getOne, id);
    yield put(getInvoiceSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        invoicesApiError(
          "Vous n'êtes pas autorisé à accéder aux informations de cette facture"
        )
      );
    } else if (error.response.status === 404) {
      yield put(invoicesApiError("La facture n'a pas été trouvée"));
    } else if (error.response.data !== undefined) {
      yield put(invoicesApiError(error.response.data.message));
    } else {
      yield put(invoicesApiError(error.message));
    }
  }
}

function* getPaymentsDetails({payload: {id}}) {
  try {
    const response = yield call(invoices.getPaymentDetails, id);
    yield put(getPaymentsDetailsSuccess(response));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        invoicesApiError(
          "Vous n'êtes pas autorisé à accéder aux informations de cette facture"
        )
      );
    } else if (error.response.status === 404) {
      yield put(invoicesApiError("La facture n'a pas été trouvée"));
    } else if (error.response.data !== undefined) {
      yield put(invoicesApiError(error.response.data.message));
    } else {
      yield put(invoicesApiError(error.message));
    }
  }
}

function* createInvoice({ payload: { invoice, history } }) {
  try {
    const response = yield call(invoices.create, invoice);
    yield put(createInvoiceSuccess(response));
    history.push("/invoices");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        invoicesApiError("Vous n'êtes pas autorisé à ajouter une facture")
      );
    } else if (error.response.data !== undefined) {
      yield put(invoicesApiError(error.response.data.message));
    } else {
      yield put(invoicesApiError(error.message));
    }
  }
}

function* updateInvoice({ payload: { id, invoice, history } }) {
  try {
    const response = yield call(invoices.update, id, invoice);
    yield put(updateInvoiceSuccess(response));
    history.push("/invoices");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        invoicesApiError("Vous n'êtes pas autorisé à modifier cette facture")
      );
    } else if (error.response.status === 404) {
      yield put(invoicesApiError("Le facture n'a pas été trouvée"));
    } else if (error.response.data !== undefined) {
      yield put(invoicesApiError(error.response.data.message));
    } else {
      yield put(invoicesApiError(error.message));
    }
  }
}

function* deleteInvoice({ payload: { id, history } }) {
  try {
    const response = yield call(invoices.delete, id);
    yield put(deleteInvoiceSuccess(response));
    const responseGetAll = yield call(invoices.getAll, {});
    yield put(getInvoicesSuccess(responseGetAll));
    history.push("/invoices");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        invoicesApiError("Vous n'êtes pas autorisé à supprimer cette facture")
      );
    } else if (error.response.data !== undefined) {
      yield put(invoicesApiError(error.response.data.message));
    } else {
      yield put(invoicesApiError(error.message));
    }
  }
}

function* validateInvoice({ payload: { id, history, custom_invoice_date } }) {
  try {
    let values = {
      is_draft: false,
    };
    if (custom_invoice_date) values.custom_invoice_date = custom_invoice_date;
    const response = yield call(invoices.validateInvoice, id, values);
    yield put(validateInvoiceSuccess(response));
    history.push("/invoices");
  } catch (error) {
    if (error.response.status === 401) {
      yield put(
        invoicesApiError("Vous n'êtes pas autorisé à supprimer cette facture")
      );
    } else if (error.response.data !== undefined) {
      yield put(invoicesApiError(error.response.data.message));
    } else {
      yield put(invoicesApiError(error.message));
    }
  }
}

function* generateZip({ payload: { list } }) {
  try {
    const response = yield call(invoices.generate_zip, list);

    const fileName = "Factures.zip";
    const blob = new Blob([response.data], {
      type: "application/zip",
    });

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display:none";
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
    yield put(generateZipSuccess(response));
  } catch (error) {
    if (error.response.data !== undefined) {
      yield put(invoicesApiError(error.response.data.message));
    } else {
      yield put(invoicesApiError(error.message));
    }
  }
}

function* sendInvoiceEmails({ payload: { list, query } }) {
  try {
    const response = yield call(invoices.send_invoice_emails, list);

    yield put(sendInvoiceEmailsSuccess(response));

    const responseInvoices = yield call(invoices.getAll, query);

    yield put(getInvoicesSuccess(responseInvoices));
  } catch (error) {
    if (error.response.data !== undefined) {
      yield put(invoicesApiError(error.response.data.message));
    } else {
      yield put(invoicesApiError(error.message));
    }
  }
}

function* migrateOrderFromInvoice({ payload: { values } }) {
  try {
    const response = yield call(projects.migrateOrder, values);
    yield put(migrateOrderFromInvoiceSuccess(response));
  } catch (error) {
    console.log(error)
    if (error.response.status === 401) {
      yield put(
        invoicesApiError(
          "Vous n'êtes pas autorisé à accéder aux informations de ce projet."
        )
      );
    } else if (error.response.status === 404) {
      yield put(invoicesApiError("Le projet n'a pas été trouvé"));
    } else if (error.response.status !== undefined) {
      yield put(invoicesApiError(error.response.data.message));
    } else {
      yield put(invoicesApiError(error.message));
    }
  }
}

export function* watchGetInvoices() {
  yield takeEvery(GET_INVOICES, getInvoices);
}

export function* watchGetInvoice() {
  yield takeEvery(GET_INVOICE, getInvoice);
}

export function* watchGetPaymentsDetails() {
  yield takeEvery(GET_PAYMENTS_DETAILS, getPaymentsDetails);
}

export function* watchCreateInvoice() {
  yield takeEvery(CREATE_INVOICE, createInvoice);
}

export function* watchUpdateInvoice() {
  yield takeEvery(UPDATE_INVOICE, updateInvoice);
}

export function* watchDeleteInvoice() {
  yield takeEvery(DELETE_INVOICE, deleteInvoice);
}

export function* watchValidateInvoice() {
  yield takeEvery(VALIDATE_INVOICE, validateInvoice);
}

export function* watchGenerateZip() {
  yield takeEvery(GENERATE_ZIP, generateZip);
}

export function* watchSendInvoiceEmails() {
  yield takeEvery(SEND_INVOICE_EMAILS, sendInvoiceEmails);
}

export function* watchMigrateOrderFromInvoice() {
  yield takeEvery(MIGRATE_ORDER_FROM_INVOICE, migrateOrderFromInvoice);
}

function* invoicesSaga() {
  yield all([
    fork(watchGetInvoices),
    fork(watchGetInvoice),
    fork(watchGetPaymentsDetails),
    fork(watchCreateInvoice),
    fork(watchUpdateInvoice),
    fork(watchDeleteInvoice),
    fork(watchValidateInvoice),
    fork(watchGenerateZip),
    fork(watchSendInvoiceEmails),
    fork(watchMigrateOrderFromInvoice),
  ]);
}

export default invoicesSaga;
