import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { GET_ABONNEMENT_STATES } from "./actionTypes";

import {
  getAbonnementStatesSuccess,
  abonnementStatesApiError,
} from "./actions";

import { abonnementStates } from "../../api";

function* getAbonnementStates({ payload: { query } }) {
  try {
    const response = yield call(abonnementStates.getAll, query);
    yield put(getAbonnementStatesSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          abonnementStatesApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des états d'un contrat d'entretien"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(abonnementStatesApiError(error.response.data.message));
      }
    } else {
      yield put(abonnementStatesApiError(error.message));
    }
  }
}

export function* watchGetAbonnementStates() {
  yield takeEvery(GET_ABONNEMENT_STATES, getAbonnementStates);
}

function* abonnementStatesSaga() {
  yield all([fork(watchGetAbonnementStates)]);
}

export default abonnementStatesSaga;
