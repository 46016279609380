import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import {getPaymentsDetails} from "../../store/actions";
import InvoiceDetailComponent from "./Components/InvoiceDetailComponent";
import PaymentTableComponent from "./Components/PaymentTableComponent";

const InvoiceDetail = (props) => {
  useEffect(() => {
    props.getPaymentsDetails.call(undefined,parseInt(window.location.pathname.split("/")[2], 10));
  }, [props.getPaymentsDetails]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="invoices-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs link='/invoices' title="Factures" breadcrumbItem="Détails factures" />
          {props.invoice ? (
            <React.Fragment>
              <InvoiceDetailComponent
                invoice={props.invoice}
              />
              {
                !props.invoice.is_refund ?
                <PaymentTableComponent
                  invoice={props.invoice}
                /> : ''
              }
            </React.Fragment>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { invoice } = state.Invoices;
  return {
    invoice,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPaymentsDetails,
  })(InvoiceDetail)
);
