import React from "react"
import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import { Formik, Form } from "formik";
import AsyncAutoComplete2 from "../../../components/Common/AsyncAutoComplete2";
import { promiseUsersByQueryOptions } from "../../../helpers/autocomplete_promise_options";
import { Link } from "react-router-dom";

interface UsersFormValues {
  users_ids: number[]
}

interface UsersFormProps {
  initValues: UsersFormValues,
  handleSubmit: (values: UsersFormValues) => void
}

// TODO: To support mutliple users in project detail
// -> Replace onSubmit={(values) => handleSubmit({users_ids: [values.users_ids as any]})} by onSubmit={handleSubmit}
// -> Change label and button label
// -> Change multiple={false} to multiple={true}

const UsersForm = ({ initValues, handleSubmit }: UsersFormProps) => {

  return (
    <Formik
      initialValues={initValues}
      enableReinitialize
      onSubmit={(values) => handleSubmit({users_ids: [values.users_ids as any]})}
    >
      {() => (
        <Form>
          <FormGroup>
            <Label for='payment_state'>Commercial</Label>
            <AsyncAutoComplete2
              name='users_ids'
              id='users_ids'
              loadOptions={promiseUsersByQueryOptions}
              multiple={false}
            />
          </FormGroup>
          <Row className="justify-content-end mt-4">
            <Col xs="12" className="text-center">
              <Link to={`/collaborator/detail/${initValues.users_ids[0]}`} className="btn btn-secondary btn-sm mr-2">
                Voir le commercial selectionné
              </Link>
              <Button type="submit" className="btn btn-green btn-sm">
                Mettre à jour le commercial
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default UsersForm;