import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Alert, Card, CardBody, CardTitle, Col, Collapse, Container, Row, UncontrolledAlert, } from "reactstrap";

// Bootstrap Table

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

// Redux
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import loader
import GlobalLoader from "../../components/Common/GlobalLoader";

// Helpers
import { computeOptions, parseQuery, setQuery } from "../../helpers/helper";
import { listUsersHaveMail, removeFlashMessage } from "../../helpers/lists_helpers";

import ProjectConvertModal from "../Quotes/Modals/ProjectConvertModal";

// API

// actions
import {
  deleteInvoice,
  generateZip,
  getInvoices,
  invoicesApiError,
  sendInvoiceEmails,
  validateInvoice,
  migrateOrderFromInvoice
} from "../../store/actions";

import FastInvoiceEditModal from "./FastInvoiceEditModal";
import InvoiceFilter from "./Filters/InvoiceFilter";
import Select from "react-select";
import InvoicesTableComponent from "./Components/InvoicesTableComponent";
import MailModal from "./Modals/MailModal";
import ComptaExportModal from "./Modals/comptaExportModal";
import PaymentsExportModal from "./Modals/paymentsExportModal";
import CheckPaymentsModal from "./Modals/checkPaymentsModal";
import { parseCustomerName } from "../../helpers/customer_helper";
import ExportInvoicesModal from "./Modals/ExportInvoicesModal";

export const displayTypes = {
  GENERAL: 'Général',
  TRACE: 'Suivi'
}

const InvoicesList = (props) => {
  const fastInvoiceEditModalRef = useRef(null);
  const projectConvertModalRef = useRef(null);

  const [flash_message, setFlash_message] = useState(false);
  const [filtersCollapseIsOpen, setFiltersCollapseIsOpen] = useState(false);
  const [invoicesIdList, setInvoicesIdList] = useState(Array(0));
  // if allInvoicesSelected if true then call API with invoice_ids = all to call action
  // for all invoice in BD with current filters
  const [allInvoicesSelected, setAllInvoicesSelected] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const [showComptaExportsModal, setShowComptaExportsModal] = useState(false);
  const [showPaymentsExportsModal, setShowPaymentsExportsModal] = useState(false);
  const [showCheckPaymentsModal, setShowCheckPaymentsModal] = useState(false);
  const [showExportInvoicesModal, setShowExportInvoicesModal] = useState(false);
  const [isListUsersHaveMail, setIsListUsersHaveMail] = useState(true);
  const [allListUsersHaveMail, setAllListUsersHaveMail] = useState(Array(0));

  const [displayTypeOptions] = useState(computeOptions(displayTypes));

  // Filters
  const query = parseQuery();

  /**
   * Check if the displayType in query is correct and return it
   * @return {string|string[]|string}
   */
  const computeDisplayTypeInit = () => {
    for (let key of Object.keys(displayTypes)) {
      if (displayTypes[key] === query.displayType) {
        return query.displayType
      }
    }
    return displayTypes.GENERAL;
  }

  const [displayType, setDisplayType] = useState(computeDisplayTypeInit());

  const [filters, setFilters] = useState({
    sort_by: query.sort_by || '',
    sort_dir: query.sort_dir || '',
    page: query.page || 1,
    page_size: query.page_size || 25,
    price_ht_min: query.price_ht_min || '',
    price_ht_max: query.price_ht_max || '',
    price_ttc_min: query.price_ttc_min || '',
    price_ttc_max: query.price_ttc_max || '',
    number: query.number || '',
    customer: query.customer || '',
    email_send: query.email_send || '',
    start_date: query.start_date || '',
    end_date: query.end_date || '',
    status: query.status || '',
    payment_state: query.payment_state || '',
    displayType: displayType
  });

  const getQuery = useCallback(() => {
    return {
      page: filters.page,
      page_size: filters.page_size,
      sort: `${filters.sort_by} ${filters.sort_dir}`.trim(), // trim => remove space when filters is empty
      ht_min: filters.price_ht_min,
      ht_max: filters.price_ht_max,
      ttc_min: filters.price_ttc_min,
      ttc_max: filters.price_ttc_max,
      state: filters.state,
      number: filters.number,
      client: filters.customer,
      email_send: filters.email_send,
      start_date: filters.start_date,
      end_date: filters.end_date,
      payment_state: filters.payment_state,
      status: filters.status,
      displayType: filters.displayType
    };
  }, [filters]);

  const applyFilter = useCallback(() => {
    props.getInvoices.call(undefined, getQuery()); // Update interface with new filter
  }, [getQuery, props.getInvoices]);



  useEffect(() => {
    setQuery(filters, props.history); // Update query in path

    setInvoicesIdList([]);

    applyFilter();
  }, [filters, applyFilter, props.history]);

  function resetFilters() {
    setFilters({
      sort_by: '',
      sort_dir: '',
      page: 1,
      page_size: 25,
      price_ht_min: '',
      price_ht_max: '',
      price_ttc_min: '',
      price_ttc_max: '',
      number: '',
      customer: '',
      email_send: '',
      start_date: '',
      end_date: '',
      status: '',
      payment_state: '',
      displayType: displayType
    });
  }

  function toggleModalMail() {
    setModalMail(!modalMail);
    listUsersHaveMail(invoicesIdList, props.invoices, setIsListUsersHaveMail, setAllListUsersHaveMail);
  }

  const toggleShowButton = (array) => {
    if (array.length > 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    setInvoicesIdList(array);
  };

  const computeDisplayStateQuery = (displayTypeValue) => {
    let q = parseQuery();
    q.displayType = displayTypeValue;
    setQuery(q, props.history);
  }

  const convertProject = (id) => {
    projectConvertModalRef.current?.toggle(id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="invoices-container">
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Factures" breadcrumbItem="Toutes les factures" />
          <Row>
            <Col lg="12">
              <Alert
                color="success"
                isOpen={query.success_add === "1"}
                toggle={() => removeFlashMessage(flash_message, setFlash_message)}
              >
                La facture à bien été créé.
              </Alert>
              <Alert
                color="success"
                isOpen={query.success_update === "1"}
                toggle={() => removeFlashMessage(flash_message, setFlash_message)}
              >
                La facture à bien été mise à jour.
              </Alert>
              <Alert
                color="success"
                isOpen={query.success_delete === "1"}
                toggle={() => removeFlashMessage(flash_message, setFlash_message)}
              >
                La facture à bien été suprimé.
              </Alert>
              {props.list_failed.length !== 0 ? (
                <UncontrolledAlert color="danger">
                  Les factures des personnes ci-dessous n'ont pas pus être
                  envoyées:
                  <br />
                  {props.list_failed
                    .map((e) => parseCustomerName(e))
                    .join(", ")}
                </UncontrolledAlert>
              ) : null}
              {props.list_already_sent.length !== 0 ? (
                <UncontrolledAlert color="warning">
                  Les factures des personnes ci-dessous ont déjà été envoyées:
                  <br />
                  {props.list_already_sent
                    .map((e) => parseCustomerName(e))
                    .join(", ")}
                </UncontrolledAlert>
              ) : null}
              {props.list_success.length !== 0 ? (
                <UncontrolledAlert color="success">
                  {props.list_success.length} facture
                  {props.list_success.length > 1 ? "s ont" : " a"} bien été
                  envoyée{props.list_success.length > 1 ? "s" : ""}.
                </UncontrolledAlert>
              ) : null}
            </Col>
          </Row>
          {props.loading ? (
            <GlobalLoader />
          ) : (
            <Row>
              <Col lg="12">
                <Row>
                  <Col xs="12" className="d-flex justify-content-start">
                    <span
                      className="d-flex text-link mb-3"
                      onClick={() => {
                        setFiltersCollapseIsOpen(!filtersCollapseIsOpen);
                      }}
                    >
                      <i className="fas fa-filter mr-1" />Filtrer
                    </span>
                    <span
                      className="d-flex text-link text-color-unset mb-3"
                      onClick={resetFilters}
                    >
                      <i className="fas fa-times mr-1" /> Vider les
                      filtres
                    </span>
                  </Col>
                  <Col xs="12">
                    <Collapse isOpen={filtersCollapseIsOpen}>
                      <InvoiceFilter
                        filters={filters}
                        setFilters={setFilters}
                      />
                    </Collapse>
                  </Col>
                </Row>
                <Card>
                  <CardBody className="table-invoices-container">
                    <CardTitle className="mb-3">
                      <Row className="pr-2 pl-2 d-flex justify-content-between">
                        <Col xs="12" className="title-button">
                          Toutes les factures{" "}
                          {showButton ? (
                            <button
                              className="ml-3 bg-transparent !text-theme-color-2"
                              onClick={() => {
                                if (allInvoicesSelected) {
                                  props.generateZip({
                                    ...getQuery(),
                                    pagination: false,
                                    page: null,
                                    page_size: null,
                                    invoices: 'all'
                                  })
                                } else {
                                  props.generateZip({
                                    invoices: `${invoicesIdList.map((id, index) => id)}`
                                  })
                                }
                                toggleShowButton(Array(0));
                              }}
                            >
                              Télécharger
                            </button>
                          ) : null}
                          {showButton ? (
                            <button
                              className="ml-3 bg-transparent !text-theme-color-2"
                              onClick={() => toggleModalMail()}
                            >
                              Envoyer par mail
                            </button>
                          ) : null}
                          <button
                            className="ml-3 bg-transparent !text-theme-color-2"
                            onClick={() => setShowComptaExportsModal(true)}
                          >
                            Exporter les écritures comptables
                          </button>
                          <button
                              className="ml-3 bg-transparent !text-theme-color-2"
                              onClick={() => setShowPaymentsExportsModal(true)}
                          >
                            Exporter le suivi des paiements
                          </button>
                          <button
                              className="ml-3 bg-transparent !text-theme-color-2"
                              onClick={() => setShowCheckPaymentsModal(true)}
                          >
                            Verifier les paiements
                          </button>
                          <button
                              className="ml-3 bg-transparent !text-theme-color-2"
                              onClick={() => setShowExportInvoicesModal(true)}
                          >
                            Exporter les factures
                          </button>
                        </Col>
                      </Row>
                    </CardTitle>
                    <Row>
                      <Col lg="4" xl="3">
                        <div className="d-flex flex-column mb-5">
                          <span className="mb-2">Type d'affichage :</span>
                          <Select
                            className="w-100"
                            value={{ label: displayType, value: displayType }}
                            options={displayTypeOptions}
                            placeholder='Sélectionner'
                            onChange={(option) => {
                              setDisplayType(option.value);
                              computeDisplayStateQuery(option.value);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-end mb-3">
                        <Link
                          to="/order/add"
                          className="d-flex align-items-center text-theme-color-2 font-size-15 font-weight-bold"
                        >
                          <i className="bx bx-plus-circle mr-1" /> Créer une
                          facture
                        </Link>
                        <Link
                          to="/refund/add"
                          className="d-flex align-items-center text-theme-color-2 ml-3 font-size-15 font-weight-bold"
                        >
                          <i className="bx bx-plus-circle mr-1" /> Créer un
                          avoir
                        </Link>
                      </Col>
                    </Row>
                    <InvoicesTableComponent
                      history={props.history}
                      projectConvert={convertProject}
                      invoicesList={props.invoices}
                      fastInvoiceEditModalRef={fastInvoiceEditModalRef}
                      filters={filters}
                      setFilters={setFilters}
                      pagination={props.pagination}
                      setAllInvoicesSelected={setAllInvoicesSelected}
                      setInvoicesIdList={setInvoicesIdList}
                      invoicesIdList={invoicesIdList}
                      setShowButton={setShowButton}
                      displayType={displayType}
                      last_invoice_validate={props.last_invoice_validate}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <div>
        <ProjectConvertModal
          ref={projectConvertModalRef}
          handleSubmit={props.migrateOrderFromInvoice}
        />
        <FastInvoiceEditModal wrappedComponentRef={fastInvoiceEditModalRef} />
      </div>
      {/* Mail modal */}
      <MailModal
        isOpen={modalMail}
        toggle={toggleModalMail}
        toggleShowButton={toggleShowButton}
        isListUsersHaveMail={isListUsersHaveMail}
        allInvoicesSelected={allInvoicesSelected}
        allListUsersHaveMail={allListUsersHaveMail}
        invoicesIdList={invoicesIdList}
        getQuery={getQuery}
      />
      {showComptaExportsModal ? <ComptaExportModal onAbort={() => setShowComptaExportsModal(false)}/> : null}
      {showPaymentsExportsModal ? <PaymentsExportModal onAbort={() => setShowPaymentsExportsModal(false)}/> : null}
      {showCheckPaymentsModal ? <CheckPaymentsModal onAbort={() => setShowCheckPaymentsModal(false)}/> : null}
      {showExportInvoicesModal ? <ExportInvoicesModal onAbort={() => setShowExportInvoicesModal(false)}/> : null}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const {
    invoices,
    pagination,
    loading,
    zip_file,
    list_success,
    list_failed,
    list_already_sent,
    last_invoice_validate,
  } = state.Invoices;
  return {
    invoices,
    pagination,
    loading,
    zip_file,
    list_success,
    list_failed,
    list_already_sent,
    last_invoice_validate,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getInvoices,
    validateInvoice,
    invoicesApiError,
    generateZip,
    sendInvoiceEmails,
    deleteInvoice,
    migrateOrderFromInvoice
  })(InvoicesList)
);
