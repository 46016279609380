import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { parseCustomerName } from "../../../helpers/customer_helper";
import Mandate from '../../../models/mandate';

export default function ConfirmUpsertModal({
  toggleModal,
  mandate,
  handle
}: {
  isOpen: boolean,
  toggleModal: () => void,
  mandate: Mandate | null,
  handle: () => void,
}) {
  return (
    <Modal
      isOpen={mandate !== null}
      toggle={() => toggleModal()}
    >
      <ModalHeader toggle={() => toggleModal()}> Abonnement - Confirmation</ModalHeader>
      <ModalBody>
        <h1>Attention</h1>
        <p>Le compte bancaire est déjà utilisé pour l'abonnement nº{mandate?.subscription?.number} avec pour client {mandate?.subscription?.client && parseCustomerName(mandate.subscription?.client)}. Souhaitez-vous continuer ?</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => toggleModal()}>Annuler</Button>
        <Button type='submit' onClick={() => handle()} className='btn-green'>Confirmer</Button>
      </ModalFooter>
    </Modal>
  );
}