import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Provider } from 'react-redux';

import store from './store';
// Init Sentry as soon as possible
import * as Sentry from "@sentry/react";
if (process.env.REACT_APP_SENTRY_ENV !== undefined && process.env.REACT_APP_SENTRY_ENV !== 'local') {

    Sentry.init({ 
        dsn: "https://419f3fb6f4114d54bc0cc8f071396e7f@sentry.unova.fr/2", 
        environment: process.env.REACT_APP_SENTRY_ENV,
        // TODO	release: "nwe-front@" + process.env.npm_package_version,
    });

}

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
