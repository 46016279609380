import { Form, Formik } from 'formik';
import React from "react";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import {RequestsFilters} from "../../../../models/request";
import {
  promiseRequestNaturesOptions,
  promiseRequestPriorityOptions,
  promiseRequestStatusOptions,
  promiseRequestTypesOptions, promiseUsersOptions
} from "../../../../helpers/autocomplete_promise_options_api_v2";
import { FormikAutocompleteUnselectable } from "../../../../components/Common/AsyncAutoComplete2";

interface RequestsFilterProps {
  filters: RequestsFilters,
  setFilters: React.Dispatch<React.SetStateAction<RequestsFilters>>
}

const RequestsFilter = ({
  filters,
  setFilters,
}: RequestsFilterProps) => {

  // @ts-ignore
  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      onSubmit={(values) => {
        setFilters({
          ...filters,
          ...values
        })
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form>
          <Card>
            <CardBody>
              <Row>
                <Col xs="12" lg="3" md="4" className="mb-4">
                  <Row>
                    <Col xs="12">
                      <Label for="percent">Date</Label>
                    </Col>
                    <Col xs="6" className='mb-4 unova-datepicker'>
                      <Row>
                        <Col xs="3" className={"d-flex align-items-center"}>
                          <Label className={"mb-0"}>Du</Label>
                        </Col>
                        <Col xs="9">
                          <DatePicker
                            className="form-control button-without-style"
                            name="date_begin"
                            selected={values.date_begin? new Date(values.date_begin) : null}
                            onChange={v => setFieldValue("date_begin",v)}
                            isClearable={true}
                            placeholderText={'dd/mm/aaaa'}
                            dateFormat={"dd/MM/yyyy"}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="6" className='mb-4 unova-datepicker'>
                      <Row>
                        <Col xs="3" className={"d-flex align-items-center"}>
                          <Label className={"mb-0"}>au</Label>
                        </Col>
                        <Col xs="9">
                          <DatePicker
                            className="form-control button-without-style"
                            name="date_end"
                            selected={values.date_end?new Date(values.date_end):null}
                            onChange={v => setFieldValue("date_end",v)}
                            isClearable={true}
                            placeholderText={'dd/mm/aaaa'}
                            dateFormat={"dd/MM/yyyy"}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="3" md="4" className="mb-4">
                  <Row>
                    <Col xs="12">
                      <Label for="value">Priorité</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                       loadOptions={promiseRequestPriorityOptions}
                       isSearchable={false}
                       multiple={true}
                       name={"priorities"}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="3" md="4" className='mb-4 unova-datepicker'>
                  <Row>
                    <Col xs="12">
                      <Label>Interlocuteur</Label>
                    </Col>
                    <Col xs="12">
                      <input type="text"
                             placeholder={"Entrer le nom de l’interlocuteur"}
                             name={"interlocutor_query"}
                             className={"form-control"}
                             defaultValue={values.interlocutor_query || ""}
                             onKeyUp={e=>setFieldValue("interlocutor_query",e.currentTarget.value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="3" md="4" className="mb-4">
                  <Row>
                    <Col xs="12">
                      <Label for="value">Statut</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        loadOptions={promiseRequestStatusOptions}
                        isSearchable={false}
                        multiple={true}
                        name={"status"}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="3" md="4" className="mb-4">
                  <Row>
                    <Col xs="12">
                      <Label>Collaborateur assigné</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        loadOptions={(query:string) => promiseUsersOptions({query})}
                        isSearchable={true}
                        multiple={true}
                        name={"collaborators"}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="3" md="4" className="mb-4">
                  <Row>
                    <Col xs="12">
                      <Label for="value">Type de la demande</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        loadOptions={promiseRequestTypesOptions}
                        isSearchable={false}
                        multiple={true}
                        name={"types"}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="3" md="4" className="mb-4">
                  <Row>
                    <Col xs="12">
                      <Label for="value">Nature de la demande</Label>
                    </Col>
                    <Col xs="12">
                      <FormikAutocompleteUnselectable
                        loadOptions={promiseRequestNaturesOptions}
                        isSearchable={false}
                        multiple={true}
                        name={"natures"}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="text-center mt-3">
                  <Button
                    color="none"
                    className="btn-green"
                    onClick={() => handleSubmit(values as any)}
                  >
                    Appliquer les filtres
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default RequestsFilter;
