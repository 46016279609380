import { useState, useEffect } from "react";

import queryString from "query-string";

/**
 * Check if the current JWT has expired
 */
export const isTokenValid = () => {
  const validUntil = localStorage.getItem("valid_until") || false;

  if (validUntil) {
    if (new Date() < new Date(validUntil)) {
      // Check if expired with UTC time
      return true;
    }
  }
};

/**
 * Return date with format
 */
export const getDateWithFormat = (dateToChange, format = "fr-FR") => {
  if (!dateToChange) return null;
  let opt = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let date = new Date(dateToChange);
  let dateFormat = date.toLocaleDateString(format, opt);

  return dateFormat;
};

/**
 * Return date with format
 */
export const getDateWithShortFormat = (dateToChange, format = "fr-FR") => {
  if (!dateToChange) return null;
  let date = new Date(dateToChange);
  let dateFormat = date.toLocaleDateString(format);

  return dateFormat;
};

/**
 * Return time with format
 */
export const getTimeWithFormat = (dateToChange, format = "fr-FR") => {
  if (!dateToChange) return null;
  let opt = {
    timeStyle: "short",
  };
  let date = new Date(dateToChange);
  let dateFormat = date.toLocaleTimeString(format, opt);

  return dateFormat;
};

/**
 * Return date and time with format
 */
export const getShortDateAndTimeWithFormat = (
  dateToChange,
  format = "fr-FR"
) => {
  if (!dateToChange) return null;
  return `${getDateWithShortFormat(dateToChange, format)} à ${getTimeWithFormat(
    dateToChange,
    format
  )}`;
};

export const fileConvertSize = (size) => {
  size = Math.abs(parseInt(size, 10));
  let def = [
    [1, "octets"],
    [1024, "ko"],
    [1024 * 1024, "Mo"],
    [1024 * 1024 * 1024, "Go"],
    [1024 * 1024 * 1024 * 1024, "To"],
  ];
  for (var i = 0; i < def.length; i++) {
    if (size < def[i][0]) {
      return (size / def[i - 1][0]).toFixed(2) + " " + def[i - 1][1];
    }
  }
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const useDebouncedValue = (value, wait) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => setDebouncedValue(value), wait);
    return () => clearTimeout(id);
  }, [value, wait]);

  return debouncedValue;
};

export const getLastChildOrder = (project) => {
  let order = project.order;
  while (order?.child_order) {
    order = order.child_order;
  }
  return order;
}

export const getLastParentOrder = (o) => {
  let order = o;
  while (order?.order) {
    order = order.order;
  }
  return order;
}

export const setQuery = (values, history, replace = false) => {
  Object.keys(values).forEach((key) => {
    if (values[key] instanceof Date) {
      values[key] = values[key].toISOString();
    }
  });
  history[replace?"replace":"push"]({
    pathname: window.location.pathname,
    search: queryString.stringify(
      values,
      {
        skipEmptyString: true,
        skipNull: true,
        arrayFormat: 'bracket-separator',
        arrayFormatSeparator: ','
      }
    )
  });
}

export const parseQuery = () =>  {
  return queryString.parse(window.location.search, {
    arrayFormat: 'bracket-separator',
    arrayFormatSeparator: ','
  });
}

/** @deprecated See customer_helper */
export const parseCustomer = (customer) => {
  if (!customer) return '';
  return customer.client_type === 'Professionnel' ?
    customer.company_name :
    `${customer.civility} ${customer.lastname} ${customer.firstname}` +
    (customer.firstname2 || customer.lastname2 ? ` & ${customer.lastname2} ${customer.firstname2}` : '')
}

export const payment_mode_options = [
  { label: 'Chèque', value: 'chèque'},
  { label: 'Virement', value: 'virement'},
  { label: 'Prélèvement', value: 'prélèvement'},
  { label: 'CB', value: 'CB'},
  { label: 'Espèce', value: 'espèce'},
]

export const payment_state_options = [
  { label: 'Reçu', value: 'reçu'},
  { label: 'Acquitté', value: 'acquitté'},
]

export const fourniture_label_options = [
  { label: 'ACR', value: 'acr'},
  { label: 'BIGMAT', value: 'bigmat'},
  { label: 'BRICOMAN', value: 'bricoman'},
  { label: 'CAL', value: 'cal'},
  { label: 'CEDEO', value: 'cedeo'},
  { label: 'CEGECLIM', value: 'cegeclim'},
  { label: 'CGED', value: 'cged'},
  { label: 'CLIM +', value: 'clim_plus'},
  { label: 'DEMUSSI', value: 'demussi'},
  { label: 'PUM', value: 'pum'},
  { label: 'PUYBARET', value: 'puybaret'},
  { label: 'REXEL', value: 'rexel'},
  { label: 'ROLESCO', value: 'rolesco'},
  { label: 'SONEPAR', value: 'sonepar'},
  { label: 'TEREVA', value: 'tereva'},
  { label: 'TRAPY', value: 'trapy'},
  { label: 'SIDV', value: 'sidv'},
  { label: 'YESSS', value: 'yesss'}
]

/**
 * Compute options for selectors, will just uppercase the first letter of the string attribut.
 * @param {object} options an object with string attributs
 * @return {{label, value: *}[]}
 */
export const computeOptions = (options) =>
  Object.keys(options).map(p => ({
    label: options[p].charAt(0).toUpperCase() + options[p].slice(1),
    value: options[p]
  }));





/**
 * Transform the semicolon into dot
 * @param value
 * @return {number}
 */
export function parseInputToFloat(value) {
  return parseFloat(value.split(',').join('.'));
}

export function orderHasProject(o) {
  let order = o;
  while (order?.order) {
    if (order.project || order.project_id) {
      return true;
    }

    order = order.order;
  }
  return !!(order.project || order.project_id);
}

/**
 * 
 * @param {object} error 
 * @param {(field: string, message: string | undefined) => void} setFieldError 
 */
export function parseApiError(error, setFieldError) {
  error = error.data.errors.find((error) => error.detail)
  if (error) {
    for(let field in error.detail) {
      setFieldError(field, error.detail[field][0])
    }
  }
}

/**
  * Parse amount value in cents to euro
  * @param {number} amount Cents amount
  * @param {boolean} with_devise Append euro symbol if is true [Default to false]
  * @return {string} Ex: amount = 500 -> 5.00
  */
export function parseCentsAmount(amount, with_devise = false) {
  let value = (amount / 100.0).toFixed(2)
  if (with_devise) value += " €"
  return value
}

/**
  * Remove cee prime from order
  * @param {Invoice} row Invoice
  * @param {number} price Price
  */
export function removeCeePrime(row, price) {
  return isDepositInvoice(row)
    ? `${parseFloat(price).toFixed(2)} €`
    : `${(parseFloat(price) + (parseInt(row.order.other_prime_amount) / 100.0) + parseFloat(row.order.cce_prime)).toFixed(2)} €`;
}


/**
  * Check is a deposit invoice from invoice
  * @param {Invoice} invoice Invoice
  */
export function isDepositInvoice(invoice) {
  let index = -1;
  for (let i = 0; i < invoice.order.deposits.length; i++) {
    if (invoice.order.deposits[i].invoice_id === invoice.id) {
      index = i;
      break;
    }
  }
  return index !== -1;
}
