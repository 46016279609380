import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { GET_CATEGORIES } from "./actionTypes";
import { getCategoriesSuccess, categoryApiError } from "./actions";

import { categories } from "../../api";

function* getCategories({ payload: { query } }) {
  try {
    const response = yield call(categories.getAll, query);
    yield put(getCategoriesSuccess(response));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(
          categoryApiError(
            "Vous n'êtes pas autorisé à accéder à la liste des familles de produit"
          )
        );
      } else if (error.response.data !== undefined) {
        yield put(categoryApiError(error.response.data.message));
      }
    } else {
      yield put(categoryApiError(error.message));
    }
  }
}

export function* watchGetCategories() {
  yield takeEvery(GET_CATEGORIES, getCategories);
}

function* categoriesSaga() {
  yield all([fork(watchGetCategories)]);
}

export default categoriesSaga;
